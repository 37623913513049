import { debounceTime } from 'rxjs/operators';
import { ToastrService } from './../../../core/services/toastr.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ContractService, ContractStatus, ContractType } from 'src/app';
import { Contract } from 'src/app';
import { UserService } from 'src/app';
import { IDayCalendarConfig } from 'ng2-date-picker';
import moment from 'moment';
import { User } from 'src/app/model/user';
import { ContractTemplate } from 'src/app/model/contractTemplate';
import { ActivatedRoute } from '@angular/router';
import { MeucurrencyPipe } from 'src/app/shared/pipes/meucurrency.pipe';
@Component({
  selector: 'meu-contract-management-create',
  templateUrl: './contract-management-create.component.html',
  styleUrls: ['./contract-management-create.component.scss'],
})
export class ContractManagementCreateComponent implements OnInit {
  contractType: ContractType;
  users: User;
  status: ContractStatus;
  contractTemplate: ContractTemplate;
  contract: Contract = {};
  dateSigned;
  dateStart;
  dateEnd;
  realtimePrice;
  defaultSigned = moment().format('DD/MM/YYYY');
  defaultStart = moment().format('DD/MM/YYYY');

  testCode = 0;

  inventoryId;

  isSubscriberTypePostpaid = true;

  subscriberType = [
    {
      id: 1,
      name: 'Trả sau có hiệu chỉnh',
    },
    {
      id: 2,
      name: 'Loại Hợp đồng thuê theo thời gian',
    },
  ];

  @Output() closeModalCreate = new EventEmitter();
  @Input() contractCode: string;
  @Input() customerID: string;
  @Input() contract_id: string;

  formcreate = new FormGroup({
    subscriber_type: new FormControl(1),
    type: new FormControl(),
    contractTemplate: new FormControl(),
    code: new FormControl('', Validators.maxLength(50)),
    name: new FormControl(),
    amount: new FormControl(0, [
      Validators.required,
      Validators.pattern('[0-9]+(,[0-9]+)*,?'),
    ]),
    signature: new FormControl(''),
    status: new FormControl(),
    start: new FormControl(moment(), Validators.required),
    end: new FormControl(''),
    note: new FormControl('', [Validators.maxLength(200)]),
  });

  defaultStatus;
  defaultType;
  defaultTemplete;
  filesLogo: File[] = [];

  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY',
    firstDayOfWeek: 'mo',
    opens: 'right',
    drops: 'down',
  };
  datePickerConfigEnd = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY',
    firstDayOfWeek: 'mo',
    opens: 'left',
    drops: 'down',
  };

  constructor(
    private contractService: ContractService,
    private userService: UserService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private meuCurrencyPipe: MeucurrencyPipe
  ) {}

  ngOnInit(): void {
    this.setInventoryId();
    this.getAllTypeContract();
    this.getAllContractTemplate();
    this.getAllUser();
    this.getAllStatus();
    if (this.contract_id) {
      this.getDetailContract(this.contract_id);
    }

    if (this.contractCode) {
      this.formcreate.controls['code'].setValue(this.contractCode);
    }
    if (this.customerID) {
      this.formcreate.controls['name'].setValue(this.customerID);
    }
  }

  setInventoryId() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
    });
  }

  reverseDate(date) {
    let day = date.slice(0, 2);
    let month = date.slice(3, 5);
    let year = date.slice(6, 10);
    let res = year + '/' + month + '/' + day;
    return res;
  }

  //Copy Detail Contract

  getDetailContract(id) {
    this.contractService.apiContractIdGet(id).subscribe((res) => {
      if (res.success == true) {
        this.formcreate.patchValue({
          type: res.data.contract_type_id,
          code: res.data.code,
          name: res.data.customer_id,
          amount: res.data.amount_on_contract
            ? res.data.amount_on_contract
            : null,
          signature: res.data.date_signed
            ? moment(res.data.date_signed).format('DD/MM/YYYY ')
            : null,
          status: res.data.status,
          start: res.data.date_contract_start
            ? moment(res.data.date_contract_start).format('DD/MM/YYYY ')
            : null,
          end: res.data.date_contract_end
            ? moment(res.data.date_contract_end).format('DD/MM/YYYY')
            : null,
          note: res.data.note,
        });
      }
    });
  }

  //Get Type Contract
  getAllTypeContract() {
    this.contractService.apiContractTypeGet().subscribe((data) => {
      if (data.success == true) {
        this.contractType = data.data.collection;
        this.defaultType = data.data.collection[0].id;
      }
    });
  }
  getValueTypeContract(data) {
    if (data) {
      this.formcreate.controls['type'].setValue(data.id);
    }
  }
  getValueSubscriberType(data) {
    if (data) {
      this.formcreate.controls['subscriber_type'].setValue(data.id);
    }
    if (data.id === 1) {
      this.isSubscriberTypePostpaid = true;
    } else {
      this.isSubscriberTypePostpaid = false;
    }
  }

  //Get contract template
  getAllContractTemplate() {
    this.contractService.apiContractTemplateGet().subscribe((res) => {
      if (res.success == true) {
        this.contractTemplate = res.data.collection;
        this.defaultTemplete = res.data.collection[0].id;
      }
    });
  }
  getValueTemplateContract(data) {
    if (data) {
      this.formcreate.controls['contractTemplate'].setValue(data.id);
    }
  }

  //Get Status
  getAllStatus() {
    this.contractService.apiContractStatusGet().subscribe((data) => {
      if (data.success) {
        this.status = data.data.collection;
        this.defaultStatus = data.data.collection[0].id;
      }
    });
  }
  getValueStatusContract(data) {
    if (data) {
      this.formcreate.controls['status'].setValue(data.id);
    }
  }

  //Addd
  closeModal(res = null) {
    if (res) {
      this.closeModalCreate.emit(res);

      return;
    }
    this.closeModalCreate.emit();
  }
  submit() {
    this.contract.customer_id = this.formcreate.controls['name'].value;
    this.contract.contract_template_id = this.formcreate.controls[
      'contractTemplate'
    ].value;
    this.contract.amount_on_contract = this.formcreate.controls['amount'].value
      ? this.removeSymbol(this.formcreate.controls['amount'].value)
      : 0;

    this.contract.date_signed = this.formcreate.controls['signature'].value
      ? new Date(
          this.reverseDate(this.formcreate.controls['signature'].value.trim())
        )
      : null;
    this.contract.date_contract_start = this.formcreate.controls['start'].value
      ? new Date(
          this.reverseDate(this.formcreate.controls['start'].value.trim())
        )
      : null;
    this.contract.date_contract_end = this.formcreate.controls['end'].value
      ? new Date(this.reverseDate(this.formcreate.controls['end'].value.trim()))
      : null;
    this.contract.note = this.formcreate.controls['note'].value
      ? this.formcreate.controls['note'].value.trim()
      : '';
    if (this.formcreate.controls['code'].value) {
      this.contract.code = this.formcreate.controls['code'].value
        ? this.formcreate.controls['code'].value.trim()
        : '';
    } else {
      this.contract.code = null;
    }
    this.contract.contract_type_id = this.formcreate.controls['type'].value;
    this.contract.status = this.formcreate.controls['status'].value;
    this.contract.inventory_id = this.inventoryId;
    //Check type Contract
    const isPaidUsed = this.formcreate.controls['subscriber_type'].value;
    if (isPaidUsed === 1) {
      this.contract.isPaidAsUsed = false;
    } else if (isPaidUsed === 2) {
      this.contract.isPaidAsUsed = true;
    }

    if (this.contract.code === '') {
      this.contract.code = null;
      this.formcreate.controls['code'].setValue(null);
    }
    if (this.formcreate.invalid) {
    } else {
      this.contract.code = this.changeCharater(
        this.formcreate.controls['code'].value.trim()
      );
      this.contractService.apiContractPost(this.contract).subscribe((data) => {
        if (data.success) {
          if (this.filesLogo.length > 0) {
            if (this.filesLogo.length > 0) {
              this.filesLogo.forEach((e) => {
                this.contractService
                  .apiContractIdAttachmentUploadPut(data.data.id, e)
                  .pipe(debounceTime(4000))
                  .subscribe((result2) => {
                    if (result2) {
                    }
                  });
              });
            }
          }
          this.toastrService.success(data.message);
          this.closeModal(data);
        } else {
          this.closeModal(data);
          this.toastrService.error(data.message);
        }
      });
    }
  }

  //dropdown User
  getAllUser() {
    this.userService
      .apiUsersGet(`extend_user_role@='|CUSTOMER|'`, '', 1, 1000)
      .subscribe((data) => {
        if (data.success) {
          this.users = data.data.collection;

          // this.users.forEach((data) => {
          // });
        }
      });
  }
  getValueUser(data) {
    if (data) {
      this.formcreate.controls['name'].setValue(data.id);
    }
  }

  onSelect(event) {
    this.filesLogo.push(...event.addedFiles);
  }

  onRemove(event) {
    this.filesLogo.splice(this.filesLogo.indexOf(event), 1);
  }

  getDateTime(event) {
    //this.dateSigned = event;
  }

  changeAmount() {}

  onChangeNumber(value, name) {
    let result = this.meuCurrencyPipe.transform(value);
    this.formcreate.controls[name].setValue(result);
  }

  removeSymbol(value) {
    return parseInt(value.toString().replace(/,/g, ''));
  }

  //Validate
  changeCharater(str) {
    if (str) {
      str = str.toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }
}
