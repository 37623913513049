import { MxModalOption } from './../../../../shared/components/modal/modal-confirm/modal-confirm.component';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContainerService } from 'src/app/api/container.service';

@Component({
  selector: 'meu-modal-transfer-container',
  templateUrl: './modal-transfer-container.component.html',
  styleUrls: ['./modal-transfer-container.component.scss'],
})
export class ModalTransferContainerComponent implements OnInit {
  @Output() closeModalTransfer = new EventEmitter();

  allContainer = [];
  isOpenModal = false;
  titleModal = 'Thêm mới phiếu khai báo';
  options: MxModalOption = {
    callBackAfterClose: () => {
      this.isOpenModal = false;
    },
  };

  pageIndex = 0;
  totalPage = 10;
  totalArray = 0;
  array;

  constructor(private containerService: ContainerService) {}

  ngOnInit(): void {
    this.getAllContainer();
  }
  //get all container
  getAllContainer() {
    this.containerService
      .apiContainerGet('is_locked==false,is_tranfer_car==false')
      .subscribe((res) => {
        if (res.success) {
          this.array = res.data.collection;
          this.totalArray = res.data.total;
        }
      });
  }

  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.pageIndex = (currentPage - 1) * pageSize;
    this.containerService
      .apiContainerGet(
        'is_locked==false,is_tranfer_car==false',
        '',
        currentPage,
        pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          this.allContainer = res.data.collection;
          this.totalArray = res.data.total;
        }
      });
  }
  openImport(item) {
    this.isOpenModal = true;
    this.closeModal(item);
  }

  closeModal(event) {
    this.isOpenModal = false;
    this.closeModalTransfer.emit(event);
  }
}
