import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStoreService {

    public localStorageItem = {
        inventoryId: 'inventoryId'
    }

    constructor() { }

    public saveToLocalStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getFromToLocalStorage(key: string): string {
        return localStorage.getItem(key)
    }
}
