<div class="container grid-contain">
  <div
    class="col-span-12 grid grid-cols-12 px-16px bg-white shadow-md rounded-4px mt-16px"
  >
    <div
      class="col-span-12 border-grayscale-light-grey border-opacity-40 border-b py-16px title"
    >
      <span class="text-20px font-medium text-grey-light"
        ><a
          [routerLink]="urlDetail + 'product-list'"
          routerLinkActive="isSelected"
          >Hàng hóa </a
        >></span
      ><span class="text-20px font-medium text-dark-grey">
        [{{ product.code }}] {{ product.name }}</span
      >
    </div>
    <div class="col-span-12 mt-8px gap-4px relative drop">
      <div class="md:flex md:flex-row md:w-full md:h-full">
        <button
          class="button-menu text-grey-light"
          routerLinkActive="isSelected"
          [routerLinkActiveOptions]="{ exact: true }"
          #info="routerLinkActive"
          [routerLink]="urlDetail + 'detail/' + product.id"
        >
          <img
            *ngIf="info.isActive; else normal"
            src="assets/icons/detail-icon/u_info-circle-isSelected.svg"
          />
          <ng-template #normal
            ><img src="assets/icons/detail-icon/u_info-circle.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium">
            Thông tin chung</span
          >
        </button>
        <button 
          class="button-menu text-grey-light"
          [routerLink]="urlDetail + 'detail/' + product.id + '/sku'"
          routerLinkActive="isSelected"
          #sku="routerLinkActive"
          permission="STAFF"
        >
          <img
            *ngIf="sku.isActive; else normal1"
            src="assets/icons/detail-icon/icon_ion_barcode-outline-isSelected.svg"
          />
          <ng-template #normal1>
            <img src="assets/icons/detail-icon/icon_ion_barcode-outline.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium"
            >Quản lý SKU</span
          >
        </button>
        <button
          class="button-menu text-grey-light"
          [routerLink]="urlDetail + 'detail/' + product.id + '/importexport'"
          routerLinkActive="isSelected"
          #imexport="routerLinkActive"
          permission="STAFF"
        >
          <img
            *ngIf="imexport.isActive; else normal2"
            src="assets/icons/detail-icon/u_file-import-isSelected.svg"
          />
          <ng-template #normal2>
            <img src="assets/icons/detail-icon/u_file-import.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium"
            >Quản lý Xuất - Nhập</span
          >
        </button>
      </div>
    </div>

    <div class="col-span-12" style="min-height: 500px">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
</div>
