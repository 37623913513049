<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center title"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Quản lý người dùng
      </div>
      <div class="flex flex-wrap md:float-right items-center gap-8px margin-t">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchCustomer"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
        <div class="w-220px">
          <ng-select
            [searchable]="false"
            class="select-name focus:outline-none"
            placeholder="Tất cả loại người dùng"
            bindLabel="name"
            bindValue="name"
            [multiple]="true"
            [items]="Role"
            [clearable]="false"
            [clearOnBackspace]="false"
            [closeOnSelect]="false"
            (change)="getRoleUser($event)"
          >
            <ng-template
              ng-multi-label-tmp
              let-items="items"
              let-item$="item$"
              let-index="index"
            >
              <span
                class="text-14px font-roboto-medium text-grey-label"
                *ngIf="items[0]"
                >{{ items[0].name }}
              </span>
              <span
                class="text-14px font-roboto-medium text-grey-label"
                *ngIf="items[1]"
                >, {{ items[1].name }}
              </span>
              <span
                class="text-14px font-roboto-medium text-grey-label"
                *ngIf="items[2]"
                >, {{ items[2].name }}
              </span>
              <!-- <div *ngFor="let item of items"></div> -->
            </ng-template>
            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div class="flex items-center space-x-2">
                <input
                  id="item-{{ index }}"
                  type="checkbox"
                  [ngModel]="item$.selected"
                />
                <span> {{ item.name }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="flex items-center gap-8px">
          <button
            class="btn-primary-update flex flex-row items-center gap-4px"
            (click)="updateListCustomr()"
          >
            <img
              src="assets/icons/handle-icons/u_refresh.svg"
              class="w-20px h-20px"
            />
            <span>Cập nhật</span>
          </button>
          <button
            (click)="navigateSSO()"
            class="btn-primary-icon flex flex-row items-center gap-4px"
          >
            <img src="assets/icons/handle-icons/icon_add.svg" />
            <span>Thêm mới</span>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full overflow-x-auto" style="overflow-y: hidden">
      <table
        class="w-full border-separate table-fixed"
        cellpadding="0"
        cellspacing="0"
        *ngIf="totalArray > 0; else noInfo"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
              #
            </th>

            <!-- code -->
            <th
              class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
              (click)="Sort('extend_user_employee_code')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                >
                  Mã người dùng</span
                >
                <img
                  *ngIf="
                    sort.includes('extend_user_employee_code');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- name -->
            <th
              class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('full_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Tên người dùng</span
                >
                <img
                  *ngIf="sort.includes('full_name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- email -->
            <th
              class="text-left col-email hidden-col cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('email')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Email</span
                >
                <img
                  *ngIf="sort.includes('email'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- phone -->
            <th
              class="text-left col-phone pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              Số điện thoại
            </th>

            <!-- role -->
            <th
              class="text-left col-role cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              Loại người dùng
            </th>
            <th class="text-center w-100px pt-12px px-16px pb-8px" permission="ADMIN">Thao tác</th>
          </tr>
        </thead>
        <!-- DATA TABLE -->
        <!-- *ngFor="let product of products; let i = index" -->
        <tbody>
          <tr
            style="min-height: 40px !important"
            *ngFor="let customer of customers; let i = index"
          >
            <td
              class="td-stt py-10px px-5px cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="#"
              [tooltip]="indexArray + i + 1"
            >
              {{ indexArray + i + 1 }}
            </td>

            <td
              class="td-code py-10px cursor-pointer px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Mã khách hàng "
              [tooltip]="
                customer['extend_user_employee_code']
                  ? customer['extend_user_employee_code']
                  : '--'
              "
            >
              {{
                customer['extend_user_employee_code']
                  ? customer['extend_user_employee_code']
                  : '--'
              }}
            </td>
            <td
              class="td-name py-10px cursor-pointer px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Tên khách hàng "
              [tooltip]="customer.full_name ? customer.full_name : ''"
            >
              {{ customer.full_name }}
            </td>
            <td
              class="td-email py-10px cursor-pointer px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Email"
              [tooltip]="customer.email ? customer.email : '--'"
            >
              {{ customer.email ? customer.email : '--' }}
            </td>
            <td
              data-label="Số điện thoại"
              class="cursor-pointer py-10px px-16px td-phone overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="
                customer['extend_user_phone']
                  ? customer['extend_user_phone']
                  : '--'
              "
            >
              {{
                customer['extend_user_phone']
                  ? customer['extend_user_phone']
                  : '--'
              }}
            </td>

            <td
              class="td-role align-top py-10px cursor-pointer px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Loại người dùng"
              [tooltip]="
                customer.permissions == null || customer.permissions.length == 0
                  ? '--'
                  : customer.permissions
              "
            >
              {{
                customer.permissions == null || customer.permissions.length == 0
                  ? '--'
                  : customer.permissions
              }}
            </td>
            <td class="td-thaotac md:px-16px align-top" data-label="Thao tác" permission="ADMIN">
              <div class="flex flex-row-reverse justify-center m-j-end">
                <button
                  tooltip="Chỉnh sửa"
                  class="hover:bg-primary-lightest focus:outline-none p-10px rounded-full"
                  [routerLink]="urlDetail + customer.id"
                  permission="ADMIN"
                >
                  <img
                    src="assets/icons/handle-icons/u_edit-alt.svg"
                    class="icon-handle"
                  />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noInfo>
        <table
          class="w-full border-separate table-fixed"
          cellpadding="0"
          cellspacing="0"
        >
          <thead>
            <tr
              class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
            >
              <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
                #
              </th>

              <!-- code -->
              <th
                class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
                (click)="Sort('extend_user_employee_code')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >
                    Mã người dùng</span
                  >
                  <img
                    *ngIf="
                      sort.includes('extend_user_employee_code');
                      else normalSearch
                    "
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- name -->
              <th
                class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
                (click)="Sort('full_name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >Tên người dùng</span
                  >
                  <img
                    *ngIf="sort.includes('full_name'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- email -->
              <th
                class="text-left col-email hidden-col cursor-pointer pt-12px px-16px pb-8px"
                (click)="Sort('email')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >Email</span
                  >
                  <img
                    *ngIf="sort.includes('email'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- phone -->
              <th
                class="text-left col-phone pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
              >
                Số điện thoại
              </th>

              <!-- role -->
              <th
                class="text-left col-role cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
              >
                Loại người dùng
              </th>
              <th class="text-center w-100px pt-12px px-16px pb-8px" permission="ADMIN">
                Thao tác
              </th>
            </tr>
          </thead>
          <!-- DATA TABLE -->
          <!-- *ngFor="let product of products; let i = index" -->
          <tbody>
            <tr class="font-roboto-regular text-14px h-40px">
              <td colspan="7" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [total]="totalArray"
        [array]="array"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<!-- <meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-modal-create-customer
    (closeModalCustomer)="closeModal($event)"
  ></meu-modal-create-customer>
</meu-modal-component> -->

<!-- <meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-create-product
    (closeModalUnit)="closeModalBottom($event)"
  ></meu-modal-create-product>
</meu-modal-popup-bottom> -->
