import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
export interface selectOptions {
  id: string;
  iconUrl?: string;
  label: string;
  selected?: boolean;
  code?: string;
}
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectDropdownComponent),
  multi: true,
};
const noop = () => {};
@Component({
  selector: 'meu-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SelectDropdownComponent implements OnInit, ControlValueAccessor {
  isOpenedList: any;
  //Todo
  //@Input() dropdownId: string;

  @Input() options: selectOptions[];
  @Input() isVertical: boolean = true;
  @Input() placeholder: string;
  @Input() idSelected;
  @Output() idSelectedChange = new EventEmitter();
  @ViewChild('dropdownItem') dropdownItem: ElementRef;
  stringName;
  urlName;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  selectedValue: any;

  constructor() {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.idSelected?.currentValue !== changes.idSelected?.previousValue
    ) {
      let item = this.options.find((x) => x.id == this.idSelected);
      this.emitValue(item);
    }
  }
  ngOnInit(): void {}

  onClickedOutside() {
    this.isOpenedList = 0;
  }

  openMenu() {
    this.isOpenedList === 1 ? (this.isOpenedList = 0) : (this.isOpenedList = 1);
  }
  emitValue(item) {
    if (item) {
      this.idSelected = item;
      this.idSelectedChange.emit(this.idSelected);
      this.stringName = item.label;
      this.urlName = item.iconUrl;
      this.onClickedOutside();
    } else {
      this.stringName = null;
      this.urlName = null;
    }
  }
  /**
   * Method implemented from ControlValueAccessor and set default selected value
   * @param {*} obj
   * @memberof SelectDropdownComponent
   */
  writeValue(obj: any): void {
    if (obj && obj !== '') {
      this.selectedValue = obj;
    } else {
      this.selectedValue = false;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
