import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductListManageComponent } from './product-list-manage/product-list-manage.component';
import { ProductDetailManageComponent } from './product-list-manage/product-detail-manage/product-detail-manage.component';
import { InformationProductComponent } from './product-list-manage/product-detail-manage/information-product/information-product.component';
import { SkuProductComponent } from './product-list-manage/product-detail-manage/sku-product/sku-product.component';
import { ImportExportProductComponent } from './product-list-manage/product-detail-manage/import-export-product/import-export-product.component';
import { ModalCreateProductModule } from './product-list-manage/modal-create-product/modal-create-product.module';
import { ImportExportHistoryModule } from '../import-export-history/import-export-history.module';
import { ModalCreateSkuComponent } from './product-list-manage/product-detail-manage/sku-product/modal-create-sku/modal-create-sku.component';
import { ModalCreateSKUModule } from './product-list-manage/product-detail-manage/sku-product/modal-create-sku.modules';
import { NgSelectModule } from '@ng-select/ng-select';
const routes: Routes = [
  {
    path: '',
    component: ProductListManageComponent,
  },
  {
    path: 'product-list',
    component: ProductListManageComponent,
  },

  {
    path: 'detail/:id',
    component: ProductDetailManageComponent,
    children: [
      { path: '', component: InformationProductComponent },
      { path: 'sku', component: SkuProductComponent },
      { path: 'importexport', component: ImportExportProductComponent },
    ],
  },
];

@NgModule({
  declarations: [
    ProductListManageComponent,
    ProductDetailManageComponent,
    InformationProductComponent,
    SkuProductComponent,
    ImportExportProductComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ImportExportHistoryModule,
    ModalCreateProductModule,
    ModalCreateSKUModule,
    NgSelectModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
  exports: [],
})
export class ProductModule {}
