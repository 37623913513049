<div
  id="screen"
  class="grid w-full bg-primary-dodger-blue div-overview sticky top-0 z-40"
  [ngClass]="isMobile && isIOS ? 'pt-35px':''"
  *ngIf="!isPrint"
>
  <div class="container grid-contain items-center div-all font-roboto-regular">
    <div
      class="col-span-1 lg:col-span-2 xl:col-span-3 text-left text-14px flex items-center"
      (clickOutside)="isMobile ? (mobileMenuOpen = false) : null"
    >
      <div *ngIf="isMobile" (click)="mobileMenuOpen = true">
        <!-- <svg
          [ngClass]="mobileMenuOpen ? 'hidden' : 'visible'"
          fill="currentColor"
          viewBox="0 0 20 20"
          class="block w-6 h-6"
        >
          <path
            ill-rule="evenodd"
            clip-rule="evenodd"
            stroke="#ffffff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 18h18M3 12h18M3 6h18"
          />
        </svg>
        <meu-mobile-menu
          [mobileMenuOpen]="mobileMenuOpen"
          [inventorySelected]="inventoryId"
        ></meu-mobile-menu> -->
        <img src="assets/icons/menu-icons/home.svg" alt="" class="h-20px" routerLink="../menu-mobile" routerLinkActive="active">
      </div>

      <!--Dropdown-->
      <div
        class="visible flex items-center"
        [ngClass]="
          isMobile ? 'ml-20px' : ''
        "
      >
        <img
          [src]="inventoryChoose.image"
          class="mr-2 w-32px h-32px flex-grow-0 rounded-8px bg-white cursor-pointer"
        />
        <!-- select inventory -->
        <div class="relative h-42px w-200px">
          <button
            (click)="open = !open"
            id="dropdown-btn"
            class="h-42px w-auto flex flex-row items-center py-2 text-14px text-left bg-transparent rounded-lg text-white focus:text-blue hover:text-blue focus:outline-none focus:shadow-outline"
          >
            <span
              *ngIf="!isMobile"
              class="font-medium overflow-ellipsis overflow-hidden whitespace-nowrap max-w-150px"
              [tooltip]="inventoryChoose.name"
              >{{ inventoryChoose.name }}</span
            >
            <span
              *ngIf="isMobile"
              class="font-medium overflow-ellipsis overflow-hidden whitespace-nowrap max-w-150px"
              >{{ inventoryChoose.name }}</span>
            <svg
              fill="#ffffff"
              viewBox="0 0 20 20"
              [ngClass]="{ 'rotate-180': open, 'rotate-0': !open }"
              class="inline w-4 h-4 mt-1 ml-5px transition-transform duration-200 transform"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            [ngClass]="{ hidden: !open }"
            id="dropdown-project-headeer"
            class="absolute w-full mt-1 origin-top-right rounded-md shadow-lg md:w-auto z-50"
          >
            <div class="px-10px py-2 bg-white rounded-md shadow search-box">
              <a
                *ngFor="let item of allInventories"
                (click)="chooseInventory(item)"
                [ngClass]="{
                  'text-gray-900 bg-gray-200': inventoryChoose.id == item.id
                }"
                class="block px-1 py-2 mt-2 text-sm bg-transparent rounded-md md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                href="javascript:void(0)"
                >{{ item.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end select inventory -->
    </div>

    <div class="col-span-5 lg:col-span-7 xl:col-span-9 h-full">
      <div class="lg:grid lg:grid-cols-8 h-full">
        <div class="col-span-6 hidden lg:block">
          <meu-menu [inventorySelected]="this.inventoryChoose.id"></meu-menu>
        </div>
        <div class="col-span-2 h-full">
          <div class="h-full">
            <nav
              class="flex sm:flex-row md:flex md:justify-end md:flex-row sm:px-0 items-center gap-x-4 h-full"
            >
              <!-- notification -->
              <div class="mt-10px md:mt-10px lg:mt-7px">
                <meu-notification></meu-notification>
              </div>
              <!-- end notification -->
              <!-- username -->
              <div class="flex">
                <a
                  href="javascript:void(0)"
                  class="flex text-sm rounded-md focus:outline-none items-center"
                  (click)="isOpenUser = !isOpenUser"
                >
                  <img
                    class="h-30px w-30px rounded-full"
                    [src]="(currentUser | async).avatar"
                    alt=""
                  />
                </a>

                <div
                  *ngIf="isOpenUser"
                  (click)="isOpenUser = false"
                  class="fixed inset-0 h-full w-full z-10"
                ></div>
                <div
                  *ngIf="isOpenUser"
                  class="mt-40px z-20 transition-all duration-300 transform origin-top-right -translate-y-2"
                >
                  <div
                    class="absolute right-0 w-56 mt-15px origin-top-right bg-white rounded-b-md shadow-md outline-none"
                  >
                    <div
                      class="px-4 py-3 flex flex-row items-center gap-x-10px border-b border-primary-dodger-blue"
                    >
                      <img
                        class="h-30px w-30px rounded-full"
                        [src]="(currentUser | async).avatar"
                      />
                      <div class="flex flex-col truncate text-14px">
                        <label class="">{{ (currentUser | async).name }}</label>
                      </div>
                    </div>
                    <a
                      class="px-4 py-3 flex gap-x-10px cursor-pointer hover:bg-primary-lightest-blue"
                      (click)="redirectToInfoPage()"
                    >
                      <img src="assets/icons/user.svg" />
                      <p class="text-sm text-gray :hover:text-dark leading-5">
                        Chỉnh sửa thông tin
                      </p>
                    </a>
                    <a
                      class="px-4 py-3 flex gap-x-10px cursor-pointer hover:bg-primary-lightest-blue"
                      [routerLink]="'/auth/logout'"
                    >
                      <img src="assets/icons/logout.svg" />
                      <div
                        tabindex="3"
                        (click)="logout()"
                        class="text-gray hover:text-dark hover:bg-tlr-lightest-blue flex justify-between w-full text-sm leading-5 text-left"
                        role="menuitem"
                      >
                        Đăng xuất
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <!-- end user picture -->
              <!-- <div>
                <img class="ml-4" src="assets/icons/menu-icons/u_setting.svg" />
              </div> -->
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
