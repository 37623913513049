import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeleteHistoryNoteService } from 'src/app/api/deleteHistoryNote.service';
import moment from 'moment';
import { SlotService } from 'src/app/api/slot.service';
import { StockAvailableService } from 'src/app/api/stockAvailable.service';

@Component({
  selector: 'meu-import-export-readonly',
  templateUrl: './import-export-readonly.component.html',
  styleUrls: ['./import-export-readonly.component.scss'],
})
export class ImportExportReadonlyComponent implements OnInit {
  @Input() code: string = '';
  @Input() ImExport: boolean = false; // Import :true , Export : false

  @Output() closeModal = new EventEmitter();

  is_draft;
  is_checked;

  title = '';
  arrayImExport = [];
  lstImportCode = '';

  constructor(
    private deleteHistoryService: DeleteHistoryNoteService,
    private slotService: SlotService,
    private stockAvailableService: StockAvailableService
  ) {}

  ngOnInit(): void {
    if (this.code.includes('PN')) {
      this.title = 'Phiếu nhập hàng';
    } else if (this.code.includes('PX')) {
      this.title = 'Phiếu xuất hàng';
    } else if (this.code.includes('PSX')) {
      this.title = 'Phiếu sang xe';
    } else {
      this.title = 'Phiếu khai báo';
    }
    this.getDetailCode();
  }

  async getDetailCode() {
    this.deleteHistoryService
      .apiDeleteHistoryCodeGet(this.code)
      .subscribe(async (res) => {
        if (res.success) {
          let array = [];
          res.data.collection.forEach((element) => {
            array.push(JSON.parse(element.im_export_note));
          });
          //list slot
          let lstSlot = await this.getSlotInfo(array);
          //group array
          let groups = array.reduce((groups, item) => {
            const group = groups[item.group_number] || [];
            group.push(item);
            groups[item.group_number] = group;
            return groups;
          }, {});
          if (this.ImExport) {
            this.groupImport(lstSlot, groups);
          } else {
            this.groupExport(lstSlot, groups);
          }
          // get list import code
          array.forEach((item, index) => {
            if (index == 0) {
              this.lstImportCode += item.import_code;
            } else {
              this.lstImportCode += ' | ' + item.import_code;
            }
          });
        }
      });
  }

  groupImport(lstSlot, groups) {
    let obj: any;
    Object.values(groups).forEach((element) => {
      obj = element[0];
      obj.selectProductObj = {};
      obj.selectSkuObj = {};
      obj.selectProductObj.id = obj.product_id;
      obj.selectProductObj.name = obj.product_name;
      obj.selectProductObj.code = obj.product_code;
      obj.selectSkuObj.id = obj.sku_id;
      obj.selectSkuObj.sku_code = obj.sku_code;
      obj.selectHashtag = [];
      obj.selectProduct = obj.product_id;
      obj.selectSku = obj.sku_id;
      obj.extend_supplier_name = obj.supplier_id;
      let arr = element as any[];
      let total = 0;
      let data: any;
      arr.forEach((item) => {
        data = lstSlot.find((c) => c.id == item.slot_id);
        total += item.quantity;
        obj.selectHashtag.push({
          id: item.slot_id,
          row_id: item.id,
          code: data.code,
          quantity: item.quantity,
          used_percent: data?.used_percent,
          zone_id: data?.zone_id,
          new_value: item.percent ? item.percent : null,
        });
      });
      obj.quantity = total;
      obj.expiry_date =
        obj.expiry_date != null
          ? moment(obj.expiry_date).format('DD/MM/yyyy')
          : '';
      let temp = new Date(obj.expiry_date);
      this.arrayImExport.push(obj);
    });
  }
  groupExport(lstSlot, groups) {
    let obj: any;
    Object.values(groups).forEach(async (element) => {
      obj = element[0];
      //this.formExport.controls['bacth_code'].setValue(obj.batch_code);
      obj.selectProductObj = {};
      obj.selectSkuObj = {};
      obj.stock = 0;
      obj.currentStock = 0;
      obj.totalQuantity = 0;
      obj.selectProductObj.id = obj.product_id;
      obj.net_weight = obj.net_weigth;
      obj.selectProductObj.name = obj.product_name;
      obj.selectProductObj.code = obj.product_code;
      obj.selectSkuObj.id = obj.sku_id;
      obj.selectSkuObj.sku_code = obj.sku_code;
      obj.selectHashtag = [];
      obj.selectProduct = obj.product_id;
      obj.selectSku = obj.sku_id;
      obj.real_net_weight = obj.real_net_weight
        ? obj.real_net_weight
        : obj.net_weigth;

      let arr = element as any[];

      arr.forEach(async (item) => {
        this.getStockAvailableForSlot(
          obj,
          item,
          item.customer_id,
          item.contract_id
        );
      });

      // obj.stock = await this.getStockSkuId(
      //   element[0].sku_id,
      //   element[0].product_id,
      //   element[0].contract_id
      // );

      // obj.currentStock = obj.stock + obj.totalQuantity;
      this.arrayImExport.push(obj);
    });
  }

  async getSlotInfo(array) {
    let res = [];
    for (let i = 0; i < array.length; i++) {
      let e = array[i];
      if (e.slot_id) {
        let data = await this.slotService
          .apiSlotIdInfoGet(e.slot_id)
          .toPromise();
        res.push({ ...data.data });
      }
    }
    return res;
  }
  /** get stock available for slot */
  getStockAvailableForSlot(obj?, item?, customer_id?, contract_id?) {
    this.stockAvailableService
      .apiStockAvailableCustomerIdStocksGet(
        customer_id,
        '',
        item.product_id,
        '',
        '',
        1,
        1000
      )
      .toPromise()
      .then(async (res) => {
        if (res.success) {
          // get quantity-stock slot

          let code = item.import_code ? item.import_code : item.imExportCode;

          let listProduct = res.data.collection.find(
            (x) => x.product_id == item.product_id
          );

          let listSlot = this.code
            ? listProduct.slot.find(
                (x) =>
                  x.slot_id === item.slot_id &&
                  x.sku_id === item.sku_id &&
                  x.imExportCode === code &&
                  x.slot_quantity > 0
              )
            : listProduct.slot.find(
                (x) =>
                  x.slot_id === item.slot_id &&
                  x.sku_id === item.sku_id &&
                  x.imExportCode === code
              );
          if (listSlot) {
            obj.selectHashtag.push({
              id: listSlot?.slot_id,
              row_id: this.code ? null : item.id,
              code: item.extend_slot_code,
              quantity: this.code ? listSlot?.slot_quantity : item.quantity,
              used_percent: listSlot?.use_percent,
              stock_slot_sku: listSlot?.slot_quantity,
              zone_id: listSlot?.zone_id,
              new_value: item.percent ? item.percent : null,
              currentQuantitySlotExport: item.quantity,
            });
          }

          obj.totalQuantity +=
            this.code && listSlot
              ? listSlot.slot_quantity
              : this.code
              ? item.quantity
              : 0;

          obj.stock = 0;
          listProduct.slot.forEach((el) => {
            if (el.imExportCode == code && el.sku_id == item.sku_id) {
              obj.stock += el.slot_quantity;
            }
          });

          // obj.stock += listSlot ? listSlot.slot_quantity : 0;
        }
      });
  }

  /** close modal */
  closeModalImExport(event = false) {
    this.closeModal.emit(true);
  }
}
