<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center title"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Lịch sử xóa
      </div>

      <div class="flex flex-wrap md:float-right items-center gap-8px margin-t">
        <div class="relative">
          <input
            (keyup)="subject.next($event.target.value)"
            type="text"
            placeholder="Nhập mã phiếu tìm kiếm"
            class="m-auto h-32px w-320px border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />

          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
      </div>
    </div>

    <div class="overflow-x-auto overflow-y-hidden w-full">
      <table
        class="w-full border-separate table-fixed"
        cellspacing="0"
        cellpadding="0"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="md:text-center hidden-col w-40px">#</th>

            <th
              class="text-left col-code-history cursor-pointer"
              (click)="Sort('extend_im_export_code')"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('extend_im_export_code')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Mã phiếu'"
                >
                  Mã phiếu </span
                ><img
                  *ngIf="
                    sort.includes('extend_im_export_code');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-name-history cursor-pointer"
              (click)="Sort('extend_user_name')"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('extend_user_name')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Người xóa '"
                >
                  Người xóa</span
                >
                <img
                  *ngIf="sort.includes('extend_user_name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>

            <th class="text-left col-date-history cursor-pointer">
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Ngày xóa'"
                >
                  Ngày xóa</span
                >
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>

            <th
              class="text-left col-createby-history cursor-pointer"
              (click)="Sort('extend_creator_name')"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('extend_creator_name')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Người lập phiếu'"
                >
                  Người lập phiếu
                </span>
                <img
                  *ngIf="
                    sort.includes('extend_creator_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>

            <th class="text-left col-createat-history cursor-pointer">
              <div class="flex justify-between items-center">
                <span
                  class="text-center overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Kho'"
                >
                  Kho
                </span>
              </div>
            </th>
            <th class="text-left col-imexport-history cursor-pointer">
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'ngày nhập / xuất '"
                >
                  Ngày nhập / xuất
                </span>
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th class="text-left col-stage-history cursor-pointer">
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Trạng thái '"
                >
                  Trạng thái
                </span>
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="totalArray > 0; else noInfo">
          <tr
            *ngFor="let history of array; let i = index"
            class="font-roboto-regular text-14px text-dark-grey"
            style="min-height: 40px"
          >
            <td
              class="md:text-center td-stt overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer"
              data-label="#"
              [tooltip]="(pageIndex - 1) * pageSize + i + 1"
            >
              {{ (pageIndex - 1) * pageSize + i + 1 }}
            </td>
            <td
              data-label="Mã phiếu"
              class="text-left text-14px overflow-hidden whitespace-nowrap overflow-ellipsis td-name"
            >
              <!-- (click)="MobileSort('name')" -->
              <span
                (click)="IExport(history)"
                class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                [tooltip]="
                  history.extend_im_export_code
                    ? history.extend_im_export_code
                    : '--'
                "
              >
                {{
                  history.extend_im_export_code
                    ? history.extend_im_export_code
                    : '--'
                }}</span
              >
            </td>
            <td
              data-label="Người xóa"
              class="text-left text-14px overflow-hidden whitespace-nowrap overflow-ellipsis td-code"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  history.extend_user_name ? history.extend_user_name : '--'
                "
              >
                {{
                  history.extend_user_name ? history.extend_user_name : '--'
                }}</span
              >
            </td>

            <td
              data-label="Ngày xóa"
              class="text-left text-14px td-des overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  history.delete_at
                    ? (history.delete_at | date: 'dd/MM/yyyy HH:mm')
                    : '--'
                "
              >
                {{
                  history.delete_at
                    ? (history.delete_at | date: 'dd/MM/yyyy HH:mm')
                    : '--'
                }}</span
              >
            </td>

            <td
              data-label="Người lập phiếu"
              class="text-left text-14px overflow-hidden whitespace-nowrap overflow-ellipsis td-code"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  history.extend_creator_name
                    ? history.extend_creator_name
                    : '--'
                "
              >
                {{
                  history.extend_creator_name
                    ? history.extend_creator_name
                    : '--'
                }}</span
              >
            </td>

            <td
              data-label="Kho"
              class="text-left text-14px td-des overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  history.extend_inventory_name
                    ? history.extend_inventory_name
                    : '--'
                "
              >
                {{
                  history.extend_inventory_name
                    ? history.extend_inventory_name
                    : '--'
                }}</span
              >
            </td>
            <!-- 3 -->
            <td
              data-label="Ngày nhập / xuất"
              class="text-left text-14px td-des overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              <span
                *ngIf="history.isImExported; else showExportDate"
                class="cursor-pointer"
                [tooltip]="
                  history.receipt_date
                    ? (history.receipt_date | date: 'dd/MM/yyyy HH:mm')
                    : '--'
                "
              >
                {{
                  history.receipt_date
                    ? (history.receipt_date | date: 'dd/MM/yyyy HH:mm')
                    : '--'
                }}
              </span>

              <ng-template #showExportDate>
                <span
                  class="cursor-pointer"
                  [tooltip]="
                    history.issue_date
                      ? (history.issue_date | date: 'dd/MM/yyyy HH:mm')
                      : '--'
                  "
                >
                  {{
                    history.issue_date
                      ? (history.issue_date | date: 'dd/MM/yyyy HH:mm')
                      : '--'
                  }}
                </span>
              </ng-template>
            </td>
            <td
              class="td-thaotac px-12px py-10px text-left text-14px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Trạng thái"
            >
              <!-- Import -->
              <span *ngIf="history.isImExported; else showExport">
                <span *ngIf="history.is_draft; else showImportStatus">
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-grey-label bg-grayscale-light-grey status-style"
                  >
                    Lưu nháp
                  </div>
                </span>
                <ng-template #showImportStatus>
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-green-blue bg-duck-egg-blue status-style"
                  >
                    Đã nhập
                  </div>
                </ng-template>
              </span>
              <ng-template #showExport>
                <span *ngIf="history.is_draft; else showExportStatus">
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-grey-label bg-grayscale-light-grey status-style"
                  >
                    Lưu nháp
                  </div>
                </span>
                <ng-template #showExportStatus>
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-orange-red bg-light-peach status-style"
                  >
                    Đã xuất
                  </div>
                </ng-template>
              </ng-template>
            </td>
          </tr>
        </tbody>
        <ng-template #noInfo>
          <tbody>
            <tr class="font-roboto-regular text-14px h-40px">
              <td colspan="12" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>

    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        (onChangeValue)="onChangeValue($event)"
        [total]="totalArray"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpen"
  [options]="options"
  [title]="title"
  *ngIf="isOpen"
>
  <meu-import-export-readonly
    [ImExport]="ImExport"
    [code]="code"
    (closeModal)="closeModal($event)"
  ></meu-import-export-readonly>
</meu-modal-component>

<meu-modal-popup-bottom
  [isOpen]="isOpenBottom"
  [options]="optionsBottom"
  [title]="title"
  *ngIf="isOpenBottom"
>
  <meu-import-export-readonly
    [ImExport]="ImExport"
    [code]="code"
    (closeModal)="closeModal($event)"
  ></meu-import-export-readonly>
</meu-modal-popup-bottom>
