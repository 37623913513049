<div class="h-full flex justify-center items-center text-14px w-full" #menu>
  <ng-template ngFor let-item [ngForOf]="menuItem" let-i="index">
    <a
      class="relative h-full flex flex-row items-center justify-center"
      [ngStyle]="{ width: menu.offsetWidth / menuItem.length + 'px' }"
      [ngClass]="[
        item.selected
          ? 'bg-cerulean-blue hover:bg-dark-blue'
          : 'bg-primary-dodger-blue hover:bg-light-blue',
        item.submenu ? 'menu' : ''
      ]"
      [routerLink]="item.page.replace('{{inventoryId}}', this.inventorySelected)"
      (click)="onClickMenu(item, i, $event)"
      *ngIf="item.isHasPermission"
    >
      <div
        class="flex py-4px flex-nowrap capitalize relative whitespace-nowrap"
      >
        <img [src]="item.icon" />&nbsp;
        <span
          [ngClass]="item.selected ? 'text-white ' : 'text-grey-lightest'"
          >{{ item.title }}</span
        >
      </div>
      <a class="dropdown absolute top-10 left-0" #dropdown>
        <div
          class="origin-top-right z-10 mt-3 w-192px rounded-b shadow bg-white ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <ng-template ngFor let-subItem [ngForOf]="item.submenu">
            <div
              *ngIf="subItem.isHasPermission"
              class="py-1 flex px-2 hover:bg-lightest-grey"
              [ngClass]="subItem.selected ? 'bg-lightest-grey' : null"
              role="none"
              (click)="submenuClick(subItem, i, $event)"
            >
              <img [src]="item.icon" class="filter brightness-50" />
              <a
                [routerLink]="subItem.page.replace('{{inventoryId}}', this.inventorySelected)"
                class="w-full text-gray-700 block px-2 py-2 text-sm pointer-events-auto"
                tabindex="-1"
                >{{ subItem.title }}</a
              >
            </div>
          </ng-template>
        </div>
      </a>
    </a>
  </ng-template>
</div>
