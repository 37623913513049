import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { StockAvailableService } from './../../../api/stockAvailable.service';
import { MxModalOption } from './../../../shared/components/modal/modal-component/modal-component.component';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { DashboardService } from './../../../api/dashboard.service';
import { selectOptions } from './../../../shared/components/select-dropdown/select-dropdown.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexXAxis,
  ApexLegend,
  ApexStates,
  ApexGrid,
  ApexTitleSubtitle,
  ApexFill,
  ApexStroke,
  ApexTooltip,
  ApexNoData,
  ApexResponsive,
  ApexNonAxisChartSeries,
} from 'ng-apexcharts';
import { User } from 'src/app/model/user';
// type ApexXAxis = {
//   type?: 'category' | 'datetime' | 'numeric';
//   categories?: any;
//   labels?: {
//     style?: {
//       colors?: string | string[];
//       fontSize?: string;
//     };
//   };
// };
// type TooltipAp = {
//   enabled: boolean;
//   shared: boolean;
//   theme?: 'dark' | 'light';
//   x: {
//     show: boolean;
//     formatter: undefined;
//   };
//   y: {
//     formatter: undefined;
//     title: any;
//   };
// };

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  subtitle: ApexTitleSubtitle;
  colors: string[];
  states: ApexStates;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  tooltip: any; //ApexTooltip;
  fill: ApexFill;
  responsive: ApexResponsive;
  noData: ApexNoData;
};

export type ChartTMOptions = {
  seriesTM: ApexAxisChartSeries;
  chartTM: ApexChart;
  dataLabelsTM: ApexDataLabels;
  titleTM: ApexTitleSubtitle;
  plotOptionsTM: ApexPlotOptions;
  legendTM: ApexLegend;
  noData: ApexNoData;
};
export type ChartLineOptions = {
  seriesLine: ApexAxisChartSeries;
  chartLine: ApexChart;
  dataLabelsLine: ApexDataLabels;
  titleLine: ApexTitleSubtitle;
  plotOptionsLine: ApexPlotOptions;
  legendLine: ApexLegend;
  colors: string[];
  strokeLine: ApexStroke;
  gridLine: ApexGrid;
  xaxisLine: ApexXAxis;
  yaxisLine: ApexYAxis;
  tooltipLine: ApexTooltip;
  noData: ApexNoData;
  responsive: ApexResponsive;
};

export type ChartPieOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  plotOptions: ApexPlotOptions;
};

export type ChartNegativeOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  noData: ApexNoData;
};

// declare global {
//   interface Window {
//     Apex: any;
//   }
// }

// window.Apex = {
//   chartTM: {
//     toolbar: {
//       show: false,
//     },
//   },
//   chartLine: {
//     toolbar: {
//       show: true,
//     },
//     tooltip: {
//       shared: false,
//     },
//   },
//   // chart: {
//   //   toolbar: {
//   //     show: false,
//   //   },
//   // },
//   menubar: {
//     show: false,
//   },

//   legend: {
//     show: true,
//     position: 'top',
//   },
// };

@Component({
  selector: 'meu-dashboard-manage',
  templateUrl: './dashboard-manage.component.html',
  styleUrls: ['./dashboard-manage.component.scss'],
})
export class DashboardManageComponent implements OnInit {
  // date selected
  dateSelected = null;
  dateOption: any[] = [
    {
      id: 1,
      value: 'today',
      name: 'Hôm nay',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_today.svg',
    },
    {
      id: 2,
      value: 'this_week',
      name: 'Tuần này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_week.svg',
    },
    {
      id: 3,
      value: 'this_month',
      name: 'Tháng này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_month.svg',
    },
    {
      id: 4,
      value: 'this_quarter',
      name: 'Quý này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_quarter.svg',
    },
    {
      id: 5,
      value: 'this_year',
      name: 'Năm này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_year.svg',
    },
    {
      id: 6,
      value: 'custom',
      name: 'Tùy chọn',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_year.svg',
    },
  ];
  fromDate: any;
  toDate: any;

  optionsList: selectOptions[];
  valueSelected;
  valueDate;

  valueTotal;
  valueImport;
  valueExport;
  valueExpire;
  percentImport;
  percentExport;
  percentExpdate;
  isIncrImport: boolean;
  isIncrExport: boolean;
  isIncrExpdate: boolean;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  dataChart: any;
  dataNotifications: any = [];
  pageIndexLoadMore = 1;
  maxPageIndex = -1;
  totalLoadMore = 0;

  isAdmin: boolean = false;
  inventoryId: string;
  userdInventory$ = 0;
  used_Inventory: number;
  chartPie: any;
  categoriesTimeLine;
  minmaxNegative: number;

  chartData = {
    labels: ['Đã sử dụng', 'Trống'],
    datasets: [
      {
        label: '',
        data: [0, 100],
        backgroundColor: ['#ffcb44', '#ababab'],
        hoverOffset: 1,
        borderWidth: 1,
      },
    ],
  };

  //isCheck
  isOpenFormModalDashBoard: boolean;
  isCheckExpired: boolean;

  //Export Report
  titleModalER = 'Xuất báo cáo';
  isOpenModalER: boolean;
  optionsER: MxModalOption = {
    styleClass: 'w-3/12',
    callBackAfterClose: () => {
      this.isOpenModalER = false;
    },
  };

  //Import
  titleModal = 'Chi tiết phiếu nhập hàng';
  isOpenModal: boolean;
  options: MxModalOption = {
    callBackAfterClose: () => {
      this.imExport_code = '';
      this.isOpenModal = false;
      this.isOpenFormModalDashBoard = false;
    },
  };
  imExport_code: string;

  //Export
  titleModalExport = 'Chi tiết phiểu xuất hàng';
  isOpenModalExport: boolean;
  optionsExport: MxModalOption = {
    callBackAfterClose: () => {
      this.im_Export_code = '';
      this.isOpenModalExport = false;
      this.isOpenFormModalDashBoard = false;
    },
  };
  im_Export_code: string;

  //Data chart
  dataImportLine = [];
  dataExportLine = [];
  dataTreeMap = [];
  dataBar = [];
  dataNegative = [];
  categories = [];
  inNorm = [];
  maxNorm = [];
  minNorm = [];
  outNorm = [];
  belowNorm = [];

  newDate = new Date();

  isMobile: boolean;

  @ViewChild('chart-bar') chart: ChartComponent;
  @ViewChild('char-treemap') chartTM: ChartComponent;
  @ViewChild('chart-line') chartLine: ChartComponent;
  @ViewChild('bar-chart-negative') chartNegative: ChartComponent;
  public chartNegativeOptions: Partial<ChartNegativeOptions> = {
    noData: {
      text: 'Loading...',
    },
    series: [
      {
        name: 'Mức hàng hóa trong định mức',
        data: [],
      },
      {
        name: 'Định mức tồn tối đa',
        data: [],
      },
      {
        name: 'Định mức tồn tối thiểu',
        data: [],
      },

      {
        name: 'Dưới định mức',
        data: [],
      },
      {
        name: 'Vượt định mức',
        data: [],
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: 'bottom',
    },
    colors: ['#09a2e6', '#6cd2ff', '#6cd2ff', '#ffbe3f', '#ffbe3f'],
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '100%',
        columnWidth: '70%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
      colors: ['#fff'],
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -1500,
      max: 1500,
      //tickAmount: 5,
      title: {
        // text: 'Age',
      },
      axisBorder: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(parseInt(val.toString(), 10))) + '';
        },
      },
    },
    xaxis: {
      categories: [],
      axisBorder: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return '';
        },
      },
    },
  };
  public chartOptions: Partial<ChartOptions> = {
    noData: {
      text: 'Không có dữ liệu...',
    },
    grid: {
      show: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'white',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#9de1ff'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    //colors: ['#0890cc'],
    series: [
      {
        name: '',
        data: [],
      },
    ],
    chart: {
      id: 'char-year',
      height: 280,
      type: 'bar',
      //Event
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: false,
        horizontal: true,
        barHeight: '90%',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff'],
        fontSize: '10px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'normal',
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      min: 0,
      //tickAmount: 'dataPoints',
    },
  };
  public chartTMOptions: Partial<ChartTMOptions> = {
    noData: {
      text: 'Không có dữ liệu...',
    },
    seriesTM: [
      {
        data: [],
      },
    ],
    legendTM: {
      show: false,
    },
    chartTM: {
      height: 255,
      type: 'treemap',
      toolbar: {
        show: false,
      },
    },
    dataLabelsTM: {
      enabled: true,
      style: {
        fontFamily: 'Roboto-Regular',
        fontSize: '14px',
      },
      offsetY: -2,
      // formatter: function (text, op) {
      //   return [text, op.value];
      // },
    },
  };
  public chartLineOptions: Partial<ChartLineOptions> = {
    noData: {
      text: 'Không có dữ liệu...',
    },
    seriesLine: [
      {
        name: 'Tốc độ nhập',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Tốc độ xuất',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chartLine: {
      height: 185,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          pan: false,
          zoomin: false,
        },
      },
    },
    legendLine: {
      position: 'top',
    },
    tooltipLine: {
      enabled: true,
      shared: true,
      x: {
        show: false,
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => 'Phiếu ' + seriesName.slice(7, 11),
        },
      },
      theme: 'dark',
    },
    colors: ['#33a7ff', '#ff6623'],
    strokeLine: {
      show: true,
      curve: 'smooth',
      width: 1,
    },
    gridLine: {
      show: false,
    },
    yaxisLine: {
      min: 0,
      tickAmount: 4,
      axisBorder: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(parseInt(val.toString(), 10))) + '';
        },
      },
    },
    xaxisLine: {
      categories: [],
    },
  };

  //charjs
  data: any = [];
  width: number;
  height: number;

  //Notification
  nameUser: string;
  isImExport: boolean;
  codeImEx: string;
  slots: any = [];
  dateTime: string;

  //Expired product
  isOpenExpired: boolean;
  optionModalExpired: MxModalOption = {
    styleClass: 'w-9/12',
    callBackAfterClose: () => {
      this.isCheckExpired = false;
      this.isOpenExpired = false;
    },
  };
  titleModalExpired;
  valueExpired;

  //Imexport modal
  isOpenModalPopUp;
  isImExportAdmin;
  titleModalPopUp;
  optionsPopUp: MxModalOption = {
    styleClass: 'w-9/12',
    callBackAfterClose: () => {
      this.isOpenModalPopUp = false;
    },
  };

  //Rangdate
  isOpenRangeDateModal: boolean = false;
  optionModalRangeDate: MxModalOption = {
    styleClass: 'w-4/12',
    callBackAfterClose: () => {
      this.isOpenRangeDateModal = false;
    },
  };
  dateMobileSelected = 1;

  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private stockAvailable: StockAvailableService,
    private importExportHistoryService: ImportExportHistoryService
  ) {}

  ngOnInit(): void {
    this.queryScreen();
    window.addEventListener('resize', (e) => this.queryScreen());
    this.dateSelected = this.dateOption[0];
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId || params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
        this.getAllDashBoardInventory(this.dateSelected);
      }
    });
  }

  queryScreen() {
    this.isMobile = document.body.offsetWidth < 1014 ? true : false;
  }

  onChangeDate(date) {
    this.dateSelected = date;
    if (date.id == 6) {
      this.isOpenRangeDateModal = true;
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.getAllDashBoardInventory(this.dateSelected);
    }
  }
  getValueDate(event) {
    this.dateSelected = event;
    if (event.id == 6) {
      if (this.isMobile) {
        this.optionModalRangeDate.styleClass = 'w-11/12';
      }
      this.isOpenRangeDateModal = true;
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.getAllDashBoardInventory(this.dateSelected);
    }
  }
  // get All API
  getAllDashBoardInventory(dateSelected?, fromDate?, toDate?) {
    // get revenuInfo
    this.getRevenueInfo(dateSelected, fromDate, toDate);
    // get expire product
    this.getExpiredProduct(dateSelected, fromDate, toDate);
    //get total Import
    this.getTotalImportExport(dateSelected, fromDate, toDate);
    // get time line ImExport
    this.getDataImExTimeLine(dateSelected, fromDate, toDate);
    // get data doughnut chart
    this.getInventoryCapacityStatus();
    // get % product  of category
    this.getPercentProductOfCate(dateSelected, fromDate, toDate);
    // get product of supplier
    this.getProductOfSupplier(dateSelected, fromDate, toDate);
    // get inventory product stock
    this.getInventoryReportStock();
    // get current activity
    this.getRecentActivities();
  }
  getRevenueInfo(dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardRevenueInfoGet(
        this.inventoryId,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((res) => {
        this.valueTotal = res.data.total;
      });
  }
  getExpiredProduct(dateSelected?, fromDate?, toDate?) {
    this.stockAvailable
      .apiStockAvailableInventoryIdExpiryProductGet(
        this.inventoryId,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate,
        '',
        '',
        1,
        1000000
      )
      .subscribe((res) => {
        if (res.success) {
          this.valueExpired = res.data.total;
        }
      });
  }
  getTotalImportExport(dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdStatisticsImExportGet(
        this.inventoryId,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .pipe(debounceTime(5000))
      .subscribe((data) => {
        if (data.success === true) {
          this.valueImport = data.data.total_import;
          this.valueExport = data.data.total_export;
          this.valueExpire = data.data.total_expdate;
          if (data.data.percent_import >= 0) {
            this.isIncrImport = true;
            this.percentImport = data.data.percent_import;
          } else {
            this.isIncrImport = false;
            this.percentImport = data.data.percent_import * -1;
          }
          if (data.data.percent_export >= 0) {
            this.isIncrExport = true;
            this.percentExport = data.data.percent_export;
          } else {
            this.isIncrExport = false;
            this.percentExport = data.data.percent_export * -1;
          }
          if (data.data.percent_expdate >= 0) {
            this.isIncrExpdate = true;
            this.percentExpdate = data.data.percent_expdate;
          } else {
            this.isIncrExpdate = false;
            this.percentExpdate = data.data.percent_expdate * -1;
          }
        }
      });
  }
  getDataImExTimeLine(dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdImportAndExportTimelineGet(
        this.inventoryId,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((data) => {
        if (data.success) {
          this.dataImportLine = data.data.importCount.data;
          this.dataExportLine = data.data.exportCount.data;
          this.changeDateTimeFormat(data.data.date);
          this.updateChartLine();
        }
      });
  }
  getInventoryCapacityStatus() {
    this.dashboardService
      .apiDashboardInventoryCapacityStatusInventoryIdGet(this.inventoryId)
      .subscribe((res) => {
        if (res.success) {
          this.userdInventory$ = res.data.used_percent;
          this.used_Inventory = res.data.used_percent;
        }
        if (this.chartPie) {
          this.chartPie.destroy();
        }
        this.initDoughnutChart();
      });
  }
  getPercentProductOfCate(dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdMostImportsByProductTemplateGet(
        this.inventoryId,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((data) => {
        this.dataTreeMap = data.data;
        this.updateChartTree();
      });
  }
  getProductOfSupplier(dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdMostImportProductsFollowSupplierGet(
        this.inventoryId,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((data) => {
        this.dataBar = data.data;
        this.updateBarChart();
      });
  }
  getInventoryReportStock() {
    this.dashboardService
      .apiDashboardInventoryReportInventoryIdGet(this.inventoryId)
      .subscribe((res) => {
        this.categories = res.data[0].data.slice(0, 20);
        this.inNorm = res.data[1].data.slice(0, 20);
        this.maxNorm = res.data[2].data.slice(0, 20);
        this.minNorm = res.data[3].data.slice(0, 20);
        this.belowNorm = res.data[4].data.slice(0, 20);
        this.outNorm = res.data[5].data.slice(0, 20);
        let a =
          this.checkMaxValue(this.maxNorm) +
          this.checkMaxValue(this.outNorm) +
          this.checkMaxValue(this.inNorm);
        let b =
          this.checkMaxValue(this.minNorm) + this.checkMaxValue(this.belowNorm);
        if (a > b) {
          this.minmaxNegative = a;
        } else {
          this.minmaxNegative = b;
        }
        if (this.inNorm.length < 38) {
          let n = 38 - this.inNorm.length;
          for (let i = 1; i < n; i++) {
            this.inNorm.push(0);
            this.categories.push('');
          }
        }
        this.updateNegativeChart();
      });
  }
  getRecentActivities() {
    this.dashboardService
      .apiDashboardInventoryIdRecentActivitiesImExportGet(
        this.inventoryId,
        '',
        '',
        this.pageIndexLoadMore,
        10
      )
      .subscribe((res) => {
        if (res.success) {
          this.dataNotifications = res.data.collection;
          this.totalLoadMore = res.data.total ? res.data.total : 0;
          if (res.data.total > 10) {
            let div = res.data.total / 10;
            let odd = res.data.total % 10;
            if (odd > 0) {
              div++;
            }
            this.maxPageIndex = div;
          } else {
            this.maxPageIndex = 0;
          }
        }
      });
  }

  loadMoreActiveNotification() {
    this.pageIndexLoadMore++;
    this.dashboardService
      .apiDashboardInventoryIdRecentActivitiesImExportGet(
        this.inventoryId,
        '',
        '',
        this.pageIndexLoadMore,
        10
      )
      .subscribe((res) => {
        if (res.success) {
          this.dataNotifications = res.data.collection;
          this.totalLoadMore = res.data.total ? res.data.total : 0;
          res.data.collection.forEach((element) => {
            this.dataNotifications.push(element);
          });
        }
      });
  }

  //update chart
  updateChartLine() {
    this.resizeObservable$ = fromEvent(window, 'resize');
    if (window.innerWidth < 360) {
      this.chartLineOptions.seriesLine = [
        {
          name: 'Phiếu nhập',
          data: this.dataImportLine,
        },
        {
          name: 'Phiếu xuất',
          data: this.dataExportLine,
        },
      ];
      this.chartLineOptions.xaxisLine = {
        categories: this.categoriesTimeLine,
      };
      this.chartLineOptions.chartLine = {
        height: 185,
        type: 'line',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
            pan: false,
            zoomin: false,
          },
        },
      };
    } else {
      this.chartLineOptions.seriesLine = [
        {
          name: 'Phiếu nhập',
          data: this.dataImportLine,
        },
        {
          name: 'Phiếu xuất',
          data: this.dataExportLine,
        },
      ];
      this.chartLineOptions.xaxisLine = {
        categories: this.categoriesTimeLine,
      };
    }
  }
  updateChartTree() {
    this.chartTMOptions.seriesTM = [
      {
        data: this.dataTreeMap,
      },
    ];
    // if (this.filterDate === 'today') {
    //   this.getDataImExTimeLine(
    //     this.typeFilterDate,
    //     this.inventoryId,
    //     'this_week'
    //   );
    // } else {
    //   this.getDataImExTimeLine(
    //     this.typeFilterDate,
    //     this.inventoryId,
    //     this.filterDate
    //   );
    // }
    // this.getInventoryReport(this.inventoryId);
  }
  updateBarChart() {
    this.chartOptions.series = [
      {
        data: this.dataBar,
      },
    ];
  }
  updateNegativeChart() {
    this.chartNegativeOptions.series = [
      {
        name: 'Mức hàng hóa trong định mức',
        data: this.inNorm,
      },
      {
        name: 'Định mức tồn tối đa',
        data: this.maxNorm,
      },
      {
        name: 'Định mức tồn tối thiểu',
        data: this.minNorm,
      },
      {
        name: 'Dưới định mức',
        data: this.belowNorm,
      },
      {
        name: 'Vượt định mức',
        data: this.outNorm,
      },
    ];
    this.chartNegativeOptions.xaxis = {
      categories: this.categories,
    };
    this.chartNegativeOptions.yaxis = {
      //min: -this.minmaxNegative,
      //max: this.minmaxNegative,
      //tickAmount: 5,
      title: {
        // text: 'Age',
      },
      axisBorder: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(parseInt(val.toString(), 10))) + '';
        },
      },
    };
  }
  initDoughnutChart() {
    let chartElement = document.getElementById('doughnut') as HTMLElement;
    this.chartPie = new Chart(chartElement as HTMLCanvasElement, {
      type: 'doughnut',
      data: this.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            title: {
              font: {
                size: 12,
              },
              color: '#041826',
            },
            labels: {
              boxWidth: 10,
              boxHeight: 10,
            },
          },
        },
      },
    });
    this.chartPie.resize(182, 190);
    this.chartData.datasets[0].data = [
      this.userdInventory$,
      100 - this.userdInventory$,
    ];
    this.chartPie.update();
  }

  //open modal
  openExportReport() {
    if (!this.isOpenModalER) {
      this.isOpenModalER = true;
    }
  }
  openModalExpired() {
    this.titleModalExpired = 'Hàng hóa hết hạn - ' + this.dateSelected.name;
    this.isOpenExpired = true;
    this.isCheckExpired = true;
  }
  openTotalImport() {
    this.isImExportAdmin = 0;
    this.titleModalPopUp = 'Phiếu nhập - ' + this.dateSelected?.name;
    this.isOpenModalPopUp = true;
  }
  openTotalExport() {
    this.isImExportAdmin = 1;
    this.titleModalPopUp = 'Phiếu xuất - ' + this.dateSelected?.name;
    this.isOpenModalPopUp = true;
  }
  openCurrentActivityImEx(data) {
    if (data.isImExport) {
      this.openCurrentImport(data.code);
    } else {
      this.openCurrentExport(data.code);
    }
  }
  openCurrentImport(code) {
    this.imExport_code = code;
    this.isOpenModal = true;
    this.options.styleClass = 'w-11/12';
  }
  openCurrentExport(code) {
    this.im_Export_code = code;
    this.isOpenModalExport = true;
    this.optionsExport.styleClass = 'w-11/12';
  }
  // IE export
  closeModalPopUp(event) {
    if (event) {
      if (event.includes('PN')) {
        this.imExport_code = event;
        this.titleModal = 'Chi tiết phiếu nhập hàng';
        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PSN')) {
        this.imExport_code = event;
        this.titleModal = 'Chi tiết phiếu khai báo';
        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PX')) {
        this.imExport_code = event;
        this.titleModalExport = 'Chi tiết phiếu xuất hàng';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.im_Export_code = event;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PSX')) {
        this.imExport_code = event;
        this.titleModalExport = 'Chi tiết phiếu sang xe';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
      this.isOpenFormModalDashBoard = true;
    }
    if (this.isOpenModalPopUp) {
      this.isOpenModalPopUp = false;
    }
    this.isCheckExpired = false;
  }
  closeExportReport(event) {
    if (this.isOpenModalER) {
      this.isOpenModalER = false;
    }
  }
  closeRangeDateModal(event) {
    this.fromDate = event.dateStart;
    this.toDate = event.dateEnd;
    this.isOpenRangeDateModal = false;
    this.getAllDashBoardInventory('', this.fromDate, this.toDate);
  }
  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      this.imExport_code = '';
      if (this.isCheckExpired) {
        this.isOpenExpired = event.isCloseModal;
      } else {
        this.isOpenModalPopUp = event.isCloseModal;
      }
    }
    if (this.isOpenFormModalDashBoard) {
      this.isOpenFormModalDashBoard = false;
    }
  }
  closeModalExport(event) {
    if (this.isOpenModalExport) {
      this.isOpenModalExport = false;
      this.im_Export_code = '';
      if (this.isCheckExpired) {
        this.isOpenExpired = event.isCloseModal;
      } else {
        this.isOpenModalPopUp = event.isCloseModal;
      }
    }
    if (this.isOpenFormModalDashBoard) {
      this.isOpenFormModalDashBoard = false;
    }
  }
  closeModalExpiredEvent(event) {
    if (event) {
      if (event.includes('PN')) {
        this.imExport_code = event;
        this.titleModal = 'Chi tiết phiếu nhập hàng';
        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PSN')) {
        this.imExport_code = event;
        this.titleModal = 'Chi tiết phiếu khai báo';
        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PX')) {
        this.imExport_code = event;
        this.titleModalExport = 'Chi tiết phiếu xuất hàng';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.im_Export_code = event;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PSX')) {
        this.imExport_code = event;
        this.titleModalExport = 'Chi tiết phiếu sang xe';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
      this.isOpenFormModalDashBoard = true;
    }
    if (this.isOpenExpired) {
      this.isOpenExpired = false;
    }
  }

  //format
  changeDateTimeFormat(array) {
    this.categoriesTimeLine = [];
    array.forEach((item) => {
      this.categoriesTimeLine.push(this.changeFormatDate(item));
    });
  }
  changeFormatDate(date) {
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    return day + '/' + month;
  }
  checkMaxValue(value = []) {
    let temp = 0;
    value.forEach((item) => {
      if (item < 0) {
        item = item * -1;
        if (item >= temp) {
          temp = item;
        }
      }
    });
    return temp;
  }
}
