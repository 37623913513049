import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'meu-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent implements OnInit {

  constructor() { }
  @Input() type: string;
  @Input() message:string;
  @Input() timeOut;
  @Output() close = new EventEmitter;
  ngOnInit(): void {
    
  }

}
