<div class="sm:grid sm:grid-cols-12 px-16px h-auto rounded-4px">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="formcreate">
      <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-5">
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-warm-grey"
              [ngClass]="
                formcreate.get('name').touched &&
                formcreate.controls.name.errors?.required
                  ? 'error-class'
                  : ''
              "
            >
              Tên thư mục phân loại <span class="text-red-light">*</span></span
            >
            <div class="flex items-stretch md:items-center">
              <img class="fol-icon w-20px h-20px ml-5px mt-7px absolute" alt="" src="../../../../assets/icons/product-categories-icons/icon_file_folder.svg">
              <input
                [ngClass]="
                  formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required
                    ? 'class-invalid'
                    : 'class-valid'
                "
                type="text"
                placeholder="Nhập tên thư mục"
                formControlName="name"
                required
                class="w-full border rounded-4px py-8px pl-30px h-35px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                style="line-height: 1.43; letter-spacing: normal;"
              />
            </div>
            
            <span
              class="font-roboto-regular text-12px text-red-light ml-32px"
              *ngIf="
                formcreate.get('name').touched &&
                formcreate.controls.name.errors?.required
              "
            >
              {{
                formcreate.controls.name.errors?.required
                  ? 'Vui lòng nhập tên thư mục'
                  : ''
              }}
            </span>
          </div>
        </div>
        <div
          class="col-span-full flex-row-reverse gap-8px flex h-64px mt-52px pt-8px"
        >
          <button
            class="btn-primary-add"
            [disabled]="!formcreate.valid"
            type="button"
            (click)="setButtonAddOrEdit()"
          >
            {{ nameButton }}
          </button>
          <button class="btn-text-cancel" (click)="closeModal()">
            Hủy
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
