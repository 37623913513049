/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Contract { 
    id?: string;
    code?: string;
    customer_id?: string;
    amount_on_contract?: number;
    status?: string;
    date_signed?: Date;
    date_contract_end?: Date;
    note?: string;
    contract_type_id?: string;
    created_by?: string;
    created_at?: Date;
    modified_by?: string;
    modified_at?: Date;
    date_contract_start?: Date;
    discount?: number;
    actual_amount?: number;
    client_representator_name?: string;
    client_address?: string;
    client_phone_number?: string;
    client_tax_code?: string;
    client_bank_info?: string;
    client_representator_position?: string;
    client_representator_id_number?: string;
    client_representator_issue_date?: string;
    client_representator_issue_place?: string;
    contract_template_id?: string;
    deposit?: number;
    deposit_text?: string;
    client_company_name?: string;
    client_fax?: string;
    liquidation_date?: Date;
    isPaidAsUsed?: boolean;
    inventory_id?: string;
}