import { ContainerService } from './../../../api/container.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { ContainerChargeHistory, ContainerChargeHistoryService } from 'src/app';
import { ToastrService } from 'src/app/core/services';

@Component({
  selector: 'meu-modal-create-container-charge',
  templateUrl: './modal-create-container-charge.component.html',
  styleUrls: ['./modal-create-container-charge.component.scss'],
})
export class ModalCreateContainerChargeComponent implements OnInit {
  @Input() id_conatainer_charge = '';
  @Output() emitContainerCharge = new EventEmitter();

  container_charge: ContainerChargeHistory = {};

  containerChargeForm = new FormGroup({
    container_id: new FormControl(null, [Validators.required]),
    container_number: new FormControl(null, [Validators.required]),
    start_date: new FormControl(null, [Validators.required]),
    end_date: new FormControl(null),
    cost: new FormControl(0, [Validators.pattern('[0-9]+(,[0-9]+)*,?')]),
    status: new FormControl(null),
  });

  //date confing
  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm:ss',
    firstDayOfWeek: 'mo',
    opens: 'right',
    drops: 'down',
  };

  is_draft: any;
  start_date: any;
  end_date: any;
  totalCost: any;

  statusTypeId = null;

  statusType = [
    {
      id: 1,
      name: 'Lưu nháp',
    },
    {
      id: 2,
      name: 'Đã thanh toán',
    },
  ];

  lstContainer = [];
  totalIndex = null;
  constructor(
    private containerChargeHistoryService: ContainerChargeHistoryService,
    private containerService: ContainerService,
    private toastrService: ToastrService
  ) {
    this.getAllContainer();
  }

  ngOnInit(): void {
    if (this.id_conatainer_charge) {
      this.getDetailID();
    } else {
      this.containerChargeForm.controls['status'].setValue(1);
    }
  }

  getAllContainer() {
    this.containerService
      .apiContainerGet('', '', 1, this.totalIndex ? this.totalIndex : 10)
      .subscribe((res) => {
        if (res.success) {
          this.lstContainer = res.data.collection;
          if (!this.totalIndex) {
            this.totalIndex = res.data.total;
            this.getAllContainer();
          }
          return;
        }
      });
  }

  //api
  submit() {
    this.container_charge.container_id = this.containerChargeForm.value.container_id;
    this.container_charge.container_number = this.containerChargeForm.value.container_number;
    this.container_charge.start_date = this.formatDate(
      this.containerChargeForm.value.start_date
    );
    this.container_charge.end_date = this.formatDate(
      this.containerChargeForm.value.end_date
    );
    this.container_charge.cost = parseInt(
      this.containerChargeForm.value.cost.toString().replace(/,/g, '')
    );
    this.container_charge.total_cost = this.totalCost;
    this.container_charge.is_draft =
      this.containerChargeForm.value.status == 1 ? true : false;

    if (this.containerChargeForm.valid) {
      if (this.id_conatainer_charge) {
        this.update();
      } else {
        this.add();
      }
    }
  }

  add() {
    this.containerChargeHistoryService
      .apiContainerChargeHistoryPost(this.container_charge)
      .subscribe((res) => {
        if (res.success) {
          this.emitContainerCharge.emit(true);
          this.toastrService.success('Thao tác thành công');
          return;
        } else {
          this.toastrService.error('Thao tác thất bại');
        }
      });
  }
  update() {
    this.containerChargeHistoryService
      .apiContainerChargeHistoryIdPut(
        this.id_conatainer_charge,
        this.container_charge
      )
      .subscribe((res) => {
        if (res.success) {
          this.emitContainerCharge.emit(res.success);
          this.toastrService.success('Thao tác thành công');
          return;
        } else {
          this.toastrService.error('Thao tác thất bại');
        }
      });
  }

  getDetailID() {
    this.containerChargeHistoryService
      .apiContainerChargeHistoryIdGet(this.id_conatainer_charge)
      .subscribe((res) => {
        if (res.success) {
          this.container_charge = res.data;
          this.containerChargeForm.patchValue({
            container_number: res.data.container_number,
            start_date: moment(res.data.start_date).format(
              'DD/MM/YYYY hh:mm:ss '
            ),
            end_date: moment(res.data.end_date).format('DD/MM/YYYY hh:mm:ss '),
            cost: res.data.cost || 0,
            status: res.data.is_draft ? 1 : 2,
          });
          this.countHours(
            this.formatDate(this.containerChargeForm.value.start_date),
            this.formatDate(this.containerChargeForm.value.end_date)
          );
        }
      });
  }

  //Emit data
  EmitContainerCharge() {}

  //format Date
  formatDate(date) {
    if (date) {
      let day = date.slice(0, 2);
      let month = date.slice(3, 5);
      let year = date.slice(6, 10);
      let hours = date.slice(11, 13);
      let minutes = date.slice(14, 16);
      let seconds = date.slice(17, 18);
      let res =
        year +
        '/' +
        month +
        '/' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds;
      return new Date(res);
    }
  }

  // //get dateStart , dateEnd
  getDateStartEnd(data = null) {
    this.countHours(
      this.formatDate(this.containerChargeForm.value.start_date),
      this.formatDate(this.containerChargeForm.value.end_date)
    );
  }

  //Change number currency
  onChangeValueCurrency(field: string, e: any): void {
    const cost = this.containerChargeForm.value;
    if (isNaN(e.key) && e.key != 'Backspace' && e.key != 'Delete') {
      const currency_cost = cost[`${field}`].toString().replace(/,/g, '');
      // .replace(e.key, '');
      this.containerChargeForm.controls[`${field}`].setValue(currency_cost);
      return;
    }

    this.countHours(
      this.formatDate(this.containerChargeForm.value.start_date),
      this.formatDate(this.containerChargeForm.value.end_date)
    );
  }

  //Cpunt Hour
  countHours(start_date, end_date) {
    var diff = this.getDataDiff(new Date(start_date), new Date(end_date));
    var totalHours = diff['hour'] + diff['day'] * 24;
    if (diff['minute'] > 15) {
      totalHours += 1;
    }

    this.totalCost =
      parseInt(
        this.containerChargeForm.value.cost.toString().replace(/,/g, '')
      ) * (totalHours ? totalHours : 1);
  }
  getDataDiff(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - days * 24;
    var minutes =
      Math.floor(diff / (60 * 1000)) - (days * 24 * 60 + hours * 60);
    var seconds =
      Math.floor(diff / 1000) -
      (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60);
    //minute: minutes, second: seconds
    return { day: days, hour: hours, minute: minutes };
  }

  onChangeContainer(event) {
    this.containerChargeForm.controls['container_number'].setValue(
      event.number
    );
  }
}
