<div class="px-10px">
    <div class="mb-10px">
        <!-- <div class="font-roboto-medium font-medium text-dark-grey text-16px leading-7 not-italic pt-10px pb-5px">
            Danh sách phiếu nhập
        </div> -->
        <div class="flex gap-5 mt-5px">
            <div>
                <div class="font-roboto-regular text-14px text-warm-grey">
                    Từ:
                </div>
                <input 
                    type="text" 
                    ngxDaterangepickerMd
                    [(ngModel)]="selectedStart"
                    (change)="changeDateStart($event)"
                    [closeOnAutoApply]="true"
                    [autoApply]="true"
                    [singleDatePicker]="true"
                    [locale]="{format: 'DD/MM/YYYY'}"
                    class="form-control class-valid cursor-pointer w-full border-b border-grey-light rounded-4px py-6px px-12px pr-8px h-30px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"/>
            </div>
            <div>
                <div class="font-roboto-regular text-14px text-warm-grey">
                    Đến:
                </div>
                <input 
                    type="text" 
                    ngxDaterangepickerMd 
                    [(ngModel)]="selectedEnd"
                    (change)="changeDateEnd($event)"
                    [closeOnAutoApply]="true"
                    [autoApply]="true"
                    [singleDatePicker]="true"
                    [locale]="{format: 'DD/MM/YYYY'}"
                    class="form-control class-valid cursor-pointer w-full border-b border-grey-light rounded-4px py-6px px-12px pr-8px h-30px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"/>
            </div>
        </div>
        <div class="flex border-b border-warm-grey p-3px w50 mt-10px hover:border-primary-blue focus:outline-none focus:ring-primary-blue">
            <img
                class="w-18px h-18px"
                src="assets/icons/handle-icons/Icons_search.svg"
            />
            <input 
                type="text"
                placeholder="Nhập tìm kiếm..."
                class="ml-5px outline-none border-none focus:outline-none"
                (keyup)="keyUp.next($event.target.value)"
            >
        </div>
    </div>
    <div class="w-full overflow-x-auto max-h-270px" style="overflow-y: auto">
        <table
            class="w-full border-separate table-fixed"
            cellpadding="0"
            cellspacing="0"
        >
            <thead >
                <tr class="bg-lightest-grey font-roboto-medium text-14px leading-4 overflow-hidden text-grey-med p-5px">
                    <th class="text-left pl-10px">Mã phiếu nhập</th>
                    <th class="col-name text-left pl-10px ">Tên hàng hóa</th>
                    <th class="col-container text-left pl-10px ">Số container</th>
                    <th class="col-select text-center p-10px border-warm-grey">Chọn</th>
                </tr>
            </thead>
            <tbody class="font-roboto-regular">
                <tr style="min-height: 40px !important" *ngFor="let item of lstImportHis;let i = index">
                    <td class="text-left pl-10px border-b border-warm-grey overflow-ellipsis" [tooltip]="item.imExportCode">{{item.imExportCode}}</td>
                    <td class="text-left pl-10px border-b border-warm-grey overflow-ellipsis" [tooltip]="item.product_name">{{item.product_name}}</td>
                    <td class="text-left pl-10px border-b border-warm-grey overflow-ellipsis" [tooltip]="item.container_code">{{item.container_code}}</td>
                    <td class="text-center p-10px border-b border-warm-grey">
                        <input 
                            type="checkbox" 
                            name="{{item.imExportCode}}" 
                            (change)="onChange()"
                            [(ngModel)]="item.isSelected"
                        >

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="flex flex-row-reverse my-10px">
        <button
            class="btn-primary-add"
            type="button"
            (click)="submit()"
            [disabled]="!hasSelected"
            >
            Xác nhận
        </button>
        <button class="btn-text-cancel" (click)="closeModalFilter(null)">Hủy</button>
    </div>
</div>
