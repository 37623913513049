import { ToastrService } from './../../../core/services/toastr.service';
import { ProductTemplateService } from 'src/app/api/productTemplate.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductTemplate } from 'src/app';
import { ProductTemplateTreeService } from 'src/app/api/productTemplateTree.service';

@Component({
  selector: 'meu-product-categories-create',
  templateUrl: './product-categories-create.component.html',
  styleUrls: ['./product-categories-create.component.scss'],
})
export class ProductCategoriesCreateComponent implements OnInit {
  @Input() id_product: string;
  @Input() id_parent_product: string;
  @Input() node_current: any;
  @Output() closeModalProduct = new EventEmitter();

  title: string;
  nameButton: string;
  productTemplate: ProductTemplate = {};
  nameParentProduct: string;

  constructor(
    private toastrService: ToastrService,
    private productCategoriesService: ProductTemplateService,
    private productTemplateTreeService: ProductTemplateTreeService
  ) {}

  formcreate = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    code: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    description: new FormControl('', Validators.maxLength(300)),
  });

  ngOnInit(): void {
    this.setModalAddOrEdit();
  }
  setModalAddOrEdit() {
    console.log('node nhan duoc------------', this.node_current);
    this.getParent();
    if (this.id_product) {
      this.title = 'Chỉnh sửa phân loại';
      this.nameButton = 'Lưu';
      this.getProductID();
    } else {
      this.title = 'Thêm mới phân loại';
      this.nameButton = 'Thêm';
    }
  }
  setButtonAddOrEdit() {
    if (this.id_product) {
      this.updateProductID();
    } else {
      this.addProductCategories();
    }
  }

  addProductCategories() {
    //console.log('API POST');
    //console.log(this.id_parent_product);
    this.productTemplate.name = this.formcreate.controls['name'].value.trim();
    this.productTemplate.code = this.formcreate.controls['code'].value.trim();
    if (!this.productTemplate.name) {
      //console.log('value rỗng');
      this.productTemplate.name = null;
    }
    if (!this.productTemplate.code) {
      this.productTemplate.code = null;
    }
    this.productTemplate.description = this.formcreate.controls[
      'description'
    ].value.trim();
    //this.productTemplate.treeId = this.formcreate.controls['tree_id'].value;
    this.productTemplate.tree_id = this.id_parent_product;
    console.log('data', this.productTemplate);
    this.productCategoriesService
      .apiProductTemplatePost(this.productTemplate)
      .subscribe((data) => {
        if (data.success == true) {
          //console.log(data);
          this.closeModal(data);
        } else {
          //console.log(data);
          this.toastrService.error(data.message);
        }
      });
  }

  getProductID() {
    this.productCategoriesService
      .apiProductTemplateIdGet(this.id_product)
      .subscribe((data) => {
        console.log('du lieu chinh sua', data);
        if (data.success == true) {
          (this.productTemplate = data.data),
            this.formcreate.controls['description'].setValue(
              this.productTemplate.description
            );
          this.formcreate.controls['name'].setValue(this.productTemplate.name);
          this.formcreate.controls['code'].setValue(this.productTemplate.code);
        } else {
          alert('Fail to get data');
        }
      });
  }

  getParent() {
    if (this.node_current.parent_id) {
      this.productTemplateTreeService
        .apiProductTemplateTreeIdGet(this.node_current.parent_id)
        .subscribe((data) => {
          if (data.success == true) {
            //console.log('data------------------------', data.data.name);
            this.nameParentProduct = data.data.name;
          }
        });
    } else {
      this.nameParentProduct = 'Tất cả phân loại';
    }
  }

  updateProductID() {
    this.productTemplate.name = this.formcreate.controls['name'].value.trim();
    this.productTemplate.code = this.formcreate.controls['code'].value.trim();
    if (!this.productTemplate.name) {
      //console.log('value rỗng');
      this.productTemplate.name = null;
    }
    if (!this.productTemplate.code) {
      this.productTemplate.code = null;
    }
    this.productTemplate.description = this.formcreate.controls[
      'description'
    ].value.trim();
    this.productCategoriesService
      .apiProductTemplateIdPut(this.productTemplate.id, this.productTemplate)
      .subscribe((data) => {
        if (data.success == true) {
          this.closeModal(data);
        } else {
          this.closeModal(data);
        }
      });
  }

  closeModal(data = null) {
    if (data) {
      this.closeModalProduct.emit(data);
      return;
    }
    this.closeModalProduct.emit();
  }

  onChangeCode(searchValue: string): void {
    searchValue = searchValue.replace(/ /g, '_').toUpperCase();
    this.formcreate.controls['code'].setValue(searchValue);
  }
}
