import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';
import { CustomHttpUrlEncodingCodec } from 'src/app/encoder';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'meu-control-select-search',
  templateUrl: './control-select-search.component.html',
  styleUrls: ['./control-select-search.component.scss'],
})
export class ControlSelectSearchComponent implements OnInit {
  @Input() placeholder: string;
  @Input() defaultID: string; // default id
  @Input() id_get_api: string;
  @Input() nameAPI: string;
  @Input() columnName: string; // column to display
  @Input() customColumn: string = '';
  @Input() invalidState: boolean; // set validator
  @Input() is_border: boolean = true; // border
  @Input() is_radius: boolean = true;
  @Input() formControl: FormControl;
  @Input() is_readonly: boolean = false;
  @Input() styleCssWidth: string = '';
  @Input() styleCssPosition: string = '';
  @Input() float: string = '';
  @Output() changeItem = new EventEmitter();

  array: any;

  /// new code
  public isOpen: boolean = false;
  public total: number;
  public defaultItem;
  public keyUp = new Subject<KeyboardEvent>();
  // public selectCtrl: FormControl = new FormControl();
  selectCtrl = new FormGroup({
    id: new FormControl(null),
    current_quantity: new FormControl(),
    data: new FormControl(),
    name: new FormControl(null),
    default: new FormControl(null),
  });
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nameAPI != undefined && changes.id_get_api != null) {
      this.runAPI(this.nameAPI, this.id_get_api);
    }
    if (changes.id_get_api != undefined && changes.id_get_api != null) {
      this.runAPI(this.nameAPI, this.id_get_api);
    }
    // if (changes.defaultID && changes.id_get_api != null) {
    //   this.runAPI(this.nameAPI, this.id_get_api);
    // }
  }

  ngOnInit(): void {
    this.selectCtrl.valueChanges.subscribe((res) => {
      this.emitChangeValue(this.selectCtrl.value);
    });
    this.search();
  }
  onChangeItem(event) {
    this.selectCtrl.patchValue({
      id: event?.id,
      current_quantity: event?.current_quantity,
      name: event[`${this.columnName}`],
    });
  }

  //API
  runAPI(nameAPI, id) {
    if (this.id_get_api == null || this.id_get_api == '') {
      return;
    }
    if (nameAPI == null || nameAPI.trim() == '') {
      return;
    }
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>(
        'get',
        `${environment.backendhost + nameAPI}/${encodeURIComponent(
          String(id)
        )}`,
        {
          withCredentials: this.configuration.withCredentials,
          headers: headers,
        }
      )
      .subscribe((res) => {
        if (res.success) {
          this.array = [...res.data];

          if (this.array && this.defaultID) {
            var defaultItem = this.array.find((x) => x.id == this.defaultID);
            this.selectCtrl.patchValue({
              id: defaultItem?.id,
              current_quantity: defaultItem?.current_quantity,
              name: defaultItem[`${this.columnName}`],
              default: true,
            });
            this.defaultItem = defaultItem?.[`${this.columnName}`];
          } else {
            this.defaultItem = null;
          }

          this.changeDetectorRef.detectChanges();
        }
      });
  }
  emitChangeValue(event) {
    this.changeItem.emit(event);
  }
  closeOutside() {
    this.isOpen = false;
    //search
  }
  chooseItem(event) {
    if (event) {
      this.selectCtrl.patchValue({
        id: event?.id,
        name: event ? event[`${this.columnName}`] : null,
        data: event ? event : null,
        current_quantity: event?.current_quantity,
        default: false,
      });
      if (this.formControl) {
        this.formControl.setValue(event.id);
      }
      if (this.customColumn) {
        this.defaultItem =
          event?.[`${this.customColumn}`] +
          ' | ' +
          event?.[`${this.columnName}`];
      } else {
        this.defaultItem = event?.[`${this.columnName}`];
      }
    }
    this.closeOutside();
  }
  open() {
    if (!this.is_readonly) {
      this.isOpen = true;
      this.runAPI(this.nameAPI, this.id_get_api);
    }
  }
  search() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value: any) => {});
  }
}
