import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/api/api';
import { ToastrService } from 'src/app/core/services';
import { Product } from 'src/app/model/models';

@Component({
  selector: 'meu-product-detail-manage',
  templateUrl: './product-detail-manage.component.html',
  styleUrls: ['./product-detail-manage.component.scss'],
})
export class ProductDetailManageComponent implements OnInit {
  public idProduct: string;
  product: Product = {};

  idIventory: string;
  urlDetail: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private toastService: ToastrService
  ) {}
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId || params.inventoryId != 'null') {
        this.idIventory = params.inventoryId;
        this.urlDetail =
          '/inventory/' + this.idIventory + '/product-management/';
      }
      if (params.id) {
        this.idProduct = params.id;
      }

      this.getDetail();
    });
  }
  onActivate(event) {
    if (event.checkUpdate) {
      event.checkUpdate.subscribe((data) => {
        if (data === true) {
          this.getDetail();
        }
      });
    }
  }

  getDetail() {
    this.productService
      .apiProductGet(`id==${this.idProduct}`)
      .subscribe((res) => {
        if (res.success == true) {
          this.product = res.data.collection[0];
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
}
