<div class="flex justify-between px-10px py-10px">
  <span>title</span>
  <button
    type="button"
    class="pull-right focus:outline-none"
    aria-label="Close"
    (click)="closeModal($event)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
