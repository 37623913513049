import { debounceTime, map } from 'rxjs/operators';
import { ImportExportHistoryService } from './../../../api/importExportHistory.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'meu-filter-import-modal',
  templateUrl: './filter-import-modal.component.html',
  styleUrls: ['./filter-import-modal.component.scss'],
})
export class FilterImportModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() customerID;
  @Input() contractID;
  @Input() selectedCode;
  @Input() isTransferCar;

  lstImportHis = [];
  filterCode = '';
  checkCode;
  public keyUp = new Subject<KeyboardEvent>();

  tempStart = moment().subtract(14, 'days').startOf('day').format('YYYY-MM-DD');
  tempEnd = moment().format('YYYY-MM-DD');

  selectedStart: any = {
    startDate: moment().subtract(14, 'days').startOf('day'),
    endDate: moment().subtract(14, 'days').startOf('day'),
  };
  selectedEnd: any = { startDate: moment(), endDate: moment() };

  hasSelected = false;

  constructor(private importExportHistoryService: ImportExportHistoryService) {}

  ngOnInit(): void {
    this.getAllCode();
    this.search();
  }

  getAllCode() {
    this.importExportHistoryService
      .apiImportExportHistoryGetAllCodeGet(
        this.customerID,
        '',
        new Date(this.tempStart),
        new Date(this.tempEnd),
        'is_checked==true,imExportCode|product_name|container_code@=' +
          this.filterCode,
        '',
        1,
        10000
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstImportHis = [];
          let temp = JSON.parse(JSON.stringify(res.data.collection));
          let codeImport;
          if (this.isTransferCar === true) {
            codeImport = 'PSN';
          } else {
            codeImport = 'PN';
          }
          temp.forEach((res) => {
            if (res.imExportCode.includes(codeImport)) {
              let obj = {
                imExportCode: res.imExportCode,
                product_name: res.product_name,
                container_code: res.container_code,
                product_id: res.product_id,
                isSelected: false,
              };
              this.lstImportHis.push(obj);
            }
          });

          if (this.selectedCode.length > 0) {
            this.selectedCode.forEach((element) => {
              element.isSelected = false;
            });
            let old = new Set(
              this.selectedCode.map(({ imExportCode }) => imExportCode)
            );
            let result = this.lstImportHis.filter(
              ({ imExportCode }) => !old.has(imExportCode)
            );
            this.lstImportHis = result;
          }
        }
      });
  }
  submit() {
    let tempObj = [];
    this.lstImportHis.forEach((res) => {
      if (res.isSelected) {
        tempObj.push(res);
      }
    });
    this.closeModalFilter(tempObj);
  }
  onChange() {
    let result = this.lstImportHis.filter((element) => element.isSelected);
    if (result.length > 0) {
      this.hasSelected = true;
    } else {
      this.hasSelected = false;
    }
  }
  //new search, delay for spam
  search() {
    this.keyUp.pipe(debounceTime(1200)).subscribe((res) => {
      this.filterCode = res.toString();
      this.getAllCode();
    });
  }
  changeDateStart(event) {
    if (event.startDate) {
      this.tempStart = moment(event.startDate).format('YYYY-MM-DD');
      this.getAllCode();
    }
  }
  changeDateEnd(event) {
    if (event.startDate) {
      this.tempEnd = moment(event.startDate).format('YYYY-MM-DD');
      this.getAllCode();
    }
  }
  closeModalFilter(res) {
    this.closeModal.emit(res);
  }
}
