<div class="sm:grid sm:grid-cols-12 py-8px px-16px h-auto rounded-4px">
    <div class="sm:col-span-full mt-20px w-auto flex items-stretch">
      <p class="text-grey-light text-14px flex items-stretch w-auto">{{nameParentProduct}}</p>
         <img src="../../../../assets/icons/product-categories-icons/u_angle-right.svg" alt="">
         <p class="text-grey-light text-14px  inline">
          <b class="text-black">{{node_current.name}}</b></p>
    </div>
    <div class="sm:col-span-full  mt-24px">
      <form [formGroup]="formcreate">
        <div class="w-full flex flex-col  sm:grid sm:grid-cols-12 gap-4">
          <div class="sm:col-span-6">
            <div class="flex flex-col">
              <span 
                class="font-roboto-regular text-12px text-warm-grey"
                [ngClass]="
                  formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required ||
                  formcreate.controls.name.errors?.maxlength
                  ?'error-class':''
                "
              >
                Tên phân loại hàng <span class="text-red-light">*</span></span
              >
              <input
                [ngClass]="
                  formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required ||
                  formcreate.controls.name.errors?.maxlength
                  ?'class-invalid':'class-valid'
                "
                type="text"
                placeholder="Nhập tên phân loại hàng"
                formControlName="name"
                required
                class="w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px pl-12px h-40px focus:ring-1 focus:ring-primary-blue mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
              />
              <span
                class="font-roboto-regular text-12px text-red-light ml-15px"
                *ngIf="
                  (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                  formcreate.controls.name.errors?.maxlength
                "
              >
                {{
                  formcreate.controls.name.errors?.required
                    ? 'Vui lòng nhập tên phân loại hàng'
                    : 'Tên hàng hóa tối đa 100 ký tự'
                }}
              </span>
            </div>
          </div>
          <div class="sm:col-span-6">
            <div class="flex flex-col">
              <span 
                class="font-roboto-regular text-12px text-warm-grey"
                [ngClass]="
                  formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required ||
                  formcreate.controls.code.errors?.maxlength
                  ?'error-class':''
                "
                >
                Mã phân loại hàng <span class="text-red-light">*</span></span
              >
              <input
                [ngClass]="
                formcreate.get('code').touched &&
                formcreate.controls.code.errors?.required ||
                formcreate.controls.code.errors?.maxlength
                ?'class-invalid':'class-valid'
                "
                type="text"
                placeholder="Nhập mã phân loại hàng"
                formControlName="code"
                required
                class="w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px pl-12px h-40px focus:ring-1 focus:ring-primary-blue mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
              />
              <span
                class="font-roboto-regular text-12px text-red-light ml-15px"
                *ngIf="
                  formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required ||
                  formcreate.controls.code.errors?.maxlength
                "
              >
                {{
                  formcreate.controls.code.errors?.required
                    ? 'Vui lòng nhập mã phân loại hàng'
                    : 'Mã hàng hóa tối đa 50 ký tự'
                }}
              </span>
            </div>
          </div>
          <div class="sm:col-span-full">
            <div class="flex flex-col">
              <span class="font-roboto-regular text-12px text-warm-grey"
                [ngClass]="
                formcreate.get('description').touched &&
                formcreate.controls.description.errors?.maxlength?'error-class':''
                "
                >Mô tả</span
              >
              <textarea
                [ngClass]="
                formcreate.get('description').touched &&
                formcreate.controls.description.errors?.maxlength?'class-invalid':'class-valid'
                "
                placeholder="Nhập mô tả"
                formControlName="description"
                class="w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px pl-12px focus:ring-1 focus:ring-primary-blue mt-12px h-100px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
              ></textarea>
              <span
              class="font-roboto-regular text-12px text-red-light ml-15px"
              *ngIf="
                formcreate.get('description').touched &&
                formcreate.controls.description.errors?.maxlength
              "
            >
              {{
                formcreate.controls.description.errors?.maxlength
                  ? 'Mô tả hàng hóa tối đa 300 ký tự'
                  : ''
              }}
            </span>
            </div>
          </div>
          <div
            class="col-span-full flex-row-reverse gap-8px flex h-64px mt-52px pt-8px"
          >
            <button
              class="btn-primary-add"
              [disabled]="!formcreate.valid"
              type="button"
              (click)="setButtonAddOrEdit()"
            >
              {{ nameButton }}
            </button>
            <button class="btn-text-cancel w-57px h-40px rounded-4px text-primary-blue"  (click)="closeModal()" >
              Hủy
            </button>     
          </div>
        </div>
      </form>
    </div>
  </div>
