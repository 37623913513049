import { ToastrService } from './../../../core/services/toastr.service';
import { ExportService } from './../../../api/export.service';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { UserService } from './../../../api/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from 'src/app/model/user';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { InventoryService } from 'src/app';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'meu-export-report-manage',
  templateUrl: './export-report-manage.component.html',
  styleUrls: ['./export-report-manage.component.scss'],
})
export class ExportReportManageComponent implements OnInit {
  Customers: User;
  lstInventory = [];
  typeReport = [
    {
      id: 1,
      full_name: 'Báo cáo xuất nhập tồn',
    },
    {
      id: 2,
      full_name: 'Báo cáo nhập kho',
    },
    {
      id: 3,
      full_name: 'Báo cáo xuất kho',
    },
    {
      id: 4,
      full_name: 'Báo cáo sản phẩm theo ô',
    },
    {
      id: 5,
      full_name: 'Báo cáo phí nhập',
    },
    {
      id: 6,
      full_name: 'Báo cáo phí xuất',
    },
    {
      id: 7,
      full_name: 'Báo cáo phí tải',
    },
    {
      id: 8,
      full_name: 'Báo cáo phí xuất hàng tháng',
    },
    {
      id: 9,
      full_name: 'Báo cáo theo người nhận',
    },
  ];
  typeReportInventory = [
    {
      id: 1,
      full_name: 'Báo cáo theo kho',
    },
    {
      id: 2,
      full_name: 'Báo cáo nhập kho',
    },
    {
      id: 3,
      full_name: 'Báo cáo xuất kho',
    },
    {
      id: 4,
      full_name: 'Báo cáo sản phẩm theo ô',
    },
    {
      id: 5,
      full_name: 'Báo cáo kho hàng ngày',
    },
    {
      id: 6,
      full_name: 'Báo cáo tồn kho theo lô',
    },
  ];
  lstReport = [
    {
      id: 1,
      full_name: 'Báo cáo theo khách hàng',
    },
    {
      id: 2,
      full_name: 'Báo cáo theo kho hàng',
    },
  ];
  @Output() closeModalExport = new EventEmitter();
  @Input() idUser;
  isDisable: boolean;
  isChooseInventory: boolean = true;

  formExport = new FormGroup({
    report_name: new FormControl(2),
    type: new FormControl(1),
    name: new FormControl(),
    inventory: new FormControl(),
    start: new FormControl(moment()),
    end: new FormControl(moment()),
  });

  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm',
    firstDayOfWeek: 'mo',
    opens: 'right',
    drops: 'up',
    closeOnSelect: true,
  };
  datePickerConfigEnd = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm',
    firstDayOfWeek: 'mo',
    opens: 'right',
    drops: 'up',
    closeOnSelect: true,
  };

  countChangeDate = 0;

  constructor(
    private userService: UserService,
    private exportService: ExportService,
    private toastrService: ToastrService,
    private inventoryService: InventoryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllUser();
    this.getAllInventory();
    if (this.idUser) {
      this.formExport.controls['name'].setValue(this.idUser);
      this.isDisable = false;
    } else {
      let userID = JSON.parse(localStorage.getItem('userInfo')).id;
      this.formExport.controls['name'].setValue(userID);
    }
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId || params.inventoryId != 'null') {
        //this.formExport.controls['inventory'].setValue(params.inventoryId);
      }
    });
  }
  getDateTime(event) {
    this.countChangeDate++;
  }

  reverseDate(date) {
    let day = date.slice(0, 2);
    let month = date.slice(3, 5);
    let year = date.slice(6, 10);
    let hours = date.slice(11, 13);
    let minute = date.slice(14, 16);
    let res =
      year + '-' + month + '-' + day + 'T' + hours + ':' + minute + ':00';
    return res;
  }

  submit() {
    let start: Date;
    let end: Date;
    let startTemp = this.formExport.controls['start'].value;
    let endTemp = this.formExport.controls['end'].value;
    if (startTemp && endTemp) {
      start = new Date(this.reverseDate(startTemp));
      end = new Date(this.reverseDate(endTemp));
    }

    var blobExportReport: Blob;

    let report = this.formExport.controls['report_name'].value;

    if (report === 1) {
      if (this.formExport.controls['type'].value === 1) {
        this.exportService
          .apiExportInventoryOfCustomerCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoXuấtNhậpTồn (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (this.formExport.controls['type'].value === 2) {
        this.exportService
          .apiExportReceiptStatamentCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoNhậpKho (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (this.formExport.controls['type'].value === 3) {
        this.exportService
          .apiExportExportStatamentCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoXuấtKho (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (this.formExport.controls['type'].value === 4) {
        this.exportService
          .apiExportProductsInCellsByCustomerCustomerIdPost(
            this.formExport.controls['name'].value
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoSảnPhẩmTheoÔ (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (this.formExport.controls['type'].value === 5) {
        this.exportService
          .apiExportInLoadFeeReportCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(blobExportReport, 'BaoCao.xlsx');
            }
          });
      } else if (this.formExport.controls['type'].value === 6) {
        this.exportService
          .apiExportOutLoadFeeReportCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(blobExportReport, 'BaoCao.xlsx');
            }
          });
      } else if (this.formExport.controls['type'].value === 7) {
        this.exportService
          .apiExportLoadFeeReportCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(blobExportReport, 'BaoCao.xlsx');
            }
          });
      } else if (this.formExport.controls['type'].value === 8) {
        this.exportService
          .apiExportServiceCostsByMonthCustomerIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(blobExportReport, 'BaoCao.docx');
            }
          });
      } else if (this.formExport.controls['type'].value === 9) {
        this.exportService
          .apiExportExportNoteByReceiverReceiverIdPost(
            this.formExport.controls['name'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BaoCaoTheoNguoiNhan(' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      }
    } else {
      let typeInven = this.formExport.controls['type'].value;
      if (typeInven === 1) {
        this.exportService
          .apiExportInventoryPost(
            this.formExport.controls['inventory'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoTheoKho (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (typeInven === 2) {
        this.exportService
          .apiExportReceiptStatamentReportByInventoryPost(
            this.formExport.controls['inventory'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoNhậpKho (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (typeInven === 3) {
        this.exportService
          .apiExportExportStatamentReportByInventoryPost(
            this.formExport.controls['inventory'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoXuấtKho (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (typeInven === 4) {
        this.exportService
          .apiExportProductsInCellsByInventoryPost(
            this.formExport.controls['inventory'].value
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoSảnPhẩmTheoÔ (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (typeInven === 5) {
        this.exportService
          .apiExportImportExportDailyReportPost(
            this.formExport.controls['inventory'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoKhoHàngNgày (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      } else if (typeInven === 6) {
        if (this.countChangeDate === 2) {
          start = null;
          end = null;
        }
        this.exportService
          .apiExportSummaryAvailabilityStockReportPost(
            this.formExport.controls['inventory'].value,
            start,
            end
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoTồnKhoTheoLô (' + startTemp + '-' + endTemp + ').xlsx'
              );
            }
          });
      }
    }
  }
  getValueClientContract(data) {
    if (data) {
      this.formExport.controls['name'].setValue(data.id);
    }
  }
  getValueInventory(event) {
    if (event) {
      this.formExport.controls['inventory'].setValue(event.id);
    }
  }
  getValueTypeTemplate(data) {
    if (data) {
      this.formExport.controls['type'].setValue(data.id);
    }
  }
  getValueReport(event) {
    if (event) {
      this.formExport.controls['report_name'].setValue(event.id);
    }
    if (event.id === 1) {
      this.isChooseInventory = false;
    } else {
      this.isChooseInventory = true;
    }
  }
  getAllUser() {
    this.userService
      .apiUsersGet(`extend_user_role@='|CUSTOMER|'`, '', 1, 1000)
      .subscribe((res) => {
        this.Customers = res.data.collection;
      });
  }
  getAllInventory() {
    this.inventoryService
      .apiInventoryGet('', '', 1, 1000000)
      .subscribe((res) => {
        if (res.success) {
          this.lstInventory = res.data.collection;
          let object = {
            name: 'Tất cả các kho',
            id: '',
          };
          this.lstInventory.unshift(object);
          this.formExport.controls['inventory'].setValue('');
        }
      });
  }
  closeModal() {
    this.closeModalExport.emit();
  }
}
