/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { ContainerSellProductHistory } from '../model/containerSellProductHistory';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class ContainerSellProductHistoryService {
 
     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param code 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryByCodeCodeDelete(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryByCodeCodeDelete(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryByCodeCodeDelete(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryByCodeCodeDelete(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (code === null || code === undefined) {
             throw new Error('Required parameter code was null or undefined when calling apiContainerSellProductHistoryByCodeCodeDelete.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('delete',`${this.basePath}/api/containerSellProductHistoryByCode/${encodeURIComponent(String(code))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryCodeGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryCodeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryCodeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryCodeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/containerSellProductHistoryCode`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param code 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryCodePut(code: string, body?: Array<ContainerSellProductHistory>, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryCodePut(code: string, body?: Array<ContainerSellProductHistory>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryCodePut(code: string, body?: Array<ContainerSellProductHistory>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryCodePut(code: string, body?: Array<ContainerSellProductHistory>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (code === null || code === undefined) {
             throw new Error('Required parameter code was null or undefined when calling apiContainerSellProductHistoryCodePut.');
         }
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('put',`${this.basePath}/api/containerSellProductHistory/${encodeURIComponent(String(code))}`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param container_id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryContainerIdGet(container_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryContainerIdGet(container_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryContainerIdGet(container_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryContainerIdGet(container_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (container_id === null || container_id === undefined) {
             throw new Error('Required parameter container_id was null or undefined when calling apiContainerSellProductHistoryContainerIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/containerSellProductHistory/${encodeURIComponent(String(container_id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param filter 
      * @param Filters 
      * @param Sorts 
      * @param Page 
      * @param PageSize 
      * @param fromDate 
      * @param toDate 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryFastdataGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryFastdataGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryFastdataGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryFastdataGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (filter !== undefined && filter !== null) {
             queryParameters = queryParameters.set('filter', <any>filter);
         }
         if (Filters !== undefined && Filters !== null) {
             queryParameters = queryParameters.set('Filters', <any>Filters);
         }
         if (Sorts !== undefined && Sorts !== null) {
             queryParameters = queryParameters.set('Sorts', <any>Sorts);
         }
         if (Page !== undefined && Page !== null) {
             queryParameters = queryParameters.set('Page', <any>Page);
         }
         if (PageSize !== undefined && PageSize !== null) {
             queryParameters = queryParameters.set('PageSize', <any>PageSize);
         }
         if (fromDate !== undefined && fromDate !== null) {
             queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
         }
         if (toDate !== undefined && toDate !== null) {
             queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/containerSellProductHistory/fastdata`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param Filters 
      * @param Sorts 
      * @param Page 
      * @param PageSize 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (Filters !== undefined && Filters !== null) {
             queryParameters = queryParameters.set('Filters', <any>Filters);
         }
         if (Sorts !== undefined && Sorts !== null) {
             queryParameters = queryParameters.set('Sorts', <any>Sorts);
         }
         if (Page !== undefined && Page !== null) {
             queryParameters = queryParameters.set('Page', <any>Page);
         }
         if (PageSize !== undefined && PageSize !== null) {
             queryParameters = queryParameters.set('PageSize', <any>PageSize);
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/containerSellProductHistory`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiContainerSellProductHistoryIdDelete.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('delete',`${this.basePath}/api/containerSellProductHistory/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistoryPost(body?: Array<ContainerSellProductHistory>, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistoryPost(body?: Array<ContainerSellProductHistory>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistoryPost(body?: Array<ContainerSellProductHistory>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistoryPost(body?: Array<ContainerSellProductHistory>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json-patch+json',
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/containerSellProductHistory`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param filter 
      * @param Filters 
      * @param Sorts 
      * @param Page 
      * @param PageSize 
      * @param fromDate 
      * @param toDate 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiContainerSellProductHistorySummaryByContainerGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiContainerSellProductHistorySummaryByContainerGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiContainerSellProductHistorySummaryByContainerGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiContainerSellProductHistorySummaryByContainerGet(filter?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, fromDate?: Date, toDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (filter !== undefined && filter !== null) {
             queryParameters = queryParameters.set('filter', <any>filter);
         }
         if (Filters !== undefined && Filters !== null) {
             queryParameters = queryParameters.set('Filters', <any>Filters);
         }
         if (Sorts !== undefined && Sorts !== null) {
             queryParameters = queryParameters.set('Sorts', <any>Sorts);
         }
         if (Page !== undefined && Page !== null) {
             queryParameters = queryParameters.set('Page', <any>Page);
         }
         if (PageSize !== undefined && PageSize !== null) {
             queryParameters = queryParameters.set('PageSize', <any>PageSize);
         }
         if (fromDate !== undefined && fromDate !== null) {
             queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
         }
         if (toDate !== undefined && toDate !== null) {
             queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/containerSellProductHistory/summaryByContainer`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 
