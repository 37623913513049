<div class="grid w-full h-52px">
  <div class="container grid-contain">
    <div class="col-span-12 flex items-center justify-end">
        <div class="flex" *ngIf="!isPrint">
            <img src="assets/icons/menu-icons/icon_copyright.svg" />
            <label class="text-13px text-warm-grey">2021 MeU Solutions</label>
        </div>
    </div>
  </div>
</div>
