<div class="grid grid-cols-12 mt-8px">
  <div
    class="col-span-12 flex flex-col md:flex-row md:justify-between md:items-center"
  >
    <div>
      <div
        class="pt-10px pb-14px font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Danh sách biểu phí dịch vụ
      </div>
    </div>
    <div>
      <div class="flex flex-wrap items-center gap-8px">
        <div class="flex gap-8px">
          <button
            class="btn-primary-icon mt-10px mb-14px flex items-center gap-4px"
            (click)="open()"
          >
            <img src="assets/icons/handle-icons/icon_add.svg" />
            <span>Thêm dịch vụ</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-12 overflow-x-auto w-full">
    <table
      cellpadding="0"
      cellspacing="0"
      class="w-full border-separate table-fixed"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="col-stt text-center hidden-col pt-12px px-16px pb-8px">
            #
          </th>

          <th
            class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
            (click)="Sort('extend_service_name')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Tên dịch vụ"
                >Tên dịch vụ</span
              >
              <img
                *ngIf="sort.includes('extend_service_name'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>

          <th
            class="text-right col-amount items-center cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Đơn giá"
                >Đơn giá</span
              >
            </div>
          </th>

          <th
            class="text-left col-unit items-center cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Đơn vị"
                >Đơn vị</span
              >
            </div>
          </th>

          <th
            class="text-center col-thaotac pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            Thao tác
          </th>
        </tr>
      </thead>

      <tbody *ngIf="totalArray != 0; else noInfo">
        <tr
          class="font-roboto-regular h-40px"
          *ngFor="let servicecost of allServicesContract; let i = index"
        >
          <td class="td-stt text-center py-10px" data-label="#">
            {{ i + 1 }}
          </td>

          <td
            class="td-name py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Tên dịch vụ"
          >
            <span [tooltip]="servicecost.extend_service_name">
              {{ servicecost.extend_service_name }}</span
            >
          </td>

          <td
            class="td-amount text-right py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Đơn giá"
          >
            <span
              [tooltip]="servicecost.extend_service_amount|meucurrency"
            >
              {{ servicecost.extend_service_amount|meucurrency }}</span
            >
          </td>

          <td
            class="td-unit py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Đơn vị"
          >
            <span [tooltip]="servicecost.extend_service_unit">
              {{ servicecost.extend_service_unit }}</span
            >
          </td>

          <td class="td-thaotac px-5px lg:px-16px" data-label="Thao tác">
            <div class="flex flex-row-reverse justify-center m-j-end">
              <button
                tooltip="Xóa"
                class="hover:bg-primary-lightest rounded-full focus:outline-none flex items-center justify-center button-add-edit"
                (click)="deleteService(servicecost.id)"
              >
                <img src="assets/icons/handle-icons/u_trash-alt.svg" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <ng-template #noInfo>
        <tbody>
          <tr
            class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
          >
            <td
              colspan="5"
              class="td-noinfo border-light-grey border-b border-l border-r border-opacity-50"
            >
              Không có dữ liệu
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div class="col-span-12">
    <meu-pagination-component
      *ngIf="totalArray != 0"
      [total]="totalArray"
      [array]="array"
      [changePage]="onPagingFunc"
    ></meu-pagination-component>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-service-cost-contract-modal
    [idContract]="idContract"
    [listService]="listService"
    (closeModalService)="closeModal($event)"
  ></meu-service-cost-contract-modal>
</meu-modal-component>

<!-- Modal bottom sheet -->
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-service-cost-contract-modal
    [idContract]="idContract"
    [listService]="listService"
    (closeModalService)="closeModalBottom($event)"
  ></meu-service-cost-contract-modal>
</meu-modal-popup-bottom>
