import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'meu-pagination-component',
  templateUrl: './pagination-component.component.html',
  styleUrls: ['./pagination-component.component.scss'],
})
export class PaginationComponentComponent implements OnInit {
  @Input() array: any;
  @Input() changePage: Function;
  @Input() total: any;
  @Input() inputPageSize: number;
  @Input() setPropertyTotal: boolean = false;
  @Input() optionStyle: boolean = false;
  @Input() isSelectPageSize: boolean = true;
  @Input() isChart: boolean = false;
  @Output() onChangeValue = new EventEmitter();
  currentPage: number = 1;
  totalPage: number;
  pageSize: number;
  arrayStart: number = 1;
  arrayEnd: number = 10;
  arrayLenght: number;
  pageShow: number[] = [];
  pageSizes: PageOption[] = [
    { value: 5, viewValue: '5' },
    { value: 10, viewValue: '10' },
    { value: 15, viewValue: '15' },
    { value: 20, viewValue: '20' },
  ];

  imageUrl = '';
  onPaging() {
    if (this.total > 0) {
      this.arrayLenght = this.total;
      this.totalPage = Math.ceil(this.total / this.pageSize);
      if (this.totalPage < 1) {
        this.totalPage = 1;
        this.pageShow = [1];
      } else {
        if (this.totalPage < 4) {
          this.pageShow = Array.from(Array(this.totalPage), (_, i) => i + 1);
        } else {
          //
          // this.pageShow = Array.from(Array(3), (_, i) => i + 1);
          // 6 - 4 < 2
          // < 2  : <=2     > 2 ????
          //

          if (this.totalPage - this.currentPage <= 2) {
            //  6 - 4 = 2 => 0 1 2 + 3 => 3 4 5
            // 6 - 5 = 1 => 0 1 2 + 4 => 4 5 6
            // 2 3 4 curr(3)  (totalPage - ?????)
            // total == Cr     6 page => page 4 5 6  total-2
            if (this.totalPage == this.currentPage) {
              this.pageShow = Array.from(
                Array(3),
                (_, i) => i + (this.totalPage - 2)
              );
            } else {
              this.pageShow = Array.from(
                Array(3),
                (_, i) => i + (this.currentPage - 1)
              );
            }
          } else if (this.currentPage % 3 == 0) {
            // 1 2 3 CurrP(3) => 4 5 6
            this.pageShow = Array.from(
              Array(3),
              (_, i) => i + this.currentPage - 1
            );
          } else {
            // > 2  currP 1  0 1 2
            if (this.currentPage == 1) {
              this.pageShow = Array.from(Array(3), (_, i) => i + 1);
            } else {
              this.pageShow = Array.from(
                Array(3),
                (_, i) => i + (this.currentPage - 1)
              );
            }
          }
        }
      }

      if (this.currentPage > this.totalPage) {
        this.currentPage = 1;
      }
      this.changePage(this.array, this.currentPage, this.pageSize);
      this.arrayStart = (this.currentPage - 1) * this.pageSize + 1;
      this.arrayEnd = (this.currentPage - 1) * this.pageSize + this.pageSize;
      if (this.arrayEnd >= this.total) {
        this.arrayEnd = this.total;
      }
      if (this.pageSize && this.currentPage) {
        this.onChangeValue.emit({
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
        });
      }
    }
  }
  change(page) {
    this.currentPage = page;
    this.onPaging();
  }

  nextPage() {
    this.currentPage += 1;
    this.onPaging();
  }
  backPage() {
    this.currentPage -= 1;
    this.onPaging();
  }
  constructor() {}

  ngOnInit() {
    if (!this.inputPageSize) {
      this.pageSize = 10;
    } else {
      this.pageSize = this.inputPageSize;
    }
    this.onPaging();
  }
  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === 'array') {
        this.array = changes[property].currentValue;
        if (this.array?.length < 1) {
          this.arrayStart = 0;
          this.arrayEnd = 0;
          this.arrayLenght = 0;
          this.totalPage = 1;
          this.currentPage = 1;
          this.changePage(this.array, this.currentPage, this.pageSize);
        }
        this.onPaging();
      }
      if (property === 'total' && this.setPropertyTotal) {
        this.onPaging();
      }
    }
  }
}
interface PageOption {
  value: number;
  viewValue: string;
}
