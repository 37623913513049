<div class="debt-payment modal-create-container">
  <div class="h-40px flex justify-between items-center">
    <span class="pyx`-12px text-16px font-roboto-medium"
      >Lượt thanh toán công nợ
    </span>
    <span (click)="addPayment()">
      <img class="m-16px" src="assets/icons/debt-management/plus.svg" alt="" />
    </span>
  </div>
  <!-- table -->
  <table
    *ngFor="let item of this.userDebt; let i = index"
    class="w-full border-separate table-fixed"
    cellpadding="0"
    cellspacing="0"
    id="table"
  >
    <thead>
      <tr
        class="bg-lightest-grey text-12px leading-4 overflow-hidden text-grey-med"
      >
        <th class="text-left col-customer items-center pt-12px px-8px pb-8px">
          <div class="text-left">
            <span
              class="overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Khách hàng"
              >Phương thức thanh toán</span
            >
          </div>
        </th>

        <th class="text-left col-customer items-center pt-12px px-8px pb-8px">
          <div class="text-right">
            <span
              class="overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Khách hàng"
              >Số tiền thanh toán (VNĐ)</span
            >
          </div>
        </th>
      </tr>
    </thead>

    <!-- DATA TABLE -->
    <tbody class="font-roboto-regular">
      <tr class="font-roboto-medium" style="min-height: 40px !important">
        <td
          class="py-10px px-8px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-left"
          data-label="Giá trị đơn hàng"
        >
          <span class="font-semibold"> {{ item.method }} </span>
        </td>

        <td
          class="py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
          data-label="Đã thanh toán"
        >
          <span class="font-semibold"> {{ item.payment | meucurrency }} </span>
        </td>
      </tr>

      <tr class="" style="min-height: 40px !important">
        <td
          class="py-10px px-8px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-left"
          data-label="Giá trị đơn hàng"
        >
          <span class="text-grey-med">Số hóa đơn</span>
        </td>

        <td
          class="py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
          data-label="Đã thanh toán"
        >
          <span class="text-black-grey"> {{ item.code }} </span>
        </td>
      </tr>

      <tr class="text-grey-med" style="min-height: 40px !important">
        <td
          class="py-10px px-8px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-left"
          data-label="Giá trị đơn hàng"
        >
          <span class="">Tệp đính kèm</span>
        </td>

        <td
          class="py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right text-primary-dodger-blue"
          data-label="Đã thanh toán"
        >
          <a
            (click)="downloadFile(item.id)"
            download
            class="text-primary-dodger-blue"
            >{{ item.attachment }}
          </a>
        </td>
      </tr>

      <tr class="text-grey-med" style="min-height: 40px !important">
        <td
          class="py-10px px-8px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-left"
          data-label="Giá trị đơn hàng"
        >
          <span class="">Thời gian cập nhật</span>
        </td>

        <td
          class="py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
          data-label="Đã thanh toán"
        >
          <span class=""
            >{{ item.created_at | date: 'dd/MM/yyyy HH:mm' }}
          </span>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <button
            (click)="deletePaymentReceipt(item.id)"
            class="text-red-light text-14px py-2.5 w-full flex justify-center bg-white font-roboto-medium rounded"
          >
            <div class="flex gap-3 items-center">
              <span
                ><img
                  src="../../../../assets/icons/handle-icons/u_trash-red.svg"
                  width="20"
                  height="20"
                />
              </span>
              <span>Xóa</span>
            </div>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="col-span-12" [formGroup]="debtPaymentForm">
    <table
      formArrayName="debtPayment"
      class="w-full border-separate table-fixed"
      cellpadding="0"
      cellspacing="0"
    >
      <tbody
        *ngIf="
          userDebt?.length != 0 || debtPaymentForm.value.debtPayment != 0;
          else noInfo
        "
        class="font-roboto-regular text-dark-grey text-14px"
      >
        <ng-container *ngFor="let debt of debtPayment.controls; let i = index">
          <ng-container [formGroupName]="i">
            <tr>
              <td data-label="#" class="w-1/2 text-left border-l text-14px">
                <div class="px-10px">
                  <ng-select
                    class="focus:outline-none select-product"
                    placeholder="Chọn phương thức thanh toán"
                    bindLabel="name"
                    bindValue="value"
                    [items]="allStatus"
                    formControlName="method"
                    [(ngModel)]="selectedStatus"
                  >
                    <!--
                    notFoundText="Không tìm thấy phương thức thanh toán" -->
                    <ng-template ng-option-tmp let-item="item">
                      <div
                        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                        [tooltip]="item.name"
                      >
                        {{ item.name }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </td>
              <td
                data-label="#"
                class="w-1/2 text-right border-l overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                <div class="flex flex-col">
                  <!-- [formControl]="debtPayment.controls[i]" -->
                  <input
                    formControlName="payment"
                    type="text"
                    class="w-full h-full input text-right"
                    [placeholder]="'Nhập số tiền'"
                  />
                </div>
                <!-- </ng-template> -->
              </td>
            </tr>
            <tr>
              <td
                data-label="#"
                class="w-1/2 text-left border-l overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                <div
                  class="overflow-hidden whitespace-nowrap overflow-ellipsi px-10px"
                >
                  <span> Số hóa đơn </span>
                </div>
              </td>
              <td
                data-label="#"
                class="w-1/2 text-right border-l overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                <input
                  formControlName="code"
                  type="text"
                  class="w-full h-full input text-right "
                  [placeholder]="'Nhập số hóa đơn'"
                />
              </td>
            </tr>
            <tr>
              <td
                data-label="#"
                class="w-1/2 text-left border-l overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                <div
                  class="overflow-hidden whitespace-nowrap overflow-ellipsi px-10px"
                >
                  <span> Tệp đính kèm </span>
                </div>
              </td>
              <td
                data-label="#"
                class="w-1/2 border-l overflow-hidden whitespace-nowrap overflow-ellipsis py-8px px-12px"
              >
                <div>
                  <input
                    class="text-primary-dodger-blue w-150px whitespace-nowrap overflow-ellipsis"
                    type="file"
                    [placeholder]="'Tải lên tệp đính kèm'"
                    (change)="choosenFile($event, i)"
                  />
                  <!-- <button class="flex gap-6px" (click)="importFile.click()">
                    <span
                      [ngClass]="
                        choosenFileName
                          ? 'primary-dodger-blue overflow-hidden whitespace-nowrap overflow-ellipsi	'
                          : 'text-purplish-grey'
                      "
                      >{{
                        choosenFileName
                          ? choosenFileName
                          : 'Tải lên tệp đính kèm'
                      }}</span
                    >
                    <img
                      [ngClass]="choosenFileName ? hidden : 'text-11px'"
                      src="assets/icons/debt-management/u_paperclip.svg"
                    />
                  </button> -->
                </div>
              </td>
            </tr>
            <tr>
              <td
                data-label="#"
                class="w-1/2 text-left border-l overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                <div
                  class="overflow-hidden whitespace-nowrap overflow-ellipsi px-10px"
                >
                  <span> Thời gian cập nhật </span>
                </div>
              </td>
              <td
                data-label="#"
                class="w-1/2 text-right border-l overflow-hidden whitespace-nowrap overflow-ellipsis"
              >
                <input
                  readonly
                  formControlName="date"
                  type="text"
                  class="w-full h-full input"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4" class="border-l">
                <div class="flex items-center justify-between p-6px">
                  <button
                    class="btn-cancel flex items-center gap-2px"
                    type="button"
                    (click)="removePayment(i)"
                  >
                    <img
                      src="../../../../assets/icons/handle-icons/u_times.svg"
                      alt=""
                    />
                    Hủy
                  </button>
                  <button
                    class="btn-save flex items-center gap-2px"
                    type="button"
                    (click)="savePayment(i, debt.value)"
                  >
                    <img
                      src="../../../../assets/icons/handle-icons/u_save.svg"
                      alt=""
                    />
                    Lưu
                  </button>
                </div>
                <!-- <ng-template #isEdit>
                      <div class="flex items-center justify-between p-6px">
                        <button
                          class="btn-delete flex items-center gap-2px"
                          type="button"
                          (click)="removeRowProduct(i, receipt.value)"
                        >
                          <img
                            src="../../../../assets/icons/handle-icons/u_trash-red.svg"
                            alt=""
                          />
                          Xóa
                        </button>
                        <button
                          class="btn-edit flex items-center gap-2px"
                          type="button"
                          (click)="editRowProduct(i, receipt.value)"
                        >
                          <img
                            src="../../../../assets/icons/handle-icons/u_edit-yellow.svg"
                            alt=""
                          />
                          Sửa
                        </button>
                      </div></ng-template
                    > -->
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <ng-template #noInfo>
        <tbody>
          <tr
            class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
          >
            <td
              colspan="4"
              class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
            >
              Hiện chưa có hàng hóa nào <br />
              Bấm "+" bên trên để thêm
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
