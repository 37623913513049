import { Component, Input, OnInit } from '@angular/core';
import { ContainerSellProductPaymentService } from 'src/app/api/containerSellProductPayment.service';
import { UserDebtService } from 'src/app/api/userDebt.service';

@Component({
  selector: 'meu-modal-debt-infomation',
  templateUrl: './modal-debt-infomation.component.html',
  styleUrls: ['./modal-debt-infomation.component.scss'],
})
export class ModalDebtInfomationComponent implements OnInit {
  @Input() userDetailDebt: any;
  @Input() userDetailID: string;
  listContainerProductPayment;
  totalPayment: number = 0;
  totalPrice: number = 0;
  isDebt: boolean = false;
  isOpenBottomsheet: boolean = false;
  title = 'Thông tin công nợ';
  constructor(
    private userDebtService: UserDebtService,
    private containerSellProductPaymentService: ContainerSellProductPaymentService
  ) {}

  ngOnInit(): void {
    this.getContainerSellProductPayment();
  }
  openEdit() {
    if (window.screen.width < 767) {
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      } else {
        this.isOpenBottomsheet = false;
      }
    }
  }
  closeEdit(event) {
    if (event) {
      this.userDetailDebt.max_debt_allow = event.data.max_debt_allow;
      this.isOpenBottomsheet = false;
    }
  }
  getContainerSellProductPayment() {
    this.containerSellProductPaymentService
      .apiContainerSellProductPaymentByCustomerIdUserIdGet(this.userDetailID)
      .subscribe((res) => {
        if (res.success) {
          this.listContainerProductPayment = res.data.collection;
          for (let i = 0; i < this.listContainerProductPayment.length; i++) {
            this.totalPayment += this.listContainerProductPayment[
              i
            ].total_payment;
            this.totalPrice += this.listContainerProductPayment[i].total_price;
          }
        }
      });
  }
}
