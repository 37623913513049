<div
  [formGroup]="slotForm"
  class="dv-form grid grid-cols-12 px-16px h-auto gap-5 custom-scroll"
  style="min-height: 200px"
  id="scrollbar"
>
  <ng-container formArrayName="slot">
    <ng-container *ngFor="let item of slot.controls; let i = index">
      <ng-container [formGroupName]="i">
        <div
          *ngIf="isCheckOpenFormModalSlot"
          class="col-span-12 grid grid-cols-12 mt-16px"
        >
          <div class="col-span-3 text-center">
            <span
              class="text-primary-dodger-blue font-roboto-regular text-18px mr-4px"
              >Từ</span
            >
            <span class="text-red-light font-roboto-medium text-20px">{{
              slotForm.controls['slot'].controls[i].controls.fromCode.value
            }}</span>
          </div>
          <div class="col-span-6 self-center">
            <div class="flex items-center">
              <div class="w-full h-5px bg-primary-dodger-blue"></div>
              <div class="w-10px h-15px text-primary-dodger-blue arrow"></div>
            </div>
          </div>
          <div class="col-span-3 text-center">
            <span
              class="text-primary-dodger-blue font-roboto-regular text-18px mr-4px"
              >Đến</span
            >
            <span class="text-red-light font-roboto-medium text-20px">{{
              slotForm.controls['slot'].controls[i].controls.toCode.value
            }}</span>
          </div>

          <div class="flex items-center justify-center gap-20px">
            <div class="flex items-center gap-10px"></div>

            <div class="flex items-center gap-10px"></div>
          </div>
        </div>

        <div
          [ngClass]="
            !isCheckOpenFormModalSlot ? 'shadow-md my-10px  rounded-4px' : ''
          "
          class="p-10px col-span-12 grid grid-cols-12 gap-5"
        >
          <!-- From -->
          <div
            [ngClass]="
              !isCheckOpenFormModalSlot
                ? 'col-span-12 xl:col-span-5  grid-cols-10'
                : 'col-span-12 xl:col-span-6  grid-cols-12'
            "
            class="grid gap-5"
          >
            <!-- Mã ô -->
            <div
              [ngClass]="
                !isCheckOpenFormModalSlot ? 'col-span-12' : 'col-span-12 '
              "
            >
              <div class="flex flex-col">
                <span class="font-roboto-regular text-12px text-grey-label">
                  Mã ô
                </span>
                <input
                  type="text"
                  readonly
                  formControlName="fromCode"
                  class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                />
                <!-- <ng-select
                  *ngIf="!isCheckOpenFormModalSlot"
                  class="select-name mt-8px focus:outline-none"
                  bindLabel="code"
                  bindValue="id"
                  [items]="
                    slotForm.controls['slot'].controls[i].controls.fromListSlot
                      .value
                  "
                  placeholder="Chọn ô"
                  (change)="getDataFromSlot($event, i)"
                  formControlName="fromSlot_id"
                  required
                  notFoundText="Không tìm thấy phân loại hàng hóa"
                >
                 <ng-template ng-option-tmp let-item="item">
                    <div
                      class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                      [tooltip]="item.product"
                    >
                      {{ item.product }}
                    </div>
                  </ng-template> 
                </ng-select>  -->
              </div>
            </div>
            <!-- Đã dùng % -->
            <!-- <div
              [ngClass]="
                !isCheckOpenFormModalSlot
                  ? 'col-span-5'
                  : 'col-span-12 md:col-span-6'
              "
            >
              <div class="flex flex-col">
                <span class="font-roboto-regular text-12px text-grey-label">
                  Đã dùng (%)
                </span>
                <input
                  type="text"
                  readonly
                  formControlName="fromPercent"
                  class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                />
                <span class="font-roboto-regular text-12px text-red-light">
                </span>
              </div>
            </div> -->
            <!--list product in slot -->
            <!-- <div
              *ngIf="!isCheckOpenFormModalSlot"
              [ngClass]="
                !isCheckOpenFormModalSlot ? 'col-span-5' : 'col-span-6'
              "
            >
              <div class="flex flex-col">
                <span class="font-roboto-regular text-12px text-grey-label">
                  Hàng hóa
                </span>
                <ng-select
                  class="select-name mt-8px focus:outline-none"
                  bindLabel="product"
                  (change)="changeProduct($event, i)"
                  [items]="
                    slotForm.controls['slot'].controls[i].controls
                      .fromExtend_stock_available.value
                  "
                  placeholder="Chọn hàng hóa "
                  formControlName="fromProduct"
                  notFoundText="Không tìm thấy phân loại hàng hóa"
                > -->
            <!-- <ng-template ng-option-tmp let-item="item">
                    <div
                      class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                      [tooltip]="item.product"
                    >
                      {{ item.product }}
                    </div>
                  </ng-template> -->
            <!-- </ng-select>
              </div> -->
            <!-- (change)="getproductTemplate($event)" -->
            <!-- </div> -->
            <!-- stock -->
            <!-- <div
              *ngIf="!isCheckOpenFormModalSlot"
              [ngClass]="
                !isCheckOpenFormModalSlot ? 'col-span-5' : 'col-span-6'
              "
            >
              <div class="flex flex-col">
                <span class="font-roboto-regular text-12px text-grey-label">
                  Số lượng tồn
                </span>
                <input
                  formControlName="fromStock"
                  type="text"
                  required
                  class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                />
              </div>
            </div> -->
          </div>
          <div
            *ngIf="!isCheckOpenFormModalSlot"
            class="col-span-full xl:col-span-1 xl:mt-35px"
          >
            <div class="div-arrow">
              <div class="flex items-center">
                <div class="w-full h-5px bg-primary-dodger-blue"></div>
                <div class="w-10px h-15px text-primary-dodger-blue arrow"></div>
              </div>
            </div>
            <div class="div-arrow-mobi justify-center">
              <div class="flex flex-col items-center">
                <div class="w-10px h-30px bg-primary-dodger-blue"></div>
                <div
                  class="w-10px h-15px text-primary-dodger-blue arrow-down"
                ></div>
              </div>
            </div>
          </div>
          <!-- TO -->
          <div
            [ngClass]="
              !isCheckOpenFormModalSlot
                ? 'col-span-12 xl:col-span-5  grid-cols-10'
                : 'col-span-12 xl:col-span-6  grid-cols-12'
            "
            class="grid gap-5"
          >
            <!-- Mã ô -->
            <div
              [ngClass]="
                !isCheckOpenFormModalSlot ? 'col-span-12' : 'col-span-12 '
              "
            >
              <div class="flex flex-col">
                <span class="font-roboto-regular text-12px text-grey-label">
                  Mã ô <span class="text-red-light">*</span></span
                >
                <ng-select
                  class="select-name mt-8px focus:outline-none"
                  bindLabel="code"
                  bindValue="id"
                  [items]="
                    slotForm.controls['slot'].controls[i].controls.toListSlot
                      .value
                  "
                  placeholder="Chọn ô"
                  (change)="getDataToSlot($event, i)"
                  formControlName="toSlot_id"
                  required
                  notFoundText="Không tìm thấy phân loại hàng hóa"
                >
                  <!-- <ng-template ng-option-tmp let-item="item">
                    <div
                      class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                      [tooltip]="item.product"
                    >
                      {{ item.product }}
                    </div>
                  </ng-template> -->
                </ng-select>
                <!-- <input
                  type="text"
                  placeholder="Nhập tên đơn vị tính"
                  required
                  formControlName="toCode"
                  class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                /> -->
              </div>
            </div>
            <!-- Đã dùng % -->
            <!-- <div
              [ngClass]="
                !isCheckOpenFormModalSlot
                  ? 'col-span-5'
                  : 'col-span-12 md:col-span-6'
              "
            >
              <div class="flex flex-col">
                <span class="font-roboto-regular text-12px text-grey-label">
                  Đã dùng (%)
                </span>
                <input
                  type="text"
                  readonly
                  formControlName="toPercent"
                  class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                />
              </div>
            </div> -->
          </div>
          <!-- remove -->
          <div
            *ngIf="
              !isCheckOpenFormModalSlot &&
              slotForm.controls['slot'].controls[i].controls
                .fromExtend_stock_available.value.length == 0
            "
            class="col-span-1 mt-35px"
          >
            <button
              (click)="removeSlot(i, item)"
              tooltip="Xóa"
              class="hover:bg-primary-lightest rounded-full focus:outline-none"
            >
              <img src="assets/icons/handle-icons/u_trash-alt.svg" />
            </button>
          </div>

          <div *ngIf="!isCheckOpenFormModalSlot" class="col-span-full mb-10px">
            <div class="flex items-center">
              <span
                (click)="openTable(i, item.value.isOpenTable)"
                class="text-16px font-medium text-primary-dodger-blue underline pb-2px cursor-pointer"
                >Danh sách hàng hóa tại
                {{
                  slotForm.controls['slot'].controls[i].controls.fromCode.value
                }}</span
              >
              <div
                class="h-15px w-15px rounded-full bg-primary-dodger-blue ml-8px"
              >
                <img src="assets/icons/handle-icons/icon_add.svg" />
              </div>
              <span
                class="ml-6px text-12px font-medium text-primary-dodger-blue italic"
                *ngIf="
                  !slotForm.controls['slot'].controls[i].controls.isOpenTable
                    .value &&
                  slotForm.controls['slot'].controls[i].controls
                    .fromExtend_stock_available.value.length == 0
                "
                >(Không có dữ liệu hàng hóa)</span
              >
            </div>

            <table
              *ngIf="
                slotForm.controls['slot'].controls[i].controls.isOpenTable.value
              "
              class="mt-8px min-w-full divide-y divide-border-table"
            >
              <thead class="bg-lightest-grey">
                <tr>
                  <th
                    *ngFor="let item of displayColumn; let i = index"
                    scope="col"
                    [ngClass]="i != 0 && i != 1 ? 'text-right' : 'text-left'"
                    class="px-4 py-2.5 text-grey-med text-12px font-medium text-gray-500 tracking-wider"
                  >
                    {{ item }}
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-2.5 text-right text-grey-med text-12px font-medium text-gray-500 tracking-wider"
                  >
                    <input
                      type="checkbox"
                      [checked]="isCheckAll"
                      (change)="
                        selectAll(
                          slotForm.controls['slot'].controls[i].controls
                            .fromExtend_stock_available.value
                        )
                      "
                    />
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white divide-y divide-gray-200 w-full text-dark-grey text-14px cursor-pointer"
              >
                <tr
                  *ngFor="
                    let item of slotForm.controls['slot'].controls[i].controls
                      .fromExtend_stock_available.value;
                    let i = index
                  "
                >
                  <td class="px-4 py-2.5 whitespace-nowrap">
                    {{ i + 1 }}
                  </td>
                  <td
                    class="px-4 py-2.5 whitespace-nowrap text-primary-dodger-blue"
                  >
                    {{ item.product }}
                  </td>
                  <td class="px-4 py-2.5 whitespace-nowrap text-right">
                    {{ item.quantity }}
                  </td>
                  <td
                    class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 text-right"
                  >
                    <span *ngIf="!item.isEdit">
                      {{ item['quantityChange'] }}</span
                    >

                    <div class="flex flex-col">
                      <input
                        type="number"
                        [max]="item.quantity"
                        min="0"
                        *ngIf="item.isEdit"
                        [ngModelOptions]="{ standalone: true }"
                        (input)="onChangeQuantity($event.target.value, item)"
                        class="class-valid text-right w-full border rounded-4px py-8px px-12px h-40px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                        [(ngModel)]="item.quantityChange"
                      />
                      <span
                        class="text-red-light text-12px font-roboto-regular"
                        *ngIf="item.isError"
                        >{{ item.isError }}</span
                      >
                    </div>
                  </td>

                  <!-- <td
                    class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 text-right"
                  >
                    <button
                      *ngIf="!item.isEdit"
                      class="focus:outline-none xl:mr-5px"
                      (click)="editQuantity(item)"
                    >
                      <img
                        src="../../../../assets/icons/handle-icons/u_edit-alt.svg "
                      />
                    </button>
                    <button
                      *ngIf="item.isEdit"
                      class="focus:outline-none xl:mr-5px"
                      (click)="saveQuantity(item)"
                    >
                      <img
                        src="../../../../assets/icons/handle-icons/u_save_grey.svg"
                      />
                    </button>
                  </td> -->
                  <td
                    class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 text-right"
                  >
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="item.isSelect"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="
                slotForm.controls['slot'].controls[i].controls
                  .fromExtend_stock_available.value.length == 0 &&
                slotForm.controls['slot'].controls[i].controls.isOpenTable.value
              "
              class="flex w-full h-48px items-center justify-center bg-white text-purplish-grey text-12px"
            >
              Không có dữ liệu
            </div>
          </div>

          <!-- table -->
          <div *ngIf="isCheckOpenFormModalSlot" class="col-span-full">
            <span class="text-16px font-medium text-dark-grey mb-6px"
              >Danh sách hàng hóa tại
              {{
                slotForm.controls['slot'].controls[i].controls.fromCode.value
              }}</span
            >
            <table class="mt-8px min-w-full divide-y divide-border-table">
              <thead class="bg-lightest-grey">
                <tr>
                  <th
                    *ngFor="let item of displayColumn; let i = index"
                    scope="col"
                    [ngClass]="i != 0 && i != 1 ? 'text-right' : 'text-left'"
                    class="px-4 py-2.5 text-grey-med text-12px font-medium text-gray-500 tracking-wider"
                  >
                    {{ item }}
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-2.5 text-right text-grey-med text-12px font-medium text-gray-500 tracking-wider"
                  >
                    <input
                      type="checkbox"
                      [checked]="isCheckAll"
                      (change)="
                        selectAll(
                          slotForm.controls['slot'].controls[i].controls
                            .fromExtend_stock_available.value
                        )
                      "
                    />
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white divide-y divide-gray-200 w-full text-dark-grey text-14px cursor-pointer"
              >
                <tr
                  *ngFor="
                    let item of slotForm.controls['slot'].controls[i].controls
                      .fromExtend_stock_available.value;
                    let i = index
                  "
                >
                  <td class="px-4 py-2.5 whitespace-nowrap">
                    {{ i + 1 }}
                  </td>
                  <td
                    class="px-4 py-2.5 whitespace-nowrap text-primary-dodger-blue"
                  >
                    {{ item.product }}
                  </td>
                  <td class="px-4 py-2.5 whitespace-nowrap text-right">
                    {{ item.quantity }}
                  </td>
                  <td
                    class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 text-right"
                  >
                    <span *ngIf="!item.isEdit">
                      {{ item['quantityChange'] }}</span
                    >

                    <div class="flex flex-col">
                      <input
                        type="number"
                        [max]="item.quantity"
                        min="0"
                        *ngIf="item.isEdit"
                        [ngModelOptions]="{ standalone: true }"
                        (input)="onChangeQuantity($event.target.value, item)"
                        class="class-valid text-right w-full border rounded-4px py-8px px-12px h-40px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                        [(ngModel)]="item.quantityChange"
                      />
                      <span
                        class="text-red-light text-12px font-roboto-regular"
                        *ngIf="item.isError"
                        >{{ item.isError }}</span
                      >
                    </div>
                  </td>

                  <!-- <td
                    class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 text-right"
                  >
                    <button
                      *ngIf="!item.isEdit"
                      class="focus:outline-none xl:mr-5px"
                      (click)="editQuantity(item)"
                    >
                      <img
                        src="../../../../assets/icons/handle-icons/u_edit-alt.svg "
                      />
                    </button>
                    <button
                      *ngIf="item.isEdit"
                      class="focus:outline-none xl:mr-5px"
                      (click)="saveQuantity(item)"
                    >
                      <img
                        src="../../../../assets/icons/handle-icons/u_save_grey.svg"
                      />
                    </button>
                  </td> -->
                  <td
                    class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500 text-right"
                  >
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="item.isSelect"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="
                slotForm.controls['slot'].controls[i].controls
                  .fromExtend_stock_available.value.length == 0
              "
              class="flex w-full h-48px items-center justify-center bg-white text-purplish-grey text-12px"
            >
              Không có dữ liệu
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- add -->
  <!-- <div *ngIf="!isCheckOpenFormModalSlot" class="col-span-full">
    <button
      class="btn-cancel flex items-center gap-2px"
      type="button"
      (click)="pushSlot()"
    >
      <img src="assets/icons/handle-icons/u_plus.svg" alt="" />
      Thêm ô
    </button>
  </div> -->
</div>

<div class="grid grid-cols-12 px-16px h-auto gap-5">
  <div
    class="col-span-full flex-row-reverse gap-8px flex h-64px mt-20px items-center"
  >
    <button
      (click)="saveChangePosition(false)"
      class="btn-text-cancel"
      type="button"
    >
      Hủy
    </button>
    <button
      (click)="saveChangePosition(true)"
      class="btn-primary-add"
      type="button"
      [disabled]="slotForm.invalid"
    >
      Chuyển
    </button>
  </div>
</div>
