<div class="container charge-container">
  <div
    class="w-full h-auto font-roboto bg-white shadow-md rounded-4px px-16px pb-16px mt-16px contain"
  >
    <div class="section">
      <div class="list-container-charge">
        <div class="w-full min-h-300">
          <div
            class="w-full py-12px mt-8px flex flex-col lg:flex-row lg:justify-between lg:items-center title"
          >
            <div
              class="font-roboto-medium font-medium text-dark-grey text-16px leading-7 not-italic"
            >
              Quản lý phí sạc container
            </div>

            <div
              class="sm:grid md:flex md:float-right items-center gap-8px margin-t z-3"
            >
              <div class="relative mt-4px sm:mt-0">
                <input
                  type="text"
                  placeholder="Nhập từ khóa tìm kiếm"
                  (keyup)="keyUp.next($event.target.value)"
                  class="search-import-export m-auto h-32px w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
                />
                <img
                  class="absolute top-0 mt-9px ml-12px"
                  src="assets/icons/handle-icons/Icons_search.svg"
                />
              </div>
              <div class="dropdown mt-4px sm:mt-0">
                <a
                  type="button"
                  href="javascript:void(0)"
                  class="flex text-sm rounded-md focus:outline-none items-center"
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-controls="layout-user-action"
                >
                  <div
                    class="flex justify-between font-roboto-medium w-150px text-grey-label cursor-pointer text-14px pl-12px pr-7px h-32px mt--5px py-6px border border-light-grey rounded"
                  >
                    {{ selectedStatus?.name || '--' }}

                    <img
                      src="/assets/icons/product-categories-icons/u_angle-down.svg"
                      alt=""
                      class="ml-5px w-20px h-20px"
                    />
                  </div>
                </a>

                <div
                  class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
                >
                  <div
                    class="absolute left-0 md:right-0 w-150px mt-1 origin-top-right bg-white text-grey-med rounded-md shadow outline-none"
                    aria-labelledby="headlessui-menu-button-2"
                    id="layout-user-action"
                    role="menu"
                  >
                    <div class="py-2">
                      <div
                        class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                        *ngFor="let status of allStatus"
                        (click)="onChangeStatus(status)"
                      >
                        <div class="flex">
                          {{ status.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative mt-4px sm:mt-0">
                <button
                  class="btn-primary-icon flex items-center gap-4px"
                  (click)="openModal(null)"
                >
                  <img src="assets/icons/handle-icons/icon_add.svg" />
                  <span>Thêm mới</span>
                </button>
              </div>
            </div>
          </div>
          <div class="w-full overflow-x-auto overflow-y-hidden">
            <table
              class="w-full border-separate table-fixed"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr
                  class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
                >
                  <th class="text-center col col-1">#</th>
                  <th class="text-left col col-2">Số Container</th>
                  <th class="text-left col col-3">Ngày bắt đầu</th>
                  <th class="text-left col col-4">Ngày kết thúc</th>
                  <th class="text-left col col-5">Đơn giá/giờ (VNĐ)</th>
                  <th class="text-left col col-6">Người tạo</th>
                  <th class="text-center col col-7">Trạng thái</th>
                  <th class="text-center col col-8">Thao tác</th>
                </tr>
              </thead>

              <!-- DATA TABLE -->
              <tbody
                class="font-roboto-regular text-dark-grey text-14px"
                *ngIf="totalArray != 0; else noInfo"
              >
                <tr
                  *ngFor="let item of listContainerCharge; let i = index"
                  class="mh-40px"
                >
                  <td
                    class="td-stt whitespace-nowrap overflow-hidden overflow-ellipsis text-center border-l"
                    [title]="(pageIndex - 1) * pageSize + i + 1"
                    data-label="#"
                  >
                    {{ (pageIndex - 1) * pageSize + i + 1 }}
                  </td>

                  <td data-label="Số container">
                    <div
                      class="cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
                      [title]="item.container_number"
                    >
                      <span class="">{{ item.container_number || '--' }}</span>
                    </div>
                  </td>

                  <td data-label="Ngày bắt đầu">
                    <div
                      class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                    >
                      <span
                        [title]="
                          item.start_date
                            ? (item.start_date | date: 'dd/MM/yyyy - HH:mm')
                            : '--'
                        "
                      >
                        {{
                          item.start_date
                            ? (item.start_date | date: 'dd/MM/yyyy - HH:mm')
                            : '--'
                        }}
                      </span>
                    </div>
                  </td>

                  <td data-label="Ngày kết thúc">
                    <div
                      class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                    >
                      <span
                        [title]="
                          item.end_date
                            ? (item.end_date | date: 'dd/MM/yyyy - HH:mm')
                            : '--'
                        "
                      >
                        {{
                          item.end_date
                            ? (item.end_date | date: 'dd/MM/yyyy - HH:mm')
                            : '--'
                        }}
                      </span>
                    </div>
                  </td>

                  <td data-label="Đơn giá/giờ (VNĐ)">
                    <div
                      class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                    >
                      <span [title]="item.cost | meucurrency">
                        {{ (item.cost | meucurrency) || '--' }}
                      </span>
                    </div>
                  </td>

                  <td
                    data-label="Người tạo"
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span [title]="item.extend_creator_name">
                      {{ item.extend_creator_name || '--' }}
                    </span>
                  </td>

                  <td class="td-status" data-label="Trạng thái">
                    <div class="px-10px py-6px">
                      <div
                        class="w-full h-auto m-auto text-12px flex-grow-0 gap-4px rounded-4px px-6px py-3px items-center text-center status-style"
                        [ngClass]="
                          item.is_draft
                            ? 'text-grey-label bg-grayscale-light-grey'
                            : 'text-green-blue bg-duck-egg-blue'
                        "
                      >
                        {{ item.is_draft ? 'Lưu nháp' : 'Đã thanh toán' }}
                      </div>
                    </div>
                  </td>
                  <td class="td-thaotac" data-label="Thao tác">
                    <div class="flex justify-end md:justify-center">
                      <button
                        (click)="openModal(item.id)"
                        class="hover:bg-primary-lightest focus:outline-none p-10px rounded-full button-add-edit"
                        tooltip="Chỉnh sửa"
                      >
                        <img
                          src="assets/icons/handle-icons/u_edit-alt.svg "
                          class="w-20px h-20px"
                        />
                      </button>
                      <button
                        tooltip="Xóa"
                        class="hover:bg-primary-lightest rounded-full focus:outline-none p-10px button-add-edit"
                        (click)="deleteconfirm(item.id)"
                      >
                        <img
                          src="assets/icons/handle-icons/u_trash-alt.svg"
                          class="w-20px h-20px"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>

              <ng-template #noInfo>
                <tbody>
                  <tr
                    class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
                  >
                    <td
                      colspan="8"
                      class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
                    >
                      Không có dữ liệu
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
          <div class="w-full">
            <meu-pagination-component
              *ngIf="totalArray != 0"
              [total]="totalArray"
              [changePage]="changePageFunc"
              [inputPageSize]="pageSize"
              [setPropertyTotal]="true"
              (onChangeValue)="onPaging($event)"
            ></meu-pagination-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-modal-create-container-charge
    [id_conatainer_charge]="id_conatainer_charge"
    (emitContainerCharge)="closeModal($event)"
  ></meu-modal-create-container-charge>
</meu-modal-component>

<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-create-container-charge
    [id_conatainer_charge]="id_conatainer_charge"
    (emitContainerCharge)="closeModal($event)"
  ></meu-modal-create-container-charge>
</meu-modal-popup-bottom>
