import Konva from 'konva';
import { EventEmitter, Injectable } from '@angular/core';
import { MainMenu } from './main-menu';
import { Subject, Subscription } from 'rxjs';
import { Containers } from './containers';
import { Slot } from 'src/app/model/slot';
import { OnInit, OnDestroy } from '@angular/core';
import { NpgsqlPoint } from 'src/app';
import { ConfirmService } from 'src/app/core/services';
import { filter, take } from 'rxjs/operators';

export class InventoryMap {
  private mainMenu: MainMenu;
  private subscriptions: Subscription[] = [];
  private confirmService: ConfirmService = new ConfirmService();

  containers: Containers;

  newPos = { x: 1, y: 1 };
  newScale = 1;

  inventoryMapData$ = new Subject();
  reloadSelectSlot = new Subject();
  slotDataFromModal$ = new Subject();
  changeImage$ = new Subject();
  openModalSlot: EventEmitter<any> = new EventEmitter();
  openModalImportHistory: EventEmitter<string> = new EventEmitter();
  mapData: EventEmitter<any> = new EventEmitter();
  addMapImage: EventEmitter<any> = new EventEmitter();
  selectSlotEvent: EventEmitter<any> = new EventEmitter();
  shapeSelected;
  slotSelected: any = {};
  slotDataMap = [];
  lstSlotSelected: Slot[] = [];
  slotNameSelected: string = '';
  constructor(
    private stage: Konva.Stage,
    private layer: Konva.Layer,
    zoneId?,
    isAdmin?
  ) {
    //this.getBackgroundImg();

    //create menu
    if (isAdmin) {
      this.mainMenu = new MainMenu(stage, layer);
      this.addKeydownListenerEvent();
    }

    //new container
    this.containers = new Containers(stage, layer, isAdmin);

    //zoom
    let scaleBy = 1.1;
    stage.on('wheel', (e) => {
      e.evt.preventDefault();
      let oldScale = this.stage.scaleX();
      let pointer = this.stage.getPointerPosition();

      let mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale,
      };

      this.newScale =
        e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

      this.stage.scale({ x: this.newScale, y: this.newScale });

      this.newPos = {
        x: pointer.x - mousePointTo.x * this.newScale,
        y: pointer.y - mousePointTo.y * this.newScale,
      };
      this.mainMenu?.newScale$.next({
        newPos: this.newPos,
        newScale: this.newScale,
      });
      this.stage.position(this.newPos);
      this.stage.batchDraw();
    });
    this.stage.add(this.layer);
    //drag
    stage.on('dragmove', (e) => {
      if (e.target.getType() === 'Stage') {
        this.newPos = {
          x: e.currentTarget.attrs.x,
          y: e.currentTarget.attrs.y,
        };
        this.mainMenu?.newScale$.next({
          newPos: this.newPos,
          newScale: this.newScale,
        });
      }
    });

    this.subscriptions.push(
      //listen menu click
      this.mainMenu?.btnClick.subscribe((type) => {
        if (type == 'save') {
          this.containers.saveContainer();
        } else if (type == 'image') {
          this.addMapImage.emit();
        } else {
          this.containers.addShape(type);
        }
      }),
      //get data to slot
      this.inventoryMapData$.subscribe((res: any) => {
        res.forEach((item) => {
          let shape: any = {
            opacity: 0.85,
            rotation: item.rotation,
            scaleX: item.scale.x,
            scaleY: item.scale.y,
            strokeWidth: 2,
          };
          if (item.shape_type == 'Circle') {
            shape.lineJoin = 'miter';
            shape.x = item.points[0].x;
            shape.y = item.points[0].y;
            shape.radius = item.radius;
            let circle = this.containers.addCircle(
              shape,
              item.code,
              '',
              item.used_percent,
              item.extend_import_history,
              item.extend_stock_available
            );
            this.mapSlotDataWithContainer(item, circle);
          } else if (item.shape_type == 'Line') {
            shape.points = [];
            for (let i = 0; i < item.points.length; i++) {
              shape.points.push(item.points[i].x, item.points[i].y);
            }

            shape.closed = true;
            let polygon = this.containers.joinLineToPolygon(
              shape,
              item.code,
              '',
              item.used_percent,
              item.extend_import_history,
              item.extend_stock_available
            );
            this.mapSlotDataWithContainer(item, polygon);
          }
        });
      }),
      //listen save button menu click.
      this.containers.containersEvent.subscribe((res) => {
        let slotContainer = [];
        for (let i = 0; i < this.slotDataMap.length; i++) {
          let slot: Slot = {},
            points: NpgsqlPoint[] = [];
          let shape = this.slotDataMap[i].shape;
          if (Object.getPrototypeOf(shape).className == 'Line') {
            //Line
            for (let j = 0; j < shape.points().length; j += 2) {
              let point = {
                x: shape.attrs.points[j] * shape.scaleX() + shape.x(),
                y: shape.attrs.points[j + 1] * shape.scaleY() + shape.y(),
              };
              point.x = point.x + shape.parent.x();
              point.y = point.y + shape.parent.y();
              points.push(point);
            }
            slot.scale = {
              x: 1,
              y: 1,
            };
          } else {
            //Circle
            let point = {
              x: shape.x() + shape.parent.x(),
              y: shape.y() + shape.parent.y(),
            };
            slot.scale = {
              x: shape.scaleX(),
              y: shape.scaleY(),
            };
            points.push(point);
          }
          slot.points = points;
          slot.zone_id = zoneId;
          slot.shape_type = Object.getPrototypeOf(shape).className;
          slot.camera_id = null;
          slot.camera_link = null;
          slot.radius = shape.attrs.radius ? shape.attrs.radius : 0;
          slot.rotation = shape.attrs.rotation;
          slot.id = this.slotDataMap[i].slotData.id;
          slot.used_percent = this.slotDataMap[i].slotData.used_percent
            ? parseInt(this.slotDataMap[i].slotData.used_percent)
            : 0;
          slot.code = this.slotDataMap[i].slotData.code
            ? this.slotDataMap[i].slotData.code
            : '';
          slot.name = this.slotDataMap[i].slotData.name
            ? this.slotDataMap[i].slotData.name
            : '';
          slot.height = this.slotDataMap[i].slotData.height
            ? parseInt(this.slotDataMap[i].slotData.height)
            : 0;
          slot.weight = this.slotDataMap[i].slotData.weight
            ? parseInt(this.slotDataMap[i].slotData.weight)
            : 0;
          slot.width = this.slotDataMap[i].slotData.width
            ? parseInt(this.slotDataMap[i].slotData.width)
            : 0;
          slot._long = this.slotDataMap[i].slotData._long
            ? parseInt(this.slotDataMap[i].slotData._long)
            : 0;
          slotContainer.push({ data: slot });
        }
        this.mapData.emit(slotContainer);
      }),       
      
      // listen click groupInfo, open modal import history
      this.containers.containersClickEvent.subscribe((res: string) => {
        if(res.includes('PN' || 'PX' || 'PSX' || 'PSN')) {
          this.openModalImportHistory.emit(res);
        }
      })
      ,
      
      //listen double click slot, open modal
      this.containers.containersDblClickEvent.subscribe((res) => {
        this.shapeSelected = res;

        let slotMap = this.slotDataMap.find(
          (x) => x.shape._id == this.shapeSelected._id
        );
        if (!slotMap) {
          this.slotSelected.slotData = {};
          this.slotSelected.shape = this.shapeSelected;
          this.mapSlotDataWithContainer(
            this.slotSelected.slotData,
            this.slotSelected.shape
          );
        } else {
          this.slotSelected = slotMap;
        }
        this.openModalSlot.emit(this.slotSelected);
      }),

      //listen modal slot infomation save event
      this.slotDataFromModal$.subscribe((res: any) => {
        if (res === 'delete') {
          this.stage.clickStartShape.parent.remove();
          this.layer.batchDraw();
          return;
        }

        if (
          res &&
          res.used_percent != this.slotSelected.slotData.used_percent
        ) {
          this.slotSelected.slotData.used_percent = res.used_percent;
        }

        this.containers.addPercentToLineJoin(
          this.shapeSelected,
          this.slotSelected.slotData.used_percent
        );
        if (!isAdmin) {
          this.containers.saveContainer();
          return;
        }
        let text = this.shapeSelected.parent.find('Text');
        if (text.length > 0) {
          text.text(this.slotSelected.slotData.code);
          text.align('center');
          this.layer.batchDraw();
        } else {
          let x, y;
          switch (Object.getPrototypeOf(this.shapeSelected).className) {
            case 'Line':
              x =
                this.shapeSelected.attrs.points[0] *
                  this.shapeSelected.scaleX() +
                this.shapeSelected.getPosition().x;
              y =
                this.shapeSelected.attrs.points[1] *
                  this.shapeSelected.scaleY() +
                this.shapeSelected.getPosition().y;
              break;
            case 'Circle':
              x =
                this.shapeSelected.x() -
                this.shapeSelected.radius() * this.shapeSelected.scaleX();
              y =
                this.shapeSelected.y() -
                this.shapeSelected.radius() * this.shapeSelected.scaleY();
              break;
            default:
              break;
          }
          this.containers.addTextToShape(
            x,
            y,
            this.shapeSelected,
            this.slotSelected.slotData.code
          );
        }
        this.layer.batchDraw();
      }),
      //listen clone event to mapdata
      this.containers.containersEmitToMapEvent.subscribe((res) => {
        this.slotDataMap.forEach((item, index) => {
          if (item.shape.parent._id == res.shape.parent._id) {
            let newSlotData = { ...item.slotData };
            delete newSlotData.id;
            this.mapSlotDataWithContainer(newSlotData, res.clone);
            this.containers.addPercentToLineJoin(
              res.clone,
              newSlotData.used_percent
            );
            return;
          }
        });
      }),
      this.containers.containerSelectedEvent.subscribe((e) => {
        let dataIndex = this.slotDataMap.find(
          (x) => x.shape._id == e.currentTarget._id
        ).slotData;
        if (
          e.newVal &&
          this.lstSlotSelected.some((x) => x.id == e.currentTarget._id) == false
        ) {
          this.lstSlotSelected.length == 0
            ? (this.slotNameSelected = dataIndex.code)
            : (this.slotNameSelected =
                dataIndex.code + ', ' + this.slotNameSelected);
          this.lstSlotSelected.unshift({
            id: dataIndex.id,
            code: dataIndex.code,
            used_percent: dataIndex.used_percent,
            zone_id: dataIndex.zone_id,
          });
        } else {
          let indexOf = this.lstSlotSelected.findIndex(
            (x) => x == dataIndex.id
          );
          this.lstSlotSelected.splice(indexOf, 1);
          indexOf == this.lstSlotSelected.length
            ? (this.slotNameSelected = this.slotNameSelected.replace(
                ', ' + dataIndex.code,
                ''
              ))
            : (this.slotNameSelected = this.slotNameSelected.replace(
                dataIndex.code + ',',
                ''
              ));
        }
        this.selectSlotEvent.emit({
          text: this.slotNameSelected,
          lstSlotSelected: this.lstSlotSelected,
        });
      }),

      this.reloadSelectSlot.subscribe((res: any) => {
        this.slotNameSelected = '';
        this.lstSlotSelected = [];
        this.selectSlotEvent.emit({
          text: '',
          lstSlotSelected: [],
        });
      })
    );
  }
  getBackgroundImg(imageUrl) {
    //add background Images
    if (this.layer.children[0].name() == 'bgImage') {
      this.layer.children[0].remove();
      this.layer.batchDraw();
    }
    Konva.Image.fromURL(imageUrl, (img) => {
      img.setAttrs({
        name: 'bgImage',
        x: 0,
        y: 0,
        // width: Math.round(this.stage.attrs.width),
        // height: Math.round(this.stage.attrs.height),
        width: 1084,
        height: 768,
        padding: 10,
        enhance: 1,
        scaleX: 1,
        scaleY: 1,
      });
      this.layer.add(img);
      img.moveToBottom();
      this.layer.draw();
    });
  }
  mapSlotDataWithContainer(slotData, shape) {
    this.slotDataMap.push({
      shape: shape,
      slotData: slotData,
    });
  }
  addKeydownListenerEvent() {
    let container = this.stage.container();
    //remove event before add new event
    container.removeEventListener('keydown', (e) => this.deleteShapeByKey(e));
    container.tabIndex = 1;
    container.focus();
    container.style.outline = 'none';
    container.style.zIndex = '1';
    //add keydown event
    container.addEventListener('keydown', (e) => this.deleteShapeByKey(e));
  }
  deleteShapeByKey(e) {
    if (
      e.keyCode === 46 &&
      this.stage.clickStartShape?.parent.getAttr('name') == 'gShape'
    ) {
      let _this = this;
      let title = 'Xóa ô';
      let content = 'Bạn có chắc chắn muốn xóa ô này?';
      _this.confirmService.deleteConfirm(title, content, true);
      _this.confirmService.open$
        .pipe(
          filter((event) => !!event),
          filter((event) => {
            return event.type === 'delete' || event.type === 'close';
          }),
          take(1)
        )
        .subscribe((events) => {
          if (events.type == 'delete') {
            this.stage.clickStartShape?.parent.remove();
            this.confirmService.close();
          } else {
            this.confirmService.close();
          }
        });

      e.preventDefault();
      this.layer.batchDraw();
      this.slotDataMap.forEach((item, index) => {
        if (item.shape.parent._id == this.stage.clickStartShape.parent._id) {
          this.slotDataMap.splice(index, 1);
          return;
        }
      });
    }
  }
  moveShapeByKey(e) {}
}
