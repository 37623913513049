<div class="grid grid-cols-12 gap-4 px-16px py-16px max-h-500px">
    <div class="col-span-12">
        <input type="checkbox" name="checkAll" id="all" class="mr-16px ml-10px" [(ngModel)]="selectAll" (change)="onChange($event)">
        <label for="all">Tất cả</label>
    </div>
    <div class="col-span-12 grid grid-cols-12 gap-4 overflow-y-auto max-h-300px">
        <div *ngFor="let item of lstCell" class="col-span-4">
            <div class="rounded bg-greyish-brown p-10px" *ngIf="isExport">
                <input type="checkbox" class="mr-10px border-none" [(ngModel)]="item.isSelected" [id]="item.code">
                <label class="w-full" [for]="item.code">{{item.code}} ({{item.quantity}})</label>
            </div>
            <div [ngClass]="item.used_percent>0 ? 'bg-primary-blue' : 'bg-greyish-brown'" class="rounded p-10px" *ngIf="!isExport">
                <input type="checkbox" class="mr-10px border-none" [(ngModel)]="item.isSelected" [id]="item.code">
                <label class="w-full" [for]="item.code">{{item.code}} ({{item.used_percent}}%)</label>
            </div>
        </div>
    </div>
    
    <div class="col-span-12 flex flex-row-reverse mt-10px">
        <button
            class="btn-primary-add"
            type="button"
            (click)="submit()"
            >
            Xác nhận
        </button>
        <button class="btn-text-cancel" (click)="closeModalCell(null)">Hủy</button>
    </div>
</div>