import {
  Component,
  Input,
  OnInit,
  ElementRef,
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Konva from 'konva';
import { Subject } from 'rxjs';
import {
  InventoryMapService,
  InventoryZoneService,
  SlotService,
} from 'src/app';
import { ToastrService } from 'src/app/core/services/toastr.service';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { AppInventoryContainerModalComponent } from '../app-inventory-container-modal/app-inventory-container-modal.component';
import { InventoryMap } from '../objects/inventory-object/inventory-map';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { Slot } from 'src/app/model/slot';
import { importExpr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'meu-app-inventory-management-editor',
  templateUrl: './app-inventory-management-editor.component.html',
  styleUrls: ['./app-inventory-management-editor.component.scss'],
})
export class AppInventoryManagementEditorComponent implements OnInit {
  @Input() zoneId;
  @Input() width;
  @Input() height;
  @Input() isCheckSlot: boolean = false;
  @Input() isCheckSlots: boolean = null;
  @Input() isAdmin: boolean;
  @Input() openModal = false;
  @Input() isOpenModal = false;
  @Output() selectSlot = new EventEmitter();
  @Output() updatePercent = new EventEmitter();
  @Output() openModalImport = new EventEmitter();
  @Output() openModalExport = new EventEmitter();
  @Output() openModalChangePosition = new EventEmitter();
  @ViewChild('inputImage') inputImage: ElementRef;
  @ViewChild(AppInventoryContainerModalComponent) modalComponent;
  stage: Konva.Stage;
  layer: Konva.Layer;
  topMenu: any;
  shapes: any = [];
  map;
  isUpdatePercent: boolean;
  options: MxModalOption = {};
  inventoryMapData = [];
  dataFromContainer: Slot = {};
  mapImage: any = '/assets/images/sodokholanh.png';
  fileUpload;
  imExport_code: string;
  titleModal: string;
  constructor(
    private cdr: ChangeDetectorRef,
    private inventoryMapService: InventoryMapService,
    private domSanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private inventoryZoneService: InventoryZoneService,
    private slotService: SlotService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.zoneId &&
      changes.zoneId.currentValue != changes.zoneId.previousValue
    ) {
      this.getInventoryZoneById(this.zoneId);
      this.getInventoryMapByZoneId(this.zoneId);
      this.layer = new Konva.Layer();
      // generate Stage
      this.stage = new Konva.Stage({
        container: 'container',
        width: 1070,
        height: 768,
        draggable: true,
      });

      //create new map
      this.map = new InventoryMap(
        this.stage,
        this.layer,
        this.zoneId,
        this.isAdmin
      );

      //open modal
      this.map.openModalSlot.subscribe((res) => {
        this.dataFromContainer = res?.slotData;
        this.openModal = true;        
      });
      
      // open modal import history
      this.map.openModalImportHistory.subscribe((res: string) => {
        this.imExport_code = res;
        if(this.imExport_code.includes('PN')) {
          this.titleModal = 'Chi tiết phiếu nhập hàng';
        } else if (this.imExport_code.includes('PX')) {
          this.titleModal = 'Chi tiết phiếu xuất hàng';
        } else if (this.imExport_code.includes('PSX')) {
          this.titleModal = 'Chi tiết phiếu sang xe';
        } else {
          this.titleModal = 'Chi tiết phiếu khai báo';
        }          
        this.options.styleClass = 'w-11/12';
        this.isOpenModal = true;        
      })      

      //data container save
      this.map.mapData.subscribe((res) => {
        let slot = { slots: res, zoneId: this.zoneId };
        this.saveDataInventoryMap(this.zoneId, slot);
      });

      // add map bg image
      this.map.addMapImage.subscribe((x) => {
        this.inputImage.nativeElement.click();
      });

      // listen select slot return data
      this.map.selectSlotEvent.subscribe((res) => {
        this.selectSlot.emit(res);
      });
    }
    if (
      this.stage &&
      changes.width &&
      changes.width.currentValue != changes.width.previousValue
    ) {
      this.stage.setAttrs({
        width: this.width,
        height: this.height,
      });
    }

    if (
      changes.isCheckSlot &&
      changes.isCheckSlot.currentValue != changes.isCheckSlot.previousValue &&
      changes.isCheckSlot.previousValue != undefined
    ) {
      this.getInventoryMapByZoneId(this.zoneId);
      if (this.dataFromContainer.id) {
        this.updateData(this.dataFromContainer.id);
      }
    }
    if (
      changes.isCheckSlots &&
      changes.isCheckSlots.currentValue != changes.isCheckSlots.previousValue &&
      changes.isCheckSlots.previousValue != undefined
    ) {
      this.map.reloadSelectSlot.next(true);
      this.getInventoryMapByZoneId(this.zoneId);
    }
  }
  ngOnInit(): void {
    this.options.callBackAfterClose = (e) => {
      this.openModal = false;
      this.isOpenModal = false;
    };
    this.addKeydownListenerEvent();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.cdr.detach();
  }

  closeModal() {
    this.openModal = false;    
  }
 
  closeModalImport() {
    this.isOpenModal = false;
  }

  updateData(idSlot) {
    this.slotService.apiSlotIdInfoGet(idSlot).subscribe((res) => {
      if (res.success == true) {
        this.dataFromContainer = res.data;
      }
    });
  }
  getInventoryZoneById(zoneId) {
    this.inventoryZoneService.apiInventoryZoneIdGet(zoneId).subscribe((res) => {
      if (res.success) {
        if (res.data.plan_map_image_path) {
          this.mapImage =
            environment.backendhost + res.data.plan_map_image_path;
        } else {
          this.mapImage = '/assets/images/sodokholanh.png';
      
  }        this.map.getBackgroundImg(this.mapImage);
      }
    });
  }
  getInventoryMapByZoneId(zoneId) {
    this.inventoryMapService
      .apiInventoryMapZoneIdGet(zoneId)
      .subscribe((res) => {
        if (res.success) {
          this.inventoryMapData = res.data.slots;
          this.map.inventoryMapData$.next(this.inventoryMapData);
        }
      });
  }
  saveDataInventoryMap(zoneId, mapData) {
    if (this.fileUpload) {
      this.uploadBackGroundImg(zoneId, this.fileUpload);
    }
    this.inventoryMapService.apiInventoryMapSaveDataPost(mapData).subscribe(
      (res) => {
        if (res.success) {
          this.toastrService.success('Lưu thành công');
          this.updatePercent.emit(true);
        } else {
          this.toastrService.error(res.message);
        }
      },
      (error) => {
        this.toastrService.error('Đã có lỗi xảy ra');
      }
    );
  }
  modalEventReturn(slotData) {
    // this.dataFromContainer = slotData;
    this.map.slotDataFromModal$.next(slotData);
  }
  updateImage(event) {
    this.fileUpload = event.target.files[0];
    const blob = new Blob([this.fileUpload], {
      type: 'application/json',
    });
    // this.mapImage = this.domSanitizer.bypassSecurityTrustUrl(
    //   URL.createObjectURL(blob)
    // );
    this.mapImage = URL.createObjectURL(blob);
    this.map.getBackgroundImg(this.mapImage);
  }
  addKeydownListenerEvent() {
    let container = this.stage.container();
    //remove event before add new event
    container.removeEventListener('keydown', (e) => this.deleteShapeByKey(e));
    container.tabIndex = 1;
    container.focus();
    container.style.outline = 'none';
    container.style.zIndex = '1';
    //add keydown event
    container.addEventListener('keydown', (e) => this.deleteShapeByKey(e));
  }
  deleteShapeByKey(e) {
    let _this = this;
    if (
      e.keyCode === 46 &&
      this.stage.clickStartShape?.parent.getAttr('name') == 'gShape'
    ) {
      this.modalComponent.removeSlot();
    }
  }
  uploadBackGroundImg(zoneId, img) {
    this.inventoryZoneService
      .apiInventoryZoneIdUploadPut(zoneId, img)
      .subscribe(
        (res) => {
          if (!res.success) {
            this.toastrService.error(res.message);
          }
        },
        (err) => {
          this.toastrService.error('Không thể tải lên tệp');
        }
      );
  }
}
