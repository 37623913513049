<div
  [ngClass]="mobileMenuOpen ? 'visible left-0' : '-left-180px invisible'"
  class="h-full fixed z-40 transition-all duration-300 top-46px border-t border-white-two text-14px w-180px bg-primary-dodger-blue"
  #menu
>
  <ng-template ngFor let-item [ngForOf]="menuItem" let-i="index">
    <a
      class="relative w-full"
      [routerLink]="item.page.replace('{{inventoryId}}', this.inventorySelected)"
      (click)="onClickMenu(item, i, $event)"
      *ngIf="item.isHasPermission"
    >
      <div
        class="flex px-3 py-4 flex-nowrap capitalize relative whitespace-nowrap justify-between"
        [ngClass]="[
          item.selected ? 'bg-cerulean-blue ' : 'bg-primary-dodger-blue'
        ]"
      >
        <div class="flex items-center">
          <img [src]="item.icon" />
          <span class="pl-10px text-grey-lightest">{{ item.title }}</span>
        </div>
        <svg
          *ngIf="item.submenu"
          fill="#ffffff"
          viewBox="0 0 20 20"
          [ngClass]="{ 'rotate-180': item.open, 'rotate-0': !item.open }"
          class="inline w-4 h-4 mt-1 transition-transform duration-200 transform"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      <a
        [ngClass]="item.open ? 'block' : 'hidden'"
        class="dropdown w-full transition-all duration-300"
        #dropdown
      >
        <div
          class="z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <ng-template
            ngFor
            let-subItem
            [ngForOf]="item.submenu"
            let-subIndex="index"
          >
            <div
              class="pl-10 pr-3 py-4 flex"
              [ngClass]="subItem.selected ? 'bg-cerulean-blue' : null"
              role="none"
              (click)="submenuClick(subItem, i, $event)"
            >
              <img [src]="item.icon" />
              <a
                [routerLink]="subItem.page.replace('{{inventoryId}}', this.inventorySelected)"
                class="w-full pl-10px block text-grey-lightest text-sm pointer-events-auto"
                tabindex="-1"
                >{{ subItem.title }}</a
              >
            </div>
          </ng-template>
        </div>
      </a>
    </a>
  </ng-template>
</div>
