import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';
import { AuthenticateService } from './api/authenticate.service';
import { ContainerService } from './api/container.service';
import { ContainerChargeHistoryService } from './api/containerChargeHistory.service';
import { ContainerImportHistoryService } from './api/containerImportHistory.service';
import { ContainerProductHistoryService } from './api/containerProductHistory.service';
import { ContainerSellHistoryService } from './api/containerSellHistory.service';
import { ContainerSellProductHistoryService } from './api/containerSellProductHistory.service';
import { ContainerSellProductPaymentService } from './api/containerSellProductPayment.service';
import { ContainerSummaryHistoryService } from './api/containerSummaryHistory.service';
import { ContractService } from './api/contract.service';
import { DashboardService } from './api/dashboard.service';
import { DebugService } from './api/debug.service';
import { DeleteHistoryNoteService } from './api/deleteHistoryNote.service';
import { ExportService } from './api/export.service';
import { FreightService } from './api/freight.service';
import { ImportExportHistoryService } from './api/importExportHistory.service';
import { InventoryService } from './api/inventory.service';
import { InventoryMapService } from './api/inventoryMap.service';
import { InventoryTypeService } from './api/inventoryType.service';
import { InventoryZoneService } from './api/inventoryZone.service';
import { ProductService } from './api/product.service';
import { ProductTemplateService } from './api/productTemplate.service';
import { ProductTemplateTreeService } from './api/productTemplateTree.service';
import { ServiceCostService } from './api/serviceCost.service';
import { ServiceCostConfigService } from './api/serviceCostConfig.service';
import { ServiceCostHistoryService } from './api/serviceCostHistory.service';
import { SkuService } from './api/sku.service';
import { SlotService } from './api/slot.service';
import { StockAvailableService } from './api/stockAvailable.service';
import { SupplierService } from './api/supplier.service';
import { UnitService } from './api/unit.service';
import { UserService } from './api/user.service';
import { UserDebtService } from './api/userDebt.service';
import { UserDebtPaymentService } from './api/userDebtPayment.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AuthenticateService,
    ContainerService,
    ContainerChargeHistoryService,
    ContainerImportHistoryService,
    ContainerProductHistoryService,
    ContainerSellHistoryService,
    ContainerSellProductHistoryService,
    ContainerSellProductPaymentService,
    ContainerSummaryHistoryService,
    ContractService,
    DashboardService,
    DebugService,
    DeleteHistoryNoteService,
    ExportService,
    FreightService,
    ImportExportHistoryService,
    InventoryService,
    InventoryMapService,
    InventoryTypeService,
    InventoryZoneService,
    ProductService,
    ProductTemplateService,
    ProductTemplateTreeService,
    ServiceCostService,
    ServiceCostConfigService,
    ServiceCostHistoryService,
    SkuService,
    SlotService,
    StockAvailableService,
    SupplierService,
    UnitService,
    UserService,
    UserDebtService,
    UserDebtPaymentService,
  ],
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
