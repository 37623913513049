/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class StockAvailableService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param contractId
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableContractIdContractIdGet(
    contractId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableContractIdContractIdGet(
    contractId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableContractIdContractIdGet(
    contractId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableContractIdContractIdGet(
    contractId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (contractId === null || contractId === undefined) {
      throw new Error(
        'Required parameter contractId was null or undefined when calling apiStockAvailableContractIdContractIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable/contract_id/${encodeURIComponent(
        String(contractId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param customerId
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableCustomerIdGet(
    customerId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableCustomerIdGet(
    customerId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableCustomerIdGet(
    customerId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableCustomerIdGet(
    customerId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (customerId === null || customerId === undefined) {
      throw new Error(
        'Required parameter customerId was null or undefined when calling apiStockAvailableCustomerIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable/${encodeURIComponent(
        String(customerId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param customerId
   * @param contractId
   * @param productId
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableCustomerIdStocksGet(
    customerId: string,
    contractId?: string,
    productId?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableCustomerIdStocksGet(
    customerId: string,
    contractId?: string,
    productId?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableCustomerIdStocksGet(
    customerId: string,
    contractId?: string,
    productId?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableCustomerIdStocksGet(
    customerId: string,
    contractId?: string,
    productId?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (customerId === null || customerId === undefined) {
      throw new Error(
        'Required parameter customerId was null or undefined when calling apiStockAvailableCustomerIdStocksGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (contractId !== undefined && contractId !== null) {
      queryParameters = queryParameters.set('contractId', <any>contractId);
    }
    if (productId !== undefined && productId !== null) {
      queryParameters = queryParameters.set('productId', <any>productId);
    }
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable/${encodeURIComponent(
        String(customerId)
      )}/stocks`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param slotId
   * @param contractId
   * @param productId
   * @param inventoryId
   * @param skuId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableGetNetweightGet(
    slotId?: string,
    contractId?: string,
    productId?: string,
    inventoryId?: string,
    skuId?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableGetNetweightGet(
    slotId?: string,
    contractId?: string,
    productId?: string,
    inventoryId?: string,
    skuId?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableGetNetweightGet(
    slotId?: string,
    contractId?: string,
    productId?: string,
    inventoryId?: string,
    skuId?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableGetNetweightGet(
    slotId?: string,
    contractId?: string,
    productId?: string,
    inventoryId?: string,
    skuId?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (slotId !== undefined && slotId !== null) {
      queryParameters = queryParameters.set('slotId', <any>slotId);
    }
    if (contractId !== undefined && contractId !== null) {
      queryParameters = queryParameters.set('contractId', <any>contractId);
    }
    if (productId !== undefined && productId !== null) {
      queryParameters = queryParameters.set('productId', <any>productId);
    }
    if (inventoryId !== undefined && inventoryId !== null) {
      queryParameters = queryParameters.set('inventoryId', <any>inventoryId);
    }
    if (skuId !== undefined && skuId !== null) {
      queryParameters = queryParameters.set('skuId', <any>skuId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable/getNetweight`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param inventoryId
   * @param filter
   * @param fromDate
   * @param toDate
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableInventoryIdExpiryProductGet(
    inventoryId: string,
    filter?: string,
    fromDate?: Date,
    toDate?: Date,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableInventoryIdExpiryProductGet(
    inventoryId: string,
    filter?: string,
    fromDate?: Date,
    toDate?: Date,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableInventoryIdExpiryProductGet(
    inventoryId: string,
    filter?: string,
    fromDate?: Date,
    toDate?: Date,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableInventoryIdExpiryProductGet(
    inventoryId: string,
    filter?: string,
    fromDate?: Date,
    toDate?: Date,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (inventoryId === null || inventoryId === undefined) {
      throw new Error(
        'Required parameter inventoryId was null or undefined when calling apiStockAvailableInventoryIdExpiryProductGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (filter !== undefined && filter !== null) {
      queryParameters = queryParameters.set('filter', <any>filter);
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = queryParameters.set(
        'fromDate',
        <any>fromDate.toISOString()
      );
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = queryParameters.set(
        'toDate',
        <any>toDate.toISOString()
      );
    }
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable/${encodeURIComponent(
        String(inventoryId)
      )}/expiryProduct`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param slotId
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStockAvailableSlotIdSlotIdGet(
    slotId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStockAvailableSlotIdSlotIdGet(
    slotId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStockAvailableSlotIdSlotIdGet(
    slotId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStockAvailableSlotIdSlotIdGet(
    slotId: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (slotId === null || slotId === undefined) {
      throw new Error(
        'Required parameter slotId was null or undefined when calling apiStockAvailableSlotIdSlotIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/stockAvailable/slot_id/${encodeURIComponent(
        String(slotId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
