<div class="container grid-contain">
  <div class="col-span-full grid grid-cols-12 h-auto font-roboto">
    <div
      class="col-span-9 container-12 content-m content-ipad container-grid-12-ipad container-12-ipad col-span-full-ipad"
    >
      <div
        class="col-span-full grid grid-cols-12 mt-16px col-span-full-ipad container-12-ipad z-20"
      >
        <div
          class="col-span-8 margin-mt-21 container-7 items-center justify-items-center mt-5px col-span-6-ipad"
        >
          <p
            class="text-16px text-left font-roboto-medium title-dashboard leading24 text-dark-grey"
          >
            Tổng quan kho hàng
          </p>
        </div>
        <div class="col-span-4 container-5 col-span-6-ipad">
          <div class="margin-mt-13 w-full h-40px flex flex-row-reverse">
            <!-- date -->
            <div class="text-left rounded-4px z-index-2 w-full">
              <div *ngIf="dateSelected && !isMobile" class="dropdown">
                <a
                  type="button"
                  href="javascript:void(0)"
                  class="flex text-sm rounded-md focus:outline-none items-center h-38px"
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-controls="layout-user-action"
                >
                  <div
                    class="flex justify-between items-center font-roboto-medium w-160px bg-white rounded-4px text-grey-label cursor-pointer text-14px pl-12px pr-7px h-full mt--5px py-6px shadow-sm"
                  >
                    <div class="flex items-center gap-8px">
                      <img
                        class="w-20px h-20px"
                        [src]="dateSelected?.iconUrl"
                      />
                      {{ dateSelected?.name || '--' }}
                    </div>
                    <img
                      src="/assets/icons/product-categories-icons/u_angle-down.svg"
                      alt=""
                      class="ml-5px w-20px h-20px"
                    />
                  </div>
                </a>

                <div
                  class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
                >
                  <div
                    class="absolute left-0 md:right-0 w-160px mt-1 origin-top-right bg-white text-grey-med rounded-md shadow outline-none"
                    aria-labelledby="headlessui-menu-button-2"
                    id="layout-user-action"
                    role="menu"
                  >
                    <div class="py-2">
                      <div
                        class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                        *ngFor="let date of dateOption"
                        (click)="onChangeDate(date)"
                      >
                        <div class="flex gap-8px">
                          <img class="w-20px h-20px" [src]="date?.iconUrl" />
                          {{ date.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isMobile" class="w-full">
                <ng-select
                  class="mt-8px border-none focus:outline-none custome-select-date shadow-sm"
                  bindLabel="name"
                  bindValue="id"
                  [clearable]="false"
                  [items]="dateOption"
                  [searchable]="false"
                  [(ngModel)]="dateMobileSelected"
                  (change)="getValueDate($event)"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <div class="flex items-center space-x-2">
                      <img
                        height="20"
                        width="20"
                        [src]="item.iconUrl"
                        class=""
                      />
                      <span class="text-14px font-roboto-regular text-dark-grey">{{
                        item.name
                      }}</span>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="flex items-center space-x-2">
                      <img height="20" width="20" [src]="item.iconUrl" />
                      <span>{{ item.name }}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            
            <!-- report -->
            <button
              class="bg-white rounded-4px ml-10px ml-50-ipad hide-m mr-8px flex items-center w-full border-export shadow-sm font-roboto-medium text-14px text-center pl-25px outline-none text-grey-med"
              (click)="openExportReport()"
            >
              <img
                src="assets/icons/dashboard-client/u_print.svg"
                class="mr-4px w-20px h-20px"
                alt=""
              />
              Xuất báo cáo
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-span-12 grid grid-cols-12 gap-2 container-12 container-12-ipad mt-8px"
      >
        <div
          class="col-span-12 flex items-stretch sm:items-center m-dp-fl z-index-1"
        >
          <!-- Tổng -->
          <div
            class="col-span-2 h-84px w-250px rounded-8px mr-8px sm:grid sm:grid-cols-12 flex items-stretch bg-light-blue shadow-sm container-12 width-ipad-full mt-ipad-10 width-m-full margin-mt-8"
          >
            <img
              src="../../../../assets/icons/dashboard-icons/Finance_Monthly_Salary.svg"
              alt=""
              class="col-span-2 w-28px h-28px mt-28px ml-12px"
            />
            <div
              class="col-span-7 ml-16px text-white font-roboto-medium mt-22px m-w-100"
            >
              <p
                class="text-20px w-128px h-24px truncate"
                [tooltip]="valueTotal | meucurrency"
              >
                {{ valueTotal | meucurrency }}
              </p>
              <p class="text-12px h-16px leading16 mt-2px">Doanh thu</p>
            </div>
            <!-- <div class="col-span-3 mt-48px flex items-stretch margin-ml-none margin-mr-20 text-white hideIpad">
                            <img src="../../../../assets/icons/dashboard-icons/Combined Shape.svg" alt="" class="w-8px h-8px">
                            <p class="w-36px h-16px text-11px">+12,5%</p>
                        </div> -->
          </div>
          <!-- hàng hóa hết hạn -->
          <button
            (click)="openModalExpired()"
            class="text-left col-span-2 bg-white h-84px w-249px rounded-8px mr-8px shadow-sm sm:grid sm:grid-cols-12 flex items-stretch container-12 width-m-full margin-mt-8 width-ipad-full mt-ipad-10 focus:outline-none"
          >
            <img
              src="../../../../assets/icons/dashboard-icons/Notifications_alert-circle.svg"
              alt=""
              class="w-28px h-28px mt-28px ml-12px col-span-2"
            />
            <div
              class="col-span-7 ml-16px text-grey-med font-roboto-medium mt-22px m-w-100 col-span-10-ipad"
            >
              <p class="text-20px w-128px h-24px truncate">
                {{ valueExpired }}
              </p>
              <p class="text-12px h-16px leading16 mt-2px">Hàng hóa hết hạn</p>
            </div>
            <!-- <div class="col-span-3 mt-48px flex items-stretch text-grey-med margin-ml-none margin-mr-20 hideIpad" >
                            <img *ngIf="isIncrExpdate else isDesExp" src="../../../../assets/icons/dashboard-icons/Combined Shape (green).svg" alt="" class="w-8px h-8px">
                            <ng-template #isDesExp>
                                <img src="../../../../assets/icons/dashboard-icons/down-chart-percent.svg" alt="" class="w-8px h-8px">
                            </ng-template>
                            <p class="w-36px h-16px text-11px ">+--%</p>
                        </div> -->
          </button>
          <!-- phiếu nhập hàng -->
          <button
            (click)="openTotalImport()"
            class="text-left col-span-2 bg-white h-84px w-249px rounded-8px mr-8px shadow-sm sm:grid sm:grid-cols-12 flex items-stretch container-12 width-m-full margin-mt-8 width-ipad-full mt-ipad-10 focus:outline-none"
          >
            <img
              src="../../../../assets/icons/dashboard-icons/Misc_box.svg"
              alt=""
              class="w-28px h-28px mt-28px ml-12px col-span-2"
            />
            <div
              class="col-span-7 ml-16px text-grey-med font-roboto-medium mt-22px m-w-100 col-span-10-ipad"
            >
              <p class="text-20px w-128px h-24px truncate">{{ valueImport }}</p>
              <p class="text-12px h-16px leading16 mt-2px">Phiếu nhập hàng</p>
            </div>
            <div
              class="col-span-3 mt-48px flex items-stretch text-grey-med margin-ml-none margin-mr-20 hideIpad"
            >
              <img
                *ngIf="isIncrImport; else isDes"
                src="../../../../assets/icons/dashboard-icons/Combined Shape (green).svg"
                alt=""
                class="w-8px h-8px"
              />
              <ng-template #isDes>
                <img
                  src="../../../../assets/icons/dashboard-icons/down-chart-percent.svg"
                  alt=""
                  class="w-8px h-8px"
                />
              </ng-template>
              <p class="w-36px h-16px text-11px">+{{ percentImport }}%</p>
            </div>
          </button>
          <!-- phiểu xuất hàng -->
          <button
            (click)="openTotalExport()"
            class="text-left col-span-2 bg-white h-84px w-249px rounded-8px shadow-sm sm:grid sm:grid-cols-12 flex items-stretch container-12 width-m-full margin-mt-8 width-ipad-full mt-ipad-10 focus:outline-none"
          >
            <img
              src="../../../../assets/icons/dashboard-icons/Finance_Transportation.svg"
              alt=""
              class="w-28px h-28px mt-28px ml-12px col-span-2"
            />
            <div
              class="col-span-7 ml-16px text-grey-med font-roboto-medium mt-22px m-w-100 col-span-10-ipad"
            >
              <!-- <p class="text-20px w-128px h-24px truncate">{{valueExpire}}</p> -->
              <p class="text-20px w-128px h-24px truncate">{{ valueExport }}</p>
              <p class="text-12px h-16px leading16 mt-2px">Phiếu xuất hàng</p>
            </div>
            <div
              class="col-span-3 mt-48px flex items-stretch text-grey-med margin-ml-none margin-mr-20 hideIpad"
            >
              <img
                *ngIf="isIncrExport; else isDesEx"
                src="../../../../assets/icons/dashboard-icons/Combined Shape (green).svg"
                alt=""
                class="w-8px h-8px"
              />
              <ng-template #isDesEx>
                <img
                  src="../../../../assets/icons/dashboard-icons/down-chart-percent.svg"
                  alt=""
                  class="w-8px h-8px"
                />
              </ng-template>
              <p class="w-36px h-16px text-11px">+{{ percentExport }}%</p>
            </div>
          </button>
        </div>
        <div class="col-span-12 h-24px">
          <p
            class="text-16px text-left font-roboto-medium title-dashboard leading24 text-dark-grey"
          >
            Thống kê
          </p>
        </div>
        <!-- chart line -->
        <div
          class="col-span-9 bg-white rounded-4px shadow-sm h-220px col-span-full-ipad container-12 h-235px-m"
        >
          <button
            class="grid grid-cols-12 w-full pt-6px pl-16px focus:outline-none"
          >
            <div
              class="col-span-11 text-16px font-roboto-medium leading-6 text-left"
            >
              Tốc độ nhập và xuất kho theo thời gian
            </div>
            <div class="col-span-1">
              <img
                src="../../../../assets/icons/dashboard-icons/down-small.svg"
                alt=""
                class="ml-35px chart-line-icon-m"
              />
            </div>
          </button>
          <div id="chart-line" class="chart-line-m">
            <apx-chart
              [series]="chartLineOptions.seriesLine"
              [chart]="chartLineOptions.chartLine"
              [xaxis]="chartLineOptions.xaxisLine"
              [yaxis]="chartLineOptions.yaxisLine"
              [grid]="chartLineOptions.gridLine"
              [stroke]="chartLineOptions.strokeLine"
              [colors]="chartLineOptions.colors"
              [noData]="chartLineOptions.noData"
              [legend]="chartLineOptions.legendLine"
            ></apx-chart>
            <!-- [tooltip]="chartLineOptions.tooltipLine" -->
          </div>
        </div>
        <!-- doughnut chart -->
        <div
          class="col-span-3 z-index-1 bg-white rounded-4px shadow-sm h-220px items-center container-12 col-span-5-ipad h-300-ipad h-350-m"
        >
          <button
            class="flex items-stretch w-full pt-6px pl-16px focus:outline-none"
          >
            <div class="text-16px font-roboto-medium leading-6">
              Tình trạng kho hàng
            </div>
            <img
              src="../../../../assets/icons/dashboard-icons/down-small.svg"
              alt=""
              class="ml-55px m-ml-140px"
            />
          </button>
          <div
            class="cssPercent relative pt-5px pt-20p-ipad text-webkit-center mt-25-ipad mt-30-m"
          >
            <div class="relative">
              <canvas id="doughnut"></canvas>
              <!-- <div
                              class="absolute top-65px left-75px text-18px font-semibold cssCustoms"
                            >
                              {{ userdInventory$ | async }}%
                            </div> -->
              <div
                class="absolute top-65px left-77px text-18px font-semibold cssCustoms"
              >
                {{ used_Inventory }}%
              </div>
            </div>
          </div>
        </div>

        <!-- % product  of category -->
        <div
          class="col-span-5 h-300px bg-white rounded-4px shadow-sm col-span-7-ipad container-12 treemap-h-m"
        >
          <button
            class="grid grid-cols-12 w-full pt-6px pl-16px focus:outline-none"
          >
            <div
              class="col-span-11 text-16px font-roboto-medium leading-6 text-left"
            >
              Thống kê tỷ lệ hàng hóa theo phân loại hàng
            </div>
            <div class="col-span-1">
              <img
                src="../../../../assets/icons/dashboard-icons/down-small.svg"
                alt=""
                class="ml-7px m-ipad m-ml-0px"
              />
            </div>
          </button>
          <div id="char-treemap" class="px-16px treemap-ipad treemap-m">
            <apx-chart
              [series]="chartTMOptions.seriesTM"
              [chart]="chartTMOptions.chartTM"
              [dataLabels]="chartTMOptions.dataLabelsTM"
              [plotOptions]="chartTMOptions.plotOptionsTM"
              [legend]="chartTMOptions.legendTM"
              [noData]="chartTMOptions.noData"
            ></apx-chart>
          </div>
        </div>

        <!-- list product of supplier -->
        <div
          class="col-span-7 bg-white rounded-4px shadow-sm h-300px col-span-full-ipad container-12 bar-h-m"
        >
          <button
            class="grid grid-cols-12 w-full pt-6px pl-16px focus:outline-none"
          >
            <div
              class="col-span-11 text-16px font-roboto-medium leading-6 text-left"
            >
              Danh sách nhập hàng theo nhà cung cấp
            </div>
            <div class="col-span-1">
              <img
                src="../../../../assets/icons/dashboard-icons/down-small.svg"
                alt=""
                class="ml-22px m-l-0-m"
              />
            </div>
          </button>
          <div id="chart-bar" class="marginsub h-w-ipad bar-chart-m bar-small">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [plotOptions]="chartOptions.plotOptions"
              [yaxis]="chartOptions.yaxis"
              [noData]="chartOptions.noData"
              [fill]="chartOptions.fill"
              [grid]="chartOptions.grid"
            ></apx-chart>
          </div>
        </div>
        <!-- report stock inventory -->
        <div
          class="col-span-12 h-350px bg-white shadow-sm rounded-4px col-span-7-ipad col-span-8-ipad-pro"
        >
          <button
            class="grid grid-cols-12 w-full pt-6px pl-16px focus:outline-none"
          >
            <div
              class="col-span-11 text-16px font-roboto-medium leading-6 text-left"
            >
              Thống kê mức tồn hàng hóa
            </div>
            <div class="col-span-1">
              <img
                src="../../../../assets/icons/dashboard-icons/down-small.svg"
                alt=""
                class="padding-destop padding-ipad"
              />
            </div>
          </button>
          <div
            id="bar-chart-negative"
            class="customRote pt-10px h-w-ipad-chart-negative negative-chart-ipad-pro negative-m"
          >
            <apx-chart
              [series]="chartNegativeOptions.series"
              [chart]="chartNegativeOptions.chart"
              [dataLabels]="chartNegativeOptions.dataLabels"
              [stroke]="chartNegativeOptions.stroke"
              [colors]="chartNegativeOptions.colors"
              [title]="chartNegativeOptions.title"
              [grid]="chartNegativeOptions.grid"
              [plotOptions]="chartNegativeOptions.plotOptions"
              [yaxis]="chartNegativeOptions.yaxis"
              [xaxis]="chartNegativeOptions.xaxis"
              [legend]="chartNegativeOptions.legend"
              [noData]="chartNegativeOptions.noData"
            ></apx-chart>
          </div>
        </div>

        <!-- recent  activity -->
        <div
          class="col-span-4-ipad-pro bg-white h-auto hidden w-full rounded recentActive-display col-span-5-ipad display-ipad shadow-sm container-12 display-m"
        >
          <div
            class="text-16px font-roboto-medium leading-6 ml-16px mt-4px mb-4px"
          >
            Các hoạt động gần đây
          </div>
          <div class="mx-8px text-grey-med recentActi-ipad-pro">
            <button
              (click)="openCurrentActivityImEx(data)"
              *ngFor="let data of dataNotifications; index as i; trackByFn"
              class="grid grid-cols-12 boxshadow-cs pt-6px text-left focus:outline-none"
            >
              <div class="col-span-1">
                <img
                  *ngIf="data.isImExport; else isExportImg"
                  src="../../../../assets/icons/dashboard-icons/u_file-download-alt.svg"
                  alt=""
                  class="ml-4px mt-16px"
                />
                <ng-template #isExportImg>
                  <img
                    src="../../../../assets/icons/dashboard-icons/u_file-upload-alt.svg"
                    alt=""
                    class="ml-4px mt-16px"
                  />
                </ng-template>
              </div>
              <div class="col-span-11 ml-8px">
                <div class="flex text-12px">
                  <div
                    *ngIf="data.isImExport; else isExport"
                    class="font-roboto-bold w-100px"
                  >
                    NHẬP HÀNG
                  </div>
                  <ng-template #isExport>
                    <div class="font-roboto-bold w-100px">XUẤT HÀNG</div>
                  </ng-template>
                  <div
                    *ngIf="data.receipt_date; else isIssueDate"
                    class="flex-col-reverse font-roboto-regular ml-40px m-ml-70px m-ml-30px"
                  >
                    {{ data.receipt_date | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </div>
                  <ng-template #isIssueDate>
                    <div
                      class="flex-col-reverse font-roboto-regular ml-40px m-ml-70px m-ml-30px"
                    >
                      {{ data.issue_date | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </div>
                  </ng-template>
                </div>
                <div
                  *ngIf="data.isImExport; else isExportTitle"
                  class="text-12px font-roboto-regular col-span-full mb-8px"
                >
                  <div>
                    <b>{{ data.name }}</b> mới tạo phiếu nhập hàng
                    <a
                      class="text-primary-dodger-blue cursor-pointer"
                      (click)="openImport(data.code)"
                      >{{ data.code }}</a
                    >
                    thành công tại ô
                    <a class="text-primary-dodger-blue">{{ data.slot }} </a>
                  </div>
                </div>
                <ng-template #isExportTitle>
                  <div
                    class="text-12px font-roboto-regular col-span-full mb-8px"
                  >
                    <div>
                      <b>{{ data.name }}</b> mới tạo phiếu xuất hàng
                      <a
                        class="text-primary-dodger-blue cursor-pointer"
                        (click)="openExport(data.code)"
                        >{{ data.code }}</a
                      >
                      thành công tại ô
                      <a class="text-primary-dodger-blue">{{ data.slot }} </a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </button>
          </div>
          <div class="mx-8px bg-brownish-orange w-full"></div>
        </div>
      </div>
    </div>

    <div
      class="col-span-3 ml-8px bg-white wh-customs mt-16px rounded-4px shadow-sm pb-3px recentActive-ipad absolute-ipad position-ipad recentActive-ipad-pro absolute-ipad-pro position-ipad-pro width-mobile container-12 display-none-m mtop-m"
    >
      <div class="text-16px font-roboto-medium leading-6 ml-16px mt-4px mb-4px">
        Các hoạt động gần đây
      </div>
      <div class="mx-8px text-grey-med recentActi">
        <button
          (click)="openCurrentActivityImEx(data)"
          *ngFor="let data of dataNotifications; index as i; trackByFn"
          class="grid grid-cols-12 boxshadow-cs pt-6px text-left focus:outline-none"
        >
          <div class="col-span-1">
            <img
              *ngIf="data.isImExport; else isExportImg"
              src="../../../../assets/icons/dashboard-icons/u_file-download-alt.svg"
              alt=""
              class="ml-4px mt-16px"
            />
            <ng-template #isExportImg>
              <img
                src="../../../../assets/icons/dashboard-icons/u_file-upload-alt.svg"
                alt=""
                class="ml-4px mt-16px"
              />
            </ng-template>
          </div>
          <div class="col-span-11 ml-8px">
            <div class="flex text-12px">
              <div
                *ngIf="data.isImExport; else isExport"
                class="font-roboto-bold w-100px"
              >
                NHẬP HÀNG
              </div>
              <ng-template #isExport>
                <div class="font-roboto-bold w-100px">XUẤT HÀNG</div>
              </ng-template>
              <div
                *ngIf="data.receipt_date; else isIssueDate"
                class="flex-col-reverse font-roboto-regular ml-40px m-ml-70px m-ml-30px"
              >
                {{ data.receipt_date | date: 'dd/MM/yyyy HH:mm:ss' }}
              </div>
              <ng-template #isIssueDate>
                <div
                  class="flex-col-reverse font-roboto-regular ml-40px m-ml-70px m-ml-30px"
                >
                  {{ data.issue_date | date: 'dd/MM/yyyy HH:mm:ss' }}
                </div>
              </ng-template>
            </div>
            <div
              *ngIf="data.isImExport; else isExportTitle"
              class="text-12px font-roboto-regular col-span-full mb-8px"
            >
              <div>
                <b>{{ data.name }}</b> mới tạo phiếu nhập hàng
                <a
                  class="text-primary-dodger-blue cursor-pointer"
                  (click)="openCurrentImport(data.code)"
                  >{{ data.code }}</a
                >
                thành công tại ô
                <a class="text-primary-dodger-blue">{{ data.slot }} </a>
              </div>
            </div>
            <ng-template #isExportTitle>
              <div class="text-12px font-roboto-regular col-span-full mb-8px">
                <div>
                  <b>{{ data.name }}</b> mới tạo phiếu xuất hàng
                  <a
                    class="text-primary-dodger-blue cursor-pointer"
                    (click)="openCurrentExport(data.code)"
                    >{{ data.code }}</a
                  >
                  thành công tại ô
                  <a class="text-primary-dodger-blue">{{ data.slot }} </a>
                </div>
              </div>
            </ng-template>
          </div>
        </button>
        <div 
          *ngIf="totalLoadMore>10 && pageIndexLoadMore < maxPageIndex"
          (click)="loadMoreActiveNotification()"
          class="w-full p-2 cursor-pointer flex items-center justify-center text-primary-dodger-blue font-roboto-medium text-14px"
          >Xem thêm
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Export Report -->
<meu-modal-component
  [isOpen]="isOpenModalER"
  [options]="optionsER"
  [title]="titleModalER"
  *ngIf="isOpenModalER"
>
  <meu-export-report-manage
    (closeModalExport)="closeExportReport($event)"
  ></meu-export-report-manage>
</meu-modal-component>

<!-- Import -->
<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [isOpenFormModalDashBoard]="isOpenFormModalDashBoard"
    [imExport_code]="imExport_code"
    (closeModalImport)="closeModal($event)"
  ></meu-import-history>
</meu-modal-component>

<!-- Export -->
<meu-modal-component
  [isOpen]="isOpenModalExport"
  [options]="optionsExport"
  [title]="titleModalExport"
  *ngIf="isOpenModalExport"
>
  <meu-export-history
    [isOpenFormModalDashBoard]="isOpenFormModalDashBoard"
    [imExport_code]="im_Export_code"
    (closeModalExport)="closeModalExport($event)"
  ></meu-export-history>
</meu-modal-component>

<!-- Expired product --->
<meu-modal-component
  [isOpen]="isOpenExpired"
  [options]="optionModalExpired"
  *ngIf="isOpenExpired"
  [title]="titleModalExpired"
>
  <meu-expired-product-modal
    (closeModalExpired)="closeModalExpiredEvent($event)"
    [inventory_id]="inventoryId"
    [dateSelected]="dateSelected?.value"
    [fromDate]="fromDate"
    [toDate]="toDate"
  ></meu-expired-product-modal>
</meu-modal-component>

<meu-modal-component
  [isOpen]="isOpenModalPopUp"
  [options]="optionsPopUp"
  [title]="titleModalPopUp"
  *ngIf="isOpenModalPopUp"
>
  <meu-pop-up
    [inventory_id]="inventoryId"
    [filter]="dateSelected?.value"
    [type]="isImExportAdmin"
    [startDate]="fromDate"
    [endDate]="toDate"
    (closeModalImExport)="closeModalPopUp($event)"
  >
  </meu-pop-up>
</meu-modal-component>

<!-- custom date -->
<meu-modal-component
  [isOpen]="isOpenRangeDateModal"
  [options]="optionModalRangeDate"
  [title]="'Tùy chọn ngày'"
  *ngIf="isOpenRangeDateModal"
>
  <meu-date-range-modal (closeModalRangeDate)="closeRangeDateModal($event)">
  </meu-date-range-modal>
</meu-modal-component>
