import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';

@Component({
  selector: 'meu-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  inventoryId = null;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.paramMap.get('inventoryId')) {
          this.inventoryId = event.snapshot.paramMap.get('inventoryId');
        }
      }
    });
  }

  ngOnInit(): void {}
}
