import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import defaultLanguage from '../assets/i18n/vi.json';
import { ConfirmService } from './core/services/confirm.service';
import { ToastrService } from './core/services/toastr.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { FcmService } from './core/layout/notification/fcm.service';
import { NotifyService } from './core/layout/notification/notify.service';
import { environment } from 'src/environments/environment';
import { AuthenticateService } from './api/authenticate.service';
import { Subscription } from 'rxjs';
const crypto = require('asymmetric-crypto');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [FcmService, NotifyService]
})
export class AppComponent {
  title = 'erp-inventory-management-frontend';
  objectModal: any;
  toastr;
  private subscription: Subscription[] = [];
  constructor(
    private translate: TranslateService,
    private confirmService: ConfirmService,
    private toastrService: ToastrService,
    private afMessaging: AngularFireMessaging,
    private fcmService: FcmService,
    private notifyService: NotifyService,
    private authenticateService: AuthenticateService,
  ) {
    translate.setTranslation('vi', defaultLanguage);
    translate.setDefaultLang('vi');
    this.tokenHandler();
    this.getInfoUserLogged();
  }
  ngOnInit() {
    this.toastrService.toastr$.subscribe((res) => {
      this.toastr = res;
    });
    this.confirmService.open$.subscribe((x) => {
      this.objectModal = x;
    });
    this.registerNotificationDevice();
  }
  ngOnDestroy(): void {
    localStorage.removeItem(environment.authTokenKey);
    this.subscription.forEach((sb) => sb.unsubscribe());
  }
  private registerNotificationDevice() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('./assets/js/firebase-messaging-sw.js')
        .then((registration) => {
          this.afMessaging.useServiceWorker(registration);
          this.afMessaging.requestPermission
            .pipe(mergeMapTo(this.afMessaging.tokenChanges))
            .subscribe((token) => {
              this.fcmService
                .apiFcmRegisterDevicePost({
                  token: token,
                  client_os: 'web',
                })
                .toPromise()
                .then(() => {
                  this.afMessaging.messages.subscribe((res) => {
                    this.notifyService.reviceMessage(res);
                  });
                });
            });
        });
    }
  }
  private tokenHandler() {
    let token = this.getParameterByName('token');
    if (!!!token) {
      token = localStorage.getItem(environment.authTokenKey);
    } else {
      const keyPair = JSON.parse(localStorage.getItem(environment.privateKeyToken));
      const dt = JSON.parse(atob(token));
      let decryptToken = crypto.decrypt(dt.data, dt.nonce, dt.SSOServerPublicKey, keyPair.secretKey);
      //REMOVE TOKEN ON URL
      let currentURL = window.location.href;
      currentURL = currentURL.replace('?token=' + token, '');
      window.history.replaceState({}, '', currentURL);
      localStorage.setItem(environment.authTokenKey, decryptToken);
    }
  }
  private getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  getInfoUserLogged() {
    this.authenticateService.apiAuthenticateGetMyInfoGet().subscribe((res) => {
      localStorage.setItem(environment.userInfo, JSON.stringify(res.data));
    });
  }
}
