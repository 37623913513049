<div
  class="bottom-sheet-bg-overlay"
  *ngIf="isOpen"
  (click)="closeFromOutside()"
></div>
<div
  class="bottom-sheet-container {{ option.styleClass }}"
  *ngIf="isOpen"
  [@slideDownToggle]
>
  <div class="flex border-light-grey border-b border-opacity-40 h-40px">
    <div
      class="font-roboto-medium text-16px font-medium not-italic text-grey-med leading-normal ml-15px mt-8px"
    >
      {{ title }}
    </div>
    <span class="icons-cross" (click)="close()" *ngIf="!option.hideCloseButton"
      ><img [src]="'assets/icons/toast/u_close.svg'" alt="cross icon"
    /></span>
  </div>
  <div class="bottom-sheet-content">
    <ng-content></ng-content>
  </div>
</div>
