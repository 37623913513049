<div>
  <div class="w-auto m-15px">
    <div>
      <span class="text-20px font-medium text-grey-light">Số ô > </span>
      <span class="text-20px font-medium text-dark-grey">{{ slot.code }}</span>
    </div>

    <div class="mt-15px">
      <span class="font-roboto-regular text-12px text-warm-grey">
        Số lượng</span
      >
      <input
        type="number"
        spellcheck="false"
        [(ngModel)]="slot.quantity"
        min="0"
        onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
        class="font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-40px mt-8px py-8px pl-12px border bg-white border-grayscale-light-grey hover:border-primary-light focus:outline-none focus:ring-1 focus:ring-primary-blue"
      />
    </div>
  </div>

  <div class="col-span-full flex-row-reverse gap-8px flex h-60px mr-15px">
    <button class="btn-primary-add" type="button" (click)="saveQuantity()">
      Lưu
    </button>
    <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
  </div>
</div>
