<div class="container grid-contain">
  <div
    class="col-span-12 grid grid-cols-12 px-16px bg-white shadow-md rounded-4px mt-16px"
  >
    <div
      class="col-span-12 border-grayscale-light-grey border-opacity-40 border-b py-16px title"
    >
      <!-- routerLinkActive="isSelected" -->
      <span class="text-20px font-medium text-grey-light"
        ><a [routerLink]="'/inventory/'+contracts.inventory_id+'/contract/contract-list'">Hợp đồng </a>></span
      ><span class="text-20px font-medium text-dark-grey">
        [{{ contracts.code }}] {{ contracts['extend_contract_type'] }}</span
      >
    </div>
    <div class="col-span-12 mt-8px gap-4px relative drop">
      <div class="md:flex md:flex-row md:w-full md:h-full">
        <button
          class="button-menu text-grey-light"
          routerLinkActive="isSelected"
          [routerLinkActiveOptions]="{ exact: true }"
          #info="routerLinkActive"
          [routerLink]="'/inventory/'+contracts.inventory_id+'/contract/detail/' + contracts.id"
        >
          <img
            *ngIf="info.isActive; else normal"
            src="assets/icons/detail-icon/u_info-circle-isSelected.svg"
          />
          <ng-template #normal
            ><img src="assets/icons/detail-icon/u_info-circle.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium">
            Thông tin chung</span
          >
        </button>
        <button
          class="button-menu text-grey-light"
          [routerLink]="'/inventory/'+contracts.inventory_id+'/contract/detail/' + contracts.id + '/import-export'"
          routerLinkActive="isSelected"
          #imexport="routerLinkActive"
        >
          <img
            *ngIf="imexport.isActive; else normal2"
            src="assets/icons/detail-icon/u_file-import-isSelected.svg"
          />
          <ng-template #normal2>
            <img src="assets/icons/detail-icon/u_file-import.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium"
            >Quản lý phiếu nhập / xuất</span
          >
        </button>
        <button
          class="button-menu text-grey-light"
          [routerLink]="'/inventory/'+contracts.inventory_id+'/contract/detail/' + contracts.id + '/service-cost'"
          routerLinkActive="isSelected"
          #sercost="routerLinkActive"
        >
          <img
            *ngIf="sercost.isActive; else normal3"
            src="assets/icons/detail-icon/u_dollar-alt-black.svg"
          />
          <ng-template #normal3>
            <img src="assets/icons/detail-icon/u_dollar-alt.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium"
            >Biểu phí dịch vụ</span
          >
        </button>
      </div>

      <!-- mobi menu -->
    </div>

    <div class="col-span-12" style="min-height: 500px">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
</div>
