import { DashboardManageService } from './../../dashboard/dashboard.service';
import { DashboardService } from './../../../api/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'meu-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements OnInit {
  @Input() inventory_id;
  @Input() userID: string;
  @Input() filter; // dateSelected
  @Input() type: number; // 0: Import , 1 :Export
  @Input() startDate;
  @Input() endDate;
  @Output() closeModalImExport = new EventEmitter();

  pageIndex: number = 1;
  pageSize: number = 10;

  totalArray;
  lstImExport = [];
  isImport = true;
  indexArray: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private dashboardManageService: DashboardManageService
  ) { }

  ngOnInit(): void {
    this.getImExport();
  }

  getImExport() {
    if (this.type == 0) {
      //Import
      if (this.userID) {
        this.dashboardService
          .apiDashboardInventoryIdClientImportNoteGet(
            this.inventory_id,
            this.userID,
            this.filter == 'custom' ? '' : this.filter,
            this.startDate,
            this.endDate,
            '',
            '',
            1,
            1000
          )
          .subscribe((res) => {
            if (res.success) {
              this.lstImExport = res.data.collection;
              this.totalArray = res.data.total;
              this.isImport = true;

            }
          });
        return;
      } else {
        this.dashboardService
          .apiDashboardInventoryIdImportNoteGet(
            this.inventory_id,
            this.filter == 'custom' ? '' : this.filter,
            this.startDate,
            this.endDate,
            '',
            '',
            1,
            1000
          )
          .subscribe((res) => {
            if (res.success) {
              this.lstImExport = res.data.collection;
              this.totalArray = res.data.total;
              this.isImport = true;

            }
          });
      }
      return;
    }
    {
      //Export
      if (this.userID) {
        this.dashboardService
          .apiDashboardInventoryIdClientExportNoteGet(
            this.inventory_id,
            this.userID,
            this.filter == 'custom' ? '' : this.filter,
            this.startDate,
            this.endDate,
            '',
            '',
            1,
            1000
          )
          .subscribe((res) => {
            if (res.success) {
              this.lstImExport = res.data.collection;
              this.totalArray = res.data.total;
              this.isImport = false;
            }
          });
        return;
      } else {
        this.dashboardService
          .apiDashboardInventoryIdExportNoteGet(
            this.inventory_id,
            this.filter == 'custom' ? '' : this.filter,
            this.startDate,
            this.endDate,
            '',
            '',
            1,
            1000
          )
          .subscribe((res) => {
            if (res.success) {
              this.lstImExport = res.data.collection;
              this.totalArray = res.data.total;
              this.isImport = false;
            }
          });
      }
    }
  }

  get onPagingFunc() {
    return this.onPaging.bind(this);
  }

  onPaging() { }

  ngAfterViewInit() {
    this.lstImExport = [];
  }
  onChangeValue(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getImExport();
  }

  open(IExportCode) {
    if (IExportCode) {
      this.closeModalImExport.emit(IExportCode);
    }
  }

  closeModal() {
    this.closeModalImExport.emit();
  }
}
