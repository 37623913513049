<!-- use form control -->

<!-- <ng-container *ngIf="isReadOnly; else notRead">
  <ng-select
    *ngIf="formControl; else noFormControl"
    [ngClass]="[border_input ? 'border-select' : '']"
    class="focus:outline-none custom-select-readonly"
    [placeholder]="placeholder"
    [bindValue]="bindValue"
    [notFoundText]="notFoundText"
    [formControl]="formControl"
    [items]="array"
    [searchable]="false"
    [isOpen]="false"
    [clearable]="false"
    [(ngModel)]="defaultID"
    (change)="onChangeItem($event)"
  >
    <ng-template ng-label-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ item[columnName] }}
        {{ customColumn ? '| ' + item[customColumn] : '' }}
      </div></ng-template
    >
    <ng-template ng-option-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ item[columnName] }}
        {{ customColumn ? '| ' + item[customColumn] : '' }}
      </div>
    </ng-template>
  </ng-select>

  <ng-template #noFormControl>
    <ng-select
      [ngClass]="[border_input ? 'border-select' : '']"
      class="focus:outline-none custom-select-readonly"
      [placeholder]="placeholder"
      [bindValue]="bindValue"
      [notFoundText]="notFoundText"
      [items]="array"
      [searchable]="false"
      [(ngModel)]="defaultID"
      [isOpen]="false"
      [clearable]="false"
      (change)="onChangeItem($event)"
    >
      <ng-template ng-label-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ item[columnName] }}
          {{ customColumn ? '| ' + item[customColumn] : '' }}
        </div></ng-template
      >
      <ng-template ng-option-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ item[columnName] }}
          {{ customColumn ? '| ' + item[customColumn] : '' }}
        </div>
      </ng-template>
    </ng-select>
  </ng-template>
</ng-container>
<ng-template #notRead>
  <ng-select
    *ngIf="formControl; else noFormControl1"
    [ngClass]="[
      invalidState ? 'custom-select-invalid' : 'custom-select ',
      border_input ? 'border-select' : ''
    ]"
    class="focus:outline-none"
    [placeholder]="placeholder"
    [bindValue]="bindValue"
    [notFoundText]="notFoundText"
    [formControl]="formControl"
    [items]="array"
    [searchable]="false"
    [clearable]="false"
    [(ngModel)]="defaultID"
    (change)="onChangeItem($event)"
  >
    <ng-template ng-label-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ item[columnName] }}
        {{ customColumn ? '| ' + item[customColumn] : '' }}
      </div></ng-template
    >
    <ng-template ng-option-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ item[columnName] }}
        {{ customColumn ? '| ' + item[customColumn] : '' }}
      </div>
    </ng-template>
  </ng-select>

  <ng-template #noFormControl1>
    <ng-select
      [ngClass]="[
        invalidState ? 'custom-select-invalid' : 'custom-select ',
        border_input ? 'border-select' : ''
      ]"
      class="focus:outline-none"
      [placeholder]="placeholder"
      [bindValue]="bindValue"
      [notFoundText]="notFoundText"
      [items]="array"
      [searchable]="false"
      [clearable]="false"
      [(ngModel)]="defaultID"
      (change)="onChangeItem($event)"
    >
      <ng-template ng-label-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ item[columnName] }}
          {{ customColumn ? '| ' + item[customColumn] : '' }}
        </div></ng-template
      >
      <ng-template ng-option-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ item[columnName] }}
          {{ customColumn ? '| ' + item[customColumn] : '' }}
        </div>
      </ng-template>
    </ng-select>
  </ng-template>
</ng-template>

<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div> -->

<div class="relative w-full" clickOutside (clickOutside)="closeOutside()">
  <div
    [ngClass]="[
      is_border
        ? 'border border-solid border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue'
        : '',
      is_radius ? 'rounded-4px' : '',
      invalidState ? 'border-red-light' : '',

    ]"
    class="h-40px cursor-pointer relative items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full py-8px pl-12px pr-8px bg-white overflow-hidden"
    (click)="open()"
  >
    <!-- default value and placeholder -->
    <div class="grid grid-cols-12">
      <div
        [ngClass]="[
          is_readonly && float ? float : '',
          is_readonly ? 'col-span-12' : 'col-span-10 overflow-ellipsis overflow-hidden whitespace-nowrap'
        ]"
      >
        <span
          [ngClass]="[!defaultItem ? 'placeholder' : '']"
          class="overflow-ellipsis overflow-hidden whitespace-nowrap"
        >
          {{ defaultItem || placeholder }}
        </span>
      </div>
      <div *ngIf="!is_readonly" class="col-span-2">
        <div class="mt-2px relative right-5px flex justify-end items-center">
          <!-- <img
        *ngIf="defaultItem && isOpen"
        class="w-15px h-15px"
        alt="cross icon"
        src="assets/icons/toast/u_close.svg"
        (click)="clear()"
      /> -->
          <svg
            fill="#4d4d50"
            viewBox="0 0 20 20"
            [ngClass]="{
              'rotate-180': isOpen,
              'rotate-0': !isOpen
            }"
            class="inline w-4 h-4 transition-transform duration-200 transform"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div
    class="absolute"
    [ngClass]="{ hidden: !isOpen }"
    style="z-index: 9999"
    [ngClass]="[
      !isOpen ? 'hidden' : '',
      styleCssPosition != '' ? styleCssPosition : '',

    ]"
  >
    <div
      class="pb-8px bg-white rounded-md box-shadow-custom float-right w-250px search-box custom-scroll max-h-248px"
    >
      <!-- search -->
      <div
        class="flex bg-white w-250px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
      >
        <img
          class="w-20px h-20px m-10px"
          src="../../../../assets/icons/handle-icons/Icons_search.svg"
        />
        <input
          class="w-full px-5px border-none focus:outline-none text-14px"
          placeholder="Nhập tìm kiếm..."
          (keyup)="keyUp.next($event.target.value)"
          placeholder="Tìm kiếm..."
        />
      </div>

      <!-- array -->
      <a
        *ngFor="let item of array"
        class="bg-white hover:bg-primary-lightest py-10px px-8px rounded inline-flex items-center focus:outline-none h-40px w-250px"
        href="javascript:void(0)"
        (click)="chooseItem(item)"
      >
        <span class="text-14px ml-10px text-gray overflow-x-hidden overflow-ellipsis">
          {{ customColumn ? item[customColumn] + '|' : '' }}
          {{ item[columnName] }}
        </span>
      </a>
    </div>
  </div>
</div>

<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div>
