import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractService } from 'src/app/api/contract.service';
import { ServiceCostService } from 'src/app/api/serviceCost.service';
import { ToastrService } from 'src/app/core/services';
import { MeucurrencyPipe } from 'src/app/shared/pipes/meucurrency.pipe';

@Component({
  selector: 'meu-service-cost-contract-modal',
  templateUrl: './service-cost-contract-modal.component.html',
  styleUrls: ['./service-cost-contract-modal.component.scss'],
})
export class ServiceCostContractModalComponent implements OnInit {
  @Input() idContract: string;
  @Input() listService: any[];
  @Output() closeModalService = new EventEmitter();

  service_id: string;
  nameButton: string;
  allServices = [];

  constructor(
    private contractService: ContractService,
    private serviceCostService: ServiceCostService,
    private toastr: ToastrService,
    private meuCurrencyPipe: MeucurrencyPipe
  ) {}

  createNewServiceForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    unit: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    this.setModalAddOrEdit();
    this.getAllServiceCost();
  }

  setModalAddOrEdit() {
    this.nameButton = 'Thêm';
  }

  closeModal(event = false) {
    this.closeModalService.emit({ success: event });
  }

  getAllServiceCost() {
    let arrayTemp = [...this.listService];
    for (let j = 0; j < arrayTemp.length; j++) {
      arrayTemp[j] = 'id!=' + arrayTemp[j];
    }
    this.serviceCostService
      .apiServiceCostGet(arrayTemp.join(), '', 1, 1000)
      .subscribe((data) => {
        if (data.success == true) {
          this.allServices = data.data.collection;
        }
      });
  }

  getValueServiceName(data) {
    if (data) {
      this.service_id = data.id;

      this.serviceCostService.apiServiceCostIdGet(data.id).subscribe((data) => {
        if (data.success) {
          this.createNewServiceForm.patchValue({
            amount: this.meuCurrencyPipe.transform(data.data.amount),
            unit: data.data.unit,
          });

          this.createNewServiceForm.value.amount = data.data.amount;
          this.createNewServiceForm.value.unit = data.data.unit;
        }
      });
    } else {
      this.createNewServiceForm.reset();
    }
  }

  submit() {
    const controls = this.createNewServiceForm.controls;
    if (this.createNewServiceForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.createNewServiceForm.markAllAsTouched();

    this.createNewServiceForm.value.name = this.createNewServiceForm.controls[
      'name'
    ].value.trim();
    if (this.createNewServiceForm.value.name == '') {
      this.createNewServiceForm.controls['name'].setValue(null);
    }

    let obj = {
      contract_id: this.idContract,
      service_cost_id: this.service_id,
    };

    this.contractService.apiContractServiceCostPost(obj).subscribe((res) => {
      if (res.success) {
        this.toastr.success(res.message);
        this.closeModal(true);
      } else {
        this.toastr.error(res.message);
      }
    });
  }
}
