import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'meu-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {

  constructor() { }
  @Input() title: string;
  @Input() onSearch: boolean;
  @Input() onButton: boolean;

  ngOnInit(): void {
   
  }

}
