import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/api/user.service';
import { ToastrService } from 'src/app/core/services';
import { User } from 'src/app/model/user';

@Component({
  selector: 'meu-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit {
  public idCustomer: string;
  customer: User = {};

  urlDetail: string;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private customerService: UserService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.ActivatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.idCustomer = params.id;
      }
      this.urlDetail = '/customer-management/';
      this.getDetailCustomer();
    });
  }
  onActivate(event) {
    if (event.checkUpdate) {
      event.checkUpdate.subscribe((res) => {
        if (res === true) {
          this.getDetailCustomer();
        }
      });
    }
  }
  getDetailCustomer() {
    this.customerService
      .apiUsersGet(`id==${this.idCustomer}`)
      .subscribe((res) => {
        if (res.success == true) {
          this.customer = res.data.collection[0];
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
}
