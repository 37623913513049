<form [formGroup]="formExport" class="col-span-full grid grid-cols-12 px-16px gap-4">
    <div class="col-span-12">
      <span class="font-roboto-regular text-12px text-warm-grey">Báo cáo</span>
      <ng-select
          class="mt-8px hover:border-primary-blue focus:outline-none focus:ring-primary-blue cus-max-height"
          placeholder="Chọn báo cáo"
          bindLabel="full_name"
          bindValue="id"
          [clearable]="false"
          [items]="lstReport"
          formControlName="report_name"
          (change)="getValueReport($event)"
          notFoundText="Không tìm thấy báo cáo"
        >
      </ng-select>
    </div>

    <div class="col-span-12" [hidden]="isChooseInventory">
        <span class="font-roboto-regular text-12px text-warm-grey">Loại báo cáo</span>
        <ng-select
          class="mt-8px hover:border-primary-blue focus:outline-none focus:ring-primary-blue cus-max-height"
          placeholder="Chọn loại báo cáo"
          bindLabel="full_name"
          bindValue="id"
          [clearable]="false"
          [items]="typeReport"
          formControlName="type"
          (change)="getValueTypeTemplate($event)"
          notFoundText="Không tìm thấy loại báo cáo"
        >
        </ng-select>
    </div>
    <div class="col-span-12" [hidden]="!isChooseInventory">
      <span class="font-roboto-regular text-12px text-warm-grey">Loại báo cáo</span>
      <ng-select
        class="mt-8px hover:border-primary-blue focus:outline-none focus:ring-primary-blue cus-max-height"
        placeholder="Chọn loại báo cáo"
        bindLabel="full_name"
        bindValue="id"
        [clearable]="false"
        [items]="typeReportInventory"
        formControlName="type"
        (change)="getValueTypeTemplate($event)"
        notFoundText="Không tìm thấy loại báo cáo"
      >
      </ng-select>
    </div>
    <div class="col-span-12" [hidden]="isChooseInventory">
        <span 
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            formExport.get('name').touched &&
            formExport.controls.name.errors?.required
              ? 'error-class'
              : ''
          "
          >Khách hàng</span>
        <ng-select
          class="mt-8px hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
          [clearable]="false"
          placeholder="Chọn khách hàng"
          bindLabel="full_name"
          bindValue="id"
          [items]="Customers"
          formControlName="name"
          (change)="getValueClientContract($event)"
          notFoundText="Không tìm thấy khách hàng"
          [isOpen]="isDisable"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="flex items-center space-x-2">
              <img
                height="20"
                width="20"
                [src]="item.avatar"
                class="rounded-full"
              />
              <span class="text-14px font-roboto-regular text-dark-grey">{{
                item.full_name
              }}</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="flex items-center space-x-2">
              <img height="20" width="20" [src]="item.avatar" />
              <span>{{ item.full_name }}</span>
            </div>
          </ng-template>
        </ng-select>
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            formExport.get('name').touched &&
            formExport.controls.name.errors?.required
          "
        >
          {{
            formcreate.controls.name.errors?.required
              ? 'Vui lòng chọn khách hàng'
              : ''
          }}
        </span>
    </div>
    <div class="col-span-12" [hidden]="!isChooseInventory">
        <span 
          class="font-roboto-regular text-12px text-warm-grey"
          >Kho khàng</span>
        <ng-select
          class="mt-8px hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
          [clearable]="false"
          placeholder="Chọn kho hàng"
          bindLabel="name"
          bindValue="id"
          [items]="lstInventory"
          formControlName="inventory"
          (change)="getValueInventory($event)"
          notFoundText="Không tìm thấy kho hàng"
          [isOpen]="isDisable"
        >
          <!-- <ng-template ng-label-tmp let-item="item">
            <div class="flex items-center space-x-2">
              <img
                height="20"
                width="20"
                [src]="item.image"
                class="rounded-full"
              />
              <span class="text-14px font-roboto-regular text-dark-grey">{{
                item.name
              }}</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="flex items-center space-x-2">
              <img height="20" width="20" [src]="item.image" />
              <span>{{ item.name }}</span>
            </div>
          </ng-template> -->
        </ng-select>
    </div>
    <div class="col-span-6">
        <span class="font-roboto-regular text-12px text-warm-grey">Từ ngày</span>
        <input
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfig"
          [theme]="'dp-material'"
          formControlName="start"
          [mode]="'daytime'"
          (onChange)="getDateTime($event)"
          placeholder="Chọn ngày bắt đầu"
          class="class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-38px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
        />
    </div>
    <div class="col-span-6">
        <span class="font-roboto-regular text-12px text-warm-grey">Đến ngày</span>
        <input
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfigEnd"
          [theme]="'dp-material'"
          formControlName="end"
          [mode]="'daytime'"
          (onChange)="getDateTime($event)"
          placeholder="Nhập ngày kết thúc"
          class="class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-38px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
      />
    </div>
    <div class="col-span-12 flex-row-reverse gap-8px flex h-60px">
      <button
        class="btn-primary-add"
        type="button"
        (click)="submit()"
        [disabled]="!formExport.controls.name.value"
      >
        Xuất báo cáo
      </button>
      <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
    </div>
</form>
