<div class="container grid-contain">
  <div
    class="col-span-12 grid grid-cols-12 px-16px bg-white shadow-md rounded-4px mt-16px"
  >
    <div
      class="col-span-12 flex flex-col lg:flex-row lg:justify-between mt-8px mb-6px lg:mb-0"
    >
      <div
        class="py-12px font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Quản lý loại hợp đồng
      </div>
      <div class="flex flex-wrap md:float-right items-center gap-8px">
        <div class="flex gap-8px">
          <button
            class="btn-primary-icon flex items-center gap-4px"
            (click)="addEditContractType(null)"
          >
            <img src="assets/icons/handle-icons/icon_add.svg" />
            <span>Thêm mới</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-span-12 overflow-x-auto" style="overflow-y: hidden">
      <table
        class="w-full border-separate table-fixed"
        cellpadding="0"
        cellspacing="0"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
              #
            </th>

            <!-- code -->
            <!-- <th
                class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px w-140px"
              >
                <div
                  class="flex justify-between items-center"
                >
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    tooltip="Mã loại hợp đồng"
                  >
                    Mã loại hợp đồng</span
                  >
                </div>
              </th> -->
            <!-- name -->
            <th
              class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px w-300px"
              (click)="Sort('name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Tên loại hợp đồng"
                  >Tên loại hợp đồng</span
                >
                <img
                  *ngIf="sort.includes('name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Mô tả"
                  >Mô tả</span
                >
              </div>
            </th>

            <th
              class="text-center col-thaotac pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap w-200px"
            >
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody *ngIf="totalArray > 0; else noInfo">
          <tr
            style="min-height: 40px !important"
            *ngFor="let contract of contractType; let i = index"
          >
            <td class="td-stt py-10px" data-label="#">
              {{ indexArray + i + 1 }}
            </td>

            <!-- <td
                class="td-code py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
                data-label="Mã loại hợp đồng "
              >
                <span [tooltip]="contract.code ? contract.code : '--'">
                  {{ contract.code ? contract.code : '--' }}</span
                >
              </td> -->
            <td
              class="td-name py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Tên loại hợp đồng"
            >
              <span [tooltip]="contract['name'] ? contract['name'] : '--'">
                {{ contract['name'] ? contract['name'] : '--' }}</span
              >
            </td>
            <td
              class="td-des py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Mô tả"
            >
              <span
                [tooltip]="contract.description ? contract.description : '--'"
              >
                {{ contract.description ? contract.description : '--' }}</span
              >
            </td>

            <td class="td-thaotac px-5px lg:px-16px" data-label="Thao tác">
              <div class="flex flex-row-reverse justify-center m-j-end">
                <button
                  tooltip="Xóa"
                  class="hover:bg-primary-lightest rounded-full focus:outline-none flex items-center justify-center button-add-edit"
                  (click)="onDelete(contract.id)"
                >
                  <img src="assets/icons/handle-icons/u_trash-alt.svg" />
                </button>

                <button
                  tooltip="Chỉnh sửa"
                  class="hover:bg-primary-lightest focus:outline-none flex items-center justify-center rounded-full button-add-edit"
                  (click)="addEditContractType(contract.id)"
                >
                  <img src="assets/icons/handle-icons/u_edit-alt.svg" />
                </button>

                <button
                  tooltip="Danh sách mẫu HĐ"
                  class="hover:bg-primary-lightest rounded-full focus:outline-none flex items-center justify-center button-add-edit"
                  (click)="addContractTemplate(contract.id, contract.name)"
                >
                  <img src="assets/icons/handle-icons/u_list-ul.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <ng-template #noInfo>
          <tbody>
            <tr
              class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
            >
              <td colspan="4" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <div class="col-span-12">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [total]="totalArray"
        [array]="array"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-contract-type-modal
    (closeModalCreate)="closeModal($event)"
    [id]="contractType_id"
  ></meu-contract-type-modal>
</meu-modal-component>

<!-- Modal bottom sheet -->
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-contract-type-modal
    [id]="contractType_id"
    (closeModalCreate)="closeModalBottom($event)"
  ></meu-contract-type-modal>
</meu-modal-popup-bottom>

<meu-modal-component
  [isOpen]="isOpenModalTemplate"
  [options]="optionsTemplate"
  *ngIf="isOpenModalTemplate"
  [title]="titleModalTemplate"
>
  <meu-contract-template-modal
    (closeModalTemplate)="closeModalTemplate($event)"
    [type_id]="contractType_id"
    [type_name]="contractType_name"
  ></meu-contract-template-modal>
</meu-modal-component>

<!-- Modal bottom sheet -->
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomSheetTemplate"
  [options]="optionsBottomSheetTemplate"
  *ngIf="isOpenBottomSheetTemplate"
  [title]="titleModalTemplate"
>
  <meu-contract-template-modal
    (closeModalTemplate)="closeBottomSheetTemplate($event)"
    [type_id]="contractType_id"
    [type_name]="contractType_name"
  ></meu-contract-template-modal>
</meu-modal-popup-bottom>
