import { async } from '@angular/core/testing';
import { HeaderManagementService } from './../../../shared/services/header.service';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { ServiceCostHistoryService } from 'src/app/api/serviceCostHistory.service';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { ServiceCostHistory } from 'src/app/model/serviceCostHistory';
import {
  ModalConfirmComponent,
  MxModalOption,
} from 'src/app/shared/components/modal/modal-confirm/modal-confirm.component';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-service-list-management',
  templateUrl: './service-list-management.component.html',
  styleUrls: ['./service-list-management.component.scss'],
})
export class ServiceListManagementComponent implements OnInit {
  services: ServiceCostHistory[] = [];
  array: ServiceCostHistory[] = [];

  arrayLenght: number = this.array.length;
  indexArray: number = 0;
  totalArray: number;

  id_service: string;
  inventoryId;
  titleModal: string;
  searchService: string = '';
  sortName: string = '';
  sort: string = '';

  isOpenModal: boolean = false;
  isOpenBottomsheet: boolean = false;
  options: MxModalOption = {};
  optionsBottom: MxBottomPopupOption = {};

  subject: Subject<any> = new Subject();

  constructor(
    private serviceCostHistoryService: ServiceCostHistoryService,
    private confirmService: ConfirmService,
    private toastService: ToastrService,
    private headerManagementService: HeaderManagementService
  ) {}

  ngOnInit(): void {
    this.getInventoryId();
    this.getAllService();
    this.search();
    this.optionCallbackModal();
    this.optionCallbackModalBottom();
  }

  getInventoryId() {
    this.headerManagementService.inventoryID.subscribe((res) => {
      this.inventoryId = res;
      this.getAllService();
    });
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getAllService();
  }

  //Search
  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getAllService();
    });
  }
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}

  //Modal
  open(id) {
    if (id) {
      this.id_service = id;
      this.titleModal = 'Chỉnh sửa dịch vụ đã sử dụng';
    } else {
      this.titleModal = 'Thêm dịch vụ đã sử dụng';
    }
    if (window.screen.width < 767) {
      this.isOpenModal = false;
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      }
    } else {
      this.isOpenBottomsheet = false;
      if (!this.isOpenModal) {
        this.isOpenModal = true;
      }
    }
  }
  closeModal(data) {
    if (!data) {
      if (this.isOpenModal) {
        this.id_service = '';
        this.isOpenModal = false;
      }
    } else {
      if (data.success === true) {
        if (this.isOpenModal) {
          this.isOpenModal = false;
          this.id_service = '';
          this.getAllService();
        }
      } else {
        this.isOpenModal = true;
      }
    }
  }
  closeModalBottom(data) {
    if (!data) {
      if (this.isOpenBottomsheet) {
        this.id_service = '';
        this.isOpenBottomsheet = false;
      }
    } else {
      if (data.success == false) {
        if (this.isOpenBottomsheet) {
          this.isOpenBottomsheet = true;
        }
      } else {
        this.isOpenBottomsheet = false;
        this.id_service = '';
        this.getAllService();
      }
    }
  }
  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.id_service = '';
      this.isOpenModal = false;
    };
  }
  optionCallbackModalBottom() {
    this.optionsBottom.callBackAfterClose = (e) => {
      this.id_service = '';
      this.isOpenBottomsheet = false;
    };
  }

  //Get data, paging
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.serviceCostHistoryService
      .apiServiceCostHistoryGet(
        this.inventoryId,
        `extend_customer_name|extend_service_name|extend_contract_code@=${this.searchService}`,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.services = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  async getAllService() {
    await (this.inventoryId = localStorage.getItem('inventoryId'));
    this.serviceCostHistoryService
      .apiServiceCostHistoryGet(this.inventoryId)
      .subscribe((data) => {
        if (data.success == true) {
          this.array = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
  deleteconfirm(id) {
    let title = 'Xóa dịch vụ đã sử dụng';
    let content = 'Bạn có chắc chắn muốn xóa dịch vụ này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.serviceCostHistoryService
            .apiServiceCostHistoryIdDelete(id)
            .subscribe((res) => {
              if (res.success == true) {
                this.toastService.success(res.message);
                this.getAllService();
              } else {
                this.toastService.error(res.message);
                this.getAllService();
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
}
