import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardClientComponent } from './dashboard-client.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ExportReportManageModule } from '../dashboard/export-report-manage/export-report-manage.module';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';
import { PopUpModule } from './pop-up/pop-up.module';
import { DateRangeModule } from '../dashboard/date-range-modal/date-range-modal.module';
import { FilterUserModalComponent } from './filter-user-modal/filter-user-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImportExportHistoryModule } from '../import-export-history/import-export-history.module';

const routes: Routes = [
  {
    path: '',
    component: DashboardClientComponent,
  },
  {
    path: 'dashboard-client',
    component: DashboardClientComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'CUSTOMER' },
  },
];

@NgModule({
  declarations: [DashboardClientComponent, FilterUserModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ExportReportManageModule,
    PopUpModule,
    DateRangeModule,
    NgSelectModule,
    ImportExportHistoryModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class DashboardClientModule {}
