import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { ContractService } from './../../api/contract.service';
import { DashboardService } from './../../api/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid,
} from 'ng-apexcharts';
import { ToastrService } from 'src/app/core/services';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { ServiceCostHistoryService } from 'src/app/api/serviceCostHistory.service';
import moment from 'moment';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { Observable, Subject } from 'rxjs';
import { StockAvailableService } from 'src/app/api/stockAvailable.service';
import { debounceTime, map } from 'rxjs/operators';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  colors: String[];
  gridLine: ApexGrid;
};

@Component({
  selector: 'meu-dashboard-client',
  templateUrl: './dashboard-client.component.html',
  styleUrls: ['./dashboard-client.component.scss'],
})
export class DashboardClientComponent implements OnInit {
  // date selected
  dateSelected = null;
  dateMobileSelected = 2;
  dateOption: any[] = [
    {
      id: 1,
      value: 'today',
      name: 'Hôm nay',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_today.svg',
    },
    {
      id: 2,
      value: 'this_week',
      name: 'Tuần này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_week.svg',
    },
    {
      id: 3,
      value: 'this_month',
      name: 'Tháng này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_month.svg',
    },
    {
      id: 4,
      value: 'this_quarter',
      name: 'Quý này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_quarter.svg',
    },
    {
      id: 5,
      value: 'this_year',
      name: 'Năm này',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_year.svg',
    },
    {
      id: 6,
      value: 'custom',
      name: 'Tùy chọn',
      iconUrl:
        '../../../../assets/icons/dashboard-icons/icon_calendar_year.svg',
    },
  ];
  fromDate: any;
  toDate: any;

  // user selected
  user_id: string = '';

  //selected
  idSelected = '3';
  filterDate: string;
  totalImport;
  totalExport;

  //ID inventory
  inventoryId: string;

  //pagination
  myCostService: any[];
  totalCostService: number = 0;
  pageIndexCS = 1;
  pageSizeCS = 5;

  myContractData: any[];
  totalContract: number = 0;
  pageIndexContract = 1;
  pageSizeContract = 5;

  myInventoryReport: any[];
  totalInRe: number = 0;
  pageIndexInRe = 1;
  pageSizeInRe = 5;

  allCodeImportCode: any;

  public keyUp = new Subject<KeyboardEvent>();
  filterStock: any = '';
  filter = '';

  // Chart option
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> = {
    legend: {
      position: 'top',
    },
    colors: ['#6cd2ff', '#ff773b'],
    series: [
      {
        name: 'Số phiếu nhập',
        data: [],
      },
      {
        name: 'Số phiếu xuất',
        data: [],
      },
    ],
    chart: {
      type: 'bar',
      height: 280,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    gridLine: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        //endingShape: "rounded"
        //distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      min: 0,
      max: 0,
      // title: {
      //   text: 'PN / PX',
      // },
      tickAmount: 4,
      axisBorder: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  importData;
  exportData;
  imExCategories: any = [];
  categories = [];
  tick: number = 0;

  resizeObservable$: Observable<Event>;

  isOpenFormModalDashBoard: boolean;

  titleModalER = 'Xuất báo cáo';
  isOpenModalER: boolean;
  optionsER: MxModalOption = {
    callBackAfterClose: () => {
      this.isOpenModalER = false;
    },
  };

  titleModalPopUp;
  isOpenModalPopUp: boolean;
  optionsPopUp: MxModalOption = {
    styleClass: 'w-9/12',
    callBackAfterClose: () => {
      this.isOpenModalPopUp = false;
    },
  };

  //Import
  titleModal: string;
  isOpenModal: boolean = false;
  options: MxModalOption = {};
  imExport_code: string;

  //Export
  titleModalExport: string;
  isOpenModalExport: boolean = false;
  optionsExport: MxModalOption = {};

  //Total
  cosTotal;
  contractActive;
  isImExport;

  //Rangdate
  isOpenRangeDateModal: boolean = false;
  optionModalRangeDate: MxModalOption = {};

  isMobile: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private serviceCostHistory: ServiceCostHistoryService,
    private contractService: ContractService,
    private toastrService: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private importExportHistoryService: ImportExportHistoryService,
    private stockAvailableService: StockAvailableService
  ) {}
  ngOnInit(): void {
    // get current date
    this.queryScreen();
    window.addEventListener('resize', (e) => this.queryScreen());
    this.dateSelected = this.dateOption[2];
    //JSON.parse(localStorage.getItem('userInfo')).id;
    this.user_id = '6e225e8f-23a9-400b-98ed-39fec50cc35d';

    //Inventory change
    this.activatedRoute.params.subscribe(async (params) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;

        this.getAllDashBoardClient(this.user_id, this.dateSelected);
        this.cdr.detectChanges();
      }
    });
    this.onSearch();
  }

  queryScreen() {
    this.isMobile = document.body.offsetWidth < 1014 ? true : false;
  }

  onChangeDate(date) {
    this.dateSelected = date;
    if (date.id == 6) {
      if (this.isMobile) {
      }
      this.isOpenRangeDateModal = true;
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.getAllDashBoardClient(this.user_id, this.dateSelected);
    }
  }

  getValueDate(event) {
    this.dateSelected = event;
    if (event.id == 6) {
      this.isOpenRangeDateModal = true;
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.getAllDashBoardClient(this.user_id, this.dateSelected);
    }
  }

  //Search
  onSearch() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(3000)
      )
      .subscribe((value) => {
        this.filterStock = value;
        this.filter = ',imExportCode@=' + this.filterStock;
        //search
        this.getImportCode(
          this.user_id,
          this.dateSelected,
          this.fromDate,
          this.toDate
        );
      });
  }

  getAllImportHistory(user_id?, dateSelected?, fromDate?, toDate?) {
    this.importExportHistoryService
      .apiImportExportHistoryGetAllCodeGet()
      .subscribe((res) => {
        if (res.success) {
        }
      });
  }

  emitUserID(event) {
    this.user_id = event;
    this.getAllDashBoardClient(this.user_id, this.dateSelected);
  }

  //Get Api
  getAllDashBoardClient(user_id?, dateSelected?, fromDate?, toDate?) {
    //get Const Info client
    this.getCostInfo(user_id, dateSelected, fromDate, toDate);
    //get total ImExport client
    //this.getStatisticsImExport(user_id, dateSelected, fromDate, toDate);
    // get active contract client
    this.getActiveContract(user_id);
    // get cost service client
    this.getAllCostService(user_id);
    // get contract client
    this.getAllMyContract(user_id);
    // get total im ex for chart
    this.getTimeLineImEx(user_id, dateSelected, fromDate, toDate);
    this.getImportNumber(user_id, dateSelected, fromDate, toDate);
    this.getExportNumber(user_id, dateSelected, fromDate, toDate);

    // get inventory report stock
    this.getMyInventoryReport(user_id, dateSelected, fromDate, toDate);
    this.getImportCode(user_id, dateSelected, fromDate, toDate);
  }

  getCostInfo(user_id?, dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardClientCostInfoGet(
        this.inventoryId,
        user_id,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((res) => {
        if (res.success) {
          this.cosTotal = res.data.total;
        } else {
          this.cosTotal = 0;
        }
      });
  }
  getStatisticsImExport(user_id?, dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdClientStatisticsImExportGet(
        this.inventoryId,
        user_id,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((res) => {
        if (res.success) {
          this.totalImport = res.data.total_import;
          this.totalExport = res.data.total_export;
        }
      });
  }
  getImportNumber(user_id?, dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdClientImportNoteGet(
        this.inventoryId,
        user_id,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((res) => {
        if (res.success) {
          this.totalImport = res.data.total;
        }
      });
  }
  getExportNumber(user_id?, dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardInventoryIdClientExportNoteGet(
        this.inventoryId,
        user_id,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((res) => {
        if (res.success) {
          this.totalExport = res.data.total;
        }
      });
  }
  getActiveContract(user_id?) {
    this.dashboardService
      .apiDashboardClientMyActiveContractsGet(user_id, this.inventoryId)
      .subscribe((res) => {
        if (res.success) {
          this.contractActive = res.count;
        }
      });
  }
  getAllCostService(user_id?) {
    this.serviceCostHistory
      .apiServiceCostHistoryGet(
        this.inventoryId,
        'extend_customer_id==' + user_id,
        '',
        this.pageIndexCS,
        this.pageSizeCS
      )
      .subscribe((res) => {
        if (res.success) {
          this.myCostService = res.data.collection;
          this.totalCostService = res.data.total;
        }
      });
  }
  getAllMyContract(user_id?) {
    this.dashboardService
      .apiDashboardClientMyContractsGet(
        this.inventoryId,
        user_id,
        '',
        '',
        this.pageIndexContract,
        this.pageSizeContract
      )
      .subscribe((res) => {
        if (res.success) {
          this.myContractData = res.data.collection;
          this.totalContract = res.data.total;
        }
      });
  }
  getTimeLineImEx(user_id?, dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardClientInventoryIdImportExportTimelineGet(
        this.inventoryId,
        user_id,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate
      )
      .subscribe((res) => {
        if (res.success) {
          this.imExCategories = res.data.date;
          this.importData = res.data.importCount.data;
          this.exportData = res.data.exportCount.data;
          this.changeDateTimeFormat(this.imExCategories);
          this.updateChart();
        }
      });
  }
  getMyInventoryReport(user_id?, dateSelected?, fromDate?, toDate?) {
    this.dashboardService
      .apiDashboardClientInventoryIdMyInventoryReportGet(
        this.inventoryId,
        user_id,
        dateSelected.id != 6 ? dateSelected.value : '',
        fromDate,
        toDate,
        '',
        '',
        this.pageIndexInRe,
        this.pageSizeCS
      )
      .subscribe((res) => {
        if (res.success) {
          this.myInventoryReport = res.data.collection;
          this.totalInRe = res.data.total;
          this.updateChart();
        }
      });
  }

  // Model Custom Date
  openTotalImport() {
    this.isImExport = 0;
    this.titleModalPopUp = 'Phiếu nhập - ' + this.dateSelected?.name;
    this.isOpenModalPopUp = true;
  }
  openTotalExport() {
    this.isImExport = 1;
    this.titleModalPopUp = 'Phiếu xuất - ' + this.dateSelected?.name;
    this.isOpenModalPopUp = true;
  }
  openExportReport() {
    if (!this.isOpenModalER) {
      this.isOpenModalER = true;
    }
  }
  closeExportReport(event) {
    if (this.isOpenModalER) {
      this.isOpenModalER = false;
    }
  }
  closeRangeDateModal(event) {
    this.fromDate = event.dateStart;
    this.toDate = event.dateEnd;
    this.isOpenRangeDateModal = false;
    this.getAllDashBoardClient(this.user_id, '', this.fromDate, this.toDate);
  }
  closeModal(event) {
    if (event) {
      if (event.includes('PN')) {
        this.imExport_code = event;
        this.titleModal = 'Chi tiết phiếu nhập hàng';
        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PSN')) {
        this.imExport_code = event;
        this.titleModal = 'Chi tiết phiếu khai báo';
        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PX')) {
        this.imExport_code = event;
        this.titleModalExport = 'Chi tiết phiếu xuất hàng';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
      if (event.includes('PSX')) {
        this.imExport_code = event;
        this.titleModalExport = 'Chi tiết phiếu sang xe';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
    }
    this.isOpenFormModalDashBoard = true;
    if (this.isOpenModalPopUp) {
      this.isOpenModalPopUp = false;
    }
  }
  closeModalImport(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      this.isOpenFormModalDashBoard = false;
      this.isOpenModalPopUp = event.isCloseModal;
      this.imExport_code = '';
    }
  }
  closeModalExport(event) {
    if (this.isOpenModalExport) {
      this.isOpenModalExport = false;
      this.isOpenFormModalDashBoard = false;
      this.isOpenModalPopUp = event.isCloseModal;
      this.imExport_code = '';
    }
  }

  //Update chart
  updateChart() {
    this.chartOptions.series = [
      {
        name: 'Số phiếu nhập',
        data: this.importData ? this.importData : [],
      },
      {
        name: 'Số phiếu xuất',
        data: this.exportData ? this.exportData : [],
      },
    ];
    this.chartOptions.xaxis = {
      categories: this.categories,
    };
    this.chartOptions.yaxis = {
      min: 0,
      max:
        this.importData && this.exportData
          ? Math.max(...this.importData, ...this.exportData)
          : 0,
      // title: {
      //   text: 'PN / PX',
      // },
      //tickAmount: tick,
      axisBorder: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(parseInt(val.toString(), 10))) + '';
        },
      },
    };
  }

  //Paging
  get changePageFunc() {
    return this.onKeyDown.bind(this);
  }
  onKeyDown(): void {}
  onPagingCostService(event) {
    this.pageIndexCS = event.pageIndex;
    this.pageSizeCS = event.pageSize;
    this.getAllCostService(this.user_id);
  }
  onPagingContract(event) {
    this.pageIndexContract = event.pageIndex;
    this.pageSizeContract = event.pageSize;
    this.getAllMyContract(this.user_id);
  }
  onPagingInventoryReport(event) {
    this.pageIndexInRe = event.pageIndex;
    this.pageSizeInRe = event.pageSize;

    this.getImportCode(
      this.user_id,
      this.dateSelected,
      this.fromDate,
      this.toDate
    );
  }

  // format date
  changeDateTimeFormat(array) {
    this.categories = [];
    array.forEach((item) => {
      this.categories.push(this.changeFormatDate(item));
    });
  }
  changeFormatDate(date) {
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    return day + '/' + month;
  }

  download(id) {
    var blobExportReport: Blob;
    this.contractService
      .apiContractIdAttachmentAllDownloadGet(id)
      .subscribe((res) => {
        if (res.type === 'application/json') {
          res.text().then((text) => {
            let resToJson = JSON.parse(text.toString());
            this.toastrService.error(resToJson.message);
          });
        } else {
          blobExportReport = new Blob([res], {
            type: res.type,
          });
          saveAs(blobExportReport, 'Tailieu.zip');
        }
      });
  }
  navigateContractDetail(id) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/inventory/${this.inventoryId}/contract/detail/${id}`,
      ])
    );
    window.open(url, '_blank');
  }
  navigateProduct(id) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/inventory/${this.inventoryId}/product-management/detail/${id}`,
      ])
    );
    window.open(url, '_blank');
  }

  //Get All Import Code of user
  getImportCode(user_id, dateSelected, formDate, toDate) {
    let startDate;
    let endDate;
    if (dateSelected.id === 1) {
      startDate = moment().format();
      endDate = moment().format();
    }
    if (dateSelected.id === 2) {
      startDate = moment().startOf('week').add(1, 'days').format();
      endDate = moment().endOf('week').add(1, 'days').format();
    }
    if (dateSelected.id === 3) {
      startDate = moment().startOf('month').format();
      endDate = moment().endOf('month').format();
    }
    if (dateSelected.id === 4) {
      startDate = moment().startOf('quarter').format();
      endDate = moment().endOf('quarter').format();
    }
    if (dateSelected.id === 5) {
      startDate = moment().startOf('year').format();
      endDate = moment().endOf('year').format();
    }
    if (dateSelected.id === 6) {
      startDate = formDate;
      endDate = toDate;
    }
    //search
    this.importExportHistoryService
      .apiImportExportHistoryGetAllCodeGet(
        user_id,
        '',
        new Date(startDate),
        new Date(endDate),
        `inventory_id==` + this.inventoryId + this.filter,
        '',
        this.pageIndexInRe,
        this.pageSizeCS
      )
      .subscribe((res) => {
        if (res.success) {
          this.allCodeImportCode = res.data.collection;
          this.totalInRe = res.data.total;
          this.allCodeImportCode.forEach((element, index) => {
            //this.getFirstStock(element.imExportCode, index);
            this.getStockByImportCode(element.imExportCode, index);
          });
        }
      });
  }

  // async getFirstStock(code, index) {
  //   await this.importExportHistoryService
  //     .apiImportExportHistoryGet(`imExportCode==` + code, '', 1, 1000, false)
  //     .subscribe((res) => {
  //       if (res.success) {
  //         let total = 0;
  //         res.data.collection.forEach((element) => {
  //           total += element.quantity;
  //         });
  //         this.allCodeImportCode[index].total_import_quantity = total;
  //       }
  //     });
  // }

  async getStockByImportCode(code, index) {
    await this.stockAvailableService
      .apiStockAvailableGet(`imExportCode==` + code, '', 1, 1000)
      .subscribe((res) => {
        if (res.success) {
          let total_quantity = 0;
          res.data.collection.forEach((element) => {
            total_quantity += element.quantity;
          });
          this.allCodeImportCode[index].total_stock_quantity = total_quantity;
        }
      });
  }
}
