/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ContainerSellHistory { 
    id?: string;
    user_id?: string;
    seller_id?: string;
    note?: string;
    container_code?: string;
    product_code?: string;
    product_name?: string;
    sku_id?: string;
    sku_code?: string;
    sell_code?: string;
    quantity?: number;
    unit?: string;
    net_weight?: number;
    price?: number;
    total_price?: number;
    payment?: number;
    payment_method?: string;
    owe?: number;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    inventory_id?: string;
    status?: string;
    container_id?: string;
    product_id?: string;
    supplier_id?: string;
    supplier_name?: string;
    is_draft?: boolean;
    group_number?: number;
}