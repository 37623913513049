import Konva from 'konva';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';

export class MenuButton {
  private submenuObject;
  onClick: EventEmitter<any> = new EventEmitter();
  newButtonScale$ = new Subject<any>();
  icon: any;

  // constructor
  constructor(
    private layer?: Konva.Layer,
    private group?: Konva.Group,
    btnObj?
  ) {
    let _this = this;
    Konva.Image.fromURL('/assets/icons/konva/' + btnObj.icon, (button) => {
      button.setAttrs({
        x: btnObj.lstField.x,
        y: btnObj.lstField.y,
        width: btnObj.lstField.width,
        height: btnObj.lstField.height,
        enhance: 1,
      });
      button.on('click', (e) => {
        _this.onClick.emit(btnObj.type);
      });
      //button.cache();
      this.group.add(button);
      //this.layer.add(this.group);
      this.layer.batchDraw();
      this.newButtonScale$.subscribe((scale) => {
        let point = {
          x: btnObj.lstField.x / scale.newScale - scale.newPos.x / scale.newScale,
          y: btnObj.lstField.y / scale.newScale - scale.newPos.y / scale.newScale,
        };
        button.position(point);
        button.setAttrs({
          width : btnObj.lstField.width / scale.newScale,
          height : btnObj.lstField.height / scale.newScale
        });
        button.moveToTop();
      });
      button.moveToTop();
    });
    

  }
}
