import { DashboardsModule } from './view/dashboard/dashboard.module';
import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { ExampleElementComponent } from './view/example-element/example-element.component';
import { ApiModule } from './api.module';
import { BASE_PATH } from '.';
import { environment } from 'src/environments/environment';
import { MapModule } from './view/map/map.module';
import { ProductCategoriesModule } from './view/product-categories/product-categories.module';
import { UnitModule } from './view/unit/unit.module';
import { ModalExampleComponent } from './view/modal-example/modal-example.component';
import { ModalContentComponent } from './view/modal-example/modal-content/modal-content.component';
import { ProductModule } from './view/product/product.module';
import { SuppliersModule } from './view/suppliers/suppliers.module';
import { SharedModule } from './shared/shared.module';
import { ImportExportHistoryModule } from './view/import-export-history/import-export-history.module';
import { ToastrService, ConfirmService } from './core/services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { CustomerModule } from './view/customer/customer.module';
import { ContractManagementModule } from './view/contract-management/contract-management.module';
import { DashboardClientModule } from './view/dashboard-client/dashboard-client.module';
import { CanActivateRouteGuard } from './core/guards/permissions-route.guard';
import { ServiceListModule } from './view/services-list/service-list.module';
import { AppMenuMobileComponent } from './view/app-menu-mobile/app-menu-mobile.component';
import { DebtManagementModule } from './view/debt-management/debt-management.module';
import { ImportExportMobileComponent } from './view/import-export-mobile/import-export-mobile.component';
import { RouterModule } from '@angular/router';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const rootRouting: ModuleWithProviders<any> = RouterModule.forRoot([], {
  useHash: false,
});
@NgModule({
  declarations: [
    AppComponent,
    ExampleElementComponent,
    ModalExampleComponent,
    ModalContentComponent,
    AppMenuMobileComponent,
    ImportExportMobileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    MapModule,
    ApiModule,
    ProductCategoriesModule,
    BrowserAnimationsModule,
    UnitModule,
    SharedModule,
    SuppliersModule,
    ProductModule,
    ImportExportHistoryModule,
    NgSelectModule,
    TagInputModule,
    FormsModule,
    DashboardsModule,
    NgApexchartsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    CustomerModule,
    ContractManagementModule,
    DashboardClientModule,
    ServiceListModule,
    ReactiveFormsModule,
    DebtManagementModule,
    NgxDaterangepickerMd.forRoot(),
    rootRouting,
  ],

  providers: [
    { provide: BASE_PATH, useValue: environment.backendhost },
    ToastrService,
    ConfirmService,
    CanActivateRouteGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
