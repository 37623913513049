<!-- Code block starts -->
<!-- <div class="w-full static" #inputWidth (clickOutside)="onClickedOutside()">
  <div class="cursor-pointer text-gray-600" data-menu>
    <div
      class="input-group flex flex-row-reverse items-center w-full"
      (click)="openMenu()"
    >
      <img class="input-icon" src="./assets/icons/u_angle-down.svg" />
      <input
      
        class="input-field"
        readonly
        [placeholder]="placeholder"
        [(ngModel)]="stringName"
      />
    </div>
  </div>
  <ul style="width: 100px"
    id="dropdown-options"
    [ngStyle]="{'width': '210px'}"
    [ngClass]="
      isOpenedList === 1
        ? 'visible w-full transition duration-300 opacity-100 bg-white rounded-4px border border-solid border-lightest-grey py-8px absolute z-50 shadow max-h-200px aqua-scrollbar'
        : 'hidden'
    "
  >
    <li #dropdownItem
      *ngFor="let item of options"
      class="cursor-pointer py-10px px-16px hover:bg-tlr-lightest-blue flex items-center border-t border-grey-lightest"
      [value]="item.id"
      (click)="emitValue(item); "
    >
      <img
        *ngIf="item.iconUrl"
        class="w-20px h-20px mr-10px"
        alt="u_edit-alt"
        [src]="item.iconUrl"
      />
      <div class="text-gray text-14px font-roboto-regular">
        {{ item.label }}
      </div>
    </li>
  </ul>
</div> -->
<!-- Code block ends -->
<div class="w-full relative focus:outline-none">
  <div class="cursor-pointer text-gray-600 w-146px m-w-126px" data-menu>
    <button
      class="input-group flex flex-row-reverse sm:items-center bg-white rounded-4px focus:outline-none outline-none border-none shadow-sm"
      (click)="openMenu()"
    >
      <img class="input-icon mr-12px m-mr-2px itemcenter-m-icon" alt="" src="../../../../assets/icons/product-categories-icons/u_angle-down.svg"/>
      <input
        class="input-field w-full h-38px focus:outline-none p-4px rounded-4px sm:items-center text-14px font-roboto-medium text-grey-med cursor-pointer"
        readonly
        [placeholder]="placeholder"
        [(ngModel)]="stringName"
      />
      <img [src]="urlName" alt="" class="ml-20px itemcenter-m-img m-ml-8px">
    </button>
  </div>
  <div
    class="w-full dropdown absolute origin-bottom-left right-0 mt-2 rounded-md shadow-lg bg-white text-14px text-grey-med line-height20 font-roboto-regular focus:outline-none"
  >
    <ul
      id="dropdown-options"
      [ngClass]="
      isOpenedList === 1
        ? 'visible w-full transition duration-300 opacity-100 bg-white rounded-4px border border-solid border-lightest-grey py-8px absolute z-50 shadow max-h-300px aqua-scrollbar'
        : 'hidden'
      "
    >
      <li
        #dropdownItem
        *ngFor="let item of options"
        class="cursor-pointer py-10px px-16px m-px-10px flex items-center  item-Hover"
        [value]="item.id"
        (click)="emitValue(item)"
      >
        <img
          *ngIf="item.iconUrl"
          class="w-20px h-20px mr-10px"
          alt="u_edit-alt"
          [src]="item.iconUrl"
        />
        <div class="text-gray text-14px font-roboto-regular">
          {{ item.label }}
        </div>
      </li>
    </ul>
  </div>
</div>
<!-- <button class="w-full relative focus:outline-none">
  <img class="cursor-pointer" src="assets/icons/more-vertical.svg" />
  <div
    class="w-full dropdown absolute origin-bottom-left right-0 mt-2  rounded-md shadow-lg bg-white text-14px text-black-gray focus:outline-none"
  >
    <ul class="py-1 w-full">
      <li class="flex cursor-pointer px-4 py-2 hover:bg-tlr-lightest-blue">
        <img class="mr-2" src="assets/icons/u_check-circle-black.svg" />
        <span>Duyệt yêu cầu</span>
      </li>
      <li class="flex cursor-pointer px-4 py-2 hover:bg-tlr-lightest-blue">
        <img class="mr-2" src="assets/icons/u_user-times.svg" />
        <span>Từ chối</span>
      </li>
      <li class="flex cursor-pointer px-4 py-2 hover:bg-tlr-lightest-blue">
        <img class="mr-3" src="assets/icons/u_edit-alt.svg" />
        <span>Chỉnh sửa</span>
      </li>
      <li class="flex cursor-pointer px-4 py-2 hover:bg-tlr-lightest-blue">
        <img class="mr-3" src="assets/icons/u_trash-alt.svg" />
        <span>Xóa yêu cầu</span>
      </li>
    </ul>
  </div>
</button> -->
