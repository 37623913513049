<div class="sm:grid sm:grid-cols-12 px-16px h-auto">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="formcreate">
      <div class="w-full grid grid-cols-12 gap-16px">
        <!-- Ten -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Tên khách hàng <span class="text-red-light">*</span></span
            >

            <input
              [ngClass]="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập tên khách hàng"
              formControlName="name"
              required
              class="w-full border rounded-4px py-8px pl-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
              "
            >
              {{
                formcreate.controls.name.errors?.required
                  ? 'Vui lòng nhập tên đơn vị tính'
                  : 'Tên đơn vị tối đa 30 kí tự'
              }}
            </span>
          </div>
        </div>
        <!-- Ma -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Mã khách hàng <span class="text-red-light">*</span></span
            >
            <!-- (input)="onChangeCode($event.target.value)" -->
            <input
              [ngClass]="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập mã khách hàng"
              formControlName="code"
              required
              class="w-full border rounded-4px py-8px pl-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
              "
            >
              {{
                formcreate.controls.code.errors?.required
                  ? 'Vui lòng nhập mã đơn vị tính'
                  : 'Mã đơn vị tối đa 30 kí tự'
              }}
            </span>
          </div>
        </div>
        <!-- Email -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('email').touched &&
                  formcreate.controls.email.errors?.required) ||
                formcreate.controls.email.errors?.email ||
                formcreate.controls.email.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Email <span class="text-red-light">*</span>
            </span>
            <input
              [ngClass]="
                (formcreate.get('email').touched &&
                  formcreate.controls.email.errors?.required) ||
                formcreate.controls.email.errors?.email ||
                formcreate.controls.email.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="VD asd@gmail.com"
              formControlName="email"
              class="w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('email').touched &&
                  formcreate.controls.email.errors?.required) ||
                formcreate.controls.email.errors?.email ||
                formcreate.controls.email.errors?.maxlength
              "
            >
              {{
                formcreate.controls.email.errors?.required
                  ? 'Vui lòng nhập email'
                  : formcreate.controls.email.errors?.email
                  ? 'Vui lòng nhập đúng ký tự email'
                  : 'Email tối đa 150 ký tự'
              }}
            </span>
          </div>
        </div>
        <!-- Phone -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.phone.errors?.pattern ||
                formcreate.controls.phone.errors?.maxlength ||
                formcreate.controls.phone.errors?.minlength
                  ? 'error-class'
                  : ''
              "
            >
              Điện thoại
            </span>
            <input
              [ngClass]="
                formcreate.controls.phone.errors?.pattern ||
                formcreate.controls.phone.errors?.maxlength ||
                formcreate.controls.phone.errors?.minlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="VD 098765417"
              formControlName="phone"
              class="w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                formcreate.controls.phone.errors?.pattern ||
                formcreate.controls.phone.errors?.maxlength ||
                formcreate.controls.phone.errors?.minlength
              "
            >
              {{
                formcreate.controls.phone.errors?.pattern
                  ? 'Vui lòng nhập đúng ký tự số '
                  : formcreate.controls.phone.errors?.maxlength
                  ? 'Số điện thoại tối đa 11 số'
                  : 'Số điện thoại tối thiểu 10 số'
              }}
            </span>
          </div>
        </div>
        <!-- Date -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Ngày sinh</span
            >
            <div class="relative">
              <img
                src="assets/icons/table-icon/u_calender.svg"
                class="absolute right-12px top-17px"
              />
              <input
                #dayPicker
                type="text"
                #dateToDp="dpDayPicker"
                [dpDayPicker]="datePickerConfig"
                [theme]="'dp-material'"
                formControlName="date"
                [mode]="'daytime'"
                placeholder="DD/MM/YYYY HH:mm"
                class="class-valid cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
              />
            </div>
          </div>
        </div>
        <!-- Gender -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Giới tính</span
            >
            <div class="relative">
              <img
                class="absolute right-0 mr-12px mt-18px"
                src="assets/icons/table-icon/u_angle-down.svg"
              />
              <select
                formControlName="gender"
                required
                class="appearance-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid mt-8px rounded-4px h-40px py-8px pl-12px pr-30px overflow-hidden overflow-ellipsis border bg-white border-grayscale-light-grey hover:border-primary-light focus:outline-none focus:ring-1 focus:ring-primary-blue"
              >
                <option value="" hidden selected>Chọn giới tính</option>
                <option *ngFor="let gender of genders" [ngValue]="gender.id">
                  {{ gender.name }}
                </option>
              </select>
            </div>

            <!-- (input)="onChangeCode($event.target.value)" -->
          </div>
        </div>
        <!-- NameCom -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.nameCompany.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Tên công ty
            </span>

            <input
              [ngClass]="
                formcreate.controls.nameCompany.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập tên công ty"
              formControlName="nameCompany"
              required
              class="w-full border rounded-4px py-8px pl-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="formcreate.controls.nameCompany.errors?.maxlength"
            >
              {{
                formcreate.controls.nameCompany.errors?.maxlength
                  ? 'Tên đơn vị tối đa 30 kí tự'
                  : ''
              }}
            </span>
          </div>
        </div>
        <!-- Taxcode -->
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.tax_code.errors?.pattern ||
                formcreate.controls.tax_code.errors?.maxlength ||
                formcreate.controls.tax_code.errors?.minlength
                  ? 'error-class'
                  : ''
              "
            >
              Mã số thuế
            </span>
            <input
              [ngClass]="
                formcreate.controls.tax_code.errors?.pattern ||
                formcreate.controls.tax_code.errors?.maxlength ||
                formcreate.controls.tax_code.errors?.minlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="VD 123456789"
              formControlName="tax_code"
              class="w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                formcreate.controls.tax_code.errors?.pattern ||
                formcreate.controls.tax_code.errors?.maxlength ||
                formcreate.controls.tax_code.errors?.minlength
              "
            >
              {{
                formcreate.controls.tax_code.errors?.pattern
                  ? 'Vui lòng nhập đúng ký tự số'
                  : formcreate.controls.tax_code.errors?.maxlength
                  ? 'Mã số thuế tối đa 10 số'
                  : 'Mã số thuế tối thiểu 10 số'
              }}
            </span>
          </div>
        </div>
        <!-- address -->
        <div class="col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.address.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Địa chỉ công ty</span
            >

            <input
              [ngClass]="
                formcreate.controls.address.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập địa chỉ công ty"
              formControlName="address"
              required
              class="w-full border rounded-4px py-8px pl-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="formcreate.controls.address.errors?.maxlength"
            >
              {{
                formcreate.controls.address.errors?.maxlength
                  ? 'Mã đơn vị tối đa 30 kí tự'
                  : ''
              }}
            </span>
          </div>
        </div>
        <!-- Note -->
        <div class="col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.note.errors?.maxlength ? 'error-class' : ''
              "
            >
              Ghi chú</span
            >
            <textarea
              [ngClass]="
                formcreate.controls.note.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              placeholder="Nhập ghi chú"
              formControlName="note"
              class="w-full border rounded-4px mt-8px py-8px pl-12px h-80px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            ></textarea>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="formcreate.controls.note.errors?.maxlength"
            >
              {{
                formcreate.controls.note.errors?.maxlength
                  ? 'Mô tả tối đa 300 ký tự'
                  : ''
              }}
            </span>
          </div>
        </div>

        <div
          class="col-span-full flex-row-reverse gap-8px flex h-64px mt-5px pt-8px items-center"
        >
          <button
            class="btn-primary-add"
            [disabled]="!formcreate.valid"
            type="button"
          >
            Thêm
          </button>
          <button class="btn-text-cancel" type="button" (click)="closeModal()">
            Hủy
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
