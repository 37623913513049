import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Unit } from 'src/app';
import { UnitService } from 'src/app/api/unit.service';
import { ToastrService } from 'src/app/core/services/toastr.service';

@Component({
  selector: 'meu-modal-create-unit',
  templateUrl: './modal-create-unit.component.html',
  styleUrls: ['./modal-create-unit.component.scss'],
})
export class ModalCreateUnitComponent implements OnInit {
  @Input() id_unit: string;
  @Output() closeModalUnit = new EventEmitter();
  messError = '';
  constructor(
    private unitService: UnitService,
    private toastService: ToastrService
  ) {}

  formcreate = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    code: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    description: new FormControl('', [Validators.maxLength(300)]),
  });

  unit: Unit = {};
  title: string;
  nameButton: string;
  changeCode = false;
  ngOnInit(): void {
    this.setModalAddOrEdit();
  }
  setModalAddOrEdit() {
    if (this.id_unit) {
      this.title = 'Chỉnh sửa đơn vị tính';
      this.nameButton = 'Lưu';
      this.getUnitID();
    } else {
      this.title = 'Thêm mới đơn vị tính';
      this.nameButton = 'Thêm';
    }
  }

  setButtonAddOrEdit() {
    this.formcreate.markAllAsTouched();
    if (this.id_unit) {
      this.updateUnitID();
    } else {
      this.addUnit();
    }
  }
  closeModal(data = null) {
    if (data) {
      this.closeModalUnit.emit(data);

      return;
    }
    this.closeModalUnit.emit();
  }

  addUnit() {
    // Object.keys(this.formcreate.controls).forEach((key) =>
    //   this.formcreate.get(key).setValue(this.formcreate.get(key).value.trim())
    // );

    this.unit.description = this.formcreate.controls[
      'description'
    ].value.trim();
    this.unit.name = this.formcreate.controls['name'].value.trim();
    this.unit.code = this.formcreate.controls['code'].value.trim();

    if (this.unit.name == '') {
      this.formcreate.controls['name'].setValue(null);
    }
    if (this.unit.code == '') {
      this.formcreate.controls['code'].setValue(null);
    }

    if (this.formcreate.invalid) {
    } else {
      this.unit.code = this.changeCharater(
        this.formcreate.controls['code'].value.trim()
      );
      this.unitService.apiUnitPost(this.unit).subscribe((res) => {
        if (res.success == true) {
          this.toastService.success(res.message);
          this.closeModal(res);
        } else {
          this.toastService.error('Mã đơn vị đã tồn tại');
          this.closeModal(res);
        }
      });
    }
  }

  getUnitID() {
    this.unitService.apiUnitIdGet(this.id_unit).subscribe((data) => {
      if (data.success == true) {
        (this.unit = data.data),
          this.formcreate.controls['description'].setValue(
            this.unit.description
          );
        this.formcreate.controls['name'].setValue(this.unit.name);
        this.formcreate.controls['code'].setValue(this.unit.code);
      } else {
        this.toastService.error('Lỗi không lấy được dữ liệu');
      }
    });
  }
  updateUnitID() {
    // Object.keys(this.formcreate.controls).forEach((key) =>
    //   this.formcreate.get(key).setValue(this.formcreate.get(key).value.trim())
    // );
    this.unit.name = this.formcreate.controls['name'].value.trim();
    this.unit.code = this.formcreate.controls['code'].value.trim();

    this.unit.description = this.formcreate.controls['description'].value;

    if (this.unit.name == '') {
      this.formcreate.controls['name'].setValue(null);
    }
    if (this.unit.code == '') {
      this.formcreate.controls['code'].setValue(null);
    }
    if (this.formcreate.invalid) {
    } else {
      this.unit.code = this.changeCharater(
        this.formcreate.controls['code'].value.trim()
      );
      this.unitService
        .apiUnitIdPut(this.unit.id, this.unit)
        .subscribe((res) => {
          if (res.success == true) {
            this.toastService.success(res.message);
            this.closeModal(res);
          } else {
            this.toastService.error('Mã đơn vị đã tồn tại');
            this.closeModal(res);
          }
        });
    }
  }

  changeCharater(str) {
    if (str) {
      str = str.toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }
}
