import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../services/shared.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(private router: Router, private sharedService: SharedService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          switch (event.status) {
            case 202:
              //TODO go to maintenance page
              break;
            default:
              //TODO default success HttpResponse
              break;
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        //TODO general error handle
        switch (error.status) {
          case 401:
            //GOTO SSO
            this.sharedService.openSSO();
            break;
          case 403:
            //TODO 403
            break;
          case 404:
            //TODO 404
            break;
          default:
        }
        return throwError(error);
      })
    );
  }
}
