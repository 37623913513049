import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDayCalendarConfig } from 'ng2-date-picker';

@Component({
  selector: 'meu-modal-create-customer',
  templateUrl: './modal-create-customer.component.html',
  styleUrls: ['./modal-create-customer.component.scss'],
})
export class ModalCreateCustomerComponent implements OnInit {
  @Output() closeModalCustomer = new EventEmitter();
  @Input() name_customer: string;

  genders = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
    { id: 3, name: 'Other' },
  ];
  formcreate = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(150),
    ]),
    phone: new FormControl('', [
      Validators.pattern('^[0-9]*'),
      Validators.minLength(10),
      Validators.maxLength(11),
    ]),
    date: new FormControl(''),
    gender: new FormControl(''),
    nameCompany: new FormControl('', [Validators.maxLength(300)]),
    tax_code: new FormControl('', [
      Validators.pattern('[0-9]*'),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    address: new FormControl('', [Validators.maxLength(200)]),
    note: new FormControl(),
  });

  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm',
    firstDayOfWeek: 'mo',
    opens: 'left',
    drops: 'down',
  };

  dateExpiryPickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY',
    firstDayOfWeek: 'mo',
    drops: 'up',
  };

  constructor() {}

  ngOnInit(): void {
    if (this.name_customer) {
      this.formcreate.controls['name'].setValue(this.name_customer);
    }
  }

  closeModal(res = null) {
    if (res) {
      this.closeModalCustomer.emit(res);

      return;
    }
    this.closeModalCustomer.emit();
  }
}
