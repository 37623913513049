import { ToastrService } from 'src/app/core/services';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { UserDebtService } from 'src/app/api/userDebt.service';
import { MeucurrencyPipe } from 'src/app/shared/pipes/meucurrency.pipe';

@Component({
  selector: 'meu-modal-debt-infomation-edit',
  templateUrl: './modal-debt-infomation-edit.component.html',
  styleUrls: ['./modal-debt-infomation-edit.component.scss'],
})
export class ModalDebtInfomationEditComponent implements OnInit {
  @Output() closeRequest = new EventEmitter();
  @ViewChild(DaterangepickerDirective, { static: false })
  picker: DaterangepickerDirective;

  @Input() userID = '';
  idDebt = '';

  element = {
    maxDebt: '0',
    date: moment().format(),
    note: '',
  };

  constructor(
    private userDebtService: UserDebtService,
    private toastr: ToastrService,
    private meuCurrencyPipe: MeucurrencyPipe
  ) {
    this.getDebtUserInfomation();
  }

  ngOnInit(): void {}

  getDebtUserInfomation() {
    this.userDebtService
      .apiUserDebtGet(`user_id==` + this.userID)
      .subscribe((res) => {
        if (res.success) {
          this.idDebt = res.data.collection[0].id;
          this.element.maxDebt = this.meuCurrencyPipe.transform(
            res.data.collection[0].max_debt_allow
          );
        }
      });
  }

  changeDate(event) {
    this.element.date = event.startDate;
  }

  submit() {
    const obj = {
      max_debt_allow: this.element.maxDebt
        ? this.removeSymbol(this.element.maxDebt)
        : 0,
    };
    this.userDebtService.apiUserDebtIdPut(this.idDebt, obj).subscribe((res) => {
      if (res.success) {
        this.toastr.success(res.message);
        this.closeModal(res);
      }
    });
  }

  closeModal(event) {
    this.closeRequest.emit(event);
  }

  removeSymbol(value) {
    return parseInt(value.toString().replace(/,/g, ''));
  }
}
