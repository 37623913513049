<div class="sm:grid sm:grid-cols-12 px-16px h-auto font-roboto">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="inventoryMapForm">
      <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-4">
        <!-- <div class="sm:col-span-3">
          <div class="flex flex-col">
            <span class="text-12px text-warm-grey mb-2"> Hình ảnh mô tả </span>
            <div
              class="flex justify-center border border-light-grey rounded w-full h-152px"
            >
              <img
                src="assets/icons/menu-icons/u_image.svg"
                (click)="selectImage.click()"
              />
            </div>
            <input
              type="file"
              (change)="updateImage($event)"
              class="hidden"
              #selectImage
            />
          </div>
        </div> -->
        <div class="sm:col-span-12 gap-4">
          <div class="sm:col-span-12 sm:grid sm:grid-cols-12 gap-4">
            <div class="sm:col-span-6">
              <div class="flex flex-col">
                <span
                  class="text-12px text-warm-grey"
                  [ngClass]="
                    inventoryMapForm.get('code').touched &&
                    inventoryMapForm.controls.code.errors?.required
                      ? 'error-class'
                      : ''
                  "
                >
                  Mã ô <span class="text-red-light">*</span>
                </span>
                <input
                  [ngClass]="
                    inventoryMapForm.get('code').touched &&
                    inventoryMapForm.controls.code.errors?.required
                      ? 'class-invalid'
                      : 'class-valid'
                  "
                  type="text"
                  placeholder="Nhập mã ô"
                  formControlName="code"
                  class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                  style="line-height: 1.43; letter-spacing: normal"
                />
                <span
                  class="text-12px text-red-light"
                  *ngIf="
                    inventoryMapForm.get('code').touched &&
                    inventoryMapForm.controls.code.errors?.required
                  "
                  >Vui lòng nhập mã ô
                </span>
              </div>
            </div>
            <div class="sm:col-span-6">
              <div class="flex flex-col">
                <span
                  class="text-12px text-warm-grey"
                  [ngClass]="
                    inventoryMapForm.get('used_percent').touched &&
                    inventoryMapForm.controls.used_percent.errors?.pattern
                      ? 'error-class'
                      : ''
                  "
                >
                  Đã dùng(%)
                </span>
                <input
                  [ngClass]="
                    inventoryMapForm.get('used_percent').touched &&
                    inventoryMapForm.controls.used_percent.errors?.pattern
                      ? 'class-invalid'
                      : 'class-valid'
                  "
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                  type="number"
                  min="0"
                  max="100"
                  placeholder="Nhập giá trị đã sử dụng"
                  formControlName="used_percent"
                  class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                  style="line-height: 1.43; letter-spacing: normal"
                />
                <span
                  class="text-12px text-red-light"
                  *ngIf="
                    inventoryMapForm.get('used_percent').touched &&
                    inventoryMapForm.controls.used_percent.errors?.pattern
                  "
                  >Vui lòng nhập số từ 0-100
                </span>
              </div>
            </div>
          </div>
          <div class="sm:col-span-12 sm:grid sm:grid-cols-12 gap-4 mt-5px">
            <div class="sm:col-span-6">
              <div class="flex flex-col">
                <span
                  [ngClass]="
                    inventoryMapForm.get('_long').touched &&
                    inventoryMapForm.controls._long.errors?.required
                      ? 'error-class'
                      : ''
                  "
                  class="text-12px text-warm-grey"
                >
                  Chiều dài(mm) <span class="text-red-light">*</span></span
                >
                <input
                  [ngClass]="
                    inventoryMapForm.get('_long').touched &&
                    inventoryMapForm.controls._long.errors?.required
                      ? 'class-invalid'
                      : 'class-valid'
                  "
                  type="number"
                  placeholder="Nhập chiều dài"
                  formControlName="_long"
                  class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                  style="line-height: 1.43; letter-spacing: normal"
                />
                <span
                  class="text-12px text-red-light"
                  *ngIf="
                    inventoryMapForm.get('_long').touched &&
                    inventoryMapForm.controls._long.errors?.required
                  "
                  >Vui lòng nhập chiều dài(mm)
                </span>
              </div>
            </div>
            <div class="sm:col-span-6">
              <div class="flex flex-col">
                <span
                  [ngClass]="
                    inventoryMapForm.get('width').touched &&
                    inventoryMapForm.controls.width.errors?.required
                      ? 'error-class'
                      : ''
                  "
                  class="text-12px text-warm-grey"
                >
                  Chiều rộng(mm)
                  <span class="text-red-light">*</span>
                </span>

                <input
                  type="number"
                  [ngClass]="
                    inventoryMapForm.get('width').touched &&
                    inventoryMapForm.controls.width.errors?.required
                      ? 'class-invalid'
                      : 'class-valid'
                  "
                  placeholder="Nhập chiều rộng"
                  formControlName="width"
                  class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                  style="line-height: 1.43; letter-spacing: normal"
                />
                <span
                  class="text-12px text-red-light"
                  *ngIf="
                    inventoryMapForm.get('width').touched &&
                    inventoryMapForm.controls.width.errors?.required
                  "
                  >Vui lòng nhập chiều rộng(mm)
                </span>
              </div>
            </div>
          </div>
          <div class="sm:col-span-12 sm:grid sm:grid-cols-12 gap-4 mt-5px">
            <div class="sm:col-span-6">
              <div class="flex flex-col">
                <span
                  [ngClass]="
                    inventoryMapForm.get('height').touched &&
                    inventoryMapForm.controls.height.errors?.required
                      ? 'error-class'
                      : ''
                  "
                  class="text-12px text-warm-grey"
                >
                  Chiều cao(mm) <span class="text-red-light">*</span></span
                >
                <input
                  [ngClass]="
                    inventoryMapForm.get('height').touched &&
                    inventoryMapForm.controls.height.errors?.required
                      ? 'class-invalid'
                      : 'class-valid'
                  "
                  type="number"
                  placeholder="Nhập chiều cao"
                  formControlName="height"
                  class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                  style="line-height: 1.43; letter-spacing: normal"
                />
                <span
                  class="text-12px text-red-light"
                  *ngIf="
                    inventoryMapForm.get('height').touched &&
                    inventoryMapForm.controls.height.errors?.required
                  "
                  >Vui lòng nhập chiều cao(mm)
                </span>
              </div>
            </div>
            <div class="sm:col-span-6">
              <div class="flex flex-col">
                <span
                  [ngClass]="
                    inventoryMapForm.get('weight').touched &&
                    inventoryMapForm.controls.weight.errors?.required
                      ? 'error-class'
                      : ''
                  "
                  class="text-12px text-warm-grey"
                >
                  Trọng lượng(kg) <span class="text-red-light">*</span></span
                >

                <input
                  [ngClass]="
                    inventoryMapForm.get('weight').touched &&
                    inventoryMapForm.controls.weight.errors?.required
                      ? 'class-invalid'
                      : 'class-valid'
                  "
                  type="number"
                  placeholder="Nhập trọng lượng"
                  formControlName="weight"
                  class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                  style="line-height: 1.43; letter-spacing: normal"
                />
                <span
                  class="text-12px text-red-light"
                  *ngIf="
                    inventoryMapForm.get('weight').touched &&
                    inventoryMapForm.controls.weight.errors?.required
                  "
                  >Vui lòng nhập trọng lượng(kg)
                </span>
              </div>
            </div>
          </div>

          <div class="sm:col-span-12 mt-5px">
            <div class="flex flex-col">
              <span class="text-12px text-warm-grey"> Ghi chú </span>

              <textarea
                type="text"
                placeholder="Nhập ghi chú"
                formControlName="name"
                rows="2"
                class="w-full py-8px pl-12px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
                style="line-height: 1.43; letter-spacing: normal"
              ></textarea>
              <span class="text-12px text-red-light"> </span>
            </div>
          </div>
        </div>
        <div *ngIf="!isAdmin" class="col-span-full overflow-hidden">
          <div class="flex flex-row items-center justify-between">
            <div class="text-16px font-medium text-dark-grey">
              Danh sách hàng hóa
            </div>
            <div class="flex">
              <!-- Chưa xong -->
              <!-- <button
                id="save"
                class="btn-trash"
                type="submit"
                (click)="openChangePositionModal()"
              >
                Chuyển vị trí
              </button> -->
              <button
                id="import-btn"
                class="btn-primary-sm mb-4 mx-4 flex flex-row items-center"
                (click)="openImport(null)"
                [disabled]="inventoryMapForm.controls.used_percent.value >= 100"
              >
                <img
                  class="mr-1"
                  src="assets/icons/handle-icons/fi_download.svg"
                />
                <span class="text-14px">Nhập hàng</span>
              </button>
              <!-- <button
                class="btn-primary-sm mb-4 flex flex-row items-center"
                (click)="openExport(null)"
              >
                <img
                  class="mr-1"
                  src="assets/icons/handle-icons/u_file-export.svg"
                />
                <span class="text-14px">Xuất hàng</span>
              </button> -->
            </div>
          </div>
          <div class="flex flex-col">
            <div
              class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 overflow-y-auto"
              style="max-height: 20vh"
            >
              <div
                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              >
                <div
                  class="overflow-hidden border-b border-border-table sm:rounded"
                >
                  <table class="min-w-full divide-y divide-border-table">
                    <thead class="bg-lightest-grey">
                      <tr>
                        <th
                          *ngFor="let item of displayColumn"
                          scope="col"
                          class="px-4 py-2.5 text-grey-med text-left text-12px font-medium text-gray-500 tracking-wider"
                        >
                          {{ item }}
                        </th>
                        <!-- <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Edit</span>
                              </th> -->
                      </tr>
                    </thead>
                    <tbody
                      class="bg-white divide-y divide-gray-200 w-full text-dark-grey text-14px cursor-pointer"
                    >
                      <tr
                        *ngFor="
                          let importexport of allImportExport;
                          let i = index
                        "
                      >
                        <td class="px-4 py-2.5 whitespace-nowrap">
                          {{ i + 1 }}
                        </td>
                        <td
                          class="px-4 py-2.5 whitespace-nowrap text-primary-dodger-blue"
                          (click)="openIE(importexport.imExportCode)"
                        >
                          {{ importexport.imExportCode }}
                        </td>
                        <td class="px-4 py-2.5 whitespace-nowrap">
                          {{
                            importexport.receipt_date
                              ? (importexport.receipt_date | date: 'dd/MM/yyyy')
                              : importexport.issue_date
                              ? (importexport.issue_date | date: 'dd/MM/yyyy')
                              : '---'
                          }}
                        </td>
                        <td
                          class="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{
                            importexport.extend_implementer_name
                              ? importexport.extend_implementer_name
                              : '---'
                          }}
                        </td>
                      </tr>
                      <!-- More people... -->
                    </tbody>
                  </table>
                  <div
                    *ngIf="allImportExport.length == 0"
                    class="flex w-full h-48px items-center justify-center bg-white text-purplish-grey text-12px"
                  >
                    Không có dữ liệu
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-span-full flex gap-8px mb-4"
          [ngClass]="isAdmin ? 'justify-between' : 'justify-end'"
        >
          <div *ngIf="isAdmin && inventoryMapForm.value.used_percent === 0">
            <button
              class="btn-text-sm flex items-center"
              type="button"
              (click)="closeModal.emit(true)"
            >
              <img src="assets/icons/menu-icons/u_trash.svg" />
              <span class="text-red-light" (click)="removeSlot()"
                >&nbsp;Xóa ô này</span
              >
            </button>
          </div>
          <div>
            <button
              id="save"
              class="btn-primary-sm mr-2"
              type="submit"
              (click)="saveInventoryMap()"
              (keyup.enter)="saveInventoryMap()"
              [disabled]="!inventoryMapForm.valid"
            >
              Lưu
            </button>
            <button class="btn-text-cancel" (click)="closeModal.emit(true)">
              Hủy
            </button>
          </div>
          <!-- <div *ngIf="!isAdmin">
            <button
              id="close"
              class="btn-primary-sm mr-2"
              type="button"
              (click)="closeModal.emit(true)"
            >
              Đóng
            </button>
          </div> -->
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Import -->
<!-- <meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [imExport_code]="imExport_code"
    [dataFromContainer]="dataFromContainer"
    (closeModalImport)="closeModalImport($event)"
    (changeTitleModal)="changeTitle($event)"
  ></meu-import-history>
</meu-modal-component> -->
