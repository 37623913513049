export * from './authenticate.service';
import { AuthenticateService } from './authenticate.service';
export * from './container.service';
import { ContainerService } from './container.service';
export * from './containerChargeHistory.service';
import { ContainerChargeHistoryService } from './containerChargeHistory.service';
export * from './containerImportHistory.service';
import { ContainerImportHistoryService } from './containerImportHistory.service';
export * from './containerProductHistory.service';
import { ContainerProductHistoryService } from './containerProductHistory.service';
export * from './containerSellHistory.service';
import { ContainerSellHistoryService } from './containerSellHistory.service';
export * from './containerSellProductHistory.service';
import { ContainerSellProductHistoryService } from './containerSellProductHistory.service';
export * from './containerSellProductPayment.service';
import { ContainerSellProductPaymentService } from './containerSellProductPayment.service';
export * from './containerSummaryHistory.service';
import { ContainerSummaryHistoryService } from './containerSummaryHistory.service';
export * from './contract.service';
import { ContractService } from './contract.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './debug.service';
import { DebugService } from './debug.service';
export * from './deleteHistoryNote.service';
import { DeleteHistoryNoteService } from './deleteHistoryNote.service';
export * from './export.service';
import { ExportService } from './export.service';
export * from './freight.service';
import { FreightService } from './freight.service';
export * from './importExportHistory.service';
import { ImportExportHistoryService } from './importExportHistory.service';
export * from './inventory.service';
import { InventoryService } from './inventory.service';
export * from './inventoryMap.service';
import { InventoryMapService } from './inventoryMap.service';
export * from './inventoryType.service';
import { InventoryTypeService } from './inventoryType.service';
export * from './inventoryZone.service';
import { InventoryZoneService } from './inventoryZone.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './productTemplate.service';
import { ProductTemplateService } from './productTemplate.service';
export * from './productTemplateTree.service';
import { ProductTemplateTreeService } from './productTemplateTree.service';
export * from './serviceCost.service';
import { ServiceCostService } from './serviceCost.service';
export * from './serviceCostConfig.service';
import { ServiceCostConfigService } from './serviceCostConfig.service';
export * from './serviceCostHistory.service';
import { ServiceCostHistoryService } from './serviceCostHistory.service';
export * from './sku.service';
import { SkuService } from './sku.service';
export * from './slot.service';
import { SlotService } from './slot.service';
export * from './stockAvailable.service';
import { StockAvailableService } from './stockAvailable.service';
export * from './supplier.service';
import { SupplierService } from './supplier.service';
export * from './unit.service';
import { UnitService } from './unit.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userDebt.service';
import { UserDebtService } from './userDebt.service';
export * from './userDebtPayment.service';
import { UserDebtPaymentService } from './userDebtPayment.service';
export const APIS = [AuthenticateService, ContainerService, ContainerChargeHistoryService, ContainerImportHistoryService, ContainerProductHistoryService, ContainerSellHistoryService, ContainerSellProductHistoryService, ContainerSellProductPaymentService, ContainerSummaryHistoryService, ContractService, DashboardService, DebugService, DeleteHistoryNoteService, ExportService, FreightService, ImportExportHistoryService, InventoryService, InventoryMapService, InventoryTypeService, InventoryZoneService, ProductService, ProductTemplateService, ProductTemplateTreeService, ServiceCostService, ServiceCostConfigService, ServiceCostHistoryService, SkuService, SlotService, StockAvailableService, SupplierService, UnitService, UserService, UserDebtService, UserDebtPaymentService];
