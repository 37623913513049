export class MenuConfig {
  public defaults: any = {
    header: {
      items: [
        {
          title: 'báo cáo',
          icon: 'assets/icons/menu-icons/area-chart-alt.svg',
          page: '',
          selected: false,
          submenu: [
            {
              title: 'Tổng quan kho hàng',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/inventory/{{inventoryId}}/dashboard/dashboard-manage',
              selected: false,
              permission: 'STAFF',
            },
            {
              title: 'Khách hàng',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/inventory/{{inventoryId}}/dashboard-client',
              selected: false,
              permission: 'CUSTOMER',
            },
          ],
        },
        {
          title: 'Sơ đồ kho',
          icon: 'assets/icons/menu-icons/home.svg',
          page: '/inventory/{{inventoryId}}/map',
          selected: true,
          permission: 'STAFF',
        },
        {
          title: 'Hàng hóa',
          icon: 'assets/icons/menu-icons/box.svg',
          page: '',
          selected: false,
          permission: 'STAFF',
          submenu: [
            {
              title: 'Hàng hóa',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page:
                '/inventory/{{inventoryId}}/product-management/product-list',
              permission: 'STAFF',
            },
            {
              title: 'Phân loại hàng',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/inventory/{{inventoryId}}/product-categories',
              permission: 'ADMIN',
            },
          ],
        },
        {
          title: 'Nhập - Xuất',
          icon: 'assets/icons/menu-icons/icon_import_export.svg',
          page:
            '/inventory/{{inventoryId}}/import-export-management/import-export-list',
          selected: false,
        },
        {
          title: 'Đối tác',
          icon: 'assets/icons/menu-icons/venn-diagram.svg',
          page: '',
          selected: false,
          permission: 'STAFF',
          submenu: [
            {
              title: 'Người dùng',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/customer-management/customer-list',
            },
            {
              title: 'Hợp đồng',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/inventory/{{inventoryId}}/contract/contract-list',
            },
          ],
        },
        {
          title: 'Dịch vụ',
          icon: 'assets/icons/menu-icons/icon_service.svg',
          page: '/service-management/service-list',
          selected: false,
          permission: 'STAFF',
        },
        {
          title: 'thiết lập',
          icon: 'assets/icons/menu-icons/icon_setting.svg',
          page: '',
          selected: false,
          permission: 'STAFF',
          submenu: [
            {
              title: 'Đơn vị',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/unit-management/unit-list',
              permission: 'STAFF',
            },
            {
              title: 'Nhà cung cấp',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/supplier-management/supplier-list',
              permission: 'STAFF',
            },
            {
              title: 'Kho hàng',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/inventory-management/inventory-list',
              permission: 'ADMIN',
            },
            {
              title: 'Sơ đồ',
              icon: 'assets/icons/menu-icons/area-chart-alt.svg',
              page: '/inventory/{{inventoryId}}/map/admin',
              permission: 'STAFF',
            },
            {
              title: 'Loại Hợp đồng',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/inventory/{{inventoryId}}/contract/type',
              permission: 'STAFF',
            },
            {
              title: 'Biểu phí dịch vụ',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/service-cost-management/service-cost-list',
              permission: 'STAFF',
            },
            {
              title: 'Lịch sử xóa',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/history-management/history-list',
              // permission: 'STAFF',
            },
            {
              title: 'Quản lý xe container',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/container-management/list-container',
              // permission: 'STAFF',
            },
            {
              title: 'Lịch sử bán hàng',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/container-management/sales-history',
              // permission: 'STAFF',
            },
            {
              title: 'Phí sạc Container',
              icon: 'assets/icons/menu-icons/venn-diagram.svg',
              page: '/container-charge-management/list-container-charge',
              // permission: 'STAFF',
            },
          ],
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
