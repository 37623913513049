<!-- notification -->
<div class="noti-mt" (clickOutside)="isOpenMenu = false">
  <a class="noti-bg" href="javascript:void(0)" (click)="openDropdownMenu()">
    <span class="sr-only">Xem thông báo</span>
    <img src="/assets/icons/bell.svg" class="bell-fillter" />
    <ng-container *ngIf="notificationCount$ | async as count">
      <div [hidden]="count == 0" class="badge-count">{{ count }}</div>
    </ng-container>
  </a>
  <!--Notification body-->
  <!-- <div *ngIf="isOpenMenu" (click)="isOpenMenu = false" class="dropdown"></div> -->
  <div [ngClass]="isOpenMenu ? 'visible' : 'hidden'" class="dropdown">
    <div class="dropdown-width">
      <div class="padding-b-2">
        <div #notiGroup>
          <div class="group-noti-name">
            <div class="name">
              {{
                selectedGroupIndex > -1
                  ? groupNotification[selectedGroupIndex].name
                  : 'Thông báo'
              }}
            </div>
            <!-- <img src="assets/icons/Iconly_Bold_Setting.svg" /> -->
          </div>
          <div class="group-noti-icons">
            <div class="display-icons">
              <ng-container *ngFor="let item of groupNotification; index as i">
                <div
                  [ngClass]="
                    selectedGroupIndex == i ? 'bg-gradient' : 'bg-greylighter'
                  "
                  class="bg-icon"
                  (click)="getNotification(item.serviceId, item.type, i)"
                >
                  <img
                    [ngClass]="selectedGroupIndex == i ? '' : 'filter-icon'"
                    class="border-radius-full"
                    [src]="backendhost + item.icon_group"
                    width="20"
                    height="20"
                  />
                  <span *ngIf="item.unread != 0" class="number-position"
                    ><span [hidden]="item.unread == 0" class="number-bg">
                      {{ item.unread }}
                    </span></span
                  >
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="scroll" (scroll)="onScroll($event)">
          <div>
            <ng-container *ngFor="let item of notify; index as i">
              <a
                (click)="readNotify(item.id, i, $event, item.is_read, item)"
                class="noti-body"
                (mouseover)="deleteIcon.hidden = false"
                (mouseout)="deleteIcon.hidden = true"
              >
                <div class="noti-position">
                  <div class="bg-gradient-avatar bg-avatar">
                    <img
                      *ngIf="item.icon"
                      class="border-radius-full"
                      [src]="item.message.actor_avatar"
                      width="38"
                      height="38"
                    />
                  </div>
                  <span class="noti-sub-icon">
                    <img
                      [src]="item.message.sub_category_icon"
                      width="16"
                      height="16"
                    />
                  </span>
                </div>

                <div class="noti-content">
                  <div class="display-title">
                    <div class="title">{{ item.title }}</div>
                    <div class="action">
                      <span
                        #deleteIcon
                        hidden
                        (click)="deleteNotify(item.id, i, $event)"
                        ><img
                          *ngIf="!item.expired_at"
                          src="assets/icons/u_trash-alt.svg"
                          width="14"
                          height="15"
                      /></span>
                      <span
                        [hidden]="item.is_read"
                        class="dot bg-gradient"
                      ></span>
                    </div>
                  </div>
                  <div class="message" [innerHtml]="item.message.message"></div>
                  <div *ngIf="item.expired_at" class="noti-del">
                    <div>
                      Thông báo sẽ được xóa sau {{ item.expired_at }} phút nữa
                    </div>
                    <div
                      (click)="cancelDeleteNotify(item.id, i, $event)"
                      class="cancel-del"
                    >
                      Hủy xóa
                    </div>
                  </div>
                  <div class="time">{{ item.created_at }}</div>
                </div>
              </a>
            </ng-container>
            <div
              class="no-notify"
              *ngIf="loadList?.length == 0 && notify?.length == 0"
            >
              Không có thông báo
            </div>
          </div>
          <div class="transition-a" [ngClass]="loading ? 'visible' : 'hidden'">
            <div [ngTemplateOutlet]="pulse"></div>
          </div>
        </div>
      </div>
      <!-- <div
        *ngIf="notifyTotal > notify.length"
        (click)="
          getNotification(serviceId, selectedType, selectedGroupIndex, true)
        "
        class="view-more-btn"
      >
        Xem thêm
      </div> -->
    </div>
  </div>
  <!--End notification body-->
</div>
<ng-template #pulse>
  <div *ngFor="let i of loadList" class="pulse-contain">
    <div class="animate-pulse display-pulse">
      <div class="border-radius-full circle"></div>
      <div class="box">
        <div class="line w-75"></div>
        <div class="line"></div>
        <div class="line w-33"></div>
      </div>
    </div>
  </div>
</ng-template>

<!-- end notification -->
