import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExportHistoryService, ProductService } from 'src/app/api/api';
import { ImportExportHistory } from 'src/app/model/models';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { Subject } from 'rxjs';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'src/app/core/services';

@Component({
  selector: 'meu-import-export-contract',
  templateUrl: './import-export-contract.component.html',
  styleUrls: ['./import-export-contract.component.scss'],
})
export class ImportExportContractComponent implements OnInit {
  idContract: string = '';
  im_Export_code: string;
  imExport_code: string;
  searchIExport: string = '';
  idInventory: string;

  indexArray: number = 0;
  totalArray: number;
  array: ImportExportHistory[] = [];
  IExports: ImportExportHistory[] = [];

  subject: Subject<any> = new Subject();
  sortName: string = '';
  sort: string = '';

  titleModal: string;
  isOpenModal: boolean;
  options: MxModalOption = {};

  titleModalExport: string;
  isOpenModalExport: boolean;
  optionsExport: MxModalOption = {};

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private importExportService: ImportExportHistoryService,
    private confirmService: ConfirmService,
    private product: ProductService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.idContract = this.router.url.split('/')[5];
    this.getIExportOfProduct();
    this.optionCallbackModalIx();
    this.optionCallbackModalEx();
    this.search();
  }

  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getIExportOfProduct();
  }

  //search
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}
  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getIExportOfProduct();
    });
  }

  // Paging

  getIExportOfProduct() {
    this.importExportService
      .apiImportExportHistoryGet(
        `contract_id==${this.idContract},is_draft==false,imExportCode|unit_name@=${this.searchIExport}`
      )
      .pipe(debounceTime(1000))

      .subscribe((res) => {
        if (res.success == true) {
          this.array = res.data.collection;
          this.totalArray = res.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.importExportService
      .apiImportExportHistoryGet(
        `contract_id==${this.idContract},is_draft==false,imExportCode|unit_name@=${this.searchIExport}`,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.IExports = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //Modal
  addImport() {
    this.titleModal = 'Thêm mới phiếu nhập hàng';
    if (!this.isOpenModal) {
      this.isOpenModal = true;
      this.options.styleClass = 'w-11/12';
    }
  }

  addExport() {
    this.titleModalExport = 'Thêm mới phiếu xuất hàng';
    if (!this.isOpenModal) {
      this.isOpenModalExport = true;
      this.optionsExport.styleClass = 'w-11/12';
    }
  }
  optionCallbackModalIx() {
    this.options.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModal = false;
    };
  }
  optionCallbackModalEx() {
    this.optionsExport.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModalExport = false;
    };
  }
  open(code) {
    if (code) {
      if (code.includes('PN')) {
        this.imExport_code = code;
        this.titleModal = 'Chi tiết phiếu nhập hàng';

        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }

      //Export
      if (code.includes('PX')) {
        this.im_Export_code = code;
        this.titleModalExport = 'Chi tiết phiếu xuất hàng';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
    }
  }

  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      this.imExport_code = '';
    }
    if (event.success) {
      this.getIExportOfProduct();
    }
  }

  closeModalExport(event) {
    if (this.isOpenModalExport) {
      this.isOpenModalExport = false;
      this.im_Export_code = '';
    }
    if (event.success) {
      this.getIExportOfProduct();
    }
  }

  changeTitle(event) {
    if (event.success) {
      this.titleModal = 'Chi tiết phiếu nhập hàng';
    }
  }

  changeTitleExport(event) {
    if (event.success) {
      this.titleModalExport = 'Chi tiết phiếu xuất hàng';
    }
  }
}
