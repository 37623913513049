import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { UnitManageComponent } from './unit-manage/unit-manage.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalCreateUnitComponent } from './unit-manage/modal-create-unit/modal-create-unit.component';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';

const routes: Routes = [
  {
    path: 'unit-list',
    component: UnitManageComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
];

@NgModule({
  declarations: [UnitManageComponent, ModalCreateUnitComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class UnitModule {}
