<div class="sm:grid sm:grid-cols-12 px-16px h-auto">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="formcreate">
      <div class="w-full grid grid-cols-12 gap-5">
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Tên đơn vị tính <span class="text-red-light">*</span></span
            >

            <input
              [ngClass]="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập tên đơn vị tính"
              formControlName="name"
              required
              class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
              "
            >
              {{
                formcreate.controls.name.errors?.required
                  ? 'Vui lòng nhập tên đơn vị tính'
                  : 'Tên đơn vị tối đa 30 kí tự'
              }}
            </span>
          </div>
        </div>
        <div class="sm:col-span-6 col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Mã đơn vị tính <span class="text-red-light">*</span></span
            >
            <!-- (input)="onChangeCode($event.target.value)" -->
            <input
              [ngClass]="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập mã đơn vị tính"
              formControlName="code"
              required
              class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
              "
            >
              {{
                formcreate.controls.code.errors?.required
                  ? 'Vui lòng nhập mã đơn vị tính'
                  : 'Mã đơn vị tối đa 30 kí tự'
              }}
            </span>
            <span
              class="font-roboto-regular text-12px text-purplish-grey font-medium not-italic"
              >Định dạng hợp lệ: THUNG, THUNG10KG, THUNG24CHAI
            </span>
          </div>
        </div>
        <div class="col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.description.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Mô tả</span
            >
            <textarea
              [ngClass]="
                formcreate.controls.description.errors?.maxlength
                  ? 'class-invalid'
                  : 'class-valid'
              "
              placeholder="Nhập mô tả"
              formControlName="description"
              class="w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            ></textarea>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="formcreate.controls.description.errors?.maxlength"
            >
              {{
                formcreate.controls.description.errors?.maxlength
                  ? 'Mô tả tối đa 300 ký tự'
                  : ''
              }}
            </span>
          </div>
        </div>

        <div
          class="col-span-full flex-row-reverse gap-8px flex h-64px mt-52px pt-8px items-center"
        >
          <button
            class="btn-primary-add"
            [disabled]="!formcreate.valid"
            type="button"
            (click)="setButtonAddOrEdit()"
          >
            {{ nameButton }}
          </button>
          <button class="btn-text-cancel" type="button" (click)="closeModal()">
            Hủy
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
