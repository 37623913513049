import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  InventoryZoneService,
  InventoryService,
  DashboardService,
} from 'src/app';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { InventoryZone } from 'src/app/model/inventoryZone';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';

@Component({
  selector: 'meu-app-inventory-management-map',
  templateUrl: './app-inventory-management-map.component.html',
  styleUrls: ['./app-inventory-management-map.component.scss'],
})
export class AppInventoryManagementMapComponent implements OnInit {
  inventoryId: string;
  zoneId: string;
  zoneIndex;
  imExport_code: any;
  dataFromContainer: any;

  optionsExport: MxModalOption = {};
  options: MxModalOption = {};
  zoneOption: MxModalOption = {};
  optionsChangePosition: MxModalOption = {};

  openModalFromSlot: boolean = false;
  isAdmin: boolean = false;
  isOpenZoneModal;
  isCreateZone;
  isOpenModal: any;
  isOpenModalExport: any;
  isOpenModalChangePosition: any;

  titleModalZone: string;
  titleModalExport: string;
  titleModal: string;
  titleModalChangePosition: string;

  zoneObject: InventoryZone = {};
  inventory: InventoryZone = {};

  inventoryZone = [];
  blockData = [];
  zoneUsedPercent$ = new Subject();
  currentZoneSelected: InventoryZone;

  disableImExport: boolean = false;

  chart: any;
  chartData = {
    labels: ['Có hàng', 'Trống'],
    datasets: [
      {
        label: '',
        data: [0, 100],
        backgroundColor: ['#ffcb44', '#ababab'],
        hoverOffset: 1,
        borderWidth: 1,
      },
    ],
  };
  lstTransfer = [{ title: 'Phiếu Nhập' }, { title: 'Phiếu Xuất' }];

  slotSelected: any = {};
  isMobile: boolean = false;

  //For Transfer Car
  isTransferCar: boolean = false;

  array_slot: Array<any> = [];

  // select 1
  isCheckSlot: boolean = false;
  // select > 2
  isCheckSlots: boolean = false;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private domSanitizer: DomSanitizer,
    private inventoryZoneService: InventoryZoneService,
    private inventoryService: InventoryService,
    private router: Router,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private confirmService: ConfirmService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.queryScreen();
    window.addEventListener('resize', (e) => this.queryScreen());
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        if (/admin/.test(this.router.url)) {
          this.isAdmin = true;
        }
        this.inventoryId = params.inventoryId;
        this.getInventoryById(this.inventoryId);
        this.getInventoryZoneByInventoryId(this.inventoryId);
        this.checkTransferCar(this.inventoryId);
        if (this.chart) {
          this.chart.destroy();
        }
      }
      this.optionCallbackModal();
      this.zoneOptionCallbackModal();
      this.optionCallbackModalExport();
      this.optionCallbackModalChangePosition();
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.chart) {
      this.chart.destroy();
    }
  }
  updatePercent(res) {
    this.isCheckSlot = false;
    if (res == true) {
      this.getInventoryCapacityPerZoneStatus(this.inventoryId, true);
    }
  }
  queryScreen() {
    this.isMobile = document.body.offsetWidth < 768 ? true : false;
  }
  checkTransferCar(id) {
    this.inventoryService.apiInventoryIdGet(id).subscribe((res) => {
      if (
        res.data.code.includes('SANGXE') ||
        res.data.code.includes('SANG XE')
      ) {
        this.disableImExport = true;
      } else {
        this.disableImExport = false;
      }
    });
  }
  getInventoryZoneByInventoryId(id) {
    this.inventoryZoneService
      .apiInventoryZonesInventoryIdGet(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.success) {
          this.inventoryZone = res.data.collection;

          if (this.inventoryZone.length > 0) {
            this.currentZoneSelected = this.inventoryZone[0];
            this.getInventoryCapacityPerZoneStatus(this.inventoryId);
          }
          this.initDoughnutChart();
        }
      });
  }
  getInventoryById(id) {
    this.inventoryService
      .apiInventoryIdGet(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.success) {
          this.inventory = res.data;
        }
      });
  }
  // getInventoryCapacityStatus(id) {
  //   this.dashboardService.apiDashboardInventoryCapacityStatusInventoryIdGet(id).subscribe((res) => {
  //     if (res.success) {
  //       this.inventoryUsedPercent = res.data.used_percent;
  //     }
  //   });
  // }
  getInventoryCapacityPerZoneStatus(id, isCurrentZone?: boolean) {
    this.dashboardService
      .apiDashboardInventoryCapacityPerZoneStatusInventoryIdGet(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.success) {
          if (isCurrentZone) {
            res.data.forEach((item, i) => {
              this.inventoryZone[i].used_percent =
                item.used_percent > 0 ? item.used_percent : 0;
              if (this.inventoryZone[i].id == this.currentZoneSelected.id) {
                this.zoneUsedPercent$.next(this.inventoryZone[i].used_percent);
              }
              this.initDoughnutChart();
            });
          } else {
            res.data.forEach((item, i) => {
              this.inventoryZone[i].used_percent =
                item.used_percent > 0 ? item.used_percent : 0;
            });
            this.zoneUsedPercent$.next(this.inventoryZone[0].used_percent);
          }
        }
      });
  }
  selectChangeInventoryZone(item) {
    this.initDoughnutChart();
    this.currentZoneSelected = item;
    this.zoneUsedPercent$.next(item.used_percent);
  }

  //Open modal
  open(event?) {
    if (event) {
      if (event.code) {
        this.imExport_code = event.code;
        this.dataFromContainer = event.dataFromContainer;
        this.titleModal = this.imExport_code.includes('PN')
          ? 'Chi tiết phiếu nhập hàng'
          : 'Chi tiết phiếu khai báo';
      } else {
        this.dataFromContainer = event.dataFromContainer;
        this.titleModal = 'Thêm mới phiếu nhập hàng';
      }
    } else {
      this.dataFromContainer = null;
      this.titleModal = 'Thêm mới phiếu nhập hàng';
    }

    if (!this.isOpenModal) {
      this.isTransferCar = false;
      this.isOpenModal = true;
      this.openModalFromSlot = false;
      this.options.styleClass = 'w-11/12';
    }
  }

  //Sang xe
  openKYC(event?) {
    if (event) {
    } else {
      this.dataFromContainer = null;
      this.titleModal = 'Thêm mới phiếu khai báo';
    }
    if (!this.isOpenModal) {
      this.isTransferCar = true;
      this.isOpenModal = true;
      this.openModalFromSlot = false;
      this.options.styleClass = 'w-11/12';
    }
  }

  openTransferCar(event?) {
    if (event) {
    } else {
      this.dataFromContainer = null;
      this.titleModal = 'Thêm mới phiếu sang xe';
    }
    if (!this.isOpenModal) {
      this.isTransferCar = true;
      this.isOpenModalExport = true;
      this.openModalFromSlot = false;
      this.optionsExport.styleClass = 'w-11/12';
    }
  }

  openExport(event) {
    if (event) {
      if (event.code) {
        this.imExport_code = event.code;
        this.dataFromContainer = event.dataFromContainer;
        this.titleModalExport = this.imExport_code.includes('PX')
          ? 'Chi tiết phiếu xuất hàng'
          : 'Chi tiết phiếu sang xe';
      } else {
        this.dataFromContainer = event.dataFromContainer;
        this.titleModalExport = 'Thêm mới phiếu xuất hàng';
      }
    } else {
      this.dataFromContainer = null;
      this.titleModalExport = 'Thêm mới phiếu xuất hàng';
    }

    if (!this.isOpenModalExport) {
      this.isTransferCar = false;
      this.isOpenModalExport = true;
      this.openModalFromSlot = false;
      this.optionsExport.styleClass = 'w-11/12';
    }
  }

  openModalChangePosition(event?, arraySlot?) {
    if (event) {
      Object.assign(event[0], { zone_id: this.currentZoneSelected.id });
      this.array_slot.push({
        id_slot: event[0].id_slot,
        zone_id: event[0].zone_id,
        code: event[0].code,
        used_percent: event[0].used_percent,
      });
      Object.assign(this.array_slot, { isCheckOpenFormModalSlot: true });
      this.isOpenModalChangePosition = true;
      this.openModalFromSlot = false;
      this.optionsChangePosition.styleClass = 'w-6/12';
      this.titleModalChangePosition = 'Chuyển vị trí ô';
    } else {
      if (arraySlot.lstSlotSelected && arraySlot.lstSlotSelected.length != 0) {
        arraySlot.lstSlotSelected.forEach((item) => {
          this.array_slot.push({
            id_slot: item.id,
            zone_id: item.zone_id,
            code: item.code,
            used_percent: item.used_percent,
          });
        });
        Object.assign(this.array_slot, { isCheckOpenFormModalSlot: false });
        this.isOpenModalChangePosition = true;
        this.openModalFromSlot = false;
        this.optionsChangePosition.styleClass = 'w-6/12';
        this.titleModalChangePosition = 'Chuyển vị trí ô';
      } else {
        this.toastrService.warnning('Vui lòng chọn ô để chuyển vị trí');
      }
    }
  }

  //Call back
  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModal = false;
      this.isTransferCar = false;
    };
  }

  optionCallbackModalExport() {
    this.optionsExport.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModalExport = false;
    };
  }

  optionCallbackModalChangePosition() {
    this.optionsChangePosition.callBackAfterClose = (e) => {
      this.isOpenModalChangePosition = false;
      this.array_slot = [];
    };
  }

  //Close modal import
  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      // if (this.slotSelected.lstSlotSelected.length < 1) {
      // } else {
      // }

      this.openModalFromSlot = event.isCloseModal;
      if (this.openModalFromSlot) {
        this.isCheckSlot = !this.isCheckSlot;
        this.getInventoryCapacityPerZoneStatus(this.inventoryId, true);
        this.getInventoryById(this.inventoryId);
        this.getInventoryZoneByInventoryId(this.inventoryId);
      } else {
        this.isCheckSlots = !this.isCheckSlots;
        this.slotSelected = {};
        this.getInventoryCapacityPerZoneStatus(this.inventoryId, true);
        this.getInventoryById(this.inventoryId);
        this.getInventoryZoneByInventoryId(this.inventoryId);
      }

      this.imExport_code = '';
    }
  }
  closeModalExport(event) {
    if (this.isOpenModalExport) {
      this.openModalFromSlot = event.isCloseModal;
      this.isCheckSlots = !this.isCheckSlots;
      this.slotSelected = {};
      this.getInventoryCapacityPerZoneStatus(this.inventoryId, true);
      this.getInventoryById(this.inventoryId);
      this.getInventoryZoneByInventoryId(this.inventoryId);
      this.getInventoryZoneById(this.inventoryId);
      this.isOpenModalExport = false;
      this.imExport_code = '';
    }
  }

  closeModalChangeSlot(event) {
    this.isOpenModalChangePosition = false;
    this.array_slot = [];
    if (event) {
      this.isCheckSlots = !this.isCheckSlots;
      this.getInventoryCapacityPerZoneStatus(this.inventoryId, true);
      this.getInventoryById(this.inventoryId);
      this.getInventoryZoneByInventoryId(this.inventoryId);
    } else {
      this.openModalFromSlot = true;
    }
  }

  changeTitle(event) {
    if (event.success) {
      this.titleModal = 'Chi tiết phiếu nhập hàng';
    }
  }

  changeTitleExport(event) {
    if (event.success) {
      this.titleModalExport = 'Chi tiết phiếu xuất hàng';
    }
  }

  openModalCreateZone(id?, index?) {
    this.isOpenZoneModal = true;
    this.zoneOption.styleClass = 'w-4/12';
    if (id) {
      this.getInventoryZoneById(id);
      this.titleModalZone = 'Chỉnh sửa tầng';
      this.isCreateZone = false;
      this.zoneId = id;
      this.zoneIndex = index;
    } else {
      this.titleModalZone = 'Tạo mới tầng';
      this.isCreateZone = true;
    }
    this.zoneObject.inventory_id = this.inventoryId;
    this.zoneObject.description = '';
    this.zoneObject.plan_map_image_name = '';
    this.zoneObject.plan_map_image_path = '';
    this.zoneObject.plan_map_ratio = 0;
  }
  zoneOptionCallbackModal() {
    this.zoneOption.callBackAfterClose = (e) => {
      this.isOpenZoneModal = false;
      this.zoneObject = {};
    };
  }
  saveInventoryZone() {
    if (this.isCreateZone) {
      this.createInventoryZone();
    } else {
      this.editInventoryZone(this.zoneId);
    }
  }
  createInventoryZone() {
    this.inventoryZoneService.apiInventoryZonePost(this.zoneObject).subscribe(
      (res) => {
        if (res.success) {
          this.inventoryZone.push(res.data);
          if (this.inventoryZone.length == 1) {
            this.currentZoneSelected = this.inventoryZone[0];
          }
          this.initDoughnutChart();
          this.getInventoryZoneByInventoryId(this.inventoryId);
          this.isOpenZoneModal = false;
          this.toastrService.success('Lưu thành công');
        } else {
          this.toastrService.error(res.message);
        }
      },
      (error) => {
        this.toastrService.error('Đã có lỗi xảy ra');
      }
    );
  }

  editInventoryZone(zoneId) {
    this.inventoryZoneService
      .apiInventoryZoneIdPut(zoneId, this.zoneObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.success) {
            this.inventoryZone[this.zoneIndex] = res.data;
            this.getInventoryCapacityPerZoneStatus(this.inventoryId, true);
            this.isOpenZoneModal = false;
            this.toastrService.success('Lưu thành công');
          } else {
            this.toastrService.error(res.message);
          }
        },
        (error) => {
          this.toastrService.error('Đã có lỗi xảy ra');
        }
      );
  }
  getInventoryZoneById(id) {
    this.inventoryZoneService
      .apiInventoryZoneIdGet(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.success) {
          this.zoneObject.inventory_id = res.data.inventory;
          this.zoneObject.name = res.data.name;
          this.zoneObject.description = res.data.description;
        }
      });
  }

  deleteInventoryZone(zoneId, index) {
    let title = 'Xóa tầng';
    let content = 'Bạn có chắc chắn muốn xóa tầng này?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.inventoryZoneService
            .apiInventoryZoneIdDelete(zoneId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
              if (res.success) {
                this.inventoryZone.splice(index, 1);
                this.toastrService.success('Xóa thành công');
              } else {
                this.toastrService.error(
                  'Dữ liệu này đã tồn tại dữ liệu phụ thuộc'
                );
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
  initDoughnutChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    let chartElement = document.getElementById('doughnut') as HTMLElement;
    this.chart = new Chart(chartElement as HTMLCanvasElement, {
      type: 'doughnut',
      data: this.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            title: {
              font: {
                size: 12,
              },
              color: '#041826',
            },
            labels: {
              boxWidth: 10,
              boxHeight: 10,
            },
          },
        },
      },
    });
    this.chart.resize(182, 190);
    this.zoneUsedPercent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x: any) => {
        this.chartData.datasets[0].data = [x, 100 - x];
        this.chart.update();
      });
  }
}
