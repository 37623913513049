import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Slot, SlotService } from 'src/app';
import { ConfirmService } from 'src/app/core/services';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { ToastrService } from 'src/app/core/services';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'meu-app-inventory-container-modal',
  templateUrl: './app-inventory-container-modal.component.html',
  styleUrls: ['./app-inventory-container-modal.component.scss'],
})
export class AppInventoryContainerModalComponent implements OnInit {
  private cdr: ChangeDetectorRef;
  @Input() dataFromContainer: Slot;
  @Input() isAdmin: boolean = false;
  @Input() isUpdatePercent: boolean = false;
  @Output() closeModal = new EventEmitter();
  @Output() modalEvent: EventEmitter<any> = new EventEmitter();
  @Output() openModalImport: EventEmitter<any> = new EventEmitter();
  @Output() openModalExport: EventEmitter<any> = new EventEmitter();
  @Output() openModalChangePosition: EventEmitter<any> = new EventEmitter();

  imExport_code: any;
  titleModal: string;
  isOpenModal: any;
  options: MxModalOption = {};

  allImportExport = [];

  slot: any[] = [];

  inventoryMapForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
    used_percent: new FormControl('', [
      Validators.pattern('^[0-9][0-9]?$|^100$'),
    ]),
    _long: new FormControl('', [Validators.required]),
    width: new FormControl('', [Validators.required]),
    height: new FormControl('', [Validators.required]),
    weight: new FormControl('', [Validators.required]),
    name: new FormControl(),
  });
  displayColumn = [
    '#',
    'Mã phiếu nhập/xuất',
    'Ngày nhập/xuất',
    'Người nhập/xuất kho',
  ];
  constructor(
    private confirmService: ConfirmService,
    private importExportHistoryService: ImportExportHistoryService,
    private toastService: ToastrService,
    private slotService: SlotService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (this.dataFromContainer) {
      this.inventoryMapForm.patchValue({
        code: this.dataFromContainer.code,

        used_percent: this.dataFromContainer.used_percent,

        _long: this.dataFromContainer._long,

        width: this.dataFromContainer.width,

        height: this.dataFromContainer.height,

        weight: this.dataFromContainer.weight,

        name: this.dataFromContainer.name,
      });
      if (this.inventoryMapForm.controls['code'].value == null) {
        this.inventoryMapForm.controls['code'].reset();
      }
      if (this.inventoryMapForm.controls['used_percent'].value == null) {
        this.inventoryMapForm.controls['used_percent'].reset();
      }
      if (this.inventoryMapForm.controls['_long'].value == null) {
        this.inventoryMapForm.controls['_long'].reset();
      }
      if (this.inventoryMapForm.controls['width'].value == null) {
        this.inventoryMapForm.controls['width'].reset();
      }
      if (this.inventoryMapForm.controls['height'].value == null) {
        this.inventoryMapForm.controls['height'].reset();
      }
      if (this.inventoryMapForm.controls['weight'].value == null) {
        this.inventoryMapForm.controls['weight'].reset();
      }
      if (this.inventoryMapForm.controls['name'].value == null) {
        this.inventoryMapForm.controls['name'].reset();
      }

      if (!this.isAdmin) {
        this.inventoryMapForm.controls['code'].disable();
        this.inventoryMapForm.controls['_long'].disable();
        this.inventoryMapForm.controls['width'].disable();
        this.inventoryMapForm.controls['height'].disable();
        this.inventoryMapForm.controls['weight'].disable();
        this.inventoryMapForm.controls['name'].disable();
      }
    }
    // else {
    //   this.inventoryMapForm.reset();
    // }
    if (this.dataFromContainer?.id) {
      this.getAllImportExport();
    }
  }
  ngOnInit(): void {
    this.optionCallbackModal();
  }
  ngOnDestroy() {}
  updateImage(img) {}

  saveInventoryMap() {
    if (this.inventoryMapForm.value.used_percent == null) {
      this.dataFromContainer.used_percent = 0;
      this.inventoryMapForm.controls['used_percent'].setValue(0);
    } else {
      this.dataFromContainer.used_percent = this.inventoryMapForm.value.used_percent;
    }

    if (this.isAdmin) {
      this.dataFromContainer.code = this.inventoryMapForm.value.code;
      this.dataFromContainer._long = this.inventoryMapForm.value._long;
      this.dataFromContainer.width = this.inventoryMapForm.value.width;
      this.dataFromContainer.height = this.inventoryMapForm.value.height;
      this.dataFromContainer.weight = this.inventoryMapForm.value.weight;
      this.dataFromContainer.name = this.inventoryMapForm.value.name;
    }

    if (!this.isAdmin) {
      this.slotService
        .apiSlotIdUsedPut(
          this.dataFromContainer.id,
          this.dataFromContainer.used_percent
        )
        .subscribe((res) => {});
    }

    if (this.isUpdatePercent) {
      this.modalEvent.emit(this.dataFromContainer);
      this.isUpdatePercent = false;
    } else {
      this.isUpdatePercent = false;
      this.modalEvent.emit();
    }
    //emit data to change percent in modal slot

    this.closeModal.emit(true);
    // this.inventoryMapForm.reset();
  }
  removeSlot() {
    let title = 'Xóa ô';
    let content = 'Bạn có chắc chắn muốn xóa ô này?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.modalEvent.emit('delete');
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModal = false;
    };
  }
  openIE(code) {
    if (code.includes('PN')) {
      this.openImport(code);
    } else {
      this.openExport(code);
    }
  }

  openImport(code) {
    // this.closeModal.emit();
    // this.openModalImport.emit(this.dataFromContainer);

    if (code) {
      this.closeModal.emit();
      this.openModalImport.emit({
        code: code,
        dataFromContainer: this.dataFromContainer,
      });
    } else {
      this.closeModal.emit();
      this.openModalImport.emit({
        code: null,
        dataFromContainer: this.dataFromContainer,
      });
    }
    // if (code) {
    //   this.imExport_code = code;
    //   this.titleModal = 'Chỉnh sửa phiếu nhập hàng';
    // } else {
    //   this.titleModal = 'Thêm mới phiếu nhập hàng';
    // }

    // if (!this.isOpenModal) {
    //   this.isOpenModal = true;
    //   this.options.styleClass = 'w-11/12';
    // }
  }
  openExport(code) {
    if (code) {
      this.closeModal.emit();
      this.openModalExport.emit({
        code: code,
        dataFromContainer: this.dataFromContainer,
      });
    } else {
      this.closeModal.emit();
      this.openModalExport.emit({
        code: null,
        dataFromContainer: this.dataFromContainer,
      });
    }
  }

  openChangePositionModal() {
    this.closeModal.emit();
    if (this.slot) {
      this.slot = [];
    }
    this.slot.push({
      id_slot: this.dataFromContainer.id,
      code: this.dataFromContainer.code,
      used_percent: this.dataFromContainer.used_percent,
    });
    this.openModalChangePosition.emit(this.slot);
  }

  // closeModalImport(event) {
  //   if (this.isOpenModal) {
  //     this.isOpenModal = false;
  //     this.imExport_code = '';
  //   }
  // }

  // changeTitle(event) {
  //   if (event.success) {
  //     this.titleModal = 'Chi tiết phiếu nhập hàng';
  //   }
  // }

  getAllImportExport() {
    this.importExportHistoryService
      .apiImportExportHistoryGet('slot_id==' + this.dataFromContainer.id)
      .subscribe((data) => {
        if (data.success == true) {
          this.allImportExport = data.data.collection;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
}
