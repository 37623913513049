import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '../../guards/permission.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'meu-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private sharedService: SharedService
  ) {}
  permission;
  ngOnInit(): void {}
  return() {
    window.location.href = '/';
  }
  logOut() {
    localStorage.removeItem('permissions');
    this.sharedService.openSSO();
  }
}
