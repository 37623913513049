<form [formGroup]="formUpdate">
  <div class="grid grid-cols-12 gap-4 mt-24px">
    <div class="md:col-span-3 col-span-full">
      <span class="text-12px font-roboto-regular text-grey-label mb-2">
        Hình ảnh mô tả
      </span>
      <div
        class="flex border border-grayscale-light-grey rounded mt-8px h-276px"
        (click)="imagePath.click()"
      >
        <img
          [src]="urlImage"
          *ngIf="urlImage != null; else defaultImage"
          class="w-full h-full m-auto"
        />
        <ng-template #defaultImage>
          <img
            [src]="urlProduct"
            class="w-full h-full m-auto"
            *ngIf="urlProduct != null; else noteImage"
          />
        </ng-template>
        <ng-template #noteImage
          ><img
            src="assets/icons/table-icon/u_image-input-default.svg"
            class="w-32px h-32px m-auto"
          />
        </ng-template>
        <input
          type="file"
          class="hidden"
          #imagePath
          (change)="getImage($event.target.files)"
        />
      </div>
      <button
        class="btn-primary-barcode w-full mt-8px hidden items-center justify-center gap-2px"
      >
        <img src="assets/icons/detail-icon/u_qrcode-scan.svg" /><span
          >In QR Code</span
        >
      </button>
    </div>
    <div class="md:col-span-9 col-span-full">
      <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div class="sm:col-span-1 col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              (formUpdate.get('name').touched &&
                formUpdate.controls.name.errors?.required) ||
              formUpdate.controls.name.errors?.maxlength
                ? 'error-class'
                : ''
            "
          >
            Tên hàng hóa <span class="text-red-light">*</span></span
          >
          <input
            placeholder="Nhập tên hàng hóa"
            (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
            [ngClass]="
              (formUpdate.get('name').touched &&
                formUpdate.controls.name.errors?.required) ||
              formUpdate.controls.name.errors?.maxlength
                ? 'class-invalid'
                : 'class-valid'
            "
            formControlName="name"
            type="text"
            class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
          />
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              (formUpdate.get('name').touched &&
                formUpdate.controls.name.errors?.required) ||
              formUpdate.controls.name.errors?.maxlength
            "
          >
            {{
              formUpdate.controls.name.errors?.required
                ? 'Vui lòng nhập tên hàng hóa'
                : 'Tên hàng hóa tối đa 100 ký tự'
            }}
          </span>
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              (formUpdate.get('code').touched &&
                formUpdate.controls.code.errors?.required) ||
              formUpdate.controls.code.errors?.maxlength
                ? 'error-class'
                : ''
            "
          >
            Mã hàng hóa <span class="text-red-light">*</span></span
          >
          <input
            placeholder="Nhập mã hàng hóa"
            (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
            [ngClass]="
              (formUpdate.get('code').touched &&
                formUpdate.controls.code.errors?.required) ||
              formUpdate.controls.code.errors?.maxlength
                ? 'class-invalid'
                : 'class-valid'
            "
            formControlName="code"
            type="text"
            class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
          />
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              (formUpdate.get('code').touched &&
                formUpdate.controls.code.errors?.required) ||
              formUpdate.controls.code.errors?.maxlength
            "
          >
            {{
              formUpdate.controls.code.errors?.required
                ? 'Vui lòng nhập mã hàng hóa'
                : 'Mã hàng hóa tối đa 20 ký tự'
            }}
          </span>
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formUpdate.controls.minimum_stock.errors?.pattern || isCheckStock
                ? 'error-class'
                : ''
            "
          >
            Định mức tối thiểu</span
          >
          <input
            [value]="formUpdate.get('minimum_stock').value | meucurrency"
            (keyup)="onChangeValueCurrency('minimum_stock', $event)"
            placeholder="Nhập định mức tối thiểu"
            [ngClass]="
              formUpdate.controls.minimum_stock.errors?.pattern || isCheckStock
                ? 'class-invalid'
                : 'class-valid'
            "
            formControlName="minimum_stock"
            type="text"
            class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
          />
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="formUpdate.controls.minimum_stock.errors?.pattern"
          >
            {{
              formUpdate.controls.minimum_stock.errors?.pattern
                ? 'Vui lòng nhập đúng ký tự số'
                : ''
            }}
          </span>
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formUpdate.controls.maximum_stock.errors?.pattern || isCheckStock
                ? 'error-class'
                : ''
            "
          >
            Định mức tối đa</span
          >
          <input
            [value]="formUpdate.get('maximum_stock').value | meucurrency"
            (keyup)="onChangeValueCurrency('maximum_stock', $event)"
            placeholder="Nhập định mức tối đa"
            [ngClass]="
              formUpdate.controls.maximum_stock.errors?.pattern || isCheckStock
                ? 'class-invalid'
                : 'class-valid'
            "
            formControlName="maximum_stock"
            type="text"
            class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
          />
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="formUpdate.controls.maximum_stock.errors?.pattern"
          >
            {{
              formUpdate.controls.maximum_stock.errors?.pattern
                ? 'Vui lòng nhập đúng ký tự số'
                : ''
            }}
          </span>
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formUpdate.get('productTemplate').touched &&
              formUpdate.controls.productTemplate.errors?.required
                ? 'error-class'
                : ''
            "
            >Phân loại hàng hóa <span class="text-red-light">*</span></span
          >
          <ng-select
            [ngClass]="
              formUpdate.get('productTemplate').touched &&
              formUpdate.controls.productTemplate.errors?.required
                ? 'select-invalid'
                : 'select-name'
            "
            class="mt-8px focus:outline-none"
            placeholder="Chọn phân loại hàng hóa"
            bindLabel="name"
            bindValue="id"
            [virtualScroll]="true"
            [items]="productTemplateChoose"
            formControlName="productTemplate"
            (change)="getproductTemplate($event)"
            [tooltip]="tooltipProductTemplate ? tooltipProductTemplate : ''"
            #toolTipProductTemplate="tooltip"
            [options]="{ display: tooltipProductTemplate ? true : false }"
            notFoundText="Không tìm thấy phân loại hàng hóa"
          >
            <ng-template ng-option-tmp let-item="item">
              <div
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="item.name"
              >
                {{ item.name }}
              </div>
            </ng-template>
          </ng-select>

          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              formUpdate.get('productTemplate').touched &&
              formUpdate.controls.productTemplate.errors?.required
            "
          >
            {{
              formUpdate.controls.productTemplate.errors?.required
                ? 'Vui lòng chọn phân loại hàng hóa'
                : ''
            }}
          </span>
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span class="font-roboto-regular text-12px text-grey-label"
            >Tình trạng hoạt động</span
          >
          <input
            readonly
            formControlName="status"
            type="text"
            class="border bg-grey-lightest overflow-hidden overflow-ellipsis border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-purplish-grey font-medium not-italic"
          />
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formUpdate.get('unitName').touched &&
              formUpdate.controls.unitName.errors?.required
                ? 'error-class'
                : ''
            "
            >Đơn vị tính <span class="text-red-light">*</span></span
          >
          <ng-select
            [ngClass]="
              formUpdate.get('unitName').touched &&
              formUpdate.controls.unitName.errors?.required
                ? 'select-invalid'
                : 'select-name'
            "
            class="select-name mt-8px focus:outline-none"
            placeholder="Chọn đơn vị tính"
            bindLabel="name"
            bindValue="id"
            [items]="unitChoose"
            formControlName="unitName"
            (change)="getUnit($event)"
            notFoundText="Không tìm thấy đơn vị tính"
            [tooltip]="tooltipUnit ? tooltipUnit : ''"
            #toolTipUnit="tooltip"
            [options]="{ display: tooltipUnit ? true : false }"
          >
            <ng-template ng-option-tmp let-item="item">
              <div
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="item.name"
              >
                {{ item.name }}
              </div>
            </ng-template>
          </ng-select>
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              formUpdate.get('unitName').touched &&
              formUpdate.controls.unitName.errors?.required
            "
          >
            {{
              formUpdate.controls.unitName.errors?.required
                ? 'Vui lòng chọn đơn vị tính'
                : ''
            }}
          </span>
        </div>
        <div class="sm:col-span-1 col-span-full">
          <span class="font-roboto-regular text-12px text-grey-label">
            Tồn kho
          </span>

          <input
            readonly
            [value]="product['stock'] | number"
            type="text"
            class="border overflow-hidden overflow-ellipsis bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-purplish-grey font-medium not-italic"
          />
        </div>
        <div class="col-span-full">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formUpdate.controls.description.errors?.maxlength
                ? 'error-class'
                : ''
            "
          >
            Mô tả</span
          >
          <textarea
            [ngClass]="
              formUpdate.controls.description.errors?.maxlength
                ? 'class-invalid'
                : 'class-valid'
            "
            (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
            placeholder="Nhập mô tả"
            formControlName="description"
            class="w-full border rounded-4px mt-8px py-8px pl-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
          ></textarea>
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="formUpdate.controls.description.errors?.maxlength"
          >
            {{
              formUpdate.controls.description.errors?.maxlength
                ? 'Mô tả tối đa 300 ký tự'
                : ''
            }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="product != null"
    class="col-span-full flex-row-reverse gap-8px flex h-72px py-16px mt-120px"
  >
    <button
      class="btn-cancel flex items-center gap-2px"
      type="button"
      (click)="cancel()"
      *ngIf="!change || formUpdate.invalid"
    >
      <img src="assets/icons/detail-icon/u_times.svg" />
      Hủy
    </button>
    <button
      class="btn-primary-add flex items-center gap-2px"
      type="button"
      [disabled]="change"
      (click)="updateProductDetail()"
    >
      <img src="assets/icons/detail-icon/u_save.svg" />
      Lưu
    </button>
    <button
      class="btn-text-cancel flex items-center gap-2px"
      type="button"
      (click)="hiddenProduct(product.is_hidden)"
    >
      <img
        *ngIf="product.is_hidden == false; else normal"
        src="assets/icons/detail-icon/u_eye-slash.svg"
      />
      <ng-template #normal>
        <img src="assets/icons/detail-icon/u_eye.svg" />
      </ng-template>
      <span>{{
        product.is_hidden == false ? 'Ẩn hàng hóa' : 'Hiện hàng hóa'
      }}</span>
    </button>
  </div>
</form>
