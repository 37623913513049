<div
  *ngIf="!isChart; else chart"
  [ngClass]="[
    optionStyle
      ? ''
      : 'border-b-0 border-l-0 md:border-b border-light-grey border-opacity-50 md:border-l',
    isSelectPageSize ? 'md:justify-between' : 'md:justify-end'
  ]"
  class="flex md:flex-row flex-col-reverse justify-center"
>
  <div
    *ngIf="isSelectPageSize"
    class="pl-16px py-14px font-roboto-regular text-14px text-center flex flex-wrap items-center gap-16px justify-end"
    style="color: #ababab"
  >
    <span>Số dòng trên 1 trang</span>
    <select
      class="focus:outline-none text-dark-grey w-45px bg-white hover:bg-primary-lightest rounded"
      [(ngModel)]="pageSize"
      (change)="onPaging()"
    >
      <option *ngFor="let pageSize of pageSizes" [ngValue]="pageSize.value">
        {{ pageSize.value }}
      </option>
    </select>
    <span class=""
      >{{ arrayStart }} - {{ arrayEnd }} trong {{ arrayLenght }} hàng</span
    >
  </div>
  <div
    class="flex font-roboto-regular text-14px justify-end"
    style="color: #ababab"
  >
    <button
      [ngClass]="optionStyle ? 'rounded-full' : 'button-back'"
      (click)="backPage()"
      [disabled]="currentPage === 1"
      class="w-48px hover:bg-primary-lightest focus:outline-none border-button"
    >
      <img
        *ngIf="currentPage === 1; else EnableBack"
        src="assets/icons/icon-pagination/icon_back_disable.svg"
        class="m-auto"
      />
      <ng-template #EnableBack>
        <img src="assets/icons/icon-pagination/icon_back.svg" class="m-auto" />
      </ng-template>
    </button>

    <button
      *ngFor="let page of pageShow"
      class="hover:bg-primary-lightest focus:outline-none border-button"
      [ngClass]="[
        page == currentPage ? 'active' : 'not-active',
        optionStyle ? 'rounded-full w-48px' : 'button-page w-59px'
      ]"
      (click)="change(page)"
    >
      {{ page }}
    </button>

    <button
      [ngClass]="optionStyle ? 'rounded-full' : 'button-next '"
      (click)="nextPage()"
      [disabled]="currentPage === totalPage"
      class="w-48px hover:bg-primary-lightest focus:outline-none border-button"
    >
      <img
        *ngIf="currentPage === totalPage; else EnableForward"
        src="assets/icons/icon-pagination/icon_forward_disable.svg"
        class="m-auto"
      />
      <ng-template #EnableForward>
        <img src="assets/icons/icon-pagination/icon_right.svg" class="m-auto" />
      </ng-template>
    </button>
  </div>
</div>
<ng-template #chart>
  <div class="flex gap-6px">
    <img
      *ngIf="currentPage === 1; else EnableBack"
      src="assets/icons/icon-pagination/icon_back_disable.svg"
    />
    <ng-template #EnableBack>
      <img
        class="cursor-pointer"
        (click)="backPage()"
        src="assets/icons/icon-pagination/icon_back.svg"
      />
    </ng-template>
    <div
      class="font-roboto-regular gap-6px text-14px text-center flex flex-wrap items-center justify-end"
      style="color: #ababab"
    >
      <select
        class="focus:outline-none text-dark-grey w-45px bg-white hover:bg-primary-lightest rounded"
        [(ngModel)]="pageSize"
        (change)="onPaging()"
      >
        <option *ngFor="let pageSize of pageSizes" [ngValue]="pageSize.value">
          {{ pageSize.value }}
        </option>
      </select>
      <span class=""
        >{{ arrayStart }} - {{ arrayEnd }} trong {{ arrayLenght }} hàng</span
      >
    </div>
    <img
      *ngIf="currentPage === totalPage; else EnableForward"
      src="assets/icons/icon-pagination/icon_forward_disable.svg"
    />
    <ng-template #EnableForward>
      <img
        class="cursor-pointer"
        src="assets/icons/icon-pagination/icon_right.svg"
        (click)="nextPage()"
      />
    </ng-template>
  </div>
</ng-template>
