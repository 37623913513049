import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class NotifyService {
    private message = new Subject();
    currentMessage = this.message.asObservable();
  
    constructor() { }
  
    reviceMessage(mess) {
      this.message.next(mess);
    }
}