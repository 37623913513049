<div class="container">
  <div class="my-4 sm:flex justify-between">
    <p
      class="text-20px text-left font-roboto-medium title-dashboard leading24 text-dark-grey mb-4"
    >
      Quản lý công nợ
    </p>
    <div class="relative">
      <input
        (keyup)="searchUser($event.target.value)"
        placeholder="Tìm khách hàng"
        class="search-import-export m-auto h-32px w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
        type="text"
      />
      <img
        class="absolute top-0 mt-9px ml-12px"
        src="assets/icons/handle-icons/Icons_search.svg"
      />
    </div>
  </div>
  <div
    class="col-span-12 grid grid-cols-12 gap-2 container-12 container-12-ipad mt-8px"
  >
    <div
      class="col-span-12 grid grid-cols-12 gap-1 m-dp-fl z-index-1 sm:gap-2"
    >
      <button
        class="bg-white h-80px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none mb-4 col-span-full sm:col-span-4"
      >
        <img
          class="w-28px h-28px mt-28px ml-12px col-span-2"
          src="assets/icons/debt-management/Misc_database.svg"
          alt=""
        />
        <div class="col-span-7 text-left text-grey-med">
          <div class="font-roboto-medium text-20px pt-15px">
            {{ totalTurnOver | meucurrency }}
          </div>
          <div
            class="text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Doanh thu
          </div>
        </div>
      </button>
      <button
        class=" bg-white h-80px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none mb-4 col-span-full sm:col-span-4"
      >
        <img
          class="w-28px h-28px mt-28px ml-12px col-span-2"
          src="assets/icons/debt-management/Finance_Weekly-Salary.svg"
          alt=""
        />
        <div class="col-span-7 text-left text-grey-med">
          <div class="font-roboto-medium text-20px pt-15px">
            {{ totalActualPaid | meucurrency }}
          </div>
          <div
            class="text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Thanh toán thực tế
          </div>
        </div>
      </button>
      <button
        class=" bg-white h-80px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none mb-4 col-span-full sm:col-span-4"
      >
        <img
          class="w-28px h-28px mt-28px ml-12px col-span-2"
          src="assets/icons/debt-management/Messaging_archive.svg"
          alt=""
        />
        <div class="col-span-7 text-left text-grey-med">
          <div class="font-roboto-medium text-20px pt-15px">
            {{ totalDebt | meucurrency }}
          </div>
          <div
            class="text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Ghi nợ
          </div>
        </div>
      </button>
    </div>
    <div
      class="col-span-12 flex flex-col items-stretch m-dp-fl z-index-1 sm:grid sm:grid-cols-12 sm:gap-2"
    >
      <div
        *ngFor="let item of listUserDebt"
        class="bg-white rounded-4px p-2 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none mb-4 border border-primary-light-2 sm:col-span-3"
      >
        <div
          (click)="
            openModal(item.extend_customer_name, item.id);
            sendUserDetailId(item.user_id)
          "
          class="flex flex-col"
        >
          <div class="flex justify-between text-14px font-roboto-medium mb-1">
            <div class="flex gap-3 items-center">
              <img
                src="{{ item.extend_customer_avatar }}"
                alt=""
                class="w-6 h-6 rounded object-cover"
              />
              <div class="text-dark-grey">{{ item.extend_customer_name }}</div>
            </div>
            <div class="text-primary-dodger-blue">
              {{ item.turnover | meucurrency }}
            </div>
          </div>
          <div class="flex justify-between text-12px font-roboto-regular mb-1">
            <div>
              <div class="">Thanh toán thực tế (VNĐ)</div>
            </div>
            <div class="text-black font-roboto-medium">
              {{ item.actual_paid | meucurrency }}
            </div>
          </div>
          <div class="flex justify-between text-12px font-roboto-regular">
            <div>
              <div class="">Ghi nợ (VNĐ)</div>
            </div>
            <div class="text-red-light font-roboto-medium">
              {{ item.debt | meucurrency }}
            </div>
          </div>
        </div>
      </div>
      <button
        [ngClass]="pageUser < totalUser ? 'block' : 'hidden'"
        (click)="loadMorePage()"
        class="text-primary-dodger-blue text-14px py-2.5 focus:outline-none full bg-white font-roboto-medium rounded sm:hidden"
      >
        Xem Thêm
      </button>
    </div>
  </div>
  <div class="hidden sm:flex justify-center w-full text-14px font-roboto-regular items-center" [ngClass]="pageUser < totalUser ? 'flex' : 'hidden'">
    <a class="cursor-pointer text-primary-dodger-blue" (click)="loadMorePage()">Xem Thêm</a>
  </div>
</div>

<!-- [title]="userTitle" -->
<!-- [cancel]="isCancelBottomsheet" -->
<meu-modal-popup-bottom
  [title]="titleModal"
  [isOpen]="isOpenBottomsheet"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-debt-manage
    (closeResquest)="closeResquest($event)"
    [userDetailID]="userDetailID"
    [userDetailDebt]="userDetail"
  ></meu-modal-debt-manage>
</meu-modal-popup-bottom>
<!--     <div
      class="col-span-12 flex flex-col items-stretch sm:items-center m-dp-fl z-index-1"
    >
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Misc_database.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium mt-5px text-20px">
            {{ totalTurnOver | meucurrency }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px mt-5px pt-2px"
          >
            Doanh thu
          </div>
        </div>
      </button>
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Finance_Weekly-Salary.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium mt-5px text-20px">
            {{ totalActualPaid | meucurrency }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px mt-5px pt-2px"
          >
            Thanh toán thực tế
          </div>
        </div>
      </button>
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Messaging_archive.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium mt-5px text-20px">
            {{ totalDebt | meucurrency }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px mt-5px pt-2px"
          >
            Ghi nợ
          </div>
        </div>
      </button>
    </div> -->
