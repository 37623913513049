<div class="container grid-contain font-roboto-regular">
  <ng-container *ngIf="isMobile; else desktop">
    <div
      class="col-span-12 w-full panel mt-4 flex items-center justify-center"
      style="height: 85vh"
    >
      <button
        id="import-btn"
        class="btn-primary-sm mr-4 my-2 flex flex-row items-center"
        (click)="open(null)"
        *ngIf="!disableImExport"
      >
        <img class="mr-1" src="assets/icons/handle-icons/fi_download.svg" />
        <span class="text-14px">Nhập hàng</span>
      </button>
      <div class="text-14px text-black">
        Hiện tại không hỗ trợ hiển thị trên điện thoại
      </div>
    </div>
  </ng-container>
  <ng-template #desktop>
    <div class="col-span-12 grid-contain">
      <div class="col-span-2 flex items-center w-full">
        <div
          class="text-16px text-dark-grey font-medium truncate"
          [tooltip]="inventory?.name"
        >
          {{ 'Sơ đồ ' + inventory?.name }}
        </div>
        <!-- <meu-subheader
          [title]="
            'Sơ đồ kho ' + inventory?.name + ' - ' + currentZoneSelected?.name
          "
        ></meu-subheader> -->
      </div>
      <div
        class="col-span-4 lg:col-span-7 xl:col-span-10 flex items-center justify-between"
        [ngClass]="isAdmin ? 'invisible' : 'visible'"
      >
        <div
          class="flex items-center"
          [ngClass]="
            slotSelected.lstSlotSelected?.length > 0 ? 'visible' : 'invisible'
          "
          [ngStyle]="{ width: '50%' }"
        >
          <input
            type="checkbox"
            class="form-checkbox h-4 w-4 text-gray-600"
            checked
          />
          <div class="ml-2 text-14px text-grey-label font-medium truncate">
            Đã chọn {{ slotSelected.text }}
          </div>
        </div>
        <div [ngStyle]="{ width: '50%' }" class="flex justify-end">
          <!-- <ng-select
            [clearable]="false"
            [searchable]="false"
            [items]="lstTransfer"
            bindValue="title"
            bindLabel="title"
            placeholder="Sang xe"
            (change)="transferCar($event)"
            class="focus:outline-none h-40px mt-2 mx-4 outline-none border-none"
          >
          </ng-select> -->

          <!-- chưa mở -->
          <!-- <button
            class="btn-trash mr-4 my-2 flex flex-row items-center"
            (click)="openModalChangePosition(null, slotSelected)"
          >
            <span class="text-14px">Chuyển vị trí</span>
          </button> -->

          <!-- chưa mở -->
          <button
            id="import-btn"
            class="btn-primary-sm mr-4 my-2 flex flex-row items-center"
            (click)="openKYC(null)"
            *ngIf="disableImExport"
          >
            <img class="mr-1" src="assets/icons/handle-icons/fi_download.svg" />
            <span class="text-14px">Khai báo</span>
          </button>
          <button
            class="btn-primary-sm my-2 mr-4 flex flex-row items-center"
            (click)="openTransferCar(null)"
            *ngIf="disableImExport"
          >
            <img
              class="mr-1"
              src="assets/icons/handle-icons/u_file-export.svg"
            />
            <span class="text-14px">Sang xe</span>
          </button>
          <button
            id="import-btn"
            class="btn-primary-sm mr-4 my-2 flex flex-row items-center"
            (click)="open(null)"
            *ngIf="!disableImExport"
          >
            <img class="mr-1" src="assets/icons/handle-icons/fi_download.svg" />
            <span class="text-14px">Nhập hàng</span>
          </button>
          <button
            class="btn-primary-sm my-2 flex flex-row items-center"
            (click)="openExport(null)"
            *ngIf="!disableImExport"
          >
            <img
              class="mr-1"
              src="assets/icons/handle-icons/u_file-export.svg"
            />
            <span class="text-14px">Xuất hàng</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-span-2 panel bg-white">
      <div class="py-2">
        <div class="px-2 flex justify-between">
          <div class="text-dark-grey font-semibold text-16px">Tầng/Khu</div>
          <img
            *ngIf="isAdmin"
            (click)="openModalCreateZone()"
            class="cursor-pointer"
            src="assets/icons/product-categories-icons/u_plus.svg"
          />
        </div>

        <ng-template ngFor let-item [ngForOf]="inventoryZone" let-i="index">
          <div
            [ngClass]="
              item.id == currentZoneSelected.id
                ? 'border border-filled '
                : ' border-greyish-brown'
            "
            class="block"
            [attr.style]="
              domSanitizer.bypassSecurityTrustStyle(
                '--width-filled:' + item.used_percent + '%'
              )
            "
            (click)="selectChangeInventoryZone(item)"
            (mouseover)="actionEl.hidden = false"
            (mouseout)="actionEl.hidden = true"
          >
            <div class="px-2 py-0.5 flex flex-row justify-between">
              <div class="flex flex-col">
                <div class="text-dark-grey relative font-semibold">
                  {{ item.name }}
                </div>
                <div class="text-grey-lightest relative">
                  {{ item.used_percent }}%
                </div>
              </div>
              <div #actionEl class="flex-col justify-between" hidden>
                <img
                  *ngIf="isAdmin"
                  (click)="openModalCreateZone(item.id, i)"
                  class="cursor-pointer w-15px h-15px mb-2px z-20 filter brightness-100"
                  src="assets/icons/handle-icons/u_edit-alt.svg"
                />
                <img
                  *ngIf="isAdmin"
                  (click)="deleteInventoryZone(item.id, i)"
                  class="cursor-pointer w-15px h-15px filter brightness-150"
                  src="assets/icons/product-categories-icons/u_trash-alt.svg"
                />
              </div>
            </div>
          </div>
        </ng-template>
        <!-- <ng-template ngFor let-item [ngForOf]="blockData">
          <div
            class="block"
            [attr.style]="
              domSanitizer.bypassSecurityTrustStyle(
                '--width-filled:' + item.value + '%'
              )
            "
          >
            <div class="px-2 py-0.5">
              <div class="text-dark-grey relative font-semibold">
                {{ item.name }}
              </div>
              <div class="text-grey-lightest relative">{{ item.value }}%</div>
            </div>
          </div>
        </ng-template> -->
      </div>
      <hr class="mx-2 text-light-grey" />
      <div class="p-2">
        <div class="flex flex-row flex-wrap">
          <div class="">
            <div class="text-16px font-semibold text-dark-grey">
              Tình trạng kho hàng
            </div>
            <div class="text-10px text-dark-grey">22/02/2021</div>
          </div>
          <div class="pt-3.5">
            <!-- <meu-app-doughnut-chart
              [data]="data"
              [width]="182"
              [height]="190"
              [percent]="38"
            ></meu-app-doughnut-chart> -->
            <div
              class="relative"
              [ngClass]="currentZoneSelected ? 'visible' : 'invisible'"
            >
              <canvas id="doughnut"></canvas>
              <div
                class="absolute top-65px left-75px text-18px font-semibold cssCustoms"
              >
                {{ zoneUsedPercent$ | async }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-span-10 md:h-600 lg:h-768 bg-white rounded shadow-sm"
      #elRef
    >
      <meu-app-inventory-management-editor
        *ngIf="currentZoneSelected"
        [zoneId]="currentZoneSelected.id"
        [width]="elRef.offsetWidth"
        [height]="elRef.offsetHeight"
        [isAdmin]="isAdmin"
        [isCheckSlot]="isCheckSlot"
        [isCheckSlots]="isCheckSlots"
        (updatePercent)="updatePercent($event)"
        (selectSlot)="slotSelected = $event"
        (openModalImport)="open($event)"
        (openModalExport)="openExport($event)"
        (openModalChangePosition)="openModalChangePosition($event, null)"
        [openModal]="openModalFromSlot"
      ></meu-app-inventory-management-editor>
    </div>
  </ng-template>

  <!-- Import -->
  <meu-modal-component
    [isOpen]="isOpenModal"
    [options]="options"
    [title]="titleModal"
    *ngIf="isOpenModal"
  >
    <meu-import-history
      [imExport_code]="imExport_code"
      [isTransferCar]="isTransferCar"
      [lstSlotSelected]="slotSelected.lstSlotSelected"
      [dataFromContainer]="dataFromContainer"
      (closeModalImport)="closeModal($event)"
      (changeTitleModal)="changeTitle($event)"
    ></meu-import-history>
  </meu-modal-component>

  <meu-modal-component
    [isOpen]="isOpenZoneModal"
    [options]="zoneOption"
    [title]="titleModalZone"
    *ngIf="isOpenZoneModal"
  >
    <div class="font-roboto-regular">
      <div
        class="modal-content-container px-4 py-2 xs:aqua-scrollbar md:overflow-y-visible"
      >
        <div class="flex flex-col mb-4">
          <span class="text-12px text-warm-grey"> Tên </span>
          <input
            type="text"
            placeholder="Nhập tên"
            [(ngModel)]="zoneObject.name"
            class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
            style="line-height: 1.43; letter-spacing: normal"
          />
          <!-- <span class="text-12px text-red-light"> </span> -->
        </div>
        <!-- <div class="flex flex-col">
          <span class="text-12px text-warm-grey"> Mô tả </span>
          <input
            type="text"
            placeholder="Nhập mô tả"
            [(ngModel)]="zoneObject.description"
            class="w-full py-8px pl-12px h-40px mt-8px text-14px border border-grayscale-light-grey rounded-4px placeholder-grayscale-light-grey text-dark-grey not-italic focus:outline-none focus:border-light-blue"
            style="line-height: 1.43; letter-spacing: normal"
          />
        </div> -->
      </div>
      <div class="flex gap-8px m-4 items-center justify-end">
        <button
          id="save-btn"
          class="btn-primary-sm"
          type="button"
          (click)="saveInventoryZone()"
        >
          <span class="text-white">Lưu</span>
        </button>
        <button
          id="close-btn"
          class="btn-text-sm"
          (click)="isOpenZoneModal = false"
        >
          Hủy
        </button>
      </div>
    </div>
  </meu-modal-component>
  <!-- Export -->
  <meu-modal-component
    [isOpen]="isOpenModalExport"
    [options]="optionsExport"
    [title]="titleModalExport"
    *ngIf="isOpenModalExport"
  >
    <meu-export-history
      [imExport_code]="imExport_code"
      [lstSlotSelected]="slotSelected.lstSlotSelected"
      [isTransferCar]="isTransferCar"
      [dataFromContainer]="dataFromContainer"
      (closeModalExport)="closeModalExport($event)"
      (changeTitleModal)="changeTitleExport($event)"
    ></meu-export-history>
  </meu-modal-component>

  <meu-modal-component
    [isOpen]="isOpenModalChangePosition"
    [options]="optionsChangePosition"
    [title]="titleModalChangePosition"
    *ngIf="isOpenModalChangePosition"
  >
    <meu-app-change-position-modal
      [zone_id]="currentZoneSelected.id"
      (closeModal)="closeModalChangeSlot($event)"
      [array_slot]="array_slot"
    ></meu-app-change-position-modal>
  </meu-modal-component>
</div>
