import { ToastrService } from 'src/app/core/services/toastr.service';
import { debounceTime, map } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { ExportService } from 'src/app/api/export.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'meu-import-export-mobile',
  templateUrl: './import-export-mobile.component.html',
  styleUrls: ['./import-export-mobile.component.scss'],
})
export class ImportExportMobileComponent implements OnInit {
  lstImExportCode = [];
  inventoryId: string;
  maxLoadMore = 0;
  pageIndex = 1;
  isDisable = false;
  isOpenBottomsheetImport = false;
  isOpenBottomsheetExport = false;
  titleModalImport = 'Nhập hàng';
  titleModalExport = 'Xuất hàng';
  isImport = false;
  filterImExport: string = ',isImExported==true';
  filterSearch = '';
  searchField = '';
  import_code = '';
  export_code = '';
  public keyUp = new Subject<KeyboardEvent>();

  optionsBottomImport: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenBottomsheetImport = false;
    },
  };
  optionsBottomExport: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenBottomsheetExport = false;
    },
  };

  @ViewChild(DaterangepickerDirective, { static: false })
  picker: DaterangepickerDirective;

  firstDay = moment().startOf('month').format();
  endDay = moment().endOf('month').format();

  selectedDate: any = {
    startDate: Date,
    endDate: Date,
  };

  isFilter = false;

  constructor(
    private importExportHistoryService: ImportExportHistoryService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.selectedDate.startDate = new Date(moment().startOf('month').format());
    this.selectedDate.endDate = new Date(moment().endOf('month').format());
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
      if (this.router.url.split('/')[2]) {
        this.inventoryId = this.router.url.split('/')[2];
      }
      this.getHashFragment();
      this.getAllHistory();
    });
    this.onSearch();
  }

  getAllHistory() {
    let start = this.selectedDate.startDate;
    let end = this.selectedDate.endDate;
    this.importExportHistoryService
      .apiImportExportHistoryFastdataGet(
        `inventory_id==` +
          this.inventoryId +
          this.filterImExport +
          this.filterSearch,
        '',
        this.pageIndex,
        5,
        start,
        end
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstImExportCode = res.data.collection;
          if (res.data.total > 5) {
            let div = res.data.total / 5;
            let odd = res.data.total % 5;
            if (odd > 0) {
              div++;
            }
            this.maxLoadMore = Math.floor(div);
          } else {
            this.maxLoadMore = 0;
          }
        }
      });
  }

  loadingMore() {
    let start = this.selectedDate.startDate;
    let end = this.selectedDate.endDate;
    this.pageIndex++;
    if (this.pageIndex === this.maxLoadMore) {
      this.isDisable = true;
    }
    this.importExportHistoryService
      .apiImportExportHistoryFastdataGet(
        `inventory_id==` +
          this.inventoryId +
          this.filterImExport +
          this.filterSearch,
        '',
        this.pageIndex,
        5,
        start,
        end
      )
      .subscribe((res) => {
        if (res.success) {
          res.data.collection.forEach((element) => {
            this.lstImExportCode.push(element);
          });
        }
      });
  }

  openDetail(element) {
    this.activatedRoute.fragment.subscribe((res: string) => {
      if (res === '1') {
        this.import_code = element.imExportCode;
        this.isOpenBottomsheetImport = true;
      }
      if (res === '2') {
        this.export_code = element.imExportCode;
        this.isOpenBottomsheetExport = true;
      }
    });
  }

  openImExport() {
    if (this.isImport) {
      this.import_code = '';
      this.isOpenBottomsheetImport = true;
    } else {
      this.export_code = '';
      this.isOpenBottomsheetExport = true;
    }
  }

  onSearch() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(1000)
      )
      .subscribe((res) => {
        this.filterSearch = ',imExportCode@=*' + res;
        if (!res) {
          this.pageIndex = 1;
        }
        this.getAllHistory();
      });
  }
  clearSearch() {
    if (this.searchField) {
      this.searchField = '';
      this.filterSearch = '';
      this.getAllHistory();
    }
  }
  noChange() {
    return false;
  }

  filterDate() {
    this.getAllHistory();
  }

  openDatePicker() {
    this.picker.open();
  }

  getHashFragment() {
    this.activatedRoute.fragment.subscribe((res: string) => {
      if (res === '1') {
        this.isImport = true;
        this.filterImExport = ',isImExported==true';
      }
      if (res === '2') {
        this.isImport = false;
        this.filterImExport = ',isImExported==false';
      }
      if (!res) {
        this.filterImExport = ',isImExported==true';
        this.isImport = true;
      }
    });
  }
  closeModalImport(event) {
    if (event) {
      this.getAllHistory();
    }
    this.isOpenBottomsheetImport = false;
  }
  closeModalExport(event) {
    if (event) {
      this.getAllHistory();
    }
    this.isOpenBottomsheetExport = false;
  }
  downloadFile() {
    var blobExportReport: Blob;
    this.exportService
      .apiExportImportExportCheckingImportCodePost('PX22-07/1223')
      .subscribe((res) => {
        if (res.type === 'application/json') {
          res.text().then((text) => {
            let resToJson = JSON.parse(text.toString());
            this.toastrService.error(resToJson.message);
          });
        } else {
          blobExportReport = new Blob([res], {
            type: res.type,
          });
          saveAs(blobExportReport, 'BáoCáoPhiếuXuất.xlsx');
        }
      });
  }
}
