import { Component, Input, OnInit } from '@angular/core';
import {
  ExportService,
  ImportExportHistoryService,
  SkuService,
  SlotService,
  StockAvailableService,
  SupplierService,
} from 'src/app';
import { saveAs } from 'file-saver';
import { ToastrService } from 'src/app/core/services';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { BehaviorSubject, of } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'meu-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit {
  @Input() iexportCode: string;
  @Input() isBillOrCommand: boolean; // true : phieu , false: lenh
  @Input() isPrintDivideQuantity: boolean = false;
  @Input() arrayImport: any[] = [];
  @Input() isTransferCar: boolean;

  isCheckContainer: boolean;

  //Import Print
  subjectImport = new BehaviorSubject<any>([]);
  subjectSlot = new BehaviorSubject<any>([]);
  stringSlot: string = '';
  arrayPrint: any[] = [];
  arrInventory: any[] = [];

  //Export  Print
  arrExport: any;
  arrProducts: any = [];

  extend_name_customer: string;
  date_time_export: any;
  note: string;
  extend_name_inventory: string;
  extend_name_supplier: string;
  container: string;
  dateNow = new Date();
  totalQuantity: number = 0;
  totalquantity: number = 0;
  totalNet: number = 0;
  totalNET_QUAN: number = 0;
  totalnet_quan: number = 0;
  totalSlot: number = 0;
  countInventory: number = 0;
  create_by: string;
  id_customer: string;
  receiver: string;
  address: string;
  receive_car: string;
  seller_staff: string;

  titleBillOrCommand1: string = '';
  titleBillOrCommand2: string = '';
  titlePrint: string = '';
  titleDownload: string = '';
  constructor(
    private importExportHistoryService: ImportExportHistoryService,
    private slotService: SlotService,
    private skuService: SkuService,
    private exportBill: ExportService,
    private toastrService: ToastrService,
    private localStoreService: LocalStoreService,
    private supplierService: SupplierService,
    private stockAvailableService: StockAvailableService
  ) {}

  ngOnInit(): void {
    this.optionPrint();
  }

  setUp() {
    if (this.isTransferCar) {
      if (this.iexportCode.includes('PSN') || this.iexportCode.includes('PN')) {
        if (this.isBillOrCommand) {
          this.titleBillOrCommand1 = 'Phiếu Khai Báo';
          this.titlePrint = 'In phiếu khai báo';
          this.titleDownload = 'Tải phiếu khai báo';
          // this.titleBillOrCommand2 = 'RECEIPT VOUCHER';
        } else {
          this.titleBillOrCommand1 = 'Lệnh Khai Báo';
          this.titlePrint = 'In lệnh khai báo';
          this.titleDownload = 'Tải lệnh khai báo';
          // this.titleBillOrCommand2 = 'RECEIPT NOTE';
        }
      }
      if (this.iexportCode.includes('PSX') || this.iexportCode.includes('PX')) {
        if (this.isBillOrCommand) {
          this.titleBillOrCommand1 = 'Phiếu Sang Xe';
          this.titlePrint = 'In phiếu sang xe';
          this.titleDownload = 'Tải phiếu sang xe';
          // this.titleBillOrCommand2 = 'OUT STORE';
        } else {
          this.titleBillOrCommand1 = 'Lệnh Sang Xe';
          this.titlePrint = 'In lệnh sang xe';
          this.titleDownload = 'Tải lệnh sang xe';
          // this.titleBillOrCommand2 = 'OUT STORE NOTE';
        }
      }
    } else {
      if (this.iexportCode.includes('PSN') || this.iexportCode.includes('PN')) {
        if (this.isBillOrCommand) {
          this.titleBillOrCommand1 = 'Phiếu Nhập Kho';
          this.titlePrint = 'In phiếu nhập kho';
          this.titleDownload = 'Tải phiếu nhập kho';
          this.titleBillOrCommand2 = 'RECEIPT VOUCHER';
        } else {
          this.titleBillOrCommand1 = 'Lệnh Nhập Kho';
          this.titlePrint = 'In lệnh nhập kho';
          this.titleDownload = 'Tải lệnh nhập kho';
          this.titleBillOrCommand2 = 'RECEIPT NOTE';
        }
      }
      if (this.iexportCode.includes('PX') || this.iexportCode.includes('PSX')) {
        if (this.isBillOrCommand) {
          this.titleBillOrCommand1 = 'Phiếu Xuất Kho';
          this.titlePrint = 'In phiếu xuất kho';
          this.titleDownload = 'Tải phiếu xuất kho';
          this.titleBillOrCommand2 = 'OUT STORE';
        } else {
          this.titleBillOrCommand1 = 'Lệnh Xuất Kho';
          this.titlePrint = 'In lệnh xuất kho';
          this.titleDownload = 'Tải lệnh xuất kho';
          this.titleBillOrCommand2 = 'OUT STORE NOTE';
        }
      }
    }
    if (this.iexportCode.includes('PX') || this.iexportCode.includes('PSX')) {
      this.isCheckContainer = false;
    } else {
      this.isCheckContainer = true;
    }
  }

  //Option
  optionPrint() {
    if (!this.isPrintDivideQuantity) {
      if (this.iexportCode) {
        this.setUp();
        this.getDetailExportCode();
      }
    } else {
      if (this.arrayImport) {
        this.subjectImport.next([this.arrayImport[0]]);
        this.subjectImport.subscribe((res) => {
          this.arrayPrint = res;
          var array = [];
          this.arrayPrint.forEach((e) => {
            e.selectHashtag.forEach((j) => {
              array.push(' ' + `${j.code}`);
            });
          });
          this.stringSlot = array.toString();
        });
      }
    }
  }

  //  get detail

  getDetailExportCode() {
    if (this.iexportCode.includes('PX') || this.iexportCode.includes('PSX')) {
      this.importExportHistoryService
        .apiImportExportHistoryCodeGet(this.iexportCode)
        .subscribe(async (res) => {
          if (this.isBillOrCommand) {
            this.arrExport = res.data.collection;
            let lstSlot = await this.getSlotInfo();
            let lstStock = await this.getStockInfo();
            this.arrExport = res.data.collection;
            this.extend_name_supplier = this.arrExport[0].supplier_name;
            this.getAdress(this.arrExport[0].supplier_id);
            this.arrExport.forEach((item) => {
              this.date_time_export = item.issue_date;

              this.note = item.note;
              this.receiver = item.receiver;
              this.container = item.container_code;
              this.create_by = item.extend_creator_name;
              this.id_customer = item.customer_id;
              this.receive_car = item.receive_car;
              this.seller_staff = item.extend_seller_name;
              this.extend_name_supplier = item.supplier_name;
            });

            let groups = this.arrExport.reduce((groups, item) => {
              const group = groups[item.group_number] || [];
              group.push(item);
              groups[item.group_number] = group;
              return groups;
            }, {});

            let obj: any;
            Object.values(groups).forEach((element) => {
              obj = element[0];
              obj.selectProductObj = {};
              obj.selectSkuObj = {};
              obj.selectProductObj.id = obj.product_id;
              obj.selectProductObj.name = obj.product_name;
              obj.selectProductObj.code = obj.product_code;
              obj.selectSkuObj.id = obj.sku_id;
              obj.selectSkuObj.sku_code = obj.sku_code;
              obj.selectHashtag = [];
              obj.selectProduct = obj.product_id;
              obj.selectSku = obj.sku_id;
              let arr = element as any[];
              let total = 0;
              let data: any;
              let count = 0;
              arr.forEach((item) => {
                data = lstSlot.find((c) => c.id == item.slot_id);
                total += item.quantity;
                count++;
                obj.selectHashtag.push({
                  id: item.slot_id,
                  row_id: item.id,
                  code: item.extend_slot_code,
                  quantity: item.quantity,
                  used_percent: data?.used_percent,
                  zone_id: data?.zone_id,
                  new_value: null,
                });
              });
              obj.quantity = total;
              obj.countIventory = count;
              let dataStock: any;
              dataStock = lstStock.find((x) => x.id == obj.sku_id);
              obj.stock = dataStock.stock;
              this.arrProducts.push(obj);
            });
            this.arrProducts = [...this.arrProducts].reverse();
            this.arrProducts.forEach((element) => {
              this.totalQuantity = this.totalQuantity + element.quantity;
              this.totalNet =
                this.totalNet + element.net_weigth * element.quantity;
              this.getStock(
                element,
                element.inventory_id,
                element.sku_id,
                element.product_id,
                element.contract_id
              );
            });
          } else {
            // let count = 0;
            let arr = [];
            this.arrProducts = res.data.collection;
            this.extend_name_supplier = this.arrProducts[0].supplier_name;
            this.getAdress(this.arrProducts[0].supplier_id);
            this.arrProducts.forEach((item) => {
              this.date_time_export = item.issue_date;
              this.extend_name_customer = item.extend_customer_name;

              this.note = item.note;
              this.container = item.container_code;
              this.create_by = item.extend_creator_name;
              this.id_customer = item.customer_id;
              this.receiver = item.receiver;
              this.receive_car = item.receive_car;
              this.extend_name_inventory = item.extend_inventory_name;
              this.seller_staff = item.extend_seller_name;
              this.totalnet_quan =
                this.totalnet_quan + item.net_weigth * item.quantity;
              this.totalquantity = this.totalquantity + item.quantity;

              this.stockCustomerAndContract(item.customer_id, item.contract_id);
              this.arrInventory.push(item.extend_inventory_name);
            });
            for (let i = 0; i < this.arrInventory.length; i++) {
              for (let j = i + 1; j < this.arrInventory.length; j++) {
                if (
                  this.arrInventory[i] !== this.arrInventory[j] &&
                  this.arrInventory[j] != ''
                ) {
                  this.countInventory++;
                }
              }
            }
          }
        });
    } else {
      this.importExportHistoryService
        .apiImportExportHistoryCodeGet(this.iexportCode)
        .subscribe(async (res) => {
          if (this.isBillOrCommand) {
            let arr = [];
            arr = res.data.collection;
            let lstSlot = [];
            for (let i = 0; i < arr.length; i++) {
              let e = arr[i];
              let data = await this.slotService
                .apiSlotIdInfoGet(e.slot_id)
                .toPromise();
              lstSlot.push({ ...data.data });
            }
            let lstStock = [];
            for (let i = 0; i < arr.length; i++) {
              let e = arr[i];
              let data = await this.skuService
                .apiSkuIdGet(e.sku_id)
                .toPromise();
              lstStock.push({ ...data.data });
            }

            this.extend_name_supplier = arr[0].supplier_name;
            this.getAdress(arr[0].supplier_id);
            arr.forEach((item) => {
              this.date_time_export = item.receipt_date;
              this.extend_name_customer = item.extend_customer_name;

              this.note = item.note;
              this.container = item.container_code;
              this.create_by = item.extend_creator_name;
              this.id_customer = item.customer_id;
              this.receiver = item.receiver;
              this.receive_car = item.receive_car;
              this.seller_staff = item.extend_seller_name;
            });

            let groups = arr.reduce((groups, item) => {
              const group = groups[item.group_number] || [];
              group.push(item);
              groups[item.group_number] = group;
              return groups;
            }, {});

            let obj: any;
            Object.values(groups).forEach((element) => {
              obj = element[0];
              obj.selectProductObj = {};
              obj.selectSkuObj = {};
              obj.selectProductObj.id = obj.product_id;
              obj.selectProductObj.name = obj.product_name;
              obj.selectProductObj.code = obj.product_code;
              obj.selectSkuObj.id = obj.sku_id;
              obj.selectSkuObj.sku_code = obj.sku_code;
              obj.selectHashtag = [];
              obj.selectProduct = obj.product_id;
              obj.selectSku = obj.sku_id;
              let arr = element as any[];
              let total = 0;
              let data: any;
              arr.forEach((item) => {
                data = lstSlot.find((c) => c.id == item.slot_id);
                total += item.quantity;
                obj.selectHashtag.push({
                  id: item.slot_id,
                  row_id: item.id,
                  code: item.extend_slot_code,
                  quantity: item.quantity,
                  used_percent: data?.used_percent,
                  zone_id: data?.zone_id,
                  new_value: null,
                });
              });
              obj.quantity = total;

              let dataStock: any;
              dataStock = lstStock.find((x) => x.id == obj.sku_id);
              obj.stock = dataStock.stock;
              this.arrProducts.push(obj);
            });
            this.arrProducts = [...this.arrProducts].reverse();
            this.arrProducts.forEach((element) => {
              this.totalQuantity = this.totalQuantity + element.quantity;
              this.totalNet =
                this.totalNet + element.net_weigth * element.quantity;
              // await this.getStock(
              //     element.inventory_id,
              //     element.sku_id,
              //     element.product_id,
              //     element.contract_id
              //   ),
              this.getStock(
                element,
                element.inventory_id,
                element.sku_id,
                element.product_id,
                element.contract_id
              );
            });
          } else {
            this.arrProducts = res.data.collection;
            this.extend_name_supplier = this.arrProducts[0].supplier_name;
            this.getAdress(this.arrProducts[0].supplier_id);
            this.arrProducts.forEach((item) => {
              this.date_time_export = item.receipt_date;
              this.extend_name_customer = item.extend_customer_name;

              this.note = item.note;
              this.container = item.container_code;
              this.create_by = item.extend_creator_name;
              this.id_customer = item.customer_id;
              this.receiver = item.receiver;
              this.receive_car = item.receive_car;
              this.seller_staff = item.extend_seller_name;

              this.totalNET_QUAN =
                this.totalNET_QUAN + item.net_weigth * item.quantity;
              this.totalQuantity = this.totalQuantity + item.quantity;
            });
            this.totalSlot = this.arrProducts.length;
          }
        });
    }
  }

  stockCustomerAndContract(customer_id, contract_id) {
    this.stockAvailableService
      .apiStockAvailableCustomerIdStocksGet(
        customer_id,
        '',
        '',
        '',
        '',
        1,
        1000
      )
      .subscribe((res) => {
        if (res.success) {
          let arrayStockSlot = [];
          arrayStockSlot = res.data.collection;
          this.arrProducts.forEach((element) => {
            arrayStockSlot.forEach((item) => {
              if (item.product_id == element.product_id) {
                item.slot.forEach((el) => {
                  if (el.slot_id == element.slot_id) {
                    Object.assign(element, { slot_quantity: el.slot_quantity });
                  }
                });
              }
            });
          });
        }
      });
  }

  async getSlotInfo() {
    let res = [];
    for (let i = 0; i < this.arrExport.length; i++) {
      let e = this.arrExport[i];
      let data = await this.slotService.apiSlotIdInfoGet(e.slot_id).toPromise();
      res.push({ ...data.data });
    }
    return res;
  }

  async getStockInfo() {
    let res = [];
    for (let i = 0; i < this.arrExport.length; i++) {
      let e = this.arrExport[i];
      let data = await this.skuService.apiSkuIdGet(e.sku_id).toPromise();
      res.push({ ...data.data });
    }
    return res;
  }

  saveIExportBillorCommand() {
    if (this.id_customer && this.iexportCode) {
      if (this.isBillOrCommand) {
        var blobExportReport: Blob;
        this.exportBill
          .apiExportImExportBillCustomerIdPost(
            this.id_customer,
            this.iexportCode
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });

              saveAs(blobExportReport, `${'Phiếu'}-${this.iexportCode}.xlsx`);
            }
          });
      } else {
        var blobExportReport: Blob;
        this.exportBill
          .apiExportImExportCommandCustomerIdPost(
            this.id_customer,
            this.iexportCode
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastrService.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });

              saveAs(blobExportReport, `${'Lệnh'}-${this.iexportCode}.xlsx`);
            }
          });
      }
    }
  }

  getStock(element, inventoryID, skuID, productID, contractID) {
    this.skuService
      .apiSkuStockAvailableGet(inventoryID, skuID, productID, contractID)
      .subscribe((res) => {
        if (res.success) {
          Object.assign(element, { stock_SKU: res.data.stock });
        }
      });
  }

  //Print Import
  getValueArray(data) {
    this.subjectImport.next([data]);
    this.subjectSlot.next([this.arrayImport[0].selectHashtag]);
  }

  getAdress(id) {
    this.supplierService.apiSupplierIdGet(id).subscribe((res) => {
      if (res.success) {
        this.address = res.data.address;
      }
    });
  }
}
