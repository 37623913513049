<div class="bg-gradient text-center font-roboto-regular px-6 md:p-0">
  <div class="flex justify-center mb-10 px-20px md:px-0">
    <img src="assets/images/error-404.svg" />
  </div>
  <div class="text-20px md:text-32px font-medium text-white mb-6">
    TRANG TÌM KIẾM KHÔNG TỒN TẠI !
  </div>
  <div class="text-14px md:text-16px font-medium text-white mb-4">
    Xin lỗi bạn vì sự bất tiện này, chúng tôi hiện không tìm thấy trang bạn yêu
    cầu.
  </div>
  <button id="back-btn" class="btn btn-primary-sm mr-16px" (click)="return()">
    QUAY VỀ TRANG CHỦ
  </button>
  <button id="logout-btn" class="btn btn-primary-sm" (click)="logOut()">
    ĐĂNG XUẤT
  </button>
</div>
