<div class="sm:grid sm:grid-cols-12 px-16px h-auto">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="createNewServiceForm">
      <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-5">
        <!-- Khach hàng -->
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                createNewServiceForm.get('id_customer').touched &&
                createNewServiceForm.controls.id_customer.errors?.required
                  ? 'error-class'
                  : ''
              "
            >
              Tên khách hàng <span class="text-red-light">*</span></span
            >

            <ng-select
              [ngClass]="
                createNewServiceForm.get('id_customer').touched &&
                createNewServiceForm.controls.id_customer.errors?.required
                  ? 'select-invalid'
                  : 'select-name'
              "
              (change)="onChangeUser($event)"
              required
              class="mt-8px focus:outline-none"
              placeholder="Chọn khách hàng"
              bindLabel="full_name"
              bindValue="id"
              [items]="allUser"
              formControlName="id_customer"
              notFoundText="Không tìm thấy khách hàng"
              #toolTipUser="tooltip"
              [tooltip]="tooltipUser ? tooltipUser : ''"
              [options]="{
                display: tooltipUser ? true : false
              }"
            >
              <ng-template ng-option-tmp let-item="item">
                <div
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="item.full_name"
                >
                  {{ item.full_name }}
                </div>
              </ng-template>
            </ng-select>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                createNewServiceForm.get('id_customer').touched &&
                createNewServiceForm.controls.id_customer.errors?.required
              "
            >
              Vui lòng chọn khách hàng
            </span>
          </div>
        </div>
        <!-- hop dong -->
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                createNewServiceForm.get('id_contract_user').touched &&
                createNewServiceForm.controls.id_contract_user.errors
                  ?.required &&
                !isCheckUser &&
                allContractUser &&
                allContractUser.length > 0
                  ? 'error-class'
                  : ''
              "
            >
              Mã hợp đồng <span class="text-red-light">*</span></span
            >

            <ng-select
              [ngClass]="
                createNewServiceForm.get('id_contract_user').touched &&
                createNewServiceForm.controls.id_contract_user.errors
                  ?.required &&
                !isCheckUser &&
                allContractUser &&
                allContractUser.length > 0
                  ? 'select-invalid'
                  : 'select-name'
              "
              (change)="onChangeContract($event)"
              class="mt-8px focus:outline-none"
              [readonly]="isCheckUser"
              [placeholder]="
                isCheckUser ? 'Vui lòng chọn mã hợp đồng' : 'Chọn mã hợp đồng'
              "
              bindLabel="code"
              bindValue="id"
              [items]="allContractUser"
              formControlName="id_contract_user"
              notFoundText="Không tìm thấy mã hợp đồng"
              #toolTipContract="tooltip"
              [tooltip]="tooltipContract ? tooltipContract : ''"
              [options]="{
                display: tooltipContract ? true : false
              }"
            >
              <ng-template ng-option-tmp let-item="item">
                <div
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="item.code"
                >
                  {{ item.code }}
                </div>
              </ng-template>
            </ng-select>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                createNewServiceForm.get('id_contract_user').touched &&
                createNewServiceForm.controls.id_contract_user.errors
                  ?.required &&
                !isCheckUser &&
                allContractUser &&
                allContractUser.length > 0
              "
            >
              Vui lòng chọn hợp đồng
            </span>
          </div>
        </div>
        <!-- dich vu -->
        <div class="sm:col-span-12">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                createNewServiceForm.get('id_service_contract').touched &&
                createNewServiceForm.controls.id_service_contract.errors
                  ?.required &&
                !isCheckContract &&
                allServiceContract &&
                allServiceContract.length > 0
                  ? 'error-class'
                  : ''
              "
            >
              Tên dịch vụ <span class="text-red-light">*</span></span
            >

            <ng-select
              [ngClass]="
                createNewServiceForm.get('id_service_contract').touched &&
                createNewServiceForm.controls.id_service_contract.errors
                  ?.required &&
                !isCheckContract &&
                allServiceContract &&
                allServiceContract.length > 0
                  ? 'select-invalid'
                  : 'select-name'
              "
              [readonly]="isCheckContract"
              (change)="onChangeService($event)"
              required
              class="mt-8px focus:outline-none"
              [placeholder]="
                isCheckContract ? 'Vui lòng chọn dịch vụ ' : 'Chọn dịch vụ'
              "
              bindLabel="extend_service_name"
              bindValue="service_cost_id"
              [items]="allServiceContract"
              formControlName="id_service_contract"
              notFoundText="Không tìm thấy loại dịch vụ"
              #toolTipService="tooltip"
              [tooltip]="tooltipService ? tooltipService : ''"
              [options]="{
                display: tooltipService ? true : false
              }"
            >
              <ng-template ng-option-tmp let-item="item">
                <div
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="item.extend_service_name"
                >
                  {{ item.extend_service_name }}
                </div>
              </ng-template>
            </ng-select>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                createNewServiceForm.get('id_service_contract').touched &&
                createNewServiceForm.controls.id_service_contract.errors
                  ?.required &&
                !isCheckContract &&
                allServiceContract &&
                allServiceContract.length > 0
              "
            >
              Vui lòng chọn loại dịch vụ
            </span>
          </div>
        </div>

        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Đơn giá
            </span>

            <input
              type="text"
              formControlName="amount"
              [value]="createNewServiceForm.controls.amount.value | meucurrency"
              readonly
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>

        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Đơn vị
            </span>

            <input
              readonly
              type="text"
              formControlName="unit"
              spellcheck="false"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>

        <div class="sm:col-span-12">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (createNewServiceForm.get('quantity').touched &&
                  (createNewServiceForm.controls.quantity.errors?.required ||
                    createNewServiceForm.controls.quantity.errors?.pattern)) ||
                createNewServiceForm.controls.quantity.errors?.pattern
                  ? 'error-class'
                  : ''
              "
            >
              Số lượng <span class="text-red-light">*</span>
            </span>

            <input
              [ngClass]="
                (createNewServiceForm.get('quantity').touched &&
                  (createNewServiceForm.controls.quantity.errors?.required ||
                    createNewServiceForm.controls.quantity.errors?.pattern)) ||
                createNewServiceForm.controls.quantity.errors?.pattern
                  ? 'class-invalid'
                  : 'class-valid'
              "
              type="number"
              placeholder="Nhập số lượng đã sử dụng, vd: 50"
              formControlName="quantity"
              required
              class="w-full border rounded-4px py-8px pl-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic leading-normal tracking-normal"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (createNewServiceForm.get('quantity').touched &&
                  (createNewServiceForm.controls.quantity.errors?.required ||
                    createNewServiceForm.controls.quantity.errors?.pattern)) ||
                createNewServiceForm.controls.quantity.errors?.pattern
              "
              >{{
                createNewServiceForm.controls.quantity.errors?.required
                  ? 'Vui lòng nhập số lượng'
                  : 'Vui lòng nhập đúng ký tự số'
              }}
            </span>
          </div>
        </div>

        <div class="col-span-full flex-row-reverse gap-8px flex h-60px">
          <button
            class="btn-primary-add"
            type="button"
            [disabled]="!createNewServiceForm.valid"
            (click)="submit()"
          >
            {{ nameButton }}
          </button>
          <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
        </div>
      </div>
    </form>
  </div>
</div>
