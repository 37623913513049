<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center title"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Nhà cung cấp
      </div>

      <div class="flex flex-wrap md:float-right items-center gap-8px margin-t">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchSupplier"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
        <button
          class="btn-primary-icon flex items-center gap-4px"
          (click)="open(null)"
        >
          <img src="assets/icons/handle-icons/icon_add.svg" />
          <span>Thêm mới</span>
        </button>
      </div>
    </div>

    <div class="overflow-x-auto w-full">
      <table
        class="w-full border-separate table-fixed"
        cellspacing="0"
        cellpadding="0"
        *ngIf="totalArray > 0; else noInfo"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden"
          >
            <th class="md:text-center w-40px">#</th>
            <th
              class="text-left col-code cursor-pointer"
              (click)="Sort('code')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Mã NCC'"
                  >Mã NCC</span
                >

                <img
                  *ngIf="sort == 'code' || sort == '-code'; else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left items-center col-name cursor-pointer"
              (click)="Sort('name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Tên nhà cung cấp'"
                >
                  Tên nhà cung cấp
                </span>

                <img
                  *ngIf="sort.includes('name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-phone cursor-pointer"
              (click)="Sort('phone')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Điện thoại'"
                  >Điện thoại</span
                >

                <img
                  *ngIf="sort.includes('phone'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-email cursor-pointer"
              (click)="Sort('email')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Email'"
                  >Email</span
                >

                <img
                  *ngIf="sort.includes('email'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img
                    class="float-right"
                    src="assets/icons/sort/sort_normal.svg"
                  />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-tax cursor-pointer"
              (click)="Sort('tax_code')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Mã số thuế'"
                  >Mã số thuế</span
                >

                <img
                  *ngIf="sort.includes('tax_code'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th class="text-left col-address">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="'Địa chỉ'"
                >Địa chỉ</span
              >
            </th>
            <th class="text-left col-note">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="'Ghi chú'"
                >Ghi chú</span
              >
            </th>
            <th class="text-center col-thaotac">
              <span class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                >Thao tác</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let supplier of suppliers; let i = index"
            class="font-roboto-regular text-14px text-dark-grey"
          >
            <td
              data-label="#"
              class="md:text-center w-40px td-stt verflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="indexArray + i + 1"
            >
              {{ indexArray + i + 1 }}
            </td>
            <td
              data-label="Mã NCC"
              class="td-code text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.code ? supplier.code : '--'"
            >
              {{ supplier.code ? supplier.code : '--' }}
            </td>
            <td
              data-label="Tên nhà cung cấp"
              class="td-name text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.name ? supplier.name : '--'"
            >
              {{ supplier.name ? supplier.name : '--' }}
            </td>
            <td
              data-label="Điện thoại"
              class="td-phone text-left text-14px col-phone overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.phone ? supplier.phone : '--'"
            >
              {{ supplier.phone ? supplier.phone : '--' }}
            </td>
            <td
              data-label="Email"
              class="td-email text-left text-14px col-email overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.email ? supplier.email : '--'"
            >
              {{ supplier.email ? supplier.email : '--' }}
            </td>
            <td
              data-label="Mã số thuế"
              class="td-tax-code text-left text-14px col-tax overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.tax_code ? supplier.tax_code : '--'"
            >
              {{ supplier.tax_code ? supplier.tax_code : '--' }}
            </td>
            <td
              data-label="Địa chỉ"
              class="td-address text-left text-14px col-address overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.address ? supplier.address : '--'"
            >
              {{ supplier.address ? supplier.address : '--' }}
            </td>
            <td
              data-label="Ghi chú"
              class="td-note text-left text-14px col-note overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="supplier.notes ? supplier.notes : '--'"
            >
              {{ supplier.notes ? supplier.notes : '--' }}
            </td>
            <td data-label="Thao tác" class="text-center td-thaotac py-0">
              <div class="flex justify-end md:justify-center">
                <button
                  (click)="open(supplier.id)"
                  class="hover:bg-primary-lightest focus:outline-none p-10px rounded-full button-add-edit"
                  tooltip="Chỉnh sửa"
                >
                  <img
                    src="assets/icons/handle-icons/u_edit-alt.svg"
                    class="w-20px h-20px"
                  />
                </button>
                <button
                  tooltip="Xóa"
                  class="hover:bg-primary-lightest rounded-full focus:outline-none button-add-edit p-10px"
                  (click)="deleteconfirm(supplier.id)"
                >
                  <img
                    src="assets/icons/handle-icons/u_trash-alt.svg"
                    class="w-20px h-20px"
                  />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noInfo>
        <table
          class="w-full border-separate table-fixed"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr
              class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden"
            >
              <th class="md:text-center w-40px">#</th>
              <th
                class="text-left col-code cursor-pointer"
                (click)="Sort('code')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Mã NCC'"
                    >Mã NCC</span
                  >

                  <img
                    *ngIf="sort == 'code' || sort == '-code'; else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left items-center col-name cursor-pointer"
                (click)="Sort('name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Tên nhà cung cấp'"
                  >
                    Tên nhà cung cấp
                  </span>

                  <img
                    *ngIf="sort.includes('name'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-phone cursor-pointer"
                (click)="Sort('phone')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Điện thoại'"
                    >Điện thoại</span
                  >

                  <img
                    *ngIf="sort.includes('phone'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-email cursor-pointer"
                (click)="Sort('email')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Email'"
                    >Email</span
                  >

                  <img
                    *ngIf="sort.includes('email'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img
                      class="float-right"
                      src="assets/icons/sort/sort_normal.svg"
                    />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-tax cursor-pointer"
                (click)="Sort('tax_code')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Mã số thuế'"
                    >Mã số thuế</span
                  >

                  <img
                    *ngIf="sort.includes('tax_code'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th class="text-left col-address">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Địa chỉ'"
                  >Địa chỉ</span
                >
              </th>
              <th class="text-left col-note">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Ghi chú'"
                  >Ghi chú</span
                >
              </th>
              <th class="text-center col-thaotac">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Thao tác</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="font-roboto-regular text-14px h-40px">
              <td colspan="9" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [total]="totalArray"
        [array]="array"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-modal-create-supplier
    [id_supplier]="idSupplier"
    (closeModalSupplier)="closeModal($event)"
  ></meu-modal-create-supplier>
</meu-modal-component>

<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-create-supplier
    [id_supplier]="idSupplier"
    (closeModalSupplier)="closeModalBottom($event)"
  ></meu-modal-create-supplier>
</meu-modal-popup-bottom>
