import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { filter, take } from 'rxjs/operators';
import { UserService } from 'src/app';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { User } from 'src/app/model/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'meu-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss'],
})
export class CustomerInformationComponent implements OnInit {
  idCustomer: string = this.route.snapshot.paramMap.get('id');

  @Output() checkUpdate: EventEmitter<any> = new EventEmitter();

  customer: User = {};
  allRole = [];
  role: any = [];

  urlImage: string = null;
  change: boolean = true;
  urlDetailSSO: string = '';

  formcreate = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    date: new FormControl(''),
    gender: new FormControl(''),
    nameCompany: new FormControl(''),
    tax_code: new FormControl(''),
    type_customer: new FormControl(''),
    note: new FormControl(''),
  });
  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm',
    firstDayOfWeek: 'mo',
    opens: 'left',
    drops: 'down',
  };
  dateExpiryPickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY',
    firstDayOfWeek: 'mo',
    drops: 'up',
  };

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private customerService: UserService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.getDetailCustomer();
    this.getAllRole();
  }
  changePermission(event) {
    this.role = event;

    this.change = false;
  }
  //API
  getDetailCustomer() {
    this.customerService.apiUsersIdGet(this.idCustomer).subscribe((res) => {
      if (res.success == true) {
        this.customer = res.data;

        this.formcreate.patchValue({
          name: this.customer.full_name ? this.customer.full_name : '--',
          email: this.customer.email ? this.customer.email : '--',
          phone: this.customer['extend_user_phone']
            ? this.customer['extend_user_phone']
            : '--',
          date: this.customer['extend_user_birthday']
            ? this.customer['extend_user_birthday'].split(' ', 1)
            : '--',
          gender: this.customer['extend_user_gender'] == true ? 'Nam' : 'Nữ',
          nameCompany: this.customer['extend_user_company_name']
            ? this.customer['extend_user_company_name']
            : '--',
          tax_code: this.customer['extend_user_company_tax']
            ? this.customer['extend_user_company_tax']
            : '--',
          type_customer: this.customer.permissions,
        });

        this.urlImage = this.customer.avatar;
      } else {
        this.toastService.error('Lỗi không lấy được dữ liệu');
      }
    });
  }
  getAllRole() {
    this.customerService.apiUsersGetAllRolesGet().subscribe((res) => {
      if (res.success == true) {
        this.allRole = res.data;
      }
    });
  }
  resetDetail() {
    this.change = true;
    this.getDetailCustomer();
  }
  updateRole() {
    var array = [];

    this.role.forEach((e) => {
      array.push(`${e.name}`);
    });

    let title = 'Xác nhận lưu phân quyền';
    let content = 'Bạn có chắc chắn muốn thay đổi phân quyền này ?';
    this.confirmService.deleteConfirm(title, content, false);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.customerService
            .apiUsersIdRolePut(this.idCustomer, array)
            .subscribe((res) => {
              if (res.success == true) {
                this.getDetailCustomer();
                this.change = true;
                this.toastService.success(res.message);
              } else {
                this.toastService.error(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
  //Edit SSO
  editSSO(id_customer) {
    window.open(
      environment.ssoFeUrl +
        '/portal/hr/user-management/edit/' +
        id_customer +
        '/0000000000000000000#user-profile',
      '_blank'
    );
  }
}
