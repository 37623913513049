<!-- use form control -->

<!-- <ng-container *ngIf="isReadOnly; else notRead">
  <ng-select
    *ngIf="formControl; else noFormControl"
    [ngClass]="[
      border_input ? 'border-select' : '',
      height_auto ? 'height-auto' : 'min-height'
    ]"
    class="focus:outline-none custom-select-readonly"
    [placeholder]="placeholder"
    [bindValue]="bindValue"
    [notFoundText]="notFoundText"
    [items]="filteredInput"
    [formControl]="formControl"
    [readonly]="is_readonly"
    [isOpen]="false"
    [clearable]="false"
    [searchable]="false"
    [dropdownPosition]="dropdownPosition"
    [(ngModel)]="defaultID"
    (change)="onChangeItem($event)"
    (open)="open()"
    (scrollToEnd)="scrollToEnd()"
  >
    <ng-template ng-header-tmp>
      <span
        *ngIf="addNew"
        (click)="returnAdd.emit(true)"
        class="text-14px text-primary-dodger-blue ellipsis"
      >
        +
      </span>
      <input
        type="text"
        (keyup)="keyUp.next($event.target.value)"
        placeholder="Tìm kiếm..."
        class="input-search h-32px"
    /></ng-template>
    <ng-template ng-label-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ customColumn ? item[customColumn] + '|' : '' }}
        {{ item[columnName] }}
      </div></ng-template
    >
    <ng-template ng-option-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ customColumn ? item[customColumn] + '|' : '' }}
        {{ item[columnName] }}
      </div>
    </ng-template>
    <ng-template *ngIf="isScrollToEnd" ng-footer-tmp>
      <span
        class="text-14px text-dark-grey font-roboto-regular cursor-pointer"
        (click)="loadMore()"
      >
        Xem thêm</span
      >
    </ng-template>
  </ng-select>

  <ng-template #noFormControl>
    <ng-select
      [ngClass]="[
        border_input ? 'border-select' : '',
        height_auto ? 'height-auto' : 'min-height'
      ]"
      class="focus:outline-none custom-select-readonly"
      [placeholder]="placeholder"
      [bindValue]="bindValue"
      [notFoundText]="notFoundText"
      [items]="filteredInput"
      [searchable]="false"
      [isOpen]="false"
      [clearable]="false"
      [readonly]="is_readonly"
      [(ngModel)]="defaultID"
      [dropdownPosition]="dropdownPosition"
      (change)="onChangeItem($event)"
      (scrollToEnd)="scrollToEnd()"
    >
      <ng-template ng-header-tmp>
        <span
          *ngIf="addNew"
          (click)="returnAdd.emit(true)"
          class="text-14px text-primary-dodger-blue ellipsis"
        >
          +
        </span>
        <input
          type="text"
          (keyup)="keyUp.next($event.target.value)"
          placeholder="Tìm kiếm..."
          class="input-search"
      /></ng-template>
      <ng-template ng-label-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ customColumn ? item[customColumn] + '|' : '' }}
          {{ item[columnName] }}
        </div></ng-template
      >
      <ng-template ng-option-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ customColumn ? item[customColumn] + '|' : '' }}
          {{ item[columnName] }}
        </div>
      </ng-template>
      <ng-template *ngIf="isScrollToEnd" ng-footer-tmp>
        <span
          class="text-14px text-dark-grey font-roboto-regular cursor-pointer"
          (click)="loadMore()"
        >
          Xem thêm</span
        >
      </ng-template>
    </ng-select>
  </ng-template>
</ng-container>
<ng-template #notRead>
  <ng-select
    *ngIf="formControl; else noFormControl1"
    [ngClass]="[
      invalidState ? 'custom-select-invalid' : 'custom-select ',
      border_input ? 'border-select' : '',
      height_auto ? 'height-auto' : 'min-height'
    ]"
    class="focus:outline-none"
    [placeholder]="placeholder"
    [bindValue]="bindValue"
    [notFoundText]="notFoundText"
    [items]="filteredInput"
    [formControl]="formControl"
    [searchable]="false"
    [readonly]="is_readonly"
    [(ngModel)]="defaultID"
    [dropdownPosition]="dropdownPosition"
    (change)="onChangeItem($event)"
    (open)="open()"
    (scrollToEnd)="scrollToEnd()"
  >
    <ng-template ng-header-tmp>
      <span
        *ngIf="addNew"
        (click)="returnAdd.emit(true)"
        class="text-14px text-primary-dodger-blue ellipsis"
      >
        +
      </span>
      <input
        type="text"
        (keyup)="keyUp.next($event.target.value)"
        placeholder="Tìm kiếm..."
        class="input-search h-32px"
    /></ng-template>
    <ng-template ng-label-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ customColumn ? item[customColumn] + '|' : '' }}
        {{ item[columnName] }}
      </div></ng-template
    >
    <ng-template ng-option-tmp let-item="item">
      <div
        [title]="item[columnName]"
        class="overflow-hidden overflow-ellipsis whitespace-nowrap"
      >
        {{ customColumn ? item[customColumn] + '|' : '' }}
        {{ item[columnName] }}
      </div>
    </ng-template>
    <ng-template *ngIf="isScrollToEnd" ng-footer-tmp>
      <span
        class="text-14px text-dark-grey font-roboto-regular cursor-pointer"
        (click)="loadMore()"
      >
        Xem thêm</span
      >
    </ng-template>
  </ng-select>

  <ng-template #noFormControl1>
    <ng-select
      [ngClass]="[
        invalidState ? 'custom-select-invalid' : 'custom-select ',
        border_input ? 'border-select' : '',
        height_auto ? 'height-auto' : 'min-height'
      ]"
      class="focus:outline-none"
      [placeholder]="placeholder"
      [bindValue]="bindValue"
      [notFoundText]="notFoundText"
      [items]="filteredInput"
      [searchable]="false"
      [readonly]="is_readonly"
      [dropdownPosition]="dropdownPosition"
      [(ngModel)]="defaultID === '' || defaultID === null ? null : defaultID"
      (change)="onChangeItem($event)"
      (scrollToEnd)="scrollToEnd()"
    >
      <ng-template ng-header-tmp>
        <span
          *ngIf="addNew"
          (click)="returnAdd.emit(true)"
          class="text-14px text-primary-dodger-blue ellipsis"
        >
          +
        </span>
        <input
          type="text"
          (keyup)="keyUp.next($event.target.value)"
          placeholder="Tìm kiếm..."
          class="input-search"
      /></ng-template>
      <ng-template ng-label-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ customColumn ? item[customColumn] + '|' : '' }}
          {{ item[columnName] }}
        </div></ng-template
      >
      <ng-template ng-option-tmp let-item="item">
        <div
          [title]="item[columnName]"
          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {{ customColumn ? item[customColumn] + ' |' : '' }}
          {{ item[columnName] }}
        </div>
      </ng-template>
      <ng-template *ngIf="isScrollToEnd" ng-footer-tmp>
        <span
          class="text-14px text-dark-grey font-roboto-regular cursor-pointer"
          (click)="loadMore()"
        >
          Xem thêm</span
        >
      </ng-template>
    </ng-select>
  </ng-template>
</ng-template>
<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div> -->

<div class="relative w-full" clickOutside (clickOutside)="closeOutside()">
  <div
    [ngClass]="[
      is_border
        ? 'border border-solid border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue'
        : '',
      is_radius ? 'rounded-4px' : '',
      invalidState ? 'border-red-light' : '',
   
    ]"
    class="h-40px cursor-pointer relative items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full py-8px pl-12px pr-8px bg-white  overflow-hidden"
    (click)="open()"
  >
    <div class="grid grid-cols-12">
      <div
        [ngClass]="[
          is_readonly && float ? float : '',
          is_readonly ? 'col-span-12' : 'col-span-10 overflow-ellipsis overflow-hidden whitespace-nowrap'
        ]"
      >
        <span
          [ngClass]="[!defaultItem ? 'placeholder' : '']"
          class="overflow-ellipsis overflow-hidden whitespace-nowrap"
        >
          {{ defaultItem || placeholder }}
        </span>
      </div>
      <div *ngIf="!is_readonly" class="col-span-2">
        <div class="mt-2px relative right-5px flex justify-end items-center">
          <!-- <img
        *ngIf="defaultItem && isOpen"
        class="w-15px h-15px"
        alt="cross icon"
        src="assets/icons/toast/u_close.svg"
        (click)="clear()"
      /> -->
          <svg
            fill="#4d4d50"
            viewBox="0 0 20 20"
            [ngClass]="{
              'rotate-180': isOpen,
              'rotate-0': !isOpen
            }"
            class="inline w-4 h-4 transition-transform duration-200 transform"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <!-- default value and placeholder -->
  </div>
  <div
    class="absolute"
    [ngClass]="[
      !isOpen ? 'hidden' : '',
      styleCssPosition != '' ? styleCssPosition : ''
    ]"
    style="z-index: 9999"
  >
    <div
      [ngClass]="styleCssWidth != '' ? styleCssWidth : 'w-full'"
      class="pb-8px bg-white rounded-md box-shadow-custom float-right search-box custom-scroll max-h-248px"
    >
      <!-- search -->
      <div
        class="flex bg-white w-full h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
      >
        <img
          class="w-20px h-20px m-10px"
          src="../../../../assets/icons/handle-icons/Icons_search.svg"
        />
        <input
          class="w-full px-5px border-none focus:outline-none text-14px"
          placeholder="Nhập tìm kiếm..."
          (keyup)="keyUp.next($event.target.value)"
          placeholder="Tìm kiếm..."
        />
      </div>

      <!-- add more -->
      <a
        *ngIf="addNew"
        (click)="returnAdd.emit(true)"
        class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-full"
        href="javascript:void(0)"
        >
        <span class="text-14px ml-10px text-primary-dodger-blue ellipsis">
          + Thêm mới
        </span>
      </a>

      <!-- array -->
      <a
        *ngFor="let item of filteredInput"
        class="bg-white hover:bg-primary-lightest py-10px px-8px rounded inline-flex items-center focus:outline-none h-40px w-full"
        href="javascript:void(0)"
        (click)="chooseItem(item)"
      >
        <span class="text-14px ml-10px text-gray ellipsis">
          {{ customColumn ? item[customColumn] + '|' : '' }}
          {{ item[columnName] }}
        </span>
      </a>

      <!-- no found text -->
      <a
        *ngIf="filteredInput.length == 0"
        class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-full"
        href="javascript:void(0)"
      >
        <span class="text-14px ml-10px text-grey-med ellipsis">
          Không tìm thấy kết quả
        </span>
      </a>

      <!-- add more -->
      <!-- <a
        *ngIf="addNew"
        (click)="returnAdd.emit(true)"
        class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-full"
        href="javascript:void(0)"
      >
        <span class="text-14px ml-10px text-primary-dodger-blue ellipsis">
          + Thêm mới
        </span>
      </a> -->

      <!-- load more -->
      <div
        [ngClass]="pageSize < total ? 'block' : 'hidden'"
        (click)="loadMore()"
        class="text-14px w-full h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
      >
        Xem thêm
      </div>
    </div>
  </div>
</div>

<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div>
