import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplierService } from 'src/app/api/supplier.service';
import { ToastrService } from 'src/app/core/services';
import { Supplier } from 'src/app/model/supplier';

@Component({
  selector: 'meu-modal-create-supplier',
  templateUrl: './modal-create-supplier.component.html',
  styleUrls: ['./modal-create-supplier.component.scss'],
})
export class ModalCreateSupplierComponent implements OnInit {
  @Input() id_supplier: string;
  @Output() closeModalSupplier = new EventEmitter();

  formcreate = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    address: new FormControl('', [Validators.maxLength(200)]),
    phone: new FormControl('', [
      Validators.pattern('^[0-9]*'),
      Validators.minLength(10),
      Validators.maxLength(11),
    ]),
    tax_code: new FormControl('', [
      Validators.pattern('[0-9]*'),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    notes: new FormControl('', [Validators.maxLength(200)]),
    email: new FormControl('', [
      Validators.pattern(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      Validators.maxLength(150),
    ]),
  });
  supplier: Supplier = {};
  title: string;
  nameButton: string;
  codeChange = false;
  constructor(
    private supplierService: SupplierService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.setModalAddOrEdit();
  }

  setModalAddOrEdit() {
    if (this.id_supplier) {
      this.title = 'Chỉnh sửa đơn vị tính';
      this.nameButton = 'Lưu';
      this.getSupplierID();
    } else {
      this.title = 'Thêm mới nhà cung cấp';
      this.nameButton = 'Thêm';
    }
  }

  setButtonAddOrEdit() {
    if (this.id_supplier) {
      this.updateSupplier();
    } else {
      this.addSupplier();
    }
  }
  closeModal(data = null) {
    if (data) {
      this.closeModalSupplier.emit(data);
      return;
    }
    this.closeModalSupplier.emit();
  }

  addSupplier() {
    this.supplier.name = this.formcreate.controls['name'].value.trim();
    this.supplier.code = this.formcreate.controls['code'].value.trim();
    this.supplier.address = this.formcreate.controls['address'].value.trim();
    this.supplier.phone = this.formcreate.controls['phone'].value.trim();
    this.supplier.tax_code = this.formcreate.controls['tax_code'].value.trim();
    this.supplier.notes = this.formcreate.controls['notes'].value.trim();
    this.supplier.email = this.formcreate.controls['email'].value.trim();
    if (this.supplier.name == '') {
      this.formcreate.controls['name'].setValue(null);
    }
    if (this.supplier.code == '') {
      this.formcreate.controls['code'].setValue(null);
    }
    if (this.formcreate.invalid) {
    } else {
      this.supplier.code = this.changeCharater(
        this.formcreate.controls['code'].value.trim()
      );
      this.supplierService.apiSupplierPost(this.supplier).subscribe((res) => {
        if (res.success === true) {
          this.toastService.success(res.message);
          this.closeModal(res);
        } else {
          this.toastService.error(res.message);
          this.closeModal(res);
        }
      });
    }
  }
  getSupplierID() {
    this.supplierService
      .apiSupplierIdGet(this.id_supplier)
      .subscribe((data) => {
        if (data.success == true) {
          (this.supplier = data.data),
            this.formcreate.controls['name'].setValue(this.supplier.name);
          this.formcreate.controls['code'].setValue(this.supplier.code);
          this.formcreate.controls['address'].setValue(this.supplier.address);
          this.formcreate.controls['phone'].setValue(this.supplier.phone);
          this.formcreate.controls['tax_code'].setValue(this.supplier.tax_code);
          this.formcreate.controls['notes'].setValue(this.supplier.notes);
          this.formcreate.controls['email'].setValue(this.supplier.email);
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
  updateSupplier() {
    this.supplier.name = this.formcreate.controls['name'].value.trim();
    this.supplier.code = this.formcreate.controls['code'].value.trim();
    this.supplier.phone = this.formcreate.controls['phone'].value.trim();
    this.supplier.tax_code = this.formcreate.controls['tax_code'].value.trim();
    this.supplier.email = this.formcreate.controls['email'].value.trim();
    this.supplier.notes = this.formcreate.controls['notes'].value.trim();
    this.supplier.address = this.formcreate.controls['address'].value.trim();

    if (this.supplier.name == '') {
      this.formcreate.controls['name'].setValue(null);
    }
    if (this.supplier.code == '') {
      this.formcreate.controls['code'].setValue(null);
    }
    if (this.formcreate.invalid) {
    } else {
      this.supplier.code = this.changeCharater(
        this.formcreate.controls['code'].value.trim()
      );
      this.supplierService
        .apiSupplierIdPut(this.supplier.id, this.supplier)
        .subscribe((res) => {
          if (res.success == true) {
            this.toastService.success(res.message);
            this.closeModal(res);
          } else {
            this.toastService.error(res.message);
            this.closeModal(res);
          }
        });
    }
  }

  updateSupplerID(id, supplier) {}
  changeCharater(str) {
    if (str) {
      str = str.replace(/ /g, ' ').toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }
}
