import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { ToastrService, ConfirmService } from 'src/app/core/services';
import { ContractService } from 'src/app/api/contract.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'meu-contract-template-modal',
  templateUrl: './contract-template-modal.component.html',
  styleUrls: ['./contract-template-modal.component.scss']
})
export class ContractTemplateModalComponent implements OnInit {
  @Output() closeModalTemplate = new EventEmitter();
  @Input() type_id: string;
  @Input() type_name: string;

  arrTemplate = [];

  constructor(
    private confirmService: ConfirmService,
    private toastr: ToastrService,
    private contractService: ContractService
  ) { }

  ngOnInit(): void {
    if (this.type_id) {
      this.getTemplatesByContractTypeId();
    }
  }

  getTemplatesByContractTypeId() {
    this.contractService
      .apiContractTemplateGet('contract_type_id==' + this.type_id, '', 1, 1000)
      .subscribe((data) => {
        if (data.success) {
          this.arrTemplate = data.data.collection;
        } else {
          this.toastr.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  closeModal(event = false) {
    this.closeModalTemplate.emit({ success: event });
  }

  addRowTemplate() {
    let objTemplate = {};

    objTemplate = {
      name: '',
      file_name: '',
      isEdit: true,
      files: [],
      is_enabled: true
    }

    this.arrTemplate.push(objTemplate);
    this.arrTemplate = [...this.arrTemplate];
  }

  onSelect(event, item) {
    item.files = [];
    item.files.push(...event.addedFiles);
  }

  onRemove(event, item) {
    item.files.splice(item.files.indexOf(event), 1);
  }

  editRow(item) {
    item.isEdit = true;

    this.contractService
      .apiContractTemplateIdGet(item.id)
      .subscribe((data) => {
        if (data.success) {
          item.name = data.data.name;
          item.file_name = data.data.file_name;
          item.files = [];
          item.files.push({
            ...data.data,
            name: data.data.file_name,
            file_name: data.data.name
          });
        }
      });
  }

  cancelEdit(i, item) {
    item.isEdit = false;

    if (!item.id) {
      if (item.name == '' || item.files.length == 0) {
        this.arrTemplate.splice(i, 1);
        this.arrTemplate = [...this.arrTemplate];
      }
      if (item.name != '' && item.files.length > 0) {
        this.arrTemplate.splice(i, 1);
        this.arrTemplate = [...this.arrTemplate];
      }
    }
  }

  changeIsEnabled(item) {
    item.is_enabled = !item.is_enabled;
  }

  saveRow(index, item) {
    let obj = {
      name: item.name,
      is_enabled: item.is_enabled,
      contract_type_id: this.type_id
    }

    if (!item.name) {
      this.toastr.warnning('Vui lòng nhập mẫu hợp đồng');
      return;
    }

    if (item.name.length > 200) {
      this.toastr.warnning('Mẫu hợp đồng tối đa 200 kí tự');
      return;
    }

    if (item.files.length == 0) {
      this.toastr.warnning('Vui lòng tải tệp đính kèm');
      return;
    }

    if (item.id) {
      this.contractService.apiContractTemplateIdPut(item.id, obj).subscribe(res => {
        if (res.success) {
          if (!item.files[0].file_name) {
            this.contractService.apiContractTemplateIdUploadPut(res.data.id, item.files[0]).subscribe(result2 => {
              this.toastr.success(res.message);
              this.arrTemplate[index].isEdit = false;
              this.arrTemplate[index].name = result2.data.name;
              this.arrTemplate[index].is_enabled = result2.data.is_enabled;
              this.arrTemplate[index].file_name = result2.data.file_name;
              this.arrTemplate[index].files = [];
              this.arrTemplate[index].files.push({
                ...result2.data,
                name: result2.data.file_name,
                file_name: result2.data.name
              });
            })
          }
          else {
            this.arrTemplate[index].isEdit = false;
            this.toastr.success(res.message);
          }
        }
        else {
          this.toastr.error(res.message);
        }
      })
    }
    else {
      this.contractService.apiContractTemplatePost(obj).subscribe(res => {
        if (res.success) {
          if (item.files.length > 0) {
            this.contractService.apiContractTemplateIdUploadPut(res.data.id, item.files[0]).subscribe(result2 => {
              this.toastr.success(res.message);
              this.arrTemplate[index].isEdit = false;
              this.arrTemplate[index].id = result2.data.id;
              this.arrTemplate[index].name = result2.data.name;
              this.arrTemplate[index].is_enabled = result2.data.is_enabled;
              this.arrTemplate[index].file_name = result2.data.file_name;
              this.arrTemplate[index].files = [];
              this.arrTemplate[index].files.push({
                ...result2.data,
                name: result2.data.file_name,
                file_name: result2.data.name
              });
            })
          }
        }
        else {
          this.toastr.error(res.message);
        }
      })
    }
  }

  removeRow(item) {
    let title = 'Xóa mẫu hợp đồng';
    let content = 'Bạn có chắc chắn muốn xóa mẫu hợp đồng này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.contractService.apiContractTemplateIdDelete(item.id).subscribe((res) => {
            if (res.success) {
              //this.getTemplatesByContractTypeId();
              // if (this.arrTemplate.length == 1) {
              //   this.arrTemplate = [];
              // }
              var index = this.arrTemplate.indexOf(item);
              this.arrTemplate.splice(index, 1);
              this.toastr.success(res.message);
            } else {
              this.toastr.error(res.message);
            }
          });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  downloadTemplate(id, name) {
    this.contractService
      .apiContractTemplateIdDownloadGet(id)
      .subscribe(
        (res) => {
          saveAs(res, name);
        },
        (err) => {
          setTimeout(
            function () {
              this.toastr.error('Không thể tải xuống tệp')
            }.bind(this),
            500
          );
        }
      );
  }
}
