import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ConfirmService } from 'src/app/core/services/confirm.service';

@Component({
  selector: 'meu-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  title: string;
  content: string;
  confirmOption = false; //true : confirm, false: delete ;
  option: MxModalOption;
  @Input() contents: any;
  // @Output() Delete = new EventEmitter();
  constructor(private ConfirmService: ConfirmService) {}
  defaultMxModalOption = {
    styleClass: 'w-11/12 md:w-6/12 lg:w-5/12 xl:w-4/12',
    closeFromOutside: false,
    top: '50%',
    useDefaultPadding: true,
    hideCloseButton: false,
    callBackAfterClose: null,
    zIndex: '9998',
  };
  ngOnInit(): void {
    this.setDefaultValue();
  }
  setDefaultValue() {
    if (this.option) {
      const inputOption = this.option;
      this.option = {
        ...this.defaultMxModalOption,
        ...inputOption,
      };
    } else {
      this.option = this.defaultMxModalOption;
    }
  }

  Del(value) {
    if (value) {
      // this.Delete.emit(value)
      this.ConfirmService.open$.next({
        type: 'delete',
      });
    } else {
      this.ConfirmService.open$.next({
        type: 'close',
      });
    }
  }

  open(title, content, confirmOption?, styleOptions?) {
    this.title = title;
    this.content = content;
    this.confirmOption = confirmOption;
    this.option = styleOptions;
    // this.bgScrollDisable.disable(true);
  }

  close() {
    this.ConfirmService.open$.next(null);
    // this.isOpen = false;
    if (this.option.callBackAfterClose != null) {
      this.option.callBackAfterClose();
    }
    // this.bgScrollDisable.disable(false);
  }

  closeFromOutside() {
    if (this.option.closeFromOutside) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    // this.bgScrollDisable.disable(false);
  }
}
export interface MxModalOption {
  top?: string;
  closeFromOutside?: boolean;
  hideCloseButton?: boolean;
  callBackAfterClose?: any;
  zIndex?: string;
  styleClass?: string;
}
