import { ImportExportMobileComponent } from './view/import-export-mobile/import-export-mobile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './core/layout/main-layout/main-layout.component';
import { ExampleElementComponent } from './view/example-element/example-element.component';
import { AppProductCategoriesComponent } from './view/product-categories/app-product-categories/app-product-categories.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CanActivateRouteGuard } from './core/guards/permissions-route.guard';
import { ErrorComponent } from './core/layout/error/error.component';
import { HistoryListComponent } from './view/delete-history/history-list/history-list.component';
import { ContainerChargeServiceComponent } from './view/container-charge/container-charge-service/container-charge-service.component';
import { AppMenuMobileComponent } from './view/app-menu-mobile/app-menu-mobile.component';
import { DebtManagementComponent } from './view/debt-management/debt-management.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'inventory/:inventoryId/map',
        loadChildren: () =>
          import('./view/map/map.module').then((m) => m.MapModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'unit-management',
        loadChildren: () =>
          import('./view/unit/unit.module').then((m) => m.UnitModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'supplier-management',
        loadChildren: () =>
          import('./view/suppliers/suppliers.module').then(
            (m) => m.SuppliersModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory/:inventoryId/product-categories',
        component: AppProductCategoriesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory/:inventoryId/contract',
        loadChildren: () =>
          import('./view/contract-management/contract-management.module').then(
            (m) => m.ContractManagementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory/:inventoryId/product-management',
        loadChildren: () =>
          import('./view/product/product.module').then((m) => m.ProductModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory-management',
        loadChildren: () =>
          import('./view/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory/:inventoryId/dashboard',
        loadChildren: () =>
          import('./view/dashboard/dashboard.module').then(
            (m) => m.DashboardsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory/:inventoryId/import-export-management',
        loadChildren: () =>
          import(
            './view/import-export-history/import-export-history.module'
          ).then((m) => m.ImportExportHistoryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-management',
        loadChildren: () =>
          import('./view/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory/:inventoryId/dashboard-client',
        loadChildren: () =>
          import('./view/dashboard-client/dashboard-client.module').then(
            (m) => m.DashboardClientModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'container-management',
        loadChildren: () =>
          import('./view/sales-management/sales-management.module').then(
            (m) => m.SalesManagementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'service-cost-management',
        loadChildren: () =>
          import('./view/service-cost/service-cost.module').then(
            (m) => m.ServiceCostModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'service-management',
        loadChildren: () =>
          import('./view/services-list/service-list.module').then(
            (m) => m.ServiceListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'history-management',
        component: HistoryListComponent,
        loadChildren: () =>
          import('./view/delete-history/history.module').then(
            (m) => m.HistoryModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'container-charge-management',
        component: ContainerChargeServiceComponent,
        loadChildren: () =>
          import('./view/container-charge/container-charge.module').then(
            (m) => m.ContainerChargeModule
          ),
        canActivate: [AuthGuard],
      }, // { path: '', redirectTo: '', pathMatch: 'full' },
      {
        path: 'menu-mobile',
        component: AppMenuMobileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'debt-management',
        component: DebtManagementComponent,
      },
      {
        path: 'inventory/:inventoryId/import-export-mobile',
        component: ImportExportMobileComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'example',
    component: ExampleElementComponent,
  },
  {
    path: 'product-categories',
    component: AppProductCategoriesComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  // {
  //   path: '**',
  //   redirectTo: '/inventory/null/dashboard/dashboard-manage',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
