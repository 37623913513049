<div class="grid grid-cols-12 px-16px h-auto mt-6px">
  <form
    [formGroup]="containerChargeForm"
    class="col-span-12 grid grid-cols-12 gap-8px"
  >
    <div class="col-span-12">
      <div class="flex flex-col gap-4px">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            containerChargeForm.get('container_number').touched &&
            containerChargeForm.controls.container_number.errors?.required
              ? 'text-red-light'
              : ''
          "
        >
          Số Container <span class="text-red-light">*</span></span
        >
        <!-- <input
          [ngClass]="
            containerChargeForm.get('container_number').touched &&
            containerChargeForm.controls.container_number.errors?.required
              ? 'class-invalid'
              : 'class-valid'
          "
          type="text"
          placeholder="Nhập số container..."
          formControlName="container_number"
          spellcheck="false"
        /> -->
        <ng-select
          formControlName="container_id"
          [items]="lstContainer"
          bindLabel="number"
          bindValue="id"
          [virtualScroll]="true"
          placeholder="Chọn container..."
          [clearable]="false"
          (change)="onChangeContainer($event)"
        >
        </ng-select>
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            containerChargeForm.get('container_number').touched &&
            containerChargeForm.controls.container_number.errors?.required
          "
        >
          Vui lòng nhập số container
        </span>
      </div>
    </div>
    <div class="col-span-12 sm:col-span-6">
      <div class="flex flex-col gap-4px">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            containerChargeForm.get('start_date').touched &&
            containerChargeForm.controls.start_date.errors?.required
              ? 'text-red-light'
              : ''
          "
        >
          Ngày bắt đầu <span class="text-red-light">*</span></span
        >
        <input
          [ngClass]="
            containerChargeForm.get('start_date').touched &&
            containerChargeForm.controls.start_date.errors?.required
              ? 'class-invalid'
              : 'class-valid'
          "
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfig"
          [theme]="'dp-material'"
          formControlName="start_date"
          [mode]="'daytime'"
          (onChange)="getDateStartEnd($event)"
          placeholder="Nhập ngày bắt đầu..."
          class="cursor-pointer"
        />
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            containerChargeForm.get('start_date').touched &&
            containerChargeForm.controls.start_date.errors?.required
          "
        >
          Vui chọn ngày bắt đầu
        </span>
      </div>
    </div>
    <div class="col-span-12 sm:col-span-6">
      <div class="flex flex-col gap-4px">
        <span class="font-roboto-regular text-12px text-warm-grey">
          Ngày kết thúc
        </span>
        <input
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfig"
          [theme]="'dp-material'"
          formControlName="end_date"
          (onChange)="getDateStartEnd($event)"
          [mode]="'daytime'"
          placeholder="Nhập ngày kết thúc..."
          class="cursor-pointer class-valid"
        />
      </div>
    </div>
    <div class="col-span-12">
      <div class="flex flex-col gap-4px">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            containerChargeForm.controls.cost.errors?.pattern
              ? 'error-class'
              : ''
          "
        >
          Đơn giá/giờ (VNĐ)
        </span>
        <input
          [ngClass]="
            containerChargeForm.controls.cost.errors?.pattern
              ? 'class-invalid'
              : 'class-valid'
          "
          class=""
          type="text"
          placeholder="Nhập đơn giá..."
          [value]="containerChargeForm.get('cost').value | meucurrency"
          (keyup)="onChangeValueCurrency('cost', $event)"
          formControlName="cost"
          spellcheck="false"
        />
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            containerChargeForm.get('cost').touched &&
            containerChargeForm.controls.cost.errors?.pattern
          "
        >
          Vui lòng nhập số
        </span>
      </div>
    </div>

    <!-- status -->
    <div
      class="col-span-12 sm:col-span-8 text-14px font-roboto-medium text-dark-grey text-right mt-10px flex items-center justify-end"
    >
      Trạng thái
    </div>
    <div
      class="col-span-12 sm:col-span-4 text-14px font-roboto-regular text-dark-grey text-right mt-10px flex items-center justify-end"
    >
      <ng-select
        [items]="statusType"
        formControlName="status"
        [clearable]="false"
        [searchable]="false"
        bindLabel="name"
        bindValue="id"
        class="cursor-pointer custom-ng-select custom-height-40px w-130px"
        notFoundText="Không có dữ liệu"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap flex"
          >
            <img [src]="item.icon" alt="" class="mr-10px" />
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>
    </div>

    <!-- cost -->
    <div
      class="col-span-12 sm:col-span-8 text-14px font-roboto-medium text-dark-grey text-right mt-10px"
    >
      Tổng tiền (VNĐ)
    </div>
    <div
      class="col-span-12 sm:col-span-4 text-14px font-roboto-regular text-dark-grey text-right mt-10px"
    >
      {{ totalCost | meucurrency }}
    </div>

    <!-- button -->
  </form>
  <div
    class="col-span-12 flex-row-reverse gap-8px flex h-64px mt-52px pt-8px items-center"
  >
    <button
      type="button"
      [disabled]="!containerChargeForm.valid"
      (click)="submit()"
      class="btn-primary-add"
      type="button"
    >
      Lưu
    </button>

    <button
      class="btn-primary-print flex items-center"
      type="button"
      [useExistingCss]="true"
      printSectionId="billContainerCharge"
      *ngIf="id_conatainer_charge"
      ngxPrint
    >
      <img src="/assets/icons/handle-icons/u_save.svg" alt="" class="mr-1" />
      In
    </button>

    <button
      class="btn-text-cancel"
      type="button"
      (click)="emitContainerCharge.emit()"
    >
      Hủy
    </button>
  </div>
</div>

<!-- print UI -->
<div id="billContainerCharge" class="w-full hidden">
  <div class="flex w-full">
    <img
      src="./assets/icons/hoa-chu-onions.png"
      style="width: 100px; height: 100px"
    />
    <div class="flex flex-col justify-between gap-6px">
      <div class="w-full text-18px font-roboto-medium text-dark-grey">
        CÔNG TY TNHH SX TM DV XNK HÒA CHU
      </div>
      <div class="w-full pl-10px text-14px font-roboto-regular">
        <span
          >Địa chỉ: 68 Nguyễn Thị Thích, P.Bình Chiểu, Thành phố Thủ Đức, Thành
          phố Hồ Chí Minh</span
        >
      </div>
      <div class="w-full pl-10px text-14px font-roboto-regular">
        <span>TEL: 0888960033 - 0888980033 </span>
        <span class="ml-10px">MST: 0317012371</span>
      </div>

      <div class="w-full pl-10px text-14px font-roboto-regular">
        <span>Email: nongsan@hoachu.vn</span>
      </div>
    </div>
  </div>
  <hr class="mt-5px" />
  <div class="cont">
    <div class="grid grid-cols-12 mt-15px">
      <div
        class="col-span-6 text-center self-center text-24px font-roboto-medium text-dark-grey"
      >
        <!-- {{ titleBillOrCommand1 }}<br />
        {{ titleBillOrCommand2 }} -->
      </div>
      <div class="col-span-6 text-left self-center">
        <div class="col-span-12 text-24px font-roboto-medium text-dark-grey">
          <!-- *{{ iexportCode }}* -->
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-10px">
    <div class="col-span-12 text-center text-25px font-roboto-medium">
      Phí Sạc Container
    </div>
    <div class="col-span-4 text-left">Số Container:</div>
    <div class="col-span-8 text-left">
      {{ container_charge.container_number }}
    </div>
    <div class="col-span-4 text-left">Ngày bắt đầu:</div>
    <div class="col-span-8 text-left">
      {{ (container_charge.start_date | date: 'dd/MM/YYYY hh:mm:ss') || '--' }}
    </div>
    <div class="col-span-4 text-left">Ngày kết thúc:</div>
    <div class="col-span-8 text-left">
      {{ (container_charge.end_date | date: 'dd/MM/YYYY hh:mm:ss') || '--' }}
    </div>
    <div class="col-span-4 text-left">Đơn giá/giờ (VNĐ):</div>
    <div class="col-span-8 text-left">
      {{ (container_charge.cost | meucurrency) || '--' }}
    </div>
    <div class="col-span-4 text-left">Trạng thái:</div>
    <div class="col-span-8 text-left">
      {{ container_charge.is_draft ? 'Lưu nháp' : 'Đã thanh toán' }}
    </div>
    <div class="col-span-4 text-left">Tổng tiền (VNĐ):</div>
    <div class="col-span-8 text-left">
      {{ (totalCost | meucurrency) || '--' }}
    </div>
  </div>
  <div class="signature mt-120px">
    <div class="grid grid-cols-12">
      <div
        class="col-span-6 text-center font-roboto-regular text-12px flex flex-col justify-end"
      >
        <span> Người thanh toán</span>
        <span> Payer</span>
      </div>
      <div
        class="col-span-6 text-center font-roboto-regular text-12px flex flex-col justify-end"
      >
        <span> Người lập phiếu</span>
        <span> Prepared by</span>
      </div>

      <div
        class="col-span-6 text-center font-roboto-regular text-12px flex flex-col"
      ></div>
      <div
        class="col-span-6 text-center font-roboto-regular text-12px flex flex-col mt-90px"
      >
        {{ container_charge['extend_creator_name'] }}
      </div>
    </div>
  </div>
</div>
