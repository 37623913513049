import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import BackgroundScroll from 'src/app/shared/utils/background-scroll.util';
export interface MxBottomPopupOption {
  closeFromOutside?: boolean;
  hideCloseButton?: boolean;
  callBackAfterClose?: any;
  styleClass?: string;
}

const slideDownAnimation = [
  trigger('slideDownToggle', [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({ transform: 'translateY(100%)' })),
    ]),
  ]),
];

@Component({
  selector: 'meu-modal-popup-bottom',
  templateUrl: './modal-popup-bottom.component.html',
  styleUrls: ['./modal-popup-bottom.component.scss'],
  animations: [slideDownAnimation],
})
export class ModalPopupBottomComponent implements OnInit {
  @Input() title: string;
  @Input() isOpen = false;
  @Input('options') option;
  defaultOption: MxBottomPopupOption = {
    closeFromOutside: true,
    hideCloseButton: false,
    callBackAfterClose: null,
  };
  bgScrollDisable: BackgroundScroll = new BackgroundScroll();
  constructor() {}

  ngOnInit() {
    this.setDefaultValue();
    if (this.isOpen) {
      this.bgScrollDisable.disable(true);
    }
  }

  setDefaultValue() {
    if (this.option) {
      const inputOption = this.option;
      this.option = {
        ...this.defaultOption,
        ...inputOption,
      };
    } else {
      this.option = this.defaultOption;
    }
  }

  open() {
    this.isOpen = true;
    this.bgScrollDisable.disable(true);
  }

  close() {
    this.isOpen = false;

    if (this.option.callBackAfterClose != null) {
      this.option.callBackAfterClose();
    }
    this.bgScrollDisable.disable(false);
  }
  closeFromOutside() {
    if (this.option.closeFromOutside) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    this.bgScrollDisable.disable(false);
  }
}
