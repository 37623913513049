import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractManagementCreateComponent } from './contract-management-create/contract-management-create.component';
import { AppContractManagementComponent } from './app-contract-management/app-contract-management.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ContractDetailManageComponent } from './contract-detail/contract-detail-manage.component';
import { InformationContractComponent } from './contract-detail/information-contract/information-contract.component';
import { ImportExportContractComponent } from './contract-detail/import-export-contract/import-export-contract.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ContractManagementCreateModule } from './contract-management-create/contract-management-create.module';
import { ImportExportHistoryModule } from '../import-export-history/import-export-history.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';
import { ContractTypeManagementComponent } from './contract-type-management/contract-type-management.component';
import { ContractTypeModalComponent } from './contract-type-modal/contract-type-modal.component';
import { ContractTemplateModalComponent } from './contract-template-modal/contract-template-modal.component';
import { ServiceCostContractComponent } from './contract-detail/service-cost-contract/service-cost-contract.component';
import { ServiceCostContractModalComponent } from './contract-detail/service-cost-contract/service-cost-contract-modal/service-cost-contract-modal.component';

const routes: Routes = [
  {
    path: '',
    component: AppContractManagementComponent,
  },
  {
    path: 'type',
    component: ContractTypeManagementComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
  {
    path: 'contract-list',
    component: AppContractManagementComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
  {
    path: 'detail/:id',
    component: ContractDetailManageComponent,
    children: [
      {
        path: '',
        component: InformationContractComponent,
        canActivate: [CanActivateRouteGuard],
        data: { permissions: 'STAFF' },
      },
      {
        path: 'import-export',
        component: ImportExportContractComponent,
        canActivate: [CanActivateRouteGuard],
        data: { permissions: 'STAFF' },
      },
      {
        path: 'service-cost',
        component: ServiceCostContractComponent,
        canActivate: [CanActivateRouteGuard],
        data: { permissions: 'STAFF' },
      },
    ],
  },
];

@NgModule({
  declarations: [
    //ContractManagementCreateComponent,
    AppContractManagementComponent,
    ContractDetailManageComponent,
    InformationContractComponent,
    ImportExportContractComponent,
    ContractTypeManagementComponent,
    ContractTypeModalComponent,
    ContractTemplateModalComponent,
    ServiceCostContractComponent,
    ServiceCostContractModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    DpDatePickerModule,
    ContractManagementCreateModule,
    ImportExportHistoryModule,
    NgSelectModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
  exports: [ContractManagementCreateComponent],
})
export class ContractManagementModule {}
