<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center title"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Đơn vị tính
      </div>

      <div class="flex flex-wrap md:float-right items-center gap-8px margin-t">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchUnit"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
        <button
          class="btn-primary-icon flex items-center gap-4px"
          (click)="open(null)"
        >
          <img src="assets/icons/handle-icons/icon_add.svg" />
          <span>Thêm mới</span>
        </button>
      </div>
    </div>

    <div class="overflow-x-auto overflow-y-hidden w-full">
      <table
        class="w-full border-separate table-fixed"
        cellspacing="0"
        cellpadding="0"
        *ngIf="totalArray > 0; else noInfo"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="md:text-center hidden-col w-40px">#</th>
            <th
              class="text-left col-code-unit cursor-pointer"
              (click)="Sort('code')"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('code')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Mã đơn vị'"
                >
                  Mã Đơn Vị</span
                ><img
                  *ngIf="sort.includes('code'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-name-unit cursor-pointer"
              (click)="Sort('name')"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('name')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Tên đơn vị'"
                >
                  Tên Đơn Vị</span
                ><img
                  *ngIf="sort.includes('name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th class="text-left col-des-unit hidden-col">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="'Mô tả'"
              >
                Mô tả</span
              >
            </th>
            <th class="text-center hidden-col w-187px">Thao tác</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let unit of units; let i = index"
            class="font-roboto-regular text-14px text-dark-grey"
            style="min-height: 40px"
          >
            <td
              class="md:text-center td-stt overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer"
              data-label="#"
              [tooltip]="indexArray + i + 1"
            >
              {{ indexArray + i + 1 }}
            </td>
            <td
              data-label="Mã đơn vị"
              (click)="MobileSort('code')"
              class="text-left text-14px overflow-hidden whitespace-nowrap overflow-ellipsis td-code"
            >
              <span
                class="cursor-pointer"
                [tooltip]="unit.code ? unit.code : '--'"
              >
                {{ unit.code ? unit.code : '--' }}</span
              >
            </td>
            <td
              (click)="MobileSort('name')"
              data-label="Tên đơn vị"
              class="text-left text-14px overflow-hidden whitespace-nowrap overflow-ellipsis td-name"
            >
              <span
                class="cursor-pointer"
                [tooltip]="unit.name ? unit.name : '--'"
              >
                {{ unit.name ? unit.name : '--' }}</span
              >
            </td>
            <td
              data-label="Mô tả"
              class="text-left text-14px td-des overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              <span
                class="cursor-pointer"
                [tooltip]="unit.description ? unit.description : '--'"
              >
                {{ unit.description ? unit.description : '--' }}</span
              >
            </td>
            <td data-label="Thao tác" class="text-center td-thaotac">
              <div class="flex justify-end md:justify-center">
                <button
                  (click)="open(unit.id)"
                  class="hover:bg-primary-lightest focus:outline-none p-10px rounded-full button-add-edit"
                  tooltip="Chỉnh sửa"
                >
                  <img
                    src="assets/icons/handle-icons/u_edit-alt.svg "
                    class="w-20px h-20px"
                  />
                </button>
                <button
                  tooltip="Xóa"
                  class="hover:bg-primary-lightest rounded-full focus:outline-none p-10px button-add-edit"
                  (click)="deleteconfirm(unit.id)"
                >
                  <img
                    src="assets/icons/handle-icons/u_trash-alt.svg"
                    class="w-20px h-20px"
                  />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noInfo>
        <table
          class="w-full border-separate table-fixed"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr
              class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
            >
              <th class="md:text-center hidden-col w-40px">#</th>
              <th
                class="text-left col-code-unit cursor-pointer"
                (click)="Sort('code')"
              >
                <div
                  class="flex justify-between items-center"
                  (click)="Sort('code')"
                >
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Mã đơn vị'"
                  >
                    Mã Đơn Vị</span
                  ><img
                    *ngIf="sort.includes('code'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-name-unit cursor-pointer"
                (click)="Sort('name')"
              >
                <div
                  class="flex justify-between items-center"
                  (click)="Sort('name')"
                >
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Tên đơn vị'"
                  >
                    Tên Đơn Vị</span
                  ><img
                    *ngIf="sort.includes('name'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th class="text-left col-des-unit hidden-col">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Mô tả'"
                >
                  Mô tả</span
                >
              </th>
              <th class="text-center hidden-col w-187px">Thao tác</th>
            </tr>
          </thead>

          <tbody>
            <tr class="font-roboto-regular text-14px h-40px">
              <td colspan="5" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>

    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [array]="array"
        [total]="totalArray"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-modal-create-unit
    [id_unit]="idUnit"
    (closeModalUnit)="closeModal($event)"
  ></meu-modal-create-unit>
</meu-modal-component>

<!-- Modal bottom sheet -->
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-create-unit
    [id_unit]="idUnit"
    (closeModalUnit)="closeModalBottom($event)"
  ></meu-modal-create-unit>
</meu-modal-popup-bottom>
