import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { ContractService } from 'src/app/api/contract.service';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { Contract } from 'src/app/model/contract';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-customer-contract',
  templateUrl: './customer-contract.component.html',
  styleUrls: ['./customer-contract.component.scss'],
})
export class CustomerContractComponent implements OnInit {
  idCustomer: string = '';
  idIventory: string = '';

  searchContract: string = '';
  array: Contract[] = [];
  arrayLenght: number = this.array.length;

  indexArray: number = 0;

  contracts: Contract[] = [];
  show: string = '';
  totalArray: number;
  idContract: string;

  isOpenModal: boolean;
  isOpenBottomsheet: Boolean;

  options: MxModalOption = {};
  optionsBottom: MxBottomPopupOption = {};
  titleModal: string;

  subject: Subject<any> = new Subject();
  sortName: string = '';
  sort: string = '';

  constructor(
    private contractService: ContractService,
    private ActivatedRoute: ActivatedRoute,
    private toastService: ToastrService,
    private confirmService: ConfirmService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.idCustomer = this.router.url.split('/')[3];
    this.idIventory = this.router.url.split('/')[2];
    this.getContract();
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getContract();
    });
    this.callBackAfterClose();
  }

  //API
  getContract() {
    this.contractService
      .apiContractGet(
        `customer_id==${this.idCustomer},code|extend_contract_type@=${this.searchContract}`
      )
      .subscribe((res) => {
        if (res.success == true) {
          this.array = res.data.collection;
          this.totalArray = res.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.contractService
      .apiContractGet(
        `customer_id==${this.idCustomer},code|extend_contract_type@=${this.searchContract}`,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.contracts = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
  deleteconfirm(id) {
    let title = 'Xóa hợp đồng';
    let content = 'Bạn có chắc chắn muốn xóa hợp đồng này?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.contractService.apiContractIdDelete(id).subscribe((res) => {
            if (res.success == true) {
              this.toastService.success(res.message);
              this.getContract();
            } else {
              this.toastService.error(res.message);
            }
          });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  //Modal

  open(id) {
    if (id) {
      this.idContract = id;
    }
    this.titleModal = 'Thêm mới hợp đồng';

    if (!this.isOpenModal) {
      this.titleModal = 'Thêm mới hợp đồng';
      this.isOpenModal = true;
    }
  }
  closeModal(data) {
    if (!data) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModal = false;
        this.idContract = '';
        this.getContract();
      } else {
        // this.isOpenModal = true;
      }
    }
  }

  callBackAfterClose() {
    this.options.callBackAfterClose = (e) => {
      this.idContract = '';
      this.isOpenModal = false;
    };
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getContract();
  }
  // MobileSort(data) {
  //   if (window.screen.width < 481) {
  //     this.Sort(data);
  //   }
  // }

  //Search
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}
}
