import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Supplier, Inventory, Product, Sku } from 'src/app/model/models';
import {
  SupplierService,
  InventoryService,
  SkuService,
  ProductService,
} from 'src/app/api/api';
import { ToastrService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'meu-modal-create-sku',
  templateUrl: './modal-create-sku.component.html',
  styleUrls: ['./modal-create-sku.component.scss'],
})
export class ModalCreateSkuComponent implements OnInit {
  @Input() id_sku: string;
  @Input() id_product: string;
  @Input() code_sku: string;
  @Input() id_supplier: string;
  @Output() closeModalSku = new EventEmitter();

  public supplierChoose: Supplier;
  public inventoryChoose: Inventory;

  sku: Sku = {};

  //isDate: boolean = false;

  change1: boolean = true;
  change2: boolean = true;

  //tooltip
  subjectInven$ = new Subject();
  tooltipInvent: string;
  subjectSupp$ = new Subject();
  tooltipSupp: string;
  @ViewChild('toolTipInvent', { static: false }) toolTipInvent;
  @ViewChild('toolTipSupp', { static: false }) toolTipSupp;

  mess = '';
  //Name
  nameButton: string;

  //Load more
  pageNumber_inventory: number = 1000;
  pageNumber_supplier: number = 1000;

  // Form
  formcreate = new FormGroup({
    codeSKU: new FormControl(1, [
      Validators.required,
      Validators.maxLength(30),
    ]),
    nameProduct: new FormControl(''),
    stock: new FormControl(0, [Validators.pattern('[0-9]*')]),
    supplierName: new FormControl(null, Validators.required),
    inventoryName: new FormControl(null, Validators.required),
    isDate: new FormControl(false),
  });

  constructor(
    private supplierService: SupplierService,
    private inventoryService: InventoryService,
    private skuService: SkuService,
    private productSerivce: ProductService,
    private toastService: ToastrService,
    private localStoreService: LocalStoreService
  ) {}

  ngOnInit(): void {
    if (!this.id_sku) {
      if (
        this.localStoreService.getFromToLocalStorage(
          this.localStoreService.localStorageItem.inventoryId
        )
      ) {
        this.formcreate.controls['inventoryName'].setValue(
          this.localStoreService.getFromToLocalStorage(
            this.localStoreService.localStorageItem.inventoryId
          )
        );
        this.inventoryService
          .apiInventoryIdGet(
            this.localStoreService.getFromToLocalStorage(
              this.localStoreService.localStorageItem.inventoryId
            )
          )
          .subscribe((res) => {
            if (res.success == true) {
              this.tooltipInvent = res
                ? '[' + res.data.code + ']' + ' ' + res.data.name
                : null;
            }
          });
      }
    }

    this.setModalAddOrEdit();
    this.getSupplierForChoose();
    this.getInventoryForChoose();

    this.productSerivce.apiProductIdGet(this.id_product).subscribe((res) => {
      if (res.success == true) {
        this.formcreate.controls['nameProduct'].setValue(res.data.name);
      }
    });

    if (this.code_sku) {
      this.formcreate.controls['codeSKU'].setValue(this.code_sku);
    }

    if (this.id_supplier) {
      this.formcreate.controls['supplierName'].setValue(this.id_supplier);
    }

    // this.formcreate.controls['supplierName'].value;
    // this.formcreate.controls['nameInventory'].value;
  }
  setModalAddOrEdit() {
    if (this.id_sku) {
      this.nameButton = 'Lưu';

      this.getdetialSKU();
    } else {
      this.nameButton = 'Thêm';
    }
  }
  setButtonAddOrEdit() {
    if (this.id_sku) {
      this.updateSKU();
    } else {
      this.addSKU();
    }
  }

  closeModal(res = null) {
    if (res) {
      this.closeModalSku.emit(res);

      return;
    }
    this.closeModalSku.emit();
  }
  //Get detail and update
  getdetialSKU() {
    this.skuService.apiSkuIdGet(this.id_sku).subscribe((data) => {
      if (data.success == true) {
        this.sku = data.data;
        this.formcreate.patchValue({
          codeSKU: this.sku.net_weight,
          stock: this.sku.stock,
          supplierName:
            this.sku.supplier_id !== null ? this.sku.supplier_id : null,
          inventoryName:
            this.sku.inventory_id !== null ? this.sku.inventory_id : null,
        });

        this.tooltipSupp = this.sku.supplier_id
          ? this.sku['extend_supplier_name']
          : null;

        this.tooltipInvent = this.sku.inventory_id
          ? '[' +
            this.sku['extend_inventory_code'] +
            ']' +
            ' ' +
            this.sku['extend_inventory_name']
          : null;
      } else {
        this.toastService.error('Lỗi không lấy được dữ liệu');
      }
    });
  }
  updateSKU() {
    this.sku.net_weight = this.formcreate.controls['codeSKU'].value;
    this.sku.stock = this.convertStringStockToNumber(
      this.formcreate.controls['stock'].value
    );
    this.sku.product_id = this.id_product;
    this.sku.supplier_id = this.formcreate.controls['supplierName'].value;
    this.sku.inventory_id = this.formcreate.controls['inventoryName'].value;

    if (this.sku.net_weight == 0) {
      this.formcreate.controls['codeSKU'].setValue(0);
    }
    if (this.formcreate.invalid) {
    } else {
      this.sku.net_weight = this.sku.net_weight = this.formcreate.controls[
        'codeSKU'
      ].value;
      this.skuService.apiSkuIdPut(this.id_sku, this.sku).subscribe((res) => {
        if (res.success == true) {
          this.toastService.success(res.message);

          this.closeModal(res);
        } else {
          this.toastService.error(res.message);
          this.closeModal(res);
        }
      });
    }
  }

  //Add and

  addSKU() {
    let isDate = this.formcreate.controls['isDate'].value;
    this.sku.supplier_id = this.formcreate.controls['supplierName'].value;

    this.sku.inventory_id = this.formcreate.controls['inventoryName'].value;

    this.sku.net_weight = this.formcreate.controls['codeSKU'].value;

    this.sku.product_id = this.id_product;
    this.sku.stock = this.convertStringStockToNumber(
      this.formcreate.controls['stock'].value
    );
    if (this.sku.net_weight == 0) {
      this.formcreate.controls['codeSKU'].setValue(0);
    }
    if (this.formcreate.invalid) {
    } else {
      this.sku.net_weight = this.formcreate.controls['codeSKU'].value;

      if (!this.sku.inventory_id || !this.sku.supplier_id) {
        if (!this.sku.supplier_id) {
          this.toastService.warnning('Vui lòng chọn nhà cung cấp');
        }
        if (!this.sku.inventory_id) {
          this.toastService.warnning('Vui lòng chọn kho hàng');
        }
      } else {
        this.skuService.apiSkuPost(this.sku, isDate).subscribe((res) => {
          if (res.success == true) {
            this.toastService.success(res.message);
            this.closeModal(res);
          } else {
            this.toastService.error(res.message);
            this.closeModal(res);
          }
        });
      }
    }
  }

  //Get option for select

  getSupplierForChoose() {
    this.supplierService
      .apiSupplierGet('', '', 1, this.pageNumber_supplier)
      .subscribe((res) => {
        if (res.success == true) {
          this.supplierChoose = res.data.collection;
        }
      });
  }
  getInventoryForChoose() {
    this.inventoryService
      .apiInventoryGet('', '', 1, this.pageNumber_inventory)
      .subscribe((res) => {
        if (res.success == true) {
          this.inventoryChoose = res.data.collection;
        }
      });
  }

  //tooltip
  getInven(data) {
    this.toolTipInvent.hide();
    this.tooltipInvent = data ? '[' + data.code + ']' + ' ' + data.name : null;
  }
  getSupp(data) {
    this.toolTipSupp.hide();
    this.tooltipSupp = data ? data.name : null;
  }

  onChange(val: string): void {
    if (val != null) {
      //val = this.changeCharater(val);
      //this.formcreate.controls['codeSKU'].setValue(val);
    }
  }

  changeCharater(str) {
    if (str) {
      str = str.replace(/ /g, ' ').toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }

  convertStringStockToNumber(value) {
    var x = value;
    var y: number = +x;
    return y;
  }

  changeInput(data) {
    if (data) {
      data = data.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      this.formcreate.controls['stock'].setValue(data);
    }
  }
}
