<div class="m-16px gap-16px mt-4px bg-white">
  <div class="debt-infomation-edit">
    <!-- <div class="pb-12px h-40px flex justify-between items-center">
        <span class="text-16px font-roboto-medium">Thông tin công nợ </span>
      </div> -->
    <form>
      <div class="w-full grid grid-cols-12 md:grid-cols-12 xl:gir gap-5">
        <div class="col-span-12 flex gap-16px">
          <div class="col-span-6">
            <span
              class="font-roboto-regular text-12px text-warm-grey overflow-hidden overflow-ellipsis whitespace-nowrap py-11px"
            >
              Công nợ tối đa (VNĐ)</span
            >
            <input 
              type="text"
              class="class-valid text-right w-full"
              [(ngModel)]="element.maxDebt"
              [value]="element.maxDebt | meucurrency"
              [ngModelOptions]="{standalone: true}"
              />
          </div>
          <div class="col-span-6">
            <span
              class="font-roboto-regular text-12px text-warm-grey overflow-hidden overflow-ellipsis whitespace-nowrap py-11px"
            >
              Hạn thanh toán</span
            >
            <input 
              type="text"
              class="class-valid w-full"
              ngxDaterangepickerMd
              [closeOnAutoApply]="true"
              [autoApply]="true"
              [locale]="{format: 'DD/MM/YYYY'}"
              [opens]="'left'"
              placeholder="Chọn ngày"
              [singleDatePicker]="true"
              (change)="changeDate($event)"
              />
          </div>
        </div>
        <div class="col-span-12 flex flex-col">
          <span
            class="font-roboto-regular text-12px text-warm-grey overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            Ghi chú</span
          >
          <textarea
            style="height: 100px"
            type="text"
            class="class-valid"
            row="3"
            placeholder="Không có ghi chú"
            [(ngModel)]="element.note"
            [ngModelOptions]="{standalone: true}"
          ></textarea>
        </div>
      </div>
    </form>
    <div class="footer col-span-full text-right px-16px pb-16px pt-8px">
      <button (click)="closeModal(null)" class="btn-text-cancel">
        
        Hủy
      </button>
      <button class="btn-primary-add" (click)="submit()">Lưu</button>
    </div>
  </div>
</div>
