import { ContainerService } from './../../../api/container.service';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { ImportExportHistory } from 'src/app/model/importExportHistory';
import { ToastrService } from 'src/app/core/services';
import { debounceTime, map } from 'rxjs/operators';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { PermissionService } from 'src/app/core/guards/permission.service';

@Component({
  selector: 'meu-import-export-management',
  templateUrl: './import-export-management.component.html',
  styleUrls: ['./import-export-management.component.scss'],
})
export class ImportExportManagementComponent implements OnInit {
  inventoryId: string;
  searchImportExport: any = '';
  filter = '';
  sort: string = '';
  sortName: string = '';
  pageIndex = 1;
  pageSize = 10;

  array: ImportExportHistory[] = [];
  arrayLenght: number = this.array.length;

  indexArray: number = 0;
  allImportExport: ImportExportHistory[] = [];
  totalArray: number;

  public keyUp = new Subject<KeyboardEvent>();

  //Import
  titleModal: string;
  isOpenModal: boolean;
  options: MxModalOption = {};
  imExport_code: string;
  is_unload: boolean = false;

  //Export
  titleModalExport: string;
  isOpenModalExport: boolean;
  optionsExport: MxModalOption = {};
  im_Export_code: string;

  selectedStatus: string = '';
  allStatus: any[] = [
    { value: '', name: 'Tất cả trạng thái' },
    { value: 'draft', name: 'Lưu nháp' },
    { value: 'import', name: 'Đã nhập' },
    { value: 'export', name: 'Đã xuất' },
  ];

  selectedCheck: string = '';
  allChecks: any[] = [
    { value: '', name: 'Trạng thái kiểm kho' },
    { value: 'check', name: 'Đã kiểm kho' },
    { value: 'uncheck', name: 'Chưa kiểm kho' },
  ];

  customer_id;

  lstPermission: any = [];

  count_car_done = 0;
  isOpenModalTransfer = false;
  titleModalTransfer = 'Xe đã chốt';
  optionsTransfer: MxModalOption = {
    callBackAfterClose: () => {
      this.isOpenModalTransfer = false;
    },
  };
  saleItem = '';

  constructor(
    private importExportHistoryService: ImportExportHistoryService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private localStoreService: LocalStoreService,
    private permissionService: PermissionService,
    private router: Router,
    private containerService: ContainerService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
      if (this.router.url.split('/')[2]) {
        this.inventoryId = this.router.url.split('/')[2];
      }
      this.getAllImportExport();
      this.onSearch();
      this.optionCallbackModal();
      this.optionCallbackModalExport();
      this.getCreator();
      this.getPermission();
      this.getContainerDone();
    });
  }

  getContainerDone() {
    this.containerService
      .apiContainerGet('is_locked==false')
      .subscribe((res) => {
        if (res.success) {
          this.count_car_done = res.data.total;
        }
      });
  }

  getCreator() {
    var user = JSON.parse(
      this.localStoreService.getFromToLocalStorage('userInfo')
    );
    this.customer_id = user.id;
  }

  getPermission() {
    this.permissionService
      .getMyPermissions()
      .toPromise()
      .then((res) => {
        this.lstPermission = res;
      });
  }

  onSearch() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.searchImportExport = value;
        this.getAllImportExport();
      });
  }

  onChangeStatus(event) {
    this.getAllImportExport();
  }

  onChangeCheck(event) {
    this.getAllImportExport();
  }

  get onPagingFunc() {
    return this.onKeyDown.bind(this);
  }
  // get changePageFunc() {
  //   return this.onKeyDown.bind(this);
  // }
  onKeyDown(): void {}
  // onPaging(array, currentPage, pageSize) {
  //   const a = array;
  //   this.indexArray = (currentPage - 1) * pageSize;

  //   this.filter = '';
  //   let check = false;
  //   if (this.searchImportExport) {
  //     this.filter =
  //       'imExportCode|extend_customer_name|extend_implementer_name|container_code@=*' +
  //       this.searchImportExport;
  //     check = true;
  //   }

  //   if (this.selectedStatus == 'draft') {
  //     this.filter = check
  //       ? this.filter + ',isImExported ==,is_draft==true'
  //       : this.filter + 'isImExported ==,is_draft==true';
  //     check = true;
  //   }

  //   if (this.selectedStatus == 'import') {
  //     this.filter = check
  //       ? this.filter + ',isImExported == true,is_draft==false'
  //       : this.filter + 'isImExported == true,is_draft==false';
  //     check = true;
  //   }

  //   if (this.selectedStatus == 'export') {
  //     this.filter = check
  //       ? this.filter + ',isImExported == false,is_draft==false'
  //       : this.filter + 'isImExported == false,is_draft==false';
  //     check = true;
  //   }

  //   if (this.selectedCheck == 'check') {
  //     this.filter = check
  //       ? this.filter + ',is_checked==true'
  //       : this.filter + 'is_checked==true';
  //     check = true;
  //   }

  //   if (this.selectedCheck == 'uncheck') {
  //     this.filter = check
  //       ? this.filter + ',is_checked==false'
  //       : this.filter + 'is_checked==false';
  //     check = true;
  //   }

  //   if (
  //     this.lstPermission.includes('CUSTOMER') &&
  //     this.lstPermission.length == 1
  //   ) {
  //     this.importExportHistoryService
  //       .apiImportExportHistoryFastdataGet(
  //         //apiImportExportHistoryGet
  //         this.filter +
  //           ',inventory_id==' +
  //           this.inventoryId +
  //           ',customer_id==' +
  //           this.customer_id,
  //         this.sort,
  //         currentPage,
  //         pageSize
  //       )
  //       .subscribe((data) => {
  //         if (data.success == true) {
  //           this.allImportExport = data.data.collection;
  //           this.totalArray = data.data.total;
  //         } else {
  //           this.toastService.error('Lỗi không lấy được dữ liệu');
  //         }
  //       });
  //   } else {
  //     this.importExportHistoryService
  //       .apiImportExportHistoryFastdataGet(
  //         this.filter + ',inventory_id==' + this.inventoryId,
  //         this.sort,
  //         currentPage,
  //         pageSize
  //       )
  //       .subscribe((data) => {
  //         if (data.success == true) {
  //           this.allImportExport = data.data.collection;
  //           this.totalArray = data.data.total;
  //         } else {
  //           this.toastService.error('Lỗi không lấy được dữ liệu');
  //         }
  //       });
  //   }
  // }

  getAllImportExport() {
    this.filter = '';
    let check = false;
    if (this.searchImportExport) {
      this.filter =
        'imExportCode|extend_customer_name|extend_implementer_name|container_code@=*' +
        this.searchImportExport;
      check = true;
    }

    if (this.selectedStatus == 'draft') {
      this.filter = check
        ? this.filter + ',isImExported ==,is_draft==true'
        : this.filter + 'isImExported ==,is_draft==true';
      check = true;
    }

    if (this.selectedStatus == 'import') {
      this.filter = check
        ? this.filter + ',isImExported == true,is_draft==false'
        : this.filter + 'isImExported == true,is_draft==false';
      check = true;
    }

    if (this.selectedStatus == 'export') {
      this.filter = check
        ? this.filter + ',isImExported == false,is_draft==false'
        : this.filter + 'isImExported == false,is_draft==false';
      check = true;
    }

    if (this.selectedCheck == 'check') {
      this.filter = check
        ? this.filter + ',is_checked==true'
        : this.filter + 'is_checked==true';
      check = true;
    }

    if (this.selectedCheck == 'uncheck') {
      this.filter = check
        ? this.filter + ',is_checked==false'
        : this.filter + 'is_checked==false';
      check = true;
    }

    if (
      this.lstPermission.includes('CUSTOMER') &&
      this.lstPermission.length == 1
    ) {
      this.importExportHistoryService
        .apiImportExportHistoryFastdataGet(
          this.filter +
            ',inventory_id==' +
            this.inventoryId +
            ',customer_id==' +
            this.customer_id,
          this.sort,
          this.pageIndex,
          this.pageSize
        )
        .subscribe((data) => {
          if (data.success == true) {
            this.allImportExport = data.data.collection;
            this.totalArray = data.data.total;
          } else {
            this.toastService.error('Lỗi không lấy được dữ liệu');
          }
        });
    } else {
      this.importExportHistoryService
        .apiImportExportHistoryFastdataGet(
          this.filter + ',inventory_id==' + this.inventoryId,
          this.sort,
          this.pageIndex,
          this.pageSize
        )
        .subscribe((data) => {
          if (data.success == true) {
            this.allImportExport = data.data.collection;
            this.totalArray = data.data.total;
          } else {
            this.toastService.error('Lỗi không lấy được dữ liệu');
          }
        });
    }
  }
  onChangeValue(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllImportExport();
  }

  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getAllImportExport();
  }

  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModal = false;
      this.is_unload = false;
    };
  }

  optionCallbackModalExport() {
    this.optionsExport.callBackAfterClose = (e) => {
      this.im_Export_code = '';
      this.isOpenModalExport = false;
    };
  }

  open(code) {
    if (code) {
      //Import
      if (code.includes('PN') || code.includes('PSN')) {
        this.imExport_code = code;
        this.titleModal = 'Chi tiết phiếu nhập hàng';

        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }

      //Export
      if (code.includes('PX') || code.includes('PSX')) {
        this.im_Export_code = code;
        this.titleModalExport = 'Chi tiết phiếu xuất hàng';

        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
    }
  }

  openModalTransferCar() {
    this.isOpenModalTransfer = true;
  }

  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      this.imExport_code = '';
    }
    if (event.success) {
      this.getAllImportExport();
    }
    if (!event.success && this.saleItem) {
      this.isOpenModalTransfer = true;
    }
  }

  closeModalExport(event) {
    if (this.isOpenModalExport) {
      this.isOpenModalExport = false;
      this.im_Export_code = '';
    }
    if (event.success) {
      this.getAllImportExport();
    }
  }
  closeModalTransfer(event) {
    this.saleItem = event;
    this.isOpenModalTransfer = false;
    this.isOpenModal = true;
    this.is_unload = true;
    this.options.styleClass = 'w-11/12';
  }
}
