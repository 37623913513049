<div class="sm:grid sm:grid-cols-12 px-16px h-auto">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="createNewServiceForm">
      <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-5">
        <div class="sm:col-span-12">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-warm-grey"
              [ngClass]="
                createNewServiceForm.get('name').touched &&
                createNewServiceForm.controls.name.errors?.required
                  ? 'error-class'
                  : ''
              "
            >
              Tên dịch vụ <span class="text-red-light">*</span></span
            >

            <ng-select
              [ngClass]="
                createNewServiceForm.get('name').touched &&
                createNewServiceForm.controls.name.errors?.required
                  ? 'select-invalid'
                  : 'select-name'
              "
              required
              class="mt-8px focus:outline-none"
              placeholder="Chọn tên dịch vụ"
              bindLabel="name"
              bindValue="id"
              [items]="allServices"
              formControlName="name"
              notFoundText="Không tìm thấy tên dịch vụ"
              (change)="getValueServiceName($event)"
            >
              <ng-template ng-label-tmp let-item="item">
                <div class="flex items-center space-x-2">
                  <span
                    class="text-14px font-roboto-regular text-dark-grey overflow-hidden overflow-ellipsis"
                    >{{ item.name }}</span
                  >
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="flex items-center space-x-2">
                  <span>{{ item.name }}</span>
                </div>
              </ng-template>
            </ng-select>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                createNewServiceForm.get('name').touched &&
                createNewServiceForm.controls.name.errors?.required
              "
            >
              {{
                createNewServiceForm.controls.name.errors?.required
                  ? 'Vui lòng chọn tên dịch vụ'
                  : ''
              }}
            </span>
          </div>
        </div>

        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-warm-grey">
              Đơn giá</span
            >

            <input
              readonly
              formControlName="amount"
              type="text"
              placeholder="Đơn giá"
              class="cursor-pointer border overflow-hidden overflow-ellipsis bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-purplish-grey font-medium not-italic"
            />
          </div>
        </div>

        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-warm-grey">
              Đơn vị</span
            >

            <input
              readonly
              formControlName="unit"
              type="text"
              placeholder="Đơn vị"
              class="cursor-pointer border overflow-hidden overflow-ellipsis bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-purplish-grey font-medium not-italic"
            />
          </div>
        </div>

        <div class="col-span-full flex-row-reverse gap-8px flex h-60px">
          <button
            class="btn-primary-add"
            type="button"
            [disabled]="!createNewServiceForm.valid"
            (click)="submit()"
          >
            {{ nameButton }}
          </button>
          <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
        </div>
      </div>
    </form>
  </div>
</div>
