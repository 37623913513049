import { Component, OnInit, Input } from '@angular/core';
import { MxModalOption } from 'src/app/shared/components/modal/modal-confirm/modal-confirm.component';
import { Sku } from 'src/app/model/sku';
import { Subject } from 'rxjs';
import { SkuService } from 'src/app/api/sku.service';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { debounceTime, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { ToastrService } from 'src/app/core/services';

@Component({
  selector: 'meu-sku-product',
  templateUrl: './sku-product.component.html',
  styleUrls: ['./sku-product.component.scss'],
})
export class SkuProductComponent implements OnInit {
  idProduct: string = '';

  searchSKU: string = '';
  array: Sku[] = [];
  totalArray: number;
  indexArray: number = 0;
  idSKU: string;

  skus: Sku[] = [];
  subject: Subject<any> = new Subject();
  sortName: string = '';
  sort: string = '';

  titleModal: string;
  isOpenModal: boolean;
  isOpenBottomsheet;
  optionsBottom: MxBottomPopupOption = {};
  options: MxModalOption = {};

  titleImport: string;
  isOpenImport: boolean;
  optionsImport: MxModalOption = {};

  titleExport: string;
  isOpenExport: boolean;
  optionsExport: MxModalOption = {};

  constructor(
    private skuService: SkuService,
    private confirmService: ConfirmService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.idProduct = this.router.url.split('/')[5];
    this.getSkuOfProduct();
    this.isOpenBottomsheet = false;
    this.isOpenModal = false;

    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getSkuOfProduct();
    });
    this.optionCallbackModal();
    this.optionCallbackModalBottom();
    this.optionCallbackModalIx();
    this.optionCallbackModalEx();
  }

  //Call back
  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.idSKU = '';
      this.isOpenModal = false;
    };
  }

  optionCallbackModalBottom() {
    this.optionsBottom.callBackAfterClose = (e) => {
      this.idSKU = '';
      this.isOpenBottomsheet = false;
    };
  }

  optionCallbackModalIx() {
    this.optionsImport.callBackAfterClose = (e) => {
      this.isOpenImport = false;
      this.idSKU = '';
    };
  }
  optionCallbackModalEx() {
    this.optionsExport.callBackAfterClose = (e) => {
      this.isOpenExport = false;
      this.idSKU = '';
    };
  }
  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getSkuOfProduct();
  }
  MobileSort(data) {
    if (window.screen.width < 481) {
      this.Sort(data);
    }
  }

  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}

  getSkuOfProduct() {
    this.skuService
      .apiSkuGet(
        `product_id==${this.idProduct},sku_code|extend_inventory_name|extend_supplier_name@=${this.searchSKU}`
      )
      .pipe(debounceTime(3000))
      .subscribe((res) => {
        if (res.success == true) {
          this.array = res.data.collection;
          this.totalArray = res.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  // Paging
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.skuService
      .apiSkuGet(
        `product_id==${this.idProduct},sku_code|extend_inventory_name|extend_supplier_name@=${this.searchSKU}`,
        this.sort,
        currentPage,
        pageSize
      )
      .pipe(debounceTime(2000))
      .subscribe((data) => {
        if (data.success == true) {
          this.skus = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //handle Modal
  open(id) {
    if (id) {
      this.idSKU = id;
      this.titleModal = 'Chỉnh sửa SKU';
    } else {
      this.titleModal = 'Thêm mới SKU';
    }
    if (window.screen.width < 767) {
      this.isOpenModal = false;
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      }
    } else {
      this.isOpenBottomsheet = false;
      if (!this.isOpenModal) {
        this.isOpenModal = true;
      }
    }
  }
  closeModal(data) {
    if (!data) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
        this.idSKU = '';
      }
    } else {
      if (data.success == true) {
        if (this.isOpenModal) {
          this.isOpenModal = false;
          this.idSKU = '';

          this.getSkuOfProduct();
        }
      } else {
      }
    }
  }
  closeModalBottom(data) {
    if (!data) {
      if (this.isOpenBottomsheet) {
        this.isOpenBottomsheet = false;
      }
    } else {
      if (data.success == true) {
        if (this.isOpenBottomsheet) {
          this.isOpenBottomsheet = false;
          this.idSKU = '';

          this.getSkuOfProduct();
        }
      } else {
      }
    }
  }
  deleteconfirm(id) {
    let title = 'Xóa SKU';
    let content = 'Bạn có chắc chắn muốn xóa SKU này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.skuService.apiSkuIdDelete(id).subscribe((res) => {
            if (res.success === true) {
              this.getSkuOfProduct();
              this.toastService.success(res.message);
            } else {
              this.getSkuOfProduct();
              this.toastService.error(res.message);
            }
          });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
  addImport(id) {
    this.titleImport = 'Thêm mới phiếu nhập hàng';
    if (!this.isOpenImport) {
      this.isOpenImport = true;
      this.idSKU = id;
      this.optionsImport.styleClass = 'w-11/12';
    }
  }
  addExport(id) {
    this.titleExport = 'Thêm mới phiếu xuất hàng';
    if (!this.isOpenExport) {
      this.isOpenExport = true;
      this.idSKU = id;
      this.optionsExport.styleClass = 'w-11/12';
    }
  }
  closeModalImport(event) {
    if (this.isOpenImport) {
      this.isOpenImport = false;
    }
  }

  closeModalExport(event) {
    if (this.isOpenExport) {
      this.isOpenExport = false;
    }
  }

  changeTitle(event) {
    if (event.success) {
      this.titleImport = 'Chi tiết phiếu nhập hàng';
    }
  }

  changeTitleExport(event) {
    if (event.success) {
      this.titleExport = 'Chi tiết phiếu xuất hàng';
    }
  }
}
