import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedService) {}

  canActivate(): boolean {
    const isAuthenticated = this.isAuthenticated();
    if (!isAuthenticated) {
      this.sharedService.openSSO();
    }
    return isAuthenticated;
  }

  isAuthenticated() {
    const token = localStorage.getItem(environment.authTokenKey);
    const infoUserLogged = localStorage.getItem(environment.userInfo);
    if (token && token.trim() != '') {// && infoUserLogged && infoUserLogged.trim() != ''
      return true;
    }
    return false;
  }
}
