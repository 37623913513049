<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Hàng hóa
      </div>
      <div class="flex flex-wrap md:float-right items-center gap-8px">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchProduct"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>

        <div class="flex gap-8px">
          <button
            class="btn-primary-update flex items-center gap-4px"
            (click)="updateList()"
            permission="STAFF"
          >
            <img
              src="assets/icons/handle-icons/u_refresh.svg"
              class="w-20px h-20px"
            />
            <span>Cập nhật</span>
          </button>
          <button
            class="btn-primary-icon flex items-center gap-4px"
            (click)="open(null)"
            permission="STAFF"
          >
            <img src="assets/icons/handle-icons/icon_add.svg" />
            <span>Thêm mới</span>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full overflow-x-auto" style="overflow-y: hidden">
      <table
        class="w-full border-separate table-fixed"
        cellpadding="0"
        cellspacing="0"
        *ngIf="totalArray > 0; else noInfo"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
              #
            </th>
            <!-- image -->
            <th class="text-center col-image w-40px relative">
              <img
                src="assets/icons/table-icon/u_image-v.svg"
                class="top-0 bottom-0 left-0 right-0 absolute m-auto pt-1"
              />
            </th>
            <!-- code -->
            <th
              class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('code')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                >
                  Mã hàng hóa</span
                ><img
                  *ngIf="sort.includes('code'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- name -->
            <th
              class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Tên hàng hóa</span
                >
                <img
                  *ngIf="sort == 'name' || sort == '-name'; else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- product template -->
            <th
              class="text-left col-product-temp hidden-col cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('extend_product_template_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Loại hàng hóa</span
                >
                <img
                  *ngIf="
                    sort.includes('extend_product_template_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- unit name -->
            <th
              class="text-left col-unit hidden-col cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('extend_unit_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Đơn vị tính</span
                >
                <img
                  *ngIf="sort.includes('extend_unit_name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- sku -->
            <th class="text-left col-sku pt-12px px-16px pb-8px">
              <span class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                >SKU</span
              >
            </th>
            <!-- stock -->
            <th
              class="text-left col-number cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('stock')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Tồn kho</span
                >

                <img
                  *ngIf="sort.includes('stock'); else normalSearch"
                  class="float-right"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img
                    class="float-right"
                    src="assets/icons/sort/sort_normal.svg"
                  />
                </ng-template>
              </div>
            </th>
            <th
              class="text-center w-140px pt-12px px-16px pb-8px"
              permission="STAFF"
            >
              Thao tác
            </th>
          </tr>
        </thead>
        <!-- DATA TABLE -->
        <tbody>
          <tr
            *ngFor="let product of products; let i = index"
            style="min-height: 40px !important"
          >
            <td
              class="td-stt align-top py-10px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="#"
              [tooltip]="indexArray + i + 1"
            >
              {{ indexArray + i + 1 }}
            </td>
            <!-- image  td-image-->
            <td
              class="td-image responsive align-top relative"
              data-label="Hình ảnh"
            >
              <div class="w-full h-41px">
                <img
                  [src]="urlBankEnd + product.image"
                  *ngIf="product.image; else default"
                  class="md:w-full float-right w-40px h-full"
                />
                <ng-template #default>
                  <img
                    src="assets/icons/table-icon/icon_24_default_image.svg"
                    class="md:w-full h-full w-40px float-right px-8px py-4x"
                  />
                </ng-template>
              </div>
              <div
                class="md:w-full w-40px bg-dark-grey opacity-40 h-41px absolute top-0"
                *ngIf="product.is_hidden == true"
              ></div>
              <img
                *ngIf="product.is_hidden == true"
                src="assets/icons/table-icon/u_lock-alt.svg"
                class="w-20px h-20px absolute top-10px right-9px md:left-11px"
              />

              <!-- <ng-template #noBlock>
            <div class="md:w-full w-40px h-41px">
              <img
                [src]="urlBankEnd + product.image"
                *ngIf="product.image; else default"
                class="md:w-full w-40px h-full"
              />
              <ng-template #default>
                <img
                  src="assets/icons/table-icon/icon_24_default_image.svg"
                  class="w-full h-full px-8px py-4x"
              /></ng-template>
            </div>
          </ng-template> -->
            </td>

            <td
              class="td-code align-top py-10px px-16px text-primary-dodger-blue overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Mã hàng hóa "
              (click)="MobileSort('code')"
            >
              <span
                class="cursor-pointer"
                [tooltip]="product.code ? product.code : '--'"
              >
                {{ product.code ? product.code : '--' }}</span
              >
            </td>
            <td
              class="td-name align-top py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Tên hàng hóa "
              (click)="MobileSort('name')"
            >
              <span
                class="cursor-pointer"
                [tooltip]="product.name ? product.name : '--'"
              >
                {{ product.name ? product.name : '--' }}</span
              >
            </td>
            <td
              class="td-product-temp align-top py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Đơn vị tính"
              (click)="MobileSort('extend_product_template_name')"
              [tooltip]="
                product['extend_product_template_name']
                  ? product['extend_product_template_name']
                  : '--'
              "
            >
              <span class="cursor-pointer">
                {{
                  product['extend_product_template_name']
                    ? product['extend_product_template_name']
                    : '--'
                }}</span
              >
            </td>
            <td
              class="td-unit align-top py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Đơn vị tính"
              (click)="MobileSort('extend_unit_name')"
              [tooltip]="
                product['extend_unit_name'] ? product['extend_unit_name'] : '--'
              "
            >
              <span class="cursor-pointer">
                {{
                  product['extend_unit_name']
                    ? product['extend_unit_name']
                    : '--'
                }}</span
              >
            </td>
            <td
              data-label="SKU"
              class="cursor-pointer align-top py-10px px-16px td-sku"
              *ngIf="product['extend_sku'].length > 0; else emtyStock"
              (click)="Show(product.id)"
            >
              <div
                *ngIf="show.includes(product.id); else normalShow"
                class="flex justify-between respon-sku"
              >
                <div
                  class="flex flex-col overflow-hidden whitespace-nowrap overflow-ellipsis"
                >
                  <span
                    class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                    *ngFor="let sku of product['extend_sku']"
                  >
                    {{ sku.sku }} ({{ sku.inventory_name }}) | Tồn:
                    {{ sku.stock }}
                  </span>
                </div>
                <div>
                  <img
                    *ngIf="product['extend_sku'].length > 1"
                    src="assets/icons/table-icon/u_angle-up.svg"
                    alt="up"
                    class="float-right icon-up-down"
                  />
                </div>
              </div>
              <ng-template #normalShow>
                <div class="flex justify-between respon-sku w-full h-auto">
                  <div
                    class="flex flex-col overflow-hidden whitespace-nowrap overflow-ellipsis"
                  >
                    <span
                      class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                    >
                      {{ product['extend_sku'][0].sku }} ({{
                        product['extend_sku'][0].inventory_name
                      }}) | Tồn:
                      {{ product['extend_sku'][0].stock }}
                    </span>
                  </div>
                  <div>
                    <img
                      src="assets/icons/table-icon/u_angle-down.svg"
                      *ngIf="product['extend_sku'].length > 1"
                      alt="down"
                      class="float-right icon-up-down"
                    />
                  </div>
                </div>
              </ng-template>
            </td>
            <!-- emtystock -->
            <ng-template #emtyStock>
              <td class="td-sku align-top py-10px px-16px">--</td>
            </ng-template>
            <td
              class="td-stock align-top py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Tồn"
              (click)="MobileSort('stock')"
            >
              <span
                class="cursor-pointer"
                [tooltip]="product['stock'] | number"
              >
                {{ product['stock'] | number }}</span
              >
            </td>
            <td
              class="td-thaotac md:px-16px align-top"
              data-label="Thao tác"
              permission="STAFF"
            >
              <div class="flex flex-row-reverse">
                <button
                  tooltip="Xóa"
                  class="hover:bg-primary-lightest rounded-full focus:outline-none p-10px"
                  (click)="deleteconfirm(product.id)"
                >
                  <img
                    src="assets/icons/handle-icons/u_trash-alt.svg"
                    class="icon-handle"
                  />
                </button>
                <button
                  tooltip="Chỉnh sửa"
                  class="hover:bg-primary-lightest focus:outline-none p-10px rounded-full"
                  [routerLink]="urlDetail + product.id"
                >
                  <img
                    src="assets/icons/handle-icons/u_edit-alt.svg"
                    class="icon-handle"
                  />
                </button>
                <div
                  *ngIf="
                    product.minimum_stock_level || product.maximum_stock_level
                  "
                >
                  <img
                    *ngIf="
                      product['stock'] > product.maximum_stock_level;
                      else up
                    "
                    src="assets/icons/table-icon/u_angle-double-down.svg"
                    class="p-10px button-add-edit"
                  />
                  <ng-template #up>
                    <img
                      class="p-10px button-add-edit"
                      src="assets/icons/table-icon/u_angle-double-up.svg"
                      alt="up"
                    />
                  </ng-template>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noInfo>
        <table
          class="w-full border-separate table-fixed"
          cellpadding="0"
          cellspacing="0"
        >
          <thead>
            <tr
              class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
            >
              <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
                #
              </th>
              <!-- image -->
              <th class="text-center col-image w-40px relative">
                <img
                  src="assets/icons/table-icon/u_image-v.svg"
                  class="top-0 bottom-0 left-0 right-0 absolute m-auto pt-1"
                />
              </th>
              <!-- code -->
              <th
                class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
              >
                <div
                  class="flex justify-between items-center"
                  (click)="Sort('code')"
                >
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >
                    Mã hàng hóa</span
                  ><img
                    *ngIf="sort.includes('code'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- name -->
              <th
                class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
                (click)="Sort('name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >Tên hàng hóa</span
                  >
                  <img
                    *ngIf="sort == 'name' || sort == '-name'; else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- product template -->
              <th
                class="text-left col-product-temp hidden-col cursor-pointer pt-12px px-16px pb-8px"
                (click)="Sort('extend_product_template_name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >Loại hàng hóa</span
                  >
                  <img
                    *ngIf="
                      sort.includes('extend_product_template_name');
                      else normalSearch
                    "
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- unit name -->
              <th
                class="text-left col-unit hidden-col cursor-pointer pt-12px px-16px pb-8px"
                (click)="Sort('extend_unit_name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >Đơn vị tính</span
                  >
                  <img
                    *ngIf="sort.includes('extend_unit_name'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <!-- sku -->
              <th class="text-left col-sku pt-12px px-16px pb-8px">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >SKU</span
                >
              </th>
              <!-- stock -->
              <th
                class="text-left col-number cursor-pointer pt-12px px-16px pb-8px"
                (click)="Sort('stock')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >Tồn kho</span
                  >

                  <img
                    *ngIf="sort.includes('stock'); else normalSearch"
                    class="float-right"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img
                      class="float-right"
                      src="assets/icons/sort/sort_normal.svg"
                    />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-center w-140px pt-12px px-16px pb-8px"
                permission="STAFF"
              >
                Thao tác
              </th>
            </tr>
          </thead>
          <!-- DATA TABLE -->
          <tbody>
            <tr class="font-roboto-regular text-14px h-40px">
              <td colspan="9" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [total]="totalArray"
        [array]="array"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-modal-create-product
    (closeModalUnit)="closeModal($event)"
  ></meu-modal-create-product>
</meu-modal-component>

<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-create-product
    (closeModalUnit)="closeModalBottom($event)"
  ></meu-modal-create-product>
</meu-modal-popup-bottom>
