import Konva from 'konva';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MenuButton } from './menu-button';
import { Scale } from 'chart.js';

export class MainMenu {
  btnClick: EventEmitter<any> = new EventEmitter();
  transformers: Konva.Transformer[] = [];
  private subscriptions: Subscription[] = [];
  public width: number = 40;
  public height: number = 160;
  newScale$ = new Subject<any>();
  private menuGroup = new Konva.Group();
  private menuGroupBackgroud = new Konva.Rect({
    id: '',
    x: 10,
    y: 10,
    width: 40,
    height: 160,
    fill: 'white',
    stroke: '#ffbd48',
    strokeWidth: 1,
    cornerRadius: 3,
    shadowForStrokeEnabled: true,
  });

  //list button
  lstButton = [
    {
      type: 'save',
      icon: 'icon_content_save.svg',
      lstField: {
        x: 20,
        y: 20,
        width: 22,
        height: 22,
        padding: 10,
      },
    },
    {
      type: 'line',
      icon: 'konva-pen.svg',
      lstField: {
        x: 20,
        y: 50,
        width: 22,
        height: 22,
        padding: 10,
      },
    },
    {
      type: 'rect',
      icon: 'konva-rectangle.svg',
      lstField: {
        x: 20,
        y: 80,
        width: 22,
        height: 22,
        padding: 10,
      },
    },
    {
      type: 'circle',
      icon: 'konva-circle.svg',
      lstField: {
        x: 20,
        y: 110,
        width: 22,
        height: 22,
        padding: 10,
      },
    },
    {
      type: 'image',
      icon: 'konva-image.svg',
      lstField: {
        x: 20,
        y: 140,
        width: 22,
        height: 22,
        padding: 10,
      },
    },
  ];
  // constructor
  constructor(private stage?: Konva.Stage, private layer?: Konva.Layer) {
    //menuPosition
    this.newScale$.subscribe((scale) => {
      let point = {
        x: 10 / scale.newScale - scale.newPos.x / scale.newScale,
        y: 10 / scale.newScale - scale.newPos.y / scale.newScale,
      };

      this.menuGroupBackgroud.position(point);
      this.menuGroupBackgroud.setAttrs({
        width: this.width / scale.newScale,
        height: this.height / scale.newScale,
        strokeWidth: 1 / scale.newScale,
        cornerRadius: 3 / scale.newScale,
      });
      this.menuGroupBackgroud.moveToTop();
      layer.batchDraw();
    });

    this.lstButton.forEach((item) => {
      let newBtn = new MenuButton(this.layer, this.menuGroup, item);
      this.subscriptions.push(
        this.newScale$.subscribe((scale) => {
          newBtn.newButtonScale$.next(scale);
        })
      );
      this.subscriptions.push(
        newBtn.onClick.subscribe((type) => {
          this.btnClick.emit(type);
        })
      );
    });

    this.menuGroup.add(this.menuGroupBackgroud);
    this.layer.add(this.menuGroup);
    stage.on('dragstart wheel', () => this.menuGroup.moveToTop());
  }
}
