<div class="debt-infomation">
  <div
    class="col-span-12 grid grid-cols-12 gap-2 container-12 container-12-ipad mt-8px"
  >
    <div
      class="col-span-12 flex flex-col items-stretch sm:items-center m-dp-fl z-index-1"
    >
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Misc_database.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium text-20px pt-5px">
            {{
              userDetailDebt ? (userDetailDebt.turnover | meucurrency) : false
            }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Doanh thu
          </div>
        </div>
      </button>
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Finance_Weekly-Salary.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium text-20px pt-5px">
            {{
              userDetailDebt
                ? (userDetailDebt.actual_paid | meucurrency)
                : false
            }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Thanh toán thực tế
          </div>
        </div>
      </button>
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Messaging_archive.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium text-20px pt-5px">
            {{ userDetailDebt ? (userDetailDebt.debt | meucurrency) : false }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Ghi nợ
          </div>
        </div>
        <img
          src="assets/icons/debt-management/u_exclamation-triangle.svg"
          class="w-20px h-20px mt-20px ml-12px col-span-3"
        />
      </button>
      <button
        class="col-span-3 bg-white h-60px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none my-5px"
      >
        <img
          class="w-28px h-28px mt-15px ml-12px col-span-2"
          src="assets/icons/debt-management/Notifications_info.svg"
          alt=""
        />
        <div class="h-60px col-span-7 grid grid-rows-2 text-left text-grey-med">
          <div class="row-span-1 font-roboto-medium text-20px pt-5px">
            {{
              userDetailDebt
                ? (userDetailDebt.max_debt_allow | meucurrency)
                : false
            }}
          </div>
          <div
            class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
          >
            Công nợ tối đa
          </div>
        </div>
        <img
          (click)="openEdit()"
          src="assets/icons/handle-icons/u_edit-alt.svg"
          class="w-20px h-20px mt-20px ml-12px col-span-3"
        />
      </button>
    </div>
  </div>
  <div
    class="col-span-12 grid grid-cols-12 gap-2 container-12 container-12-ipad mt-8px"
  ></div>

  <div class="">
    <div class="pb-12px">
      <span class="h-40px text-16px font-roboto-medium"
        >Thanh toán đơn hàng</span
      >
    </div>
    <div>
      <table
        class="w-full border-separate table-fixed"
        cellpadding="0"
        cellspacing="0"
        id="table"
      >
        <thead>
          <tr
            class="bg-lightest-grey text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="text-left col-code items-center py-16px px-8px">
              <!-- (click)="Sort('imExportCode')" -->
              <div class="flex justify-start items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  tooltip="Mã phiếu nhập / xuất"
                  >Mã đơn hàng</span
                >
              </div>
            </th>

            <th class="text-left col-code items-center py-16px px-8px">
              <div class="flex justify-end items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Khách hàng"
                  >Giá trị đơn hàng</span
                >
              </div>
            </th>

            <th class="text-left col-code items-center py-16px px-8px">
              <div class="flex justify-end items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Khách hàng"
                  >Đã thanh toán</span
                >
              </div>
            </th>
            <!-- <th
                class="text-left col-created-by hidden-col  pt-12px px-16px pb-8px"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    tooltip="Người lập phiếu"
                    >Người lập phiếu</span
                  >
                </div>
              </th> -->
            <!-- <th
                class="text-left col-created-at  pt-12px px-16px pb-8px"
                (click)="Sort('created_at')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    tooltip="Ngày tạo"
                    >Ngày tạo</span
                  >
  
                  <img
                    *ngIf="sort.includes('created_at'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th> -->
          </tr>
        </thead>

        <!-- DATA TABLE -->
        <tbody class="font-roboto-regular">
          <tr
            *ngFor="let item of listContainerProductPayment"
            style="min-height: 40px !important"
          >
            <td
              class="td-code py-10px px-8px responsive text-14px text-left"
              data-label="Mã đơn hàng"
            >
              <div class="relative flex">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                >
                  {{ item.code }}
                </span>
              </div>
              <!-- <ng-template #showExport>
                      <div
                        class="relative flex "
                        [tooltip]="importexport.imExportCode"
                        >
                        <img
                          src="../../../../assets/icons/dashboard-icons/u_file-upload-alt.svg"
                          class="w-16px h-16px mr-5px mt-2px"
                        />
                        <span
                          class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                          (click)="open(importexport.imExportCode)"
                          >{{ importexport.imExportCode }}</span
                        >
                      </div>
                    </ng-template> -->
            </td>

            <td
              class="td-name py-10px px-8px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
              data-label="Giá trị đơn hàng"
            >
              <span class="">
                {{ item.total_price | meucurrency }}
              </span>
            </td>

            <td
              class="td-name py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
              data-label="Đã thanh toán"
            >
              <span class="">
                {{ item.total_payment | meucurrency }}
              </span>
            </td>
          </tr>

          <tr
            style="min-height: 40px !important"
            class="font-roboto-medium"
            id="total"
          >
            <td
              class="td-code py-10px px-8px responsive text-14px text-left"
              data-label="Tổng cộng"
            >
              <div class="relative flex">
                <span> Tổng cộng </span>
              </div>
            </td>

            <td
              class="td-name py-10px px-8px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
              data-label="Giá trị đơn hàng"
            >
              <span class=""> {{ totalPrice | meucurrency }} </span>
            </td>

            <td
              class="td-name py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis text-14px text-right"
              data-label="Đã thanh toán"
            >
              <span class="">{{ totalPayment | meucurrency }} </span>
            </td>
          </tr>
        </tbody>

        <!-- <ng-template #noInfo>
                <tbody>
                  <tr
                    class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
                  >
                    <td
                      colspan="9"
                      class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
                    >
                      Không có dữ liệu
                    </td>
                  </tr>
                </tbody>
              </ng-template> -->
      </table>
    </div>
  </div>
</div>
<meu-modal-popup-bottom
  [title]="title"
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-debt-infomation-edit
    [userID]="userDetailID"
    (closeRequest)="closeEdit($event)"
  ></meu-modal-debt-infomation-edit>
</meu-modal-popup-bottom>
