<div
  class="modal-bg-overlay"
  [ngStyle]="{ 'z-index': option.zIndex }"
  (click)="close()"
></div>
<div
  class="modal-container {{ option.styleClass }}"
  [ngStyle]="{
    top: option.top,
    'animation-name':
      option.top === '50%' ? 'centerModalAnimation' : 'modalAnimation'
  }"
  [ngClass]="{ 'top-center': option.top === '50%' }"
>
  <div class="modal-content">
    <div class="grid grid-col-12 pt-8px px-16px">
      <div class="justify-between flex col-span-full items-center">
        <div class="font-roboto-medium text-16px" style="color: #505050;">
          {{ contents.title }}
        </div>
        <div>
          <span
            (click)="close()"
            *ngIf="!option.hideCloseButton"
            class="cursor-pointer"
            ><img
              [src]="'assets/icons/toast/u_close.svg'"
              alt="icons gray cross"
          /></span>
        </div>
      </div>
    </div>
    <div class="pt-48px pr-76px pb-44px pl-77px flex gap-8px cls-content">
      <img src="assets/icons/toast/u_warning_red.svg" />
      <span
        class="font-roboto-regular text-14px text-background-modal ml-10px break-normal text-left"
        >{{ contents.content }}</span
      >
    </div>
    <div
      class="col-span-full flex-row-reverse flex-wrap gap-8px flex h-64px pt-8px pb-16px px-16px"
    >
      <!-- [disabled]="!formcreate.valid" -->
      <button class="btn-cancel-delete" type="button" (click)="Del(null)">
        Hủy
      </button>
      <button class="" [ngClass]="contents.confirmOption==true ? 'btn-red-delete' : 'btn-primary-add'" type="button" (click)="Del(true)"> 
        {{ contents.confirmOption == true ? 'Xóa' : 'Xác nhận' }}
      </button>
    </div>
  </div>
</div>
