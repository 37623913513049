import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { ProductService } from 'src/app/api/product.service';
import { UnitService } from 'src/app/api/unit.service';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { SkuService } from 'src/app/api/sku.service';
import { IDayCalendarConfig, DatePickerComponent } from 'ng2-date-picker';
import { SlotService } from 'src/app/api/slot.service';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { filter, take, debounceTime, map } from 'rxjs/operators';
import { ToastrService, ConfirmService } from 'src/app/core/services';
import { UserService } from 'src/app/api/user.service';
import { Subject } from 'rxjs';
import { ContractService } from 'src/app/api/contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { environment } from 'src/environments/environment';
import { ExportService, Slot } from 'src/app';
import { StockAvailableService } from 'src/app/api/stockAvailable.service';
import { saveAs } from 'file-saver';
import { PermissionService } from 'src/app/core/guards/permission.service';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-export-history',
  templateUrl: './export-history.component.html',
  styleUrls: ['./export-history.component.scss'],
})
export class ExportHistoryComponent implements OnInit, AfterViewInit {
  @Input() imExport_code: string;
  @Input() idProduct: string;
  @Input() idContract: string;
  @Input() skuId: string;
  @Input() dataFromContainer: Slot;
  @Input() isOpenFormModalDashBoard: boolean = false;
  @Input() lstSlotSelected: any[];
  @Input() isTransferCar: boolean;
  @Output() closeModalExport = new EventEmitter();
  @Output() changeTitleModal = new EventEmitter();
  @ViewChild('dateToDp') public dateToDp: DatePickerComponent;
  @ViewChild('myTooltipProductCode', { static: false }) myTooltipProductCode;
  @ViewChild('myTooltipProductName', { static: false }) myTooltipProductName;
  @ViewChild('myTooltipSkuCode', { static: false }) myTooltipSkuCode;

  currentDate = new Date();

  allProducts = [];
  allSkus = [];

  titleImport: string;

  lstproducts = [];

  isCheckDraft: boolean = false;

  listItem;
  tempProduct = [];

  //array temp
  arrProducts: any = [];
  arrAllProducts: any = [];

  numberItem;

  arrHashtag = [];
  arrExport = [];

  selectProduct;
  selectSku;
  selectHashtag = [];
  unit_name;
  unit_code;

  create_at;
  issuedate: string;
  filter: string = '';
  inventoryId: string = '';
  quantity: number;
  net_weight: number;
  slot_id: string = '';
  product_name: string = '';
  contract_code: string = '';
  is_draft;
  is_checked;
  is_sale = false;
  stock;

  contractID;
  product_id: string = '';
  sku_code: string = '';
  value_product_id: string = '';

  //get from modal quantity
  slot: string = '';
  itemQuantity: string = '';

  //get from modal percent used slot
  listSlots: any[] = [];
  listExportSlots: any[] = [];

  listImportCode: string = '';

  listSelectImportCode = [];

  formExport = new FormGroup({
    note: new FormControl(''),
    issue_date: new FormControl(moment()),
    imExportCode: new FormControl(''),
    imExportCodeHis: new FormControl(),
    implementer_id: new FormControl(''),
    customer_id: new FormControl(''),
    contract_id: new FormControl(''),
    contract_code: new FormControl(''),
    //supplier_name: new FormControl(),
    batch_code: new FormControl(),
    receive_car: new FormControl(),
    receiver_id: new FormControl(),
    receiver: new FormControl(),
    seller_id: new FormControl(),
    //New update add container_number_import
    container_number_import: new FormControl(),
    unit_price: new FormControl(),
  });

  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm',
    firstDayOfWeek: 'mo',
    opens: 'left',
    drops: 'down',
  };

  titleModal: string;
  titleModalQuantity: string;
  titleModalSKU: string;
  titleModalQR: string;
  titleModalContract: string;
  titleModalPercentSlot: string;
  title_filter: string;
  title_Cell: string;

  isOpenModalProduct: boolean;
  isOpenModalQuantity: boolean;
  isOpenModalSKU: boolean;
  isOpenModalQR: boolean;
  isOpenModalContract: boolean;
  isOpenModalPercentSlot: boolean;
  isOpenPrintIE: boolean;
  isOpenFilterImport: boolean;
  isOpenCellLocation: boolean;
  isOpenCellLocationBottom = false;

  options: MxModalOption = {};
  optionsQuantity: MxModalOption = {};
  optionsSKU: MxModalOption = {};
  optionsQR: MxModalOption = {};
  optionsContract: MxModalOption = {};
  optionsPercentSlot: MxModalOption = {};
  optionsPrintIE: MxModalOption = {};
  optionsFilter: MxModalOption = {};
  optionsCellLocation: MxModalOption = {};
  optionsCellLocationBottom: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenCellLocationBottom = false;
    },
  };

  search = '';

  //load more implementer
  allImplemeters = [];
  implementer = {
    avatar: null,
    full_name: null,
  };
  filterImplementer: string = '';
  pageImplementer = 10;
  totalImplementer = 0;
  openImplementer = false;
  keyUpImplementer = new Subject<KeyboardEvent>();
  clearSearchImplementer: any = null;

  //load more receiver
  allReceivers = [];
  receiver = {
    id: null,
    avatar: null,
    full_name: null,
  };
  filterReceiver: string = '';
  pageReceiver = 10;
  totalReceiver = 0;
  openReceiver = false;
  keyUpReceiver = new Subject<KeyboardEvent>();
  clearSearchReceiver: any = null;

  //load more seller
  allSellers = [];
  seller = {
    id: null,
    avatar: null,
    full_name: null,
  };
  filterSeller: string = '';
  pageSeller = 10;
  totalSeller = 0;
  openSeller = false;
  keyUpSeller = new Subject<KeyboardEvent>();
  clearSearchSeller: any = null;

  //load more customer
  allCustomers = [];
  customer = {
    id: null,
    full_name: null,
    avatar: null,
  };
  filterCustomer: string = '';
  pageCustomer = 10;
  totalCustomer = 0;
  openCustomer = false;
  keyUpCustomer = new Subject<KeyboardEvent>();
  textSearchCustomer: any = '';
  clearSearchCustomer: any = null;

  //load more contract
  allContracts = [];
  contract = {
    code: null,
    id: null,
  };

  userID;
  lstCodeImExport = [];
  imExportCodeHis;
  openImExportCode = false;
  clearSearchImExportHis: any = null;
  keyUpImExportHis = new Subject<KeyboardEvent>();
  filterImExportHis: string = '';
  textSearchImExport: any = '';

  filterContract: string = '';
  pageContract = 10;
  totalContract = 0;
  openContract = false;
  keyUpContract = new Subject<KeyboardEvent>();
  textSearchContract: any = '';
  clearSearchContract: any = null;

  created_by;
  created_avatar;

  //load more search product
  allSearchProduct = [];
  filterSearchProduct: string = '';
  openSearchProduct = false;
  pageSearchProduct = 10;

  //load more search import batch code
  allSearchImport = [];
  filterSearchImport: string = '';
  stockSearchImport = '';
  usedpercentSearchImport = '';
  zoneidSearchImport = '';

  qrExportData = 'PX123123123';

  keyIndex = 0;

  //Scan QR code
  sku_codeIdQRCode = '';
  productIdQRCode = '';
  productNameQRCode = '';
  productCodeQRCode = '';
  stockQRCode = '';
  unitNameQRCode = '';
  unitCodeQRCode = '';
  slotQRCode = '';
  usedpercentQRCode = '';
  zoneidQRCode = '';
  supplierNameQRCode = '';
  supplierIdQRCode = '';

  title_print: string = '';
  titlePrint;
  allPrint = [
    // { title: 'QR code' },
    // { title: 'Lệnh Xuất' },
    // { title: 'Phiếu Xuất' },
    // { title: 'Báo cáo' },
  ];
  isBillOrCommand: boolean;

  listPercent = [];
  allStockProduct = [];

  disableCheck: boolean = false;
  disableExport: boolean = false;

  title_button = 'Chọn phiếu nhập';
  lstTilteButton = ['Chọn phiếu nhập'];

  listProductTemp = [];
  lstPermission = [];
  isMobile = false;

  lstImportCode = [];
  keyUpSearchImport = new Subject<KeyboardEvent>();
  keySearchImport = '';

  isOpenModalPercentSlotBottom = false;
  optionsPercentSlotBottom: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenModalPercentSlotBottom = false;
    },
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private productService: ProductService,
    private unitService: UnitService,
    private importExportHistoryService: ImportExportHistoryService,
    private skuService: SkuService,
    private slotService: SlotService,
    private userService: UserService,
    private confirmService: ConfirmService,
    private toastr: ToastrService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStoreService: LocalStoreService,
    private stockAvailableService: StockAvailableService,
    private exportService: ExportService,
    private permissionService: PermissionService
  ) {}

  ngAfterViewInit() {
    this.setModalAddOrEdit();
    this.myTooltipProductCode.hide();
    this.myTooltipProductName.hide();
    this.myTooltipSkuCode.hide();
  }

  ngOnInit(): void {
    this.setInventoryId();
    this.queryScreen();
    if (this.isTransferCar === true) {
      this.titleImport = 'PHIẾU SANG XE';
    } else {
      this.titleImport = 'PHIẾU XUẤT HÀNG';
    }

    //this.getAllProducts();
    this.setContractAndCustomer();
    this.getCreator();
    this.userService
      .apiUsersGet(
        'id==6e225e8f-23a9-400b-98ed-39fec50cc35d',
        '',
        1,
        this.pageCustomer
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.chooseCustomer(data.data.collection[0]);
        }
      });
    this.optionCallbackModal();
    this.optionCallbackModalProduct();
    this.optionCallbackModalSku();
    this.optionCallbackModalQRCode();
    this.optionCallbackModalContract();
    this.optionCallbackModalPercentSlot();
    this.optionCallbackModalPrint();
    this.optionCallBackModalFilter();
    this.optionCallBackModalCellLocation();

    //implementer
    this.getAllImplementers();
    this.searchImplementer();
    //Receiver
    this.getAllReceivers();
    this.searchReceiver();
    //Seller
    this.getAllSellers();
    this.searchSeller();
    //customer
    this.getAllCustomers();
    this.searchCustomer();

    //contract
    this.searchContract();

    //Import Code box
    this.searchImport();

    this.getPermission();
  }

  queryScreen() {
    this.isMobile = document.body.offsetWidth < 1014 ? true : false;
    if (this.isMobile) {
      this.getAllImportCode();
    }
  }

  getPermission() {
    this.permissionService
      .getMyPermissions()
      .toPromise()
      .then((res) => {
        this.lstPermission = res;
      });
  }
  setInventoryId() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
    });
    if (
      this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      )
    ) {
      this.inventoryId = this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      );
    }
    if (this.router.url.split('/')[2]) {
      this.inventoryId = this.router.url.split('/')[2];
    }
  }

  getAllCodeByUserID() {
    this.importExportHistoryService
      .apiImportExportHistoryGetAllCodeGet(
        this.customer.id,
        this.contract.id,
        new Date('2021-12-12'),
        new Date('2021-12-16'),
        this.filterImExportHis,
        '',
        1,
        1000000
      )
      .subscribe((res) => {
        this.lstCodeImExport = res.data;
      });
  }

  setContractAndCustomer() {
    if (this.idContract) {
      this.contractService
        .apiContractIdGet(this.idContract)
        .subscribe((res) => {
          if (res.success) {
            this.contract.code = res.data.code;
            this.customer.id = res.data.customer_id;
            this.getAllContracts();
            this.formExport.patchValue({
              contract_id: this.idContract,
              contract_code: this.contract.code,
            });
            this.chooseContract(res.data);
          }
          this.userService.apiUsersIdGet(this.customer.id).subscribe((data) => {
            if (data.success) {
              this.customer.full_name = data.data.full_name;
              this.getAllCustomers();
              this.formExport.patchValue({
                customer_id: this.customer.id,
              });
            }
          });
        });
    }
  }

  clickCodeProduct() {
    if (this.idContract) {
      this.stockCustomerAndContract(this.customer.id, this.idContract);
    }
  }

  updateSearch() {
    if (!this.idProduct) {
      if (!this.search) {
        this.allSearchProduct = [];
      } else {
        this.filterSearchProduct =
          'code|name|sku_code@=' + this.search.toUpperCase();
        this.openSearchProduct = true;
        this.getAllSearchProduct();
      }

      //scan qr code
      let arrInput = [];
      if (this.search.includes('|') && this.search.endsWith('~')) {
        arrInput = this.search.split('|', 5);
        this.search = '';
        let skuCode = arrInput[1];
        let batchCode = arrInput[3];
        var slotId = arrInput[4].split('~');
        var slot = slotId[0];

        this.skuService.apiSkuGet('', '', 1, 10000).subscribe((res) => {
          if (res.success == true) {
            this.allSkus = res.data.collection;
            let item = {};
            item = {
              sku_code: skuCode,
              batch_code: batchCode,
              slot: slot,
            };
            this.slotService
              .apiSlotsGet(this.filter, '', 1, 10000, this.inventoryId)
              .pipe(debounceTime(300))
              .subscribe((data) => {
                if (data.success == true) {
                  this.arrHashtag = data.data.collection;
                  this.addRowProductByQRCode(item);
                }
              });
            this.openSearchProduct = false;
          }
        });
      }
    }
    if (this.idProduct) {
      if (!this.search) {
        this.allSearchProduct = [];
      } else {
        this.filterSearchProduct =
          'id==' +
          this.idProduct +
          ',code|name|sku_code@=' +
          this.search.toUpperCase();
        this.openSearchProduct = true;
        this.getAllSearchProduct();
      }
    }

    if (this.imExport_code) {
      if (this.is_checked == true) {
        if (!!this.search) {
          const arr = this.arrAllProducts.filter(
            (p) =>
              p.product_code.includes(this.search.toUpperCase()) ||
              p.product_name
                .toUpperCase()
                .includes(this.search.toUpperCase()) ||
              p.sku_code.toUpperCase().includes(this.search.toUpperCase())
          );
          this.arrProducts = [...arr];
        } else {
          this.arrProducts = [...this.arrAllProducts];
        }
      }
    }
  }

  updateSearchImport(index, batchcode) {
    if (!batchcode) {
      this.allSearchImport = [];
    } else {
      this.filterSearchImport = 'batch_code@=' + batchcode;
      this.arrProducts[index].isOpen = true;
      this.getAllImportExportByBatchCode();
    }
  }

  getAllImportExportByBatchCode() {
    this.importExportHistoryService
      .apiImportExportHistoryGet(
        this.filterSearchImport +
          ',isImExported==true,inventory_id==' +
          this.inventoryId,
        '',
        1,
        10000,
        false
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allSearchImport = [...data.data.collection];
        }
      });
  }

  addRowProductBatchCode(index, item) {
    let objBatchCode = {};
    this.skuService
      .apiSkuGet('product_id==' + item.product_id, '', 1, 10000)
      .pipe(debounceTime(300))
      .subscribe((response) => {
        if (response.success) {
          this.arrProducts[index].allSkus = response.data.collection;
        }
      });
    this.skuService.apiSkuIdGet(item.sku_id).subscribe((res) => {
      if (res.success) {
        this.stockSearchImport = res.data.stock;
      }

      this.slotService.apiSlotIdInfoGet(item.slot_id).subscribe((data) => {
        if (data.success) {
          this.usedpercentSearchImport = data.data.used_percent;
          this.zoneidSearchImport = data.data.zone_id;
        }

        this.value_product_id = item.product_id;

        objBatchCode = {
          //quantity: item.quantity,
          quantity: 1,
          isEdit: true,
          selectProduct: item.product_id,
          selectSku: item.sku_id,
          unit_name: item.unit_name,
          unit_code: item.unit_code,
          selectProductObj: {
            id: item.product_id,
            name: item.product_name,
            code: item.product_code,
          },
          selectSkuObj: {
            id: item.sku_id,
            sku_code: item.sku_code,
          },
          selectHashtag: [
            {
              id: item.slot_id,
              code: item.extend_slot_code,
              used_percent: this.usedpercentSearchImport,
              zone_id: this.zoneidSearchImport,
              new_value: null,
            },
          ],
          product_name: item.product_name,
          product_code: item.product_code,
          supplier_name: item.supplier_name,
          supplier_id: item.supplier_id,
          batch_code: item.batch_code,
          sku_id: item.sku_id,
          sku_code: item.sku_code,
          slot_id: item.slot_id,
          stock: this.stockSearchImport,
          isOpen: false,
          allSkus: this.arrProducts[index].allSkus,
          net_weight: item.net_weight,
        };

        this.arrProducts[index] = objBatchCode;
        this.arrProducts = [...this.arrProducts];
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  getAllSearchProduct() {
    this.productService
      .apiProductGet(this.filterSearchProduct, '', 1, 10000)
      .subscribe((data) => {
        if (data.success == true) {
          this.allSearchProduct = [...data.data.collection];
        }
      });
  }

  optionCallbackModalPrint() {
    this.optionsPrintIE.callBackAfterClose = (e) => {
      this.isOpenPrintIE = false;
      this.titlePrint = null;
      this.isBillOrCommand = null;
    };
  }

  optionCallBackModalFilter() {
    this.optionsFilter.callBackAfterClose = (e) => {
      this.isOpenFilterImport = false;
      this.title_filter = null;
    };
  }

  optionCallBackModalCellLocation() {
    this.optionsCellLocation.callBackAfterClose = (e) => {
      this.isOpenCellLocation = false;
      this.title_Cell = null;
    };
  }

  optionCallbackModal() {
    this.optionsQuantity.callBackAfterClose = (e) => {
      this.titlePrint = null;
      this.slot = '';
      this.isOpenModalQuantity = false;
    };
  }

  optionCallbackModalProduct() {
    this.options.callBackAfterClose = (e) => {
      this.product_name = '';
      this.isOpenModalProduct = false;
    };
  }

  optionCallbackModalSku() {
    this.optionsSKU.callBackAfterClose = (e) => {
      this.product_id = '';
      this.isOpenModalSKU = false;
    };
  }

  optionCallbackModalQRCode() {
    this.optionsQR.callBackAfterClose = (e) => {
      this.titlePrint = null;
      this.isOpenModalQR = false;
    };
  }

  optionCallbackModalContract() {
    this.optionsContract.callBackAfterClose = (e) => {
      this.contract_code = '';
      this.isOpenModalContract = false;
    };
  }

  optionCallbackModalPercentSlot() {
    this.optionsPercentSlot.callBackAfterClose = (e) => {
      this.isOpenModalPercentSlot = false;
    };
  }

  setModalAddOrEdit() {
    if (this.imExport_code) {
      this.getExportByimExportCode();
    } else {
      //this.addRowProduct('');
    }
  }

  //bug
  async getStockProduct(customer_id, contract_id) {
    let data = [];
    //Change API
    await this.stockAvailableService
      .apiStockAvailableCustomerIdStocksGet(
        customer_id,
        contract_id,
        '',
        '',
        '',
        1,
        1000
      )

      .toPromise()
      .then((res) => {
        if (res.success) {
          data = res.data.collection;
        }
      });
    return data;
  }

  async getSlotInfo() {
    let res = [];
    for (let i = 0; i < this.arrExport.length; i++) {
      let e = this.arrExport[i];
      let data = await this.slotService.apiSlotIdInfoGet(e.slot_id).toPromise();
      res.push({ ...data.data });
    }
    return res;
  }

  async getStockInfo() {
    let res = [];
    for (let i = 0; i < this.arrExport.length; i++) {
      let e = this.arrExport[i];
      let data = await this.skuService.apiSkuIdGet(e.sku_id).toPromise();
      res.push({ ...data.data });
    }
    return res;
  }

  titleByImExportCode(item) {
    if (item.is_transfer_car === true) {
      this.isTransferCar = true;
      this.titleImport = 'PHIẾU SANG XE';
    } else {
      this.isTransferCar = false;
      this.titleImport = 'PHIẾU XUẤT HÀNG';
    }
  }

  ///check3
  getExportByimExportCode() {
    this.importExportHistoryService
      .apiImportExportHistoryCodeGet(this.imExport_code)
      .subscribe(async (res) => {
        if (res.success) {
          this.arrExport = res.data.collection;
          this.titleByImExportCode(res.data.collection[0]);
          if (this.arrExport.length >= 1) {
            for (let i = 0; i < this.arrExport.length; i++) {
              if (i + 1 === this.arrExport.length) {
                this.listImportCode += this.arrExport[i].import_code
                  ? this.arrExport[i].import_code
                  : '';
              } else {
                this.listImportCode += this.arrExport[i].import_code
                  ? this.arrExport[i].import_code
                  : '' + '|';
              }
            }
          }

          //form group
          this.arrExport.forEach((item) => {
            this.formExport.setValue({
              note: item.note,
              imExportCodeHis: this.listImportCode,
              issue_date: item.issue_date
                ? moment(item.issue_date).format('DD/MM/YYYY HH:mm')
                : '',
              imExportCode: item.imExportCode,
              //supplier_name: item.supplier_name,
              batch_code: item.batch_code,
              //container_code: item.container_code,
              implementer_id:
                item.implementer_id != null ? item.implementer_id : '',
              customer_id: item.customer_id != null ? item.customer_id : '',
              contract_id: item.contract_id != null ? item.contract_id : '',
              contract_code:
                item.contract_code != null ? item.contract_code : '',
              receive_car: item.receive_car,
              receiver: item.receiver,
              receiver_id: item.receiver_id != null ? item.receiver_id : '',
              seller_id: item.seller_id != null ? item.seller_id : '',
              container_number_import: item.container_number_import,
              unit_price: 0,
            });
            this.formExport.patchValue({
              imExportCodeHis: this.listImportCode,
            });
            this.create_at = item.created_at;
            this.is_draft = item.is_draft;
            this.created_by = item.extend_creator_name;
            this.created_avatar = item.extend_creator_avatar;
            this.is_checked = item.is_checked;
            this.is_sale = item.is_sale;
            //implementer
            this.implementer.avatar = item.extend_implementer_avatar;
            this.implementer.full_name = item.extend_implementer_name;
            //customer
            this.customer.id = item.customer_id;
            this.customer.full_name = item.extend_customer_name;
            this.customer.avatar = item.extend_customer_avatar;
            //Receiver
            this.receiver.id = item.receiver_id;
            this.receiver.full_name = item.extend_receiver_name;
            this.receiver.avatar = item.extend_receiver_avatar;
            //Seller
            this.seller.id = item.seller_id;
            this.seller.full_name = item.extend_seller_name;
            this.seller.avatar = item.extend_seller_avatar;
            //contract
            this.contract.code =
              item.contract_code != null ? item.contract_code : '';
            this.contract.id = item.contract_id != null ? item.contract_id : '';
          });

          //list of items
          let groups = this.arrExport.reduce((groups, item) => {
            const group = groups[item.group_number] || [];
            group.push(item);
            groups[item.group_number] = group;
            return groups;
          }, {});

          let obj: any;
          Object.values(groups).forEach(async (element) => {
            obj = element[0];
            //this.formExport.controls['bacth_code'].setValue(obj.batch_code);
            obj.selectProductObj = {};
            obj.selectSkuObj = {};
            obj.stock = 0;
            obj.currentStock = 0;
            obj.totalQuantity = 0;
            obj.selectProductObj.id = obj.product_id;
            obj.net_weight = obj.net_weigth;
            obj.selectProductObj.name = obj.product_name;
            obj.selectProductObj.code = obj.product_code;
            obj.selectSkuObj.id = obj.sku_id;
            obj.selectSkuObj.sku_code = obj.sku_code;
            obj.selectHashtag = [];
            obj.selectProduct = obj.product_id;
            obj.selectSku = obj.sku_id;
            obj.container_number_import = obj.container_number_import;
            obj.unit_price = obj.unit_price;
            obj.real_net_weight = obj.real_net_weight
              ? obj.real_net_weight
              : obj.net_weigth;

            let arr = element as any[];

            arr.forEach(async (item) => {
              this.getStockAvailableForSlot(
                obj,
                item,
                item.customer_id,
                item.contract_id
              );
            });

            // obj.stock = await this.getStockSkuId(
            //   element[0].sku_id,
            //   element[0].product_id,
            //   element[0].contract_id
            // );

            // obj.currentStock = obj.stock + obj.totalQuantity;
            this.arrProducts.push(obj);
          });

          this.allPrint = [
            { title: 'QR code' },
            { title: this.isTransferCar ? 'Lệnh Sang Xe' : 'Lệnh Xuất' },
            { title: this.isTransferCar ? 'Phiếu Sang Xe' : 'Phiếu Xuất' },
          ];

          /**get slot tock */
          // Promise.all(
          //   this.arrProducts.map(async (item) => {
          //     //set stock
          //     item.stock = await this.getStockSkuId(
          //       item.sku_id,
          //       item.product_id,
          //       item.contract_id,
          //       item.inventory_id
          //     );
          //   })
          // ).then((res) => {});

          /** get current quantity */
          this.arrProducts.map(async (item) => {
            item.currentStock = item.stock + item.totalQuantity;
          });

          this.arrProducts = [...this.arrProducts];
          this.arrAllProducts = this.arrProducts;
          this.getAllContracts();
        }
      });
  }

  //bug
  // setStock(item) {
  //   this.skuService
  //     .apiSkuStockAvailableGet(
  //       this.inventoryId,
  //       item.sku_id,
  //       item.product_id,
  //       item.contract_id
  //     )
  //     .pipe(debounceTime(300))
  //     .subscribe((res) => {
  //       item.stock = res.data.stock;
  //     });
  // }

  ///check2
  //Xuất theo phiếu nhập
  getImportByimExportCode() {
    this.importExportHistoryService
      .apiImportExportHistoryByCodeGet(this.imExportCodeHis)
      .subscribe(async (res) => {
        if (res.success) {
          let arrEx = [];
          arrEx = res.data;
          //Get new slot
          // let lstSlot = [];
          // for (let i = 0; i < arrEx.length; i++) {
          //   let e = arrEx[i];
          //   let data = await this.slotService
          //     .apiSlotIdInfoGet(e.slot_id)
          //     .toPromise();
          //   lstSlot.push({ ...data.data });
          // }
          arrEx.forEach((item) => {
            this.formExport.setValue({
              note: item.note,
              imExportCodeHis: this.imExportCodeHis,
              issue_date: item.issue_date
                ? moment(item.issue_date).format('DD/MM/YYYY HH:mm')
                : '',
              imExportCode: item.imExportCode,
              //supplier_name: item.supplier_name,
              batch_code: item.batch_code,
              // container_code: item.container_code,
              implementer_id:
                item.implementer_id != null ? item.implementer_id : '',
              customer_id: item.customer_id != null ? item.customer_id : '',
              contract_id: item.contract_id != null ? item.contract_id : '',
              contract_code:
                item.contract_code != null ? item.contract_code : '',
              receive_car: item.receive_car,
              receiver: item.receiver,
              receiver_id: item.receiver_id != null ? item.receiver_id : '',
              seller_id: item.seller_id != null ? item.seller_id : '',
              container_number_import: item.container_number_import,
              unit_price: 0,
            });
            this.create_at = item.created_at;
            //this.is_draft = item.is_draft;
            //this.created_by = item.extend_creator_name;
            //this.created_avatar = item.extend_creator_avatar;
            //this.is_checked = item.is_checked;
            //implementer
            // this.implementer.avatar = item.extend_implementer_avatar;
            // this.implementer.full_name = item.extend_implementer_name;
            //customer
            // this.customer.id = item.customer_id;
            // this.customer.full_name = item.extend_customer_name;
            // this.customer.avatar = item.extend_customer_avatar;
            //contract
            // this.contract.code =
            //   item.contract_code != null ? item.contract_code : '';
            // this.contract.id = item.contract_id != null ? item.contract_id : '';
            //totalStock += item.quantity;
            this.stockCustomerAndContract(
              this.customer.id,
              this.contract.id,
              item.product_id
            );
          });
          let groups = arrEx.reduce((groups, item) => {
            const group = groups[item.group_number] || [];
            group.push(item);
            groups[item.group_number] = group;
            return groups;
          }, {});

          let obj: any;
          Object.values(groups).forEach(async (element) => {
            obj = element[0];
            this.formExport.controls['batch_code'].setValue(obj.batch_code);
            obj.net_weight = obj.net_weigth;
            obj.selectProductObj = {};
            obj.selectSkuObj = {};
            obj.selectProductObj.id = obj.product_id;
            obj.selectProductObj.name = obj.product_name;
            obj.selectProductObj.code = obj.product_code;
            obj.selectSkuObj.id = obj.sku_id;
            obj.selectSkuObj.sku_code = obj.sku_code;
            obj.selectHashtag = [];
            obj.selectProduct = obj.product_id;
            obj.selectSku = obj.sku_id;
            obj.extend_supplier_name = obj.supplier_id;
            obj.import_code = obj.imExportCode;
            obj.real_net_weight = obj.net_weigth;
            obj.stock = 0;
            obj.totalQuantity = 0;
            obj.currentStock = 0;
            obj.container_number_import = obj.container_number_import;
            obj.unit_price = obj.unit_price;

            let arr = element as any[];

            arr.forEach((item) => {
              // data = lstSlot.find((c) => c.id == item.slot_id);
              // this.allProducts.forEach((item2) => {
              //   if (
              //     item.product_id == item2.id &&
              //     item.sku_id &&
              //     item2.sku_id
              //   ) {
              //     item2.slot.forEach((item3) => {
              //       if (item3.imExportCode) {
              //         if (
              //           item3.slot_id == item.slot_id &&
              //           item3.sku_id == item.sku_id &&
              //           item3.slot_quantity > 0 &&
              //           item3.imExportCode === item.imExportCode
              //         ) {
              //           total += item3.slot_quantity;
              //           obj.selectHashtag.push({
              //             id: item3.slot_id,
              //             // row_id: item.id,
              //             code: item3.slot_code,
              //             quantity: item3.slot_quantity,
              //             used_percent: item3.use_percent,
              //             stock_slot_sku: item3.slot_quantity,
              //             zone_id: item3.zone_id,
              //             new_value: null,
              //             imExportCode: item3.imExportCode,
              //           });
              //         }
              //       } else {
              //         if (
              //           item3.slot_id == item.slot_id &&
              //           item3.sku_id == item.sku_id &&
              //           item3.slot_quantity > 0
              //         ) {
              //           total += item3.slot_quantity;
              //           obj.selectHashtag.push({
              //             id: item3.slot_id,
              //             // row_id: item.id,
              //             code: item3.slot_code,
              //             quantity: item3.slot_quantity,
              //             used_percent: item3.use_percent,
              //             stock_slot_sku: item3.slot_quantity,
              //             zone_id: item3.zone_id,
              //             new_value: null,
              //             imExportCode: item3.imExportCode,
              //           });
              //         }
              //       }
              //     });
              //   }
              // });
              this.getStockAvailableForSlot(
                obj,
                item,
                item.customer_id,
                item.contract_id
              );
            });
            this.arrProducts.push(obj);
            this.editItem(obj);
          });
          /**get slot tock */

          // Promise.all(
          //   this.arrProducts.forEach(async (item) => {
          //     item.stock = await this.getStockSkuId(
          //       item.sku_id,
          //       item.product_id,
          //       item.contract_id,
          //       item.inventory_id
          //     );
          //   })
          // ).then((res) => {});

          this.arrAllProducts = this.arrProducts;
          this.arrProducts = [...this.arrProducts];
        }
      });
  }

  closeModal(event = false) {
    if (this.dataFromContainer || this.isOpenFormModalDashBoard) {
      this.closeModalExport.emit({ success: event, isCloseModal: true });
    } else {
      this.closeModalExport.emit({ success: event, isCloseModal: false });
    }
  }

  changeTitle(event = false) {
    this.changeTitleModal.emit({ success: event });
  }

  getAllProducts() {
    this.productService.apiProductGet('', '', 1, 10000).subscribe((data) => {
      if (data.success == true) {
        this.allProducts = data.data.collection;
      }
    });
  }

  //Implementer
  getAllImplementers() {
    this.userService
      .apiUsersGet(
        this.filterImplementer + ',extend_user_role@=STAFF|ADMIN',
        '',
        1,
        this.pageImplementer
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allImplemeters = [...data.data.collection];
          this.totalImplementer = data.data.total;
        }
      });
  }

  //Receiver
  getAllReceivers() {
    this.userService
      .apiUsersGet(
        this.filterReceiver + ',full_name!=Hoa Chu,extend_user_role@=CUSTOMER',
        '',
        1,
        this.pageReceiver
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allReceivers = [...data.data.collection];
          this.totalReceiver = data.data.total;
        }
      });
  }

  //Seller
  getAllSellers() {
    this.userService
      .apiUsersGet(
        this.filterSeller +
          ',full_name!=Hoa Chu,extend_user_role@=SELLER_STAFF',
        '',
        1,
        this.pageSeller
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allSellers = [...data.data.collection];
          this.totalSeller = data.data.total;
        }
      });
  }

  searchImplementer() {
    this.keyUpImplementer
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterImplementer = 'full_name@=*' + value;
        this.pageImplementer = 10;
        this.allImplemeters = [];
        this.getAllImplementers();
        this.clearSearchImplementer = value;
      });
  }

  searchReceiver() {
    this.keyUpReceiver
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterReceiver = 'full_name@=*' + value;
        this.pageReceiver = 10;
        this.allReceivers = [];
        this.getAllReceivers();
        this.clearSearchReceiver = value;
      });
  }

  searchSeller() {
    this.keyUpSeller
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterSeller = 'full_name@=*' + value;
        this.pageSeller = 10;
        this.allSellers = [];
        this.getAllSellers();
        this.clearSearchSeller = value;
      });
  }
  //Search import box
  searchImport() {
    this.keyUpSearchImport
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.keySearchImport = '' + value;
        this.getAllImportCode();
      });
  }
  //Clear Search import box
  clearSearch() {
    this.keySearchImport = '';
    this.getAllImportCode();
  }

  closeOutsideImplementer() {
    if (this.openImplementer) {
      this.openImplementer = false;
      this.clearSearchImplementer = null;
      this.filterImplementer = '';
      this.pageImplementer = 10;
      this.getAllImplementers();
    }
  }
  closeOutsideReceiver() {
    if (this.openReceiver) {
      this.openReceiver = false;
      this.clearSearchReceiver = null;
      this.filterReceiver = '';
      this.pageReceiver = 10;
      this.getAllReceivers();
    }
  }
  closeOutsideSeller() {
    if (this.openSeller) {
      this.openSeller = false;
      this.clearSearchSeller = null;
      this.filterSeller = '';
      this.pageSeller = 10;
      this.getAllSellers();
    }
  }

  chooseImplementer(item) {
    this.implementer.avatar = item.avatar;
    this.implementer.full_name = item.full_name;
    this.formExport.patchValue({
      implementer_id: item.id,
    });
    this.closeChooseImplementer();
  }
  chooseReceiver(item) {
    this.receiver.id = item.id;
    this.receiver.avatar = item.avatar;
    this.receiver.full_name = item.full_name;
    this.formExport.patchValue({
      receiver_id: item.id,
      receiver: item.full_name,
    });
    this.closeChooseReceiver();
  }

  chooseSeller(item) {
    this.seller.id = item.id;
    this.seller.avatar = item.avatar;
    this.seller.full_name = item.full_name;
    this.formExport.patchValue({
      seller_id: item.id,
    });
    this.closeChooseSeller();
  }

  closeChooseImplementer() {
    if (this.openImplementer) {
      this.openImplementer = false;
    }
  }

  closeChooseReceiver() {
    if (this.openReceiver) {
      this.openReceiver = false;
    }
  }

  closeChooseSeller() {
    if (this.openSeller) {
      this.openSeller = false;
    }
  }

  loadMoreImplementer() {
    this.pageImplementer += 10;
    this.getAllImplementers();
  }

  loadMoreReceiver() {
    this.pageReceiver += 10;
    this.getAllReceivers();
  }

  loadMoreSeller() {
    this.pageSeller += 10;
    this.getAllSellers();
  }

  //Customer
  getAllCustomers() {
    this.userService
      .apiUsersGet(
        this.filterCustomer + ',extend_user_role@=CUSTOMER',
        '',
        1,
        this.pageCustomer
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allCustomers = [...data.data.collection];
          this.totalCustomer = data.data.total;
        }
      });
  }

  searchCustomer() {
    this.keyUpCustomer
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterCustomer = 'full_name@=*' + value;
        this.textSearchCustomer = value;
        this.pageCustomer = 10;
        this.allCustomers = [];
        this.getAllCustomers();
        this.clearSearchCustomer = value;
      });
  }

  closeOutsideCustomer() {
    if (this.openCustomer) {
      this.openCustomer = false;
      this.clearSearchCustomer = null;
      this.filterCustomer = '';
      this.textSearchCustomer = '';
      this.pageCustomer = 10;
      this.getAllCustomers();
    }
  }

  chooseCustomer(item) {
    this.customer.full_name = item.full_name;
    this.customer.id = item.id;
    this.customer.avatar = item.avatar;
    this.formExport.patchValue({
      customer_id: item.id,
    });
    this.closeChooseCustomer();
    //this.chooseImplementer(item);
    this.getAllContracts();
    this.stockCustomerAndContract(item.id, null);
    this.contract.code = '';
  }

  closeChooseCustomer() {
    if (this.openCustomer) {
      this.openCustomer = false;
    }
  }

  loadMoreCustomer() {
    this.pageCustomer += 10;
    this.getAllCustomers();
  }

  //Contract
  getAllContracts() {
    this.contractService
      .apiContractGet(
        this.inventoryId,
        this.filterContract +
          ',customer_id==' +
          this.customer.id +
          ',extend_contract_status==Đang hoạt động',
        '',
        1,
        this.pageContract
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allContracts = [...data.data.collection];
          this.totalContract = data.data.total;
          this.chooseContract(data.data.collection[0]);
        }
      });
  }

  searchContract() {
    this.keyUpContract
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterContract = 'code@=*' + value;
        this.textSearchContract = value;
        this.pageContract = 10;
        this.allContracts = [];
        this.getAllContracts();
        this.clearSearchContract = value;
      });
  }

  searchImExportHistory() {
    this.keyUpImExportHis
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterImExportHis = 'importCode@=' + value;
        this.textSearchImExport = value;
        this.lstCodeImExport = [];
        //this.getAllCodeByUserID();
        this.clearSearchImExportHis = value;
      });
  }

  closeOutsideContract() {
    if (this.openContract) {
      this.openContract = false;
      this.clearSearchContract = null;
      this.filterContract = '';
      this.textSearchContract = '';
      this.pageContract = 10;
      this.getAllContracts();
    }
  }

  closeOutsideImExport() {
    if (this.openImExportCode) {
      this.openImExportCode = false;
      this.clearSearchImExportHis = null;
      this.filterImExportHis = '';
      this.textSearchImExport = '';
    }
  }

  chooseContract(item) {
    if (item?.id) {
      this.contract.id = item.id;
      this.contract.code = item.code;
      this.formExport.patchValue({
        contract_id: item.id,
        contract_code: item.code,
      });
      this.closeChooseContract();
      //this.getAllCodeByUserID();
      this.stockCustomerAndContract(this.customer.id, this.contract.id);
    }
  }

  chooseImExportCode(item) {
    this.imExportCodeHis = item;
    this.formExport.patchValue({
      imExportCodeHis: item,
    });
    //this.closeChooseImExportHistory();
    this.getImportByimExportCode();
  }

  stockCustomerAndContract(customer_id, contract_id?, product_id?) {
    this.stockAvailableService
      .apiStockAvailableCustomerIdStocksGet(customer_id, '', product_id)
      .subscribe((data) => {
        if (data.success) {
          this.lstproducts = data.data.collection;
          if (this.lstproducts.length > 0) {
            this.allProducts = [];
            this.lstproducts.forEach((item) => {
              this.allProducts.push({
                id: item.product_id,
                name: item.product_name,
                code: item.product_code,
                slot_id: item.slot[0].slot_id,
                slot_code: item.slot[0].slot_code,
                zone_id: item.slot[0].zone_id,
                used_percent: item.slot[0].use_percent,
                sku_id: item.slot[0].sku_id,
                sku_code: item.slot[0].sku_code,
                slot: item.slot,
              });
            });
            this.allProducts = [...this.allProducts];
          } else {
            this.getAllProducts();
          }
        }
      });
  }

  chooseSearchContract(item) {
    if (!this.isOpenModalContract) {
      if (this.customer.id) {
        this.isOpenModalContract = true;
        this.titleModalContract = 'Thêm mới hợp đồng';
        this.contract_code = item;
      } else {
        this.toastr.warnning('Vui lòng chọn khách hàng');
        return;
      }
    }
  }
  chooseSearchImExport(item) {}

  closeModalContract(data) {
    if (!data) {
      if (this.isOpenModalContract) {
        this.isOpenModalContract = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModalContract = false;
        this.contract_code = '';
        this.getAllContracts();
        this.toastr.success(data.message);
      } else {
        this.toastr.error(data.message);
      }
    }
  }

  closeChooseContract() {
    if (this.openContract) {
      this.openContract = false;
    }
  }

  closeChooseImExportHistory() {
    if (this.openImExportCode) {
      this.openImExportCode = false;
    }
  }

  loadMoreContract() {
    this.pageContract += 10;
    this.getAllContracts();
  }

  getCreator() {
    var user = JSON.parse(
      this.localStoreService.getFromToLocalStorage('userInfo')
    );
    this.created_by = user.name;
    this.created_avatar = user.avatar;
    let customers = {
      full_name: user.name,
      avatar: user.avatar,
      id: user.id,
    };
    this.chooseImplementer(customers);
  }

  getSlot(event) {
    if (!event) {
      this.arrHashtag = [];
      return;
    }
    this.filter = 'code@=*' + event;
    this.getAllSlots();
  }

  addFirstItem(el) {
    const filterArr = this.arrHashtag.filter((item) => {
      return !el.selectHashtag.find((p) => p.id == item.id);
    });

    if (filterArr[this.keyIndex]) {
      if (this.keyIndex == 0) {
        el.selectHashtag.push(filterArr[this.keyIndex]);
        this.keyIndex = 0;
      } else {
        this.keyIndex = 0;
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  doSomething(event: KeyboardEvent): void {
    if (event.code == 'ArrowUp' && this.keyIndex > 0) {
      this.keyIndex--;
    }
    if (
      event.code == 'ArrowDown' &&
      this.keyIndex < this.arrHashtag.length - 1
    ) {
      this.keyIndex++;
    }
  }

  getAllSlots() {
    this.slotService
      .apiSlotsGet(
        this.filter + ',used_percent<=100,used_percent>=1',
        '',
        1,
        10000,
        this.inventoryId
      )
      .pipe(debounceTime(300))
      .subscribe((data) => {
        if (data.success == true) {
          this.arrHashtag = [...data.data.collection];
        }
      });
  }

  ///check1
  getUnitNameAndAllSkusByProductId(event, element, index) {
    if (!this.contract.id) {
      this.contract.id = this.idContract;
    }
    if (!event) {
      element.product_code = '';
      element.product_name = '';
      element.unit_name = '';
      element.unit_code = '';
      this.arrProducts[index].allSkus = [];
      element.selectSku = '';
      element.sku_code = '';
      element.supplier_name = '';
      element.supplier_id = '';
      element.stock = '';
      element.net_weight = null;
      this.value_product_id = '';
      if (this.lstproducts.length > 0) {
        element.selectHashtag = [];
      }
      this.myTooltipProductCode.hide();
      this.myTooltipProductName.hide();
    } else {
      if (this.lstproducts.length > 0) {
        element.quantity = 0;
        element.stock = 0;
        element.selectProductObj = {};
        element.selectSkuObj = {};
        element.selectProductObj.id = event.id;
        element.selectProductObj.name = event.name;
        element.selectProductObj.code = event.code;
        element.product_code = event.code;
        element.product_name = event.name;
        element.sku_id = event.sku_id;
        element.sku_code = event.sku_code;
        this.value_product_id = event.id;
        this.productService.apiProductIdGet(event.id).subscribe((res) => {
          if (res.success) {
            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  element.unit_name = data.data.name;
                  element.unit_code = data.data.code;
                }
              });
          }
        });
        // this.skuService
        //   .apiSkuStockAvailableGet(
        //     this.inventoryId,
        //     event.sku_id,
        //     event.id,
        //     this.contract.id
        //   )
        //   .subscribe((res) => {
        //     if (res.success) {
        //       element.stock = res.data.stock;
        //       // element.quantity = res.data.stock ;
        //     }
        //   });
        this.stockAvailableService
          .apiStockAvailableGetNetweightGet(
            event.slot_id,
            this.contract.id,
            event.id,
            this.inventoryId,
            event.sku_id
          )
          .subscribe((res) => {
            if (res.success) {
              element.net_weight = res.data.net_weight;
            }
          });
        this.skuService
          .apiSkuGet('product_id==' + event.id, '', 1, 10000)
          .subscribe((response) => {
            if (response.success) {
              this.arrProducts[index].allSkus = response.data.collection;
            }
          });
        element.selectSku = event.sku_id;
        element.sku_code = event.sku_code;
        element.selectSkuObj.id = event.sku_id;
        element.selectSkuObj.sku_code = event.sku_code;

        this.skuService.apiSkuIdGet(event.sku_id).subscribe((res) => {
          if (res.success) {
            element.supplier_name = res.data.extend_supplier_name;
            element.extend_supplier_name = res.data.supplier_id;
            element.supplier_id = res.data.supplier_id;
          }
        });

        this.myTooltipProductCode.hide();
        this.myTooltipProductName.hide();

        element.selectHashtag = [];

        if (
          element.selectProduct == event.id &&
          element.sku_id == event.sku_id
        ) {
          event.slot.forEach((item) => {
            if (item.slot_quantity > 0) {
              element.quantity += item.slot_quantity;
              element.stock += item.slot_quantity;
              element.selectHashtag.push({
                id: item.slot_id,
                row_id: element.id,
                code: item.slot_code,
                quantity: item.slot_quantity,
                used_percent: item.use_percent,
                stock_slot_sku: item.slot_quantity,
                zone_id: item.zone_id,
                new_value: null,
              });
            }
          });
        }

        // let unique = [];
        // event.slot.forEach((value) => {
        //   if (!unique.includes(value.slot_id)) {
        //     unique.push(value.slot_id);
        //   }
        // });

        // let tempStock = 0;

        // for (let i = 0; i <= unique.length; i++) {
        //   for (let j = 0; j <= event.slot.length; j++) {
        //     if (
        //       unique[i] === event.slot[j].slot_id &&
        //       event.slot[j].sku_id === element.selectSku &&
        //       event.slot[j].slot_quantity > 0
        //     ) {
        //       element.selectHashtag.push({
        //         id: event.slot[j].slot_id,
        //         code: event.slot[j].slot_code,
        //         used_percent: event.slot[j].use_percent,
        //         zone_id: event.slot[j].zone_id,
        //         new_value: null,
        //         stock_slot_sku: event.slot[j].slot_quantity,
        //         quantity: event.slot[j].slot_quantity,
        //       });
        //       tempStock = tempStock + event.slot[j].slot_quantity;
        //       // break;
        //     }
        //   }
        // }
        // element.quantity = total;
        // event.slot.forEach((value) => {
        //   element.selectHashtag.push({
        //     id: value.slot_id,
        //     code: value.slot_code,
        //     used_percent: value.used_percent,
        //     zone_id: value.zone_id,
        //     new_value: null,
        //   });
        // });
      } else {
        element.selectProductObj = event;
        element.product_code = event.code;
        element.product_name = event.name;
        this.value_product_id = event.id;
        this.productService.apiProductIdGet(event.id).subscribe((res) => {
          if (res.success) {
            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  element.unit_name = data.data.name;
                  element.unit_code = data.data.code;
                }
              });
          }
        });

        this.skuService
          .apiSkuGet('product_id==' + event.id, '', 1, 10000)
          .subscribe((response) => {
            if (response.success) {
              this.arrProducts[index].allSkus = response.data.collection;
            }
          });
        element.selectSku = '';
        element.sku_code = '';
        element.supplier_name = '';
        element.supplier_id = '';
        element.stock = '';

        this.myTooltipProductCode.hide();
        this.myTooltipProductName.hide();
      }
    }
  }

  getSku(event, element) {
    if (!this.contract.id) {
      this.contract.id = this.idContract;
    }
    if (event) {
      element.selectSkuObj = event;
      element.sku_code = event.sku_code;
      element.supplier_name = event.extend_supplier_name;
      element.supplier_id = event.supplier_id;
      element.selectSku = event.id;
      element.extend_supplier_name = event.supplier_id;
      //element.net_weight = event.net_weigth;

      //old API get wrong stock
      // this.skuService.apiSkuIdGet(event.id).subscribe((res) => {
      //   if (res.success) {
      //     element.stock = res.data.stock;
      //     this.stock = element.stock;
      //   }
      // });
      // let tempStock = 0;
      this.stockAvailableService
        .apiStockAvailableCustomerIdStocksGet(
          this.customer.id,
          '',
          '',
          '',
          '',
          1,
          1000
        )
        .subscribe((res) => {
          if (res.success) {
            const temp = res.data.collection;

            temp.forEach((eleme) => {
              if (eleme.product_id === event.product_id) {
                element.quantity = 0;
                element.selectHashtag = [];

                for (let j = 0; j <= eleme.slot.length; j++) {
                  if (
                    eleme.slot[j].sku_id === event.id &&
                    eleme.slot[j].slot_quantity > 0
                  ) {
                    element.quantity += eleme.slot[j].slot_quantity;
                    element.selectHashtag.push({
                      id: eleme.slot[j].slot_id,
                      code: eleme.slot[j].slot_code,
                      used_percent: eleme.slot[j].use_percent,
                      zone_id: eleme.slot[j].zone_id,
                      stock_slot_sku: eleme.slot[j].slot_quantity,
                      new_value: null,
                      quantity: eleme.slot[j].slot_quantity,
                    });
                    // tempStock = tempStock + eleme.slot[j].slot_quantity;
                  }
                }
              }
            });
          }
        });
      element.stock = event.stock;
      // element.stock = teempStock;
      // element.quantity = tempStock;
      // this.stock = tempStock;
      this.myTooltipSkuCode.hide();

      // if (event.sku_id) {
      //   element.selectSku = event.sku_id;
      //   this.skuService
      //     .apiSkuStockAvailableGet(
      //       this.inventoryId,
      //       event.sku_id,
      //       event.id,
      //       this.contract.id
      //     )
      //     .subscribe((res) => {
      //       if (res.success) {
      //         element.stock = res.data.stock;
      //         element.quantity = res.data.stock;
      //         this.stock = element.stock;
      //       }
      //     });
      // } else {
      //   element.selectSku = event.id;
      //   this.skuService
      //     .apiSkuStockAvailableGet(
      //       this.inventoryId,
      //       event.id,
      //       event.product_id,
      //       this.contract.id
      //     )
      //     .subscribe((res) => {
      //       if (res.success) {
      //         element.stock = res.data.stock;
      //         element.quantity = res.data.stock;
      //         this.stock = element.stock;
      //       }
      //     });
      // }
    } else {
      element.stock = '';
      element.supplier_name = '';
      element.supplier_id = '';
      element.sku_code = '';
      this.myTooltipSkuCode.hide();
    }
  }

  addRowProduct(item) {
    let objProductId = {};
    let obj = {};
    let objSku = {};
    let objSlot = {};
    let objLstSlot = {};

    //item
    let objItem = {};
    let objSlotItem = {};
    let objLstSlotItem = {};
    let objSkuItem = {};
    let objProductIdItem = {};

    if (!item) {
      var itemProductId = '';
      var itemProductName = '';
      var itemProductCode = '';
      var itemUnit = '';
      var itemCodeUnit = '';
      var itemSku_code = '';
      var itemStock = '';
      var itemSupplier_name = '';
      var itemSupplier_id = '';

      if (this.idProduct) {
        itemProductId = this.idProduct;

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + itemProductId, '', 1, 10000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService.apiProductIdGet(itemProductId).subscribe((res) => {
          if (res.success) {
            itemProductName = res.data.name;
            itemProductCode = res.data.code;

            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  itemUnit = data.data.name;
                  itemCodeUnit = data.data.code;

                  if (this.skuId) {
                    this.skuService
                      .apiSkuIdGet(this.skuId)
                      .subscribe((response) => {
                        if (response.success) {
                          itemSku_code = response.data.sku_code;
                          itemStock = response.data.stock;
                          itemSupplier_name =
                            response.data.extend_supplier_name;
                          itemSupplier_id = response.data.supplier_id;

                          objSku = {
                            name: '',
                            code: '',
                            quantity: 1,
                            sku: '',
                            number: '',
                            isEdit: true,
                            selectProduct: itemProductId,
                            selectSku: this.skuId,
                            unit_name: itemUnit,
                            unit_code: itemCodeUnit,
                            selectHashtag: [],
                            selectProductObj: {
                              id: itemProductId,
                              name: itemProductName,
                              code: itemProductCode,
                            },
                            selectSkuObj: {
                              id: this.skuId,
                              sku_code: itemSku_code,
                            },
                            stock: itemStock,
                            product_name: itemProductName,
                            product_code: itemProductCode,
                            sku_code: itemSku_code,
                            supplier_name: itemSupplier_name,
                            supplier_id: itemSupplier_id,
                            batch_code: '',
                            isOpen: false,
                            allSkus: arrSKU,
                          };
                          this.arrProducts.push(objSku);
                          this.arrProducts = [...this.arrProducts];
                          this.changeDetectorRef.detectChanges();
                          this.myTooltipProductCode.hide();
                          this.myTooltipProductName.hide();
                        }
                      });
                  } else {
                    objProductId = {
                      name: '',
                      code: '',
                      quantity: 1,
                      sku: '',
                      number: '',
                      isEdit: true,
                      selectProduct: itemProductId,
                      selectSku: '',
                      unit_name: itemUnit,
                      unit_code: itemCodeUnit,
                      selectHashtag: [],
                      supplier_name: '',
                      supplier_id: '',
                      selectProductObj: {
                        id: itemProductId,
                        name: itemProductName,
                        code: itemProductCode,
                      },
                      selectSkuObj: {},
                      stock: '',
                      product_name: itemProductName,
                      product_code: itemProductCode,
                      sku_code: itemSku_code,
                      batch_code: '',
                      isOpen: false,
                      allSkus: arrSKU,
                    };

                    this.arrProducts.push(objProductId);
                    this.arrProducts = [...this.arrProducts];
                    this.changeDetectorRef.detectChanges();
                    this.myTooltipProductCode.hide();
                    this.myTooltipProductName.hide();
                  }
                }
              });
          }
        });
      }

      // else if (this.dataFromContainer) {
      //   let arrSKU = []
      //   this.skuService
      //     .apiSkuGet('product_id==' + item.id, '', 1, 1000)
      //     .subscribe((response) => {
      //       if (response.success) {
      //         arrSKU = response.data.collection;
      //       }
      //     });

      //   objSlot = {
      //     name: '',
      //     code: '',
      //     quantity: 1,
      //     sku: '',
      //     number: '',
      //     isEdit: true,
      //     selectProduct: '',
      //     selectSku: '',
      //     unit_name: '',
      //     unit_code: '',
      //     selectHashtag: [{
      //       id: this.dataFromContainer.id,
      //       code: this.dataFromContainer.code,
      //       used_percent: this.dataFromContainer.used_percent,
      //       zone_id: this.dataFromContainer.zone_id,
      //       new_value: null
      //     }],
      //     supplier_name: '',
      //     supplier_id: '',
      //     stock: '',
      //     batch_code: '',
      //     isOpen: false,
      //     allSkus: arrSKU
      //   }

      //   this.arrProducts.push(objSlot);
      //   this.arrProducts = [...this.arrProducts];
      //   this.changeDetectorRef.detectChanges();
      // }

      // else if (this.lstSlotSelected) {
      //   var arry = [];
      //   this.lstSlotSelected.forEach(element => {
      //     arry.push({
      //       id: element.id,
      //       code: element.code,
      //       used_percent: element.used_percent,
      //       zone_id: element.zone_id,
      //       new_value: null
      //     })
      //   });

      //   let arrSKU = []
      //   this.skuService
      //     .apiSkuGet('product_id==' + item.id, '', 1, 1000)
      //     .subscribe((response) => {
      //       if (response.success) {
      //         arrSKU = response.data.collection;
      //       }
      //     });

      //   objLstSlot = {
      //     name: '',
      //     code: '',
      //     quantity: 1,
      //     sku: '',
      //     number: '',
      //     isEdit: true,
      //     selectProduct: '',
      //     selectSku: '',
      //     unit_name: '',
      //     unit_code: '',
      //     selectHashtag: arry,
      //     supplier_name: '',
      //     supplier_id: '',
      //     batch_code: '',
      //     isOpen: false,
      //     allSkus: arrSKU
      //   }

      //   this.arrProducts.push(objLstSlot);
      //   this.arrProducts = [...this.arrProducts];
      //   this.changeDetectorRef.detectChanges();
      // }
      else {
        obj = {
          name: '',
          code: '',
          quantity: 1,
          unit: '',
          sku: '',
          number: '',
          isEdit: true,
          selectProduct: '',
          selectSku: '',
          unit_name: '',
          unit_code: '',
          selectHashtag: [],
          stock: '',
          supplier_name: '',
          supplier_id: '',
          batch_code: '',
          isOpen: false,
          allSkus: [],
        };

        this.arrProducts.push(obj);
        this.arrProducts = [...this.arrProducts];
        this.changeDetectorRef.detectChanges();
      }
    } else {
      var itemProductIdItem = '';
      var itemProductNameItem = '';
      var itemProductCodeItem = '';
      var itemUnitItem = '';
      var itemCodeUnitItem = '';
      var itemSku_codeItem = '';
      var itemStockItem = '';
      var itemSupplier_nameItem = '';
      var itemSupplier_idItem = '';

      if (this.idProduct) {
        itemProductIdItem = this.idProduct;

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + itemProductIdItem, '', 1, 10000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService
          .apiProductIdGet(itemProductIdItem)
          .subscribe((res) => {
            if (res.success) {
              itemProductNameItem = res.data.name;
              itemProductCodeItem = res.data.code;

              this.unitService
                .apiUnitIdGet(res.data.unit_id)
                .subscribe((data) => {
                  if (data.success) {
                    itemUnitItem = data.data.name;
                    itemCodeUnitItem = data.data.code;

                    if (this.skuId) {
                      this.skuService
                        .apiSkuIdGet(this.skuId)
                        .subscribe((response) => {
                          if (response.success) {
                            itemSku_codeItem = response.data.sku_code;
                            itemStockItem = response.data.stock;
                            itemSupplier_nameItem =
                              response.data.extend_supplier_name;
                            itemSupplier_idItem = response.data.supplier_id;

                            objSkuItem = {
                              name: '',
                              code: '',
                              quantity: 1,
                              sku: '',
                              number: '',
                              isEdit: true,
                              selectProduct: itemProductIdItem,
                              selectSku: this.skuId,
                              unit_name: itemUnitItem,
                              unit_code: itemCodeUnitItem,
                              selectHashtag: [],
                              selectProductObj: {
                                id: itemProductIdItem,
                                name: itemProductNameItem,
                                code: itemProductCodeItem,
                              },
                              selectSkuObj: {
                                id: this.skuId,
                                sku_code: itemSku_codeItem,
                              },
                              stock: itemStockItem,
                              product_name: itemProductNameItem,
                              product_code: itemProductCodeItem,
                              sku_code: itemSku_codeItem,
                              supplier_name: itemSupplier_nameItem,
                              supplier_id: itemSupplier_idItem,
                              batch_code: '',
                              isOpen: false,
                              allSkus: arrSKU,
                            };
                            this.arrProducts.push(objSkuItem);
                            this.arrProducts = [...this.arrProducts];
                            this.changeDetectorRef.detectChanges();
                          }
                        });
                    } else {
                      objProductIdItem = {
                        name: '',
                        code: '',
                        quantity: 1,
                        sku: '',
                        number: '',
                        isEdit: true,
                        selectProduct: itemProductIdItem,
                        selectSku: '',
                        unit_name: itemUnitItem,
                        unit_code: itemCodeUnitItem,
                        selectHashtag: [],
                        supplier_name: '',
                        supplier_id: '',
                        selectProductObj: {
                          id: itemProductIdItem,
                          name: itemProductNameItem,
                          code: itemProductCodeItem,
                        },
                        selectSkuObj: {},
                        stock: '',
                        product_name: itemProductNameItem,
                        product_code: itemProductCodeItem,
                        sku_code: itemSku_codeItem,
                        batch_code: '',
                        isOpen: false,
                        allSkus: arrSKU,
                      };

                      this.arrProducts.push(objProductIdItem);
                      this.arrProducts = [...this.arrProducts];
                      this.changeDetectorRef.detectChanges();
                    }
                  }
                });
            }
          });
      }

      // else if (this.dataFromContainer) {
      //   let arrSKU = []
      //   this.skuService
      //     .apiSkuGet('product_id==' + item.id, '', 1, 1000)
      //     .subscribe((response) => {
      //       if (response.success) {
      //         arrSKU = response.data.collection;
      //       }
      //     });

      //   this.productService.apiProductIdGet(item.id).subscribe((res) => {
      //     if (res.success) {
      //       this.unitService.apiUnitIdGet(res.data.unit_id).subscribe((data) => {
      //         if (data.success) {
      //           this.unit_code = data.data.code;
      //         }

      //         this.value_product_id = item.id;
      //         objSlotItem = {
      //           quantity: 1,
      //           isEdit: true,
      //           selectProduct: item.id,
      //           unit_name: item.extend_unit_name,
      //           unit_code: this.unit_code,
      //           selectHashtag: [{
      //             id: this.dataFromContainer.id,
      //             code: this.dataFromContainer.code,
      //             used_percent: this.dataFromContainer.used_percent,
      //             zone_id: this.dataFromContainer.zone_id,
      //             new_value: null
      //           }],
      //           supplier_name: '',
      //           supplier_id: '',
      //           stock: '',
      //           selectProductObj: {
      //             id: item.id,
      //             name: item.name,
      //             code: item.code
      //           },
      //           selectSkuObj: {},
      //           product_name: item.name,
      //           product_code: item.code,
      //           batch_code: '',
      //           isOpen: false,
      //           allSkus: arrSKU
      //         }

      //         this.arrProducts.push(objSlotItem);
      //         this.arrProducts = [...this.arrProducts];
      //         this.changeDetectorRef.detectChanges();
      //       })
      //     }
      //   })
      // }

      // else if (this.lstSlotSelected) {
      //   var arry = [];
      //   this.lstSlotSelected.forEach(element => {
      //     arry.push({
      //       id: element.id,
      //       code: element.code,
      //       used_percent: element.used_percent,
      //       zone_id: element.zone_id,
      //       new_value: null
      //     })
      //   });

      //   let arrSKU = []
      //   this.skuService
      //     .apiSkuGet('product_id==' + item.id, '', 1, 1000)
      //     .subscribe((response) => {
      //       if (response.success) {
      //         arrSKU = response.data.collection;
      //       }
      //     });

      //   this.productService.apiProductIdGet(item.id).subscribe((res) => {
      //     if (res.success) {
      //       this.unitService.apiUnitIdGet(res.data.unit_id).subscribe((data) => {
      //         if (data.success) {
      //           this.unit_code = data.data.code;
      //         }

      //         this.value_product_id = item.id;
      //         objLstSlotItem = {
      //           unit: item.unit_id,
      //           quantity: 1,
      //           isEdit: true,
      //           selectProduct: item.id,
      //           unit_name: item.extend_unit_name,
      //           unit_code: this.unit_code,
      //           selectHashtag: arry,
      //           selectProductObj: {
      //             id: item.id,
      //             name: item.name,
      //             code: item.code
      //           },
      //           selectSkuObj: {},
      //           stock: '',
      //           product_name: item.name,
      //           product_code: item.code,
      //           supplier_name: '',
      //           supplier_id: '',
      //           batch_code: '',
      //           isOpen: false,
      //           allSkus: arrSKU
      //         }

      //         this.arrProducts.push(objLstSlotItem);
      //         this.arrProducts = [...this.arrProducts];
      //         this.changeDetectorRef.detectChanges();
      //       })
      //     }
      //   })
      // }
      else {
        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + item.id, '', 1, 10000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService.apiProductIdGet(item.id).subscribe((res) => {
          if (res.success) {
            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  this.unit_code = data.data.code;
                }

                this.value_product_id = item.id;
                objItem = {
                  unit: item.unit_id,
                  quantity: 1,
                  isEdit: true,
                  selectProduct: item.id,
                  unit_name: item.extend_unit_name,
                  unit_code: this.unit_code,
                  selectHashtag: [],
                  selectProductObj: {
                    id: item.id,
                    name: item.name,
                    code: item.code,
                  },
                  selectSkuObj: {},
                  stock: '',
                  product_name: item.name,
                  product_code: item.code,
                  supplier_name: '',
                  supplier_id: '',
                  batch_code: '',
                  isOpen: false,
                  allSkus: arrSKU,
                };

                this.arrProducts.push(objItem);
                this.arrProducts = [...this.arrProducts];
                this.changeDetectorRef.detectChanges();
              });
          }
        });
      }
    }
  }

  ///note
  addRowProductByQRCode(item) {
    let obj = {};

    this.sku_codeIdQRCode = this.allSkus.find(
      (p) => p.sku_code == item.sku_code
    ).id;

    if (!this.arrHashtag.find((x) => x.id == item.slot)) {
      this.toastr.error('Không tìm thấy ô trong kho hàng này');
      return;
    } else {
      this.slotQRCode = this.arrHashtag.find((x) => x.id == item.slot).code;
      this.usedpercentQRCode = this.arrHashtag.find(
        (x) => x.id == item.slot
      ).used_percent;
      this.zoneidQRCode = this.arrHashtag.find(
        (x) => x.id == item.slot
      ).zone_id;
    }

    this.skuService
      .apiSkuIdGet(this.sku_codeIdQRCode)
      .pipe(debounceTime(300))
      .subscribe((res) => {
        if (res.success) {
          this.stockQRCode = res.data.stock;
          this.productIdQRCode = res.data.product_id;
          this.productNameQRCode = res.data.extend_product_name;
          this.supplierNameQRCode = res.data.extend_supplier_name;
          this.supplierIdQRCode = res.data.supplier_id;
        }

        this.value_product_id = this.productIdQRCode;

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + this.productIdQRCode, '', 1, 10000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService
          .apiProductIdGet(this.productIdQRCode)
          .pipe(debounceTime(300))
          .subscribe((response) => {
            if (response.success) {
              this.productCodeQRCode = response.data.code;

              this.unitService
                .apiUnitIdGet(response.data.unit_id)
                .pipe(debounceTime(300))
                .subscribe((data) => {
                  if (data.success) {
                    this.unitNameQRCode = data.data.name;
                    this.unitCodeQRCode = data.data.code;
                  }

                  obj = {
                    quantity: 1,
                    isEdit: true,
                    unit_name: this.unitNameQRCode,
                    unit_code: this.unitCodeQRCode,
                    selectHashtag: [
                      {
                        id: item.slot,
                        code: this.slotQRCode,
                        used_percent: this.usedpercentQRCode,
                        zone_id: this.zoneidQRCode,
                        new_value: null,
                      },
                    ],
                    selectProduct: this.productIdQRCode,
                    product_id: this.productIdQRCode,
                    product_name: this.productNameQRCode,
                    product_code: this.productCodeQRCode,
                    selectProductObj: {
                      id: this.productIdQRCode,
                      name: this.productNameQRCode,
                      code: this.productCodeQRCode,
                    },
                    selectSkuObj: {
                      id: this.sku_codeIdQRCode,
                      sku_code: item.sku_code,
                    },
                    selectSku: this.sku_codeIdQRCode,
                    sku_code: item.sku_code,
                    stock: this.stockQRCode,
                    supplier_name: this.supplierNameQRCode,
                    supplier_id: this.supplierIdQRCode,
                    batch_code: item.batch_code,
                    isOpen: false,
                    allSkus: arrSKU,
                  };

                  if (
                    this.arrProducts[0].selectProduct ||
                    this.arrProducts[0].selectHashtag.length > 0
                  ) {
                    this.arrProducts.push(obj);
                  } else {
                    this.arrProducts[0] = obj;
                  }

                  this.arrProducts = [...this.arrProducts];
                  this.changeDetectorRef.detectChanges();
                });
            }
          });
      });
  }

  cancelEdit(i, item) {
    item.isEdit = false;

    if (
      item.selectProduct == [] ||
      item.unit_name == '' ||
      item.selectSku == [] ||
      item.supplier_name == '' ||
      item.selectHashtag == []
    ) {
      this.arrProducts.splice(i, 1);
      this.arrProducts = [...this.arrProducts];
    }
  }

  editItem(item) {
    item.isEdit = true;
    let tempSupplierID = item.supplier_id;
    if (this.imExportCodeHis) {
      this.value_product_id = item.selectProductObj.id;
      this.skuService
        .apiSkuGet('product_id==' + item.selectProductObj.id, '', 1, 10000)
        .subscribe((response) => {
          if (response.success) {
            item.allSkus = response.data.collection;
          }
        });
    }
    if (this.imExport_code) {
      this.value_product_id = item.selectProductObj.id;
      this.skuService
        .apiSkuGet('product_id==' + item.selectProductObj.id, '', 1, 10000)
        .subscribe((response) => {
          if (response.success) {
            item.allSkus = response.data.collection;
            item.extend_supplier_name = tempSupplierID;
          }
        });
    }
  }

  saveItem(item, index) {
    if (item.is_draft == false && this.imExport_code) {
      // if (item.totalQuantity > item.currentStock) {
      //   this.toastr.warnning(
      //     'Số lượng xuất phải nhỏ hơn số lượng tồn thực tế :' +
      //       item.currentStock
      //   );
      //   return;
      // }
    } else {
      if (item.totalQuantity > item.stock) {
        this.toastr.warnning('Số lượng xuất phải nhỏ hơn số lượng tồn');
        return;
      }
    }

    // if (item.quantity > item.stock) {
    //   this.toastr.warnning('Số lượng xuất phải nhỏ hơn số lượng tồn');
    //   return;
    // }else

    if (!this.isOpenModalPercentSlot) {
      this.listExportSlots = [];

      item.selectHashtag.forEach((element) => {
        this.listExportSlots.push({
          id: element.id,
          code: element.code,
          zone_id: element.zone_id,
          used_percent: element.used_percent,
          new_value: element.new_value
            ? element.new_value
            : element.used_percent
            ? Math.round(
                element.used_percent *
                  (element.quantity / element.stock_slot_sku)
              )
            : 0,
          quantityImport: element.stock_slot_sku,
          check: false,
          quantitySlot: element.quantity,
          currentQuantitySlotExport: element.currentQuantitySlotExport,
        });
      });
      Object.assign(this.listExportSlots, {
        index: index,
        totalQuantity: item.totalQuantity,
        isEditExport:
          item.is_draft == false && this.imExport_code ? true : false,
      });
      if (this.isMobile) {
        this.isOpenModalPercentSlotBottom = true;
      } else {
        this.isOpenModalPercentSlot = true;
      }
      this.titleModalPercentSlot = 'Cập nhật phần trăm, số lượng mỗi ô';
      this.optionsPercentSlot.styleClass = 'w-8/12';
    }
  }

  removeItem(i) {
    let index = this.lstTilteButton.indexOf(this.arrProducts[i].imExportCode);
    let title = 'Xóa hàng';
    let content = 'Bạn có chắc chắn muốn xóa hàng này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.arrProducts.splice(i, 1);
          this.arrProducts = [...this.arrProducts];
          this.confirmService.close();
          this.toastr.success('Xóa hàng thành công');
          this.lstTilteButton.splice(index, 1);
          this.title_button = '';
          if (this.lstTilteButton.length > 0) {
            this.lstTilteButton.forEach((ele) => {
              this.title_button += ele + ' ';
            });
          } else {
            this.title_button = 'Chọn phiếu nhập';
          }
        } else {
          this.confirmService.close();
        }
      });
  }

  getDateTime(event) {
    this.issuedate = event;
  }

  onSelect() {
    // let slot = slotArr.find((p) => p.id == item.id);
    // if (!this.isOpenModalQuantity) {
    //   this.isOpenModalQuantity = true;
    //   this.titleModalQuantity = 'Cài đặt số lượng';
    //   this.optionsQuantity.styleClass = 'w-3/12';
    //   this.slot = slot;
    // }
  }

  closeModalQuantity(data: any) {
    if (!data) {
      if (this.isOpenModalQuantity) {
        this.isOpenModalQuantity = false;
        this.slot = '';
      }
    }
  }

  valueQuantity(event) {
    this.itemQuantity = event;
  }

  getPer(total, length, isLast) {
    return isLast
      ? total - Math.floor(total / length) * (length - 1)
      : Math.floor(total / length);
  }

  quantityChange(item) {
    let per = 0;
    item.selectHashtag.forEach((element, index) => {
      per = this.getPer(
        item.totalQuantity,
        item.selectHashtag.length,
        index == item.selectHashtag.length - 1
      );
      element.quantity = per;
    });
  }

  createObject(isDraft, isCheck, objExport) {
    let valid = true;

    if (this.arrProducts.length == 0) {
      this.toastr.warnning('Vui lòng thêm sản phẩm');
      valid = false;
      return false;
    }

    let tempNumb = 1;
    this.arrProducts.forEach((item) => {
      if (item.is_draft == true || item.is_draft == null) {
        if (!isCheck) {
          if (item.stock < item.quantity) {
            this.toastr.warnning('Số lượng xuất phải nhỏ hơn số lượng tồn');
            valid = false;
            return false;
          }
        }
      }
      if (item.selectHashtag.length == 0) {
        this.toastr.warnning('Vui lòng chọn số ô');
        valid = false;
      }

      if (!item.selectSku) {
        this.toastr.warnning('Vui lòng chọn sku');
        valid = false;
      }

      if (!item.selectProduct) {
        this.toastr.warnning('Vui lòng chọn hàng hóa');
        valid = false;
      }

      if (item.quantity === 0) {
        this.toastr.warnning('Số lượng phải lớn hơn 0');
        valid = false;
      }

      let usage = 0;
      let numOfNoQuantity = 0;
      let per = 0;

      item.selectHashtag.forEach((element) => {
        if (!element.quantity) {
          numOfNoQuantity++;
        } else {
          usage += element.quantity;
        }
      });
      // if (!isCheck) {
      //   if (usage > item.stock) {
      //     this.toastr.warnning('Vui lòng nhập số lượng nhỏ hơn tồn kho');
      //     valid = false;
      //     return false;
      //   }
      // }
      if (!valid) return false;

      item.selectHashtag.forEach((element, index) => {
        per = this.getPer(
          item.quantity - usage,
          numOfNoQuantity,
          index == item.selectHashtag.length - 1
        );
        element.quantity = !!element.quantity ? element.quantity : per;

        let obj = {
          //form group
          isImExported: false,

          /// lưu nháp
          is_draft: isDraft,
          //

          // kiểm kho
          is_checked: isCheck,
          //

          container_code: item.container_code,
          note: this.formExport.value.note,
          issue_date: this.issuedate ? this.issuedate : moment(),
          implementer_id: this.formExport.value.implementer_id,
          customer_id: this.formExport.value.customer_id,
          contract_id: item.contract_id,
          contract_code: item.contract_code,
          inventory_id: item.inventory_id,

          //list of items

          // id
          id: element?.row_id ? element?.row_id : null,

          product_id: item.selectProductObj.id,
          product_name: item.selectProductObj.name,
          product_code: item.selectProductObj.code,
          quantity: element.quantity,
          unit_name: item.unit_name,
          unit_code: item.unit_code,
          batch_code: this.formExport.value.batch_code,
          sku_id: item.selectSkuObj.id,
          sku_code: item.selectSkuObj.sku_code,
          supplier_name: item.supplier_name,
          supplier_id: item.supplier_id,
          slot_id: element.id,
          group_number: tempNumb,
          net_weight: item.net_weight,
          net_weigth: item.net_weight,
          import_code: item.import_code,
          percent: element.new_value,
          receive_car: this.formExport.value.receive_car,
          receiver: this.formExport.value.receiver,
          receiver_id: this.formExport.value.receiver_id,
          seller_id: this.formExport.value.seller_id,
          is_transfer_car: this.isTransferCar,
          real_net_weight: item.real_net_weight,
          //Không phải phiếu bán hàng
          is_sale: false,
          is_done: false,
          //New update add container_number_import
          container_number_import: item.container_number_import,
          unit_price: item.unit_price
        };

        //delete when id is null

        if (obj.id == null) {
          delete obj.id;
        }
        objExport.push(obj);
        // if (this.imExportCodeHis) {
        //   if (isCheck === true) {
        //     //Kiem kho
        //     let obj = {
        //       //form group
        //       isImExported: false,
        //       is_draft: isDraft,
        //       is_checked: isCheck,
        //       container_code: item.container_code,
        //       note: this.formExport.value.note,
        //       issue_date: this.issuedate ? this.issuedate : moment(),
        //       implementer_id: this.formExport.value.implementer_id,
        //       customer_id: this.formExport.value.customer_id,
        //       contract_id: item.contract_id,
        //       contract_code: item.contract_code,
        //       inventory_id: item.inventory_id,

        //       //list of items
        //       id: element.row_id,
        //       product_id: item.selectProductObj.id,
        //       product_name: item.selectProductObj.name,
        //       product_code: item.selectProductObj.code,
        //       quantity: element.quantity,
        //       unit_name: item.unit_name,
        //       unit_code: item.unit_code,
        //       batch_code: this.formExport.value.batch_code,
        //       sku_id: item.selectSkuObj.id,
        //       sku_code: item.selectSkuObj.sku_code,
        //       supplier_name: item.supplier_name,
        //       supplier_id: item.supplier_id,
        //       slot_id: element.id,
        //       group_number: tempNumb,
        //       net_weight: item.net_weight,
        //       net_weigth: item.net_weight,
        //       import_code: item.import_code,
        //       percent: element.new_value,
        //       receive_car: this.formExport.value.receive_car,
        //       receiver: this.formExport.value.receiver,
        //       receiver_id: this.formExport.value.receiver_id,
        //       seller_id: this.formExport.value.seller_id,
        //       is_transfer_car: this.isTransferCar,
        //       real_net_weight: item.real_net_weight,
        //     };
        //     objExport.push(obj);
        //   } else {
        //     //Phieeus moi
        //     let obj = {
        //       //form group
        //       isImExported: false,
        //       is_draft: isDraft,
        //       is_checked: isCheck,
        //       container_code: item.container_code,
        //       note: this.formExport.value.note,
        //       issue_date: this.issuedate ? this.issuedate : moment(),
        //       implementer_id: this.formExport.value.implementer_id,
        //       customer_id: this.formExport.value.customer_id,
        //       contract_id: item.contract_id,
        //       contract_code: item.contract_code,
        //       inventory_id: item.inventory_id,
        //       //list of items
        //       product_id: item.selectProductObj.id,
        //       product_name: item.selectProductObj.name,
        //       product_code: item.selectProductObj.code,
        //       quantity: element.quantity,
        //       unit_name: item.unit_name,
        //       unit_code: item.unit_code,
        //       batch_code: this.formExport.value.batch_code,
        //       sku_id: item.selectSkuObj.id,
        //       sku_code: item.selectSkuObj.sku_code,
        //       supplier_name: item.supplier_name,
        //       supplier_id: item.supplier_id,
        //       slot_id: element.id,
        //       group_number: tempNumb,
        //       net_weight: item.net_weight,
        //       net_weigth: item.net_weight,
        //       import_code: item.import_code,
        //       percent: element.new_value,
        //       receive_car: this.formExport.value.receive_car,
        //       receiver: this.formExport.value.receiver,
        //       receiver_id: this.formExport.value.receiver_id,
        //       seller_id: this.formExport.value.seller_id,
        //       is_transfer_car: this.isTransferCar,
        //       real_net_weight: item.real_net_weight,
        //     };
        //     objExport.push(obj);
        //   }
        // } else {
        //   if (isCheck === true) {
        //     let obj = {
        //       //form group
        //       isImExported: false,
        //       is_draft: isDraft,
        //       is_checked: isCheck,
        //       container_code: item.container_code,
        //       note: this.formExport.value.note,
        //       issue_date: this.issuedate ? this.issuedate : moment(),
        //       implementer_id: this.formExport.value.implementer_id,
        //       customer_id: this.formExport.value.customer_id,
        //       contract_id: this.formExport.value.contract_id,
        //       contract_code: this.formExport.value.contract_code,
        //       inventory_id: item.inventory_id,
        //       //list of items
        //       id: element.row_id,
        //       product_id: item.selectProductObj.id,
        //       product_name: item.selectProductObj.name,
        //       product_code: item.selectProductObj.code,
        //       quantity: element.quantity,
        //       unit_name: item.unit_name,
        //       unit_code: item.unit_code,
        //       batch_code: this.formExport.value.batch_code,
        //       sku_id: item.selectSkuObj.id,
        //       sku_code: item.selectSkuObj.sku_code,
        //       supplier_name: item.supplier_name,
        //       supplier_id: item.supplier_id,
        //       slot_id: element.id,
        //       group_number: tempNumb,
        //       net_weight: item.net_weight,
        //       net_weigth: item.net_weight,
        //       import_code: item.import_code,
        //       percent: element.new_value,
        //       receive_car: this.formExport.value.receive_car,
        //       receiver: this.formExport.value.receiver,
        //       receiver_id: this.formExport.value.receiver_id,
        //       seller_id: this.formExport.value.seller_id,
        //       is_transfer_car: this.isTransferCar,
        //       real_net_weight: item.real_net_weight,
        //     };
        //     objExport.push(obj);
        //   } else {
        //     let obj = {
        //       //form group
        //       isImExported: false,
        //       is_draft: isDraft,
        //       is_checked: isCheck,
        //       container_code: item.container_code,
        //       note: this.formExport.value.note,
        //       issue_date: this.issuedate ? this.issuedate : moment(),
        //       implementer_id: this.formExport.value.implementer_id,
        //       customer_id: this.formExport.value.customer_id,
        //       contract_id: this.formExport.value.contract_id,
        //       contract_code: this.formExport.value.contract_code,
        //       inventory_id: item.inventory_id,
        //       //list of items
        //       product_id: item.selectProductObj.id,
        //       product_name: item.selectProductObj.name,
        //       product_code: item.selectProductObj.code,
        //       quantity: element.quantity,
        //       unit_name: item.unit_name,
        //       unit_code: item.unit_code,
        //       batch_code: this.formExport.value.batch_code,
        //       sku_id: item.selectSkuObj.id,
        //       sku_code: item.selectSkuObj.sku_code,
        //       supplier_name: item.supplier_name,
        //       supplier_id: item.supplier_id,
        //       slot_id: element.id,
        //       group_number: tempNumb,
        //       net_weight: item.net_weight,
        //       net_weigth: item.net_weight,
        //       import_code: item.import_code,
        //       percent: element.new_value,
        //       receive_car: this.formExport.value.receive_car,
        //       receiver: this.formExport.value.receiver,
        //       receiver_id: this.formExport.value.receiver_id,
        //       seller_id: this.formExport.value.seller_id,
        //       is_transfer_car: this.isTransferCar,
        //       real_net_weight: item.real_net_weight,
        //     };
        //     objExport.push(obj);
        //   }
        // }
      });

      tempNumb++;
    });

    if (!valid) {
      return false;
    } else {
      return true;
    }
  }

  //Create/Edit of draft
  saveInDraft() {
    let objExport: any = [];

    if (this.formExport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    var checkEdit: boolean;
    this.arrProducts.forEach((el) => {
      if (el.isEdit == true) {
        checkEdit = true;
      }
    });

    if (checkEdit) {
      this.toastr.warnning('Vui lòng lưu bảng danh sách hàng hóa');
      return;
    }

    let res = this.createObject(
      this.is_draft || this.is_draft == null ? true : false,
      !this.is_checked || this.is_checked == null ? false : true,
      objExport
    );

    if (!res) return;
    
    if (this.imExport_code) {
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objExport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    } else {
      this.importExportHistoryService
        .apiImportExportHistoryPost(objExport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.imExport_code = res.data.imExportCode;
            this.arrProducts = [];
            this.changeTitle(true);
            //this.getExportByimExportCode();
            this.closeModal(true);
          }
        });
    }
  }

  //Create/Edit of export
  saveExport(arraySlots) {
    this.listSlots = [];
    let objExport: any = [];
    if (!this.formExport.value.customer_id) {
      this.toastr.warnning('Vui lòng chọn khách hàng');
      return;
    }

    if (!this.formExport.value.contract_id) {
      this.toastr.warnning('Vui lòng chọn hợp đồng');
      return;
    }

    if (!this.formExport.value.implementer_id) {
      this.toastr.warnning('Vui lòng chọn người kiểm kê');
      return;
    }

    let checkReceiver = this.formExport.value.receiver
      ? this.formExport.value.receiver.trim()
      : null;

    let checkSeller = this.formExport.value.seller_id
      ? this.formExport.value.seller_id.trim()
      : null;

    if (!checkReceiver) {
      this.toastr.warnning('Vui lòng chọn người nhận');
      return;
    }

    if (!checkSeller) {
      this.toastr.warnning('Vui lòng chọn nhân viên kinh doanh');
      return;
    }

    if (this.formExport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    if (this.isCheckDraft == false) {
      this.arrProducts.forEach((e) => {
        if (e.is_draft == true) {
          this.isCheckDraft = true;
          this.editItem(e);
        }
      });
      if (this.isCheckDraft) {
        this.toastr.warnning('Vui lòng kiểm tra lại số lượng và phần trăm');
        return;
      }
      this.isCheckDraft = null;
    }
    let res = this.createObject(false, false, objExport);

    if (!res) {
      return;
    }

    var checkEdit: boolean;
    this.arrProducts.forEach((el) => {
      if (el.isEdit == true) {
        checkEdit = true;
      }
    });

    if (checkEdit) {
      this.toastr.warnning('Vui lòng lưu bảng danh sách hàng hóa');
      return;
    }

    this.disableExport = true;
    
    if (this.imExport_code) {
      this.arrProducts.forEach((element) => {
        element.selectHashtag.forEach((el) => {
          let totalPercent = el.used_percent - el.new_value;
          this.slotService
            .apiSlotIdUsedPut(el.id, totalPercent < 0 ? 0 : totalPercent)
            .pipe(debounceTime(300))
            .toPromise()
            .then((data) => {});
        });
      });
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objExport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    } else {
      this.arrProducts.forEach((element) => {
        element.selectHashtag.forEach((el) => {
          let totalPercent = el.used_percent - el.new_value;
          this.slotService
            .apiSlotIdUsedPut(el.id, totalPercent < 0 ? 0 : totalPercent)
            .pipe(debounceTime(300))
            .toPromise()
            .then((data) => {});
        });
      });
      this.importExportHistoryService
        .apiImportExportHistoryPost(objExport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    }
  }

  //Uncheck export
  saveUncheck() {
    let objExport: any = [];
    if (!this.formExport.value.customer_id) {
      this.toastr.warnning('Vui lòng chọn khách hàng');
      return;
    }

    if (!this.formExport.value.contract_id) {
      this.toastr.warnning('Vui lòng chọn hợp đồng');
      return;
    }

    if (!this.formExport.value.implementer_id) {
      this.toastr.warnning('Vui lòng chọn người kiểm kê');
      return;
    }

    if (this.formExport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    let res = this.createObject(false, false, objExport);
    if (!res) {
      return;
    } else {
      if (this.imExport_code) {
        this.importExportHistoryService
          .apiImportExportHistoryCodePut(this.imExport_code, objExport)
          .subscribe((res) => {
            if (res.success) {
              this.toastr.success(res.message);
              this.closeModal(true);
            }
          });
      }
    }
  }

  //Check export
  saveCheck() {
    let objExport: any = [];
    if (!this.formExport.value.customer_id) {
      this.toastr.warnning('Vui lòng chọn khách hàng');
      return;
    }

    if (!this.formExport.value.contract_id) {
      this.toastr.warnning('Vui lòng chọn hợp đồng');
      return;
    }

    if (!this.formExport.value.implementer_id) {
      this.toastr.warnning('Vui lòng chọn người kiểm kê');
      return;
    }

    if (this.formExport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    let res = this.createObject(false, true, objExport);
    if (!res) return;
    this.disableCheck = true;

    if (this.imExport_code) {
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objExport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          } else {
            this.toastr.error(res.message);
          }
        });
    }
  }

  removeExport() {
    let title = 'Xóa phiếu';
    let content = 'Bạn có chắc chắn muốn xóa phiếu này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.importExportHistoryService
            .apiImportExportHistoryDeleteImportExportRecordCodeDelete(
              this.imExport_code
            )
            .subscribe((res) => {
              if (res.success == true) {
                this.toastr.success(res.message);
                this.closeModal(true);
              } else {
                this.toastr.error(res.message);
                this.closeModal(true);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  addProduct = (term: string) => {
    if (!this.isOpenModalProduct) {
      this.isOpenModalProduct = true;
      this.titleModal = 'Thêm mới hàng hóa';
      this.product_name = term;
    }
  };

  closeModalProduct(data) {
    if (!data) {
      if (this.isOpenModalProduct) {
        this.isOpenModalProduct = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModalProduct = false;
        this.product_name = '';
        this.getAllProducts();
        this.toastr.success(data.message);
      } else {
        this.toastr.error(data.message);
      }
    }
  }

  onClickSKU(e) {
    this.value_product_id = e.selectProduct;
  }

  addSku = (term: string) => {
    if (!this.isOpenModalSKU) {
      if (this.idProduct || this.value_product_id) {
        this.isOpenModalSKU = true;
        this.titleModalSKU = 'Thêm mới SKU';
        if (this.idProduct) {
          this.product_id = this.idProduct;
        } else {
          this.product_id = this.value_product_id;
        }
        this.sku_code = term;
      } else {
        this.toastr.warnning('Vui lòng chọn hàng hóa');
        return;
      }
    }
  };

  closeModalSku(data) {
    if (!data) {
      if (this.isOpenModalSKU) {
        this.isOpenModalSKU = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModalSKU = false;
        this.product_id = '';
        this.value_product_id = '';
        this.sku_code = '';
        this.skuService
          .apiSkuGet('product_id==' + data.data.product_id, '', 1, 10000)
          .subscribe((response) => {
            const ele = this.arrProducts.find(
              (p) => p.selectProduct == data.data.product_id
            );
            if (response.success) {
              if (ele) {
                ele.allSkus = response.data.collection;
              }
            }
          });
        this.toastr.success(data.message);
      } else {
        this.toastr.error(data.message);
      }
    }
  }

  openPrintQR() {
    if (!this.isOpenModalQR) {
      this.isOpenModalQR = true;
      this.titleModalQR = 'In mã QR';
    }
  }

  closeModalQR(event) {
    if (this.isOpenModalQR) {
      this.titlePrint = null;
      this.isOpenModalQR = false;
    }
  }

  closeModalFilter(event) {
    console.log(event);
    if (event) {
      this.title_button = '';
      this.lstTilteButton = [];
      if (event.length >= 1) {
        for (let i = 0; i < event.length; i++) {
          this.lstTilteButton.push(event[i].imExportCode);
          if (i + 1 === event.length) {
            this.title_button += event[i].imExportCode;
          } else {
            this.title_button += event[i].imExportCode + ' ';
          }
        }
      }
      event.forEach((element) => {
        this.listSelectImportCode.push(element);
        this.chooseImExportCode(element.imExportCode);
      });
    }
    if (this.isOpenFilterImport) {
      this.isOpenFilterImport = false;
      this.title_filter = null;
    }
  }

  openFilterImport() {
    if (!this.isOpenFilterImport) {
      this.title_filter = 'Phiếu nhập';
      this.optionsFilter.styleClass = 'w-6/12';
      this.isOpenFilterImport = true;
    }
  }

  openCellLocation(event, index) {
    this.numberItem = index;
    this.listItem = this.arrAllProducts[index].selectHashtag;
    if (this.isMobile) {
      if (!this.isOpenCellLocationBottom) {
        this.title_Cell = 'Vị trí ô';
        this.isOpenCellLocationBottom = true;
      }
    } else {
      if (!this.isOpenCellLocation) {
        this.title_Cell = 'Vị trí ô';
        this.optionsCellLocation.styleClass = 'w-5/12';
        this.isOpenCellLocation = true;
      }
    }
  }

  closeCellLocation(event) {
    //Close modal
    this.isOpenCellLocation = false;
    this.isOpenCellLocationBottom = false;
    //Action
    if (event) {
      this.arrProducts[this.numberItem].selectHashtag = [];
      this.arrProducts[this.numberItem].quantity = 0;
      event.forEach((item) => {
        if (item.isSelected == true) {
          this.arrProducts[this.numberItem].selectHashtag.push(item);
          this.arrProducts[this.numberItem].quantity += item.quantity;
        }
        if (item.isSelected == false) {
          //this.arrProducts[this.numberItem].quantity -= item.quantity;
        }
      });
      this.saveItem(this.arrProducts[this.numberItem], this.numberItem);
    }
  }

  async closeModalPercenUsedSlot(event) {
    if (event.success && event.listData.length > 0) {
      var selectHashtag = [];
      let totalQuantity = 0;
      this.arrProducts.forEach((element, index) => {
        if (index == event.listData.index) {
          element.quantity = event.totalEmit;
          // selectHashtag = element.selectHashtag;
          for (let i = 0; i < element.selectHashtag.length; i++) {
            for (let j = 0; j < event.listData.length; j++) {
              if (element.selectHashtag[i].id == event.listData[j].id) {
                element.selectHashtag[i].quantity =
                  event.listData[j].quantitySlot;
                element.selectHashtag[i].new_value =
                  event.listData[j].new_value;
                totalQuantity += element.selectHashtag[i].quantity;
              }
            }
          }
        }
      });

      //Update new Hashtag

      if (this.isOpenModalPercentSlot) {
        this.arrProducts.forEach((element, index) => {
          if (index == event.listData.index) {
            element.isEdit = false;
            element.totalQuantity = totalQuantity;
          }
        });
        this.isOpenModalPercentSlot = false;
      }
      if (this.isOpenModalPercentSlotBottom) {
        this.arrProducts.forEach((element, index) => {
          if (index == event.listData.index) {
            element.isEdit = false;
            element.totalQuantity = totalQuantity;
          }
        });
        this.isOpenModalPercentSlotBottom = false;
      }
    } else if (!event.success) {
      if (this.isOpenModalPercentSlot) {
        this.isOpenModalPercentSlot = false;
      }
      if (this.isOpenModalPercentSlotBottom) {
        this.isOpenModalPercentSlotBottom = false;
      }
    }
  }

  //print
  openModalPrint() {
    if (!this.isOpenPrintIE) {
      this.optionsPrintIE.styleClass = 'w-8/12';
      this.isOpenPrintIE = true;
    }
  }

  optionPrint(data) {
    switch (data.title) {
      case 'QR code':
        this.openPrintQR();
        break;
      case this.isTransferCar ? 'Lệnh Sang Xe' : 'Lệnh Xuất':
        this.title_print = this.isTransferCar ? 'Lệnh Sang Xe' : 'Lệnh Xuất';
        this.isBillOrCommand = false;
        this.openModalPrint();
        break;
      case this.isTransferCar ? 'Phiếu Sang Xe' : 'Phiếu Xuất':
        this.title_print = this.isTransferCar
          ? 'Phiếu Sang Xe'
          : 'Phiếu Xuất Kho';
        this.isBillOrCommand = true;
        this.openModalPrint();
        break;
      case 'Báo cáo':
        var blobExportReport: Blob;
        this.exportService
          .apiExportImportExportCheckingImportCodePost(this.imExport_code)
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastr.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoPhiếuXuất-' + this.imExport_code + '.xlsx'
              );
            }
          });
      default:
        break;
    }
  }

  onRemoveTag(event, element) {
    if (event.quantity) {
      // element.stock = element.stock - event.quantity;
      element.totalQuantity = element.totalQuantity - event.quantity;
    }
  }

  onAdd(event, element) {
    this.allProducts.forEach((el) => {
      if (el.id == element.selectProduct && el.sku_id == element.selectSku) {
        element.selectHashtag.forEach((item) => {
          if (!item.hasOwnProperty('quantity')) {
            el.slot.forEach((itmSlt) => {
              if (itmSlt.slot_id == item.id) {
                element.quantity += itmSlt.slot_quantity;
                Object.assign(item, {
                  quantity: itmSlt.slot_quantity,
                  stock_slot_sku: itmSlt.slot_quantity,
                  used_percent: itmSlt.use_percent,
                  new_value: itmSlt.use_percent ? itmSlt.use_percent : null,
                });
              }
            });
          }
        });
      }
    });

    //  id: item.slot_id,
    //                 row_id: item.id,
    //                 code: item.extend_slot_code,
    //                 quantity: item.quantity,

    //                 stock_slot_sku: element.slot_quantity,
    //                 zone_id: element.zone_id,
    //                 new_value: item.percent ? item.percent : null,
  }

  navigateSSO() {
    window.location.href = environment.ssoFeUrl;
  }
  //SSO
  navigateNewWindowsSSO() {
    window.open(environment.ssoFeUrl);
  }

  /** get stock available for slot */
  getStockAvailableForSlot(obj?, item?, customer_id?, contract_id?) {
    this.stockAvailableService
      .apiStockAvailableCustomerIdStocksGet(
        customer_id,
        '',
        item.product_id,
        '',
        '',
        1,
        1000
      )
      .toPromise()
      .then(async (res) => {
        if (res.success) {
          // get quantity-stock slot

          let code = item.import_code ? item.import_code : item.imExportCode;

          let listProduct = res.data.collection.find(
            (x) => x.product_id == item.product_id
          );

          if (listProduct) {
            let listSlot = this.imExportCodeHis
              ? listProduct.slot.find(
                  (x) =>
                    x.slot_id === item.slot_id &&
                    x.sku_id === item.sku_id &&
                    x.imExportCode === code &&
                    x.slot_quantity > 0
                )
              : listProduct.slot.find(
                  (x) =>
                    x.slot_id === item.slot_id &&
                    x.sku_id === item.sku_id &&
                    x.imExportCode === code
                );
            if (listSlot) {
              obj.selectHashtag.push({
                id: listSlot?.slot_id,
                row_id: this.imExportCodeHis ? null : item.id,
                code: item.extend_slot_code,
                quantity: this.imExportCodeHis
                  ? listSlot?.slot_quantity
                  : item.quantity,
                used_percent: listSlot?.use_percent,
                stock_slot_sku: listSlot?.slot_quantity,
                zone_id: listSlot?.zone_id,
                new_value: item.percent ? item.percent : null,
                currentQuantitySlotExport: item.quantity,
              });
            }

            obj.totalQuantity +=
              this.imExportCodeHis && listSlot
                ? listSlot.slot_quantity
                : this.imExport_code
                ? item.quantity
                : 0;

            obj.stock = 0;
            listProduct.slot.forEach((el) => {
              if (el.imExportCode == code && el.sku_id == item.sku_id) {
                obj.stock += el.slot_quantity;
              }
            });
          }

          // obj.stock += listSlot ? listSlot.slot_quantity : 0;
        }
      });
  }
  /**get stock for sku */
  async getStockSkuId(sku_id, product_id, contract_id, inventory_id?) {
    let stock = 0;
    await this.skuService
      .apiSkuStockAvailableGet(inventory_id, sku_id, product_id, contract_id)
      .toPromise()
      .then((res) => {
        stock = res.data.stock;
      });
    return stock;
  }

  /**Get .total quantity row */
  getTotalQuantity(array?) {
    let total = 0;
    array.forEach((item) => {});
  }

  //Mobile
  selectImport(item) {
    item.checked = !item.checked;
    if (item.checked) {
      this.chooseImExportCode(item.imExportCode);
    } else {
      this.arrProducts = this.arrProducts.filter(
        (res) => res.imExportCode != item.imExportCode
      );
    }
  }
  getAllImportCode() {
    this.importExportHistoryService
      .apiImportExportHistoryFastdataGet(
        `imExportCode|container_code@=*` +
          this.keySearchImport +
          ',is_checked==true,isImExported==true'
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstImportCode = res.data.collection;
          this.changeDetectorRef.detectChanges();
        }
      });
  }
}
