import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/model/product';
import { Subject } from 'rxjs';
import { ProductService } from 'src/app/api/product.service';
import { debounceTime, filter, take } from 'rxjs/operators';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { ToastrService } from 'src/app/core/services';
import { ActivatedRoute } from '@angular/router';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'meu-product-list-manage',
  templateUrl: './product-list-manage.component.html',
  styleUrls: ['./product-list-manage.component.scss'],
})
export class ProductListManageComponent implements OnInit {
  array: Product[] = [];
  products: Product[] = [];

  totalArray: number;
  indexArray: number = 0;
  arrayLenght: number = this.array.length;

  isOpenModal: boolean = false;
  isOpenBottomsheet: Boolean = false;
  options: MxModalOption = {};
  optionsBottom: MxBottomPopupOption = {};

  titleModal: string;
  sortName: string = '';
  sort: string = '';
  show: string = '';
  idProduct: string;
  searchProduct: string = '';
  idunit: string;
  urlBankEnd: string = environment.backendhost;
  idIventory: string;
  urlDetail: string;

  subject: Subject<any> = new Subject();

  constructor(
    private confirmService: ConfirmService,
    private productService: ProductService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private importExportHistoryService: ImportExportHistoryService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId || params.inventoryId != 'null') {
        this.idIventory = params.inventoryId;
        this.urlDetail =
          '/inventory/' + this.idIventory + '/product-management/detail/';
      }
      if (params.id) {
        this.idProduct = params.id;
      }

      this.getAllProduct();
      this.search();
      this.optionCallbackModal();
      this.optionCallbackModalBottom();
    });
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getAllProduct();
  }
  MobileSort(data) {
    if (window.screen.width < 481) {
      this.Sort(data);
    }
  }

  //Search
  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getAllProduct();
    });
  }
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}

  //Modal
  open(id) {
    if (id) {
      this.idProduct = id;
    } else {
      this.titleModal = 'Thêm mới hàng hóa';
    }
    if (window.screen.width < 767) {
      this.isOpenModal = false;
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      }
    } else {
      this.isOpenBottomsheet = false;
      if (!this.isOpenModal) {
        this.isOpenModal = true;
      }
    }
  }
  closeModal(data) {
    if (!data) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModal = false;
        this.idProduct = '';
        this.getAllProduct();
      } else {
        // this.isOpenModal = true;
      }
    }
  }
  closeModalBottom(data) {
    if (!data) {
      if (this.isOpenBottomsheet) {
        this.isOpenBottomsheet = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenBottomsheet = false;
        this.idProduct = '';
        this.getAllProduct();
      } else {
        // this.getAllProduct();
      }
    }
  }
  optionCallbackModalBottom() {
    this.optionsBottom.callBackAfterClose = (e) => {
      this.idProduct = '';
      this.isOpenBottomsheet = false;
    };
  }
  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.idProduct = '';
      this.isOpenModal = false;
    };
  }

  //Get data
  compare(a, b) {
    if (a.stock < b.stock) {
      return -1;
    }
    if (a.stock > b.stock) {
      return 1;
    }
    return 0;
  }
  stock(product: Product[]) {
    product.forEach((pro) => {
      var stock = 0;

      pro['extend_sku'].forEach((p) => {
        stock += p.stock;
      });
      Object.assign(pro, { stock: stock });
    });
    return product;
  }
  updateList() {
    this.importExportHistoryService
      .apiImportExportHistoryReValidateStockLevelGet()
      .subscribe((res) => {
        if (res.success == true) {
          this.getAllProduct();
          this.toastService.success(res.message);
        }
      });
  }
  Show(id) {
    var n = this.show.search(id);
    if (n > -1) {
      this.show = this.show.replace(id, '');
    } else {
      this.show += id;
    }
    // this.getAllProduct();
  }
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    if (this.sort == 'stock' || this.sort == '-stock') {
      this.productService
        .apiProductGet(
          `name|code|extend_unit_name|extend_all_sku_name|extend_product_template_name@=${this.searchProduct}`,
          '',
          1,
          this.totalArray
        )
        .subscribe((data) => {
          if (data.success == true) {
            var temp = data.data.collection;
            this.stock(temp);
            temp.sort(this.compare);
            if (this.sort == '-stock') {
              temp.reverse();
            }
            if (currentPage * pageSize > this.array.length) {
              this.products = temp.splice(this.indexArray, this.array.length);
            } else {
              this.products = temp.splice(this.indexArray, pageSize);
            }
            this.totalArray = data.data.total;
            // setTimeout(() => {}, 0);
          } else {
            this.toastService.error('Lỗi không lấy được dữ liệu');
          }
        });
    } else {
      this.productService
        .apiProductGet(
          `name|code|extend_unit_name|extend_all_sku_name|extend_product_template_name@=${this.searchProduct}`,
          this.sort,
          currentPage,
          pageSize
        )
        .subscribe((data) => {
          if (data.success == true) {
            var product = this.stock(data.data.collection);
            this.products = product;
            this.totalArray = data.data.total;
          } else {
            this.toastService.error('Lỗi không lấy được dữ liệu');
          }
        });
    }
  }
  getAllProduct() {
    this.productService
      .apiProductGet(
        `name|code|extend_unit_name|extend_all_sku_name|extend_product_template_name@=${this.searchProduct}`
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.array = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //Delete
  deleteconfirm(id) {
    let title = 'Xóa hàng hóa';
    let content = 'Bạn có chắc chắn muốn xóa hàng hóa này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.productService.apiProductIdDelete(id).subscribe((res) => {
            if (res.success === true) {
              this.toastService.success(res.message);
              this.getAllProduct();
            } else {
              this.toastService.error(res.message);
              this.getAllProduct();
            }
          });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
}
