import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerManagementComponent } from './customer-management/customer-management.component';
import { ModalCreateCustomerComponent } from './customer-management/modal-create-customer/modal-create-customer.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CustomerDetailComponent } from './customer-management/customer-detail/customer-detail.component';
import { CustomerInformationComponent } from './customer-management/customer-detail/customer-information/customer-information.component';
import { CustomerContractComponent } from './customer-management/customer-detail/customer-contract/customer-contract.component';
import { ModalCreateCustomertModule } from './customer-management/modal-create-customer/modal-create-customer.module';
import { ContractManagementCreateModule } from '../contract-management/contract-management-create/contract-management-create.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';

const routes: Routes = [
  {
    path: '',
    component: CustomerManagementComponent,
  },
  {
    path: 'customer-list',
    component: CustomerManagementComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
  {
    path: 'detail/:id',
    component: CustomerDetailComponent,
    children: [
      {
        path: '',
        component: CustomerInformationComponent,
        canActivate: [CanActivateRouteGuard],
        data: { permissions: 'STAFF' },
      },
      {
        path: 'contract',
        component: CustomerContractComponent,
        canActivate: [CanActivateRouteGuard],
        data: { permissions: 'STAFF' },
      },
    ],
  },
];

@NgModule({
  declarations: [
    CustomerManagementComponent,
    CustomerDetailComponent,
    CustomerInformationComponent,
    CustomerContractComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    ModalCreateCustomertModule,
    ContractManagementCreateModule,
    NgSelectModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
  exports: [],
})
export class CustomerModule {}
