import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { InventoryService } from 'src/app/api/inventory.service';
import { HeaderManagementService } from 'src/app/shared/services/header.service';
import { SummaryContainerService } from 'src/app/view/sales-management/container-management/modal-summary-container/summary-container.service';
import { environment } from 'src/environments/environment';
import { PermissionService } from '../../guards/permission.service';
import { SharedService } from '../../services/shared.service';
import { Device } from '@capacitor/device';

@Component({
  selector: 'meu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  screenWidth;
  isOpenUser: boolean = false;
  open: boolean = false;
  mobileMenuOpen: boolean = false;
  isMobile: boolean = false;
  isPrint = false;
  @Input() inventoryId = null;
  allInventories = [];
  inventoryChoose = { id: null, name: '', image: '' };
  avatarDefault = '../../../../assets/images/default-image.png';
  currentUser = new BehaviorSubject<any>({});
  userInfo;
  isIOS;
  lstPermission: any = [];

  @HostListener('document:click', ['$event'])
  outsideClick(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.open = false;
      this.mobileMenuOpen = false;
    }
  }

  constructor(
    private inventoryService: InventoryService,
    private eRef: ElementRef,
    private router: Router,
    private sharedService: SharedService,
    private permissionService: PermissionService,
    private headerManagementService: HeaderManagementService,
    private summaryContainerService: SummaryContainerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    var prevScrollpos = 0;
    this.queryScreen();
    this.detectorPlatform();
    window.addEventListener('resize', (e) => this.queryScreen());
    //Scroll to hide menu
    window.onscroll = function () {
      let currentScroll = window.pageYOffset;
      if (prevScrollpos > currentScroll) {
        document.getElementById('screen').style.top = '0';
        prevScrollpos = currentScroll;
      } else {
        document.getElementById('screen').style.top = '-70px';
        prevScrollpos = currentScroll;
      }
    };
    this.permissionService
      .getMyPermissions()
      .toPromise()
      .then((res) => {
        this.lstPermission = res;
        this.getInfoUserLogged();
        this.getAllInventories();
      });
  }

  //detect ios to add padding for notch
  async detectorPlatform() {
    const data = await Device.getInfo();
    if (data.operatingSystem === 'ios') {
      this.isIOS = true;
      this.cdr.detectChanges();
    }
  }

  queryScreen() {
    this.isMobile = document.body.offsetWidth < 1014 ? true : false;
    if (this.isMobile) {
      this.summaryContainerService.is_print.subscribe((res) => {
        this.isPrint = res;
      });
    }
  }
  getAllInventories() {
    this.inventoryService.apiInventoryGet('', '', 1, 500).subscribe((res) => {
      if (res.success) {
        this.allInventories = res.data.collection;
        if (this.router.url == '/') {
          this.inventoryChoose = this.allInventories[0];
          localStorage.setItem('inventoryId', this.inventoryChoose.id);
          if (
            this.lstPermission.includes('CUSTOMER') &&
            this.lstPermission.length == 1
          ) {
            this.router.navigateByUrl(
              `/inventory/${this.inventoryChoose.id}/dashboard-client`
            );
          } else {
            if (this.isMobile) {
              this.router.navigateByUrl(`/menu-mobile`);
            } else {
              this.router.navigateByUrl(
                `/inventory/${this.inventoryChoose.id}/dashboard/dashboard-manage`
              );
            }
          }
        }
        let localId = localStorage.getItem('inventoryId');
        if (localId !== 'null') {
          this.inventoryId = localId;
          this.allInventories.forEach((element) => {
            element.image = element.image
              ? environment.backendhost + element.image
              : this.avatarDefault;
            if (element.id === this.inventoryId) {
              this.inventoryChoose = element;
            }
          });
        } else {
          this.inventoryChoose = this.allInventories[0];
          localStorage.setItem('inventoryId', this.inventoryChoose.id);
        }
      }
    });
  }

  chooseInventory(item) {
    if (item.id != this.inventoryChoose.id) {
      localStorage.setItem('inventoryId', item.id);
      this.headerManagementService.inventoryID.next(item.id);
      this.inventoryChoose = item;
      this.router.navigateByUrl(
        this.router.url.replace(this.inventoryId, item.id)
      );
      this.inventoryId = item.id;
      this.open = false;
    }
  }
  getInfoUserLogged() {
    this.userInfo = JSON.parse(localStorage.getItem(environment.userInfo));
    // user.name = user.name.split(' ')[0];
    this.currentUser.next(this.userInfo);
  }
  logout() {
    localStorage.removeItem('permissions');
    this.sharedService.openSSO();
  }
  redirectToInfoPage() {
    window.location.href = `${environment.ssoFeUrl}/portal/hr/user-management/edit/${this.userInfo.id}/0000000000000000000#user-profile`;
  }
}
