<form [formGroup]="formcreate">
  <div class="grid grid-cols-12 gap-4 mt-24px">
    <div class="md:col-span-2 col-span-full">
      <div class="flex flex-col h-full">
        <span class="text-12px font-roboto-regular text-grey-label mb-2">
          Hình ảnh mô tả
        </span>
        <div class="flex border border-grayscale-light-grey rounded h-full">
          <img
            [src]="urlImage"
            *ngIf="urlImage"
            class="w-full h-full rounded object-cover"
          />
        </div>
      </div>
    </div>
    <div class="md:col-span-10 col-span-full">
      <div class="grid sm:grid-cols-2 md:grid-cols-10 gap-4">
        <div class="md:col-span-3 sm:col-span-5 col-span-10">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Tên người dùng
            </span>

            <input
              readonly
              type="text"
              formControlName="name"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>

        <div class="col-span-10 sm:col-span-5 md:col-span-3">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Ngày sinh</span
            >

            <input
              readonly
              type="text"
              formControlName="date"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>
        <div class="col-span-10 sm:col-span-5 md:col-span-4">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Giới tính</span
            >

            <input
              readonly
              type="text"
              formControlName="gender"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>
        <div class="col-span-10 sm:col-span-5 md:col-span-3">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Email
            </span>

            <input
              readonly
              type="text"
              formControlName="email"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>
        <div class="col-span-10 sm:col-span-5 md:col-span-3">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Điện thoại
            </span>
            <input
              readonly
              type="text"
              formControlName="phone"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>

        <div class="md:col-span-4 col-span-10">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Loại người dùng
            </span>
            <ng-select
              [searchable]="false"
              class="select-name mt-8px focus:outline-none"
              placeholder="Chọn loại người dùng"
              [clearable]="!change"
              bindLabel="name"
              [multiple]="true"
              [items]="allRole"
              formControlName="type_customer"
              (change)="changePermission($event)"
            >
            </ng-select>
          </div>
        </div>
        <div class="md:col-span-6 col-span-10">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Tên công ty
            </span>
            <input
              readonly
              type="text"
              formControlName="nameCompany"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>
        <div class="md:col-span-4 col-span-10">
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-label">
              Mã số thuế
            </span>
            <input
              readonly
              type="text"
              formControlName="tax_code"
              class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px overflow-hidden overflow-ellipsis h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="customer != null"
    class="col-span-full flex-row-reverse gap-8px flex h-72px py-16px mt-200px"
  >
    <button
      class="btn-cancel flex items-center gap-2px"
      type="button"
      *ngIf="!change"
      (click)="resetDetail()"
    >
      <img src="assets/icons/detail-icon/u_times.svg" />
      Hủy
    </button>
    <button
      class="btn-primary-add flex items-center gap-2px"
      type="button"
      [disabled]="change || formcreate.invalid"
      (click)="updateRole()"
    >
      <img src="assets/icons/detail-icon/u_save.svg" />
      Lưu
    </button>
    <a
      class="btn-primary-export flex items-center gap-2px cursor-pointer"
      (click)="editSSO(customer.id)"
    >
      <img
        class="w-16px h-16px"
        src="assets/icons/detail-icon/u_edit-alt.svg"
      />
      Chỉnh sửa người dùng
    </a>
  </div>
</form>
