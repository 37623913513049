import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'src/app/core/services/toastr.service';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductTemplate } from 'src/app/model/productTemplate';
import { ProductTemplateService } from 'src/app/api/productTemplate.service';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
@Component({
  selector: 'meu-app-product-categories',
  templateUrl: './app-product-categories.component.html',
  styleUrls: ['./app-product-categories.component.scss'],
})
export class AppProductCategoriesComponent implements OnInit {
  searchProduct: string = '';
  indexArray: number = 0;
  searchName: any;
  products: ProductTemplate[] = [];
  array: ProductTemplate[] = [];
  index: number = 0;
  isOpenModal: boolean;
  isOpenModalDel: boolean;
  SearchField: boolean;
  SearchIcon: boolean;
  arrayLenght: number = this.array.length;
  productTemplate: ProductTemplate = {};
  id_product: string;
  id_parent_product: string;
  current_folder_id: string;
  isTable: boolean;
  isNewNode: boolean;
  currentNode: any;
  newNode: any;
  isEditFoler: boolean;
  isOpenEditFolder: boolean;
  titleMessage: string;
  toastAlert: boolean;
  toastSucces: boolean;
  isRender: boolean;
  sortName: string = '';
  sort: string = '';
  addProductMode: boolean;
  titleModal: string;
  options: MxModalOption = {};
  isAdmin: boolean = false;
  inventoryId: string;
  totalArray: number;

  subject: Subject<any> = new Subject();
  changeDetectorRef: any;
  constructor(
    private productCategoriesService: ProductTemplateService,
    private confirmService: ConfirmService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  formcreate = new FormGroup({
    name: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    description: new FormControl(''),
  });

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId || params.inventoryId != 'null') {
        // if (/admin/.test(this.router.url)) {
        //   this.isAdmin = true;
        // }
        this.inventoryId = params.inventoryId;
      }
    });
    this.isOpenModal = false;
    this.isOpenModalDel = false;
    this.SearchField = false;
    this.SearchIcon = true;
    this.isTable = false;
    this.isOpenEditFolder = false;
    this.toastAlert = false;
    this.toastSucces = false;
    this.isEditFoler = false;
    this.addProductMode = true;
    //this.current_folder_id = '';
    this.subject.pipe(debounceTime(600)).subscribe(() => {
      this.getProductById(this.current_folder_id);
    });
    this.onSearch();
    this.options.callBackAfterClose = (e) => {
      this.id_product = '';
      this.isOpenModal = false;
      this.isEditFoler = false;
    };
  }

  nodeFolder($event) {
    this.currentNode = $event;
  }

  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getProductById(this.current_folder_id);
  }

  getProductById($event) {
    if ($event) {
      //console.log('Id khong rong------------->', $event);
      this.current_folder_id = $event;
      this.addProductMode = false;
      this.id_parent_product = $event;
      this.filter = '';

      if (this.dataSearchBasic) {
        this.filter = 'code|name@=*' + this.dataSearchBasic;
      }
      this.productCategoriesService
        .apiProductTemplateGet(this.filter + `,tree_id==${$event}`)
        .pipe(debounceTime(5000))
        .subscribe((data) => {
          if (data.success == true) {
            this.products = data.data.collection;
            this.products.forEach((product) => {
              if (product.description == '') {
                product.description = '--';
              }
            });
            this.array = this.products;
            this.totalArray = data.data.total;
            if (this.products.length > 0) {
              this.isTable = true;
            } else {
              this.isTable = false;
            }
          } else {
            //alert('Fail to get data');
            this.isTable = false;
          }
        });
    } else {
      //console.log('Id Rong------------->', $event);
      this.current_folder_id = $event;
      this.addProductMode = true;
    }
  }

  getProductByName() {
    if (this.searchName === '') {
      this.getProductById(this.current_folder_id);
    } else {
      this.productCategoriesService
        .apiProductTemplateGet(`tree_id==${this.current_folder_id}`)
        .pipe(debounceTime(5000))
        .subscribe((data) => {
          if (data.success == true) {
            this.products = data.data.collection;
            this.array = this.products;
            if (this.products.length > 0) {
              this.isTable = true;
            } else {
              this.isTable = false;
            }
          } else {
            //alert('Fail to get data');
            this.isTable = false;
          }
        });
    }
  }

  Search() {
    if (this.searchName === '') {
      this.getProductById(this.current_folder_id);
    } else {
      this.products = this.products.filter((res) => {
        return res.name
          .toLocaleLowerCase()
          .match(this.searchName.toLocaleLowerCase());
      });
    }
  }

  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';
  filter: any;

  onSearch() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.dataSearchBasic = value;
        this.getProductById(this.current_folder_id);
      });
  }

  openEditFolder($event) {
    this.isEditFoler = true;
    if ($event) {
      this.titleModal = 'Sửa thư mục phân loại';
    } else {
      this.titleModal = 'Thêm thư mục phân loại mới';
    }

    //console.log('Sửa or Thêm', $event);
    this.id_product = $event;
  }

  showSearch(e) {
    if (!this.SearchField) {
      this.SearchField = true;
      this.SearchIcon = false;
      //console.log(e.preventDefault())
    }
  }

  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    if (this.dataSearchBasic) {
      this.filter = 'code|name@=*' + this.dataSearchBasic;
    }
    this.productCategoriesService
      .apiProductTemplateGet(
        this.filter + `,tree_id==${this.current_folder_id}`,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.products = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          //alert('Fail to get data');
        }
      });
  }

  open(id) {
    if (id) {
      this.id_product = id;
      this.titleModal = 'Chỉnh sửa phân loại hàng';
    } else {
      this.id_product = null;
      this.titleModal = 'Thêm mới phân loại hàng';
    }
    if (!this.isOpenModal) {
      this.isOpenModal = true;
    }
  }

  closeModal(data: any) {
    if (!data) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
        this.id_product = '';
      }
    } else {
      if (data.success === true) {
        if (this.isOpenModal) {
          this.isOpenModal = false;
          this.id_product = '';
          this.getProductById(this.current_folder_id);
          this.toastService.success(data.message);
        }
      }
      if (data.success === false) {
        if (this.isOpenModal) {
          this.isOpenModal = false;
          this.id_product = '';
          this.getProductById(this.current_folder_id);
          this.toastService.error(data.message);
        }
      }
    }
  }

  closeModalFolder(data: any) {
    if (!data) {
      if (this.isEditFoler) {
        this.isEditFoler = false;
      }
    } else {
      if (data.success === true) {
        if (this.isEditFoler) {
          this.isEditFoler = false;
          this.toastService.success(data.message);
        }
      }
      if (data.success === false) {
        if (this.isEditFoler) {
          this.isEditFoler = false;
          this.toastService.error(data.message);
        }
      }
    }
  }
  onKeyDown(): void {}
  closeModalDelSucess() {
    if (this.isOpenModalDel) {
      //console.log('đóng xóa thành công');
      this.isOpenModalDel = false;
      this.id_product = '';
      this.getProductById(this.current_folder_id);
      this.toastSucces = true;
      this.titleMessage = 'Xóa thành công';
      setTimeout(
        function () {
          this.toastSucces = false;
        }.bind(this),
        2500
      );
    }
  }

  reRenderTree() {
    if (this.isRender) {
      this.isRender = false;
    } else {
      this.isRender = true;
    }
  }
  deleteconfirm(id) {
    let title = 'Xóa phân loại';
    let content = 'Bạn có chắc chắn muốn xóa phân loại này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.productCategoriesService
            .apiProductTemplateIdDelete(id)
            .subscribe((res) => {
              if (res.success == true) {
                this.getProductById(this.current_folder_id);
                this.toastService.success(res.message);
              } else {
                this.getProductById(this.current_folder_id);
                this.toastService.error(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
}
