<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center title"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Quản lý phiếu nhập và phiếu xuất
      </div>

      <div class="sm:grid md:flex md:float-right items-center gap-8px margin-t">
        <div
          class="link-car-done text-primary-dodger-blue font-roboto-medium"
          (click)="openModalTransferCar()"
        >
          Chờ hạ <span class="cus-bagde">({{ count_car_done }})</span>
        </div>
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            (keyup)="keyUp.next($event.target.value)"
            class="search-import-export m-auto h-32px w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>

        <select
          class="select-check font-roboto-medium text-14px text-grey-label not-italic border-solid rounded-4px h-32px px-12px border bg-white border-grayscale-light-grey hover:border-primary-light focus:outline-none focus:ring-1 focus:ring-primary-blue"
          [(ngModel)]="selectedCheck"
          (change)="onChangeCheck($event.target.value)"
        >
          <option *ngFor="let check of allChecks" [ngValue]="check.value">
            {{ check.name }}
          </option>
        </select>

        <select
          class="select-status font-roboto-medium text-14px text-grey-label not-italic border-solid rounded-4px h-32px px-12px border bg-white border-grayscale-light-grey hover:border-primary-light focus:outline-none focus:ring-1 focus:ring-primary-blue"
          [(ngModel)]="selectedStatus"
          (change)="onChangeStatus($event.target.value)"
        >
          <option *ngFor="let status of allStatus" [ngValue]="status.value">
            {{ status.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="w-full overflow-x-auto" style="overflow-y: hidden">
      <table
        class="w-full border-separate table-fixed"
        cellpadding="0"
        cellspacing="0"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="text-center hidden-col w-40px pt-12px pb-8px">#</th>

            <th
              class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('imExportCode')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  tooltip="Mã phiếu nhập / xuất"
                  >Mã phiếu nhập / xuất</span
                >

                <img
                  *ngIf="sort.includes('imExportCode'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>

            <th
              class="text-left col-customer items-center cursor-pointer pt-12px px-16px pb-8px"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Khách hàng"
                  >Khách hàng</span
                >
              </div>
            </th>

            <!-- <th
              class="text-left col-created-by hidden-col cursor-pointer pt-12px px-16px pb-8px"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Người lập phiếu"
                  >Người lập phiếu</span
                >
              </div>
            </th> -->
            <th
              class="text-left col-created-by hidden-col cursor-pointer pt-12px px-16px pb-8px"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Số container"
                  >Số container</span
                >
              </div>
            </th>

            <th
              class="text-left col-implementer hidden-col cursor-pointer pt-12px px-16px pb-8px"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Người kiểm kê"
                  >Người kiểm kê</span
                >
              </div>
            </th>

            <!-- <th
              class="text-left col-created-at cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('created_at')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Ngày tạo"
                  >Ngày tạo</span
                >

                <img
                  *ngIf="sort.includes('created_at'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th> -->

            <th
              class="text-left col-receipt-issue-date cursor-pointer pt-12px px-16px pb-8px"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Ngày nhập / xuất kho"
                  >Ngày nhập / xuất kho</span
                >
              </div>
            </th>

            <th
              class="text-center col-check pt-12px px-16px pb-8px cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Đã kiểm kho"
            >
              Đã kiểm kho
            </th>

            <th
              class="text-center col-option pt-12px px-16px pb-8px cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Trạng thái"
            >
              Trạng thái
            </th>
          </tr>
        </thead>

        <!-- DATA TABLE -->
        <tbody class="font-roboto-regular" *ngIf="totalArray != 0; else noInfo">
          <tr
            *ngFor="let importexport of allImportExport; let i = index"
            style="min-height: 40px !important"
          >
            <td
              class="td-stt px-5px py-10px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="#"
              [tooltip]="indexArray + i + 1"
            >
              {{ indexArray + i + 1 }}
            </td>

            <td
              class="td-code py-10px px-16px responsive"
              data-label="Mã phiếu nhập / xuất"
            >
              <div
                class="relative flex cursor-pointer"
                *ngIf="importexport.isImExported; else showExport"
                [tooltip]="importexport.imExportCode"
              >
                <img
                  src="../../../../assets/icons/dashboard-icons/u_file-download-alt.svg"
                  class="w-16px h-16px mr-5px mt-2px"
                />
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                  (click)="open(importexport.imExportCode)"
                  >{{ importexport.imExportCode }}</span
                >
              </div>
              <ng-template #showExport>
                <div
                  class="relative flex cursor-pointer"
                  [tooltip]="importexport.imExportCode"
                >
                  <img
                    src="../../../../assets/icons/dashboard-icons/u_file-upload-alt.svg"
                    class="w-16px h-16px mr-5px mt-2px"
                  />
                  <span
                    class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                    (click)="open(importexport.imExportCode)"
                    >{{ importexport.imExportCode }}</span
                  >
                </div>
              </ng-template>
            </td>

            <td
              class="td-name py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Khách hàng"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  importexport.extend_customer_name
                    ? importexport.extend_customer_name
                    : '---'
                "
              >
                {{
                  importexport.extend_customer_name
                    ? importexport.extend_customer_name
                    : '---'
                }}
              </span>
            </td>

            <td
              class="td-unit py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Số container"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  importexport.container_code
                    ? importexport.container_code
                    : '---'
                "
              >
                {{
                  importexport.container_code
                    ? importexport.container_code
                    : '---'
                }}
              </span>
            </td>

            <td
              class="td-unit py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Người kiểm kê"
            >
              <span
                class="cursor-pointer"
                [tooltip]="
                  importexport.extend_implementer_name
                    ? importexport.extend_implementer_name
                    : '---'
                "
              >
                {{
                  importexport.extend_implementer_name
                    ? importexport.extend_implementer_name
                    : '---'
                }}
              </span>
            </td>

            <!-- <td
              class="td-unit py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Ngày tạo"
            >
              <span
                class="cursor-pointer"
                [tooltip]="importexport.created_at | date: 'dd/MM/yyyy HH:mm'"
              >
                {{ importexport.created_at | date: 'dd/MM/yyyy HH:mm' }}
              </span>
            </td> -->

            <td
              class="td-unit py-10px px-16px overflow-hidden overflow-ellipsis whitespace-nowrap"
              data-label="Ngày nhập / xuất kho"
            >
              <span
                class="cursor-pointer"
                *ngIf="importexport.isImExported; else showExportDate"
                [tooltip]="
                  importexport.receipt_date
                    ? (importexport.receipt_date | date: 'dd/MM/yyyy HH:mm')
                    : '---'
                "
              >
                {{
                  importexport.receipt_date
                    ? (importexport.receipt_date | date: 'dd/MM/yyyy HH:mm')
                    : '---'
                }}
              </span>

              <ng-template #showExportDate>
                <span
                  class="cursor-pointer"
                  [tooltip]="
                    importexport.issue_date
                      ? (importexport.issue_date | date: 'dd/MM/yyyy HH:mm')
                      : '---'
                  "
                >
                  {{
                    importexport.issue_date
                      ? (importexport.issue_date | date: 'dd/MM/yyyy HH:mm')
                      : '---'
                  }}
                </span>
              </ng-template>
            </td>

            <td class="td-check py-10px px-16px" data-label="Đã kiểm kho">
              <div class="items-center">
                <input
                  type="checkbox"
                  [checked]="importexport.is_checked"
                  onclick="return false"
                  class="w-16px h-16px"
                />
              </div>
            </td>

            <td class="td-thaotac px-12px py-10px" data-label="Trạng thái">
              <!-- Import -->
              <span *ngIf="importexport.isImExported; else showExportStatus">
                <span *ngIf="importexport.is_draft; else showImportInfo">
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-grey-label bg-grayscale-light-grey status-style"
                  >
                    Lưu nháp
                  </div>
                </span>
                <ng-template #showImportInfo>
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-green-blue bg-duck-egg-blue status-style"
                  >
                    Đã nhập
                  </div>
                </ng-template>
              </span>

              <!-- Export -->
              <ng-template #showExportStatus>
                <span *ngIf="importexport.is_draft; else showExportInfo">
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-grey-label bg-grayscale-light-grey status-style"
                  >
                    Lưu nháp
                  </div>
                </span>
                <ng-template #showExportInfo>
                  <div
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-orange-red bg-light-peach status-style"
                  >
                    Đã xuất
                  </div>
                </ng-template>
              </ng-template>
            </td>
          </tr>
        </tbody>

        <ng-template #noInfo>
          <tbody>
            <tr
              class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
            >
              <td
                colspan="9"
                class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
              >
                Không có dữ liệu
              </td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [total]="totalArray"
        [changePage]="onPagingFunc"
        [setPropertyTotal]="true"
        (onChangeValue)="onChangeValue($event)"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<!-- Import -->
<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [is_unload]="is_unload"
    [imExport_code]="imExport_code"
    [saleItem]="saleItem"
    (closeModalImport)="closeModal($event)"
  ></meu-import-history>
</meu-modal-component>

<!-- Export -->
<meu-modal-component
  [isOpen]="isOpenModalExport"
  [options]="optionsExport"
  [title]="titleModalExport"
  *ngIf="isOpenModalExport"
>
  <meu-export-history
    [imExport_code]="im_Export_code"
    (closeModalExport)="closeModalExport($event)"
  ></meu-export-history>
</meu-modal-component>
<!-- transfer done car -->
<meu-modal-component
  [isOpen]="isOpenModalTransfer"
  [options]="optionsTransfer"
  [title]="titleModalTransfer"
  *ngIf="isOpenModalTransfer"
>
  <meu-modal-transfer-container
    (closeModalTransfer)="closeModalTransfer($event)"
  >
  </meu-modal-transfer-container>
</meu-modal-component>
