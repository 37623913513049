import { DashboardManageService } from '../dashboard.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { ConfirmService } from 'src/app/core/services';

@Component({
  selector: 'meu-date-range-modal',
  templateUrl: './date-range-modal.component.html',
  styleUrls: ['./date-range-modal.component.scss'],
})
export class DateRangeModalComponent implements OnInit {
  @Output() closeModalRangeDate = new EventEmitter();

  formRangeDate = new FormGroup({
    start: new FormControl(moment()),
    end: new FormControl(),
  });

  maxDate = moment();
  minDate = moment();

  constructor(
    private dashboardService: DashboardManageService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {}

  getDateTime(event) {
    this.minDate = moment(event.endDate);
  }
  submit() {
    let title = 'Xác nhận lưu tùy chọn ngày';
    let content = 'Bạn có chắc chắn muốn chọn tùy chọn ngày này?';
    const start = moment(
      this.formRangeDate.controls['start'].value.endDate
    ).format();
    const end = moment(
      this.formRangeDate.controls['end'].value.endDate
    ).format();
    this.dashboardService.dateStart.next(start);
    this.dashboardService.dateEnd.next(end);
    this.closeModal(start, end);
  }
  closeModal(start?, end?) {
    this.closeModalRangeDate.emit({
      dateStart: new Date(start),
      dateEnd: new Date(end),
    });
  }
}
