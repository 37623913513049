import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissionDecrypt: any;
  currentPermissions: any = [];
  permissionEncrypt: any;
  encryptPassword = '@123RAPTOR!@#&^';
  constructor(private http: HttpClient) {}
  // Authentication/Authorization

  getMyPermissions(isLogin?: boolean): Observable<any> {
    if (!isLogin) {
      let storedPermission = JSON.parse(localStorage.getItem('permissions'));
      //decript data from localstorage
      try {
        this.permissionDecrypt = storedPermission
          ? CryptoJS.AES.decrypt(
              storedPermission,
              this.encryptPassword
            ).toString(CryptoJS.enc.Utf8)
          : null;
        this.permissionDecrypt = JSON.parse(this.permissionDecrypt);
      } catch (error) {
        let item = {
          permissions: '',
          expiry: new Date().getTime(),
        };
        this.permissionDecrypt = item;
      }
      let timeNow = new Date().getTime();
      if (this.permissionDecrypt?.expiry > timeNow) {
        return of(this.permissionDecrypt.permissions);
      }
    }
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .get<any>(
        environment.backendhost + '/api/authenticate/getMyPermissions',
        { headers: httpHeaders }
      )
      .pipe(
        map((res) => {
          if (res.success) {
            this.currentPermissions = res.data;
            const item = {
              permissions: this.currentPermissions,
              expiry: new Date().getTime() + 30 * 60000,
            };
            //encript data to localstorage
            this.permissionEncrypt = CryptoJS.AES.encrypt(
              JSON.stringify(item),
              this.encryptPassword
            ).toString();
            localStorage.setItem(
              'permissions',
              JSON.stringify(this.permissionEncrypt)
            );
            return this.currentPermissions;
          }
        }),
        catchError((err) => {
          return null;
        })
      );
  }
}
