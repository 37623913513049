import { debounceTime, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DeleteHistoryNoteService } from 'src/app/api/deleteHistoryNote.service';
import { ToastrService } from 'src/app/core/services/toastr.service';
import { DeleteHistoryNote } from 'src/app/model/deleteHistoryNote';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
})
export class HistoryListComponent implements OnInit {
  public array: DeleteHistoryNote[] = [];
  public totalArray: number = 0;

  public filter: string = '';
  public searchHistory: string = '';
  public sort: string = '';
  public sortName: string = '';

  public subject = new Subject<string>();
  public temp: any[] = [];

  title = '';
  code = '';
  ImExport: boolean;
  isOpen: boolean = false;
  isOpenBottom: boolean = false;
  options: MxModalOption = {
    styleClass: 'w-11/12',
    callBackAfterClose: () => {
      this.isOpen = false;
    },
  };
  optionsBottom: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenBottom = false;
    },
  };

  constructor(
    private deleteHistoryService: DeleteHistoryNoteService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAllHistory();
    this.search();
  }

  //data
  getAllHistory() {
    this.deleteHistoryService
      .apiDeleteHistoryGet(
        `extend_im_export_code|extend_creator_name|extend_user_name@=${this.searchHistory}`,
        '',
        this.pageIndex,
        this.pageSize,
        true
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.array = data.data.collection;
          this.totalArray = data.data.total;
          this.array.forEach((note) => {
            let temp1 = JSON.parse(note.im_export_note).isImExported;
            let temp2 = JSON.parse(note.im_export_note).is_draft;
            let temp3 = JSON.parse(note.im_export_note).imExportCode;
            let temp4 = JSON.parse(note.im_export_note).extend_inventory_name;
            Object.assign(note, {
              isImExported: temp1,
              is_draft: temp2,
              imExportCode: temp3,
              extend_inventory_name: temp4,
            });
          });
        } else {
          this.toastrService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //page
  get onPagingFunc() {
    return this.pageControl.bind(this);
  }
  pageControl(pageArray, currentPage, pageSize) {
    this.array.length = pageSize;
  }
  pageIndex = 1;
  pageSize = 10;
  onChangeValue(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllHistory();
  }

  //search
  search() {
    this.subject.pipe(debounceTime(600)).subscribe((data) => {
      this.searchHistory = data;
      this.getAllHistory();
    });
  }

  //sort
  Sort(name) {
    let x = this.sort.search(name);
    if (x > -1) {
      if (this.sortName == '../../../../assets/icons/sort/sort_down.svg') {
        this.sort = this.sort.replace(name, '-' + name);
        this.sortName = '../../../../assets/icons/sort/sort_up.svg';
      } else {
        this.sort = '';
        this.sortName = 'assets/icons/sort/sort_normal.svg';
      }
    } else {
      this.sort = name;
      this.sortName = '../../../../assets/icons/sort/sort_down.svg';
    }
    this.getAllHistory();
  }

  IExport(data) {
    if (window.screen.width < 767) {
      console.log('mobile');
      this.isOpenBottom = true;
      this.code = data.extend_im_export_code;
    } else {
      console.log('desktop');
      this.isOpen = true;
      this.code = data.extend_im_export_code;
    }
    if (data.extend_im_export_code.includes('PN')) {
      this.ImExport = true;
      this.title = 'Chi tiết phiếu nhập hàng';
    } else if (data.extend_im_export_code.includes('PX')) {
      this.ImExport = false;
      this.title = 'Chi tiết phiếu xuất hàng';
    } else if (data.extend_im_export_code.includes('PSX')) {
      this.ImExport = true;
      this.title = 'Chi tiết phiếu sang xe';
    } else {
      this.ImExport = false;
      this.title = 'Chi tiết phiếu khai báo';
    }
  }
  closeModal(event) {
    if (event) {
      this.getAllHistory();
    }
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpenBottom = false;
    }
  }
}
