<div *ngIf="type" class="flex flex-col fixed bottom-0 left-0 transition zindex">
  <div
    class="h-48px toastr-w py-12px pr-16px pl-12px rounded-4px mb-10px ml-16px shadow-md flex justify-between"
    [ngClass]="
      type == 'error'
        ? 'bg-red-light'
        : type === 'success'
        ? 'bg-green-blue'
        : type === 'warnning'
        ? 'bg-golden-yellow'
        : type === 'info'
        ? 'bg-dark-grey'
        : ''
    "
  >
    <div class="flex items-center" [ngSwitch]="type">
      <img
        *ngSwitchCase="'success'"
        class="p-2px"
        [src]="'assets/icons/toast/u_check.svg'"
      />
      <img
        *ngSwitchCase="'error'"
        class="p-2px"
        src="assets/icons/toast/u_info.svg"
      />
      <img
        *ngSwitchCase="'warnning'"
        class="p-2px"
        src="assets/icons/toast/u_warning.svg"
      />
      <img
        *ngSwitchCase="'info'"
        class="p-2px"
        src="assets/icons/toast/u_info.svg"
      />
      <span
        class="p-2px font-roboto-regular text-14px text-background-modal ml-10px text-white"
        >{{ message }}</span
      >
    </div>
    <div class="flex items-center">
      <span
        class="p-2px font-roboto-regular text-14px text-background-modal"
      ></span>

      <img (click)="close.emit()" src="assets/icons/toast/u_times.svg" />
    </div>
  </div>
</div>
