import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from './../../../api/user.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { User } from 'src/app';
import { DashboardManageService } from '../../dashboard/dashboard.service';

@Component({
  selector: 'meu-filter-user-modal',
  templateUrl: './filter-user-modal.component.html',
  styleUrls: ['./filter-user-modal.component.scss'],
})
export class FilterUserModalComponent implements OnInit {
  @Input() userID: string;
  @Output() emitUserID = new EventEmitter();

  defaultID: string = '';

  Customers: User;

  constructor(
    private userService: UserService,
    private dashboardManageService: DashboardManageService
  ) {}

  ngOnInit(): void {
    if (this.userID) {
      this.defaultID = this.userID;
      this.getAllUser();
    }
  }

  getAllUser() {
    this.userService
      .apiUsersGet(`extend_user_role@='|CUSTOMER|'`, '', 1, 100000)
      .subscribe((res) => {
        this.Customers = res.data.collection;
      });
  }

  getValueClientContract(data) {
    this.emitUserID.emit(data?.id);
  }
}
