<div
  class="col-span-full px-16px custom-scroll content-modal grid grid-cols-12 gap-4px"
>
  <div class="col-span-6">
    <button
      (click)="isPayment = false"
      class="focus:outline-none py-16px px-20px text-center text-14px font-roboto-medium w-full border-b-2 h-full"
      [ngClass]="
        !isPayment
          ? 'text-primary-dodger-blue border-primary-dodger-blue'
          : 'text-grey-light'
      "
    >
      Thông tin công nợ
    </button>
  </div>
  <div class="col-span-6">
    <button
      class="focus:outline-none py-16px px-20px text-center text-14px font-roboto-medium w-full border-b-2 h-full"
      (click)="isPayment = true"
      [ngClass]="
        isPayment
          ? ' text-primary-dodger-blue border-primary-dodger-blue'
          : ' text-grey-light'
      "
    >
      Thanh toán công nợ
    </button>
  </div>

  <div class="col-span-12">
    <div *ngIf="isPayment; else isInfo">
      <meu-modal-debt-payment
        [userDetailID]="userDetailID"
      ></meu-modal-debt-payment>
    </div>
    <ng-template #isInfo>
      <meu-modal-debt-infomation
        [userDetailID]="userDetailID"
        [userDetailDebt]="userDetailDebt"
      ></meu-modal-debt-infomation>
    </ng-template>
  </div>

  <div class="footer col-span-full text-right px-16px pb-16px pt-8px">
    <button (click)="closeModal(false)" class="btn-text-cancel">Hủy</button>
  </div>
</div>
