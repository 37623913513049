<div class="container grid-contain">
  <div
    class="col-span-12 grid grid-cols-12 px-16px bg-white shadow-md rounded-4px mt-16px"
  >
    <div
      class="col-span-12 border-grayscale-light-grey border-opacity-40 border-b py-16px title"
    >
      <span class="text-20px font-medium text-grey-light"
        ><a
          [routerLink]="urlDetail + 'customer-list'"
          routerLinkActive="isSelected"
          >Người dùng </a
        >></span
      ><span class="text-20px font-medium text-dark-grey">
        {{ customer.full_name }}
      </span>
    </div>
    <div class="col-span-12 mt-8px gap-4px relative drop">
      <div
        style="z-index: 8"
        class="absolute hidden right-16px shadow-lg md:flex md:flex-row md:w-full md:h-full md:relative md:shadow-none md:bg-none md:rounded-0 md:left-3px md:space-x-12px"
      >
        <button
          class="button-menu text-grey-light"
          routerLinkActive="isSelected"
          [routerLinkActiveOptions]="{ exact: true }"
          #info="routerLinkActive"
          [routerLink]="urlDetail + 'detail/' + customer.id"
        >
          <img
            *ngIf="info.isActive; else normal"
            src="assets/icons/detail-icon/u_info-circle-isSelected.svg"
          />
          <ng-template #normal
            ><img src="assets/icons/detail-icon/u_info-circle.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium">
            Thông tin người dùng</span
          >
        </button>
        <button
          class="button-menu text-grey-light"
          [routerLink]="urlDetail + 'detail/' + customer.id + '/contract'"
          routerLinkActive="isSelected"
          #sku="routerLinkActive"
        >
          <img
            *ngIf="sku.isActive; else normal1"
            src="assets/icons/detail-icon/u_files-landscapes-alt.svg"
          />
          <ng-template #normal1>
            <img
              src="assets/icons/detail-icon/u_files-landscapes-alt-default.svg"
          /></ng-template>
          <span class="text-14px font-medium font-roboto-medium"
            >Quản lý hợp đồng</span
          >
        </button>
      </div>
    </div>

    <div class="col-span-12" style="min-height: 500px">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
</div>
