import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'src/app/core/services';

@Component({
  selector: 'meu-percent-used-slot',
  templateUrl: './percent-used-slot.component.html',
  styleUrls: ['./percent-used-slot.component.scss'],
})
export class PercentUsedSlotComponent implements OnInit {
  @Input() listSlots: any[] = [];
  @Input() isCheckIE: boolean;
  @Output() closeModalPercentUsedSlot = new EventEmitter();
  @Output() percentUsed = new EventEmitter();
  touched: boolean = false;
  checkPercent: boolean;
  checkAll;
  totalSlot;
  totalQuantity = 0;
  subjectQuantity = new BehaviorSubject<any>([]);
  constructor(private toastr: ToastrService) {}
  isMobile = false;

  ngOnInit(): void {
    this.queryScreen();
    if (this.listSlots) {
      this.totalSlot = this.listSlots.length;
      this.totalQuantity = this.listSlots['totalQuantity'];
    }
  }

  closeModal(data = { success: false, totalEmit: 0, listData: [] }) {
    this.closeModalPercentUsedSlot.emit(data);
  }

  queryScreen() {
    this.isMobile = document.body.offsetWidth < 1014 ? true : false;
  }

  onKeyUpValue(data, check) {
    if (check == true) {
      this.listSlots.forEach((element) => {
        if (element.check == true) {
          element.new_value = data;
        }
      });
    }
  }
  percent(data, i) {
    if (this.isCheckIE == false && !this.listSlots['isEditExport'])
      this.listSlots.forEach((element, index) => {
        if (index == i) {
          element.new_value = Math.round(
            element.used_percent * (data / element.quantityImport)
          );
        }
      });
  }
  onKeyUpQuantity(data, check, i) {
    if (check == true && !this.listSlots['isEditExport']) {
      this.listSlots.forEach((element) => {
        if (element.check == true) {
          element.quantitySlot = data;
          if (this.isCheckIE == false) {
            element.new_value = Math.round(
              element.used_percent * (data / element.quantityImport)
            );
          }
        }
      });
    }
  }
  getTotal($event) {
    var total = 0;
    this.listSlots.forEach((element) => {
      total += element.quantitySlot ? element.quantitySlot : 0;
    });
    this.totalQuantity = total;
  }

  savePercentUsedSlot() {
    this.touched = true;
    let isZeroPercent;
    this.listSlots.forEach((item) => {
      if (item.new_value === 0 && !this.listSlots['isEditExport']) {
        isZeroPercent = true;
      }
    });
    if (isZeroPercent) {
      this.toastr.warnning('Phần trăm sử dụng phải lớn hơn 0%');
    } else {
      let lst = this.listSlots.filter(
        (x) =>
          x.new_value > 100 ||
          x.new_value < 0 ||
          (!x.new_value && x.new_value !== 0) ||
          (this.isCheckIE &&
            !this.listSlots['isEditExport'] &&
            x.new_value + x.used_percent > 100) ||
          (!this.isCheckIE &&
            !this.listSlots['isEditExport'] &&
            x.used_percent - x.new_value < 0) ||
          (!x.quantitySlot && x.quantitySlot == 0) ||
          (!this.isCheckIE &&
            !this.listSlots['isEditExport'] &&
            x.quantitySlot > x.quantityImport) ||
          (!this.isCheckIE &&
            !this.listSlots['isEditExport'] &&
            x.quantitySlot < x.quantityImport &&
            x.new_value == x.used_percent) ||
          (this.listSlots['isEditExport'] &&
            x.quantitySlot > x.quantityImport + x.currentQuantitySlotExport)
      );

      if (lst.length > 0) {
        return;
      }

      let totalInput = 0;
      this.listSlots.forEach((item) => {
        totalInput = totalInput + item.quantitySlot;
      });
      if (this.isCheckIE) {
        if (
          totalInput != this.listSlots['totalQuantity'] &&
          !this.listSlots['isEditExport']
        ) {
          this.toastr.warnning(
            'Số lượng phân bổ cần phải bằng số lượng đầu vào'
          );
        } else {
          this.closeModal({
            success: true,
            totalEmit: totalInput,
            listData: this.listSlots,
          });
        }
      } else {
        if (
          totalInput > this.listSlots['totalQuantity'] &&
          !this.listSlots['isEditExport']
        ) {
          this.toastr.warnning(
            'Số lượng để xuất hàng hóa phải nhỏ hơn hoặc bằng số lượng tồn'
          );
        } else {
          this.closeModal({
            success: true,
            totalEmit: totalInput,
            listData: this.listSlots,
          });
        }
      }
    }
  }

  selectAll() {
    this.listSlots.forEach((element) => {
      element.check = this.checkAll;
    });
    this.subjectQuantity.next(this.listSlots);
  }
}
