import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardManageService {
  dateStart = new Subject<string>();
  dateEnd = new Subject<string>();
  rangeDate = new Subject<string>();
  userIDClient = new Subject<string>();

  constructor() {}
}
