<div class="grid grid-cols-12 mt-8px">
  <div
    class="col-span-12 flex flex-col md:flex-row md:justify-between md:items-center"
  >
    <div
      class="pt-10px pb-14px font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
    >
      Danh sách SKU
    </div>

    <div class="flex flex-wrap md:float-right items-center gap-8px">
      <div class="relative">
        <input
          type="text"
          placeholder="Nhập từ khóa tìm kiếm"
          [(ngModel)]="searchSKU"
          (keyup)="onKeyUp()"
          (keydown)="onKeyDown()"
          class="m-auto h-32px w-320px border mb-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
        />
        <img
          class="absolute top-0 mt-9px ml-12px"
          src="assets/icons/handle-icons/Icons_search.svg"
        />
      </div>
      <button
        class="btn-primary-icon mt-10px mb-14px flex items-center gap-4px"
        (click)="open(null)"
      >
        <img src="assets/icons/handle-icons/icon_add.svg" />
        <span>Thêm mới</span>
      </button>
    </div>
  </div>
  <div class="col-span-12 h-auto" style="overflow: inherit">
    <table
      cellpadding="0"
      cellspacing="0"
      class="w-full border-separate table-fixed"
      *ngIf="totalArray > 0; else noInfo"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="md:text-center w-40px">#</th>
          <th
            class="text-left col-code cursor-pointer"
            (click)="Sort('sku_code')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'SKU'"
                >SKU</span
              >

              <img
                *ngIf="sort.includes('sku_code'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <th
            class="text-left col-supplier items-center cursor-pointer"
            (click)="Sort('extend_supplier_name')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Nhà cung cấp'"
                >Nhà cung cấp</span
              >

              <img
                *ngIf="sort.includes('extend_supplier_name'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <th
            class="text-left col-name-inventory hidden-col cursor-pointer"
            (click)="Sort('extend_inventory_name')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Tên Kho'"
                >Tên Kho</span
              >

              <img
                *ngIf="
                  sort.includes('extend_inventory_name');
                  else normalSearch
                "
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <th
            class="text-left col-code-inventory cursor-pointer"
            (click)="Sort('inventory_id')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Mã Kho'"
                >Mã Kho</span
              >

              <img
                *ngIf="sort.includes('inventory_id'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- Quy cách -->
          <th
            class="text-left col-number cursor-pointer"
            (click)="Sort('net_weight')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Quy cách'"
                >Quy cách</span
              >

              <img
                *ngIf="sort.includes('net_weight'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <th
            class="text-left col-number cursor-pointer"
            (click)="Sort('stock')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Tồn Kho'"
                >Tồn Kho</span
              >

              <img
                *ngIf="sort.includes('stock'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <th class="text-center col-thaotac"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="font-roboto-regular h-40px"
          *ngFor="let sku of skus; let i = index"
        >
          <td
            class="td-stt overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
            data-label="#"
            [tooltip]="indexArray + i + 1"
          >
            {{ indexArray + i + 1 }}
          </td>
          <td class="td-sku" data-label="SKU" (click)="MobileSort('sku_code')">
            <span
              [tooltip]="sku.sku_code ? sku.sku_code : '--'"
              class="overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
            >
              {{ sku.sku_code ? sku.sku_code : '--' }}</span
            >
          </td>
          <td
            class="td-supplier cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap"
            data-label="Nhà cung cấp"
            (click)="MobileSort('extend_supplier_name')"
            [tooltip]="
              sku['extend_supplier_name'] ? sku['extend_supplier_name'] : '--'
            "
          >
            {{
              sku['extend_supplier_name'] ? sku['extend_supplier_name'] : '--'
            }}
          </td>
          <td
            class="td-nameinventory overflow-hidden overflow-ellipsis whitespace-nowrap"
            data-label="Tên kho"
            (click)="MobileSort('extend_inventory_name')"
          >
            {{
              sku['extend_inventory_name'] ? sku['extend_inventory_name'] : '--'
            }}
          </td>
          <td
            class="td-codeinventory overflow-hidden overflow-ellipsis whitespace-nowrap"
            data-label="Mã kho"
            (click)="MobileSort('extend_inventory_code')"
          >
            {{
              sku['extend_inventory_code'] ? sku['extend_inventory_code'] : '--'
            }}
          </td>

          <td
            class="td-stock overflow-hidden overflow-ellipsis whitespace-nowrap"
            data-label="Quy cách"
            (click)="MobileSort('net_weight')"
          >
            {{ sku.net_weight ? (sku.net_weight | number) : '--' }}
          </td>

          <td
            class="td-stock overflow-hidden overflow-ellipsis whitespace-nowrap"
            data-label="Tồn kho"
            (click)="MobileSort('stock')"
          >
            {{ sku.stock | number }}
          </td>
          <td class="text-center py-0 td-thaotac" data-label="Thao tác">
            <div class="dropdown">
              <a
                type="button"
                href="javascript:void(0)"
                class="flex justify-center text-sm rounded-md focus:outline-none items-center"
                aria-expanded="false"
                aria-haspopup="true"
                aria-controls="layout-user-action"
              >
                <img
                  id="dropdown-btn"
                  class="cursor-pointer w-20px"
                  src="../../../../assets/icons/product-categories-icons/u_ellipsis-v.svg"
                  alt=""
                />
              </a>

              <div
                class="opacity-0 right-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
              >
                <div
                  class="position-menu"
                  aria-labelledby="headlessui-menu-button-2"
                  id="layout-user-action"
                  role="menu"
                >
                  <div class="py-2">
                    <div
                      (click)="open(sku.id)"
                      class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                    >
                      <div class="flex">
                        <img
                          src=".../../../../assets/icons/detail-icon/leading_icon_edit.svg"
                          alt=""
                          class="mr-3"
                        />
                        Chỉnh sửa
                      </div>
                    </div>

                    <div
                      (click)="addImport(sku.id)"
                      class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                    >
                      <div class="flex">
                        <img
                          src=".../../../../assets/icons/detail-icon/leading_icon_IE.svg"
                          alt=""
                          class="mr-3"
                        />
                        Nhập hàng
                      </div>
                    </div>

                    <div
                      (click)="addExport(sku.id)"
                      class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                    >
                      <div class="flex">
                        <img
                          src=".../../../../assets/icons/detail-icon/leading_icon_IE.svg"
                          alt=""
                          class="mr-3"
                        />
                        Xuất hàng
                      </div>
                    </div>

                    <div
                      (click)="deleteconfirm(sku.id)"
                      class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                    >
                      <div class="flex">
                        <img
                          src=".../../../../assets/icons/detail-icon/leading_icon_trash.svg"
                          alt=""
                          class="mr-3"
                        />
                        Xóa
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Emty array -->
    <ng-template #noInfo>
      <table class="w-full border-collapse table-fixed">
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="md:text-center w-40px">#</th>
            <th
              class="text-left col-code cursor-pointer"
              (click)="Sort('sku_code')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'SKU'"
                  >SKU</span
                >

                <img
                  *ngIf="sort.includes('sku_code'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-supplier items-center cursor-pointer"
              (click)="Sort('extend_supplier_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Nhà cung cấp'"
                  >Nhà cung cấp</span
                >

                <img
                  *ngIf="
                    sort.includes('extend_supplier_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-name-inventory hidden-col cursor-pointer"
              (click)="Sort('extend_inventory_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Tên Kho'"
                  >Tên Kho</span
                >

                <img
                  *ngIf="
                    sort.includes('extend_inventory_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-code-inventory cursor-pointer"
              (click)="Sort('inventory_id')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Mã Kho'"
                  >Mã Kho</span
                >

                <img
                  *ngIf="sort.includes('inventory_id'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-number cursor-pointer"
              (click)="Sort('stock')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Tồn Kho'"
                  >Tồn Kho</span
                >

                <img
                  *ngIf="sort.includes('stock'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th class="text-center col-thaotac"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="font-roboto-regular no-info text-14px h-40px">
            <td colspan="7" class="td-noinfo">Không có dữ liệu</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
</div>

<meu-pagination-component
  *ngIf="totalArray != 0"
  [total]="totalArray"
  [array]="array"
  [changePage]="onPagingFunc"
></meu-pagination-component>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-modal-create-sku
    [id_sku]="idSKU"
    [id_product]="idProduct"
    (closeModalSku)="closeModal($event)"
  ></meu-modal-create-sku>
</meu-modal-component>

<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-modal-create-sku
    [id_sku]="idSKU"
    [id_product]="idProduct"
    (closeModalSku)="closeModalBottom($event)"
  ></meu-modal-create-sku>
</meu-modal-popup-bottom>

<!-- modal IExport -->
<meu-modal-component
  [isOpen]="isOpenImport"
  [options]="optionsImport"
  [title]="titleImport"
  *ngIf="isOpenImport"
>
  <meu-import-history
    [imExport_code]="imExport_code"
    [idProduct]="idProduct"
    [skuId]="idSKU"
    (closeModalImport)="closeModalImport($event)"
    (changeTitleModal)="changeTitle($event)"
  ></meu-import-history>
</meu-modal-component>

<meu-modal-component
  [isOpen]="isOpenExport"
  [options]="optionsExport"
  [title]="titleExport"
  *ngIf="isOpenExport"
>
  <meu-export-history
    [imExport_code]="im_Export_code"
    [idProduct]="idProduct"
    [skuId]="idSKU"
    (closeModalExport)="closeModalExport($event)"
    (changeTitleModal)="changeTitleExport($event)"
  ></meu-export-history>
</meu-modal-component>
