import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductTemplateService } from 'src/app';
import { ProductTemplateTreeService } from 'src/app/api/productTemplateTree.service';
import { ProductTemplateTree } from 'src/app/model/productTemplateTree';

@Component({
  selector: 'meu-tree-view-edit',
  templateUrl: './tree-view-edit.component.html',
  styleUrls: ['./tree-view-edit.component.scss'],
})
export class TreeViewEditComponent implements OnInit {
  @Input() id_product: string;
  @Input() node_current: any;
  @Output() closeModalProduct = new EventEmitter();
  @Output() reRenderTree = new EventEmitter();
  treeData = [];
  nodes = [];

  title: string;
  nameButton: string;
  productTemplateTree: ProductTemplateTree = {};
  nameParentProduct: string;

  constructor(
    private productCategoriesService: ProductTemplateService,
    private productTemplateTreeService: ProductTemplateTreeService
  ) {}

  formcreate = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.setModalAddOrEdit();
  }
  setModalAddOrEdit() {
    //console.log('node nhan duoc------------', this.node_current);
    this.getParent();
    if (this.id_product) {
      this.title = 'Chỉnh sửa đơn vị tính';
      this.nameButton = 'Lưu';
      this.getValueFolder();
    } else {
      this.title = 'Thêm mới đơn vị tính';
      this.nameButton = 'Thêm';
    }
  }
  setButtonAddOrEdit() {
    if (this.id_product) {
      this.updateProductID();
    } else {
      this.PostNewFolder();
    }
  }

  PostNewFolder() {
    this.productTemplateTree.name = this.formcreate.controls[
      'name'
    ].value.trim();
    if (!this.productTemplateTree.name) {
      //console.log('value rỗng');
      this.productTemplateTree.name = null;
    }
    if (this.node_current) {
      this.productTemplateTree.parent_id = this.node_current.id;
    }
    //console.log(this.productTemplateTree);
    this.productTemplateTreeService
      .apiProductTemplateTreePost(this.productTemplateTree)
      .subscribe((data) => {
        if (data.success) {
          this.closeModal(data);
          this.reRenderTree.emit();
        } else {
          this.closeModal(data);
        }
      });
  }

  getValueFolder() {
    this.productTemplateTreeService
      .apiProductTemplateTreeIdGet(this.node_current.id)
      .subscribe((data) => {
        //console.log('du lieu chinh sua', data);
        if (data.success == true) {
          (this.productTemplateTree = data.data),
            this.formcreate.controls['name'].setValue(
              this.productTemplateTree.name
            );
        } else {
          alert('Fail to get data');
        }
      });
  }

  getParent() {
    if (this.node_current) {
      if (this.node_current.parent_id) {
        this.productTemplateTreeService
          .apiProductTemplateTreeIdGet(this.node_current.parent_id)
          .subscribe((data) => {
            if (data.success == true) {
              this.nameParentProduct = data.data.name;
            }
          });
      } else {
        this.nameParentProduct = 'Tất cả phân loại';
      }
    } else {
      this.nameParentProduct = 'Tất cả phân loại';
    }
  }

  updateProductID() {
    this.productTemplateTree.name = this.formcreate.controls[
      'name'
    ].value.trim();
    if (!this.productTemplateTree.name) {
      //console.log('value rỗng');
      this.productTemplateTree.name = null;
    }
    this.productTemplateTreeService
      .apiProductTemplateTreeIdPut(
        this.productTemplateTree.id,
        this.productTemplateTree
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.closeModal(data);
          this.reRenderTree.emit();
        } else {
          this.closeModal(data);
        }
      });
  }

  closeModal(data = null) {
    if (data) {
      //console.log('Cos du lieu');
      this.closeModalProduct.emit(data);
      return;
    }
    this.closeModalProduct.emit();
  }

  ChangedCodeAdd(val) {
    if (val != null) {
      var stringName = val.replace(/ /g, '_').toUpperCase();
      stringName = stringName.replace(
        /à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,
        'a'
      );
      stringName = stringName.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      stringName = stringName.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      stringName = stringName.replace(
        /ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,
        'o'
      );
      stringName = stringName.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      stringName = stringName.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      stringName = stringName.replace(/đ/g, 'd');
      stringName = stringName.replace(
        /À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g,
        'A'
      );
      stringName = stringName.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      stringName = stringName.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      stringName = stringName.replace(
        /Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g,
        'O'
      );
      stringName = stringName.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      stringName = stringName.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      stringName = stringName.replace(/Đ/g, 'D');
      stringName = stringName.replace(
        /~|`|!|@|#|%|&|(|)|_|=|-|{|}|[|]|;|:|'|"|,|<|.|>|/g,
        ''
      );
      stringName = stringName.replace(
        /\u0300|\u0301|\u0303|\u0309|\u0323/g,
        ''
      );
      stringName = stringName.replace(/\u02C6|\u0306|\u031B/g, '');
    }
  }
}
