import { ActivatedRoute } from '@angular/router';
import { DashboardService } from './../../../api/dashboard.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StockAvailableService } from 'src/app';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'meu-expired-product-modal',
  templateUrl: './expired-product-modal.component.html',
  styleUrls: ['./expired-product-modal.component.scss'],
})
export class ExpiredProductModalComponent implements OnInit {
  @Input() inventory_id;
  @Output() closeModalExpired = new EventEmitter();
  @Input() dateSelected;
  @Input() fromDate;
  @Input() toDate;

  totalArray;
  lstExpired;

  public pageSize = 5;
  public pageIndex = 1;

  constructor(private stockAvailable: StockAvailableService) {}

  ngOnInit(): void {
    this.getAllExpiredProduct();
  }

  getAllExpiredProduct() {
    this.stockAvailable
      .apiStockAvailableInventoryIdExpiryProductGet(
        this.inventory_id,
        this.dateSelected == 'custom' ? '' : this.dateSelected,
        this.fromDate,
        this.toDate,
        'quantity!=0'
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstExpired = res.data.collection;
          this.totalArray = res.data.total;
        }
      });
  }

  onChangeValue(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllExpiredProduct();
  }
  get changePageFunc() {
    return this.onKeyDown.bind(this);
  }
  onKeyDown(): void {}

  open(IExportCode: string) {
    if (IExportCode) {
      this.closeModalExpired.emit(IExportCode);
    }
  }

  closeModal() {
    this.closeModalExpired.emit();
  }

  checkImport(imExportCode: string) {
    if (imExportCode && imExportCode != null && imExportCode.includes('PN'))
      return true;
    if (imExportCode && imExportCode != null && imExportCode.includes('PX'))
      return false;
  }
}
