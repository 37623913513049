import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
@Component({
  selector: 'meu-app-doughnut-chart',
  templateUrl: './app-doughnut-chart.component.html',
  styleUrls: ['./app-doughnut-chart.component.scss'],
})
export class AppDoughnutChartComponent implements OnInit {
  @Input() data: any = [];
  @Input() width: number;
  @Input() height: number;
  @Input() percent: number = 38;
  chartData: Chart;
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue != changes.data.previousValue) {
      this.update();
    }
    console.log(changes);
  }
  ngOnInit(): void {}
  ngOnDestroy() {
    Chart.unregister();
    this.chartData.destroy();
    this.cdr.detach();
  }
  update() {
    let chartElement = document.getElementById('doughnutChart') as HTMLElement;
    const newCanvas = document.createElement('canvas');
    chartElement.appendChild(newCanvas);
    const ctx = (newCanvas as HTMLCanvasElement).getContext('2d');
    let option: any = {
      type: 'doughnut',
      data: this.data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            title: {
              font: {
                size: 12,
              },
              color: '#041826',
            },
            labels: {
              boxWidth: 10,
              boxHeight: 10,
            },
          },
        },
      },
    };

    this.chartData = new Chart(ctx, option);
    this.chartData.resize(this.width, this.height);
  }
}
