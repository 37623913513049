import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/core/guards/permission.service';
import { MenuConfig } from '../../config/menu.config';

@Component({
  selector: 'meu-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @ViewChild('dropdown') dropdown: ElementRef<any>;
  @Input() mobileMenuOpen;
  @Input() inventorySelected;
  constructor(
    private menuConfig: MenuConfig,
    private router: Router,
    private permissionService: PermissionService
  ) {}
  menuItem: any = [];
  selectedItem: any = {};
  selectedSubItem: any = {};
  lstPermission: any = [];
  listMenuMobile = [
    {
      title: 'Trang chủ',
      icon: 'assets/icons/menu-icons/home.svg',
      page: '/menu-mobile',
      selected: true,
      permission: 'STAFF',
    },
    {
      title: 'báo cáo',
      icon: 'assets/icons/menu-icons/area-chart-alt.svg',
      page: '',
      selected: false,
      submenu: [
        {
          title: 'Tổng quan kho hàng',
          icon: 'assets/icons/menu-icons/area-chart-alt.svg',
          page: '/inventory/{{inventoryId}}/dashboard/dashboard-manage',
          selected: false,
          permission: 'STAFF',
        },
        {
          title: 'Khách hàng',
          icon: 'assets/icons/menu-icons/area-chart-alt.svg',
          page: '/inventory/{{inventoryId}}/dashboard-client',
          selected: false,
          permission: 'CUSTOMER',
        },
      ],
    },
    {
      title: 'Sơ đồ kho',
      icon: 'assets/icons/menu-icons/home.svg',
      page: '/inventory/{{inventoryId}}/map',
      selected: false,
      permission: 'STAFF',
    },
    {
      title: 'Nhập - Xuất',
      icon: 'assets/icons/menu-icons/icon_import_export.svg',
      page:
        '/inventory/{{inventoryId}}/import-export-management/import-export-list',
      selected: false,
    },

    {
      title: 'thiết lập',
      icon: 'assets/icons/menu-icons/icon_setting.svg',
      page: '',
      selected: false,
      permission: 'STAFF',
      submenu: [
        {
          title: 'Lịch sử xóa',
          icon: 'assets/icons/menu-icons/venn-diagram.svg',
          page: '/history-management/history-list',
          // permission: 'STAFF',
        },
        {
          title: 'Quản lý xe container',
          icon: 'assets/icons/menu-icons/venn-diagram.svg',
          page: '/container-management/list-container',
          // permission: 'STAFF',
        },
      ],
    },
  ];
  ngOnChanges(changes: SimpleChanges) {
    if (changes.mobileMenuOpen?.currentValue) {
      this.currentPositionByUrl(this.menuItem, this.router.url);
    }
  }
  ngOnInit(): void {
    //this.menuItem = this.menuConfig.configs.header.items;
    this.menuItem = this.listMenuMobile;
    this.permissionService
      .getMyPermissions()
      .toPromise()
      .then((res) => {
        this.lstPermission = res;

        if (
          this.lstPermission.includes('CUSTOMER') &&
          this.lstPermission.length == 1
        ) {
          this.menuItem[0] = this.menuItem[0].submenu[1];
          this.menuItem[0].title = 'báo cáo';
        }
        if (
          this.lstPermission.includes('STAFF') &&
          this.lstPermission.length == 1
        ) {
          this.menuItem[0] = this.menuItem[0].submenu[0];
          this.menuItem[0].title = 'báo cáo';
        }
        this.currentPositionByUrl(this.menuItem, this.router.url);
      });
  }
  onClickMenu(item, i, e) {
    if (item.submenu?.length > 0) {
      item.open = !item.open;
      return;
    } else {
      this.selectedItem.selected = false;
      item.selected = true;
      this.selectedItem = item;
      this.selectedSubItem ? (this.selectedSubItem.selected = false) : null;
      if (item.page) {
        this.router.navigate([
          item.page.replace('{{inventoryId}}', this.inventorySelected),
        ]);
      }
    }
    e.stopPropagation();
  }
  submenuClick(item, i, e) {
    e.stopPropagation();
    this.selectedItem.selected = false;
    this.selectedSubItem ? (this.selectedSubItem.selected = false) : null;
    this.menuItem[i].selected = true;
    this.selectedItem = this.menuItem[i];
    item.selected = true;
    this.selectedSubItem = item;
    this.router.navigate([
      item.page.replace('{{inventoryId}}', this.inventorySelected),
    ]);
  }
  currentPositionByUrl(menuItem, url, parentIndex = -1) {
    menuItem.reduce((accumulator, item, i) => {
      item.isHasPermission = this.isHasPermissions(item.permission);
      if (item.submenu?.length > 0) {
        this.currentPositionByUrl(item.submenu, url, i);
      } else {
        let page = item.page.replace('{{inventoryId}}', this.inventorySelected);
        if (item.page && url == page) {
          this.menuItem[parentIndex > -1 ? parentIndex : i].selected = true;
          this.selectedItem = this.menuItem[parentIndex > -1 ? parentIndex : i];
          item.selected = true;
          this.selectedSubItem = parentIndex > -1 ? item : null;
          return;
        } else {
          item.selected = false;
        }
      }
    }, []);
  }
  isHasPermissions(permission) {
    if (permission && this.lstPermission) {
      return this.lstPermission.includes(permission);
    }
    return true;
  }
}
