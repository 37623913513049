import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DebtManagementComponent } from './debt-management.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalDebtPaymentComponent } from './modal-debt-payment/modal-debt-payment.component';
import { ModalDebtInfomationEditComponent } from './modal-debt-infomation-edit/modal-debt-infomation-edit.component';
import { ModalDebtManageComponent } from './modal-debt-manage/modal-debt-manage.component';
import { ModalDebtInfomationComponent } from './modal-debt-infomation/modal-debt-infomation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

const routes: Routes = [
  {
    path: '',
    component: DebtManagementComponent,
  },
  {
    path: 'detail/:id',
    component: ModalDebtInfomationComponent,
    children: [
      {
        path: 'infomation',
        component: ModalDebtInfomationEditComponent,
      },
      {
        path: 'payment',
        component: ModalDebtPaymentComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    DebtManagementComponent,
    ModalDebtInfomationComponent,
    ModalDebtManageComponent,
    ModalDebtInfomationEditComponent,
    ModalDebtPaymentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxDaterangepickerMd,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class DebtManagementModule {}
