/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomHttpUrlEncodingCodec } from 'src/app/encoder';
import { BASE_PATH, COLLECTION_FORMATS, Configuration } from 'src/app';

@Injectable()
export class FcmService {
  protected basePath = environment.notificationHubUrl;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  public token = `Bearer ${localStorage.getItem(environment.authTokenKey)}`;
  // public  token = `Bearer ${localStorage.getItem(environment.authTokenKey)}`;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmGetMyNotifications(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmGetMyNotifications(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmGetMyNotifications(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmGetMyNotifications(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;
    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/fcm/getMyNotifications`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  public apiFcmGetMyNotificationsSummary(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/fcm/getMyNotificationsSummary`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param notificationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmNotificationIdMarkAsReadPut(
    notificationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmNotificationIdMarkAsReadPut(
    notificationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmNotificationIdMarkAsReadPut(
    notificationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmNotificationIdMarkAsReadPut(
    notificationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error(
        'Required parameter notificationId was null or undefined when calling apiFcmNotificationIdMarkAsReadPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/fcm/${encodeURIComponent(
        String(notificationId)
      )}/markAsRead`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param notificationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmNotificationIdMarkAsDeletePut(
    notificationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmNotificationIdMarkAsDeletePut(
    notificationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmNotificationIdMarkAsDeletePut(
    notificationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmNotificationIdMarkAsDeletePut(
    notificationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error(
        'Required parameter notificationId was null or undefined when calling apiFcmNotificationIdMarkAsReadPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/fcm/${encodeURIComponent(
        String(notificationId)
      )}/markAsDelete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmPushNotificationPost(
    body?: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmPushNotificationPost(
    body?: any,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmPushNotificationPost(
    body?: any,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmPushNotificationPost(
    body?: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/api/fcm/pushNotification`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmRegisterDevicePost(
    body?: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmRegisterDevicePost(
    body?: any,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmRegisterDevicePost(
    body?: any,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmRegisterDevicePost(
    body?: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/api/fcm/registerDevice`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param notificationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmNotificationIdCancelDeletePut(
    notificationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmNotificationIdCancelDeletePut(
    notificationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmNotificationIdCancelDeletePut(
    notificationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmNotificationIdCancelDeletePut(
    notificationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error(
        'Required parameter notificationId was null or undefined when calling apiFcmNotificationIdMarkAsReadPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/fcm/${encodeURIComponent(
        String(notificationId)
      )}/markAsDelete/cancel`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   *
   *
   * @param notificationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFcmNotificationIdDeletePut(
    notificationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiFcmNotificationIdDeletePut(
    notificationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiFcmNotificationIdDeletePut(
    notificationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiFcmNotificationIdDeletePut(
    notificationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error(
        'Required parameter notificationId was null or undefined when calling apiFcmNotificationIdMarkAsReadPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/fcm/${encodeURIComponent(
        String(notificationId)
      )}/delete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
