<div style="max-height: 500px; overflow: hidden; overflow-y: auto">
  <!-- <button
    class="btn-primary-add items-center gap-2px"
    type="button"
    (click)="divideQuantityPercent()"
  >
    Phân bố số lượng hàng hóa
  </button> -->
  <div class="w-auto m-15px">
    <div class="flex items-center gap-4px mb-6px">
      <span class="font-roboto-regular text-14px text-dark-grey"
        >Chọn tất cả</span
      >
      <input type="checkbox" [(ngModel)]="checkAll" (change)="selectAll()" />
    </div>
    <div
      class="w-full grid grid-cols-12 gap-2 sm:gap-5 mb-10px"
      *ngFor="let slot of listSlots; let i = index"
    >
      <!-- new % -->
      <div class="col-span-4" [ngClass]="isCheckIE?'':'flex flex-col-reverse'">
        <div class="flex flex-col">
          <div class="flex items-center">
            <span
              class="font-roboto-regular text-12px text-dark-grey"
              [ngClass]="
                touched &&
                (slot.new_value > 100 ||
                  slot.new_value < 0 ||
                  (!slot.new_value && slot.new_value !== 0) ||
                  (isCheckIE &&
                    !listSlots['isEditExport'] &&
                    slot.new_value + slot.used_percent > 100) ||
                  (!isCheckIE &&
                    !listSlots['isEditExport'] &&
                    slot.used_percent - slot.new_value < 0) ||
                  (!isCheckIE &&
                    !listSlots['isEditExport'] &&
                    slot.quantitySlot < slot.quantityImport &&
                    slot.new_value == slot.used_percent))
                  ? 'error-class'
                  : ''
              "
            >
              Ô {{ slot.code }} (%)<span class="text-red-light">*</span></span
            >
          </div>

          <div class="flex gap-10px items-baseline">
            <input type="checkbox" class="mt-20px" [(ngModel)]="slot.check" />
            <div class="w-full">
              <input
                [ngClass]="
                  touched &&
                  (slot.new_value > 100 ||
                    slot.new_value < 0 ||
                    (!slot.new_value && slot.new_value !== 0) ||
                    (isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.new_value + slot.used_percent > 100) ||
                    (!isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.used_percent - slot.new_value < 0) ||
                    (!isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.quantitySlot < slot.quantityImport &&
                      slot.new_value == slot.used_percent))
                    ? 'class-invalid'
                    : 'class-valid'
                "
                [readonly]="listSlots['isEditExport']"
                type="number"
                spellcheck="false"
                min="0"
                (ngModelChange)="onKeyUpValue($event, slot.check)"
                placeholder="Nhập % mới"
                [(ngModel)]="slot.new_value"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                class="font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-40px mt-8px py-8px pl-12px border bg-white border-grayscale-light-grey hover:border-primary-light focus:outline-none focus:ring-1 focus:ring-primary-blue"
              />
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="
                  touched &&
                  (slot.new_value > 100 ||
                    slot.new_value < 0 ||
                    (!slot.new_value && slot.new_value !== 0) ||
                    (isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.new_value + slot.used_percent > 100) ||
                    (!isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.used_percent - slot.new_value < 0) ||
                    (!isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.quantitySlot < slot.quantityImport &&
                      slot.new_value == slot.used_percent))
                "
                >{{
                  slot.new_value > 100 ||
                  slot.new_value < 0 ||
                  (!slot.new_value && slot.new_value !== 0)
                    ? 'Vui lòng nhập số % từ 0 đến 100'
                    : isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.new_value + slot.used_percent > 100
                    ? 'Phần trăm ô đang vượt quá 100%'
                    : !isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.used_percent - slot.new_value < 0
                    ? 'Phần trăm ô đang nhỏ hơn 0%'
                    : !isCheckIE &&
                      !listSlots['isEditExport'] &&
                      slot.quantitySlot < slot.quantityImport &&
                      slot.new_value == slot.used_percent
                    ? 'Phần trăm sử dụng không được bằng phần trăm hiện tại khi SL xuất nhỏ hơn SL tồn'
                    : ''
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- quantity slot -->
      <!-- ||
                (listSlots['isEditExport'] &&
                  slot.quantitySlot >
                    slot.quantityImport + slot.currentQuantitySlotExport) -->
      <div class="col-span-4" [ngClass]="isCheckIE?'':'flex flex-col-reverse'">
        <div class="flex flex-col">
          <span
            class="font-roboto-regular text-12px text-dark-grey"
            [ngClass]="
              touched &&
              ((!slot.quantitySlot && slot.quantitySlot == 0) ||
                (!isCheckIE &&
                  !listSlots['isEditExport'] &&
                  slot.quantitySlot > slot.quantityImport))
                ? 'error-class'
                : ''
            "
          >
            Số lượng<span class="text-red-light">* </span>
            <span class="ml-5px" *ngIf="!isCheckIE">
              ( SL hiện có của ô: {{ slot.quantityImport }} )</span
            >
          </span>
          <input
            [ngClass]="
              (touched && !slot.quantitySlot && slot.quantitySlot == 0) ||
              (!isCheckIE &&
                !listSlots['isEditExport'] &&
                slot.quantitySlot > slot.quantityImport)
                ? 'class-invalid'
                : 'class-valid'
            "
            (ngModelChange)="onKeyUpQuantity($event, slot.check, i)"
            (keyup)="percent($event.target.value, i)"
            type="number"
            (input)="getTotal($event)"
            spellcheck="false"
            min="0"
            placeholder="Nhập số lượng"
            [(ngModel)]="slot.quantitySlot"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            class="class-valid font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-40px mt-8px py-8px pl-12px border bg-white border-grayscale-light-grey hover:border-primary-light focus:outline-none focus:ring-1 focus:ring-primary-blue"
          />
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              (touched && !slot.quantitySlot && slot.quantitySlot == 0) ||
              (!isCheckIE &&
                !listSlots['isEditExport'] &&
                slot.quantitySlot > slot.quantityImport)
            "
          >
            {{
              !slot.quantitySlot && slot.quantitySlot == 0
                ? 'Vui lòng nhập số lượng'
                : !isCheckIE &&
                  !listSlots['isEditExport'] &&
                  slot.quantitySlot > slot.quantityImport
                ? 'Số lượng xuất phải nhỏ hơn số lượng hiện có của Ô'
                : ''
            }}
          </span>
          <span
            class="font-roboto-regular text-12px text-red-light font-medium not-italic"
            *ngIf="
              listSlots['isEditExport'] &&
              slot.quantitySlot >
                slot.quantityImport + slot.currentQuantitySlotExport
            "
          >
            Lưu ý: <br />SL xuất lớn hơn SL tồn thực tế (SL:
            {{ slot.quantityImport + slot.currentQuantitySlotExport }}) sau khi
            chỉnh sửa
          </span>
        </div>
      </div>
      <!-- current % -->
      <div class="col-span-4" [ngClass]="isCheckIE?'':'flex flex-col-reverse'">
        <div class="flex flex-col">
          <span class="font-roboto-regular text-12px text-dark-grey">
            Phần trăm cũ (%)</span
          >
          <input
            readonly
            type="text"
            placeholder="Số % cũ"
            [value]="slot.used_percent"
            class="cursor-pointer border overflow-hidden overflow-ellipsis bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-purplish-grey font-medium not-italic"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-span-full grid grid-cols-12 m-15px gap-5">
  <div class="col-span-4">Tổng số ô: {{ totalSlot }}</div>
  <div class="col-span-4" *ngIf="!isMobile">Tổng số lượng: {{ totalQuantity | number }}</div>
  <div class="col-span-4" *ngIf="isMobile"></div>
  <div class="col-span-4">
    <div class="flex item-center justify-end">
      <button
        class="btn-primary-add items-center gap-2px"
        type="button"
        (click)="savePercentUsedSlot()"
      >
        Lưu
      </button>

      <button
        class="btn-text-cancel items-center gap-2px"
        type="button"
        (click)="closeModal()"
      >
        Hủy
      </button>
    </div>
  </div>
</div>
