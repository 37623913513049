import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import BackgroundScroll from 'src/app/shared/utils/background-scroll.util';

@Component({
  selector: 'meu-modal-component',
  templateUrl: './modal-component.component.html',
  styleUrls: ['./modal-component.component.scss'],
})
export class ModalComponentComponent implements OnInit {
  @Input() title: string;
  @Input() isOpen = false;
  @Input('options') option: MxModalOption;
  @Output() closeModal = new EventEmitter();
  bgScrollDisable: BackgroundScroll = new BackgroundScroll();
  defaultMxModalOption = {
    styleClass: 'w-11/12 md:w-8/12 lg:w-7/12 xl:w-6/12',
    closeFromOutside: false,
    top: '50%',
    useDefaultPadding: true,
    hideCloseButton: false,
    callBackAfterClose: null,
    zIndex: '9998',
  };
  constructor() {}
  ngOnInit(): void {
    this.setDefaultValue();
    if (this.isOpen) {
      this.bgScrollDisable.disable(true);
    }
  }
  setDefaultValue() {
    if (this.option) {
      const inputOption = this.option;
      this.option = {
        ...this.defaultMxModalOption,
        ...inputOption,
      };
    } else {
      this.option = this.defaultMxModalOption;
    }
  }

  open() {
    this.isOpen = true;
    this.bgScrollDisable.disable(true);
  }

  close() {
    this.isOpen = false;
    if (this.option.callBackAfterClose != null) {
      this.option.callBackAfterClose();
    }

    this.bgScrollDisable.disable(false);
  }

  closeFromOutside() {
    if (this.option.closeFromOutside) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    this.bgScrollDisable.disable(false);
  }
}

export interface MxModalOption {
  top?: string;
  closeFromOutside?: boolean;
  hideCloseButton?: boolean;
  callBackAfterClose?: any;
  zIndex?: string;
  styleClass?: string;
}
