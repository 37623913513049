import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'meu-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {
  @Output() closeModalTest = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  closeModal(event) {
    event = false;
    this.closeModalTest.emit(event);
  }

}
