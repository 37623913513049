import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInventoryManagementMapComponent } from './app-inventory-management-map/app-inventory-management-map.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { AppInventoryManagementEditorComponent } from './app-inventory-management-editor/app-inventory-management-editor.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { AppInventoryContainerModalComponent } from './app-inventory-container-modal/app-inventory-container-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportExportHistoryModule } from '../import-export-history/import-export-history.module';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';
import { AppChangePositionModalComponent } from './app-change-position-modal/app-change-position-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  {
    path: '',
    component: AppInventoryManagementMapComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
  {
    path: 'admin',
    component: AppInventoryManagementMapComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
];

@NgModule({
  declarations: [
    AppInventoryManagementMapComponent,
    AppInventoryManagementEditorComponent,
    AppInventoryContainerModalComponent,
    AppChangePositionModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    ReactiveFormsModule,
    ImportExportHistoryModule,
    FormsModule,
    NgSelectModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class MapModule {}
