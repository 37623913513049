import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { UserDebtPaymentService } from 'src/app/api/userDebtPayment.service';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { saveAs } from 'file-saver';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'meu-modal-debt-payment',
  templateUrl: './modal-debt-payment.component.html',
  styleUrls: ['./modal-debt-payment.component.scss'],
})
export class ModalDebtPaymentComponent implements OnInit {
  @Input() userDetailID: string;
  public currenLstDebt$ = new BehaviorSubject<[]>([]);
  public listDebtPayment = [];
  public userDebt;
  public choosenFileName: string = '';
  public file;

  selectedStatus: string = "Tiền mặt"
  allStatus: any[] = [
    { value: 'cash', name: 'Tiền mặt' },
    { value: 'transfer', name: 'Chuyển khoản' },
  ];
  constructor(
    private fb: FormBuilder,
    private confirmService: ConfirmService,
    private userPaymentService: UserDebtPaymentService,
    private toastr: ToastrService
  ) {}

  debtPaymentForm = this.fb.group({
    debtPayment: this.fb.array([]),
  });

  get debtPayment() {
    return this.debtPaymentForm.controls['debtPayment'] as FormArray;
  }

  ngOnInit(): void {
    this.getPayment();
  }

  getPayment() {
    this.userPaymentService.apiUserDebtPaymentGet().subscribe((res) => {
      if (res.success) {
        this.userDebt = res.data.collection.filter(
          (item) => item.user_id == this.userDetailID
        );
      }
    });
  }
  addPayment() {
    this.debtPayment.insert(
      0,
      this.fb.group({
        method: ['cash', Validators.required],
        attachment: [, Validators.required],
        code: ['', Validators.required],
        date: [moment().format('DD/MM/YYYY - HH:mm'), [Validators.required]],
        user_id: this.userDetailID,
        payment: [, Validators.pattern('[0-9]+(,[0-9]+)*,?')],
      })
    );
  }
  removePayment(index: number) {
    this.debtPayment.removeAt(index);
  }
  deletePaymentReceipt(id: string) {
    let title = 'Xoá công nợ';
    let content = 'Bạn có chắc chắn muốn xóa công nợ này?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        })
      )
      .subscribe((e) => {
        if (e.type == 'delete') {
          this.userPaymentService
            .apiUserDebtPaymentIdDelete(id)
            .subscribe((res) => {
              if (res.success) {
                this.getPayment();
                this.toastr.success(res.message);
              } else {
                this.toastr.warnning(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
  savePayment(index: number, debt: object) {
    this.currenLstDebt$.next(
      (this.debtPaymentForm.get('debtPayment') as FormArray).at(index).value
    );
    this.postPayment(debt);

    this.removePayment(index);
  }
  updateFile(id: string) {
    this.userPaymentService
      .apiUserDebtPaymentIdUploadAttachmentPut(id, this.file)
      .subscribe((res) => {
        if (res.success) {
          this.getPayment();
          this.toastr.success(res.message);
        } else {
          this.toastr.warnning(res.message);
        }
      });
  }
  postPayment(item: any) {
    this.userPaymentService.apiUserDebtPaymentPost(item).subscribe((res) => {
      if (res.success) {
        this.getPayment();
        this.toastr.success(res.message);
        this.updateFile(res.data.id);
      } else {
        this.toastr.warnning(res.message);
      }
    });
  }
  choosenFile(event: any, index: number) {
    let file: FileList = event.target.files[0];
    this.file = file;
  }
  downloadFile(id: string) {
    var exportFile: Blob;
    this.userPaymentService
      .apiUserDebtPaymentIdAttachmentDownloadGet(id)
      .subscribe((res) => {
        if (res.type == 'application/json') {
          res.text().then((text) => {
            let json = JSON.parse(text.toString());
            this.toastr.error(json.message);
          });
        } else {
          exportFile = new Blob([res], {
            type: res.type,
          });
          saveAs(exportFile, 'TailieuCongNo.zip');
        }
      });
  }
}
