import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {
  constructor(private router: Router, private permissionService: PermissionService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> | boolean {
    const permissions = route.data['permissions'] as Array<string>;
    if (permissions) {
      this.permissionService.getMyPermissions().subscribe((lstPermission) => {
        const isHasPermission = lstPermission.includes(permissions);
        if (!isHasPermission) {
          this.router.navigate(['/error']);
        }
      });
    }
    return true;
  }
}
