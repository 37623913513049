import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';
import { CustomHttpUrlEncodingCodec } from 'src/app/encoder';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'meu-control-select-search-load-more',
  templateUrl: './control-select-search-load-more.component.html',
  styleUrls: ['./control-select-search-load-more.component.scss'],
})
export class ControlSelectSearchLoadMoreComponent implements OnInit {
  @Input() placeholder: string;
  @Input() defaultID: string = ''; // default id
  @Input() nameAPI: string;
  @Input() columnName: string; // column to display
  @Input() customColumn = '';
  @Input() stringFilter: string; // filter string api
  @Input() is_border: boolean = true; // border
  @Input() is_radius: boolean = true;
  @Input() formControl: FormControl;
  @Input() invalidState: boolean; // set validator
  @Input() is_readonly: boolean = false;
  @Input() addNew: boolean = false;
  @Input() styleCssWidth: string = '';
  @Input() styleCssPosition: string = '';
  @Input() float: string = '';
  @Output() changeItem = new EventEmitter();
  @Output() returnAdd = new EventEmitter();

  public keyUp = new Subject<KeyboardEvent>();
  stringfilter: string = '';
  cacheSelectedItem = [];
  public filteredInput: any[];
  public array: any = [];
  public page = 1;
  public pageSize = 5;
  public filters = null;

  selectCtrl = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null),
    data: new FormControl(null),
    default: new FormControl(null),
  });

  /** congif header */
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** congif header */
  public defaultHeaders1 = new HttpHeaders();
  public configuration1 = new Configuration();

  /// new code
  public isOpen: boolean = false;
  public total: number;
  public defaultItem;
  constructor(
    protected httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // if (
    //   changes.defaultID != undefined &&
    //   changes.defaultID.previousValue != undefined
    // ) {
    //   this.selectCtrl.patchValue({
    //     id: this.defaultID,
    //   });
    //   this.getDataByID(this.defaultID);
    // }

    // if (changes.defaultID?.currentValue) {
    //   this.selectCtrl.patchValue({
    //     id: this.defaultID,
    //   });
    //   this.getDataByID(this.defaultID);
    // }

    // if (changes.nameAPI != undefined) {
    //   this.page = 1;
    //   this.cacheSelectedItem = [];
    //   this.filteredInput = [];
    // }
    // if (changes.stringFilter != undefined) {
    //   this.cacheSelectedItem = [];
    //   this.filteredInput = [];
    //   this.filters = this.stringFilter;
    //   this.runAPI(this.nameAPI);
    // }
    // if (
    //   changes.stringFilter != undefined &&
    //   changes.defaultID?.previousValue != undefined
    // ) {
    //   this.page = 1;
    //   this.cacheSelectedItem = [];
    //   this.filteredInput = [];
    //   this.filters = this.stringFilter;
    //   this.runAPI(this.nameAPI);
    // } else {
    //   this.runAPI(this.nameAPI);
    // }
    // if (changes.page != undefined) {
    //   this.runAPI(this.nameAPI);
    // }

    if (changes.defaultID != undefined) {
      // this.selectCtrl.patchValue({
      //   id: this.defaultID,
      // });

      this.getDataByID(this.defaultID);
    }
    if (changes.nameAPI != undefined) {
      this.page = 1;
      this.cacheSelectedItem = [];
      this.filteredInput = [];
    }
    if (changes.stringFilter != undefined) {
      this.page = 1;
      this.cacheSelectedItem = [];
      this.filteredInput = [];
      this.defaultItem = null;
      this.filters = this.stringFilter;
      this.runAPI(this.nameAPI);
    } else {
      this.runAPI(this.nameAPI);
    }
    if (changes.page != undefined) {
      this.runAPI(this.nameAPI);
    }
  }
  ngOnInit(): void {
    // this.selectCtrl.valueChanges.subscribe((res) => {
    //   this.emitChangeValue(this.selectCtrl.value);
    //   if (res?.id != null && res?.id != undefined) {
    //     let item = this.filteredInput.find((x) => x.id == res.id);
    //     if (item != undefined) {
    //       this.cacheSelectedItem.push(item);
    //     } else {
    //       this.getDataByID(res.id);
    //     }
    //   }
    // });
    // this.search();
    this.selectCtrl.valueChanges.subscribe((res) => {
      this.emitChangeValue(this.selectCtrl.value);
      if (res?.id != null && res?.id != undefined) {
        let item = this.filteredInput.find((x) => x.id == res.id);
        if (item != undefined) {
          this.cacheSelectedItem.push(item);
        } else {
          this.getDataByID(res.id);
        }
      }
    });
  }

  // onChangeItem(event) {
  //   this.selectCtrl.patchValue({
  //     id: event?.id,
  //     name: event ? event[`${this.columnName}`] : null,
  //     data: event ? event : null,
  //   });
  // }

  //API
  runAPI(nameAPI) {
    if (nameAPI == null || nameAPI.trim() == '') {
      return;
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (this.filters !== undefined && this.filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>this.filters);
    }
    if (this.page !== undefined && this.page !== null) {
      queryParameters = queryParameters.set('Page', <any>this.page);
    }
    if (this.pageSize !== undefined && this.pageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>this.pageSize);
    }
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        if (res.success) {
          let array = [...res.data.collection];
          this.total = res.data.total;

          if (this.filteredInput.length == 0) {
            this.filteredInput = [...array];
          } else {
            for (let i = 0; i < array?.length; i++) {
              if (this.cacheSelectedItem != undefined) {
                let index = this.cacheSelectedItem.findIndex(
                  (x) => x.id == array[i].id
                );
                let indexfilteredInput = this.filteredInput.findIndex(
                  (x) => x.id == array[i].id
                );
                if (index == -1 && indexfilteredInput == -1) {
                  this.filteredInput = [...this.filteredInput, array[i]];
                }
              }
            }
          }
          this.changeDetectorRef.detectChanges();
        }
      });
    if (
      this.cacheSelectedItem != null &&
      this.cacheSelectedItem != undefined &&
      this.cacheSelectedItem.length > 0
    ) {
      for (let i = 0; i < this.cacheSelectedItem.length; i++) {
        let index = this.filteredInput.findIndex(
          (x) => x.id == this.cacheSelectedItem[i].id
        );
        if (index < 0) {
          this.filteredInput = [
            ...this.filteredInput,
            this.cacheSelectedItem[i],
          ];
          // this.filteredInput.push(this.cacheSelectedItem[i]);
        }
      }
    }
  }
  getDataByID(id) {
    if (id === null || id === '' || id === undefined) {
      return;
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let filter = ' id==' + id;
    queryParameters = queryParameters.set('Filters', <any>filter);
    let headers = this.defaultHeaders1;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration1.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + this.nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration1.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        if (res.success) {
          let array = res.data.collection;
          for (let i = 0; i < array?.length; i++) {
            this.cacheSelectedItem.push(array[i]);

            if (this.customColumn) {
              this.defaultItem =
                array[i]?.[`${this.customColumn}`] +
                ' | ' +
                array[i]?.[`${this.columnName}`];
            } else {
              this.defaultItem = array[i]?.[`${this.columnName}`];
            }
            this.selectCtrl.patchValue({
              id: array[i]?.id,
              name: array[i]?.[`${this.columnName}`],
              data: array[i],
              default: true,
            });
            if (this.formControl) {
              this.formControl.setValue(array[i]?.id);
            }

            let index = this.filteredInput.findIndex(
              (x) => x.id == array[i].id
            );
            if (index < 0) {
              this.filteredInput = [...this.filteredInput, array[i]];
              // this.filteredInput.push(array[i]);
            }
          }
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  // scrollToEnd() {
  //   this.isScrollToEnd = true;
  // }

  // open() {
  //   this.filters = null;
  //   this.runAPI(this.nameAPI);
  // }

  /**emit */
  emitChangeValue(event) {
    this.changeItem.emit(event);
  }

  // new code
  open() {
    if (!this.is_readonly) {
      this.isOpen = true;
      this.runAPI(this.nameAPI);
      // if (this.defaultID) {
      //   console.log('1');

      //   this.getDataByID(this.defaultID);
      // }
    }
  }
  closeOutside() {
    this.isOpen = false;
    //search
  }
  chooseItem(event) {
    if (event) {
      this.selectCtrl.patchValue({
        id: event?.id,
        name: event ? event[`${this.columnName}`] : null,
        data: event ? event : null,
        default: false,
      });
      if (this.formControl) {
        this.formControl.setValue(event.id);
      }
      if (this.customColumn) {
        this.defaultItem =
          event?.[`${this.customColumn}`] +
          ' | ' +
          event?.[`${this.columnName}`];
      } else {
        this.defaultItem = event?.[`${this.columnName}`];
      }
    }
    this.closeOutside();
  }
  loadMore() {
    this.pageSize += 5;
    this.runAPI(this.nameAPI);
  }
  search() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value: any) => {
        this.page = 1;
        this.filteredInput = [];

        this.filters = null;
        if (value != '' || value != null) {
          if (this.stringFilter) {
            this.filters =
              this.stringFilter + ',' + this.columnName + '@=*' + event;
          } else {
            this.filters = this.columnName + '@=*' + event;
          }
        } else {
          if (this.stringFilter != null || this.stringFilter != '') {
            this.filters = this.stringFilter;
          }
        }
        this.runAPI(this.nameAPI);
      });
  }
  clear() {
    this.defaultItem = null;
    this.selectCtrl.patchValue({
      id: null,
      name: null,
      data: null,
      default: false,
    });
    if (this.formControl) {
      this.formControl.setValue(null);
    }
  }
}
