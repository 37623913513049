import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ContainerSellProductHistoryService } from 'src/app/api/containerSellProductHistory.service';
import { UserDebtService } from 'src/app/api/userDebt.service';
import { UserDebtPaymentService } from 'src/app/api/userDebtPayment.service';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-debt-management',
  templateUrl: './debt-management.component.html',
  styleUrls: ['./debt-management.component.scss']
})
export class DebtManagementComponent implements OnInit {
  isOpenBottomsheet: boolean = false;
  titleModal: string = ''
  listUserDebt = [];
  totalTurnOver: number = 0
  totalDebt: number = 0
  totalActualPaid: number = 0
  userDetail;
  userDetailID;
  pageUser: number = 10
  totalUser: number = 0

  constructor(
    private userDebtService: UserDebtService,

  ) { }

  ngOnInit(): void {
    this.getUserDebt()
  }

  getUserDebt() {
    this.userDebtService.apiUserDebtGet(
      `turnover!=0`, '', 1, this.pageUser
    ).subscribe((res) => {
      if (res.success) {

        this.listUserDebt = res.data.collection
        this.totalUser = res.data.total

        for (let i = 0; i < this.listUserDebt.length; i++) {
          this.totalActualPaid += this.listUserDebt[i].actual_paid
          this.totalDebt += Math.abs(this.listUserDebt[i].debt)
          this.totalTurnOver = this.totalDebt + this.totalActualPaid
        }
      }
    })
  }

  openModal(name: string, id: string) {
    this.titleModal = name
    if (window.screen.width < 767) {
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      } else {
        this.isOpenBottomsheet = false
      }
    }

    this.userDebtService.apiUserDebtIdGet(id).subscribe((res) => {
      if (res.success) {
        this.userDetail = res.data
      }
    })
  }
  closeResquest(event: any) {
    if (event == false) {
      this.isOpenBottomsheet = false
    }
  }

  searchUser(key: string) {
    this.userDebtService.apiUserDebtGet(
      `turnover!=0,extend_customer_name@=${key}`, '', 1, this.pageUser
    )
      .pipe(debounceTime(3000))
      .subscribe((res) => {
        if (res.success) {
          this.listUserDebt = res.data.collection
          this.totalUser = res.data.total
        }
      })
  }

  sendUserDetailId(id) {
    this.userDetailID = id
  }
  loadMorePage() {
    this.pageUser += 10;
    let listDebt = this.listUserDebt
    this.listUserDebt.push(listDebt)

    this.getUserDebt()
  }
}
