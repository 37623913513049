<div class="container">
  <div class="w-full px-16px bg-white shadow-md rounded-4px mt-12px contain">
    <div
      class="w-full py-12px mt-8px flex flex-col md:flex-row md:justify-between md:items-center title"
    >
      <div
        class="font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Dịch vụ sử dụng
      </div>

      <div class="flex flex-wrap md:float-right items-center gap-8px margin-t">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchService"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
        <button
          class="btn-primary-icon flex items-center gap-4px"
          (click)="open(null)"
        >
          <img src="assets/icons/handle-icons/icon_add.svg" />
          <span>Thêm mới</span>
        </button>
      </div>
    </div>

    <div class="w-full">
      <table
        class="w-full border-separate table-fixed"
        cellspacing="0"
        cellpadding="0"
        *ngIf="totalArray > 0; else noInfo"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden"
          >
            <th class="md:text-center w-40px">#</th>
            <th
              class="text-left col-name cursor-pointer"
              (click)="Sort('extend_customer_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Tên khách hàng'"
                  >Tên khách hàng</span
                >

                <img
                  *ngIf="
                    sort.includes('extend_customer_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left items-center col-code cursor-pointer"
              (click)="Sort('extend_contract_code')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Mã hợp đồng'"
                >
                  Mã hợp đồng
                </span>

                <img
                  *ngIf="
                    sort.includes('extend_contract_code');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-service cursor-pointer"
              (click)="Sort('extend_service_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Tên dịch vụ'"
                  >Tên dịch vụ</span
                >

                <img
                  *ngIf="
                    sort.includes('extend_service_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-date cursor-pointer"
              (click)="Sort('created_at')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Thời gian'"
                  >Thời gian</span
                >

                <img
                  *ngIf="sort.includes('created_at'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-quantity cursor-pointer"
              (click)="Sort('quantity')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Số lượng'"
                  >Số lượng</span
                >

                <img
                  *ngIf="sort.includes('quantity'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img
                    class="float-right"
                    src="assets/icons/sort/sort_normal.svg"
                  />
                </ng-template>
              </div>
            </th>
            <th
              class="text-left col-amount cursor-pointer"
              (click)="Sort('amount')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Đơn giá'"
                  >Đơn giá</span
                >

                <img
                  *ngIf="sort.includes('amount'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <th class="text-left col-unit">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="'Đơn vị'"
                >Đơn vị</span
              >
            </th>

            <th class="text-center col-thaotac">
              <span class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                >Thao tác</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let sv of services; let i = index"
            class="font-roboto-regular text-14px text-dark-grey"
          >
            <td
              data-label="#"
              class="md:text-center w-40px td-stt overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="indexArray + i + 1"
            >
              {{ indexArray + i + 1 }}
            </td>
            <td
              data-label="Tên khách hàng"
              class="td-name text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="
                sv['extend_customer_name'] ? sv['extend_customer_name'] : '--'
              "
            >
              {{
                sv['extend_customer_name'] ? sv['extend_customer_name'] : '--'
              }}
            </td>
            <td
              data-label="Mã hợp đồng"
              class="td-code text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="
                sv['extend_contract_code'] ? sv['extend_contract_code'] : '--'
              "
            >
              {{
                sv['extend_contract_code'] ? sv['extend_contract_code'] : '--'
              }}
            </td>
            <td
              data-label="Tên dịch vụ"
              class="td-service text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="
                sv['extend_service_name'] ? sv['extend_service_name'] : '--'
              "
            >
              {{ sv['extend_service_name'] ? sv['extend_service_name'] : '--' }}
            </td>
            <td
              data-label="Thời gian"
              class="td-date text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="
                sv['created_at']
                  ? (sv['created_at'] | date: 'dd/MM/yyyy HH:mm:ss')
                  : '--'
              "
            >
              {{
                sv['created_at']
                  ? (sv['created_at'] | date: 'dd/MM/yyyy HH:mm:ss')
                  : '--'
              }}
            </td>
            <td
              data-label="Số lượng"
              class="td-quantity text-right text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="sv.quantity ? (sv.quantity | number) : '--'"
            >
              {{ sv.quantity ? (sv.quantity | number) : '--' }}
            </td>
            <td
              data-label="Đơn giá"
              class="td-unit text-right text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="sv.amount ? (sv.amount | number) : '--'"
            >
              {{ sv.amount ? (sv.amount | number) : '--' }}
            </td>
            <td
              data-label="Đơn vị"
              class="td-unit text-left text-14px overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
              [tooltip]="
                sv['extend_service_unit'] ? sv['extend_service_unit'] : '--'
              "
            >
              {{ sv['extend_service_unit'] ? sv['extend_service_unit'] : '--' }}
            </td>

            <td data-label="Thao tác" class="text-center td-thaotac py-0">
              <div class="flex justify-end md:justify-center">
                <button
                  (click)="open(sv.id)"
                  class="hover:bg-primary-lightest focus:outline-none p-10px rounded-full button-add-edit"
                  tooltip="Chỉnh sửa"
                >
                  <img
                    src="assets/icons/handle-icons/u_edit-alt.svg"
                    class="w-20px h-20px"
                  />
                </button>
                <button
                  tooltip="Xóa"
                  class="hover:bg-primary-lightest rounded-full focus:outline-none button-add-edit p-10px"
                  (click)="deleteconfirm(sv.id)"
                >
                  <img
                    src="assets/icons/handle-icons/u_trash-alt.svg"
                    class="w-20px h-20px"
                  />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noInfo>
        <table
          class="w-full border-separate table-fixed"
          cellspacing="0"
          cellpadding="0"
        >
          <thead>
            <tr
              class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden"
            >
              <th class="md:text-center w-40px">#</th>
              <th
                class="text-left col-name cursor-pointer"
                (click)="Sort('extend_customer_name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Tên khách hàng'"
                    >Tên khách hàng</span
                  >

                  <img
                    *ngIf="
                      sort.includes('extend_customer_name');
                      else normalSearch
                    "
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left items-center col-code cursor-pointer"
                (click)="Sort('extend_contract_code')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Mã hợp đồng'"
                  >
                    Mã hợp đồng
                  </span>

                  <img
                    *ngIf="
                      sort.includes('extend_contract_code');
                      else normalSearch
                    "
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-service cursor-pointer"
                (click)="Sort('extend_service_name')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Tên dịch vụ'"
                    >Tên dịch vụ</span
                  >

                  <img
                    *ngIf="
                      sort.includes('extend_service_name');
                      else normalSearch
                    "
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-date cursor-pointer"
                (click)="Sort('created_at')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Thời gian'"
                    >Thời gian</span
                  >

                  <img
                    *ngIf="sort.includes('created_at'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-quantity cursor-pointer"
                (click)="Sort('quantity')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Số lượng'"
                    >Số lượng</span
                  >

                  <img
                    *ngIf="sort.includes('quantity'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img
                      class="float-right"
                      src="assets/icons/sort/sort_normal.svg"
                    />
                  </ng-template>
                </div>
              </th>
              <th
                class="text-left col-amount cursor-pointer"
                (click)="Sort('amount')"
              >
                <div class="flex justify-between items-center">
                  <span
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="'Đơn giá'"
                    >Đơn giá</span
                  >

                  <img
                    *ngIf="sort.includes('amount'); else normalSearch"
                    [src]="sortName"
                  />
                  <ng-template #normalSearch>
                    <img src="assets/icons/sort/sort_normal.svg" />
                  </ng-template>
                </div>
              </th>
              <th class="text-left col-unit">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  [tooltip]="'Đơn vị'"
                  >Đơn vị</span
                >
              </th>

              <th class="text-center col-thaotac">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >Thao tác</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="font-roboto-regular text-14px h-40px no-info">
              <td colspan="9" class="td-noinfo">Không có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>

    <div class="w-full">
      <meu-pagination-component
        *ngIf="totalArray != 0"
        [total]="totalArray"
        [array]="array"
        [changePage]="onPagingFunc"
      ></meu-pagination-component>
    </div>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-service-list-modal
    [service_id]="id_service"
    (closeModalService)="closeModal($event)"
  ></meu-service-list-modal>
</meu-modal-component>

<!-- Modal bottom sheet -->
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-service-list-modal
    [service_id]="id_service"
    (closeModalService)="closeModalBottom($event)"
  ></meu-service-list-modal>
</meu-modal-popup-bottom>
