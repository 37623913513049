import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';

@Component({
  selector: 'meu-print-qrcode',
  templateUrl: './print-qrcode.component.html',
  styleUrls: ['./print-qrcode.component.scss']
})
export class PrintQrcodeComponent implements OnInit {
  @Input() imExport_code: string;
  @Output() closeModalQR = new EventEmitter();

  allSkus = [];
  arrTemp = [];

  constructor(private importExportHistoryService: ImportExportHistoryService) { }

  ngOnInit(): void {
    this.getQRCodeByimExportCode();
  }

  closeModal(event = false) {
    this.closeModalQR.emit({ success: event });
  }

  getQRCodeByimExportCode() {
    this.importExportHistoryService
      .apiImportExportHistoryCodeGet(this.imExport_code)
      .subscribe((res) => {
        if (res.success) {
          this.arrTemp = res.data.collection;

          let groups = this.arrTemp.reduce((groups, item) => {
            const group = groups[item.group_number] || [];
            group.push(item);
            groups[item.group_number] = group;
            return groups;
          }, {});

          let obj: any;
          Object.values(groups).forEach((element) => {
            obj = element[0];
            obj.barcode = obj.barcode;
            obj.sku_code = obj.sku_code;
            obj.created_at = obj.created_at;
            obj.batch_code = obj.batch_code;
            obj.container_code = obj.container_code;
            obj.receipt_date = obj.receipt_date;
            obj.issue_date = obj.issue_date;
            obj.isImExported = obj.isImExported;

            let arr = element as any[];
            let total = 0;
            arr.forEach((item) => {
              total += item.quantity;
            });
            obj.quantity = total;

            obj.expiry_date = obj.expiry_date;
            this.allSkus.push(obj);
          });
          this.allSkus = [...this.allSkus];
          for (let i = 0; i < this.allSkus.length; i++) {
            this.allSkus[i].inputnumber = 1;
          }
        }
      });
  }


  plus(index) {
    this.allSkus[index].inputnumber++;
  }

  minus(index) {
    if (this.allSkus[index].inputnumber != 1) {
      this.allSkus[index].inputnumber--;
    }
  }

  checkCheckBoxvalue(event, element) {
    if (event.target.checked) {
      element.isCheck = true;
    }
    else {
      element.isCheck = false;
    }
  }
}
