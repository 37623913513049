<div class="container grid-contain">
  <div class="col-span-full grid grid-cols-12 h-auto font-roboto">
    <div class="col-span-12 container-12 content-m table-dashboard">
      <div class="col-span-full grid grid-cols-12 mt-16px">
        <div
          class="col-span-12 md:col-span-3 margin-mt-21 container-9 items-center justify-items-center mt-5px"
        >
          <p
            class="text-16px text-left font-roboto-medium title-dashboard leading24 text-dark-grey"
          >
            Tổng quan
          </p>
        </div>
        <div class="col-span-12 md:col-span-9 container-3">
          <div
            class="margin-mt-13 w-full h-full flex md:items-center flex-wrap md:justify-end gap-6px"
          >
            <!-- user -->
            <div class="mr-16px border-config mt-1px" permission="ADMIN">
              <meu-filter-user-modal
                [userID]="user_id"
                (emitUserID)="emitUserID($event)"
              ></meu-filter-user-modal>
            </div>
            <!-- date -->
            <div *ngIf="dateSelected && !isMobile" class="dropdown">
              <button
                type="button"
                href="javascript:void(0)"
                class="flex text-sm rounded-md focus:outline-none items-center h-38px"
                aria-expanded="false"
                aria-haspopup="true"
                aria-controls="layout-user-action"
              >
                <div
                  class="flex justify-between items-center font-roboto-medium w-160px bg-white rounded-4px text-grey-label cursor-pointer text-14px pl-12px pr-7px h-full mt--5px py-6px shadow-sm"
                >
                  <div class="flex items-center gap-8px">
                    <img class="w-20px h-20px" [src]="dateSelected?.iconUrl" />
                    {{ dateSelected?.name || '--' }}
                  </div>
                  <img
                    src="/assets/icons/product-categories-icons/u_angle-down.svg"
                    alt=""
                    class="ml-5px w-20px h-20px"
                  />
                </div>
              </button>

              <div
                class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
              >
                <div
                  class="absolute left-0 md:right-0 w-160px mt-1 origin-top-right bg-white text-grey-med rounded-md shadow outline-none"
                  aria-labelledby="headlessui-menu-button-2"
                  id="layout-user-action"
                  role="menu"
                >
                  <div class="py-2">
                    <div
                      class="text-grey-med cursor-pointer h-40px hover:bg-primary-lightest flex flex-col justify-center text-center w-full px-3 font-roboto-regular text-14px"
                      *ngFor="let date of dateOption"
                      (click)="onChangeDate(date)"
                    >
                      <div class="flex gap-8px">
                        <img class="w-20px h-20px" [src]="date?.iconUrl" />
                        {{ date.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isMobile" class="w-full">
              <ng-select
                class="mt-8px border-none focus:outline-none custome-select-date shadow-sm"
                bindLabel="name"
                bindValue="id"
                [clearable]="false"
                [searchable]="false"
                [items]="dateOption"
                [(ngModel)]="dateMobileSelected"
                (change)="getValueDate($event)"
              >
                <ng-template ng-label-tmp let-item="item">
                  <div class="flex items-center space-x-2">
                    <img
                      height="20"
                      width="20"
                      [src]="item.iconUrl"
                      class=""
                    />
                    <span class="text-14px font-roboto-regular text-dark-grey">{{
                      item.name
                    }}</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="flex items-center space-x-2">
                    <img height="20" width="20" [src]="item.iconUrl" />
                    <span>{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <!-- report -->
            <div>
              <button
                (click)="openExportReport()"
                class="bg-white rounded-4px ml-50-ipad hide-m flex items-center w-157px h-38px mt-1px border-export font-roboto-medium text-14px text-center pl-25px outline-none focus:outline-none text-grey-med shadow-sm"
              >
                <img
                  src="assetsi/cons/dashboard-client/u_print.svg"
                  class="mr-4px w-20px h-20px"
                  alt=""
                />
                Xuất báo cáo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-full grid grid-cols-12 gap-4 gap-2-m container-12 container-12-ipad mt-8px"
      >
        <button
          class="col-span-3 bg-primary-dodger-blue h-95px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none"
        >
          <div class="col-span-2 items-center margin-center">
            <img
              src="../../../assets/icons/dashboard-client/u_wallet.svg"
              alt=""
            />
          </div>
          <div class="col-span-7 grid grid-rows-2 text-white text-left">
            <div
              class="row-span-1 font-roboto-medium text-16px pt-30px"
              [tooltip]="cosTotal | currency: ' ':'symbol':'1.0-0'"
            >
              {{ cosTotal | currency: ' ':'symbol':'1.0-0' }}
            </div>
            <div
              class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
            >
              Tổng tiền phải trả
            </div>
          </div>
          <!-- <div class="col-span-3 flex flex-row-reverse items-stretch pr-10px pt-47px text-11px text-white font-roboto-medium">
                        <p>+12,5%</p>
                        <img src="../../../../assets/icons/dashboard-icons/Combined Shape.svg" alt="" class="w-8px h-8px">
                    </div> -->
        </button>
        <button
          (click)="openTotalImport()"
          class="col-span-3 bg-white h-95px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none"
        >
          <div class="col-span-2 items-center margin-center">
            <img src="assets/icons/dashboard-icons/Misc_box.svg" alt="" />
          </div>
          <div class="col-span-7 grid grid-rows-2 text-left">
            <div class="row-span-1 font-roboto-medium text-16px pt-30px">
              {{ totalImport }}
            </div>
            <div
              class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
            >
              Phiếu nhập hàng
            </div>
          </div>
          <!-- <div class="col-span-3 flex flex-row-reverse items-stretch pr-10px pt-47px text-11px text-green-blue font-roboto-medium">
                        <p>+12,5%</p>
                        <img src="../../../assets/icons/dashboard-client/Combined Shape.svg" alt="" class="w-8px h-8px">
                    </div> -->
        </button>
        <button
          (click)="openTotalExport()"
          class="col-span-3 bg-white h-95px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none"
        >
          <div class="col-span-2 items-center margin-center">
            <img
              src="assets/icons/dashboard-client/Finance_Transportation.svg"
              alt=""
            />
          </div>
          <div class="col-span-7 grid grid-rows-2 text-left">
            <div class="row-span-1 font-roboto-medium text-16px pt-30px">
              {{ totalExport }}
            </div>
            <div
              class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
            >
              Phiếu xuất hàng
            </div>
          </div>
          <!-- <div class="col-span-3 flex flex-row-reverse items-stretch pr-10px pt-47px text-11px text-green-blue font-roboto-medium">
                        <p>+12,5%</p>
                        <img src="../../../assets/icons/dashboard-client/Combined Shape.svg" alt="" class="w-8px h-8px">
                    </div> -->
        </button>
        <button
          class="col-span-3 bg-white h-95px rounded-4px grid grid-cols-12 gap-3 shadow-sm col-span-12-m outline-none focus:outline-none"
        >
          <div class="col-span-2 items-center margin-center">
            <img
              src="assets/icons/dashboard-client/Files_file-text.svg"
              alt=""
            />
          </div>
          <div class="col-span-7 grid grid-rows-2 text-left">
            <div class="row-span-1 font-roboto-medium text-16px pt-30px">
              {{ contractActive }}
            </div>
            <div
              class="row-span-1 text-12px font-roboto-regular margin-top-sub-5px pt-2px"
            >
              Hợp đồng đang hoạt động
            </div>
          </div>
        </button>
      </div>

      <!-- serivce cost -->
      <div
        class="col-span-full bg-white mt-8px rounded-4px shadow-sm px-10px pb-8px table-service-cost"
      >
        <div
          class="text-16px text-left font-roboto-medium title-dashboard leading24 text-dark-grey py-8px"
        >
          Dịch vụ sử dụng
        </div>
        <div class="col-span-12 overflow-x-auto" style="overflow-y: hidden">
          <table
            class="w-full border-separate table-fixed"
            cellpadding="0"
            cellspacing="0"
          >
            <thead>
              <tr
                class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
              >
                <th class="text-center col col-1">#</th>
                <th class="text-left col col-2">Mã hợp đồng</th>
                <th class="text-left col col-3">Tên dịch vụ</th>
                <th class="text-left col col-4">Thời gian</th>
                <th class="text-right col col-5">Số lượng</th>
                <th class="text-right col col-6">Đơn giá</th>
                <th class="text-right col col-7">Tổng tiền</th>
                <th class="text-center col col-8">Đơn vị</th>
              </tr>
            </thead>

            <!-- DATA TABLE -->
            <tbody
              class="font-roboto-regular text-dark-grey text-14px"
              *ngIf="totalCostService != 0; else noInfo1"
            >
              <tr
                *ngFor="let item of myCostService; let i = index"
                class="mh-40px"
              >
                <td
                  class="td-stt whitespace-nowrap overflow-hidden overflow-ellipsis text-center border-l"
                  [title]="(pageIndexCS - 1) * pageSizeCS + i + 1"
                  data-label="#"
                >
                  {{ (pageIndexCS - 1) * pageSizeCS + i + 1 }}
                </td>

                <td data-label="Mã hợp đồng">
                  <div
                    class="cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
                    [title]="item.extend_contract_code"
                  >
                    <span>{{ item.extend_contract_code || '--' }}</span>
                  </div>
                </td>

                <td data-label="Tên dịch vụ">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span [title]="item.extend_service_name">
                      {{ item.extend_service_name || '--' }}
                    </span>
                  </div>
                </td>

                <td data-label="Thời gian">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span
                      [title]="
                        (item.created_at | date: 'dd/MM/yyyy HH:mm:ss') || '--'
                      "
                    >
                      {{
                        (item.created_at | date: 'dd/MM/yyyy HH:mm:ss') || '--'
                      }}
                    </span>
                  </div>
                </td>

                <td data-label="Số lượng">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                  >
                    <span
                      *ngIf="
                        item.extend_service_code == 'PBXNV' ||
                          item.extend_service_code == 'PBXXR';
                        else quantity
                      "
                      [title]="
                        (item.extend_service_cost_quantity_exception
                          | meucurrency) || '--'
                      "
                    >
                      {{
                        (item.extend_service_cost_quantity_exception
                          | meucurrency) || '--'
                      }}
                    </span>
                    <ng-template #quantity>
                      <span [title]="(item.quantity | meucurrency) || '--'">
                        {{ (item.quantity | meucurrency) || '--' }}
                      </span>
                    </ng-template>
                  </div>
                </td>

                <td
                  data-label="Đơn giá"
                  class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                >
                  <span [title]="(item.amount | meucurrency) || '--'">
                    {{ (item.amount | meucurrency) || '--' }}
                  </span>
                </td>

                <td
                  data-label="Tổng tiền"
                  class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                >
                  <span
                    *ngIf="
                      item.extend_service_code == 'PVO' ||
                        item.extend_service_code == 'PLKLTO' ||
                        item.extend_service_code == 'PLKNTKO' ||
                        item.extend_service_code == 'PLKLTKL' ||
                        item.extend_service_code == 'PLKNTKL' ||
                        item.extend_service_code == 'PBXNV' ||
                        item.extend_service_code == 'PBXXR';
                      else codeService
                    "
                    [title]="
                      (item.extend_service_cost_total_exception
                        | meucurrency) || '--'
                    "
                  >
                    {{
                      (item.extend_service_cost_total_exception
                        | meucurrency) || '--'
                    }}
                  </span>
                  <ng-template #codeService>
                    <span
                      [title]="
                        (item.extend_service_cost_total | meucurrency) || '--'
                      "
                    >
                      {{
                        (item.extend_service_cost_total | meucurrency) || '--'
                      }}
                    </span>
                  </ng-template>
                </td>

                <td data-label="Đơn vị">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right md:text-center"
                  >
                    <span [title]="item.extend_service_unit || '--'">
                      {{ item.extend_service_unit || '--' }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>

            <ng-template #noInfo1>
              <tbody>
                <tr
                  class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
                >
                  <td
                    colspan="8"
                    class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
                  >
                    Không có dữ liệu
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
          <div *ngIf="totalCostService != 0" class="mt-m-10px">
            <meu-pagination-component
              [inputPageSize]="5"
              [total]="totalCostService"
              [changePage]="changePageFunc"
              [setPropertyTotal]="true"
              (onChangeValue)="onPagingCostService($event)"
            ></meu-pagination-component>
          </div>
        </div>
      </div>

      <!-- contract -->
      <div
        class="col-span-full bg-white mt-8px rounded-4px shadow-sm px-10px pb-8px table-contract"
      >
        <div
          class="text-16px text-left font-roboto-medium title-dashboard leading24 text-dark-grey py-8px"
        >
          Danh sách hợp đồng
        </div>
        <div class="col-span-12 overflow-x-auto" style="overflow-y: hidden">
          <table
            class="w-full border-separate table-fixed"
            cellpadding="0"
            cellspacing="0"
          >
            <thead>
              <tr
                class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
              >
                <th class="text-center col col-1">#</th>
                <th class="text-left col col-2">Mã hợp đồng</th>
                <th class="text-left col col-3">Loại hợp đồng</th>
                <th class="text-left col col-4">Ngày bắt đầu</th>
                <th class="text-left col col-5">Ngày kết thúc</th>
                <th class="text-right col col-6">Giá trị</th>
                <th class="text-center col col-7">Trạng thái</th>
                <th class="text-center col col-8">Thao tác</th>
              </tr>
            </thead>

            <!-- DATA TABLE -->
            <tbody
              class="font-roboto-regular text-dark-grey text-14px"
              *ngIf="totalContract != 0; else noInfo2"
            >
              <tr
                *ngFor="let item of myContractData; let i = index"
                class="mh-40px"
              >
                <td
                  class="td-stt whitespace-nowrap overflow-hidden overflow-ellipsis text-center border-l"
                  [title]="(pageIndexCS - 1) * pageSizeCS + i + 1"
                  data-label="#"
                >
                  {{ (pageIndexCS - 1) * pageSizeCS + i + 1 }}
                </td>

                <td data-label="Mã hợp đồng">
                  <div
                    class="cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
                    [title]="item.code"
                  >
                    <span>{{ item.code || '--' }}</span>
                  </div>
                </td>

                <td data-label="Loại hợp đồng">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span [title]="item.extend_contract_type">
                      {{ item.extend_contract_type || '--' }}
                    </span>
                  </div>
                </td>

                <td data-label="Ngày bắt đầu">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span
                      [title]="
                        (item.date_contract_start
                          | date: 'dd/MM/yyyy HH:mm:ss') || '--'
                      "
                    >
                      {{
                        (item.date_contract_start | date: 'dd/MM/yyyy') || '--'
                      }}
                    </span>
                  </div>
                </td>

                <td data-label="Ngày kết thúc">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                  >
                    <span
                      [title]="
                        (item.date_contract_end
                          | date: 'dd/MM/yyyy HH:mm:ss') || '--'
                      "
                    >
                      {{
                        (item.date_contract_end | date: 'dd/MM/yyyy') || '--'
                      }}
                    </span>
                  </div>
                </td>

                <td
                  data-label="Giá trị"
                  class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                >
                  <span [title]="(item.actual_amount | meucurrency) || '--'">
                    {{ (item.actual_amount | meucurrency) || '--' }}
                  </span>
                </td>

                <td
                  data-label="Trạng thái"
                  class="whitespace-nowrap overflow-hidden overflow-ellipsis text-center"
                >
                  <div class="flex justify-center justify-end-mobile">
                    <div
                      *ngIf="item.extend_contract_status == 'Đang hoạt động'"
                      class="bg-duck-egg-blue text-05cd85 text-center w-105px text-12px rounded-4px font-roboto-regular leading-5 py-2px"
                    >
                      Đang hoạt động
                    </div>
                    <div
                      *ngIf="item.extend_contract_status == 'Chưa hoạt động'"
                      class="bg-grayscale-light-grey text-center text-4d4d50 text-12px rounded-4px font-roboto-regular leading-5 w-105px py-2px"
                    >
                      Chưa hoạt động
                    </div>
                    <div
                      *ngIf="item.extend_contract_status == 'Đã thanh lý'"
                      class="bg-primary-lightest-blue text-center text-light-blue text-12px rounded-4px font-roboto-regular leading-5 w-105px py-2px"
                    >
                      Đã thanh lý
                    </div>
                    <div
                      *ngIf="item.extend_contract_status == 'Đã hủy'"
                      class="bg-light-pink text-ff3b61 text-center text-12px rounded-4px font-roboto-regular leading-5 w-105px py-2px"
                    >
                      Đã hủy
                    </div>
                  </div>
                </td>

                <td data-label="Thao tác">
                  <div
                    class="flex justify-center justify-end-mobile margrin-top"
                  >
                    <button
                      class="hover:bg-primary-lightest focus:outline-none w-40px h-40px p-10px rounded-full"
                      [tooltip]="'Tải tài liệu'"
                      (click)="download(item.id)"
                    >
                      <img
                        src="../../../assets/icons/dashboard-client/download.svg"
                      />
                    </button>
                    <button
                      [tooltip]="'Xem chi tiết'"
                      (click)="navigateContractDetail(item.id)"
                      class="hover:bg-primary-lightest rounded-full focus:outline-none w-40px h-40px p-10px"
                    >
                      <img
                        src="../../../assets/icons/dashboard-client/u_external-link-alt.svg"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>

            <ng-template #noInfo2>
              <tbody>
                <tr
                  class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
                >
                  <td
                    colspan="8"
                    class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
                  >
                    Không có dữ liệu
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
          <div *ngIf="totalContract != 0" class="mt-m-10px">
            <meu-pagination-component
              [inputPageSize]="5"
              [total]="totalContract"
              [changePage]="changePageFunc"
              [setPropertyTotal]="true"
              (onChangeValue)="onPagingContract($event)"
            ></meu-pagination-component>
          </div>
        </div>
      </div>

      <!-- chart -->

      <div class="col-span-full bg-white rounded-4px mt-8px shadow-sm h-310">
        <div
          class="text-16px text-left font-roboto-medium title-dashboard leading24 text-dark-grey py-8px ml-10px"
        >
          Số lượng phiếu nhập xuất
        </div>
        <div id="chart" class="chart-respon-m">
          <apx-chart
            *ngIf="chartOptions.series"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [yaxis]="chartOptions.yaxis"
            [legend]="chartOptions.legend"
            [fill]="chartOptions.fill"
            [stroke]="chartOptions.stroke"
            [grid]="chartOptions.gridLine"
            [xaxis]="chartOptions.xaxis"
            [colors]="chartOptions.colors"
          ></apx-chart>
        </div>
      </div>

      <!-- report stock -->
      <div
        class="col-span-full bg-white mt-8px rounded-4px shadow-sm px-10px pb-8px table-contract"
      >
        <div
          class="header-stock"
        >
          <div class="title-stock title-dashboard leading24">
            Quản lý tồn kho
          </div>
          <div class="relative search-stock">
            <input
              type="text"
              placeholder="Nhập từ khóa tìm kiếm"
              (keyup)="keyUp.next($event.target.value)" 
              class="search-import-export m-auto h-32px w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
            />
            <img
              class="absolute top-3px left-0 mt-9px ml-12px"
              src="assets/icons/handle-icons/Icons_search.svg"
            />
          </div>
        </div>
        <div class="col-span-12 overflow-x-auto" style="overflow-y: hidden">
          <table
            class="w-full border-separate table-fixed"
            cellpadding="0"
            cellspacing="0"
          >
            <thead>
              <tr
                class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
              >
                <th class="text-center col col-stt">#</th>
                <th class="text-left col col-code">Mã phiếu nhập</th>
                <th class="text-left col col-name">Tên hàng</th>
                <th class="text-left col col-unit">Đơn vị tính</th>
                <th class="text-left col col-sku">SKU</th>
                <th class="text-right col col-import">Nhập</th>
                <th class="text-right col col-stock">Tồn</th>
              </tr>
            </thead>

            <!-- DATA TABLE  -->
            <tbody
              class="font-roboto-regular text-dark-grey text-14px"
              *ngIf="totalInRe != 0; else noInfo3"
            >
              <tr
                *ngFor="let item of allCodeImportCode; let i = index"
                class="mh-40px"
              >
                <td
                  class="td-stt whitespace-nowrap overflow-hidden overflow-ellipsis text-center border-l"
                  [title]="(pageIndexContract - 1) * pageSizeContract + i + 1"
                  data-label="#"
                >
                  {{ (pageIndexContract - 1) * pageSizeContract + i + 1 }}
                </td>

                <td data-label="Mã phiếu nhập">
                  <div
                    class="cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
                    [title]="item.imExportCode"
                  >
                    <span>{{ item.imExportCode || '--' }}</span>
                  </div>
                </td>

                <td data-label="Tên hàng">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span [title]="item.product_name">
                      {{ item.product_name || '--' }}
                    </span>
                  </div>
                </td>

                <td data-label="Đơn vị tính">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span [title]="item.unit_name || '--'">
                      {{ item.unit_name || '--' }}
                    </span>
                  </div>
                </td>

                <td data-label="SKU">
                  <div
                    class="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  >
                    <span [title]="item.sku_code || '--'">
                      {{ item.sku_code || '--' }}
                    </span>
                  </div>
                </td>

                <td
                  data-label="Nhập"
                  class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                >
                  <span [title]="(item.quantity | meucurrency) || '--'">
                    {{ (item.quantity | meucurrency) || '--' }}
                  </span>
                </td>
                <td
                  data-label="Tồn"
                  class="whitespace-nowrap overflow-hidden overflow-ellipsis text-right"
                >
                  <span [title]="(item.total_stock_quantity | meucurrency) || '--'">
                    {{ (item.total_stock_quantity | meucurrency) || '--' }}
                  </span>
                </td>
              </tr>
            </tbody>

            <ng-template #noInfo3>
              <tbody>
                <tr
                  class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
                >
                  <td
                    colspan="11"
                    class="td-noinfo border-light-grey border-b border-l border-t md:border-t-0 border-r border-opacity-50"
                  >
                    Không có dữ liệu
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
          <div *ngIf="totalInRe != 0" class="mt-m-10px">
            <meu-pagination-component
              [inputPageSize]="5"
              [total]="totalInRe"
              [changePage]="changePageFunc"
              [setPropertyTotal]="true"
              (onChangeValue)="onPagingInventoryReport($event)"
            ></meu-pagination-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Export Report -->
<meu-modal-component
  [isOpen]="isOpenModalER"
  [options]="optionsER"
  [title]="titleModalER"
  *ngIf="isOpenModalER"
>
  <meu-export-report-manage
    (closeModalExport)="closeExportReport($event)"
    [idUser]="userSelected?.id"
  ></meu-export-report-manage>
</meu-modal-component>
<meu-modal-component
  [isOpen]="isOpenModalPopUp"
  [options]="optionsPopUp"
  [title]="titleModalPopUp"
  *ngIf="isOpenModalPopUp"
>
  <meu-pop-up
    [inventory_id]="inventoryId"
    [userID]="userSelected?.id"
    [filter]="dateSelected?.value"
    [type]="isImExport"
    [startDate]="fromDate"
    [endDate]="toDate"
    (closeModalImExport)="closeModal($event)"
  >
  </meu-pop-up>
</meu-modal-component>
<meu-modal-component
  [isOpen]="isOpenRangeDateModal"
  [options]="optionModalRangeDate"
  [title]="'Tùy chọn ngày'"
  *ngIf="isOpenRangeDateModal"
>
  <meu-date-range-modal (closeModalRangeDate)="closeRangeDateModal($event)">
  </meu-date-range-modal>
</meu-modal-component>

<!-- Import -->
<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [isOpenFormModalDashBoard]="isOpenFormModalDashBoard"
    [imExport_code]="imExport_code"
    (closeModalImport)="closeModalImport($event)"
  ></meu-import-history>
</meu-modal-component>

<!-- Export -->
<meu-modal-component
  [isOpen]="isOpenModalExport"
  [options]="optionsExport"
  [title]="titleModalExport"
  *ngIf="isOpenModalExport"
>
  <meu-export-history
    [isOpenFormModalDashBoard]="isOpenFormModalDashBoard"
    [imExport_code]="imExport_code"
    (closeModalExport)="closeModalExport($event)"
  ></meu-export-history>
</meu-modal-component>
<!-- for mobile -->