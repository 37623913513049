import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportHistoryComponent } from './import-history/import-history.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DpDatePickerModule } from 'ng2-date-picker';
import { TagInputModule } from 'ngx-chips';
import { ModalSlotQuantityComponent } from './modal-slot-quantity/modal-slot-quantity.component';
import { ModalCreateProductModule } from '../product/product-list-manage/modal-create-product/modal-create-product.module';
import { ModalCreateSKUModule } from '../product/product-list-manage/product-detail-manage/sku-product/modal-create-sku.modules';
import { ModalCreateCustomertModule } from '../customer/customer-management/modal-create-customer/modal-create-customer.module';
import { ContractManagementCreateModule } from '../contract-management/contract-management-create/contract-management-create.module';
import { ImportExportManagementComponent } from './import-export-management/import-export-management.component';
import { ExportHistoryComponent } from './export-history/export-history.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PrintQrcodeComponent } from './print-qrcode/print-qrcode.component';
import { NgxPrintModule } from 'ngx-print';
import { PercentUsedSlotComponent } from './percent-used-slot/percent-used-slot.component';
import { PrintComponent } from './print/print.component';
import { FilterImportModalComponent } from './filter-import-modal/filter-import-modal.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CellLocationModalComponent } from './cell-location-modal/cell-location-modal.component';
import { ModalTransferContainerComponent } from './import-export-management/modal-transfer-container/modal-transfer-container.component';

const routes: Routes = [
  {
    path: 'import-export-list',
    component: ImportExportManagementComponent,
  },
];

@NgModule({
  declarations: [
    ImportHistoryComponent,
    ModalSlotQuantityComponent,
    ImportExportManagementComponent,
    ExportHistoryComponent,
    PrintQrcodeComponent,
    PercentUsedSlotComponent,
    PrintComponent,
    FilterImportModalComponent,
    CellLocationModalComponent,
    ModalTransferContainerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DpDatePickerModule,
    TagInputModule,
    ModalCreateProductModule,
    ModalCreateSKUModule,
    QRCodeModule,
    ModalCreateCustomertModule,
    ContractManagementCreateModule,
    NgxPrintModule,
    NgxDaterangepickerMd,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
  exports: [
    ImportHistoryComponent,
    ModalSlotQuantityComponent,
    ExportHistoryComponent,
    PrintQrcodeComponent,
  ],
})
export class ImportExportHistoryModule {}
