import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService, ProductService } from 'src/app/api/api';
import { ToastrService } from 'src/app/core/services';
import { Contract, Product } from 'src/app/model/models';

@Component({
  selector: 'meu-contract-detail-manage',
  templateUrl: './contract-detail-manage.component.html',
  styleUrls: ['./contract-detail-manage.component.scss'],
})
export class ContractDetailManageComponent implements OnInit {
  public contractID: string;
  menu: boolean;
  contracts: Contract = {};
  inventoryId: string;

  constructor(
    private toastService: ToastrService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.contractID = this.activatedRoute.snapshot.paramMap.get('id');
    this.getContractID(this.contractID);

    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId || params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
    });
  }
  onActivate(event) {
    if (event.checkUpdate) {
      event.checkUpdate.subscribe((data) => {
        if (data === true) {
          this.getContractID(this.contractID);
        }
      });
    }
  }
  //get contract with id
  getContractID(id) {
    this.contractService.apiContractIdGet(id).subscribe((data) => {
      if (data) {
        this.contracts = data.data;
      }
    });
  }
}
