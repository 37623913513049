<div class="col-span-full px-16px py-5px">
  <div
    class="col-span-12 overflow-x-auto auto-h-m max-h-m-413px"
    style="overflow-y: auto; max-height: 320px"
  >
    <table
      class="w-full border-separate table-fixed"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
            #
          </th>
          <!-- image -->
          <!-- <th class="text-center col-image w-40px relative">
                <img
                  src="assets/icons/table-icon/u_image-v.svg"
                  class="top-0 bottom-0 left-0 right-0 absolute m-auto pt-1"
                />
              </th> -->
          <!-- code -->
          <th
            class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="flex justify-between items-center">
              <span class="overflow-hidden overflow-ellipsis whitespace-nowrap">
                Mã phiếu nhập / xuất</span
              >
            </div>
          </th>
          <!-- name -->
          <th
            class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="flex justify-between items-center">
              <span class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                >Tên hàng hóa</span
              >
            </div>
          </th>
          <!-- sku -->
          <th class="text-left col-sku pt-12px px-16px pb-8px">
            <span class="overflow-hidden overflow-ellipsis whitespace-nowrap"
              >SKU</span
            >
          </th>
          <!-- date expired -->
          <th class="text-left col-expired pt-12px px-16px pb-8px">
            <span class="overflow-hidden overflow-ellipsis whitespace-nowrap"
              >Ngày hết hạn</span
            >
          </th>
          <!-- Slot -->
          <th class="text-right col-slot pt-12px px-16px pb-8px">
            <span class="overflow-hidden overflow-ellipsis whitespace-nowrap">
              Số ô
            </span>
          </th>
        </tr>
      </thead>
      <!-- DATA TABLE -->
      <tbody>
        <tr
          *ngFor="let product of lstExpired; let i = index"
          style="min-height: 40px !important"
        >
          <td class="td-stt align-top py-10px" data-label="#">
            {{ (pageIndex - 1) * pageSize + i + 1 }}
          </td>
          <!-- image  td-image-->
          <!-- <td
                class="td-image responsive align-top relative"
                data-label="Hình ảnh"
              >
                <div class="w-full h-41px">
                  <img
                    [src]="urlBankEnd + product.image"
                    *ngIf="product.image; else default"
                    class="md:w-full float-right w-40px h-full"
                  />
                  <ng-template #default>
                    <img
                      src="assets/icons/table-icon/icon_24_default_image.svg"
                      class="md:w-full h-full w-40px float-right px-8px py-4x"
                    />
                  </ng-template>
                </div>
                <div
                  class="md:w-full w-40px bg-dark-grey opacity-40 h-41px absolute top-0"
                  *ngIf="product.is_hidden == true"
                ></div>
                <img
                  *ngIf="product.is_hidden == true"
                  src="assets/icons/table-icon/u_lock-alt.svg"
                  class="w-20px h-20px absolute top-10px right-9px md:left-11px"
                />
              </td> -->

          <td
            class="td-code align-top py-10px px-16px text-primary-dodger-blue overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Mã phiếu nhập / xuất"
          >
            <div
              class="relative flex justify-end md:justify-start cursor-pointer"
              [tooltip]="product.imExportCode ? product.imExportCode : '--'"
            >
              <img
                *ngIf="checkImport(product.imExportCode) == true"
                src="../../../../assets/icons/dashboard-icons/u_file-download-alt.svg"
                class="w-16px h-16px mr-5px mt-2px"
              />
              <img
                *ngIf="checkImport(product.imExportCode) == false"
                src="../../../../assets/icons/dashboard-icons/u_file-upload-alt.svg"
                class="w-16px h-16px mr-5px mt-2px"
              />
              <span
                class="cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                (click)="open(product.imExportCode)"
                >{{ product.imExportCode ? product.imExportCode : '--' }}</span
              >
            </div>
          </td>
          <td
            class="td-name align-top py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Tên hàng hóa "
          >
            <span
              class="cursor-pointer"
              [tooltip]="
                product.extend_product_name ? product.extend_product_name : '--'
              "
            >
              {{
                product.extend_product_name ? product.extend_product_name : '--'
              }}</span
            >
          </td>
          <td
            data-label="SKU"
            class="cursor-pointer align-top py-10px px-16px td-sku overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="
              product['extend_sku_code'] ? product['extend_sku_code'] : '--'
            "
          >
            <span class="cursor-pointer">
              {{
                product['extend_sku_code'] ? product['extend_sku_code'] : '--'
              }}
            </span>
          </td>
          <td
            data-label="Ngày hết hạn"
            class="cursor-pointer align-top py-10px px-16px td-expired"
            [tooltip]="
              product['expired_date']
                ? (product['expired_date'] | date: 'dd/MM/yyyy')
                : '--'
            "
          >
            <span class="cursor-pointer">
              {{
                product['expired_date']
                  ? (product['expired_date'] | date: 'dd/MM/yyyy')
                  : '--'
              }}
            </span>
          </td>
          <td
            data-label="Số ô"
            class="cursor-pointer align-top py-10px px-16px td-slot text-right"
            [tooltip]="
              product['extend_slot_code'] ? product['extend_slot_code'] : '--'
            "
          >
            <span class="cursor-pointer">
              {{
                product['extend_slot_code'] ? product['extend_slot_code'] : '--'
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <meu-pagination-component
          *ngIf="totalArray != 0"
          [inputPageSize]="5"
          [total]="totalArray"
          [array]="array"
          [changePage]="onPagingFunc"
        ></meu-pagination-component> -->

    <meu-pagination-component
      *ngIf="totalArray != 0"
      [inputPageSize]="pageSize"
      [total]="totalArray"
      [changePage]="changePageFunc"
      [setPropertyTotal]="true"
      (onChangeValue)="onChangeValue($event)"
    ></meu-pagination-component>
  </div>
  <div class="col-span-12 flex-row-reverse flex h-40px my-12px">
    <button (click)="closeModal()" class="btn-text-cancel">Đóng</button>
  </div>
</div>
