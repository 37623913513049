/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Inventory { 
    id?: string;
    name?: string;
    code?: string;
    description?: string;
    address?: string;
    longitude?: number;
    latitude?: number;
    created_at?: Date;
    created_by?: string;
    modified_by?: string;
    modified_at?: Date;
    area?: number;
    used_percent?: number;
    image?: string;
    type_id?: string;
    load_unload_fee?: number;
    store_fee?: number;
    transfer_fee?: number;
}