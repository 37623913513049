import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractManagementCreateComponent } from './contract-management-create.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [];

@NgModule({
  declarations: [ContractManagementCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    DpDatePickerModule,
    NgSelectModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
  exports: [ContractManagementCreateComponent],
})
export class ContractManagementCreateModule {}
