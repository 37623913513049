import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { ProductService } from 'src/app/api/product.service';
import { UnitService } from 'src/app/api/unit.service';
import { ImportExportHistoryService } from 'src/app/api/importExportHistory.service';
import { SkuService } from 'src/app/api/sku.service';
import { IDayCalendarConfig, DatePickerComponent } from 'ng2-date-picker';
import { SlotService } from 'src/app/api/slot.service';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { filter, take, debounceTime, map } from 'rxjs/operators';
import { ToastrService, ConfirmService } from 'src/app/core/services';
import { UserService } from 'src/app/api/user.service';
import { Subject } from 'rxjs';
import { ContractService } from 'src/app/api/contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContainerProductHistoryService,
  ContainerService,
  ExportService,
  Slot,
  StockAvailableService,
} from 'src/app';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { PermissionService } from 'src/app/core/guards/permission.service';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { emitKeypressEvents } from 'readline';

@Component({
  selector: 'meu-import-history',
  templateUrl: './import-history.component.html',
  styleUrls: ['./import-history.component.scss'],
})
export class ImportHistoryComponent implements OnInit, AfterViewInit {
  @Input() imExport_code: string;
  @Input() idProduct: string;
  @Input() idContract: string;
  @Input() skuId: string;
  @Input() dataFromContainer: Slot;
  @Input() isOpenFormModalDashBoard: boolean = false;
  @Input() lstSlotSelected: any[];
  @Input() isTransferCar: boolean;
  @Input() saleItem;
  @Input() is_unload: boolean = false;
  @Output() closeModalImport = new EventEmitter();
  @Output() changeTitleModal = new EventEmitter();
  @ViewChild('dateToDp') public dateToDp: DatePickerComponent;
  @ViewChild('dateExpiryDp') public dateExpiryDp: DatePickerComponent;
  @ViewChild('myTooltipProductCode', { static: false }) myTooltipProductCode;
  @ViewChild('myTooltipProductName', { static: false }) myTooltipProductName;
  @ViewChild('myTooltipSkuCode', { static: false }) myTooltipSkuCode;

  isMobile: boolean = false;

  currentDate = new Date();

  allProducts = [];
  allSkus = [];
  allPrint = [];

  titlePrint;
  title_print;
  disableContainer: boolean;
  isCheckDraft: boolean = false;

  //array temp
  arrProducts: any = [];
  arrAllProducts: any = [];

  productSelected: string;
  skuSelected: string;

  arrHashtag = [];
  arrImport = [];

  selectProduct;
  selectSku;
  selectHashtag = [];
  unit_name;
  unit_code;

  create_at;
  receiptdate: string;
  expirydate: string;
  filter: string = '';
  inventoryId: string = '';
  quantity: number;
  net_weigth: number;
  slot_id: string = '';
  product_name: string = '';
  contract_code: string = '';
  is_draft;
  is_checked;
  is_sale = false;

  product_id: string = '';
  sku_code: string = '';
  value_product_id: string = '';

  //get from modal quantity
  slot: string = '';
  itemQuantity: string = '';

  //get from modal percent used slot
  listSlots: any[] = [];
  listTotalProduct: any[] = [];
  listImportSlots: any[] = [];

  formImport = new FormGroup({
    note: new FormControl(''),
    receipt_date: new FormControl(moment()),
    imExportCode: new FormControl(''),
    //batch_code: new FormControl(''),
    container_code: new FormControl(''),
    implementer_id: new FormControl(''),
    customer_id: new FormControl(''),
    contract_id: new FormControl(''),
    contract_code: new FormControl(''),
    //supplier_name: new FormControl(),
    batch_code: new FormControl(),
    receive_car: new FormControl(),
    receiver: new FormControl(),
    receiver_id: new FormControl(),
    seller_id: new FormControl(),
  });

  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY HH:mm',
    firstDayOfWeek: 'mo',
    opens: 'left',
    drops: 'down',
  };

  dateExpiryPickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY',
    firstDayOfWeek: 'mo',
    drops: 'up',
    min: moment(),
  };

  expiry_date = moment().add(1, 'months').format('DD/MM/yyyy');

  titleModal: string;
  titleModalQuantity: string;
  titleModalSKU: string;
  titleModalQR: string;
  titleModalContract: string;
  titleModalPercentSlot: string;
  titleModalCell: string;

  isOpenModalProduct: boolean;
  isOpenModalQuantity: boolean;
  isOpenModalSKU: boolean;
  isOpenModalQR: boolean;
  isOpenModalContract: boolean;
  isOpenModalPercentSlot: boolean;
  isOpenPrintIE: boolean;
  isPrintDivideQuantity: boolean;
  isBillOrCommand: boolean;
  isOpenSelectCell: boolean;

  isOpenSelectCellBottom: boolean;
  isOpenModalPercentSlotBottom: boolean;
  optionsSelectCellBottom: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenSelectCellBottom = false;
    },
  };
  optionsPercentSlotBottom: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenModalPercentSlotBottom = false;
    },
  };

  options: MxModalOption = {};
  optionsQuantity: MxModalOption = {};
  optionsSKU: MxModalOption = {};
  optionsQR: MxModalOption = {};
  optionsContract: MxModalOption = {};
  optionsPercentSlot: MxModalOption = {};
  optionsPrintIE: MxModalOption = {};
  optionsSelectCell: MxModalOption = {};

  search = '';

  //load more implementer
  allImplemeters = [];
  implementer = {
    avatar: null,
    full_name: null,
  };
  filterImplementer: string = '';
  pageImplementer = 10;
  totalImplementer = 0;
  openImplementer = false;
  keyUpImplementer = new Subject<KeyboardEvent>();
  clearSearchImplementer: any = null;

  //load more receiver
  allReceivers = [];
  receiver = {
    id: null,
    avatar: null,
    full_name: null,
  };
  filterReceiver: string = '';
  pageReceiver = 10;
  totalReceiver = 0;
  openReceiver = false;
  keyUpReceiver = new Subject<KeyboardEvent>();
  clearSearchReceiver: any = null;

  //load more seller
  allSellers = [];
  seller = {
    id: null,
    avatar: null,
    full_name: null,
  };
  filterSeller: string = '';
  pageSeller = 10;
  totalSeller = 0;
  openSeller = false;
  keyUpSeller = new Subject<KeyboardEvent>();
  clearSearchSeller: any = null;

  //load more customer
  allCustomers = [];
  customer = {
    id: null,
    full_name: null,
    avatar: null,
  };
  filterCustomer: string = '';
  pageCustomer = 10;
  totalCustomer = 0;
  openCustomer = false;
  keyUpCustomer = new Subject<KeyboardEvent>();
  textSearchCustomer: any = '';
  clearSearchCustomer: any = null;

  lstSlot;

  //load more contract
  allContracts = [];
  contract = {
    code: null,
  };
  filterContract: string = '';
  pageContract = 10;
  totalContract = 0;
  openContract = false;
  keyUpContract = new Subject<KeyboardEvent>();
  textSearchContract: any = '';
  clearSearchContract: any = null;

  created_by;
  created_avatar;

  checkQuantity;

  //load more search product
  allSearchProduct = [];
  filterSearchProduct: string = '';
  openSearchProduct = false;
  pageSearchProduct = 10;

  qrImportData = 'PN123123123';

  keyIndex = 0;

  listPercent = [];
  disableCheck: boolean = false;
  checkImport: boolean = false;

  titleImport: string;

  lstPermission = [];

  tempIndex;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private productService: ProductService,
    private unitService: UnitService,
    private importExportHistoryService: ImportExportHistoryService,
    private skuService: SkuService,
    private slotService: SlotService,
    private userService: UserService,
    private confirmService: ConfirmService,
    private toastr: ToastrService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStoreService: LocalStoreService,
    private stockAvailableService: StockAvailableService,
    private exportService: ExportService,
    private permissionService: PermissionService,
    private containerProductHistoryService: ContainerProductHistoryService,
    private containerService: ContainerService
  ) {}

  ngAfterViewInit() {
    this.setModalAddOrEdit();
    this.myTooltipProductCode.hide();
    this.myTooltipProductName.hide();
    this.myTooltipSkuCode.hide();
  }

  ngOnInit(): void {
    this.queryScreen();
    this.setInventoryId();
    if (this.imExport_code) {
      this.disableContainer = true;
    } else {
      this.disableContainer = false;
    }
    if (this.isTransferCar === true) {
      this.titleImport = 'PHIẾU KHAI BÁO';
    } else {
      this.titleImport = 'PHIẾU NHẬP HÀNG';
    }

    this.getAllProducts();
    this.setContractAndCustomer();
    this.getCreator();
    this.userService
      .apiUsersGet(
        'id==6e225e8f-23a9-400b-98ed-39fec50cc35d',
        '',
        1,
        this.pageCustomer
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.chooseCustomer(data.data.collection[0]);
        }
      });
    this.optionCallbackModal();
    this.optionCallbackModalProduct();
    this.optionCallbackModalSku();
    this.optionCallbackModalQRCode();
    this.optionCallbackModalContract();
    this.optionCallbackModalPercentSlot();
    this.optionCallbackModalPrint();
    this.optionCallBackModalSelectCell();

    //implementer
    this.getAllImplementers();
    this.searchImplementer();
    //Receiver
    this.getAllReceivers();
    this.searchReceiver();
    //Seller
    this.getAllSellers();
    this.searchSeller();
    //customer
    this.getAllCustomers();
    this.searchCustomer();

    //contract
    this.searchContract();
    this.getPermission();

    //get Sale product
    if (this.saleItem && !this.imExport_code) {
      this.getContainerSaleInfo();
    }
  }
  getPermission() {
    this.permissionService
      .getMyPermissions()
      .toPromise()
      .then((res) => {
        this.lstPermission = res;
      });
  }

  queryScreen() {
    this.isMobile = document.body.offsetWidth < 1014 ? true : false;
  }

  setInventoryId() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
        this.getAllContracts();
      }
    });

    if (
      this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      )
    ) {
      this.inventoryId = this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      );
    }

    if (this.router.url.split('/')[2]) {
      this.inventoryId = this.router.url.split('/')[2];
    }
  }

  setContractAndCustomer() {
    if (this.idContract) {
      this.contractService
        .apiContractIdGet(this.idContract)
        .subscribe((res) => {
          if (res.success) {
            this.contract.code = res.data.code;
            this.customer.id = res.data.customer_id;
            this.getAllContracts();

            this.formImport.patchValue({
              contract_id: this.idContract,
              contract_code: this.contract.code,
            });
          }

          this.userService.apiUsersIdGet(this.customer.id).subscribe((data) => {
            if (data.success) {
              this.customer.full_name = data.data.full_name;
              this.getAllCustomers();

              this.formImport.patchValue({
                customer_id: this.customer.id,
              });
            }
          });
        });
    }
  }

  updateSearch() {
    if (!this.idProduct) {
      if (!this.search) {
        this.allSearchProduct = [];
      } else {
        this.filterSearchProduct =
          'code|name|sku_code@=' + this.search.toUpperCase();
        this.openSearchProduct = true;
        this.getAllSearchProduct();
      }
    }
    if (this.idProduct) {
      if (!this.search) {
        this.allSearchProduct = [];
      } else {
        this.filterSearchProduct =
          'id==' +
          this.idProduct +
          ',code|name|sku_code@=' +
          this.search.toUpperCase();
        this.openSearchProduct = true;
        this.getAllSearchProduct();
      }
    }

    if (this.imExport_code) {
      if (this.is_checked == true) {
        if (!!this.search) {
          const arr = this.arrAllProducts.filter(
            (p) =>
              p.product_code.includes(this.search.toUpperCase()) ||
              p.product_name
                .toUpperCase()
                .includes(this.search.toUpperCase()) ||
              p.sku_code.toUpperCase().includes(this.search.toUpperCase())
          );
          this.arrProducts = [...arr];
        } else {
          this.arrProducts = [...this.arrAllProducts];
        }
      }
    }
  }

  getAllSearchProduct() {
    this.productService
      .apiProductGet(this.filterSearchProduct, '', 1, 1000)
      .subscribe((data) => {
        if (data.success == true) {
          this.allSearchProduct = [...data.data.collection];
        }
      });
  }

  optionCallbackModal() {
    this.optionsQuantity.callBackAfterClose = (e) => {
      this.slot = '';
      this.titlePrint = null;
      this.isOpenModalQuantity = false;
    };
  }

  optionCallbackModalProduct() {
    this.options.callBackAfterClose = (e) => {
      this.product_name = '';
      this.isOpenModalProduct = false;
    };
  }

  optionCallbackModalSku() {
    this.optionsSKU.callBackAfterClose = (e) => {
      this.product_id = '';
      this.isOpenModalSKU = false;
    };
  }

  optionCallbackModalQRCode() {
    this.optionsQR.callBackAfterClose = (e) => {
      this.isOpenModalQR = false;
      this.titlePrint = null;
    };
  }

  optionCallbackModalContract() {
    this.optionsContract.callBackAfterClose = (e) => {
      this.contract_code = '';
      this.isOpenModalContract = false;
    };
  }

  optionCallbackModalPercentSlot() {
    this.optionsPercentSlot.callBackAfterClose = (e) => {
      this.isOpenModalPercentSlot = false;
    };
  }
  optionCallbackModalPrint() {
    this.optionsPrintIE.callBackAfterClose = (e) => {
      this.isOpenPrintIE = false;
      this.isPrintDivideQuantity = false;
      this.titlePrint = null;
      this.isBillOrCommand = null;
    };
  }

  optionCallBackModalSelectCell() {
    this.optionsSelectCell.callBackAfterClose = (e) => {
      this.isOpenSelectCell = false;
    };
  }

  setModalAddOrEdit() {
    if (this.imExport_code) {
      this.getImportByimExportCode();
    } else {
      this.addRowProduct('');
    }
  }

  async getSlotInfo() {
    let res = [];
    for (let i = 0; i < this.arrImport.length; i++) {
      let e = this.arrImport[i];
      if (e.slot_id) {
        let data = await this.slotService
          .apiSlotIdInfoGet(e.slot_id)
          .toPromise();
        res.push({ ...data.data });
      }
    }
    return res;
  }

  titleByImExportCode(item) {
    if (item.is_transfer_car === true) {
      this.isTransferCar = true;
      this.titleImport = 'PHIẾU KHAI BÁO';
    } else {
      this.isTransferCar = false;
      this.titleImport = 'PHIẾU NHẬP HÀNG';
    }
  }

  //Sale
  getContainerSaleInfo() {
    this.containerProductHistoryService
      .apiContainerProductHistoryContainerIdGet(this.saleItem.id)
      .subscribe((res) => {
        if (res.success) {
          this.arrImport = res.data;
          this.isTransferCar = false;
          this.titleImport = 'PHIẾU NHẬP HÀNG';
          this.formImport.patchValue({
            container_code: this.saleItem.number,
            seller_id: this.saleItem.created_by,
          });
          this.arrProducts = [];
          this.arrImport.forEach((item, index) => {
            if (item.current_quantity > 0) {
              let obj = {
                product_code: item.extend_product_code,
                product_name: item.name,
                selectProductObj: {
                  id: item.product_id,
                  name: item.name,
                  code: item.extend_product_code,
                },
                selectSkuObj: {
                  id: item.sku_id,
                  sku_code: item.sku_code,
                },
                selectProduct: item.product_id,
                selectSku: item.sku_id,
                extend_supplier_name: item.extend_supplier_id,
                quantity: item.current_quantity,
                expiry_date: moment().add(1, 'months').format('dd/MM/YYYY'),
                unit_name: 'Bao',
                unit_code: 'BAO',
              };
              this.skuService
                .apiSkuGet('product_id==' + item.product_id, '', 1, 1000)
                .subscribe((response) => {
                  if (response.success) {
                    this.arrProducts[index].allSkus = response.data.collection;
                    this.getSku(this.arrProducts[index].allSkus[0], obj);
                  }
                });
              this.arrProducts.push(obj);
            }
          });
          this.arrAllProducts = this.arrProducts;
          this.arrProducts = [...this.arrProducts];
          this.getAllContracts();
        }
      });
  }

  async getImportByimExportCode() {
    this.importExportHistoryService
      .apiImportExportHistoryCodeGet(this.imExport_code)
      .subscribe(async (res) => {
        if (res.success) {
          this.arrImport = res.data.collection;
          this.titleByImExportCode(res.data.collection[0]);
          let lstSlot = await this.getSlotInfo();
          //form group
          this.arrImport.forEach((item) => {
            this.formImport.setValue({
              note: item.note,
              receipt_date: item.receipt_date,
              imExportCode: item.imExportCode,
              // batch_code: item.batch_code,
              container_code: item.container_code,
              //supplier_name: item.supplier_name,
              batch_code: item.batch_code,
              implementer_id:
                item.implementer_id != null ? item.implementer_id : '',
              customer_id: item.customer_id != null ? item.customer_id : '',
              contract_id: item.contract_id != null ? item.contract_id : '',
              contract_code:
                item.contract_code != null ? item.contract_code : '',
              receive_car: item.receive_car ? item.receive_car : '',
              receiver: item.receiver ? item.receiver : '',
              receiver_id: item.receiver_id != null ? item.receiver_id : '',
              seller_id: item.seller_id != null ? item.seller_id : '',
            });
            this.create_at = item.created_at;
            this.is_draft = item.is_draft;
            this.is_sale = item.is_sale;
            this.created_by = item.extend_creator_name;
            this.created_avatar = item.extend_creator_avatar;
            this.is_checked = item.is_checked;
            //implementer
            this.implementer.avatar = item.extend_implementer_avatar;
            this.implementer.full_name = item.extend_implementer_name;
            //customer
            this.customer.id = item.customer_id;
            this.customer.full_name = item.extend_customer_name;
            this.customer.avatar = item.extend_customer_avatar;
            //Receiver
            this.receiver.id = item.receiver_id;
            this.receiver.full_name = item.extend_receiver_name;
            this.receiver.avatar = item.extend_receiver_avatar;
            //Seller
            this.seller.id = item.seller_id;
            this.seller.full_name = item.extend_seller_name;
            this.seller.avatar = item.extend_seller_avatar;
            //contract
            this.contract.code =
              item.contract_code != null ? item.contract_code : '';
          });

          //list of items
          let groups = this.arrImport.reduce((groups, item) => {
            const group = groups[item.group_number] || [];
            group.push(item);
            groups[item.group_number] = group;
            return groups;
          }, {});

          let obj: any;
          Object.values(groups).forEach((element) => {
            obj = element[0];
            obj.selectProductObj = {};
            obj.selectSkuObj = {};
            obj.selectProductObj.id = obj.product_id;
            obj.selectProductObj.name = obj.product_name;
            obj.selectProductObj.code = obj.product_code;
            obj.selectSkuObj.id = obj.sku_id;
            obj.selectSkuObj.sku_code = obj.sku_code;
            obj.selectHashtag = [];
            obj.selectProduct = obj.product_id;
            obj.selectSku = obj.sku_id;
            obj.extend_supplier_name = obj.supplier_id;
            let arr = element as any[];
            let total = 0;
            let data: any;
            arr.forEach((item) => {
              data = lstSlot.find((c) => c.id == item.slot_id);
              total += item.quantity;
              obj.selectHashtag.push({
                id: item.slot_id,
                row_id: item.id,
                code: item.extend_slot_code,
                quantity: item.quantity,
                used_percent: data?.used_percent,
                zone_id: data?.zone_id,
                new_value: item.percent ? item.percent : null,
              });
            });
            obj.quantity = total;
            obj.expiry_date =
              obj.expiry_date != null
                ? moment(obj.expiry_date).format('DD/MM/yyyy')
                : '';
            let temp = new Date(obj.expiry_date);
            this.arrProducts.push(obj);
          });
          if (this.imExport_code.includes('PSN')) {
            this.allPrint = [
              { title: 'QR code' },
              { title: this.isTransferCar ? 'Lệnh Khai Báo' : 'Lệnh Nhập' },
              { title: this.isTransferCar ? 'Phiếu Khai Báo' : 'Phiếu Nhập' },
              { title: 'Tờ Phân Bổ' },
              { title: 'Báo cáo' },
            ];
          } else {
            this.allPrint = [
              { title: 'QR code' },
              { title: this.isTransferCar ? 'Lệnh Khai Báo' : 'Lệnh Nhập' },
              { title: this.isTransferCar ? 'Phiếu Khai Báo' : 'Phiếu Nhập' },
              { title: 'Tờ Phân Bổ' },
              { title: 'Báo cáo' },
              { title: 'Bảng kê phí' },
            ];
          }
          this.arrAllProducts = this.arrProducts;

          this.arrProducts = [...this.arrProducts];
          // this.arrProducts.forEach((element) => {
          //   if (element.is_draft == true) {
          //     element.selectHashtag.forEach((item) => {
          //       item.new_value = 100 - item.used_percent;
          //     });
          //     this.editItem(element);
          //   }
          // });
          // this.arrProducts.forEach((element) => {
          //   if (element.is_draft == true) {
          //   }
          // });
          this.getAllContracts();
        }
      });
  }

  closeModal(event = false) {
    if (this.dataFromContainer || this.isOpenFormModalDashBoard) {
      this.closeModalImport.emit({ success: event, isCloseModal: true });
    } else {
      this.closeModalImport.emit({ success: event, isCloseModal: false });
    }
  }

  changeTitle(event = false) {
    this.changeTitleModal.emit({ success: event });
  }

  getAllProducts() {
    this.productService.apiProductGet('', '', 1, 1000).subscribe((data) => {
      if (data.success == true) {
        this.allProducts = data.data.collection;
      }
    });
  }

  //Implementer
  getAllImplementers() {
    this.userService
      .apiUsersGet(
        this.filterImplementer + ',extend_user_role@=STAFF|ADMIN',
        '',
        1,
        this.pageImplementer
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allImplemeters = [...data.data.collection];
          this.totalImplementer = data.data.total;
        }
      });
  }

  //Receiver
  getAllReceivers() {
    this.userService
      .apiUsersGet(
        this.filterReceiver + ',full_name!=Hoa Chu,extend_user_role@=CUSTOMER',
        '',
        1,
        this.pageReceiver
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allReceivers = [...data.data.collection];
          this.totalReceiver = data.data.total;
        }
      });
  }

  //Seller
  getAllSellers() {
    this.userService
      .apiUsersGet(
        this.filterSeller +
          ',full_name!=Hoa Chu,extend_user_role@=SELLER_STAFF',
        '',
        1,
        this.pageSeller
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allSellers = [...data.data.collection];
          this.totalSeller = data.data.total;
        }
      });
  }

  searchImplementer() {
    this.keyUpImplementer
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterImplementer = 'full_name@=*' + value;
        this.pageImplementer = 10;
        this.allImplemeters = [];
        this.getAllImplementers();
        this.clearSearchImplementer = value;
      });
  }

  searchReceiver() {
    this.keyUpReceiver
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterReceiver = 'full_name@=*' + value;
        this.pageReceiver = 10;
        this.allReceivers = [];
        this.getAllReceivers();
        this.clearSearchReceiver = value;
      });
  }

  searchSeller() {
    this.keyUpSeller
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterSeller = 'full_name@=*' + value;
        this.pageSeller = 10;
        this.allSellers = [];
        this.getAllSellers();
        this.clearSearchSeller = value;
      });
  }

  closeOutsideImplementer() {
    if (this.openImplementer) {
      this.openImplementer = false;
      this.clearSearchImplementer = null;
      this.filterImplementer = '';
      this.pageImplementer = 10;
      this.getAllImplementers();
    }
  }

  closeOutsideReceiver() {
    if (this.openReceiver) {
      this.openReceiver = false;
      this.clearSearchReceiver = null;
      this.filterReceiver = '';
      this.pageReceiver = 10;
      this.getAllReceivers();
    }
  }
  closeOutsideSeller() {
    if (this.openSeller) {
      this.openSeller = false;
      this.clearSearchSeller = null;
      this.filterSeller = '';
      this.pageSeller = 10;
      this.getAllSellers();
    }
  }

  chooseImplementer(item) {
    this.implementer.avatar = item.avatar;
    this.implementer.full_name = item.full_name;
    this.formImport.patchValue({
      implementer_id: item.id,
    });
    this.closeChooseImplementer();
  }

  chooseReceiver(item) {
    this.receiver.id = item.id;
    this.receiver.avatar = item.avatar;
    this.receiver.full_name = item.full_name;
    this.formImport.patchValue({
      receiver_id: item.id,
      receiver: item.full_name,
    });
    this.closeChooseReceiver();
  }

  chooseSeller(item) {
    this.seller.id = item.id;
    this.seller.avatar = item.avatar;
    this.seller.full_name = item.full_name;
    this.formImport.patchValue({
      seller_id: item.id,
    });
    this.closeChooseSeller();
  }

  closeChooseImplementer() {
    if (this.openImplementer) {
      this.openImplementer = false;
    }
  }

  closeChooseReceiver() {
    if (this.openReceiver) {
      this.openReceiver = false;
    }
  }

  closeChooseSeller() {
    if (this.openSeller) {
      this.openSeller = false;
    }
  }

  loadMoreImplementer() {
    this.pageImplementer += 10;
    this.getAllImplementers();
  }

  loadMoreReceiver() {
    this.pageReceiver += 10;
    this.getAllReceivers();
  }

  loadMoreSeller() {
    this.pageSeller += 10;
    this.getAllSellers();
  }

  //Customer
  getAllCustomers() {
    this.userService
      .apiUsersGet(
        this.filterCustomer + ',extend_user_role@=CUSTOMER',
        '',
        1,
        this.pageCustomer
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allCustomers = [...data.data.collection];
          this.totalCustomer = data.data.total;
        }
      });
  }

  searchCustomer() {
    this.keyUpCustomer
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterCustomer = 'full_name@=*' + value;
        this.textSearchCustomer = value;
        this.pageCustomer = 10;
        this.allCustomers = [];
        this.getAllCustomers();
        this.clearSearchCustomer = value;
      });
  }

  closeOutsideCustomer() {
    if (this.openCustomer) {
      this.openCustomer = false;
      this.clearSearchCustomer = null;
      this.filterCustomer = '';
      this.textSearchCustomer = '';
      this.pageCustomer = 10;
      this.getAllCustomers();
    }
  }

  chooseCustomer(item) {
    this.customer.full_name = item.full_name;
    this.customer.id = item.id;
    this.customer.avatar = item.avatar;
    this.formImport.patchValue({
      customer_id: item.id,
      supplier_name: item.full_name,
    });
    this.closeChooseCustomer();
    this.getAllContracts();
    //this.chooseImplementer(item);
    this.contract.code = '';
  }

  closeChooseCustomer() {
    if (this.openCustomer) {
      this.openCustomer = false;
    }
  }

  loadMoreCustomer() {
    this.pageCustomer += 10;
    this.getAllCustomers();
  }

  //Contract
  getAllContracts() {
    this.contractService
      .apiContractGet(
        this.inventoryId,
        this.filterContract +
          ',customer_id==' +
          this.customer.id +
          ',extend_contract_status==Đang hoạt động',
        '',
        1,
        this.pageContract
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allContracts = [...data.data.collection];
          this.totalContract = data.data.total;
          this.chooseContract(data.data.collection[0]);
        }
      });
  }

  searchContract() {
    this.keyUpContract
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.filterContract = 'code@=*' + value;
        this.textSearchContract = value;
        this.pageContract = 10;
        this.allContracts = [];
        this.getAllContracts();
        this.clearSearchContract = value;
      });
  }

  closeOutsideContract() {
    if (this.openContract) {
      this.openContract = false;
      this.clearSearchContract = null;
      this.filterContract = '';
      this.textSearchContract = '';
      this.pageContract = 10;
      this.getAllContracts();
    }
  }

  chooseContract(item) {
    this.contract.code = item?.code;
    this.formImport.patchValue({
      contract_id: item?.id,
      contract_code: item?.code,
    });
    this.closeChooseContract();
  }

  chooseSearchContract(item) {
    if (!this.isOpenModalContract) {
      if (this.customer.id) {
        this.isOpenModalContract = true;
        this.titleModalContract = 'Thêm mới hợp đồng';
        this.contract_code = item;
      } else {
        this.toastr.warnning('Vui lòng chọn khách hàng');
        return;
      }
    }
  }

  closeModalContract(data) {
    if (!data) {
      if (this.isOpenModalContract) {
        this.isOpenModalContract = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModalContract = false;
        this.contract_code = '';
        this.getAllContracts();
        this.toastr.success(data.message);
      } else {
        this.toastr.error(data.message);
      }
    }
  }

  closeChooseContract() {
    if (this.openContract) {
      this.openContract = false;
    }
  }

  loadMoreContract() {
    this.pageContract += 10;
    this.getAllContracts();
  }

  getCreator() {
    var user = JSON.parse(
      this.localStoreService.getFromToLocalStorage('userInfo')
    );
    this.created_by = user.name;
    this.created_avatar = user.avatar;
    let customers = {
      full_name: user.name,
      avatar: user.avatar,
      id: user.id,
    };
    this.chooseImplementer(customers);
  }

  getSlot(event) {
    if (!event) {
      this.arrHashtag = [];
      return;
    }
    this.filter = 'code@=*' + event;
    this.getAllSlots();
  }

  addFirstItem(el) {
    const filterArr = this.arrHashtag.filter((item) => {
      return !el.selectHashtag.find((p) => p.id == item.id);
    });

    if (filterArr[this.keyIndex]) {
      if (this.keyIndex == 0) {
        el.selectHashtag.push(filterArr[this.keyIndex]);
        this.keyIndex = 0;
      } else {
        this.keyIndex = 0;
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  doSomething(event: KeyboardEvent): void {
    if (event.code == 'ArrowUp' && this.keyIndex > 0) {
      this.keyIndex--;
    }
    if (
      event.code == 'ArrowDown' &&
      this.keyIndex < this.arrHashtag.length - 1
    ) {
      this.keyIndex++;
    }
  }

  getAllSlots() {
    this.slotService
      .apiSlotsGet(
        this.filter + ',used_percent<100',
        '',
        1,
        10000000,
        this.inventoryId
      )
      .pipe(debounceTime(300))
      .subscribe((data) => {
        if (data.success == true) {
          this.arrHashtag = [...data.data.collection];
        }
      });
  }

  getUnitNameAndAllSkusByProductId(event, element, index) {
    if (!event) {
      element.product_code = '';
      element.product_name = '';
      element.unit_name = '';
      element.unit_code = '';
      this.arrProducts[index].allSkus = [];
      element.selectSku = '';
      element.sku_code = '';
      element.supplier_id = '';
      this.value_product_id = '';
      this.myTooltipProductCode.hide();
      this.myTooltipProductName.hide();
    } else {
      element.selectProductObj = event;
      element.product_code = event.code;
      element.product_name = event.name;
      this.value_product_id = event.id;
      this.productService.apiProductIdGet(event.id).subscribe((res) => {
        if (res.success) {
          this.unitService.apiUnitIdGet(res.data.unit_id).subscribe((data) => {
            if (data.success) {
              element.unit_name = data.data.name;
              element.unit_code = data.data.code;
            }
          });
        }
      });

      this.skuService
        .apiSkuGet('product_id==' + event.id, '', 1, 1000)
        .subscribe((response) => {
          if (response.success) {
            this.arrProducts[index].allSkus = response.data.collection;
            this.getSku(this.arrProducts[index].allSkus[0], element);
          }
        });
      // element.selectSku = '';
      // element.sku_code = '';
      // element.supplier_id = '';
      this.myTooltipProductCode.hide();
      this.myTooltipProductName.hide();
    }
  }

  getSku(event, element) {
    if (event) {
      element.selectSkuObj = event;
      element.sku_code = event.sku_code;
      element.supplier_id = event.supplier_id;
      element.extend_supplier_name = event.supplier_id;
      element.supplier_name = event.extend_supplier_name;
      element.selectSku = event.id;
      element.net_weigth = event.net_weight;
      this.myTooltipSkuCode.hide();
    } else {
      element.supplier_name = '';
      element.supplier_id = '';
      element.sku_code = '';
      this.myTooltipSkuCode.hide();
    }
  }

  addRowProduct(item) {
    let objProductId = {};
    let obj = {};
    let objSku = {};
    let objSlot = {};
    let objLstSlot = {};

    //item
    let objItem = {};
    let objSlotItem = {};
    let objLstSlotItem = {};
    let objSkuItem = {};
    let objProductIdItem = {};

    if (!item) {
      var itemProductId = '';
      var itemProductName = '';
      var itemProductCode = '';
      var itemUnit = '';
      var itemCodeUnit = '';
      var itemSku_code = '';
      var itemSupplier_name = '';
      var itemSupplier_id = '';

      if (this.idProduct) {
        itemProductId = this.idProduct;

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + itemProductId, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService.apiProductIdGet(itemProductId).subscribe((res) => {
          if (res.success) {
            itemProductName = res.data.name;
            itemProductCode = res.data.code;

            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  itemUnit = data.data.name;
                  itemCodeUnit = data.data.code;

                  if (this.skuId) {
                    this.skuService
                      .apiSkuIdGet(this.skuId)
                      .subscribe((response) => {
                        if (response.success) {
                          itemSku_code = response.data.sku_code;
                          itemSupplier_name =
                            response.data.extend_supplier_name;
                          itemSupplier_id = response.data.supplier_id;

                          objSku = {
                            name: '',
                            code: '',
                            quantity: 1,
                            sku: '',
                            number: '',
                            isEdit: true,
                            selectProduct: itemProductId,
                            selectSku: this.skuId,
                            unit_name: itemUnit,
                            unit_code: itemCodeUnit,
                            selectHashtag: [],
                            expiry_date: '',
                            supplier_name: itemSupplier_name,
                            selectProductObj: {
                              id: itemProductId,
                              name: itemProductName,
                              code: itemProductCode,
                            },
                            selectSkuObj: {
                              id: this.skuId,
                              sku_code: itemSku_code,
                            },
                            product_name: itemProductName,
                            product_code: itemProductCode,
                            sku_code: itemSku_code,
                            supplier_id: itemSupplier_id,
                            batch_code: '',
                            allSkus: arrSKU,
                            net_weigth: 1,
                          };

                          this.arrProducts.push(objSku);
                          this.arrProducts = [...this.arrProducts];
                          this.changeDetectorRef.detectChanges();
                          this.myTooltipProductCode.hide();
                          this.myTooltipProductName.hide();
                        }
                      });
                  } else {
                    objProductId = {
                      name: '',
                      code: '',
                      quantity: 1,
                      sku: '',
                      number: '',
                      isEdit: true,
                      selectProduct: itemProductId,
                      selectSku: '',
                      unit_name: itemUnit,
                      unit_code: itemCodeUnit,
                      selectHashtag: [],
                      supplier_name: '',
                      supplier_id: '',
                      expiry_date: '',
                      selectProductObj: {
                        id: itemProductId,
                        name: itemProductName,
                        code: itemProductCode,
                      },
                      selectSkuObj: {},
                      product_name: itemProductName,
                      product_code: itemProductCode,
                      sku_code: itemSku_code,
                      batch_code: '',
                      allSkus: arrSKU,
                      net_weigth: 1,
                    };

                    this.arrProducts.push(objProductId);
                    this.arrProducts = [...this.arrProducts];
                    this.changeDetectorRef.detectChanges();
                    this.myTooltipProductCode.hide();
                    this.myTooltipProductName.hide();
                  }
                }
              });
          }
        });
      } else if (this.dataFromContainer) {
        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + item.id, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        objSlot = {
          name: '',
          code: '',
          quantity: 1,
          sku: '',
          number: '',
          isEdit: true,
          selectProduct: '',
          selectSku: '',
          unit_name: '',
          unit_code: '',
          selectHashtag: [
            {
              id: this.dataFromContainer.id,
              code: this.dataFromContainer.code,
              used_percent: this.dataFromContainer.used_percent,
              zone_id: this.dataFromContainer.zone_id,
              new_value: null,
            },
          ],
          expiry_date: '',
          supplier_name: '',
          supplier_id: '',
          batch_code: '',
          allSkus: arrSKU,
          net_weigth: 1,
        };

        this.arrProducts.push(objSlot);
        this.arrProducts = [...this.arrProducts];
        this.changeDetectorRef.detectChanges();
      } else if (this.lstSlotSelected) {
        var arry = [];
        this.lstSlotSelected.forEach((element) => {
          arry.push({
            id: element.id,
            code: element.code,
            used_percent: element.used_percent,
            zone_id: element.zone_id,
            new_value: null,
          });
        });

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + item.id, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        objLstSlot = {
          name: '',
          code: '',
          quantity: 1,
          sku: '',
          number: '',
          isEdit: true,
          selectProduct: '',
          selectSku: '',
          unit_name: '',
          unit_code: '',
          selectHashtag: arry,
          expiry_date: '',
          supplier_name: '',
          supplier_id: '',
          batch_code: '',
          allSkus: arrSKU,
          net_weigth: 1,
        };

        this.arrProducts.push(objLstSlot);
        this.arrProducts = [...this.arrProducts];
        this.changeDetectorRef.detectChanges();
      } else {
        obj = {
          name: '',
          code: '',
          quantity: 1,
          sku: '',
          number: '',
          isEdit: true,
          selectProduct: '',
          selectSku: '',
          unit_name: '',
          unit_code: '',
          selectHashtag: [],
          expiry_date: '',
          supplier_name: '',
          supplier_id: '',
          batch_code: '',
          allSkus: [],
          net_weigth: 1,
        };

        this.arrProducts.push(obj);
        this.arrProducts = [...this.arrProducts];
        this.changeDetectorRef.detectChanges();
      }
    } else {
      var itemProductIdItem = '';
      var itemProductNameItem = '';
      var itemProductCodeItem = '';
      var itemUnitItem = '';
      var itemCodeUnitItem = '';
      var itemSku_codeItem = '';
      var itemSupplier_nameItem = '';
      var itemSupplier_idItem = '';

      if (this.idProduct) {
        itemProductIdItem = this.idProduct;

        this.productService
          .apiProductIdGet(itemProductIdItem)
          .subscribe((res) => {
            if (res.success) {
              itemProductNameItem = res.data.name;
              itemProductCodeItem = res.data.code;

              this.unitService
                .apiUnitIdGet(res.data.unit_id)
                .subscribe((data) => {
                  if (data.success) {
                    itemUnitItem = data.data.name;
                    itemCodeUnitItem = data.data.code;

                    if (this.skuId) {
                      this.skuService
                        .apiSkuIdGet(this.skuId)
                        .subscribe((response) => {
                          if (response.success) {
                            itemSku_codeItem = response.data.sku_code;
                            itemSupplier_nameItem =
                              response.data.extend_supplier_name;
                            itemSupplier_idItem = response.data.supplier_id;

                            objSkuItem = {
                              name: '',
                              code: '',
                              quantity: 1,
                              sku: '',
                              number: '',
                              isEdit: true,
                              selectProduct: itemProductIdItem,
                              selectSku: this.skuId,
                              unit_name: itemUnitItem,
                              unit_code: itemCodeUnitItem,
                              selectHashtag: [],
                              expiry_date: '',
                              supplier_name: itemSupplier_nameItem,
                              selectProductObj: {
                                id: itemProductIdItem,
                                name: itemProductNameItem,
                                code: itemProductCodeItem,
                              },
                              selectSkuObj: {
                                id: this.skuId,
                                sku_code: itemSku_codeItem,
                              },
                              product_name: itemProductNameItem,
                              product_code: itemProductCodeItem,
                              sku_code: itemSku_codeItem,
                              supplier_id: itemSupplier_idItem,
                              batch_code: '',
                              allSkus: arrSKU,
                              net_weigth: 1,
                            };

                            this.arrProducts.push(objSkuItem);
                            this.arrProducts = [...this.arrProducts];
                            this.changeDetectorRef.detectChanges();
                          }
                        });
                    } else {
                      objProductIdItem = {
                        name: '',
                        code: '',
                        quantity: 1,
                        sku: '',
                        number: '',
                        isEdit: true,
                        selectProduct: itemProductIdItem,
                        selectSku: '',
                        unit_name: itemUnitItem,
                        unit_code: itemCodeUnitItem,
                        selectHashtag: [],
                        supplier_name: '',
                        supplier_id: '',
                        expiry_date: '',
                        selectProductObj: {
                          id: itemProductIdItem,
                          name: itemProductName,
                          code: itemProductCodeItem,
                        },
                        selectSkuObj: {},
                        product_name: itemProductName,
                        product_code: itemProductCodeItem,
                        sku_code: itemSku_codeItem,
                        batch_code: '',
                        allSkus: arrSKU,
                        net_weigth: 1,
                      };

                      this.arrProducts.push(objProductIdItem);
                      this.arrProducts = [...this.arrProducts];
                      this.changeDetectorRef.detectChanges();
                    }
                  }
                });
            }
          });

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + itemProductIdItem, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });
      } else if (this.dataFromContainer) {
        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + item.id, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService.apiProductIdGet(item.id).subscribe((res) => {
          if (res.success) {
            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  this.unit_code = data.data.code;
                }

                this.value_product_id = item.id;
                objSlotItem = {
                  quantity: 1,
                  isEdit: true,
                  selectProduct: item.id,
                  unit_name: item.extend_unit_name,
                  unit_code: this.unit_code,
                  selectHashtag: [
                    {
                      id: this.dataFromContainer.id,
                      code: this.dataFromContainer.code,
                      used_percent: this.dataFromContainer.used_percent,
                      zone_id: this.dataFromContainer.zone_id,
                      new_value: null,
                    },
                  ],
                  supplier_name: '',
                  supplier_id: '',
                  expiry_date: '',
                  selectProductObj: {
                    id: item.id,
                    name: item.name,
                    code: item.code,
                  },
                  selectSkuObj: {},
                  product_name: item.name,
                  product_code: item.code,
                  batch_code: '',
                  allSkus: arrSKU,
                  net_weigth: 1,
                };

                this.arrProducts.push(objSlotItem);
                this.arrProducts = [...this.arrProducts];
                this.changeDetectorRef.detectChanges();
              });
          }
        });
      } else if (this.lstSlotSelected) {
        var arry = [];
        this.lstSlotSelected.forEach((element) => {
          arry.push({
            id: element.id,
            code: element.code,
            used_percent: element.used_percent,
            zone_id: element.zone_id,
            new_value: null,
          });
        });

        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + item.id, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService.apiProductIdGet(item.id).subscribe((res) => {
          if (res.success) {
            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  this.unit_code = data.data.code;
                }

                this.value_product_id = item.id;
                objLstSlotItem = {
                  quantity: 1,
                  isEdit: true,
                  selectProduct: item.id,
                  unit_name: item.extend_unit_name,
                  unit_code: this.unit_code,
                  selectHashtag: arry,
                  supplier_name: '',
                  supplier_id: '',
                  expiry_date: '',
                  selectProductObj: {
                    id: item.id,
                    name: item.name,
                    code: item.code,
                  },
                  selectSkuObj: {},
                  product_name: item.name,
                  product_code: item.code,
                  batch_code: '',
                  allSkus: arrSKU,
                  net_weigth: 1,
                };

                this.arrProducts.push(objLstSlotItem);
                this.arrProducts = [...this.arrProducts];
                this.changeDetectorRef.detectChanges();
              });
          }
        });
      } else {
        let arrSKU = [];
        this.skuService
          .apiSkuGet('product_id==' + item.id, '', 1, 1000)
          .subscribe((response) => {
            if (response.success) {
              arrSKU = response.data.collection;
            }
          });

        this.productService.apiProductIdGet(item.id).subscribe((res) => {
          if (res.success) {
            this.unitService
              .apiUnitIdGet(res.data.unit_id)
              .subscribe((data) => {
                if (data.success) {
                  this.unit_code = data.data.code;
                }

                this.value_product_id = item.id;
                objItem = {
                  quantity: 1,
                  isEdit: true,
                  selectProduct: item.id,
                  unit_name: item.extend_unit_name,
                  unit_code: this.unit_code,
                  selectHashtag: [],
                  supplier_name: '',
                  supplier_id: '',
                  expiry_date: '',
                  selectProductObj: {
                    id: item.id,
                    name: item.name,
                    code: item.code,
                  },
                  selectSkuObj: {},
                  product_name: item.name,
                  product_code: item.code,
                  batch_code: '',
                  allSkus: arrSKU,
                  net_weigth: 1,
                };

                this.arrProducts.push(objItem);
                this.arrProducts = [...this.arrProducts];
                this.changeDetectorRef.detectChanges();
              });
          }
        });
      }
    }
  }

  cancelEdit(i, item) {
    item.isEdit = false;

    if (
      item.selectProduct == [] ||
      item.unit_name == '' ||
      item.selectSku == [] ||
      item.supplier_name == '' ||
      item.selectHashtag == []
    ) {
      this.arrProducts.splice(i, 1);
      this.arrProducts = [...this.arrProducts];
    }
  }

  editItem(item) {
    item.isEdit = true;
    if (this.imExport_code) {
      this.value_product_id = item.selectProductObj.id;
      this.skuService
        .apiSkuGet('product_id==' + item.selectProductObj.id, '', 1, 1000)
        .subscribe((response) => {
          if (response.success) {
            item.allSkus = response.data.collection;
          }
        });
    }
  }
  onItemRemoved(event, element) {
    if (event.quantity) {
      element.quantity = element.quantity - event.quantity;
    }
  }

  async getStockProduct(customer_id, contract_id) {
    let data = [];
    await this.stockAvailableService
      .apiStockAvailableCustomerIdStocksGet(
        customer_id,
        '',
        '',
        '',
        '',
        1,
        1000
      )

      .toPromise()
      .then((res) => {
        if (res.success) {
          data = res.data.collection;
        }
      });
    return data;
  }
  async saveItem(item, index) {
    let lstStockProduct = await this.getStockProduct(
      this.formImport.value.customer_id,
      this.formImport.value.contract_id
    );
    //new code
    if (!this.isOpenModalPercentSlot || !this.isOpenModalPercentSlotBottom) {
      this.listImportSlots = [];
      if (item.selectHashtag && item.selectHashtag.length > 0) {
        let usage = 0;
        let numOfNoQuantity = 0;
        let per = 0;

        item.selectHashtag?.forEach((element) => {
          if (!element.quantity) {
            numOfNoQuantity++;
          } else {
            usage += element.quantity;
          }
        });
        item.selectHashtag?.forEach((element, index) => {
          let per = 0;
          per = this.getPer(
            item.quantity - usage,
            numOfNoQuantity,
            index == item.selectHashtag?.length - 1
          );
          element.quantity = per;

          this.listImportSlots.push({
            id: element.id,
            code: element.code,
            zone_id: element.zone_id,
            used_percent: element.used_percent,
            new_value: 100 - (element.used_percent ? element.used_percent : 0),
            check: false,
            quantitySlot: element.quantity,
          });
        });
        Object.assign(this.listImportSlots, {
          totalQuantity: item.quantity,
          index: index,
          isEditExport:
            item.is_draft == false && this.imExport_code ? true : false,
        });
        if (this.isMobile) {
          this.isOpenModalPercentSlotBottom = true;
        } else {
          this.isOpenModalPercentSlot = true;
        }
        this.titleModalPercentSlot = 'Cập nhật phần trăm, số lượng mỗi ô';
        this.optionsPercentSlot.styleClass = 'w-8/12';
      } else {
        this.toastr.warnning('Vui lòng chọn vị trí ô');
        return;
      }
    }
  }

  removeItem(i) {
    let title = 'Xóa hàng';
    let content = 'Bạn có chắc chắn muốn xóa hàng này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.arrProducts.splice(i, 1);
          this.arrProducts = [...this.arrProducts];
          this.confirmService.close();
          this.toastr.success('Xóa hàng thành công');
        } else {
          this.confirmService.close();
        }
      });
  }

  //get date and time
  getDateTime(event) {
    this.receiptdate = event;
  }

  onSelect(item, slotArr) {
    let slot = slotArr.find((p) => p.id == item.id);
    if (!this.isOpenModalQuantity) {
      this.isOpenModalQuantity = true;
      this.titleModalQuantity = 'Cài đặt số lượng';
      this.optionsQuantity.styleClass = 'w-3/12';
      this.slot = slot;
    }
  }

  closeModalQuantity(data: any) {
    if (!data) {
      if (this.isOpenModalQuantity) {
        this.isOpenModalQuantity = false;
        this.slot = '';
      }
    }
    this.titlePrint = null;
  }

  valueQuantity(event) {
    this.itemQuantity = event;
  }

  getPer(total, length, isLast) {
    return isLast
      ? total - Math.floor(total / length) * (length - 1)
      : Math.floor(total / length);
  }

  quantityChange(item) {
    let per = 0;
    item.selectHashtag.forEach((element, index) => {
      per = this.getPer(
        item.quantity,
        item.selectHashtag.length,
        index == item.selectHashtag.length - 1
      );
      element.quantity = per;
    });
  }
  netWeigthChange(item) {
    let per = 0;
    item.selectHashtag.forEach((element, index) => {
      per = this.getPer(
        item.net_weigth,
        item.selectHashtag.length,
        index == item.selectHashtag.length - 1
      );
      element.net_weigth = per;
    });
  }

  createObject(isDraft, isCheck, objImport) {
    let valid = true;
    if (this.arrProducts.length == 0) {
      this.toastr.warnning('Vui lòng thêm sản phẩm');
      return;
    }

    let tempNumb = 1;
    this.arrProducts.forEach((item) => {
      if (item.selectHashtag.length == 0) {
        this.toastr.warnning('Vui lòng chọn số ô');
        valid = false;
      }

      if (!isDraft) {
        if (!item.expiry_date) {
          this.toastr.warnning('Vui lòng chọn ngày hết hạn');
          valid = false;
        }
      }

      if (!item.supplier_name) {
        this.toastr.warnning('Vui lòng chọn nhà cung cấp');
        valid = false;
      }

      if (!item.selectProduct) {
        this.toastr.warnning('Vui lòng chọn hàng hóa');
        valid = false;
      }
      if (item.quantity === 0) {
        this.toastr.warnning('Số lượng phải lớn hơn 0');
        valid = false;
      }

      if (!item.net_weigth) {
        this.toastr.warnning('Vui lòng không để trống quy cách');
        valid = false;
      }

      if (item.net_weigth === 0) {
        this.toastr.warnning('Số quy cách phải lớn hơn 0');
        valid = false;
      }

      if (!valid) return false;

      let usage = 0;
      let numOfNoQuantity = 0;
      let per = 0;

      item.selectHashtag.forEach((element) => {
        if (!element.quantity) {
          numOfNoQuantity++;
        } else {
          usage += element.quantity;
        }
      });

      item.selectHashtag.forEach((element, index) => {
        per = this.getPer(
          item.quantity - usage,
          numOfNoQuantity,
          index == item.selectHashtag.length - 1
        );

        element.quantity = !!element.quantity ? element.quantity : per;

        //show in modal
        //element.quantity = per;
        if (isCheck === true) {
          let obj = {
            //form group
            isImExported: true,
            is_draft: isDraft,
            is_checked: isCheck,
            container_code: this.formImport.value.container_code,
            note: this.formImport.value.note,
            receipt_date: this.formImport.value.receipt_date,
            implementer_id: this.formImport.value.implementer_id,
            customer_id: this.formImport.value.customer_id,
            contract_id: this.formImport.value.contract_id,
            contract_code: this.formImport.value.contract_code,
            inventory_id: this.inventoryId,
            batch_code: this.formImport.value.batch_code,
            receive_car: this.formImport.value.receive_car,
            receiver: this.formImport.value.receiver,
            receiver_id: this.formImport.value.receiver_id,
            seller_id: this.formImport.value.seller_id,
            //list of items
            id: element.row_id,
            product_id: item.selectProductObj.id,
            product_name: item.selectProductObj.name,
            product_code: item.selectProductObj.code,
            supplier_name: item.supplier_name,
            quantity: element.quantity,
            expiry_date: moment(item.expiry_date, 'DD/MM/yyyy'),
            unit_name: item.unit_name,
            unit_code: item.unit_code,
            sku_id: item.selectSkuObj.id,
            sku_code: item.selectSkuObj.sku_code,
            supplier_id: item.supplier_id,
            extend_supplier_name: item.supplier_id,
            slot_id: element.id,
            group_number: tempNumb,
            net_weigth: item.net_weigth,
            percent: element.new_value,
            is_transfer_car: this.isTransferCar,
            is_unload: this.is_unload, // sale from container
            //Không phải phiếu bán hàng
            is_sale: false,
            is_done: false,
            //New update add container_number_import
            container_number_import: this.formImport.value.container_code
          };
          objImport.push(obj);
        } else {
          let obj = {
            //form group
            isImExported: true,
            is_draft: isDraft,
            is_checked: isCheck,
            container_code: this.formImport.value.container_code,
            note: this.formImport.value.note,
            receipt_date: this.receiptdate,
            implementer_id: this.formImport.value.implementer_id,
            customer_id: this.formImport.value.customer_id,
            contract_id: this.formImport.value.contract_id,
            contract_code: this.formImport.value.contract_code,
            inventory_id: this.inventoryId,
            batch_code: this.formImport.value.batch_code,
            receive_car: this.formImport.value.receive_car,
            receiver: this.formImport.value.receiver,
            receiver_id: this.formImport.value.receiver_id,
            seller_id: this.formImport.value.seller_id,
            //list of items
            id: element.row_id,
            product_id: item.selectProductObj.id,
            product_name: item.selectProductObj.name,
            product_code: item.selectProductObj.code,
            supplier_name: item.supplier_name,
            quantity: element.quantity,
            expiry_date: moment(item.expiry_date, 'DD/MM/yyyy'),
            unit_name: item.unit_name,
            unit_code: item.unit_code,
            sku_id: item.selectSkuObj.id,
            sku_code: item.selectSkuObj.sku_code,
            supplier_id: item.supplier_id,
            extend_supplier_name: item.supplier_id,
            slot_id: element.id,
            group_number: tempNumb,
            net_weigth: item.net_weigth,
            percent: element.new_value,
            is_transfer_car: this.isTransferCar,
            is_unload: this.is_unload, // sale from container
            //Không phải phiếu bán hàng
            is_sale: false,
            is_done: false,
            //New update add container_number_import
            container_number_import: this.formImport.value.container_code
          };
          objImport.push(obj);
        }
      });
      tempNumb++;
    });
    //  this.listTotalProduct.push({
    //    totalQuantity: element.quantity,
    //    nameProduct: element.item.selectProductObj.name,
    //  });

    if (!valid) return false;
    return true;
  }

  //Create/Edit of draft
  saveInDraft() {
    let objImport: any = [];

    if (this.formImport.value.container_code.length > 50) {
      this.toastr.warnning('Số container tối đa 50 kí tự');
      return;
    }

    if (this.formImport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    // let res = this.createObject(true, false, objImport);
    let res = this.createObject(
      this.is_draft || this.is_draft == null ? true : false,
      !this.is_checked || this.is_checked == null ? false : true,
      objImport
    );

    if (!res) return;

    if (this.imExport_code) {
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objImport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    } else {
      this.importExportHistoryService
        .apiImportExportHistoryPost(objImport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.imExport_code = res.data.imExportCode;
            this.arrProducts = [];
            this.changeTitle(true);
            //this.getImportByimExportCode();
            this.closeModal(true);
          }
        });
    }
  }

  //Create/Edit of import
  saveImport() {
    // this.listSlots = [];
    let objImport: any = [];
    if (!this.formImport.value.customer_id) {
      this.toastr.warnning('Vui lòng chọn khách hàng');
      return;
    }

    if (!this.formImport.value.contract_id) {
      this.toastr.warnning('Vui lòng chọn hợp đồng');
      return;
    }

    // if (!this.formImport.value.implementer_id) {
    //   this.toastr.warnning('Vui lòng chọn người kiểm kê');
    //   return;
    // }

    if (this.formImport.value.container_code.length > 50) {
      this.toastr.warnning('Số container tối đa 50 kí tự');
      return;
    }

    let containerTemp = this.formImport.value.container_code
      ? this.formImport.value.container_code.trim()
      : null;

    if (!containerTemp) {
      this.toastr.warnning('Vui lòng điền số container');
      return;
    }

    let checkReceiver = this.formImport.value.receiver
      ? this.formImport.value.receiver.trim()
      : null;

    if (!checkReceiver) {
      this.toastr.warnning('Vui lòng chọn người nhận');
      return;
    }

    let checkSeller = this.formImport.value.seller_id
      ? this.formImport.value.seller_id.trim()
      : null;

    if (!checkSeller) {
      this.toastr.warnning('Vui lòng chọn nhân viên kinh doanh');
      return;
    }

    if (this.formImport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    if (this.isCheckDraft == false) {
      this.arrProducts.forEach((e) => {
        if (e.is_draft == true) {
          this.isCheckDraft = true;
          e.selectHashtag.forEach((el) => {
            el.new_value = 100 - el.used_percent;
          });
          this.editItem(e);
        }
      });
      if (this.isCheckDraft) {
        this.toastr.warnning('Vui lòng kiểm tra lại số lượng và phần trăm');
        return;
      }
      this.isCheckDraft = null;
    }

    let res = this.createObject(false, false, objImport);
    if (!res) return;
    var checkEdit: boolean;
    this.arrProducts.forEach((el) => {
      if (el.isEdit == true) {
        checkEdit = true;
      }
    });
    if (checkEdit) {
      this.toastr.warnning('Vui lòng lưu bảng danh sách hàng hóa');
      return;
    }
    console.log(objImport)
    return
    this.checkImport = true;
    if (this.imExport_code) {
      this.arrProducts.forEach((element) => {
        element.selectHashtag.forEach((el) => {
          let totalPercent = el.new_value + el.used_percent;
          this.slotService
            .apiSlotIdUsedPut(el.id, totalPercent > 100 ? 100 : totalPercent)
            .pipe(debounceTime(300))
            .toPromise()
            .then((data) => {});
        });
      });
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objImport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    } else {
      this.arrProducts.forEach((element) => {
        element.selectHashtag.forEach((el) => {
          let totalPercent = el.new_value + el.used_percent;
          this.slotService
            .apiSlotIdUsedPut(el.id, totalPercent > 100 ? 100 : totalPercent)
            .pipe(debounceTime(300))
            .toPromise()
            .then((data) => {});
        });
      });
      this.importExportHistoryService
        .apiImportExportHistoryPost(objImport)
        .subscribe((res) => {
          if (res.success) {
            if (this.saleItem) {
              this.checkContainerisTransfer(res.data.imExportCode);
            }
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    }
  }

  checkContainerisTransfer(code) {
    this.containerService
      .apiContainerIdPut(this.saleItem.id, {
        is_tranfer_car: true,
        import_code: code,
      })
      .subscribe((res) => {
        if (res.success) {
        }
      });
  }

  //Uncheck import
  saveUncheck() {
    let objImport: any = [];
    if (!this.formImport.value.customer_id) {
      this.toastr.warnning('Vui lòng chọn khách hàng');
      return;
    }

    if (!this.formImport.value.contract_id) {
      this.toastr.warnning('Vui lòng chọn hợp đồng');
      return;
    }

    if (!this.formImport.value.implementer_id) {
      this.toastr.warnning('Vui lòng chọn người kiểm kê');
      return;
    }

    if (this.formImport.value.container_code.length > 20) {
      this.toastr.warnning('Số container tối đa 20 kí tự');
      return;
    }

    if (this.formImport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    let res = this.createObject(true, false, objImport);
    if (!res) return;

    var checkEdit: boolean;
    this.arrProducts.forEach((el) => {
      if (el.isEdit == true) {
        checkEdit = true;
      }
    });
    if (checkEdit) {
      this.toastr.warnning('Vui lòng lưu bảng danh sách hàng hóa');
      return;
    }

    if (this.imExport_code) {
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objImport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    }
  }

  //Check import
  saveCheck() {
    let objImport: any = [];
    if (!this.formImport.value.customer_id) {
      this.toastr.warnning('Vui lòng chọn khách hàng');
      return;
    }

    if (!this.formImport.value.contract_id) {
      this.toastr.warnning('Vui lòng chọn hợp đồng');
      return;
    }

    if (!this.formImport.value.implementer_id) {
      this.toastr.warnning('Vui lòng chọn người kiểm kê');
      return;
    }

    if (this.formImport.value.container_code.length > 20) {
      this.toastr.warnning('Số container tối đa 20 kí tự');
      return;
    }

    if (this.formImport.value.note.length > 300) {
      this.toastr.warnning('Ghi chú tối đa 300 kí tự');
      return;
    }

    let res = this.createObject(false, true, objImport);

    if (!res) return;
    this.disableCheck = true;
    if (this.imExport_code) {
      this.importExportHistoryService
        .apiImportExportHistoryCodePut(this.imExport_code, objImport)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.closeModal(true);
          }
        });
    }
  }

  removeImport() {
    let title = 'Xóa phiếu';
    let content = 'Bạn có chắc chắn muốn xóa phiếu này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.importExportHistoryService
            .apiImportExportHistoryDeleteImportExportRecordCodeDelete(
              this.imExport_code
            )
            .subscribe((res) => {
              if (res.success == true) {
                this.toastr.success(res.message);
                this.closeModal(true);
              } else {
                this.toastr.error(res.message);
                this.closeModal(true);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  addProduct = (term: string) => {
    if (!this.isOpenModalProduct) {
      this.isOpenModalProduct = true;
      this.titleModal = 'Thêm mới hàng hóa';
      this.product_name = term;
    }
  };

  closeModalProduct(data) {
    if (!data) {
      if (this.isOpenModalProduct) {
        this.isOpenModalProduct = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModalProduct = false;
        this.product_name = '';
        this.getAllProducts();
        this.toastr.success(data.message);
      } else {
        this.toastr.error(data.message);
      }
    }
  }

  onClickSKU(e) {
    this.value_product_id = e.selectProduct;
  }

  addSku = (term: string) => {
    if (!this.isOpenModalSKU) {
      if (this.idProduct || this.value_product_id) {
        this.isOpenModalSKU = true;
        this.titleModalSKU = 'Thêm mới SKU';
        if (this.idProduct) {
          this.product_id = this.idProduct;
        } else {
          this.product_id = this.value_product_id;
        }
        this.sku_code = term;
      } else {
        this.toastr.warnning('Vui lòng chọn hàng hóa');
        return;
      }
    }
  };

  closeModalSku(data) {
    if (!data) {
      if (this.isOpenModalSKU) {
        this.isOpenModalSKU = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModalSKU = false;
        this.product_id = '';
        this.value_product_id = '';
        this.sku_code = '';
        this.skuService
          .apiSkuGet('product_id==' + data.data.product_id, '', 1, 1000)
          .subscribe((response) => {
            const ele = this.arrProducts.find(
              (p) => p.selectProduct == data.data.product_id
            );
            if (response.success) {
              if (ele) {
                ele.allSkus = response.data.collection;
              }
            }
          });
        this.toastr.success(data.message);
      } else {
        this.toastr.error(data.message);
      }
    }
  }

  openPrintQR() {
    if (!this.isOpenModalQR) {
      this.isOpenModalQR = true;
      this.titleModalQR = 'In mã QR';
    }
  }

  closeModalQR(event) {
    if (this.isOpenModalQR) {
      this.titlePrint = null;
      this.isOpenModalQR = false;
    }
  }

  openSelectCell(index, element) {
    if (!element.isEdit) {
      return;
    }
    if (element.selectProduct) {
      this.productSelected = element.selectProduct;
      this.skuSelected = element.selectSku;
    } else {
      this.productSelected = '';
      this.skuSelected = '';
    }
    this.lstSlot = this.arrProducts[index].selectHashtag;
    if (!this.isOpenSelectCell && !this.isMobile) {
      this.tempIndex = index;
      this.isOpenSelectCell = true;
      this.optionsSelectCell.styleClass = 'w-5/12';
      this.titleModalCell = 'Chọn vị trí';
    }
    if (!this.isOpenSelectCellBottom && this.isMobile) {
      this.tempIndex = index;
      this.isOpenSelectCellBottom = true;
      this.titleModalCell = 'Chọn vị trí';
    }
  }

  closeSelectCell(event) {
    if (this.isMobile) {
      this.isOpenSelectCellBottom = false;
    } else {
      this.isOpenSelectCell = false;
    }
    if (event) {
      this.arrProducts[this.tempIndex].selectHashtag = [];
      event.forEach((item) => {
        if (item.isSelected === true) {
          this.arrProducts[this.tempIndex].selectHashtag.push(item);
        }
      });
      this.saveItem(this.arrProducts[this.tempIndex], this.tempIndex);
    }
  }

  async closeModalPercenUsedSlot(event) {
    if (event.success && event.listData.length > 0) {
      this.listPercent = [];
      // get Old selectHashtag
      event.listData.forEach((element) => {
        this.listPercent.push(element.new_value);
      });
      var selectHashtag = [];
      this.arrProducts.forEach((element, index) => {
        if (index == event.listData.index) {
          // selectHashtag = element.selectHashtag;
          for (let i = 0; i < element.selectHashtag.length; i++) {
            for (let j = 0; j < event.listData.length; j++) {
              if (element.selectHashtag[i].id == event.listData[j].id) {
                element.selectHashtag[i].quantity =
                  event.listData[j].quantitySlot;
                element.selectHashtag[i].new_value =
                  event.listData[j].new_value;
              }
            }
          }
          // element.selectHashtag.forEach((ele1) => {
          //   event.listData.forEach((el2) => {
          //     ele1.quantity = el2.quantitySlot;
          //     ele1.new_value = el2.new_value;
          //     return;
          //   });
          // });
        }
      });

      this.arrProducts.forEach((el) => {
        let totalQuantity = 0;
        if (el.selectHashtag && el.selectHashtag.length > 0) {
          el.selectHashtag?.forEach((e, index) => {
            totalQuantity += e.quantity;
          });
          el.quantity = totalQuantity;
        }
      });

      //Update new Hashtag
      if (this.isMobile) {
        if (this.isOpenModalPercentSlotBottom) {
          this.arrProducts.forEach((element, index) => {
            if (index == event.listData.index) {
              element.isEdit = false;
            }
          });
          this.isOpenModalPercentSlotBottom = false;
        }
      } else {
        if (this.isOpenModalPercentSlot) {
          this.arrProducts.forEach((element, index) => {
            if (index == event.listData.index) {
              element.isEdit = false;
            }
          });
          this.isOpenModalPercentSlot = false;
        }
      }
    } else if (!event.success) {
      if (this.isMobile) {
        if (this.isOpenModalPercentSlotBottom) {
          this.isOpenModalPercentSlotBottom = false;
        }
      } else {
        if (this.isOpenModalPercentSlot) {
          this.isOpenModalPercentSlot = false;
        }
      }
    }
  }
  openModalPrint() {
    if (!this.isOpenPrintIE) {
      this.isOpenPrintIE = true;
      this.optionsPrintIE.styleClass = 'w-8/12';
    }
  }

  navigateSSO() {
    window.location.href = environment.ssoFeUrl;
  }
  optionPrint(data) {
    switch (data.title) {
      case 'QR code':
        this.openPrintQR();
        break;
      case this.isTransferCar ? 'Lệnh Khai Báo' : 'Lệnh Nhập':
        this.title_print = this.isTransferCar ? 'Phiếu Khai Báo' : 'Phiếu Nhập';
        this.isBillOrCommand = false;
        this.openModalPrint();
        break;
      case this.isTransferCar ? 'Phiếu Khai Báo' : 'Phiếu Nhập':
        this.title_print = this.isTransferCar ? 'Phiếu Khai Báo' : 'Phiếu Nhập';
        this.isBillOrCommand = true;
        this.openModalPrint();
        break;
      case 'Tờ Phân Bổ':
        this.title_print = 'Tờ Phân Bổ';
        this.isPrintDivideQuantity = true;
        this.openModalPrint();
        break;
      case 'Báo cáo':
        var blobExportReport: Blob;
        this.exportService
          .apiExportImportExportReportByImportCodeImportCodePost(
            this.imExport_code
          )
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastr.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BáoCáoPhiếuNhập-' + this.imExport_code + '.xlsx'
              );
            }
          });
        break;
      case 'Bảng kê phí':
        var blobExportReport: Blob;
        this.exportService
          .apiExportCostStoreImportCodePost(this.imExport_code)
          .subscribe((res) => {
            if (res.type === 'application/json') {
              res.text().then((text) => {
                let resToJson = JSON.parse(text.toString());
                this.toastr.error(resToJson.message);
              });
            } else {
              blobExportReport = new Blob([res], {
                type: res.type,
              });
              saveAs(
                blobExportReport,
                'BảngKêPhíLưuKhovàBốcXếp-' + this.imExport_code + '.xlsx'
              );
            }
          });
        break;
      default:
        break;
    }
  }
  //SSO
  navigateNewWindowsSSO() {
    window.open(environment.ssoFeUrl);
  }
}
