import { stringify } from '@angular/compiler/src/util';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionService } from '../../guards/permission.service';
import { MenuConfig } from '../config/menu.config';

@Component({
  selector: 'meu-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild('dropdown') dropdown: ElementRef<any>;
  @Input() inventorySelected;
  lstPermission: any = [];
  constructor(
    private menuConfig: MenuConfig,
    private router: Router,
    private permissionService: PermissionService
  ) {}
  menuItem: any = [];
  selectedItem: any = {};
  selectedSubItem: any = {};
  ngOnChanges(changes: SimpleChanges) {
    if (changes.inventorySelected.currentValue) {
      this.currentPositionByUrl(this.menuItem, this.router.url);
    }
  }
  ngOnInit(): void {
    this.menuItem = this.menuConfig.configs.header.items;
  }
  ngAfterViewInit() {
    this.permissionService
      .getMyPermissions()
      .toPromise()
      .then((res) => {
        this.lstPermission = res;
        if (
          this.lstPermission.includes('CUSTOMER') &&
          this.lstPermission.length == 1
        ) {
          this.menuItem[0] = this.menuItem[0].submenu[1];
          this.menuItem[0].title = 'báo cáo';
        }
        if (
          this.lstPermission.includes('STAFF') &&
          this.lstPermission.length == 1
        ) {
          this.menuItem[0] = this.menuItem[0].submenu[0];
          this.menuItem[0].title = 'báo cáo';
        }
        this.currentPositionByUrl(this.menuItem, this.router.url);
      });
  }
  onClickMenu(item, i, e) {
    if (item.submenu?.length > 0) {
      return;
    } else {
      this.selectedItem.selected = false;
      item.selected = true;
      this.selectedItem = item;
      this.selectedSubItem ? (this.selectedSubItem.selected = false) : null;
      if (item.page) {
        this.router.navigate([
          item.page.replace('{{inventoryId}}', this.inventorySelected),
        ]);
      }
    }
    e.stopPropagation();
  }
  submenuClick(item, i, e) {
    this.selectedItem.selected = false;
    this.selectedSubItem ? (this.selectedSubItem.selected = false) : null;
    this.menuItem[i].selected = true;
    this.selectedItem = this.menuItem[i];
    item.selected = true;
    this.selectedSubItem = item;
    let dropdown = e.target as HTMLElement;
    dropdown.focus();
    dropdown.blur();
    this.router.navigate([
      item.page.replace('{{inventoryId}}', this.inventorySelected),
    ]);
    e.stopPropagation();
  }
  currentPositionByUrl(menuItem, url, parentIndex = -1) {
    menuItem.reduce((accumulator, item, i) => {
      item.isHasPermission = this.isHasPermissions(item.permission);
      if (item.submenu?.length > 0) {
        this.currentPositionByUrl(item.submenu, url, i);
      } else {
        let page = item.page.replace('{{inventoryId}}', this.inventorySelected);
        if (item.page && url == page) {
          this.menuItem[parentIndex > -1 ? parentIndex : i].selected = true;
          this.selectedItem = this.menuItem[parentIndex > -1 ? parentIndex : i];
          item.selected = true;
          this.selectedSubItem = parentIndex > -1 ? item : null;
          return;
        } else {
          item.selected = false;
        }
      }
    }, []);
  }
  isHasPermissions(permission) {
    if (permission && this.lstPermission) {
      return this.lstPermission.includes(permission);
    }
    return true;
  }
}
