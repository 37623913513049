<div class="w-315px shadow-sm">
  <ng-select
    class="w-315px select-name"
    [clearable]="false"
    placeholder="Chọn khách hàng"
    bindLabel="full_name"
    bindValue="id"
    [items]="Customers"
    [(ngModel)]="defaultID"
    [dropdownPosition]="'bottom'"
    (change)="getValueClientContract($event)"
    notFoundText="Không tìm thấy khách hàng"
  >
    <ng-template ng-label-tmp let-item="item">
      <div class="flex items-center space-x-2">
        <img height="20" width="20" [src]="item.avatar" class="rounded-full" />
        <span class="text-14px font-roboto-regular text-dark-grey">{{
          item.full_name
        }}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="flex items-center space-x-2">
        <img height="20" width="20" [src]="item.avatar" class="rounded-full" />
        <span>{{ item.full_name }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
