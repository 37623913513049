import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuConfig } from './layout/config/menu.config';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { SubheaderComponent } from './layout/subheader/subheader.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationComponent } from './layout/notification/notification.component';
import { LoginComponent } from './layout/login/login.component';
import { MobileMenuComponent } from './layout/menu/mobile-menu/mobile-menu.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from './interceptors';
import { HttpResponseInterceptor } from './interceptors/http.response.interceptor';
import { ErrorComponent } from './layout/error/error.component';
// import { MenuAsideLeftComponent } from './layout/menu/menu-aside-left/menu-aside-left.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SubheaderComponent,
    MainLayoutComponent,
    NotificationComponent,
    LoginComponent,
    MobileMenuComponent,
    ErrorComponent,
  ],
  imports: [RouterModule, CommonModule, SharedModule],
  exports: [SubheaderComponent, MenuComponent],
  providers: [
    MenuConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
