<div class="col-span-full px-16px py-5px">
  <div
    class="col-span-12 overflow-x-auto max-h-300px max-h-m-413px"
    style="overflow-y: auto; max-height: 320px"
  >
    <table
      class="w-full border-separate table-fixed"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
            #
          </th>

          <th
            class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="isImport ? 'Mã phiếu nhập' : 'Mã phiếu xuất'"
                >{{ isImport ? 'Mã phiếu nhập' : 'Mã phiếu xuất' }}
              </span>
            </div>
          </th>

          <th
            class="text-left col-created-at cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Ngày tạo"
                >Ngày tạo</span
              >
            </div>
          </th>

          <th
            class="text-left col-receipt-issue-date cursor-pointer pt-12px px-16px pb-8px"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="isImport ? 'Ngày nhập kho' : 'Ngày xuất kho'"
                >{{ isImport ? 'Ngày nhập kho' : 'Ngày xuất kho' }}</span
              >
            </div>
          </th>

          <th
            class="text-center col-option pt-12px px-16px pb-8px cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap"
            tooltip="Trạng thái"
          >
            Trạng thái
          </th>
        </tr>
      </thead>

      <!-- DATA TABLE -->
      <tbody
        class="font-roboto-regular overflow-y-auto"
        *ngIf="totalArray != 0; else noInfo"
      >
        <tr
          *ngFor="let importexport of lstImExport; let i = index"
          style="min-height: 40px !important"
        >
          <td class="td-stt py-10px" data-label="#">
            {{ (pageIndex - 1) * pageSize + i + 1 }}
          </td>

          <td
            class="td-code py-10px px-16px responsive"
            [attr.data-label]="isImport ? 'Mã phiếu nhập' : 'Mã phiếu xuất'"
          >
            <!-- data-label="isImport ? 'Mã phiếu nhập' : 'Mã phiếu xuất'" -->
            <div
              class="relative flex cursor-pointer"
              *ngIf="importexport.isImExported; else showExport"
              [tooltip]="importexport.imExportCode"
            >
              <img
                src="../../../../assets/icons/dashboard-icons/u_file-download-alt.svg"
                class="w-16px h-16px mr-5px mt-2px"
              />
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                (click)="open(importexport.imExportCode)"
                >{{ importexport.imExportCode }}</span
              >
            </div>
            <ng-template #showExport>
              <div
                class="relative flex cursor-pointer"
                [tooltip]="importexport.imExportCode"
              >
                <img
                  src="../../../../assets/icons/dashboard-icons/u_file-upload-alt.svg"
                  class="w-16px h-16px mr-5px mt-2px"
                />
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-dodger-blue"
                  (click)="open(importexport.imExportCode)"
                  >{{ importexport.imExportCode }}</span
                >
              </div>
            </ng-template>
          </td>

          <td
            class="td-unit py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Ngày tạo"
          >
            <span
              class="cursor-pointer"
              [tooltip]="importexport.created_at | date: 'dd/MM/yyyy HH:mm'"
            >
              {{ importexport.created_at | date: 'dd/MM/yyyy HH:mm' }}
            </span>
          </td>

          <td
            class="td-unit py-10px px-16px overflow-hidden overflow-ellipsis whitespace-nowrap"
            [attr.data-label]="isImport ? 'Ngày nhập kho' : 'Ngày xuất kho'"
          >
            <span
              class="cursor-pointer"
              *ngIf="importexport.isImExported; else showExportDate"
              [tooltip]="
                importexport.receipt_date
                  ? (importexport.receipt_date | date: 'dd/MM/yyyy HH:mm')
                  : '---'
              "
            >
              {{
                importexport.receipt_date
                  ? (importexport.receipt_date | date: 'dd/MM/yyyy HH:mm')
                  : '---'
              }}
            </span>

            <ng-template #showExportDate>
              <span
                class="cursor-pointer"
                [tooltip]="
                  importexport.issue_date
                    ? (importexport.issue_date | date: 'dd/MM/yyyy HH:mm')
                    : '---'
                "
              >
                {{
                  importexport.issue_date
                    ? (importexport.issue_date | date: 'dd/MM/yyyy HH:mm')
                    : '---'
                }}
              </span>
            </ng-template>
          </td>

          <td class="td-thaotac px-12px py-10px" data-label="Trạng thái">
            <!-- Import -->
            <span *ngIf="importexport.isImExported; else showExportStatus">
              <span *ngIf="importexport.is_draft; else showImportInfo">
                <div
                  class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-grey-label bg-grayscale-light-grey status-style"
                >
                  Lưu nháp
                </div>
              </span>
              <ng-template #showImportInfo>
                <div
                  class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-green-blue bg-duck-egg-blue status-style"
                >
                  Đã nhập
                </div>
              </ng-template>
            </span>

            <!-- Export -->
            <ng-template #showExportStatus>
              <span *ngIf="importexport.is_draft; else showExportInfo">
                <div
                  class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-grey-label bg-grayscale-light-grey status-style"
                >
                  Lưu nháp
                </div>
              </span>
              <ng-template #showExportInfo>
                <div
                  class="w-auto h-auto flex-grow-0 gap-4px rounded-4px p-6px items-center text-center text-orange-red bg-light-peach status-style"
                >
                  Đã xuất
                </div>
              </ng-template>
            </ng-template>
          </td>
        </tr>
      </tbody>

      <ng-template #noInfo>
        <tbody>
          <tr
            class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
          >
            <td colspan="9" class="td-noinfo">Không có dữ liệu</td>
          </tr>
        </tbody>
      </ng-template>
    </table>

    <meu-pagination-component
      *ngIf="totalArray != 0"
      [inputPageSize]="5"
      [total]="totalArray"
      [setPropertyTotal]="true"
      [changePage]="onPagingFunc"
      (onChangeValue)="onChangeValue($event)"
    ></meu-pagination-component>
  </div>
  <div class="col-span-12 flex-row-reverse gap-8px flex h-40px my-12px">
    <button (click)="closeModal()" class="btn-text-cancel">Đóng</button>
  </div>
</div>
