import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'meu-modal-debt-manage',
  templateUrl: './modal-debt-manage.component.html',
  styleUrls: ['./modal-debt-manage.component.scss']
})
export class ModalDebtManageComponent implements OnInit {
  isPayment = false
  passUserDetailDebt;
  @Input() userDetailDebt: any;
  @Input() userDetailID: string;
  @Output() closeResquest = new EventEmitter()
  constructor() { }

  ngOnInit(): void {

  }
  closeModal(res: boolean) {
    this.closeResquest.emit(res)
  }
}
