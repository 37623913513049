import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ImportExportHistoryService, ProductService } from 'src/app/api/api';
import { ImportExportHistory } from 'src/app/model/models';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { Subject } from 'rxjs';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { filter } from 'rxjs/internal/operators/filter';
import { take, debounce, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'src/app/core/services';
import { ConditionalExpr } from '@angular/compiler';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';

@Component({
  selector: 'meu-import-export-product',
  templateUrl: './import-export-product.component.html',
  styleUrls: ['./import-export-product.component.scss'],
})
export class ImportExportProductComponent implements OnInit {
  idProduct: string = '';
  im_Export_code: string;
  imExport_code: string;
  searchIExport: string = '';
  idInventory: string;

  indexArray: number = 0;
  totalArray: number;
  array: ImportExportHistory[] = [];
  IExports: ImportExportHistory[] = [];

  subject: Subject<any> = new Subject();
  sortName: string = '';
  sort: string = '';

  titleModal: string;
  isOpenModal: boolean;
  options: MxModalOption = {};

  titleModalExport: string;
  isOpenModalExport: boolean;
  optionsExport: MxModalOption = {};

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private importExportService: ImportExportHistoryService,
    private confirmService: ConfirmService,
    private toastService: ToastrService,
    private router: Router,
    private localStoreService: LocalStoreService
  ) {}

  ngOnInit(): void {
    this.idProduct = this.router.url.split('/')[5];
    this.getIExportOfProduct();
    this.optionCallbackModalIx();
    this.optionCallbackModalEx();
    this.search();
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getIExportOfProduct();
  }

  //search
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}
  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getIExportOfProduct();
    });
  }

  // Paging

  getIExportOfProduct() {
    this.importExportService
      .apiImportExportHistoryProductProductIdGet(
        this.idProduct,
        `imExportCode|unit_name|extend_inventory_name@=${this.searchIExport}`
      )
      .pipe(debounceTime(2000))

      .subscribe((res) => {
        if (res.success == true) {
          this.array = res.data.collection;
          this.totalArray = res.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.importExportService
      .apiImportExportHistoryProductProductIdGet(
        this.idProduct,
        `imExportCode|unit_name|extend_inventory_name@=${this.searchIExport}`,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.IExports = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //Modal
  addImport() {
    this.titleModal = 'Thêm mới phiếu nhập hàng';
    if (!this.isOpenModal) {
      this.isOpenModal = true;
      this.options.styleClass = 'w-11/12';
    }
  }

  addExport() {
    this.titleModalExport = 'Thêm mới phiếu xuất hàng';
    if (!this.isOpenModal) {
      this.isOpenModalExport = true;
      this.optionsExport.styleClass = 'w-11/12';
    }
  }
  optionCallbackModalIx() {
    this.options.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModal = false;
    };
  }
  optionCallbackModalEx() {
    this.optionsExport.callBackAfterClose = (e) => {
      this.imExport_code = '';
      this.isOpenModalExport = false;
    };
  }
  open(code) {
    if (code) {
      if (code.includes('PN')) {
        this.imExport_code = code;
        this.titleModal = 'Chi tiết phiếu nhập hàng';

        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }

      //Export
      if (code.includes('PX')) {
        this.im_Export_code = code;
        this.titleModalExport = 'Chi tiết phiếu xuất hàng';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }

      if (code.includes('PSN')) {
        this.imExport_code = code;
        this.titleModal = 'Chi tiết phiếu khai báo';

        if (!this.isOpenModal) {
          this.isOpenModal = true;
          this.options.styleClass = 'w-11/12';
        }
      }

      //Export
      if (code.includes('PSX')) {
        this.im_Export_code = code;
        this.titleModalExport = 'Chi tiết phiếu sang xe';
        if (!this.isOpenModalExport) {
          this.isOpenModalExport = true;
          this.optionsExport.styleClass = 'w-11/12';
        }
      }
    }
  }

  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      this.imExport_code = '';
    }
    if (event.success) {
      this.getIExportOfProduct();
    }
  }

  closeModalExport(event) {
    if (this.isOpenModalExport) {
      this.isOpenModalExport = false;
      this.im_Export_code = '';
    }
    if (event.success) {
      this.getIExportOfProduct();
    }
  }

  changeTitle(event) {
    if (event.success) {
      this.titleModal = 'Chi tiết phiếu nhập hàng';
    }
  }

  changeTitleExport(event) {
    if (event.success) {
      this.titleModalExport = 'Chi tiết phiếu xuất hàng';
    }
  }

  // closeModal(data) {
  //   if (!data) {
  //     if (this.isOpenModal) {
  //       this.isOpenModal = false;
  //     }
  //   } else {
  //     if (data.success == true) {
  //       if (this.isOpenModal) {
  //         this.isOpenModal = false;
  //         this.imExport_code = '';
  //         this.getIExportOfProduct();
  //       }
  //     } else {
  //     }
  //   }
  // }
  // deleteconfirm(id) {
  //   let title = 'Xóa SKU';
  //   let content = 'Bạn có chắc chắn muốn xóa SKU này ?';
  //   this.confirmService.deleteConfirm(title, content, true);
  //   this.confirmService.open$
  //     .pipe(
  //       filter((event) => !!event),
  //       filter((event) => {
  //         return event.type === 'delete' || event.type === 'close';
  //       }),
  //       take(1)
  //     )
  //     .subscribe((events) => {
  //       if (events.type == 'delete') {
  //         this.skuService.apiSkuIdDelete(id).subscribe((res) => {
  //           if (res.success === true) {
  //             this.getSkuOfProduct();
  //             this.toastSucces = true;
  //             this.titleMess = res.message;
  //             setTimeout(
  //               function () {
  //                 this.toastSucces = false;
  //               }.bind(this),
  //               2500
  //             );
  //           } else {
  //             this.getSkuOfProduct();
  //             this.toastAlert = true;
  //             this.titleMess = res.message;
  //             setTimeout(
  //               function () {
  //                 this.toastAlert = false;
  //               }.bind(this),
  //               2500
  //             );
  //           }
  //         });
  //         this.confirmService.close();
  //       } else {
  //         this.confirmService.close();
  //       }
  //     });
  // }
}
