<div class="container contain-menu">
  <button
    class="icon-menu-top"
    routerLink="../container-management/list-container"
    routerLinkActive="active"
  >
    <img src="assets/icons/menu-icons/container.svg" alt="" class="icon-svg" />
    <span class="title-icon">Quản lý container</span>
  </button>
  <button
    class="icon-menu"
    routerLink="../inventory/{{ inventoryId }}/dashboard/dashboard-manage"
    routerLinkActive="active"
  >
    <img src="assets/icons/menu-icons/chart.svg" alt="" class="icon-svg" />
    <span class="title-icon">Tổng quan kho hàng</span>
  </button>
  <button
    class="icon-menu"
    routerLink="../inventory/{{ inventoryId }}/dashboard-client"
    routerLinkActive="active"
  >
    <img src="assets/icons/menu-icons/client.svg" alt="" class="icon-svg" />
    <span class="title-icon">Báo cáo khách hàng</span>
  </button>
  <button
    class="icon-menu"
    routerLink="../container-management/sales-history"
    routerLinkActive="active"
  >
    <img
      src="assets/icons/menu-icons/lich-su-ban-hang.svg"
      alt=""
      class="icon-svg"
    />
    <span class="title-icon">Lịch sử bán hàng</span>
  </button>
  <button
    routerLink="../debt-management"
    routerLinkActive="active"
    class="icon-menu"
  >
    <img src="assets/icons/menu-icons/debt.svg" alt="" class="icon-svg" />
    <span class="title-icon">Công nợ khách hàng</span>
  </button>
  <button 
    class="icon-menu"
    (click)="nav('1')"
    >
    <img src="assets/icons/menu-icons/nhap-kho.svg" alt="" class="icon-svg" />
    <span class="title-icon">Nhập kho</span>
  </button>
  <button 
    class="icon-menu"
    (click)="nav('2')"
    >
    <img src="assets/icons/menu-icons/xuat-kho.svg" alt="" class="icon-svg" />
    <span class="title-icon">Xuất kho</span>
  </button>
</div>
