import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SummaryContainerService {
  container_id = new BehaviorSubject<any>(null);
  containerSummary_id = new BehaviorSubject<any>(null);
  containerSummary = new BehaviorSubject<any>(null);
  totalRevenue = new BehaviorSubject<any>(null);
  numberRevenue = new BehaviorSubject<any>(null);
  is_print = new BehaviorSubject<boolean>(false);

  constructor() {}
}
