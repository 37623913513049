import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ContractService, UserService } from 'src/app/api/api';
import {
  Contract,
  ContractStatus,
  ContractTemplate,
  ContractType,
  User,
} from 'src/app/model/models';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { IDayCalendarConfig } from 'ng2-date-picker';
import { saveAs } from 'file-saver';
import { debounceTime, filter, take } from 'rxjs/operators';
import { MeucurrencyPipe } from 'src/app/shared/pipes/meucurrency.pipe';

@Component({
  selector: 'meu-information-contract',
  templateUrl: './information-contract.component.html',
  styleUrls: ['./information-contract.component.scss'],
})
export class InformationContractComponent implements OnInit {
  idContract: string = this.route.snapshot.paramMap.get('id');

  @Output() checkUpdate: EventEmitter<any> = new EventEmitter();

  contractTemplate: ContractTemplate;
  contractTemplateID;
  contract: Contract = {};
  typeContract: ContractType;
  statusContract: ContractStatus;
  users: User;
  users_create: User = {};
  change: boolean = true;
  actual_price: number = 0;

  datechange: boolean = true;

  tlpContract;

  dateEnd;
  dateStart;

  filesContract: File[] = [];
  filesContractUpdate: File[] = [];

  isDownload: boolean;

  formUpdate = new FormGroup({
    //contract infomation
    contractTemplate: new FormControl('', Validators.required),
    tax: new FormControl('', Validators.pattern('[0-9]+(,[0-9]+)*,?')),
    fax: new FormControl('', Validators.pattern('[0-9]+(,[0-9]+)*,?')),
    address: new FormControl(''),
    type: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    save: new FormControl(''),
    customer: new FormControl('', Validators.required),
    //createby_customer: new FormControl('', Validators.required),
    dateSigned: new FormControl(''),
    status: new FormControl(Validators.required),
    dateStart: new FormControl(Validators.required),
    dateEnd: new FormControl(Validators.required),
    liquidationDate: new FormControl(),
    note: new FormControl('', Validators.maxLength(300)),
    //customer infomation
    name: new FormControl(''),
    company: new FormControl(''),
    position: new FormControl(''),
    identity_card: new FormControl(
      '',
      Validators.pattern('[0-9]+(,[0-9]+)*,?')
    ),
    issued_by: new FormControl(''),
    issued_date: new FormControl(''),
    bank: new FormControl(''),
    //amount contract
    amount: new FormControl('', [Validators.pattern('[0-9]+(,[0-9]+)*,?')]),
    actual_amount: new FormControl('', [
      Validators.pattern('[0-9]+(,[0-9]+)*,?'),
    ]),
    discount: new FormControl('', [Validators.pattern('[0-9]+(,[0-9]+)*,?')]),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contractService: ContractService,
    private userService: UserService,
    private toastrService: ToastrService,
    private confirmService: ConfirmService,
    private meuCurrencyPipe: MeucurrencyPipe
  ) {}

  datePickerConfig = <IDayCalendarConfig>{
    locale: 'vi',
    format: 'DD/MM/YYYY',
    firstDayOfWeek: 'mo',
    opens: 'right',
    drops: 'down',
  };

  ngOnInit(): void {
    this.isDownload = false;
    this.getContractByID();
    this.getAllContractTemplate();
    this.getCustomers();
    this.getStatus();
    this.getTypeContract();
  }

  //Get detail
  getContractByID() {
    this.contractService.apiContractIdGet(this.idContract).subscribe((res) => {
      if (res.success == true) {
        this.contract = res.data;
        this.contractTemplateID = res.data.contract_template_id;
        this.getTooltip(res.data.contract_template_id);
        //this.actual_price = amount1 - amount2;
        this.formUpdate.patchValue({
          contractTemplate: res.data.contract_template_id
            ? res.data.contract_template_id
            : '',
          type: res.data.contract_type_id ? res.data.contract_type_id : '',
          tax: res.data.client_tax_code ? res.data.client_tax_code : '',
          fax: res.data.client_fax ? res.data.client_fax : '',
          address: res.data.client_address ? res.data.client_address : '',
          code: res.data.code ? res.data.code : '',
          customer: res.data.customer_id ? res.data.customer_id : '',
          //createby_customer: res.data.created_by,
          dateSigned: res.data.date_signed
            ? moment(res.data.date_signed).format('DD/MM/YYYY ')
            : '',
          status: res.data.status,
          dateStart: res.data.date_contract_start
            ? moment(res.data.date_contract_start).format('DD/MM/YYYY ')
            : '',
          dateEnd: res.data.date_contract_end
            ? moment(res.data.date_contract_end).format('DD/MM/YYYY')
            : '',
          liquidationDate: res.data.liquidation_date
            ? moment(res.data.liquidation_date).format('DD/MM/YYYY')
            : '',
          note: res.data.note,
          //info client
          name: res.data.client_representator_name
            ? res.data.client_representator_name
            : '',
          company: res.data.client_company_name
            ? res.data.client_company_name
            : '',
          position: res.data.client_representator_position
            ? res.data.client_representator_position
            : '',
          identity_card: res.data.client_representator_id_number
            ? res.data.client_representator_id_number
            : '',
          issued_by: res.data.client_representator_issue_place
            ? res.data.client_representator_issue_place
            : '',
          issued_date: res.data.client_representator_issue_date
            ? res.data.client_representator_issue_date
            : '',
          bank: res.data.client_bank_info ? res.data.client_bank_info : '',
          //price
          amount: res.data.amount_on_contract ? res.data.amount_on_contract : 0,
          discount: res.data.discount ? res.data.discount : 0,
          actual_amount: res.data.actual_amount ? res.data.actual_amount : 0,
        });
        let amount1 = this.formUpdate.controls['amount'].value;
        let amount2 = this.formUpdate.controls['discount'].value;
        let amount = amount1 - amount2;
        this.formUpdate.controls['actual_amount'].setValue(amount);

        this.getUserCreate();
        this.getAttachmentContract(this.idContract);
      }
    });
  }

  //Get contract template
  getAllContractTemplate() {
    this.contractService.apiContractTemplateGet().subscribe((res) => {
      if (res.success == true) {
        this.contractTemplate = res.data.collection;
      }
    });
  }
  getValueTemplateContract(data) {
    if (data) {
      this.formUpdate.controls['contractTemplate'].setValue(data.id);
      this.getTooltip(data.id);
    }
  }
  getTooltip(id) {
    this.contractService.apiContractTemplateIdGet(id).subscribe((res) => {
      if (res.success) {
        this.tlpContract = res.data.name;
      }
    });
  }

  //Update
  onUpdate() {
    this.contract.contract_type_id = this.formUpdate.controls['type'].value;

    this.contract.code = this.formUpdate.controls['code'].value.trim();
    this.contract.customer_id = this.formUpdate.controls['customer'].value;
    this.contract.status = this.formUpdate.controls['status'].value;
    // this.contract.created_by = this.formUpdate.controls[
    //   'createby_customer'
    // ].value;
    this.contract.note = this.formUpdate.controls['note'].value
      ? this.formUpdate.controls['note'].value.trim()
      : '';
    this.contract.client_tax_code = this.formUpdate.controls['tax'].value
      ? this.formUpdate.controls['tax'].value.trim()
      : '';
    (this.contract.client_fax = this.formUpdate.controls['fax'].value
      ? this.formUpdate.controls['fax'].value.trim()
      : ''),
      //this.contract.fax = this.formUpdate.controls['tax'].value.trim();
      (this.contract.client_address = this.formUpdate.controls['address'].value
        ? this.formUpdate.controls['address'].value.trim()
        : '');
    //date
    this.contract.date_signed = new Date(
      this.reverseDate(this.formUpdate.controls['dateSigned'].value.trim())
    );
    this.contract.date_contract_start = new Date(
      this.reverseDate(this.formUpdate.controls['dateStart'].value.trim())
    );
    this.contract.date_contract_end = new Date(
      this.reverseDate(this.formUpdate.controls['dateEnd'].value.trim())
    );
    this.contract.liquidation_date = new Date(
      this.reverseDate(this.formUpdate.controls['liquidationDate'].value.trim())
    );

    //info
    this.contract.client_representator_name = this.formUpdate.controls['name']
      .value
      ? this.formUpdate.controls['name'].value.trim()
      : '';
    this.contract.client_representator_position = this.formUpdate.controls[
      'position'
    ].value
      ? this.formUpdate.controls['position'].value.trim()
      : '';
    this.contract.client_company_name = this.formUpdate.controls['company']
      .value
      ? this.formUpdate.controls['company'].value.trim()
      : '';
    this.contract.client_representator_id_number = this.formUpdate.controls[
      'identity_card'
    ].value
      ? this.formUpdate.controls['identity_card'].value.trim()
      : '';
    this.contract.client_representator_issue_place = this.formUpdate.controls[
      'issued_by'
    ].value
      ? this.formUpdate.controls['issued_by'].value.trim()
      : '';
    this.contract.client_representator_issue_date = this.formUpdate.controls[
      'issued_date'
    ].value
      ? this.formUpdate.controls['issued_date'].value.trim()
      : '';
    this.contract.client_bank_info = this.formUpdate.controls['bank'].value
      ? this.formUpdate.controls['bank'].value.trim()
      : '';

    //Amount
    this.contract.amount_on_contract = this.formUpdate.controls['amount'].value
      ? this.removeSymbol(this.formUpdate.controls['amount'].value)
      : 0;

    this.contract.actual_amount = this.formUpdate.controls['actual_amount']
      .value
      ? this.removeSymbol(this.formUpdate.controls['actual_amount'].value)
      : 0;

    this.contract.discount = this.formUpdate.controls['discount'].value
      ? this.removeSymbol(this.formUpdate.controls['discount'].value)
      : null;
    this.contractService
      .apiContractIdPut(this.idContract, this.contract)
      .subscribe((data) => {
        if (data.success == true) {
          if (this.filesContractUpdate.length > 0) {
            this.filesContractUpdate.forEach((e) => {
              this.contractService
                .apiContractIdAttachmentUploadPut(data.data.id, e)
                .pipe(debounceTime(4000))
                .subscribe((result2) => {
                  if (result2) {
                    this.getAttachmentContract(data.data.id);
                  } else {
                  }
                });
            });
          }
          this.getContractByID();
          this.change = true;
          this.checkUpdate.emit(true);

          this.toastrService.success(data.message);
          this.router.navigate(['/contract/contract-list'], {
            relativeTo: this.route,
          });
        } else {
          this.toastrService.error(data.message);
        }
      });
  }

  realValue() {}

  //Get type contract
  getTypeContract() {
    this.contractService
      .apiContractTypeGet('', '', 1, 100)
      .subscribe((data) => {
        if (data.success == true) {
          this.typeContract = data.data.collection;
        }
      });
  }
  getValueTypeContract(data) {
    if (data) {
      this.formUpdate.controls['type'].setValue(data.id);
    }
    if (this.formUpdate.invalid) {
      this.change = true;
    } else {
      this.change = false;
    }
  }

  // Get customer
  getCustomers() {
    this.userService
      .apiUsersGet(`extend_user_role@='|CUSTOMER|'`, '', 1, 1000)
      .subscribe((res) => {
        if (res.success == true) {
          this.users = res.data.collection;
        }
      });
  }
  getUserCreate() {
    this.userService
      .apiUsersIdGet(this.contract.created_by)
      .subscribe((res) => {
        if (res.success == true) {
          this.users_create = res.data;
        }
      });
  }
  getValueUser(data) {
    if (data) {
      this.formUpdate.controls['customer'].setValue(data.id);
    }
    if (this.formUpdate.invalid) {
      this.change = true;
    } else {
      this.change = false;
    }
  }

  //Get status
  getStatus() {
    this.contractService.apiContractStatusGet().subscribe((res) => {
      if (res.success == true) {
        this.statusContract = res.data.collection;
      }
    });
  }
  getValueStatusContract(data) {
    if (data) {
      this.formUpdate.controls['status'].setValue(data.id);
    }
    if (this.formUpdate.invalid) {
      this.change = true;
    } else {
      this.change = false;
    }
  }

  //Cacnel
  cancel() {
    this.change = true;
    this.getContractByID();
  }

  //Get attacment

  getAttachmentContract(id) {
    this.contractService.apiContractIdAttachmentGet(id).subscribe((res) => {
      if (res.success == true) {
        this.filesContract = res.data.collection;
        this.filesContractUpdate = this.filesContract;

        if (this.filesContract.length > 0) {
          this.isDownload = true;
        } else {
          this.isDownload = false;
        }
      } else {
        this.toastrService.error(res.message);
      }
    });
  }

  //Select File
  onSelect(event) {
    this.filesContractUpdate.push(...event.addedFiles);

    this.change = false;
  }

  //Delete File
  onRemove(event) {
    if (event.name) {
      //New File
      this.filesContractUpdate.splice(
        this.filesContractUpdate.indexOf(event),
        1
      );
    } else {
      // Current File
      let title = 'Xóa tài liệu';
      let content = 'Bạn có chắc chắn muốn xóa tài liệu này ?';
      this.confirmService.deleteConfirm(title, content, true);
      this.confirmService.open$
        .pipe(
          filter((event) => !!event),
          filter((event) => {
            return event.type === 'delete' || event.type === 'close';
          }),
          take(1)
        )
        .subscribe((events) => {
          if (events.type == 'delete') {
            this.contractService
              .apiContractIdAttachmentAttachmentIdDelete(
                this.idContract,
                event.id
              )
              .subscribe((res) => {
                if (res.success == true) {
                  this.filesContractUpdate.splice(
                    this.filesContractUpdate.indexOf(event),
                    1
                  );
                  if (this.filesContract.length == 0) {
                    this.isDownload = false;
                  } else {
                    this.isDownload = true;
                  }
                  this.toastrService.success(res.message);
                } else {
                  //this.getProductById(this.current_folder_id);
                  this.toastrService.error(res.message);
                }
              });
            this.confirmService.close();
          } else {
            this.confirmService.close();
          }
        });
    }
  }
  //Download File

  onDownloadImage(idAttachment, nameAttachment) {
    var blobImage: Blob;
    this.contractService
      .apiContractIdAttachmentAttachmentIdDownloadGet(
        this.idContract,
        idAttachment
      )
      .subscribe((data) => {
        blobImage = new Blob([data], { type: 'application/octet-stream' });

        saveAs(blobImage, nameAttachment);
      });
  }

  //Export contract report
  exportContract() {
    var blobExportReport: Blob;
    this.contractService
      .apiContractTemplateTemplateIdExportGet(
        this.contractTemplateID,
        this.idContract
      )
      .subscribe((res) => {
        if (res.type === 'application/json') {
          res.text().then((text) => {
            let resToJson = JSON.parse(text.toString());
            this.toastrService.error(resToJson.message);
          });
        } else {
          blobExportReport = new Blob([res], {
            type: res.type,
          });
          saveAs(blobExportReport, 'Hop-dong.docx');
        }
      });
  }

  //Check date is change
  detectChange(data) {
    if (
      data.date ==
      moment(this.contract.date_contract_start).format('DD/MM/YYYY')
    ) {
      this.change = true;
    } else {
      if (data.granularity == 'day') {
        this.change = false;
      }
    }
  }
  detectChangeEnd(data) {
    if (
      data.date == moment(this.contract.date_contract_end).format('DD/MM/YYYY')
    ) {
      this.change = true;
    } else {
      if (data.granularity == 'day') {
        this.change = false;
      }
    }
  }
  detectChangeliquidationDate(data) {
    if (
      data.date == moment(this.contract.liquidation_date).format('DD/MM/YYYY')
    ) {
      this.change = true;
    } else {
      if (data.granularity == 'day') {
        this.change = false;
      }
    }
  }
  detectChangeSigned(data) {
    if (data.date == moment(this.contract.date_signed).format('DD/MM/YYYY')) {
      this.change = true;
    } else {
      if (data.granularity == 'day') {
        this.change = false;
      }
    }
  }

  //Validate
  changeCharater(str) {
    if (str) {
      str = str.replace(/ /g, ' ').toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }

  updateAmount(value) {}

  reverseDate(date) {
    let day = date.slice(0, 2);
    let month = date.slice(3, 5);
    let year = date.slice(6, 10);
    let res = year + '/' + month + '/' + day;
    return res;
  }

  onChangeNumber(value, name) {
    let result = this.meuCurrencyPipe.transform(value);
    this.formUpdate.controls[name].setValue(result);
  }

  changeDiscount(data) {
    let result =
      parseInt(
        this.formUpdate.controls['amount'].value.toString().replace(/,/g, '')
      ) - parseInt(data.toString().replace(/,/g, ''));
    this.formUpdate.controls['actual_amount'].setValue(result);
  }

  removeSymbol(value) {
    return parseInt(value.toString().replace(/,/g, ''));
  }
}
