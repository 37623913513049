import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderManagementService {
  inventoryID = new BehaviorSubject<string>(null);

  constructor() {}
}
