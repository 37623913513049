<div class="grid grid-cols-12 mt-8px">
  <div
    class="col-span-12 flex flex-col md:flex-row md:justify-between md:items-center"
  >
    <div>
      <div
        class="pt-10px pb-14px font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Danh sách hàng hóa
      </div>
    </div>
    <div>
      <div class="flex flex-wrap items-center gap-8px">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchIExport"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border mb-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
        <div class="flex gap-8px">
          <button
            class="btn-primary-icon mt-10px mb-14px flex items-center gap-4px"
            (click)="addImport()"
          >
            <img src="assets/icons/handle-icons/fi_download.svg" />
            <span>Nhập hàng</span>
          </button>
          <button
            (click)="addExport()"
            class="btn-primary-export mt-10px mb-14px flex items-center gap-4px"
          >
            <img src="assets/icons/handle-icons/u_file-export.svg" />
            <span>Xuất hàng</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-12 overflow-x-auto w-full">
    <table
      cellpadding="0"
      cellspacing="0"
      class="w-full border-separate table-fixed"
      *ngIf="totalArray > 0; else noInfo"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="md:text-center w-40px">#</th>
          <!-- mã phiếu nhập -->
          <th
            class="text-left col-code cursor-pointer"
            [tooltip]="'Mã phiếu nhập / xuất'"
            (click)="Sort('imExportCode')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Mã phiếu nhập / xuất'"
                >Mã phiếu nhập / xuất</span
              >

              <img
                *ngIf="sort.includes('imExportCode'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- ten hang hoa -->
          <th
            class="text-left col-name items-center overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Tên hàng hóa'"
          >
            Tên hàng hóa
          </th>
          <!-- ten kho -->
          <th
            class="text-left col-nameInventory cursor-pointer"
            (click)="Sort('extend_inventory_name')"
            [tooltip]="'Tên kho'"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Mã phiếu nhập / xuất'"
                >Tên kho</span
              >

              <img
                *ngIf="
                  sort.includes('extend_inventory_name');
                  else normalSearch
                "
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>

          <!-- ngay nhap -->
          <th
            class="text-left col-date-issue overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Ngày nhập / xuất'"
          >
            Ngày nhập / xuất
          </th>
          <!--  nhap -->
          <th
            class="text-left col-quantity-im overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Nhập'"
          >
            Nhập
          </th>
          <!-- xuat -->
          <th
            class="text-left col-quantity-ex overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Xuất'"
          >
            Xuất
          </th>
          <!-- don vi tinh -->
          <th
            class="text-left col-unit cursor-pointer"
            (click)="Sort('unit_name')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'ĐVT'"
                >ĐVT</span
              >

              <img
                *ngIf="sort.includes('unit_name'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- kiem kho -->
          <th
            class="text-center col-check overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Đã kiểm kho'"
          >
            Đã kiểm kho
          </th>
          <!-- statuc -->
          <th
            class="text-center col-status overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Trạng thái'"
          >
            Trạng thái
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="font-roboto-regular h-40px"
          *ngFor="let ie of IExports; let i = index"
        >
          <td
            class="text-center td-stt cursor-pointer text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="#"
            [tooltip]="indexArray + i + 1"
          >
            {{ indexArray + i + 1 }}
          </td>
          <!-- mã phiếu nhập -->
          <td
            (click)="open(ie.imExportCode)"
            class="td-code text-left cursor-pointer text-primary-dodger-blue overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Mã phiếu nhập / xuất"
          >
            <span
              [tooltip]="ie.imExportCode ? ie.imExportCode : '--'"
              class="cursor-pointer"
            >
              {{ ie.imExportCode ? ie.imExportCode : '--' }}</span
            >
          </td>
          <!-- ten hang hoa -->
          <td
            class="td-name text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Tên hàng hóa"
          >
            <span
              [tooltip]="ie.product_name ? ie.product_name : '--'"
              class="cursor-pointer"
            >
              {{ ie.product_name ? ie.product_name : '--' }}</span
            >
          </td>
          <!-- ten kho -->
          <td
            class="td-nameInventory text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Tên kho"
          >
            <span
              [tooltip]="
                ie['extend_inventory_name'] ? ie['extend_inventory_name'] : '--'
              "
              class="cursor-pointer"
            >
              {{
                ie['extend_inventory_name'] ? ie['extend_inventory_name'] : '--'
              }}</span
            >
          </td>

          <!-- ngay nhap -->
          <td
            *ngIf="ie.receipt_date; else issuedate"
            class="td-isdate text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Ngày nhập / xuất"
          >
            <span
              [tooltip]="
                ie.receipt_date ? (ie.receipt_date | date: 'dd/MM/yyyy') : '--'
              "
              class="cursor-pointer"
            >
              {{
                ie.receipt_date ? (ie.receipt_date | date: 'dd/MM/yyyy') : '--'
              }}</span
            >
          </td>
          <ng-template #issuedate>
            <td
              class="td-isdate text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Ngày nhập / xuất"
            >
              <span
                [tooltip]="
                  ie.issue_date ? (ie.issue_date | date: 'dd/MM/yyyy') : '--'
                "
                class="cursor-pointer"
              >
                {{
                  ie.issue_date ? (ie.issue_date | date: 'dd/MM/yyyy') : '--'
                }}</span
              >
            </td>
          </ng-template>
          <!--  nhap -->
          <td
            *ngIf="ie.imExportCode.includes('PN'); else noPN"
            class="td-stockI text-right text-dark-grey cols cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Nhập"
          >
            <span
              [tooltip]="ie.quantity ? (ie.quantity | number) : '--'"
              class="cursor-pointer"
            >
              {{ ie.quantity ? (ie.quantity | number) : '--' }}</span
            >
          </td>
          <ng-template #noPN>
            <td
              class="td-stockI text-right text-dark-grey cols cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Nhập"
            >
              <span tooltip="--">--</span>
            </td>
          </ng-template>
          <!-- xuat -->
          <td
            *ngIf="ie.imExportCode.includes('PX'); else noPX"
            class="td-stockE text-right text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Xuất"
          >
            <span
              [tooltip]="ie.quantity ? (ie.quantity | number) : '--'"
              class="cursor-pointer"
            >
              {{ ie.quantity ? (ie.quantity | number) : '--' }}</span
            >
          </td>
          <ng-template #noPX>
            <td
              class="td-stockE text-right text-dark-grey cols cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Xuất"
            >
              <span tooltip="--">--</span>
            </td>
          </ng-template>
          <!-- don vi tinh -->
          <td
            class="td-unit text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Đơn vị tính"
          >
            <span
              [tooltip]="ie.unit_name ? ie.unit_name : '--'"
              class="cursor-pointer"
            >
              {{ ie.unit_name ? ie.unit_name : '--' }}</span
            >
          </td>
          <!-- kiem kho -->
          <td
            class="td-check r text-center text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Đã kiểm kho"
          >
            <div class="flex md:justify-center justify-end">
              <input
                type="checkbox"
                [checked]="ie.is_checked == true"
                onclick="return false"
                class="w-16px h-16px"
              />
            </div>
          </td>
          <!-- status -->
          <td
            class="td-status text-center text-dark-grey"
            data-label="Trạng thái"
          >
            <div class="text-center lg:px-20px">
              <div
                [ngClass]="
                  ie.isImExported == true
                    ? 'bg-duck-egg-blue rounded-4px py-4px px-8px text-greenblue'
                    : 'bg-light-peach rounded-4px py-4px px-8px text-orange-red'
                "
                [tooltip]="ie.isImExported == true ? 'Đã nhập' : 'Đã xuất'"
              >
                {{ ie.isImExported == true ? 'Đã nhập' : 'Đã xuất' }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #noInfo>
      <table
        cellpadding="0"
        cellspacing="0"
        class="w-full border-separate table-fixed"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="md:text-center w-40px">#</th>
            <!-- mã phiếu nhập -->
            <th
              class="text-left col-code cursor-pointer"
              [tooltip]="'Mã phiếu nhập / xuất'"
              (click)="Sort('imExportCode')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Mã phiếu nhập / xuất'"
                  >Mã phiếu nhập / xuất</span
                >

                <img
                  *ngIf="sort.includes('imExportCode'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- ten hang hoa -->
            <th
              class="text-left col-name items-center overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Tên hàng hóa'"
            >
              Tên hàng hóa
            </th>
            <!-- ten kho -->
            <th
              class="text-left col-nameInventory cursor-pointer"
              (click)="Sort('extend_inventory_name')"
              [tooltip]="'Tên kho'"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Mã phiếu nhập / xuất'"
                  >Tên kho</span
                >

                <img
                  *ngIf="
                    sort.includes('extend_inventory_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>

            <!-- ngay nhap -->
            <th
              class="text-left col-date-issue overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Ngày nhập / xuất'"
            >
              Ngày nhập / xuất
            </th>
            <!--  nhap -->
            <th
              class="text-left col-quantity-im overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Nhập'"
            >
              Nhập
            </th>
            <!-- xuat -->
            <th
              class="text-left col-quantity-ex overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Xuất'"
            >
              Xuất
            </th>
            <!-- don vi tinh -->
            <th
              class="text-left col-unit cursor-pointer"
              (click)="Sort('unit_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'ĐVT'"
                  >ĐVT</span
                >

                <img
                  *ngIf="sort.includes('unit_name'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- kiem kho -->
            <th
              class="text-center col-check overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Đã kiểm kho'"
            >
              Đã kiểm kho
            </th>
            <!-- statuc -->
            <th
              class="text-center col-status overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Trạng thái'"
            >
              Trạng thái
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="font-roboto-regular text-14px h-40px">
            <td colspan="10" class="td-noinfo">Không có dữ liệu</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
  <div class="col-span-12">
    <meu-pagination-component
      *ngIf="totalArray != 0"
      [total]="totalArray"
      [array]="array"
      [changePage]="onPagingFunc"
    ></meu-pagination-component>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [imExport_code]="imExport_code"
    [idProduct]="idProduct"
    [isTransferCar]="false"
    (closeModalImport)="closeModal($event)"
    (changeTitleModal)="changeTitle($event)"
  ></meu-import-history>
</meu-modal-component>

<meu-modal-component
  [isOpen]="isOpenModalExport"
  [options]="optionsExport"
  [title]="titleModalExport"
  *ngIf="isOpenModalExport"
>
  <meu-export-history
    [imExport_code]="im_Export_code"
    [idProduct]="idProduct"
    [isTransferCar]="false"
    (closeModalExport)="closeModalExport($event)"
    (changeTitleModal)="changeTitleExport($event)"
  ></meu-export-history>
</meu-modal-component>
