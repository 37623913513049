import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContractService } from 'src/app/api/contract.service';
import { ToastrService } from 'src/app/core/services';
import { ContractType } from 'src/app/model/contractType';

@Component({
  selector: 'meu-contract-type-modal',
  templateUrl: './contract-type-modal.component.html',
  styleUrls: ['./contract-type-modal.component.scss'],
})
export class ContractTypeModalComponent implements OnInit {
  formcreate = new FormGroup({
    name: new FormControl('', Validators.required),
    des: new FormControl(''),
  });
  dataContractType: ContractType = {};

  nameButton: string;

  @Output() closeModalCreate = new EventEmitter();
  @Input() id: string;

  constructor(
    private contractService: ContractService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.id) {
      this.nameButton = 'Lưu';
      this.getContractTypeID(this.id);
    } else {
      this.nameButton = 'Thêm';
    }
  }

  closeModal(event = false) {
    this.closeModalCreate.emit({ success: event });
  }

  getContractTypeID(id) {
    this.contractService.apiContractTypeIdGet(id).subscribe((res) => {
      if (res.success) {
        this.formcreate.patchValue({
          name: res.data.name,
          des: res.data.description,
        });
      }
    });
  }

  submit() {
    this.dataContractType.name = this.formcreate.controls['name'].value
      ? this.formcreate.controls['name'].value.trim()
      : '';
    this.dataContractType.description = this.formcreate.controls['des'].value
      ? this.formcreate.controls['des'].value.trim()
      : '';

    if (this.id) {
      this.contractService
        .apiContractTypeIdPut(this.id, this.dataContractType)
        .subscribe((res) => {
          if (res.success) {
            this.toastrService.success(res.message);
            this.closeModal(true);
          } else {
            this.toastrService.error(res.message);
          }
        });
    }
    else {
      this.contractService
        .apiContractTypePost(this.dataContractType)
        .subscribe((res) => {
          if (res.success) {
            this.toastrService.success(res.message);
            this.closeModal(true);
          } else {
            this.toastrService.error(res.message);
          }
        });
    }
  }
}
