<div class="grid grid-cols-12">
  <div
    class="col-span-12 flex flex-col md:flex-row md:justify-between md:items-center mt-8px"
  >
    <div
      class="py-12px font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
    >
      Quản lý hợp đồng
    </div>
    <div class="flex flex-wrap md:float-right items-center gap-8px">
      <div class="relative">
        <input
          [(ngModel)]="searchContract"
          (keyup)="onKeyUp()"
          (keydown)="onKeyDown()"
          type="text"
          placeholder="Nhập từ khóa tìm kiếm"
          class="m-auto h-32px w-320px border mb-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
        />
        <img
          class="absolute top-0 mt-9px ml-12px"
          src="assets/icons/handle-icons/Icons_search.svg"
        />
      </div>

      <button
        class="btn-primary-icon mt-10px mb-14px flex flex-row items-center gap-4px"
        (click)="open(null)"
      >
        <img src="assets/icons/handle-icons/icon_add.svg" />
        <span>Thêm mới</span>
      </button>
    </div>
  </div>

  <div class="col-span-12 overflow-x-auto" style="overflow-y: hidden">
    <table
      *ngIf="totalArray > 0; else noInfo"
      class="w-full border-separate table-fixed"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
            #
          </th>

          <!-- code -->
          <th
            class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
          >
            <div
              class="flex justify-between items-center"
              (click)="Sort('code')"
            >
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Mã hợp đồng"
              >
                Mã Hợp đồng</span
              ><img
                *ngIf="sort.includes('code'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- name -->
          <th
            class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
            (click)="Sort('extend_contract_type')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Loại hợp đồng"
                >Loại hợp đồng</span
              >
              <img
                *ngIf="sort.includes('extend_contract_type'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- date start -->
          <th
            class="text-left overflow-hidden overflow-ellipsis whitespace-nowrap col-dateS hidden-col cursor-pointer pt-12px px-16px pb-8px"
            tooltip="Ngày bắt đầu"
          >
            Ngày bắt đầu
          </th>
          <!-- date end -->
          <th
            class="text-left col-dateE pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
            tooltip="Ngày kết thúc"
          >
            Ngày kết thúc
          </th>
          <!-- value -->
          <th
            class="text-left col-value cursor-pointer pt-12px px-16px pb-8px"
            (click)="Sort('amount_on_contract')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                tooltip="Giá trị hợp đồng"
                >Giá trị hợp đồng</span
              >

              <img
                *ngIf="sort.includes('amount_on_contract'); else normalSearch"
                class="float-right"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img
                  class="float-right"
                  src="assets/icons/sort/sort_normal.svg"
                />
              </ng-template>
            </div>
          </th>
          <!-- date create -->
          <th
            class="text-left col-dateC cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
            tooltip="Ngày tạo"
          >
            Ngày tạo
          </th>
          <!-- status -->
          <th
            class="text-center col-status cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
            tooltip="Tình trạng"
          >
            Tình trạng
          </th>
          <th
            class="text-center col-thaotac pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            Thao tác
          </th>
        </tr>
      </thead>
      <!-- DATA TABLE -->

      <tbody>
        <tr
          style="min-height: 40px !important"
          *ngFor="let contract of contracts; let i = index"
        >
          <td
            class="td-stt py-10px px-5px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="#"
            [tooltip]="indexArray + i + 1"
          >
            {{ indexArray + i + 1 }}
          </td>

          <td
            class="td-code py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Mã hợp đồng "
          >
            <span [tooltip]="contract.code ? contract.code : '--'">
              {{ contract.code ? contract.code : '--' }}</span
            >
          </td>
          <td
            class="td-name py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Loại hợp đồng"
          >
            <span
              [tooltip]="
                contract['extend_contract_type']
                  ? contract['extend_contract_type']
                  : '--'
              "
            >
              {{
                contract['extend_contract_type']
                  ? contract['extend_contract_type']
                  : '--'
              }}</span
            >
          </td>
          <td
            class="td-dateS py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Ngày bắt đầu"
          >
            <span
              [tooltip]="
                contract.date_contract_start
                  ? (contract.date_contract_start | date: 'dd/MM/yyyy')
                  : '--'
              "
            >
              {{
                contract.date_contract_start
                  ? (contract.date_contract_start | date: 'dd/MM/yyyy')
                  : '--'
              }}</span
            >
          </td>
          <td
            data-label="Ngày kết thúc"
            class="cursor-pointer py-10px px-16px td-dateE overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            <span
              [tooltip]="
                contract.date_contract_end
                  ? (contract.date_contract_end | date: 'dd/MM/yyyy')
                  : '--'
              "
            >
              {{
                contract.date_contract_end
                  ? (contract.date_contract_end | date: 'dd/MM/yyyy')
                  : '--'
              }}</span
            >
          </td>

          <td
            class="td-value text-right py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Gái trị hợp đồng"
          >
            <span
              [tooltip]="
                contract.amount_on_contract
                  ? (contract.amount_on_contract | number)
                  : '--'
              "
            >
              {{
                contract.amount_on_contract
                  ? (contract.amount_on_contract | number)
                  : '--'
              }}</span
            >
          </td>
          <td
            class="td-dateC py-10px px-16px overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Ngày tạo"
          >
            <span
              [tooltip]="
                contract.created_at
                  ? (contract.created_at | date: 'dd/MM/yyyy')
                  : '--'
              "
            >
              {{
                contract.created_at
                  ? (contract.created_at | date: 'dd/MM/yyyy')
                  : '--'
              }}</span
            >
          </td>
          <td class="td-status py-8px px-20px" data-label="Tình trạng">
            <div
              [tooltip]="
                contract['extend_contract_status']
                  ? contract['extend_contract_status']
                  : '--'
              "
              class="text-center text-12px rounded-4px py-4px px-8px status-style"
              [ngClass]="
                contract['extend_contract_status'] == 'Chưa hoạt động'
                  ? 'bg-grayscale-light-grey text-grey-med'
                  : contract['extend_contract_status'] == 'Đang hoạt động'
                  ? 'bg-duck-egg-blue text-greenblue'
                  : contract['extend_contract_status'] == 'Đã thanh lý'
                  ? 'bg-primary-lightest text-light-blue'
                  : contract['extend_contract_status'] == 'Đã hủy'
                  ? 'bg-light-pink text-watermelon'
                  : ''
              "
            >
              <span>{{ contract['extend_contract_status'] }}</span>
            </div>
          </td>
          <td class="td-thaotac px-5px lg:px-16px" data-label="Thao tác">
            <div class="flex flex-row-reverse justify-center m-j-end">
              <button
                tooltip="Xóa"
                class="hover:bg-primary-lightest rounded-full focus:outline-none button-add-edit flex items-center justify-center"
                (click)="deleteconfirm(contract.id)"
              >
                <img src="assets/icons/handle-icons/u_trash-alt.svg" />
              </button>

              <button
                tooltip="Chỉnh sửa"
                class="hover:bg-primary-lightest focus:outline-none flex items-center justify-center button-add-edit rounded-full"
                [routerLink]="['/contract/detail', contract.id]"
              >
                <img src="assets/icons/handle-icons/u_edit-alt.svg" />
              </button>
              <button
                class="hover:bg-primary-lightest focus:outline-none flex items-center justify-center button-add-edit rounded-full"
                [tooltip]="'Sao chép hợp đồng'"
                (click)="open(contract.id)"
              >
                <img src="../../../../assets/icons/contract-icons/u_copy.svg" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #noInfo>
      <table
        class="w-full border-separate table-fixed"
        cellpadding="0"
        cellspacing="0"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="text-center hidden-col w-40px pt-12px px-16px pb-8px">
              #
            </th>

            <!-- code -->
            <th
              class="text-left col-code items-center cursor-pointer pt-12px px-16px pb-8px"
            >
              <div
                class="flex justify-between items-center"
                (click)="Sort('code')"
              >
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Mã hợp đồng"
                >
                  Mã Hợp đồng</span
                ><img
                  *ngIf="sort.includes('code'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- name -->
            <th
              class="text-left col-name items-center cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('extend_contract_type')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Loại hợp đồng"
                  >Loại hợp đồng</span
                >
                <img
                  *ngIf="
                    sort.includes('extend_contract_type');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- date start -->
            <th
              class="text-left overflow-hidden overflow-ellipsis whitespace-nowrap col-dateS hidden-col cursor-pointer pt-12px px-16px pb-8px"
              tooltip="Ngày bắt đầu"
            >
              Ngày bắt đầu
            </th>
            <!-- date end -->
            <th
              class="text-left col-dateE pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Ngày kết thúc"
            >
              Ngày kết thúc
            </th>
            <!-- value -->
            <th
              class="text-left col-value cursor-pointer pt-12px px-16px pb-8px"
              (click)="Sort('amount_on_contract')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  tooltip="Giá trị hợp đồng"
                  >Giá trị hợp đồng</span
                >

                <img
                  *ngIf="sort.includes('amount_on_contract'); else normalSearch"
                  class="float-right"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img
                    class="float-right"
                    src="assets/icons/sort/sort_normal.svg"
                  />
                </ng-template>
              </div>
            </th>
            <!-- date create -->
            <th
              class="text-left col-dateC cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Ngày tạo"
            >
              Ngày tạo
            </th>
            <!-- status -->
            <th
              class="text-center col-status cursor-pointer pt-12px px-16px pb-8px overflow-hidden overflow-ellipsis whitespace-nowrap"
              tooltip="Tình trạng"
            >
              Tình trạng
            </th>
            <th class="text-center w-155px pt-12px px-16px pb-8px">Thao tác</th>
          </tr>
        </thead>
        <!-- DATA TABLE -->

        <tbody>
          <tr class="font-roboto-regular text-14px h-40px">
            <td colspan="9" class="td-noinfo">
              Không có thông tin về hợp đồng
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
  <div class="col-span-12">
    <meu-pagination-component
      *ngIf="totalArray != 0"
      [total]="totalArray"
      [array]="array"
      [changePage]="onPagingFunc"
    ></meu-pagination-component>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  *ngIf="isOpenModal"
  [title]="titleModal"
>
  <meu-contract-management-create
    [contract_id]="idContract"
    [customerID]="idCustomer"
    (closeModalCreate)="closeModal($event)"
  ></meu-contract-management-create>
</meu-modal-component>

<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheet"
  [options]="optionsBottom"
  [title]="titleModal"
  *ngIf="isOpenBottomsheet"
>
  <meu-contract-management-create
    [contract_id]="idContract"
    [customerID]="idCustomer"
    (closeModalCreate)="closeModal($event)"
  ></meu-contract-management-create>
</meu-modal-popup-bottom>
