<div>
    <div class="col-span-full row-span-full flex items-stretch md:items-center">
        <img 
                class="w-20px h-20px mr-4px arrow-right"
                alt="" 
                src="../../../../assets/icons/product-categories-icons/u_angle-down.svg"
        >
        <button 
            class="flex items-stretch md:items-center w-270px h-40px rounded-4px parent-tree pl-5px"
            (click)="go(null)"
        >
            <img class="fol-icon" alt="" src="../../../../assets/icons/product-categories-icons/icon_file_folder.svg">
            <span 
                class="text-14px font-roboto-regular fol-text mt-3px"
                (click)="selected_node_id = null"
                >Tất cả phân loại</span> 
            
        </button>
        <button
                class="w-auto h-auto mr-9px outline-none btn-uplus focus:outline-none hover:bg-transparent "
                (click)="addNewFolder()"
                type="button"
            >
                <img alt="" src="../../../../assets/icons/product-categories-icons/u_plus.svg">
        </button>   
    </div>
    
    <!-- Tree-View -->
    <tree-root
    #tree
    [nodes]="nodes"
    [options]="options"
    class="expand-tree mt-2px"
    >
    <!-- (toggleExpanded)="onEvent($event)"
    (activate)="onEvent($event)"
    (focus)="onEvent($event)"
    (blur)="onEvent($event)"> -->
        <ng-template #treeNodeTemplate let-node let-index="index">
            <button 
                class="flex items-stretch md:items-center focus:outline-none h-35px rounded-4px w-190px"
                (click)="go(node.data)"
                type="button"
                
            >
                <div class="flex flex-row">
                    <img alt="" class="fol-icon" src="../../../../assets/icons/product-categories-icons/icon_file_folder.svg">
                    <span tooltip={{node.data.name}} class="text-14px text-left font-roboto-regular fol-text max-w-220px truncate">{{ node.data.name }}</span>
                </div>

                <!-- dropdown -->
                <div class="absolute dropdown dropdownbutton">
                    <a
                    type="button"
                    href="javascript:void(0)"
                    class="flex text-sm rounded-md focus:outline-none items-center "
                    aria-expanded="false"
                    aria-haspopup="true"
                    aria-controls="layout-user-action"
                    >
                        <img
                            id="dropdown-btn"
                            class="cursor-pointer"
                            src="../../../../assets/icons/product-categories-icons/u_ellipsis-v.svg"
                            alt=""
                            height="20px"
                        />
                    </a>
                    <div
                        class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
                    >
                        <div
                            class="z-10 absolute right-0 w-200px mt-5 origin-top-right bg-white text-black-gray rounded-md shadow outline-none"
                            aria-labelledby="headlessui-menu-button-2"
                            id="layout-user-action"
                            role="menu"
                        >
                            <div class="py-2">
                            <div
                                class="item-add text-black-gray cursor-pointer h-40px hover:bg-tlr-lightest-blue flex flex-col justify-center text-center w-full px-3"
                                (click)="addNewFolder()"
                            >
                                <div class="flex">
                                    <img src="../../../../assets/icons/product-categories-icons/u_plus.svg" alt="" class="mr-3" />
                                    Thêm phân loại
                                </div>
                            </div>

                            <div
                                class="item-rename text-black-gray cursor-pointer h-40px hover:bg-tlr-lightest-blue flex flex-col justify-center text-center w-full px-3"
                                (click)="renameFolder(node.data.id)"
                            >
                                <div class="flex">
                                    <img src="../../../../assets/icons/product-categories-icons/u_edit-alt.svg" alt="" class="mr-3" />
                                    Đổi tên phân loại
                                </div>
                            </div>

                            <div
                                class="item-delete text-black-gray cursor-pointer h-40px hover:bg-tlr-lightest-blue flex flex-col justify-center text-center w-full px-3"
                                (click)="deleteconfirm(node.data.id)"
                            >
                                <div class="flex">
                                    <img src="../../../../assets/icons/product-categories-icons/u_trash-alt.svg" alt="" class="mr-3" />
                                    Xóa phân loại
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        </ng-template>
    </tree-root>
</div>