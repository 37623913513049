/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ContainerSellProductPayment } from '../model/containerSellProductPayment';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ContainerSellProductPaymentService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentByCodeCodeGet(
    code: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentByCodeCodeGet(
    code: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentByCodeCodeGet(
    code: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentByCodeCodeGet(
    code: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling apiContainerSellProductPaymentByCodeCodeGet.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${
        this.basePath
      }/api/containerSellProductPaymentByCode/${encodeURIComponent(
        String(code)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param container_id
   * @param filter
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param fromDate
   * @param toDate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentByContainerIdContainerIdGet(
    container_id: string,
    filter?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    fromDate?: Date,
    toDate?: Date,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentByContainerIdContainerIdGet(
    container_id: string,
    filter?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    fromDate?: Date,
    toDate?: Date,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentByContainerIdContainerIdGet(
    container_id: string,
    filter?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    fromDate?: Date,
    toDate?: Date,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentByContainerIdContainerIdGet(
    container_id: string,
    filter?: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    fromDate?: Date,
    toDate?: Date,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (container_id === null || container_id === undefined) {
      throw new Error(
        'Required parameter container_id was null or undefined when calling apiContainerSellProductPaymentByContainerIdContainerIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (filter !== undefined && filter !== null) {
      queryParameters = queryParameters.set('filter', <any>filter);
    }
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }
    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = queryParameters.set(
        'fromDate',
        <any>fromDate.toISOString()
      );
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = queryParameters.set(
        'toDate',
        <any>toDate.toISOString()
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${
        this.basePath
      }/api/containerSellProductPaymentByContainerId/${encodeURIComponent(
        String(container_id)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param user_id
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentByCustomerIdUserIdGet(
    user_id: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentByCustomerIdUserIdGet(
    user_id: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentByCustomerIdUserIdGet(
    user_id: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentByCustomerIdUserIdGet(
    user_id: string,
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (user_id === null || user_id === undefined) {
      throw new Error(
        'Required parameter user_id was null or undefined when calling apiContainerSellProductPaymentByCustomerIdUserIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${
        this.basePath
      }/api/containerSellProductPaymentByCustomerId/${encodeURIComponent(
        String(user_id)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param Filters
   * @param Sorts
   * @param Page
   * @param PageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentGet(
    Filters?: string,
    Sorts?: string,
    Page?: number,
    PageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (Filters !== undefined && Filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>Filters);
    }
    if (Sorts !== undefined && Sorts !== null) {
      queryParameters = queryParameters.set('Sorts', <any>Sorts);
    }
    if (Page !== undefined && Page !== null) {
      queryParameters = queryParameters.set('Page', <any>Page);
    }
    if (PageSize !== undefined && PageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>PageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/containerSellProductPayment`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentIdDelete(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentIdDelete(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentIdDelete(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentIdDelete(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiContainerSellProductPaymentIdDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/containerSellProductPayment/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentIdGet(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentIdGet(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentIdGet(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentIdGet(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiContainerSellProductPaymentIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/api/containerSellProductPayment/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentIdPut(
    id: string,
    body?: ContainerSellProductPayment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentIdPut(
    id: string,
    body?: ContainerSellProductPayment,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentIdPut(
    id: string,
    body?: ContainerSellProductPayment,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentIdPut(
    id: string,
    body?: ContainerSellProductPayment,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiContainerSellProductPaymentIdPut.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/containerSellProductPayment/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentPost(
    body?: ContainerSellProductPayment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentPost(
    body?: ContainerSellProductPayment,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentPost(
    body?: ContainerSellProductPayment,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentPost(
    body?: ContainerSellProductPayment,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json-patch+json',
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/api/containerSellProductPayment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiContainerSellProductPaymentSavingCodePut(
    code: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiContainerSellProductPaymentSavingCodePut(
    code: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiContainerSellProductPaymentSavingCodePut(
    code: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiContainerSellProductPaymentSavingCodePut(
    code: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling apiContainerSellProductPaymentSavingCodePut.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${
        this.basePath
      }/api/ContainerSellProductPaymentSaving/${encodeURIComponent(
        String(code)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
