<div id="container"></div>

<meu-modal-component
  [title]="'Chi tiết ô'"
  [isOpen]="openModal"
  [options]="options"
>
  <meu-app-inventory-container-modal
    [dataFromContainer]="dataFromContainer"
    [isUpdatePercent]="isUpdatePercent"
    [isAdmin]="isAdmin"
    (closeModal)="closeModal()"
    (modalEvent)="modalEventReturn($event)"
    (openModalImport)="openModalImport.emit($event)"
    (openModalExport)="openModalExport.emit($event)"
    (openModalChangePosition)="openModalChangePosition.emit($event)"
  ></meu-app-inventory-container-modal>
</meu-modal-component>
<input type="file" (change)="updateImage($event)" class="hidden" #inputImage />

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [imExport_code]="imExport_code"
    [dataFromContainer]="dataFromContainer"
    (closeModalImport)="closeModalImport($event)"    
  ></meu-import-history>
</meu-modal-component>
