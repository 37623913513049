<div class="grid grid-cols-12 px-16px">
  <div class="col-span-12 mt-16px">
    <form [formGroup]="formcreate">
      <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-4">
        <div class="sm:col-span-6">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              (formcreate.get('codeSKU').touched &&
                formcreate.controls.codeSKU.errors?.required) ||
              formcreate.controls.codeSKU.errors?.maxlength
                ? 'error-class'
                : ''
            "
          >
            Quy cách <span class="text-red-light">* </span></span
          >
          <span class="font-roboto-regular text-12px text-grey-label">
            ( số ký trong 1 bao )</span
          >

          <input
            [ngClass]="
              (formcreate.get('codeSKU').touched &&
                formcreate.controls.codeSKU.errors?.required) ||
              formcreate.controls.codeSKU.errors?.maxlength
                ? 'class-invalid-name'
                : 'class-valid'
            "
            type="number"
            placeholder="Nhập quy cách"
            formControlName="codeSKU"
            min="1"
            required
            class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-medium font-roboto-regular text-14px text-dark-grey not-italic"
          />

          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              (formcreate.get('codeSKU').touched &&
                formcreate.controls.codeSKU.errors?.required) ||
              formcreate.controls.codeSKU.errors?.maxlength
            "
          >
            {{
              formcreate.controls.codeSKU.errors?.required
                ? 'Vui lòng nhập quy cách'
                : 'Quy cách tối đa 30 ký tự'
            }}
          </span>
        </div>

        <div class="sm:col-span-6">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formcreate.get('inventoryName').touched &&
              formcreate.controls.inventoryName.errors?.required
                ? 'error-class'
                : ''
            "
            >Kho hàng <span class="text-red-light">*</span>
          </span>

          <ng-select
            [ngClass]="
              formcreate.get('inventoryName').touched &&
              formcreate.controls.inventoryName.errors?.required
                ? 'select-invalid'
                : 'select-name'
            "
            (change)="getInven($event)"
            placeholder="Chọn kho hàng"
            bindLabel="name"
            bindValue="id"
            [items]="inventoryChoose"
            class="mt-8px focus:outline-none"
            formControlName="inventoryName"
            notFoundText="Không tìm thấy kho hàng"
            [tooltip]="tooltipInvent ? tooltipInvent : ''"
            #toolTipInvent="tooltip"
            [options]="{ display: tooltipInvent ? true : false }"
          >
            <ng-template ng-label-tmp let-item="item">
              <div
                class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px font-roboto-regular text-dark-grey"
              >
                [{{ item.code }}] {{ item.name }}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="'[' + item.code + ']' + item.name"
              >
                [{{ item.code }}] {{ item.name }}
              </div>
            </ng-template>
            <!-- <ng-option
              *ngFor="let inventoryName of inventoryChoose"
              [value]="inventoryName.id + inventoryName.code"
              >[{{ inventoryName.code }}] {{ inventoryName.name }}</ng-option
            > -->
          </ng-select>
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              formcreate.get('inventoryName').touched &&
              formcreate.controls.inventoryName.errors?.required
            "
          >
            {{
              formcreate.controls.inventoryName.errors?.required
                ? 'Vui lòng chọn kho hàng'
                : ''
            }}
          </span>
        </div>
        <div class="sm:col-span-6">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formcreate.controls.nameProduct.errors?.pattern
                ? 'error-class'
                : ''
            "
          >
            Hàng hóa
          </span>
          <input
            readonly
            type="text"
            formControlName="nameProduct"
            class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
          />
        </div>
        <div class="sm:col-span-6 relative">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formcreate.get('supplierName').touched &&
              formcreate.controls.supplierName.errors?.required
                ? 'error-class'
                : ''
            "
            >Nhà cung cấp <span class="text-red-light">*</span>
          </span>
          <ng-select
            [ngClass]="
              formcreate.get('supplierName').touched &&
              formcreate.controls.supplierName.errors?.required
                ? 'select-invalid'
                : 'select-name'
            "
            (change)="getSupp($event)"
            class="mt-8px focus:outline-none"
            placeholder="Chọn nhà cung cấp"
            bindLabel="name"
            bindValue="id"
            [items]="supplierChoose"
            formControlName="supplierName"
            notFoundText="Không tìm thấy nhà cung cấp"
            [tooltip]="tooltipSupp ? tooltipSupp : ''"
            #toolTipSupp="tooltip"
            [options]="{ display: tooltipSupp ? true : false }"
          >
            <ng-template ng-option-tmp let-item="item">
              <div
                class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                [tooltip]="item.name"
              >
                {{ item.name }}
              </div>
            </ng-template>
          </ng-select>

          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              formcreate.get('supplierName').touched &&
              formcreate.controls.supplierName.errors?.required
            "
          >
            {{
              formcreate.controls.supplierName.errors?.required
                ? 'Vui lòng chọn nhà cung cấp'
                : ''
            }}
          </span>
        </div>

        <!-- <div class="sm:col-span-6">
          <span
            class="font-roboto-regular text-12px text-grey-label"
            [ngClass]="
              formcreate.controls.stock.errors?.pattern ? 'error-class' : ''
            "
          >
            Tồn kho
          </span>
          <input
            readonly
            placeholder="Nhập số lượng tồn"
            (input)="changeInput($event.target.value)"
            type="text"
            placeholder=""
            formControlName="stock"
            class="border bg-grey-lightest border-grayscale-light-grey focus:outline-none w-full rounded-4px py-8px pl-12px h-40px mt-8px appearance-none font-roboto-regular text-14px text-purplish-grey"
          />
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="formcreate.controls.stock.errors?.pattern"
          >
            {{
              formcreate.controls.stock.errors?.pattern
                ? 'Vui lòng nhập đúng ký tự số'
                : ''
            }}
          </span>
          <div class="flex">
            <span
              class="font-roboto-regular text-12px text-blood-orange md:whitespace-nowrap"
              *ngIf="id_sku"
              >(Lưu ý: Thay đổi "Tồn kho" có thể dẫn tới mất đồng bộ với tồn kho
              thực tế.)
            </span>
          </div>
        </div> -->
        <div class="col-span-12 mt-5px">
          <input
            type="checkbox"
            id="Date"
            class="mr-8px"
            formControlName="isDate"
          />
          <label
            for="Date"
            class="font-roboto-regular text-12px text-grey-label"
            >Phân theo ngày</label
          >
        </div>

        <div class="col-span-full flex-row-reverse gap-8px flex h-64px pt-8px">
          <button
            class="btn-primary-add"
            type="button"
            [disabled]="!formcreate.valid"
            (click)="setButtonAddOrEdit()"
          >
            {{ nameButton }}
          </button>
          <button class="btn-text-cancel" type="button" (click)="closeModal()">
            Hủy
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
