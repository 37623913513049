import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserService } from 'src/app/api/user.service';
import { ToastrService } from 'src/app/core/services';
import { User } from 'src/app/model/user';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'meu-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss'],
})
export class CustomerManagementComponent implements OnInit {
  array: User[] = [];
  arrayLenght: number = this.array.length;
  indexArray: number = 0;
  customers: User[] = [];
  totalArray: number;

  urlDetail: string;
  idCustomer: string;

  isOpenModal: boolean;
  isOpenBottomsheet: Boolean;

  options: MxModalOption = {};
  optionsBottom: MxBottomPopupOption = {};
  titleModal: string;

  searchCustomer: string = '';
  subject: Subject<any> = new Subject();

  sortName: string = '';
  sort: string = '';

  Role = [];
  arrayRole: string;
  filterRole: string;
  selectedPeople = [];

  constructor(
    private customerService: UserService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.urlDetail = '/customer-management/detail/';
    this.search();
    this.getAllCustomer();
    this.getAllRole();
    this.callBackAfterClose();
  }

  //Paging
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    if (this.arrayRole) {
      this.filterRole =
        `extend_user_role@=` +
        this.arrayRole +
        `,full_name|email|extend_user_phone|extend_user_employee_code@=${this.searchCustomer}`;
    } else {
      this.filterRole = `full_name|email|extend_user_phone|extend_user_employee_code@=${this.searchCustomer}`;
    }
    this.customerService
      .apiUsersGet(this.filterRole, this.sort, currentPage, pageSize)
      .subscribe((data) => {
        if (data.success == true) {
          this.customers = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  getAllCustomer() {
    if (this.arrayRole) {
      this.filterRole =
        `extend_user_role@=` +
        this.arrayRole +
        `,full_name|email|extend_user_phone|extend_user_employee_code@=${this.searchCustomer}`;
    } else {
      this.filterRole = `full_name|email|extend_user_phone|extend_user_employee_code@=${this.searchCustomer}`;
    }

    this.customerService
      .apiUsersGet(this.filterRole)

      .subscribe((data) => {
        if (data.success == true) {
          this.array = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //filerRole
  getAllRole() {
    this.customerService.apiUsersGetAllRolesGet().subscribe((res) => {
      if (res.success == true) {
        this.Role = res.data;
      }
    });
  }
  getRoleUser(data) {
    var array = data;
    var arrayFilter = [];

    if (array) {
      array.forEach((e) => {
        arrayFilter.push(`${e.name}`);
      });

      this.arrayRole = arrayFilter.toString().replace(/,/g, '|');
      this.getAllCustomer();
    } else {
      this.getAllCustomer();
    }
  }

  //Modal
  open(id) {
    if (!this.isOpenModal) {
      this.titleModal = 'Thêm mới người dùng';
      this.isOpenModal = true;
    }
  }
  closeModal(data) {
    if (!data) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    } else {
      if (data.success == true) {
        this.isOpenModal = false;
        this.getAllCustomer();
      } else {
        // this.isOpenModal = true;
      }
    }
  }

  callBackAfterClose() {
    this.options.callBackAfterClose = (e) => {
      this.idCustomer = '';
      this.isOpenModal = false;
    };
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getAllCustomer();
  }
  MobileSort(data) {
    if (window.screen.width < 481) {
      this.Sort(data);
    }
  }

  //Search
  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getAllCustomer();
    });
  }
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}

  //SSO
  navigateSSO() {
    window.open(environment.ssoFeUrl);
  }
  updateListCustomr() {
    this.customerService.apiUsersTriggerSyncUserGet().subscribe((res) => {
      if (res.success == true) {
        this.getAllCustomer();
        this.toastService.success(res.message);
      } else {
        this.toastService.error(res.message);
      }
    });
  }
}
