<div class="sm:grid sm:grid-cols-12 px-16px h-auto">
  <div class="sm:col-span-full mt-6px">
    <form [formGroup]="formcreate">
      <div class="sm:grid sm:grid-cols-10 sm:space-x-16px">
        <div class="sm:col-span-3">
          <div class="sm:col-span-full">
            <span class="text-12px font-roboto-regular text-grey-label mb-2">
              Hình ảnh mô tả
            </span>
            <div
              class="flex h-152px border border-grayscale-light-grey rounded mt-8px"
              (click)="imagePath.click()"
            >
              <img
                [src]="urlImage"
                *ngIf="urlImage != null; else defaultImage"
                class="w-full h-full"
              />
              <ng-template #defaultImage>
                <img
                  src="assets/icons/table-icon/u_image-input-default.svg"
                  class="w-32px h-32px m-auto"
                />
              </ng-template>
              <input
                type="file"
                (change)="getImage($event.target.files)"
                class="hidden"
                #imagePath
                formControlName="imagePath"
              />
            </div>
          </div>
        </div>
        <div class="sm:col-span-7">
          <div class="sm:grid sm:grid-cols-10 sm:gap-4">
            <div class="sm:col-span-5">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  formcreate.get('productTemplate').touched &&
                  formcreate.controls.productTemplate.errors?.required
                    ? 'error-class'
                    : ''
                "
                >Phân loại hàng hóa <span class="text-red-light">*</span>
              </span>
              <ng-select
                [ngClass]="
                  formcreate.get('productTemplate').touched &&
                  formcreate.controls.productTemplate.errors?.required
                    ? 'select-invalid'
                    : 'select-name'
                "
                class="mt-8px focus:outline-none"
                placeholder="Chọn phân loại hàng hóa"
                bindLabel="name"
                bindValue="id"
                (change)="getproductTemplate($event)"
                [items]="productTemplateChoose"
                formControlName="productTemplate"
                notFoundText="Không tìm thấy phân loại hàng hóa"
                #toolTipProductTemplate="tooltip"
                [tooltip]="tooltipProductTemplate ? tooltipProductTemplate : ''"
                [options]="{
                  display: tooltipProductTemplate ? true : false
                }"
              >
                <ng-template ng-option-tmp let-item="item">
                  <div
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="item.name"
                  >
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="
                  formcreate.get('productTemplate').touched &&
                  formcreate.controls.productTemplate.errors?.required
                "
              >
                {{
                  formcreate.controls.productTemplate.errors?.required
                    ? 'Vui lòng chọn phân loại hàng hóa'
                    : ''
                }}
              </span>
            </div>
            <div class="sm:col-span-5">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  formcreate.get('unitName').touched &&
                  formcreate.controls.unitName.errors?.required
                    ? 'error-class'
                    : ''
                "
                >Đơn vị tính<span class="text-red-light">*</span></span
              >

              <ng-select
                [ngClass]="
                  formcreate.get('unitName').touched &&
                  formcreate.controls.unitName.errors?.required
                    ? 'select-invalid'
                    : 'select-name'
                "
                (change)="getUnit($event)"
                class="select-name mt-8px focus:outline-none"
                placeholder="Chọn đơn vị tính"
                bindLabel="name"
                bindValue="id"
                [items]="unitChoose"
                formControlName="unitName"
                notFoundText="Không tìm thấy đơn vị tính"
                [tooltip]="tooltipUnit ? tooltipUnit : ''"
                #toolTipUnit="tooltip"
                [options]="{ display: tooltipUnit ? true : false }"
              >
                <ng-template ng-option-tmp let-item="item">
                  <div
                    class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    [tooltip]="item.name"
                  >
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="
                  formcreate.get('unitName').touched &&
                  formcreate.controls.unitName.errors?.required
                "
              >
                {{
                  formcreate.controls.unitName.errors?.required
                    ? 'Vui lòng chọn đơn vị tính'
                    : ''
                }}
              </span>
            </div>
            <div class="sm:col-span-5">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  (formcreate.get('name').touched &&
                    formcreate.controls.name.errors?.required) ||
                  formcreate.controls.name.errors?.maxlength
                    ? 'error-class'
                    : ''
                "
              >
                Tên hàng hóa <span class="text-red-light">*</span></span
              >
              <input
                placeholder="Nhập tên hàng hóa"
                [ngClass]="
                  (formcreate.get('name').touched &&
                    formcreate.controls.name.errors?.required) ||
                  formcreate.controls.name.errors?.maxlength
                    ? 'class-invalid'
                    : 'class-valid'
                "
                formControlName="name"
                type="text"
                class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
              />
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="
                  (formcreate.get('name').touched &&
                    formcreate.controls.name.errors?.required) ||
                  formcreate.controls.name.errors?.maxlength
                "
              >
                {{
                  formcreate.controls.name.errors?.required
                    ? 'Vui lòng nhập tên hàng hóa'
                    : 'Tên hàng hóa tối đa 100 ký tự'
                }}
              </span>
            </div>
            <div class="sm:col-span-5">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  (formcreate.get('code').touched &&
                    formcreate.controls.code.errors?.required) ||
                  formcreate.controls.code.errors?.maxlength ||
                  formcreate.controls.code.errors?.minlength
                    ? 'error-class'
                    : ''
                "
              >
                Mã hàng hóa <span class="text-red-light">*</span></span
              >
              <input
                placeholder="Nhập mã hàng hóa"
                [ngClass]="
                  (formcreate.get('code').touched &&
                    formcreate.controls.code.errors?.required) ||
                  formcreate.controls.code.errors?.maxlength ||
                  formcreate.controls.code.errors?.minlength
                    ? 'class-invalid'
                    : 'class-valid'
                "
                formControlName="code"
                type="text"
                class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
              />
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="
                  (formcreate.get('code').touched &&
                    formcreate.controls.code.errors?.required) ||
                  formcreate.controls.code.errors?.maxlength ||
                  formcreate.controls.code.errors?.minlength
                "
              >
                {{
                  formcreate.controls.code.errors?.required
                    ? 'Vui lòng nhập mã hàng hóa'
                    : 'Mã hàng hóa tối đa 20 và tối thiểu 5 ký tự'
                }}
              </span>
            </div>

            <div class="sm:col-span-5">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  formcreate.controls.minimum_stock.errors?.pattern ||
                  isCheckStock
                    ? 'error-class'
                    : ''
                "
              >
                Định mức tối thiểu</span
              >
              <input
                placeholder="Nhập định mức tối thiểu"
                [value]="formcreate.get('minimum_stock').value | meucurrency"
                (keyup)="onChangeValueCurrency('minimum_stock', $event)"
                [ngClass]="
                  formcreate.controls.minimum_stock.errors?.pattern ||
                  isCheckStock
                    ? 'class-invalid'
                    : 'class-valid'
                "
                formControlName="minimum_stock"
                type="text"
                class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
              />
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="formcreate.controls.minimum_stock.errors?.pattern"
              >
                {{
                  formcreate.controls.minimum_stock.errors?.pattern
                    ? 'Vui lòng nhập đúng ký tự số'
                    : ''
                }}
              </span>
            </div>
            <div class="sm:col-span-5">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  formcreate.controls.maximum_stock.errors?.pattern ||
                  isCheckStock
                    ? 'error-class'
                    : ''
                "
              >
                Định mức tối đa</span
              >
              <input
                [value]="formcreate.get('maximum_stock').value | meucurrency"
                (keyup)="onChangeValueCurrency('maximum_stock', $event)"
                placeholder="Nhập định mức tối đa"
                [ngClass]="
                  formcreate.controls.maximum_stock.errors?.pattern ||
                  isCheckStock
                    ? 'class-invalid'
                    : 'class-valid'
                "
                formControlName="maximum_stock"
                type="text"
                class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
              />
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="formcreate.controls.maximum_stock.errors?.pattern"
              >
                {{
                  formcreate.controls.maximum_stock.errors?.pattern
                    ? 'Vui lòng nhập đúng ký tự số'
                    : ''
                }}
              </span>
            </div>
            <div class="sm:col-span-full">
              <span
                class="font-roboto-regular text-12px text-grey-label"
                [ngClass]="
                  formcreate.controls.description.errors?.maxlength
                    ? 'error-class'
                    : ''
                "
              >
                Mô tả
              </span>
              <textarea
                [ngClass]="
                  formcreate.controls.description.errors?.maxlength
                    ? 'class-invalid'
                    : 'class-valid'
                "
                formControlName="description"
                placeholder="Nhập mô tả"
                class="w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
              ></textarea>
              <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="formcreate.controls.description.errors?.maxlength"
              >
                {{
                  formcreate.controls.description.errors?.maxlength
                    ? 'Mô tả tối đa 300 ký tự'
                    : ''
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div
    class="col-span-full flex-row-reverse gap-8px flex h-64px mt-52px pt-8px"
  >
    <button
      class="btn-primary-add"
      type="button"
      (click)="addProduct()"
      [disabled]="!formcreate.valid"
    >
      Thêm
    </button>
    <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
  </div>
  <!-- {{ products | json }} -->
</div>
