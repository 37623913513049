<div
  class="sm:grid sm:grid-cols-12 px-16px content overflow-y-auto overflow-x-hidden"
  style="max-height: 80vh"
>
  <div class="sm:col-span-full mt-16px">
    <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-5">
      <div class="sm:col-span-6 border-r border-solid border-silver-40">
        <div class="flex flex-row font-roboto-medium text-14px justify-between">
          <span>Danh sách SKU</span>
          <span class="justify-end mr-10px">SL bản in</span>
        </div>

        <div
          class="flex flex-row justify-between mt-5px mr-10px"
          *ngFor="let element of allSkus; let i = index"
        >
          <div class="flex items-center">
            <div>
              <input
                type="checkbox"
                class="w-16px h-16px"
                (change)="checkCheckBoxvalue($event, element)"
              />
            </div>

            <div class="grid ml-5px">
              <span class="text-14px font-roboto-medium">{{
                element.sku_code
              }}</span>
              <span class="text-12px font-roboto-medium text-grey-label"
                >Số lượng: {{ element.quantity }}</span
              >
              <span class="text-12px font-roboto-medium text-grey-label"
                >Ngày hết hạn:
                {{ element.expiry_date | date: 'dd/MM/yyyy' }}</span
              >
            </div>
          </div>

          <div class="justify-end lg:flex sm:grid items-center">
            <button
              class="btn-primary-quantity flex items-center gap-2px"
              type="button"
              [disabled]="element.inputnumber === 1"
              (click)="minus(i)"
            >
              <img
                src="../../../../assets/icons/detail-icon/u_minus.svg"
                alt=""
                class="w-16px h-16px"
              />
            </button>

            <input
              type="number"
              class="h-32px lg:w-40px w-32px border bg-white border-lightest-grey hover:border-primary-blue focus:outline-none py-10px pl-5px focus:ring-1 focus:ring-primary-blue appearance-none font-roboto-regular text-14px text-dark-grey"
              [(ngModel)]="element.inputnumber"
              min="1"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            />

            <button
              class="btn-primary-quantity flex items-center gap-2px"
              type="button"
              (click)="plus(i)"
            >
              <img
                src="../../../../assets/icons/detail-icon/u_plus.svg"
                alt=""
                class="w-16px h-16px"
              />
            </button>
          </div>
        </div>
      </div>

      <div class="sm:col-span-6">
        <div class="flex flex-col">
          <span class="font-roboto-medium text-14px mb-10px"
            >Xem trước bản in</span
          >
        </div>

        <div id="printSection">
          <ng-container *ngFor="let item of allSkus">
            <ng-container *ngIf="item.isCheck">
              <div
                class="flex flex-grow-0 rounded border border-solid border-silver bg-white"
                *ngFor="let stamp of [].constructor(item.inputnumber)"
              >
                <qrcode
                  [qrdata]="item.barcode"
                  [width]="76"
                  [errorCorrectionLevel]="'M'"
                  [elementType]="'svg'"
                  [margin]="0"
                  class="my-15px ml-5px"
                ></qrcode>

                <div class="grid mx-5px h-80px my-15px">
                  <span class="text-10px font-roboto-medium">{{
                    item.sku_code
                  }}</span>
                  <span class="text-10px font-roboto-medium">{{
                    imExport_code
                  }}</span>
                  <span class="text-8px font-roboto-medium"
                    >NHH:
                    {{
                      item.expiry_date
                        ? (item.expiry_date | date: 'dd/MM/yyyy')
                        : ''
                    }}</span
                  >
                  <span
                    class="text-8px font-roboto-medium"
                    *ngIf="item.isImExported; else showExport"
                    >NNK:
                    {{ item.receipt_date | date: 'dd/MM/yyyy HH:mm' }}
                  </span>
                  <ng-template #showExport>
                    <span class="text-8px font-roboto-medium"
                      >NXK:
                      {{ item.issue_date | date: 'dd/MM/yyyy HH:mm' }}
                    </span>
                  </ng-template>

                  <span class="text-8px font-roboto-medium hidden"
                    >Số lô: {{ item.batch_code }}</span
                  >
                  <span class="text-8px font-roboto-medium"
                    >Số CTN: {{ item.container_code }}</span
                  >
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-span-full flex-row-reverse gap-8px flex m-20px">
  <button
    class="btn-primary-draft items-center gap-2px"
    type="button"
    [useExistingCss]="true"
    printSectionId="printSection"
    ngxPrint
  >
    In mã QR
  </button>

  <button
    class="btn-cancel items-center gap-2px"
    type="button"
    (click)="closeModal()"
  >
    Hủy
  </button>
</div>
