import { group, state } from '@angular/animations';
import { EventEmitter } from '@angular/core';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { Scale } from 'chart.js';
import Konva from 'konva';
import moment from 'moment';
export class Containers {
  shapes: any = [];
  //transformers: Konva.Transformer[] = [];
  lstPointLine: any = [];
  lstLineDraff: any = [];
  containersEvent = new EventEmitter();
  containersDblClickEvent = new EventEmitter();
  containersClickEvent = new EventEmitter();
  containersEmitToMapEvent = new EventEmitter();
  containerSelectedEvent = new EventEmitter();
  groupContainer = new Konva.Group();
  groupProp;
  //default shape properties
  rectSize = 45;
  rect = {
    points: [
      180,
      85,
      180,
      85 + this.rectSize,
      180 + this.rectSize,
      85 + this.rectSize,
      180 + this.rectSize,
      85,
      180,
      85,
    ],
    draggable: false,
    closed: true,
    opacity: 0.85,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
  };

  circle = {
    x: 180,
    y: 85,
    radius: 25,
    fill: '#ffcb44',
    strokeWidth: 1,
    draggable: false,
    //perfectDrawEnabled: true,
    lineJoin: 'miter',
    opacity: 0.85,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
  };

  constructor(
    private stage: Konva.Stage,
    private layer: Konva.Layer,
    private isAdmin?
  ) {
    this.groupContainer.addName('groupContainer');
    this.layer.add(this.groupContainer);
    this.groupContainer.moveToTop();
    this.groupProp = { name: 'gShape', draggable: isAdmin };
  }

  getRelativePointerPosition(node) {
    var transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();
    // get pointer (say mouse or touch) position
    var pos = node.getStage().getPointerPosition();
    // now we can find relative point
    return transform.point(pos);
  }
  addline() {
    this.stage.container().style.cursor = 'crosshair';
    this.stage.on('mousedown', (e) => {
      if (e.evt.which === 1) {
        const mousePos = this.getRelativePointerPosition(this.stage);
        const x = mousePos.x;
        const y = mousePos.y;
        this.lstPointLine.push(x);
        this.lstPointLine.push(y);
        if (this.lstPointLine.length > 0) {
          if (this.lstPointLine.length == 2) {
            var circle = new Konva.Circle({
              x: x,
              y: y,
              radius: 5,
              fill: '#ffcb44',
              strokeWidth: 1,
              draggable: false,
            });
            circle.on('click', (e) => {
              /* sự kiện click 1 lần */
              this.lstPointLine[
                this.lstPointLine.length - 2
              ] = this.lstPointLine[0];
              this.lstPointLine[
                this.lstPointLine.length - 1
              ] = this.lstPointLine[1];
              for (let i = 0; i < this.lstLineDraff.length; i++) {
                this.lstLineDraff[i].destroy();
              }
              let lstPointPoly = [];
              for (let i = 0; i < this.lstPointLine.length; i += 2) {
                lstPointPoly.push({
                  x: this.lstPointLine[i],
                  y: this.lstPointLine[i + 1],
                });
              }

              let polygon = {
                points: this.lstPointLine,
                fill: '#ffcb44',
                closed: true,
                opacity: 0.85,
                scaleX: 1,
                scaleY: 1,
                rotation: 0,
              };
              this.joinLineToPolygon(polygon, '', this.groupProp);
              circle.hide();
              this.lstPointLine = [];
              this.stage.container().style.cursor = 'auto';
              this.layer.batchDraw();
              this.stage.removeEventListener('mousedown');
            });
            this.layer.add(circle);
            this.layer.batchDraw();
          }
          this.renderLine();
        }
      } else if (e.evt.which === 3) {
        this.stage.removeEventListener('mousedown');
        circle.hide();
        this.lstPointLine = [];
        this.stage.container().style.cursor = 'auto';
        this.layer.batchDraw();
      }
    });
  }
  addShape(type) {
    switch (type) {
      case 'rect':
        this.joinLineToPolygon(this.rect, '', this.groupProp);
        break;
      case 'circle':
        this.addCircle(this.circle, '', this.groupProp);
        break;
      case 'line':
        this.addline();
        break;
      default:
        break;
    }
  }
  joinLineToPolygon(
    polygonProp,
    code?,
    groupProp?,
    used_percent?,
    import_history?,
    stock_available?
  ) {
    let group = new Konva.Group(groupProp ? groupProp : this.groupProp);
    let polygon = new Konva.Line(polygonProp);
    used_percent = used_percent ? used_percent : 0;
    this.addPercentToLineJoin(polygon, used_percent);
    group.add(polygon);
    this.groupContainer.add(group);
    this.layer.batchDraw();
    if (this.isAdmin) {
      this.addTransformerListeners(group);
    } else {
      let x = polygon.attrs.points[0] + polygon.width() / 2;
      let y = polygon.attrs.points[1] + polygon.height() / 2;
      this.addRectInfomation(
        x,
        y,
        group,
        code,
        used_percent,
        import_history,
        stock_available
      );
      polygon.on('strokeChange', (e) => {
        this.containerSelectedEvent.next(e);
      });
    }
    this.cloneShape(group);
    if (code) {
      let x =
        polygon.attrs.points[0] * polygon.scaleX() + polygon.getPosition().x;
      let y =
        polygon.attrs.points[1] * polygon.scaleY() + polygon.getPosition().y;
      this.addTextToShape(x, y, polygon, code);
    }
    return polygon;
  }
  addPercentToLineJoin(polygon, used_percent) {
    if (polygon.className != 'Circle') {
      polygon.setAttrs({
        fillLinearGradientStartPoint: {
          x: polygon.attrs.points[0],
          y:
            polygon.attrs.points[1] +
            polygon.height() * 2 -
            (polygon.height() * 2 * used_percent) / 100,
        },
        fillLinearGradientEndPoint: {
          x: polygon.attrs.points[0],
          y: polygon.attrs.points[1],
        },
        fillLinearGradientColorStops: [
          0,
          '#0091ff',
          0.5,
          '#0091ff',
          0.5,
          '#ababab',
        ],
      });
      polygon.fill('');

      if (used_percent == 100) {
        polygon.setAttrs({
          fillLinearGradientEndPoint: {
            x: polygon.attrs.points[0],
            y: polygon.attrs.points[1] + polygon.height() * 2,
          },
        });
        polygon.fill('#ffcb44');
      }
    } else {
      polygon.setAttrs({
        fillLinearGradientStartPoint: {
          x: -(used_percent - 10), // need to change
          y: 40, // dont modify
        },
        fillLinearGradientEndPoint: {
          x: -(used_percent - 10), // need to change
          y: -(used_percent - 10), // need to change
        },

        fillLinearGradientColorStops: [
          0,
          '#0091ff',
          0.5,
          '#0091ff',
          0.5,
          '#ababab',
        ],
      });
      polygon.fill('');
      if (used_percent == 100) {
        polygon.fill('#ffcb44');
      }
    }

    this.layer.batchDraw();
  }
  addCircle(
    circleProp,
    code?,
    groupProp?,
    used_percent?,
    import_history?,
    stock_available?
  ) {
    let group = new Konva.Group(groupProp ? groupProp : this.groupProp);
    let circle = new Konva.Circle(circleProp);
    group.add(circle);
    this.groupContainer.add(group);

    circle.draggable(false);
    this.layer.batchDraw();
    if (used_percent > 0) {
      /// Check theo 100% slot
      circle.setAttrs({
        fillLinearGradientStartPoint: {
          x: -(used_percent - 10), // need to change
          y: 40, // dont modify
        },
        fillLinearGradientEndPoint: {
          x: -(used_percent - 10), // need to change
          y: -(used_percent - 10), // need to change
        },

        // fillLinearGradientStartPoint: {
        //   x: circle.x(),
        //   y: circle.y(),
        // },
        // fillLinearGradientEndPoint: {
        //   x: circle.y(),
        //   y: circle.x(),
        // },
        fillLinearGradientColorStops: [
          0,
          '#0091ff',
          0.5,
          '#0091ff',
          0.5,
          '#ababab',
        ],
      });
    } else {
      circle.fill('#ababab');
    }
    if (used_percent == 100) {
      circle.fill('#ffcb44');
    }
    if (this.isAdmin) {
      this.addTransformerListeners(group);
    } else {
      let x = circle.x();
      let y = circle.y() + circle.height() / 2;
      this.addRectInfomation(
        x,
        y,
        group,
        code,
        used_percent,
        import_history,
        stock_available
      );
      circle.on('strokeChange', (e) => {
        this.containerSelectedEvent.next(e);
      });
    }
    this.cloneShape(group);
    if (code) {
      let x = circle.x() - circle.radius() * circle.scaleX();
      let y = circle.y() - circle.radius() * circle.scaleY();
      this.addTextToShape(x, y, circle, code);
    }
    return circle;
  }
  addTextToShape(x, y, shape, code) {
    let text = new Konva.Text({
      text: code,
      fontSize: 12,
      x: x,
      y: y,
      width: shape.width() * shape.scaleX(),
      height: shape.height() * shape.scaleY(),
      align: 'center',
      verticalAlign: 'middle',
      fontFamily: 'Roboto-Regular',
    });
    shape.parent.add(text);

    shape.on('scaleXChange', (e) => {
      text.width(e.currentTarget.width() * e.currentTarget.scaleX());
      switch (Object.getPrototypeOf(shape).className) {
        case 'Line':
          text.x(
            shape.attrs.points[0] * e.currentTarget.scaleX() +
              shape.getPosition().x
          );
          break;
        case 'Circle':
          text.x(shape.x() - shape.radius() * e.currentTarget.scaleX());
          break;
        default:
          break;
      }
    });
    shape.on('scaleYChange', (e) => {
      text.height(e.currentTarget.height() * e.currentTarget.scaleY());
      switch (Object.getPrototypeOf(shape).className) {
        case 'Line':
          text.y(
            shape.attrs.points[1] * e.currentTarget.scaleY() +
              shape.getPosition().y
          );
          break;
        case 'Circle':
          text.y(shape.y() - shape.radius() * e.currentTarget.scaleY());
          break;
        default:
          break;
      }
    });
    this.layer.batchDraw();
  }
  renderLine() {
    var poly = new Konva.Line({
      points: this.lstPointLine,
      fill: 'yellow',
      stroke: '#f4d21b',
      strokeWidth: 1,
    });
    // add the shape to the layer
    this.lstLineDraff.push(poly);
    this.layer.add(poly);
    this.layer.batchDraw();
  }
  cloneShape(group) {
    group.on('dragstart', (e) => {
      if (e.evt.shiftKey == true) {
        let text, shapes, result;
        text = group.find('Text')[0]?.text();
        shapes = group.find('Line');
        if (shapes.length > 0) {
          result = this.joinLineToPolygon(shapes[0].attrs, text, group.attrs);
        } else {
          shapes = group.find('Circle');
          result = this.addCircle(shapes[0].attrs, text, group.attrs);
        }
        this.containersEmitToMapEvent.emit({ shape: shapes[0], clone: result });
      }
    });
  }
  addTransformerListeners(group) {
    //add tranformer to shape
    const tr = new Konva.Transformer({
      anchorStroke: '#f4d21b',
      anchorFill: '#f4d21b',
      borderStroke: '#494c55',
      rotateAnchorOffset: 30,
    });
    var _this = this;
    this.stage.on('click', function (e) {
      if (group._id == this.clickStartShape.parent._id) {
        tr.nodes([this.clickStartShape.parent.children[0]]);
      } else {
        tr.detach();
        return;
      }
      group.add(tr);
      //_this.transformers.push(tr);
      _this.layer.batchDraw();
    });
    this.stage.on('dblclick', function (e) {
      if (!this.clickStartShape || e.target.getType() === 'Stage') {
        tr.detach();
        return;
      }
      if (group._id == this.clickStartShape.parent._id) {
        _this.containersDblClickEvent.emit(
          this.clickStartShape.parent.children[0]
        );
      }
      tr.detach();
    });
  }

  saveContainer() {
    this.containersEvent.emit(this.groupContainer.children);
  }
  addRectInfomation(
    x,
    y,
    group,
    code,
    used_percent?,
    import_history?,
    stock_available?
  ) {
    //console.log(import_history, stock_available);

    const groupInfo = new Konva.Group();
    groupInfo.add(
      new Konva.RegularPolygon({
        x: x,
        y: y + 10,
        sides: 3,
        radius: 6,
        fill: 'white',
        lineJoin: 'miter',
      })
    );
    const rect = new Konva.Rect({
      x: x - 18,
      y: y + 14,
      fill: 'white',
      lineJoin: 'miter',
      width: 320,
      height: 265,
      cornerRadius: 8,
      shadowBlur: 2,
      shadowColor: 'grey',
      shadowOpacity: 0.3,
    });
    groupInfo.add(rect);
    groupInfo.add(
      new Konva.Circle({
        x: rect.x() + 20,
        y: rect.y() + 20,
        radius: 6,
        fill: used_percent == 100 ? '#ffcb44' : '#0091ff',
        padding: 10,
      })
    );
    let positionX = rect.x();
    let positionY = rect.y();

    groupInfo.add(
      this.createNewTextToInfo(
        'Mã ô',
        positionX + 20,
        (positionY += 5),
        '#505050',
        10,
        12
      )
    );
    groupInfo.add(
      this.createNewTextToInfo(
        code,
        positionX + 5,
        (positionY += 20),
        '#041826',
        10,
        20
      )
    );

    if (stock_available.length > 0) {
      positionY += 5;
      if (import_history.length > 0) {
        groupInfo.add(
          this.createNewTextToInfo(
            'Phiếu nhập:',
            positionX + 5,
            (positionY += 20),
            '#505050',
            10,
            12
          )
        );
        import_history.forEach((item) => {
          item.time = moment(item.time).format('DD/MM/YYYY - HH:mm');
          positionY += 20;
          groupInfo.add(
            this.createNewTextToInfo(
              item.code,
              positionX + 5,
              positionY,
              '#0091ff',
              10,
              12
            )
          );
          groupInfo.add(
            this.createNewTextToInfo(
              item.time,
              positionX + 90,
              positionY,
              '#041826',
              10,
              12
            )
          );
        });
        groupInfo.add(
          this.createNewTextToInfo(
            '...',
            positionX + 5,
            (positionY += 20),
            '#0091ff',
            10,
            12
          )
        );
      }
      groupInfo.add(
        this.createNewTextToInfo(
          'Hàng hóa:',
          positionX + 5,
          (positionY += 20),
          '#505050',
          10,
          12
        )
      );
      groupInfo.add(
        this.createNewTextToInfo(
          'Số lượng:',
          positionX + 200,
          positionY,
          '#505050',
          10,
          12
        )
      );
      stock_available.forEach((item, index) => {
        if (index < 2) {
          positionY += 20;
          groupInfo.add(
            this.createNewTextToInfo(
              item.product,
              positionX + 5,
              positionY,
              '#0091ff',
              10,
              12
            )
          );
          groupInfo.add(
            this.createNewTextToInfo(
              item.quantity,
              positionX + 200,
              positionY,
              '#041826',
              10,
              12
            )
          );
        }
      });
      groupInfo.add(
        this.createNewTextToInfo(
          '...',
          positionX + 5,
          (positionY += 20),
          '#0091ff',
          10,
          12
        )
      );
    } else {
      rect.height(rect.height() - 110);
    }

    groupInfo.add(
      this.createNewTextToInfo(
        'Đã dùng:',
        positionX + 5,
        (positionY += 20),
        '#505050',
        10,
        12
      )
    );
    groupInfo.add(
      this.createNewTextToInfo(
        `${used_percent}%`,
        positionX + 5,
        (positionY += 20),
        '#041826',
        10,
        14
      )
    );
    groupInfo.hide();
    group.add(groupInfo);
    let _this = this;

    this.stage.on('click', function (e) {
      if (group._id == this.clickStartShape.parent._id) {
        //select slot
        if (group.children[0].attrs.stroke) {
          group.children[0].setAttrs({ stroke: '' });
        } else {
          group.children[0].setAttrs({ stroke: '#0091ff' });
        }
        groupInfo.show();

        //listen click imp_export_code on groupInfo to get text
        for (let i = 0; i < groupInfo.children.length; i++) {
          groupInfo.children[i].addEventListener('click', () => {
            let imExport_code = groupInfo.children[i].attrs.text;
            if (imExport_code) {
              if (imExport_code.includes('PN' || 'PX' || 'PSX' || 'PSN')) {
                _this.containersClickEvent.emit(imExport_code);
                return;
              }
            }
          });
        }

        group.moveToTop();
      } else {
        groupInfo.hide();
      }
    });

    this.stage.on('dblclick', function (e) {
      if (!this.clickStartShape || e.target.getType() === 'Stage') {
        return;
      }
      if (group._id == this.clickStartShape.parent._id) {
        _this.containersDblClickEvent.emit(
          this.clickStartShape.parent.children[0]
        );
      }
      groupInfo.hide();
    });
  }
  createNewTextToInfo(s, x, y, c, p, fs) {
    return new Konva.Text({
      text: s,
      x: x,
      y: y,
      fill: c,
      padding: p,
      fontSize: fs,
      fontFamily: 'Roboto-Regular',
    });
  }
}
