import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  UnitService,
  ProductTemplateService,
  ProductService,
} from 'src/app/api/api';
import { ToastrService } from 'src/app/core/services';
import { Product, Unit, ProductTemplate } from 'src/app/model/models';

@Component({
  selector: 'meu-modal-create-product',
  templateUrl: './modal-create-product.component.html',
  styleUrls: ['./modal-create-product.component.scss'],
})
export class ModalCreateProductComponent implements OnInit {
  @Output() closeModalUnit = new EventEmitter();
  @Input() productName: string;

  public unitChoose: Unit[];
  public productTemplateChoose: ProductTemplate[];

  products: Product = {};
  isCheckStock: boolean;

  urlUpdate: File;
  urlImage: File = null;

  messError = '';

  //tooltip
  tooltipUnit: string;
  tooltipProductTemplate: string;
  @ViewChild('toolTipUnit', { static: false }) toolTipUnit;
  @ViewChild('toolTipProductTemplate', { static: false })
  toolTipProductTemplate;

  //Load more
  pageNumber_unit: number = 1000;
  pageNumber_proTemplate: number = 1000;

  //form
  formcreate = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    code: new FormControl('', [
      Validators.required,
      Validators.maxLength(20),
      Validators.minLength(5),
    ]),
    minimum_stock: new FormControl('', [
      Validators.pattern('[0-9]+(,[0-9]+)*,?'),
    ]),
    maximum_stock: new FormControl('', [
      Validators.pattern('[0-9]+(,[0-9]+)*,?'),
    ]),
    unitName: new FormControl(null, [Validators.required]),
    productTemplate: new FormControl(null, [Validators.required]),
    description: new FormControl('', [Validators.maxLength(300)]),
    imagePath: new FormControl(),
  });
  constructor(
    private unitService: UnitService,
    private productTemplateService: ProductTemplateService,
    private productService: ProductService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUnitForChoose();
    this.getProductTemplateForChoose();
    if (this.productName) {
      this.formcreate.controls['name'].setValue(this.productName);
    }
  }
  closeModal(res = null) {
    if (res) {
      this.closeModalUnit.emit(res);

      return;
    }
    this.closeModalUnit.emit();
  }
  addProduct() {
    const product = this.formcreate.value;
    this.products.product_template_id = product.productTemplate;
    this.products.unit_id = product.unitName;

    this.products.description = product.description.trim();
    this.products.name = product.name.trim();
    this.products.code = product.code.trim();
    this.products.is_hidden = false;

    //stock parseFloat(

    this.products.maximum_stock_level = product.maximum_stock
      ? parseInt(product.maximum_stock.toString().replace(/,/g, ''))
      : null;

    this.products.minimum_stock_level = product.minimum_stock
      ? parseInt(product.minimum_stock.toString().replace(/,/g, ''))
      : null;

    if (this.products.name == '') {
      this.formcreate.controls['name'].setValue(null);
    }
    if (this.products.code == '') {
      this.formcreate.controls['code'].setValue(null);
    }
    if (this.formcreate.valid) {
      if (
        this.products.maximum_stock_level &&
        this.products.minimum_stock_level
      ) {
        if (
          this.products.maximum_stock_level < this.products.minimum_stock_level
        ) {
          this.isCheckStock = true;
          this.toastService.warnning(
            'Định mức tối thiểu phải nhỏ hơn định mức tối đa'
          );
        } else {
          this.isCheckStock = false;
          this.postProduct(this.products);
        }
      } else {
        this.isCheckStock = false;
        this.postProduct(this.products);
      }
    }
  }

  postProduct(product) {
    product.code = this.changeCharater(product.code.trim());
    this.productService.apiProductPost(product).subscribe((res) => {
      if (res.success == true) {
        if (this.urlUpdate) {
          this.productService
            .apiProductIdUploadPut(res.data.id, this.urlUpdate)
            .subscribe((response) => {});
        }
        this.toastService.success(res.message);
        this.closeModal(res);
      } else {
        this.toastService.error(res.message);
        this.closeModal(res);
      }
    });
  }

  //Get option for select

  getUnitForChoose() {
    this.unitService
      .apiUnitGet('', '', 1, this.pageNumber_unit)
      .subscribe((res) => {
        if (res.success == true) {
          let defaultUnit: Unit[] = res.data.collection.filter((result: Unit) => {
            return result.name === 'Bao';
          });
          this.formcreate.controls.unitName.setValue(defaultUnit[0].id);
          this.unitChoose = res.data.collection;
        }
      });
  }
  getProductTemplateForChoose() {
    this.productTemplateService
      .apiProductTemplateGet('', '', 1, this.pageNumber_proTemplate)
      .subscribe((res) => {
        if (res.success == true) {
          this.productTemplateChoose = res.data.collection;
        }
      });
  }
  //Get tooltip ng-select
  getUnit(data) {
    this.toolTipUnit.hide();
    this.tooltipUnit = data ? data.name : null;
    (this.toolTipUnit);
  }
  getproductTemplate(data) {
    this.toolTipProductTemplate.hide();
    this.tooltipProductTemplate = data ? data.name : null;
  }

  changeCharater(str) {
    if (str) {
      str = str.replace(/ /g, ' ').toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }

  getImage(event: FileList) {
    let file: File = event[0];
    var reader = new FileReader();
    if (file.type.includes('image/')) {
      reader.onload = (event: any) => {
        this.urlImage = event.target.result;
        this.urlUpdate = file;
      };

      reader.readAsDataURL(file);
    } else {
      this.toastService.error('Không thể upload ảnh');
    }
  }

  onChangeValueCurrency(field: string, e: any) {
    const stock = this.formcreate.value;
    this.isCheckStock = false;
    if (isNaN(e.key) && e.key != 'Backspace' && e.key != 'Delete') {
      const currency_stock = stock[`${field}`].toString().replace(/,/g, '');
      // .replace(e.key, '');
      this.formcreate.controls[`${field}`].setValue(currency_stock);
      return;
    }
  }
}
