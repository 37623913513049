<div style="max-height: 500px; overflow: hidden; overflow-y: auto">
  <form
    [formGroup]="formcreate"
    class="grid grid-cols-12 gap-x-4 gap-y-2 px-16px pt-16px"
  >
    <div class="col-span-12">
      <span
        class="font-roboto-regular text-12px text-warm-grey"
        [ngClass]="
          formcreate.get('name').touched &&
          formcreate.controls.name.errors?.required
            ? 'error-class'
            : ''
        "
        >Tên loại hợp đồng <span class="text-red-light">*</span>
      </span>
      <input
        [ngClass]="
          formcreate.get('name').touched &&
          formcreate.controls.name.errors?.required
            ? 'class-invalid'
            : 'class-valid'
        "
        type="text"
        placeholder="Nhập tên loại hợp đồng"
        formControlName="name"
        class="w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px px-12px h-40px focus:ring-1 focus:ring-primary-blue mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
      />
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          formcreate.get('name').touched &&
          formcreate.controls.name.errors?.required
        "
      >
        {{
          formcreate.controls.name.errors?.required
            ? 'Vui lòng nhập tên loại hợp đồng'
            : ''
        }}
      </span>
    </div>
    <!-- <div class="col-span-4">
            <span
                class="font-roboto-regular text-12px text-warm-grey"
                [ngClass]="
                    formcreate.get('code').touched &&
                    formcreate.controls.code.errors?.required
                    ? 'error-class'
                    : ''
                "
                >Mã loại hợp đồng <span class="text-red-light">*</span>
            </span>
            <input
                [ngClass]="
                formcreate.get('code').touched &&
                formcreate.controls.code.errors?.required
                    ? 'class-invalid'
                    : 'class-valid'
                "
                type="text"
                placeholder="Nhập mã hợp đồng"
                formControlName="code"
                class="w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px px-12px h-40px focus:ring-1 focus:ring-primary-blue mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            />
            <span
                class="font-roboto-regular text-12px text-red-light"
                *ngIf="
                formcreate.get('code').touched &&
                formcreate.controls.code.errors?.required
                "
            >
                {{
                formcreate.controls.code.errors?.required
                    ? 'Vui lòng nhập mã loại hợp đồng'
                    : ''
                }}
            </span>
        </div> -->
    <div class="col-span-12">
      <span
        class="font-roboto-regular text-12px text-warm-grey"
        [ngClass]="
          formcreate.get('des').touched &&
          formcreate.controls.des.errors?.required
            ? 'error-class'
            : ''
        "
        >Ghi chú
      </span>
      <textarea
        [ngClass]="
          formcreate.controls.des.errors?.maxlength
            ? 'class-invalid'
            : 'class-valid'
        "
        formControlName="des"
        placeholder="Nhập ghi chú"
        class="w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
      ></textarea>
    </div>
  </form>
</div>
<div class="grid grid-cols-12 px-16px pt-16px z-1">
  <div class="col-span-12 flex-row-reverse gap-8px flex h-60px">
    <button
      class="btn-primary-add"
      type="button"
      (click)="submit()"
      [disabled]="!formcreate.valid"
    >
      {{ nameButton }}
    </button>
    <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
  </div>
</div>
