<div class="container py-4">
    <div class="scroll-custome">
        <table class="border-collapse">
            <thead>
                <tr class="font-roboto-medium text-12px bg-lightest-grey h-40px">
                    <th class="th-stt">#</th>
                    <th class="th-number">Số container</th>
                    <th class="th-date">Ngày nhập</th>
                    <th class="th-action">Thao tác</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of allContainer; let i = index" class="h-40px font-roboto-regular text-14px">
                    <td class="td-stt shadow-sm">{{i+1}}</td>
                    <td class="td-number shadow-sm">{{item.number}}</td>
                    <td class="td-date shadow-sm">{{item.import_date ? (item.import_date | date:'dd/MM/YYYY') : '--/--/--'}}</td>
                    <td class="td-action shadow-sm">
                        <button class="custom-button" (click)="openImport(item)">Hạ xe</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="w-full">
        <meu-pagination-component
          *ngIf="totalArray != 0"
          [total]="totalArray"
          [array]="array"
          [changePage]="onPagingFunc"
        ></meu-pagination-component>
    </div>
</div>