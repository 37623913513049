import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PermissionService } from '../../core/guards/permission.service';

@Directive({ selector: '[permission]' })
export class PermissionDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private permissionService: PermissionService
  ) {}
  @Input('permission') permission: string;
  ngOnInit() {
    //const lstPermission = this.authService.getMyPermissions();
    this.permissionService.getMyPermissions().subscribe((lstPermission) => {
      const isHasPermission = lstPermission.includes(this.permission);
      if (!isHasPermission) {
        return this.el.nativeElement.remove();
      }
    });
  }
}
