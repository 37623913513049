<div class="cls-is-bottom">
  <div
    class="col-span-12 flex flex-col md:flex-row md:justify-between mb-6px lg:mb-0 px-16px"
  >
    <div
      class="py-12px font-roboto-medium font-medium text-dark-grey text-16px leading-7 not-italic"
    >
      {{ type_name }}
    </div>
    <div class="flex flex-wrap md:float-right items-center gap-8px">
      <div class="flex gap-8px">
        <button
          class="btn-primary-icon flex items-center gap-4px"
          (click)="addRowTemplate()"
        >
          <img src="assets/icons/handle-icons/icon_add.svg" />
          <span>Thêm mới</span>
        </button>
      </div>
    </div>
  </div>

  <div class="col-span-12 px-16px">
    <table class="w-full border-collapse">
      <!-- Header -->
      <thead
        class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
      >
        <th class="md:text-center col-no">#</th>

        <th class="text-left col-name items-center cursor-pointer">
          <div class="items-center">Mẫu hợp đồng</div>
        </th>

        <th class="text-left col-attachment items-center cursor-pointer">
          <div class="items-center">Tệp đính kèm</div>
        </th>

        <th class="text-center col-is-enabled items-center cursor-pointer">
          <div class="items-center">Hiển thị</div>
        </th>

        <th class="text-center col-operation"></th>
      </thead>

      <!-- Body -->
      <tbody *ngIf="arrTemplate.length != 0; else noInfo">
        <tr
          *ngFor="let element of arrTemplate; let i = index"
          class="cursor-pointer"
        >
          <td
            class="td-index text-14px text-center font-roboto-regular"
            data-label="#"
          >
            {{ i + 1 }}
          </td>

          <td class="td-name" data-label="Mẫu hợp đồng">
            <div class="grid grid-cols-12 items-center">
              <div
                *ngIf="!element.isEdit"
                class="col-span-12 overflow-hidden overflow-ellipsis whitespace-nowrap text-14px font-roboto-regular"
                [tooltip]="element.name"
              >
                {{ element.name }}
              </div>

              <div *ngIf="element.isEdit" class="col-span-12">
                <input
                  type="text"
                  class="col-span-12 px-2 w-full h-42px border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
                  [(ngModel)]="element.name"
                  placeholder="Nhập tên mẫu HĐ"
                  autocomplete="off"
                />
              </div>
            </div>
          </td>

          <td class="td-attachment" data-label="Tệp đính kèm">
            <div class="grid grid-cols-12 items-center">
              <div
                *ngIf="!element.isEdit"
                class="col-span-12 overflow-hidden overflow-ellipsis whitespace-nowrap text-14px text-primary-dodger-blue font-roboto-regular"
                [tooltip]="element.file_name"
                (click)="downloadTemplate(element.id, element.file_name)"
              >
                {{ element.file_name }}
              </div>

              <div *ngIf="element.isEdit" class="col-span-12">
                <ngx-dropzone
                  #commonDropzone
                  id="common-dropzone"
                  (change)="onSelect($event, element)"
                  accept=".docx"
                  [multiple]="false"
                >
                  <ngx-dropzone-label
                    class="img-dropzone-label overflow-ellipsis overflow-hidden whitespace-nowrap"
                    *ngIf="!disabled"
                  >
                    Bấm vào đây để tải tệp lên (.docx)</ngx-dropzone-label
                  >

                  <ngx-dropzone-preview
                    class="img-dropzone-preview"
                    *ngFor="let itemFile of element.files"
                    [file]="itemFile"
                    [removable]="true"
                    (removed)="onRemove(itemFile, element)"
                  >
                    <ngx-dropzone-label
                      class="ellipsis img-dropzone-preview-label"
                      [tooltip]="itemFile.name"
                    >
                      {{ itemFile.name }}
                    </ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
            </div>
          </td>

          <td class="td-is-enabled py-10px px-16px" data-label="Hiển thị">
            <div *ngIf="!element.isEdit" class="items-center">
              <input
                type="checkbox"
                [checked]="element.is_enabled"
                class="w-16px h-16px"
                onclick="return false"
              />
            </div>
            <div *ngIf="element.isEdit" class="items-center">
              <input
                type="checkbox"
                [checked]="element.is_enabled"
                (change)="changeIsEnabled(element)"
                class="w-16px h-16px"
              />
            </div>
          </td>

          <td class="td-thaotac">
            <button class="focus:outline-none mr-10px" *ngIf="element.isEdit">
              <img
                src="../../../../assets/icons/handle-icons/u_save_grey.svg"
                alt=""
                tooltip="Lưu"
                (click)="saveRow(i, element)"
              />
            </button>
            <button class="focus:outline-none" *ngIf="element.isEdit">
              <img
                src="../../../../assets/icons/handle-icons/u_trash-alt.svg"
                alt=""
                tooltip="Hủy"
                (click)="cancelEdit(i, element)"
              />
            </button>
            <button class="focus:outline-none mr-10px" *ngIf="!element.isEdit">
              <img
                src="../../../../assets/icons/product-categories-icons/u_edit-alt.svg"
                alt=""
                tooltip="Chỉnh sửa"
                (click)="editRow(element)"
              />
            </button>
            <button class="focus:outline-none" *ngIf="!element.isEdit">
              <img
                src="../../../../assets/icons/product-categories-icons/u_trash-alt.svg"
                alt=""
                tooltip="Xóa"
                (click)="removeRow(element)"
              />
            </button>
          </td>
        </tr>
      </tbody>
      <ng-template #noInfo>
        <tbody>
          <tr
            class="font-roboto-regular text-14px w-full h-48px bg-white text-center"
          >
            <td colspan="5" class="td-noinfo">Không có dữ liệu</td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
<div class="grid grid-cols-12 px-16px pt-16px z-1">
  <div class="col-span-12 flex-row-reverse gap-8px flex h-60px">
    <button class="btn-text-cancel" (click)="closeModal()">Đóng</button>
  </div>
</div>
