<div class="w-full overflow-y-auto max-height">
  <div class="flex gap-8px mt-8px pl-8px">
    <button
      *ngIf="!isPrintDivideQuantity"
      [useExistingCss]="true"
      printSectionId="exportBill"
      ngxPrint
      class="btn-primary-draft flex items-center gap-2px"
      type="button"
    >
      {{ titlePrint }}
    </button>
    <button
      *ngIf="!isPrintDivideQuantity"
      (click)="saveIExportBillorCommand()"
      class="btn-primary-save flex items-center gap-2px"
      type="button"
    >
      {{ titleDownload }}
    </button>

    <!-- Phân bổ -->
    <ng-select
      *ngIf="isPrintDivideQuantity"
      required
      (change)="getValueArray($event)"
      [clearable]="false"
      class="focus:outline-none select"
      bindValue="id"
      [(ngModel)]="arrayImport[0].id"
      [items]="arrayImport"
      ><ng-template ng-label-tmp let-item="item">
        <div class="flex items-center space-x-2">
          <span
            class="text-14px font-roboto-regular text-dark-grey overflow-hidden overflow-ellipsis"
            >{{ item.product_name }}</span
          >
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="flex items-center space-x-2">
          <span>{{ item.product_name }}</span>
        </div>
      </ng-template>
    </ng-select>
    <button
      *ngIf="isPrintDivideQuantity"
      class="btn-primary-save flex items-center gap-2px"
      type="button"
      [useExistingCss]="true"
      styleSheetFile="./print.component.scss"
      printSectionId="quantityBill"
      ngxPrint
    >
      <img src="../../../../assets/icons/handle-icons/u_print.svg" alt="" />
      In
    </button>
    <!-- .productName: { 'height': '200px', 'color':'red' },
        .cellLocation: { 'height': '200px' } -->
  </div>

  <div *ngIf="!isPrintDivideQuantity" id="exportBill" class="w-full p-10px">
    <div class="flex w-full">
      <img
        src="./assets/icons/hoa-chu-onions.png"
        style="width: 100px; height: 100px"
      />
      <div class="flex flex-col justify-between gap-6px">
        <div class="w-full text-18px font-roboto-medium text-dark-grey">
          CÔNG TY TNHH SX TM DV XNK HÒA CHU
        </div>
        <div class="w-full pl-10px text-14px font-roboto-regular">
          <span
            >Địa chỉ: 68 Nguyễn Thị Thích, P.Bình Chiểu, Thành phố Thủ Đức,
            Thành phố Hồ Chí Minh</span
          >
        </div>
        <div class="w-full pl-10px text-14px font-roboto-regular">
          <span>TEL: 0888960033 - 0888980033 </span>
          <span class="ml-10px">MST: 0317012371</span>
        </div>

        <div class="w-full pl-10px text-14px font-roboto-regular">
          <span>Email: nongsan@hoachu.vn</span>
        </div>
      </div>
    </div>
    <hr class="mt-5px" />
    <div class="cont">
      <div class="grid grid-cols-12 mt-15px">
        <div
          class="col-span-6 text-center self-center text-24px font-roboto-medium text-dark-grey"
        >
          {{ titleBillOrCommand1 }}<br />
          {{ titleBillOrCommand2 }}
        </div>
        <div class="col-span-6 text-left self-center">
          <div class="col-span-12 text-24px font-roboto-medium text-dark-grey">
            *{{ iexportCode }}*
          </div>
        </div>
      </div>
    </div>
    <div class="info mt-10px">
      <div class="grid grid-cols-12">
        <!-- <div class="col-span-6"></div> -->
        <div class="col-span-6 mb-8px">
          <div class="flex flex-col gap-8px">
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">{{
                  iexportCode.includes('PN') || iexportCode.includes('PSN')
                    ? 'Số phiếu nhập/Code:'
                    : 'Số phiếu xuất/Code:'
                }}</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ iexportCode }}</span
                >
              </div>
            </div>
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">{{
                  iexportCode.includes('PN') || iexportCode.includes('PSN')
                    ? 'Ngày giờ nhập kho/Date:'
                    : 'Ngày giờ xuất kho/Date:'
                }}</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ date_time_export | date: 'dd/MM/yyyy HH:mm' }}</span
                >
              </div>
            </div>
            <div class="flex" *ngIf="iexportCode.includes('PN') || iexportCode.includes('PSN')">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">Nhà cung cấp:</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ extend_name_supplier }}</span
                >
              </div>
            </div>

            <div *ngIf="isCheckContainer" class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular"
                  >Số container:
                </span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ container }}</span
                >
              </div>
            </div>
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">Số xe:</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ receive_car }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-6">
          <div class="flex flex-col gap-8px">
            <div class="flex">
              <div class="min-width">
                <span class="text-12px font-roboto-regular"
                  >Tên đơn vị/Customer:</span
                >
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">{{
                  extend_name_customer
                }}</span>
              </div>
            </div>

            <div class="flex">
              <div class="min-width">
                <span class="text-12px font-roboto-regular"
                  >Địa chỉ/Address:</span
                >
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">{{
                  address
                }}</span>
              </div>
            </div>
            <div class="flex">
              <div class="min-width">
                <span class="text-12px font-roboto-regular"
                  >Ngày in/Print Date:</span
                >
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ dateNow | date: 'dd/MM/yyyy HH:mm' }}</span
                >
              </div>
            </div>

            <div class="flex">
              <div class="min-width">
                <span class="text-12px font-roboto-regular">
                  {{
                    iexportCode.includes('PN') || iexportCode.includes('PSN')
                      ? 'Người giao hàng/Deliver:'
                      : 'Người nhận hàng/Receiver:'
                  }}</span
                >
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">{{
                  receiver
                }}</span>
              </div>
            </div>
            <div class="flex">
              <div class="min-width">
                <span class="text-12px font-roboto-regular"
                  >Nhân viên kinh doanh:</span
                >
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ seller_staff }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-span-6">
          <div class="flex flex-col gap-8px">
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">{{
                  iexportCode.includes('PN') || iexportCode.includes('PSN')
                    ? 'Ngày nhập kho/Date:'
                    : 'Ngày xuất kho/Date:'
                }}</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ date_time_export | date: 'dd/MM/yyyy' }}</span
                >
              </div>
            </div>
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">{{
                  iexportCode.includes('PN') || iexportCode.includes('PSN')
                    ? ' Giờ nhập kho/Time:'
                    : ' Giờ xuất kho/Time:'
                }}</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ date_time_export | date: 'HH:mm' }}</span
                >
              </div>
            </div>

            <div *ngIf="isCheckContainer" class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular"
                  >Số container:
                </span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ container }}</span
                >
              </div>
            </div>
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">Số xe:</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ receive_car }}</span
                >
              </div>
            </div>
            <div class="flex">
              <div class="pl-5px min-width">
                <span class="text-12px font-roboto-regular">Nhân viên kinh doanh:</span>
              </div>
              <div class="pl-10px">
                <span class="break-words text-12px font-roboto-regular">
                  {{ seller_staff }}</span
                >
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-span-12 ml-5px grid grid-cols-12">
          <div class="col-span-12">
            <span class="text-12px font-roboto-regular">Ghi chú/Note:</span>
            <span class="mr-5px break-words text-12px font-roboto-regular">
              {{ note }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- table -->

    <!-- Phiếu -->
    <div
      *ngIf="isBillOrCommand; else NoisBillOrCommand"
      class="table-export mt-10px"
    >
      <table
        *ngIf="
          iexportCode.includes('PN') || iexportCode.includes('PSN');
          else hasPX
        "
        cellspacing="0"
        cellpadding="0"
        class="table-fixed w-full border-separate"
      >
        <thead>
          <tr>
            <th class="w-30px break-words">STT ID</th>
            <th class="col-name">
              <div class="flex flex-col">
                <span>Tên hàng</span>
                <span>Item name</span>
              </div>
            </th>
            <!-- <th class="col-code">Số phiếu nhập</th> -->
            <th class="col-sa">
              <div class="flex flex-col">
                <span>Qui cách</span>
                <span>Specific action</span>
                <span>(KG)</span>
              </div>
            </th>
            <th class="col-unit">
              <div class="flex flex-col">
                <span>ĐVT</span>
                <span>Unit</span>
              </div>
            </th>
            <th colspan="2" class="border-t">
              <div class="flex flex-col">
                <div class="w-full border-b">
                  SỐ LƯỢNG NHẬP KHO <br />
                  Receipt Quantity
                </div>
                <div class="w-full flex justify-between">
                  <div style="width: 50%" class="border-r">
                    Số kiện<br />
                    Quantity
                  </div>
                  <div style="width: 50%">
                    Trọng lượng <br />
                    Weight (KG)
                  </div>
                </div>
              </div>
            </th>
            <!-- <th class="col-quantity">
              <div class="flex flex-col">
                <span>Số kiện </span>
                <span>Qantity</span>
              </div>
            </th>
            <th class="col-net">
              <div class="flex flex-col">
                <span>Trọng lượng </span>
                <span>Netweight</span>
                <span>(KG)</span>
              </div>
            </th> -->
            <!-- <th class="col-car">
              <div class="flex flex-col">
                <span>Hạn SD </span>
                <span>Expiry</span>
              </div>
            </th> -->
            <th class="col-stock border-r">
              <div class="flex flex-col">
                <span>GHI CHÚ</span>
                <span>Note</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrProducts; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ item.product_name }}</td>
            <!-- <td>{{ import_code }}</td> -->
            <td>{{ item.net_weigth | number }}</td>
            <td>{{ item.unit_name }}</td>
            <td>{{ item.quantity | number }}</td>
            <td>{{ item.net_weigth * item.quantity | number }}</td>
            <!-- <td>{{ item.expiry_date | date: 'dd/MM/yyyy' }}</td> -->
            <td class="border-r"></td>
          </tr>
          <tr>
            <td colspan="4">TỔNG CỘNG/TOTAL</td>
            <td>{{ totalQuantity | number }}</td>
            <td>{{ totalNet | number }}</td>
            <td class="border-r"></td>
          </tr>
        </tbody>
      </table>
      <ng-template #hasPX>
        <table
          cellspacing="0"
          cellpadding="0"
          class="table-fixed w-full border-separate"
        >
          <thead>
            <tr>
              <th class="w-30px break-words">STT ID</th>
              <th class="col-name">
                <div class="flex flex-col">
                  <span>Tên hàng</span>
                  <span>Item name</span>
                </div>
              </th>
              <th class="col-code">Số phiếu nhập</th>
              <th class="col-sa">
                <div class="flex flex-col">
                  <span>Qui cách</span>
                  <span>Specific action</span>
                  <span>(KG)</span>
                </div>
              </th>
              <th class="col-unit">
                <div class="flex flex-col">
                  <span>ĐVT</span>
                  <span>Unit</span>
                </div>
              </th>
              <th class="col-quantity">
                <div class="flex flex-col">
                  <span>Số kiện </span>
                  <span>Qantity</span>
                </div>
              </th>
              <th class="col-net">
                <div class="flex flex-col">
                  <span>Trọng lượng </span>
                  <span>Netweight</span>
                  <span>(KG)</span>
                </div>
              </th>
              <th class="col-car border-r">
                <div class="flex flex-col">
                  <span>Số Container </span>
                  <!-- <span>Container Car</span> -->
                </div>
              </th>
              <!-- <th class="col-stock">
                <div class="flex flex-col">
                  <span>Tồn</span>
                  <span>Balance</span>
                </div>
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrProducts; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.product_name }}</td>
              <td>{{ item.import_code }}</td>
              <td>{{ item.net_weigth | number }}</td>
              <td>{{ item.unit_name }}</td>
              <td>{{ item.quantity | number }}</td>
              <td>{{ item.net_weigth * item.quantity | number }}</td>
              <td class="border-r">{{ item.container_code }}</td>
              <!-- <td>{{ item['stock_SKU'] | number }}</td> -->
            </tr>
            <tr>
              <td colspan="5">TỔNG CỘNG/TOTAL</td>
              <td>{{ totalQuantity | number }}</td>
              <td>{{ totalNet | number }}</td>
              <td class="border-r"></td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
    <!-- lệnh -->
    <ng-template #NoisBillOrCommand>
      <div class="table-export mt-10px">
        <table
          *ngIf="
            iexportCode.includes('PN') || iexportCode.includes('PSN');
            else hasPX1
          "
          cellspacing="0"
          cellpadding="0"
          class="table-fixed w-full border-separate"
        >
          <thead>
            <tr>
              <th class="w-30px break-words">STT ID</th>
              <th class="col-name">
                <div class="flex flex-col">
                  <span>Tên hàng</span>
                  <span>Item name</span>
                </div>
              </th>

              <th class="">
                <div class="flex flex-col">
                  <span>Số kiện </span>
                  <span>Qantity</span>
                </div>
              </th>
              <th class="">
                <div class="flex flex-col">
                  <span>Trọng lượng </span>
                  <span>Netweight</span>
                  <span>(KG)</span>
                </div>
              </th>

              <th class="col-net">
                <div class="flex flex-col">
                  <span>Mã ô </span>
                  <span>Cell Code</span>
                </div>
              </th>
              <th class="border-r">
                <div class="flex flex-col">
                  <span>Ngày nhập </span>
                  <span>Receive Date</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrProducts; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.product_name }}</td>

              <td>{{ item.quantity | number }}</td>
              <td>{{ item.net_weigth * item.quantity | number }}</td>
              <td>{{ item.extend_slot_code }}</td>
              <td class="border-r">
                {{ item.receipt_date | date: 'dd/MM/yyyy' }}
              </td>
            </tr>
            <tr>
              <td colspan="2">TỔNG CỘNG/TOTAL</td>
              <td>{{ totalQuantity | number }}</td>
              <td class="">{{ totalNET_QUAN | number }}</td>
              <td class="">{{ totalSlot | number }}</td>
              <td class="border-r"></td>
            </tr>
          </tbody>
        </table>
        <ng-template #hasPX1>
          <table
            cellspacing="0"
            cellpadding="0"
            class="table-fixed w-full border-separate"
          >
            <thead>
              <tr>
                <th class="w-30px break-words">STT ID</th>
                <th class="col-name">
                  <div class="flex flex-col">
                    <span>Tên hàng</span>
                    <span>Item name</span>
                  </div>
                </th>

                <th *ngIf="countInventory > 0" class="">
                  <div class="flex flex-col">
                    <span>Kho</span>
                  </div>
                </th>
                <th class="">
                  <div class="flex flex-col">
                    <span>Số kiện </span>
                    <span>Qantity</span>
                  </div>
                </th>
                <th class="">
                  <div class="flex flex-col">
                    <span>Trọng lượng </span>
                    <span>Netweight</span>
                    <span>(KG)</span>
                  </div>
                </th>

                <th class="col-net">
                  <div class="flex flex-col">
                    <span>Mã ô </span>
                    <span>Cell Code</span>
                  </div>
                </th>
                <th class="border-r">
                  <div class="flex flex-col">
                    <span>Ngày nhập </span>
                    <span>Receive Date</span>
                  </div>
                </th>
                <!-- <th class="border-r">
                  <div class="flex flex-col">
                    <span>Tồn</span>
                    <span>Balance</span>
                  </div>
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrProducts; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.product_name }}</td>
                <td *ngIf="countInventory > 0">
                  {{ item.extend_inventory_name }}
                </td>

                <td>{{ item.quantity | number }}</td>
                <td>{{ item.net_weigth * item.quantity | number }}</td>
                <td>{{ item.extend_slot_code }}</td>
                <td class="border-r">
                  {{ item.receipt_date | date: 'dd/MM/yyyy' }}
                </td>
                <!-- <td >{{ item.slot_quantity | number }}</td> -->
              </tr>
              <tr>
                <td colspan="2">TỔNG CỘNG/TOTAL</td>
                <td class="" *ngIf="countInventory == 0">
                  {{ totalquantity | number }}
                </td>
                <td class="" colspan="2" *ngIf="countInventory > 0">
                  {{ totalquantity | number }}
                </td>
                <td class="">{{ totalnet_quan | number }}</td>
                <td class=""></td>
                <td class="border-r"></td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </div>
    </ng-template>

    <div class="grid grid-cols-12">
      <div class="col-span-12 text-right text-12px font-roboto-regular">
        Thủ Đức, ngày {{ dateNow | date: 'dd' }} tháng
        {{ dateNow | date: 'MM' }} năm {{ dateNow | date: 'yyyy' }}
      </div>
    </div>
    <div class="signature mt-10px">
      <div class="grid grid-cols-12">
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        >
          <span> Thủ kho</span>
          <span> Warehouse keeper</span>
        </div>
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        >
          <span>
            {{
              iexportCode.includes('PN') || iexportCode.includes('PSN')
                ? 'Người giao hàng'
                : 'Người nhận hàng'
            }}
          </span>
          <span>
            {{
              iexportCode.includes('PN') || iexportCode.includes('PSN')
                ? 'Deliver'
                : 'Receiver'
            }}
          </span>
        </div>
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        >
          <span> Bốc xếp</span>
          <span> Loader</span>
        </div>
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        >
          <span> Người lập phiếu</span>
          <span> Prepared by</span>
        </div>

        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        ></div>
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        ></div>
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col"
        ></div>
        <div
          class="col-span-3 text-center font-roboto-regular text-12px flex flex-col mt-90px"
        >
          {{ create_by }}
        </div>
      </div>
    </div>
  </div>

  <!-- Phân bổ -->
  <div
    *ngIf="isPrintDivideQuantity && arrayPrint"
    id="quantityBill"
    class="w-full p-10px"
  >
    <div class="grid grid-cols-12" *ngFor="let item of arrayPrint">
      <div class="col-span-4">
        <img
          src="./assets/icons/hoa-chu-onions.png"
          style="width: 120px; height: 120px"
        />
      </div>
      <div class="col-span-8 grid grid-cols-12">
        <div
          class="title col-span-12 text-center flex items-center justify-self-center text-30px font-roboto-medium text-dark-grey"
        >
          TỜ PHÂN BỔ VỊ TRÍ
        </div>
        <div
          class="col-span-12 text-center flex items-center justify-self-center text-26px font-roboto-regular text-dark-grey"
        >
          {{ item.imExportCode }}
        </div>
      </div>
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey"
      >
        Khách hàng
      </div>
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey text-right"
      >
        {{ item.supplier_name }}
      </div>
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey"
      >
        Số Xe / Container
      </div>
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey text-right"
      >
        {{ item.receive_car }} / {{ item.container_code }}
      </div>
      <!-- <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey"
      >
        Mã hàng
      </div>
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey text-right"
      >
        {{ item.product_code }}
      </div> -->
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey"
      >
        Ngày nhập
      </div>
      <div
        class="col-span-6 mt-10px text-14px font-roboto-regular text-dark-grey text-right"
      >
        {{ item.created_at | date: 'dd/MM/yyyy' }}
      </div>
      <div class="col-span-12 mt-10px grid grid-cols-12">
        <div class="col-span-4 h-40px border-l border-t">
          <div class="flex items-center justify-center h-full break-words">
            Tên hàng
          </div>
        </div>

        <div class="col-span-8 h-40px border-l border-r border-t">
          <div class="flex items-center justify-center h-full break-words">
            Vị trí Ô
          </div>
        </div>
        <div class="productName col-span-4 h-250px border-l border-t">
          <div
            class="flex items-center justify-center h-full break-words text-30px font-roboto-medium p-20px"
          >
            {{ item.product_name }}
          </div>
        </div>

        <div class="cellLocation col-span-8 h-250px border-l border-r border-t">
          <div
            class="flex items-center justify-center h-full break-words text-30px font-roboto-medium p-20px"
          >
            <!-- {{ stringSlot }} -->
          </div>
        </div>
        <div class="col-span-4 h-40px border-l border-t">
          <div class="flex items-center justify-center h-full break-words">
            Số Kg/ ĐVT
          </div>
        </div>
        <div class="col-span-4 h-40px border-l border-t">
          <div class="flex items-center justify-center h-full break-words">
            Số Kiện (Bao/ Thùng)
          </div>
        </div>
        <div class="col-span-4 h-40px border-l border-r border-t">
          <div class="flex items-center justify-center h-full break-words">
            Trọng Lượng (Kg)
          </div>
        </div>
        <div class="col-span-4 h-150px border-l border-b">
          <div
            class="flex items-center justify-center h-full break-words text-30px font-roboto-medium p-20px"
          >
            {{ item.net_weigth | number }}
          </div>
        </div>
        <div class="col-span-4 h-150px border-l border-b">
          <div
            class="flex items-center justify-center h-full break-words text-30px font-roboto-medium p-20px"
          >
            {{ item.quantity | number }}
          </div>
        </div>
        <div class="col-span-4 h-150px border-l border-r border-b">
          <div
            class="flex items-center justify-center h-full break-words text-30px font-roboto-medium p-20px"
          >
            {{ item.quantity * item.net_weigth | number }}
          </div>
        </div>
      </div>
      <!-- {{ item.imExportCode }} -->
      <div
        class="col-span-12 text-center flex items-center justify-self-center text-26px font-roboto-regular text-dark-grey"
      >
        {{ item.imExportCode }}
      </div>
    </div>
  </div>
</div>
