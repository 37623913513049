import { map, filter } from 'rxjs/operators';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ImportExportHistoryService,
  SlotService,
  StockAvailableService,
} from 'src/app';

@Component({
  selector: 'meu-cell-location-modal',
  templateUrl: './cell-location-modal.component.html',
  styleUrls: ['./cell-location-modal.component.scss'],
})
export class CellLocationModalComponent implements OnInit {
  @Input() lstCell;
  @Input() inventoryID;
  @Input() selectedProduct: string;
  @Input() selectedSku: string;
  @Output() closeModal = new EventEmitter();
  selectAll: boolean = true;
  @Input() isExport: boolean;

  constructor(
    private importExportHistoryService: ImportExportHistoryService,
    private slotService: SlotService,
    private stockAvailableService: StockAvailableService
  ) {}

  ngOnInit(): void {
    this.selectedCell();
  }

  selectedCell() {
    if (this.isExport === true) {
      this.lstCell.forEach((element) => {
        element.isSelected = true;
      });
    } else {
      let temp = this.lstCell;
      let items;
      this.selectAll = false;
      this.slotService
        .apiSlotsGet('used_percent<100', 'code', 1, 10000000, this.inventoryID)
        .subscribe((res) => {
          if (res.success === true) {
            this.lstCell = res.data.collection;
            this.filterCellWithProduction(this.lstCell);
          }
        });
    }
  }

  filterCellWithProduction(lstCell) {
    lstCell.forEach((element) => {
      let temp = element.extend_stock_available;
      temp.forEach((element1) => {
        if (
          element1.product_id === this.selectedProduct &&
          element1.quantity > 0 &&
          element1.sku_id === this.selectedSku
        ) {
          let index = lstCell.indexOf(element);
          if (index > -1) {
            lstCell.splice(index, 1);
          }
        }
      });
    });
  }

  submit() {
    this.closeModalCell(this.lstCell);
  }
  closeModalCell(event) {
    this.closeModal.emit(event);
  }
  onChange(event) {
    if (this.selectAll === true) {
      this.lstCell.forEach((element) => {
        element.isSelected = true;
      });
    } else {
      this.lstCell.forEach((element) => {
        element.isSelected = false;
      });
    }
  }
}
