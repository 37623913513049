import { ToastrService } from 'src/app/core/services/toastr.service';
import { filter, take } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductTemplateTree } from './../../../model/productTemplateTree';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  TreeNode,
  TreeModel,
  TREE_ACTIONS,
  KEYS,
  IActionMapping,
  ITreeOptions,
} from '@circlon/angular-tree-component';
import { ProductTemplateTreeService } from '../../../api/productTemplateTree.service';
import { ConfirmService } from 'src/app/core/services';

let id_product = '';
let node_name = '';
let id_parent = '';

const actionMapping: IActionMapping = {
  mouse: {
    // contextMenu: (tree, node, $event) => {
    //   $event.preventDefault();
    //   alert(`context menu for ${node.data.name}`);
    // },
    dblClick: (tree, node, $event) => {
      if (node.hasChildren) {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
      id_product = node.data.id;
    },
    click: (tree, node, $event) => {
      $event.shiftKey
        ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
        : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
      id_product = node.data.id;
      node_name = node.data.name;
      id_parent = node.data.parent_id;
      // console.log('id cha', id_parent);
    },
    // mouseOver: (tree, node, $event) => {
    //   $event.preventDefault();
    //   //console.log(`mouseOver ${node.data.name}`);
    // },

    // mouseOut: (tree, node, $event) => {
    //   $event.preventDefault();
    //   console.log(`mouseOut ${node.data.name}`);
    // },
  },
  keys: {
    [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`),
  },
};

@Component({
  selector: 'meu-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent implements OnInit, OnChanges {
  @Input() isRender: boolean;
  @Output() current_folder_id = new EventEmitter<string>();
  @Output() nodeCurrent = new EventEmitter();
  @Output() isHasIdParent;
  @Output() newFolder = new EventEmitter<string>();
  @Output() reName = new EventEmitter<string>();
  @Output() newFolderData = new EventEmitter();
  isOptionFolder: boolean;
  isCreateNewFolder: boolean;
  productTemplateTree: ProductTemplateTree = {};
  hideElement: boolean;
  hideDropdown: boolean;
  nameParent: string;
  treeData = [];
  nodes = [];
  selected_node_id = '';
  titleMessage: string;
  toastAlert: boolean;
  toastSucces: boolean;

  constructor(
    private templateTreeService: ProductTemplateTreeService,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmService: ConfirmService,
    private toastService: ToastrService
  ) {}

  formcreate = new FormGroup({
    name: new FormControl('', Validators.required),
    parent_id: new FormControl(''),
  });

  ngOnInit(): void {
    this.isOptionFolder = false;
    this.isCreateNewFolder = false;
    this.current_folder_id.emit();
    this.nodeCurrent.emit();
    this.hideElement = false;
    this.hideDropdown = false;
    this.toastAlert = false;
    this.toastSucces = false;
    //this.getTreeData();
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes.isRender);
    if (changes.isRender) {
      //console.log('change1');
      this.getTreeData();
    }
  }
  options: ITreeOptions = {
    actionMapping,
    //useVirtualScroll: true,
  };

  go(node) {
    //console.log(node);
    if (node) {
      this.current_folder_id.emit(node.id);
      this.selected_node_id = node.id;
      this.nodeCurrent.emit(node);
      //console.log(node.name);
    } else {
      this.current_folder_id.emit('');
      this.selected_node_id = '';
      this.nodeCurrent.emit('');
      this.getTreeData();
    }
  }

  defaultId() {
    this.current_folder_id.emit('');
    // console.log(
    //   this.current_folder_id.subscribe((data) => {
    //     console.log('++++++++>', data);
    //   })
    // );
    this.nodeCurrent.emit('');
    this.selected_node_id = '';
  }

  listToTree(arr = []) {
    let map = {},
      node,
      res = [],
      i;
    for (i = 0; i < arr.length; i += 1) {
      map[arr[i].id] = i;
      arr[i]['children'] = [];
    }
    //console.log(arr);
    for (i = 0; i < arr.length; i += 1) {
      node = arr[i];
      if (node.parent_id !== null) {
        //console.log(node.id, node.parent_id);
        arr[map[node.parent_id]].children.push(node);
      } else {
        res.push(node);
      }
    }
    return res;
  }

  async getTreeData() {
    const pageSize = 100000;
    this.templateTreeService
      .apiProductTemplateTreeGet('', '', 1, 1000000)
      .subscribe((res) => {
        if (res.success) {
          // console.log('----------->>>>>>', res);
          this.treeData = res.data.collection;
          this.nodes = this.listToTree(this.treeData);
          // console.log(this.nodes);
        }
      });
  }

  addNewFolder() {
    this.newFolder.emit(null);
  }

  PostNewFolder() {
    this.productTemplateTree.name = this.formcreate.controls['name'].value;
    this.productTemplateTree.parent_id = id_product;
    this.templateTreeService
      .apiProductTemplateTreePost(this.productTemplateTree)
      .subscribe((data) => {
        if (data.success) {
          this.isCreateNewFolder = false;
          this.getTreeData();
        }
      });
  }

  renameFolder(node) {
    this.reName.emit(node);
  }

  deleteFolder() {
    this.templateTreeService
      .apiProductTemplateTreeIdDelete(id_product)
      .subscribe((data) => {
        if (data.success) {
          this.getTreeData();
        }
      });
  }
  deleteconfirm(id) {
    let title = 'Xóa phân loại';
    let content = 'Bạn có chắc chắn muốn xóa phân loại này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.templateTreeService
            .apiProductTemplateTreeIdDelete(id)
            .subscribe((res) => {
              if (res.success == true) {
                this.getTreeData();
                this.toastService.success(res.message);
              } else {
                this.toastService.error(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
}
