<div class="container">
    <div class="grid grid-cols-2 my-4">
        <div class="col-span-1 font-roboto-medium text-20px text-dark-grey flex items-center">
            {{isImport?'Nhập kho':'Xuất kho'}}
        </div>
        <div class="col-span-1 flex justify-end items-center">
            <button class="btn-add" (click)="openImExport()">
                <img src="assets/icons/handle-icons/icon_add.svg" alt="">
                Thêm mới
            </button>
        </div>
    </div>
    <div class="mb-4">
        <div class="search-box">
            <img src="assets/icons/handle-icons/Popular_search.svg" alt="">
            <input 
                type="text"
                class="w-full outline-none border-none pl-1 text-14px font-roboto-regular"
                (keyup)="keyUp.next($event.target.value)"
                [(ngModel)]="searchField"
                placeholder="Tìm phiếu nhập kho">
            <img src="assets/icons/handle-icons/u_times.svg" alt="" (click)="clearSearch()">
        </div>
    </div>
    <div class="mb-4 grid grid-cols-5 gap-2">
        <div class="flex col-span-4">
            <span class="font-roboto-regular text-14px flex items-center mr-1 whitespace-nowrap">Thời gian: </span>
            <div class="search-box custom-ngx-daterangepicker">
                <input 
                    type="text"
                    class="w-full outline-none border-none pl-1 text-14px font-roboto-regular"
                    readonly
                    [opens]="'right'"
                    ngxDaterangepickerMd
                    [closeOnAutoApply]="true"
                    [autoApply]="true"
                    [locale]="{format: 'DD/MM/YYYY'}"
                    [placeholder]="firstDay | date: 'dd/MM/yyyy'"
                    [(ngModel)]="selectedDate"
                    name="rangedatepicker"
                    startKey="startDate"
                    endKey="endDate"
                    >
                <img src="assets/icons/dashboard-icons/icon_calendar_quarter.svg" class="ngx-daterangepicker-action" alt="start" (click)="openDatePicker()">
            </div>
        </div>
        <div class="flex col-span-1">
            <button class="btn-filter" (click)="filterDate()">
                Lọc
            </button>
        </div>
    </div>
    <div class="grid grid-cols-3 wrapper-items mb-4 h-88px" *ngFor="let element of lstImExportCode" (click)="openDetail(element)">
        <div class="col-span-2 flex flex-col justify-between">
            <div class="imexport-code">
                {{element.imExportCode}}
            </div>
            <div class="container-imexport" *ngIf="isImport">
                Số container: {{element.container_code}}
            </div>
            <div class="container-imexport" *ngIf="!isImport">
                Khách hàng: {{element.extend_receiver_name}}
            </div>
            <div class="date-imexport">
                {{element.issue_date ? (element.issue_date| date: 'dd/MM/yyyy HH:mm') : (element.receipt_date| date: 'dd/MM/yyyy HH:mm')}}
            </div>
        </div>
        <div class="col-span-1 flex flex-col justify-between">
            <div class="flex items-center justify-end">
                <div class="status-draft" *ngIf="element.is_draft">
                    Lưu nháp
                </div>
                <div class="status-success" *ngIf="!element.is_draft && element.isImExported">
                    Đã nhập
                </div>
                <div class="status-export" *ngIf="!element.is_draft && !element.isImExported">
                    Đã xuất
                </div>
            </div>
            <div class="flex items-center justify-end text-grey-light text-12px">
               Đã kiểm kho
               <input 
                type="checkbox"
                name="checked" 
                id="checked" 
                class="ml-1 border-grey-light" 
                [checked]="element.is_checked"
                (click)="noChange()">
            </div>
        </div>
    </div>
    <div class="flex w-full justify-center items-center text-grey-light text-12px" *ngIf="lstImExportCode.length===0">Không có phiếu nhập</div>
    <button 
        *ngIf="lstImExportCode.length>0 && maxLoadMore > 0" 
        class="load-more"
        (click)="loadingMore()"
        [disabled]="isDisable"
    >
        Xem thêm
    </button>
</div>
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheetImport"
  [options]="optionsBottomImport"
  [title]="titleModalImport"
  *ngIf="isOpenBottomsheetImport"
>
  <meu-import-history
    (closeModalImport)="closeModalImport($event)"
    [isTransferCar]="false"
    [imExport_code]="import_code"
  ></meu-import-history>
</meu-modal-popup-bottom>
<meu-modal-popup-bottom
  [isOpen]="isOpenBottomsheetExport"
  [options]="optionsBottomExport"
  [title]="titleModalExport"
  *ngIf="isOpenBottomsheetExport"
>
  <meu-export-history
    [isTransferCar]="false"
    (closeModalExport)="closeModalExport($event)"
    [imExport_code]="export_code"
  ></meu-export-history>
</meu-modal-popup-bottom>