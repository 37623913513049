import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { SupplierService } from 'src/app/api/supplier.service';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { Supplier } from 'src/app/model/supplier';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { ToastrService } from 'src/app/core/services';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-suppliers-manage',
  templateUrl: './suppliers-manage.component.html',
  styleUrls: ['./suppliers-manage.component.scss'],
})
export class SuppliersManageComponent implements OnInit {
  suppliers: Supplier[] = [];
  array: Supplier[] = [];

  arrayLenght: number = this.array.length;
  indexArray: number = 0;
  totalArray: number;

  titleModal: string;
  idSupplier: string;
  searchSupplier: string = '';
  sortName: string = '';
  sort: string = '';

  isOpenModal: boolean = false;
  isOpenBottomsheet: boolean = false;
  optionsBottom: MxBottomPopupOption = {};
  options: MxModalOption = {};

  subject: Subject<any> = new Subject();

  constructor(
    private suppliersService: SupplierService,
    private confirmService: ConfirmService,
    private toastService: ToastrService
  ) {}
  ngOnInit(): void {
    this.getAllSupplier();
    this.search();
    this.optionCallbackModal();
    this.optionCallbackModalBottom();
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getAllSupplier();
  }

  //Search
  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getAllSupplier();
    });
  }
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}

  //Modal
  open(id) {
    if (id) {
      this.idSupplier = id;
      this.titleModal = 'Chỉnh sửa nhà cung cấp';
    } else {
      this.titleModal = 'Thêm mới nhà cung cấp';
    }
    if (window.screen.width < 767) {
      this.isOpenModal = false;
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      }
    } else {
      this.isOpenBottomsheet = false;
      if (!this.isOpenModal) {
        this.isOpenModal = true;
      }
    }
  }
  closeModal(data) {
    if (!data) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
        this.idSupplier = '';
      }
    } else {
      if (data.success === true) {
        if (this.isOpenModal) {
          this.isOpenModal = false;
          this.idSupplier = '';
          this.getAllSupplier();
        }
      } else {
        this.isOpenModal = true;
      }
    }
  }
  closeModalBottom(data) {
    if (!data) {
      if (this.isOpenBottomsheet) {
        this.idSupplier = '';
        this.isOpenBottomsheet = false;
      }
    } else {
      if (data.success == false) {
        if (this.isOpenBottomsheet) {
          this.isOpenBottomsheet = true;
        }
      } else {
        this.isOpenBottomsheet = false;
        this.idSupplier = '';
        this.getAllSupplier();
      }
    }
  }
  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.idSupplier = '';
      this.isOpenModal = false;
    };
  }
  optionCallbackModalBottom() {
    this.optionsBottom.callBackAfterClose = (e) => {
      this.idSupplier = '';
      this.isOpenBottomsheet = false;
    };
  }

  //Get data,paging
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.suppliersService
      .apiSupplierGet(
        `name|code|email|tax_code|phone@=${this.searchSupplier}`,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.suppliers = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  getAllSupplier() {
    this.suppliersService
      .apiSupplierGet(`name|code@=${this.searchSupplier}`)
      .subscribe((data) => {
        if (data.success == true) {
          this.array = data.data.collection;
          this.totalArray = data.data.total;
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  //Delete
  deleteconfirm(id) {
    let title = 'Xóa nhà cung cấp';
    let content = 'Bạn có chắc chắn muốn xóa nhà cung cấp này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.suppliersService.apiSupplierIdDelete(id).subscribe((res) => {
            if (res.success == true) {
              this.toastService.success(res.message);
              this.getAllSupplier();
            } else {
              this.toastService.error(res.message);
              this.getAllSupplier();
            }
          });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
}
