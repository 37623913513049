<div
  class="modal-bg-overlay"
  [ngStyle]="{ 'z-index': option.zIndex }"
  *ngIf="isOpen"
  (click)="closeFromOutside()"
></div>
<div
  class="modal-container {{ option.styleClass }} "
  [ngStyle]="{
    top: option.top,
    display: isOpen ? 'block' : 'none',
    'animation-name':
      option.top === '50%' ? 'centerModalAnimation' : 'modalAnimation'
  }"
  [ngClass]="{ 'top-center': option.top === '50%' }"
>
  <div
    class="border-light-grey border-b border-opacity-40 py-8px px-16px justify-between flex sm:col-span-full"
  >
    <div
      class="font-roboto-medium text-16px font-medium not-italic"
      style="
        color: #505050;
        line-height: 1.5;
        letter-spacing: normal;
        font-stretch: normal;
      "
    >
      {{ title }}
    </div>
    <div>
      <span
        (click)="close()"
        *ngIf="!option.hideCloseButton"
        class="cursor-pointer"
        ><img [src]="'assets/icons/toast/u_close.svg'" alt="icons gray cross"
      /></span>
    </div>
  </div>

  <div class="modal-content" *ngIf="isOpen">
    <ng-content></ng-content>
  </div>
</div>
