import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { SuppliersManageComponent } from './suppliers-manage/suppliers-manage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalCreateSupplierComponent } from './suppliers-manage/modal-create-supplier/modal-create-supplier.component';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';

const routes: Routes = [
  {
    path: 'supplier-list',
    component: SuppliersManageComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
];

@NgModule({
  declarations: [SuppliersManageComponent, ModalCreateSupplierComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class SuppliersModule {}
