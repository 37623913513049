import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ProductTemplate } from 'src/app/model/productTemplate';
import { Unit } from 'src/app/model/unit';
import {
  ProductService,
  UnitService,
  ProductTemplateService,
} from 'src/app/api/api';
import { Product } from 'src/app/model/models';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ConfirmService, ToastrService } from 'src/app/core/services';
import { filter, take } from 'rxjs/operators';
import { MeucurrencyPipe } from 'src/app/shared/pipes/meucurrency.pipe';

@Component({
  selector: 'meu-information-product',
  templateUrl: './information-product.component.html',
  styleUrls: ['./information-product.component.scss'],
})
export class InformationProductComponent implements OnInit {
  idProduct: string = this.route.snapshot.paramMap.get('id');

  @Output() checkUpdate: EventEmitter<any> = new EventEmitter();

  product: Product = {};

  public unitChoose: Unit;
  public productTemplateChoose: ProductTemplate;
  productTemplate: ProductTemplate[] = [];
  unit: Unit[] = [];
  change: boolean = true;
  isCheckStock: boolean;

  urlProduct: string;
  urlUpdate: File;
  urlImage: File = null;

  hideshowButton: boolean;

  //tooltip
  @ViewChild('toolTipUnit', { static: false }) toolTipUnit;
  @ViewChild('toolTipProductTemplate', { static: false })
  toolTipProductTemplate;
  tooltipUnit: string;
  tooltipProductTemplate: string;

  //Load more
  pageNumber_unit: number = 1000;
  pageNumber_proTemplate: number = 1000;

  formUpdate: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private unitService: UnitService,
    private productTemplateService: ProductTemplateService,
    private toastService: ToastrService,
    private confirmService: ConfirmService,
    private meuCurrencyPipe: MeucurrencyPipe
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getUnitForChoose();
    this.getProductTemplateForChoose();
    this.getDetailProduct();
  }

  initForm() {
    this.formUpdate = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      code: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
      ]),
      minimum_stock: new FormControl('', [
        Validators.pattern('[0-9]+(,[0-9]+)*,?'),
      ]),
      maximum_stock: new FormControl('', [
        Validators.pattern('[0-9]+(,[0-9]+)*,?'),
      ]),
      unitName: new FormControl(null, Validators.required),
      productTemplate: new FormControl(null, Validators.required),
      description: new FormControl('', [Validators.maxLength(300)]),
      imagePath: new FormControl(''),
      status: new FormControl(''),
    });
  }

  getDetailProduct() {
    this.productService.apiProductIdGet(this.idProduct).subscribe((res) => {
      if (res.success == true) {
        this.product = this.stock(res.data);
        this.formUpdate.patchValue({
          name: res.data.name,
          code: res.data.code,
          maximum_stock: this.meuCurrencyPipe.transform(
            res.data.maximum_stock_level
          ),
          minimum_stock: this.meuCurrencyPipe.transform(
            res.data.minimum_stock_level
          ),
          unitName: res.data.unit_id ? res.data.unit_id : null,
          productTemplate: res.data.product_template_id
            ? res.data.product_template_id
            : null,
          description: res.data.description,
        });

        this.formUpdate.controls['status'].setValue(
          this.product.is_hidden ? 'Đã ẩn' : 'Đang hoạt động'
        );

        if (this.product.image) {
          this.urlProduct = environment.backendhost + this.product.image;
        }
        this.tooltipUnit = res ? res.data['extend_unit_name'] : null;
        this.tooltipProductTemplate = res
          ? res.data['extend_product_template_name']
          : null;
      } else {
        this.toastService.error('Lỗi không lấy được dữ liệu');
      }
    });
  }

  //Update API
  updateProductDetail() {
    const product = this.formUpdate.value;
    this.product.maximum_stock_level = product.maximum_stock
      ? parseInt(product.maximum_stock.toString().replace(/,/g, ''))
      : null;
    this.product.minimum_stock_level = product.minimum_stock
      ? parseInt(product.minimum_stock.toString().replace(/,/g, ''))
      : null;

    this.product.name = product.name.trim();
    this.product.code = product.code.trim();
    this.product.unit_id = product.unitName;

    this.product.product_template_id = product.productTemplate;
    this.product.description = product.description.trim();
    if (this.product.name == '') {
      this.formUpdate.controls['name'].setValue(null);
    }
    if (this.product.code == '') {
      this.formUpdate.controls['code'].setValue(null);
    }
    if (this.formUpdate.valid) {
      if (
        this.product.maximum_stock_level &&
        this.product.minimum_stock_level
      ) {
        if (
          this.product.maximum_stock_level < this.product.minimum_stock_level
        ) {
          this.isCheckStock = true;
          this.toastService.warnning(
            'Định mức tối thiểu phải nhỏ hơn định mức tối đa'
          );
        } else {
          this.isCheckStock = false;
          this.putProduct(this.idProduct, this.product);
        }
      } else {
        this.isCheckStock = false;
        this.putProduct(this.idProduct, this.product);
      }
    }
  }

  putProduct(id, product) {
    product.code = this.changeCharater(product.code.trim());
    this.productService.apiProductIdPut(id, product).subscribe((res) => {
      if (res.success == true) {
        this.change = true;
        if (this.urlUpdate) {
          this.productService
            .apiProductIdUploadPut(res.data.id, this.urlUpdate)
            .subscribe((res1) => {
              if (res1.success == 'true') {
                this.checkUpdate.emit(true);
                this.toastService.success('Chỉnh sửa hình ảnh thành công');
              } else {
                this.toastService.error('Không thể chỉnh sửa hình ảnh');
              }
            });
        }
        this.checkUpdate.emit(true);
        this.getDetailProduct();
        this.toastService.success(res.message);
      } else {
        if (!this.product.unit_id) {
          this.toastService.warnning('Vui lòng chọn đơn vị tính');
        } else {
          this.toastService.error(res.message);
        }
      }
    });
  }

  hiddenProduct(res) {
    if (res == true) {
      var title = 'Hiện hàng hóa';
      var content = 'Bạn có chắc chắn muốn hiện hàng hóa này ?';
    } else {
      var title = 'Ẩn hàng hóa';
      var content = 'Bạn có chắc chắn muốn ẩn hàng hóa này ?';
    }
    this.confirmService.deleteConfirm(title, content, false);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          if (this.product.is_hidden == true) {
            this.product.is_hidden = false;
          } else {
            this.product.is_hidden = true;
          }
          this.productService
            .apiProductIdPut(this.idProduct, this.product)
            .subscribe((res) => {
              if (res.success == true) {
                this.getDetailProduct();
                // window.location.reload();

                this.toastService.success(res.message);
              } else {
                this.toastService.error(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  //Get array for select unit, productTemp
  getUnitForChoose() {
    this.unitService
      .apiUnitGet('', '', 1, this.pageNumber_unit)
      .subscribe((res) => {
        if (res.success == true) {
          this.unitChoose = res.data.collection;
        }
      });
  }

  getUnit(data) {
    if (this.formUpdate.invalid) {
      this.change = true;
    } else {
      this.change = false;
    }
    this.toolTipUnit.hide();
    this.tooltipUnit = data ? data.name : null;
  }
  getProductTemplateForChoose() {
    this.productTemplateService
      .apiProductTemplateGet('', '', 1, this.pageNumber_proTemplate)
      .subscribe((res) => {
        if (res.success == true) {
          this.productTemplateChoose = res.data.collection;
        }
      });
  }

  getproductTemplate(data) {
    if (this.formUpdate.invalid) {
      this.change = true;
    } else {
      this.change = false;
    }
    this.toolTipProductTemplate.hide();
    this.tooltipProductTemplate = data ? data.name : null;
  }

  //Get sum stock
  stock(product: Product) {
    var stock = 0;

    product['extend_sku'].forEach((p) => {
      stock += p.stock;
    });
    Object.assign(product, { stock: stock });

    return product;
  }

  //Update image
  getImage(event: FileList) {
    if (this.formUpdate.invalid) {
      this.change = true;
      this.urlProduct = '';
      let file: File = event[0];
      var reader = new FileReader();
      if (file.type.includes('image/')) {
        reader.onload = (event: any) => {
          this.urlImage = event.target.result;
          this.urlUpdate = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.toastService.error('Không thể upload ảnh');
      }
    } else {
      this.change = false;
      this.urlProduct = '';
      let file: File = event[0];
      var reader = new FileReader();
      if (file.type.includes('image/')) {
        reader.onload = (event: any) => {
          this.urlImage = event.target.result;
          this.urlUpdate = file;
        };
        reader.readAsDataURL(file);
      } else {
        this.toastService.error('Không thể upload ảnh');
      }
    }
  }

  //Convert string to number

  onChangeCode(val: string): void {
    if (val != null) {
      val = this.changeCharater(val);
      this.formUpdate.controls['code'].setValue(val);
    }
  }

  cancel() {
    this.change = true;
    // this.formUpdate.reset();
    this.isCheckStock = false;
    this.urlImage = null;
    this.getDetailProduct();
  }

  changeCharater(str) {
    if (str) {
      str = str.replace(/ /g, ' ').toUpperCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
      str = str.replace(/Đ/g, 'D');
      str = str.replace(
        /~|`|!|@|#|%|&|[(]|[)]|[_]|[=]|[-]|[{]|[}]|[|]|[;]|[:]|[']|["]|[,]|[<]|[.]|[>]|[$]|[*]|[+]|[?]|/g,
        ''
      );
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
      str = str.replace(/\u02C6|\u0306|\u031B/g, '');
      return str;
    }
  }

  onChangeValueCurrency(field: string, e: any) {
    const stock = this.formUpdate.value;
    this.formUpdate.invalid ? (this.change = true) : (this.change = false);
    if (isNaN(e.key) && e.key != 'Backspace' && e.key != 'Delete') {
      const currency_stock = stock[`${field}`].toString().replace(/,/g, '');
      // .replace(e.key, '');
      this.formUpdate.controls[`${field}`].setValue(currency_stock);
      return;
    }
  }
}
