import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from 'src/app/api/contract.service';
import { ServiceCostService } from 'src/app/api/serviceCost.service';
import { ServiceCostHistoryService } from 'src/app/api/serviceCostHistory.service';
import { UserService } from 'src/app/api/user.service';
import { ToastrService } from 'src/app/core/services';
import { Contract } from 'src/app/model/contract';
import { ContractServiceCost } from 'src/app/model/contractServiceCost';
import { ServiceCostHistory } from 'src/app/model/serviceCostHistory';
import { User } from 'src/app/model/user';
import { MeucurrencyPipe } from 'src/app/shared/pipes/meucurrency.pipe';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';

@Component({
  selector: 'meu-service-list-modal',
  templateUrl: './service-list-modal.component.html',
  styleUrls: ['./service-list-modal.component.scss'],
})
export class ServiceListModalComponent implements OnInit {
  @Input() service_id: string;
  @Output() closeModalService = new EventEmitter();
  nameButton: string;

  serviceCostHistoryObj: ServiceCostHistory = {};

  //form
  createNewServiceForm = new FormGroup({
    id_customer: new FormControl(null, [Validators.required]),
    id_contract_user: new FormControl(null, [Validators.required]),
    id_service_contract: new FormControl(null, [Validators.required]),
    quantity: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]+(,[0-9]+)*,?'),
    ]),
    unit: new FormControl('--'),
    amount: new FormControl('--'),
  });

  //tooltip
  tooltipUser: string;
  tooltipContract: string;
  tooltipService: string;
  @ViewChild('toolTipUser', { static: false }) toolTipUser;
  @ViewChild('toolTipContract', { static: false })
  toolTipContract;
  @ViewChild('toolTipService', { static: false })
  toolTipService;

  //Array
  allUser: User[];
  allContractUser: Contract[];
  allServiceContract: ContractServiceCost[];

  //check
  isCheckUser: boolean = true;
  isCheckContract: boolean = true;
  isService: boolean;

  inventoryId;

  constructor(
    private userService: UserService,
    private contractService: ContractService,
    private serviceCost: ServiceCostService,
    private serviceCostHistory: ServiceCostHistoryService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStoreService: LocalStoreService
  ) {}

  ngOnInit(): void {
    this.setInventoryId();
    this.setModalAddOrEdit();
    this.getAllUser();
  }
  setModalAddOrEdit() {
    if (this.service_id) {
      this.nameButton = 'Lưu';
      this.getDetailServiceCostHistory();
    } else {
      this.nameButton = 'Thêm';
    }
  }

  setInventoryId() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
    });
    if (this.router.url.split('/')[2]) {
      this.inventoryId = this.router.url.split('/')[2];
    }
    if (
      this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      )
    ) {
      this.inventoryId = this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      );
    }
  }

  //Get detail
  getDetailServiceCostHistory() {
    this.serviceCostHistory
      .apiServiceCostHistoryIdGet(this.service_id)
      .subscribe((res) => {
        if (res.success == true) {
          this.tooltipUser = res ? res.data['extend_customer_name'] : null;
          this.tooltipService = res ? res.data['extend_service_name'] : null;

          this.contractService
            .apiContractIdGet(res.data.contract_id)
            .subscribe((resp) => {
              if (resp.success == true) {
                this.createNewServiceForm.patchValue({
                  id_contract_user: res.data.contract_id
                    ? res.data.contract_id
                    : null,
                  id_service_contract: res.data.service_cost_id
                    ? res.data.service_cost_id
                    : null,
                  id_customer: resp.data.customer_id
                    ? resp.data.customer_id
                    : null,
                  quantity: res.data.quantity ? res.data.quantity : '--',
                });
                this.tooltipContract = resp ? resp.data.code : null;
                this.isCheckUser = false;
                this.isCheckContract = false;
                this.getDetailService(res.data.service_cost_id);
                this.getAllContractUser(resp.data.customer_id);
                this.getAllServiceContract(res.data.contract_id);
              }
            });
        }
      });
  }

  closeModal(data = null) {
    if (data) {
      this.closeModalService.emit(data);

      return;
    }
    this.closeModalService.emit();
  }

  //Handle submit
  submit() {
    const controls = this.createNewServiceForm.controls;
    if (this.createNewServiceForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.createNewServiceForm.markAllAsTouched();

    if (this.service_id) {
      this.onUpdate();
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.serviceCostHistoryObj.contract_id = this.createNewServiceForm.controls[
      'id_contract_user'
    ].value;

    this.serviceCostHistoryObj.service_cost_id = this.createNewServiceForm.controls[
      'id_service_contract'
    ].value;

    this.serviceCostHistoryObj.quantity = this.createNewServiceForm.controls[
      'quantity'
    ].value
      ? this.createNewServiceForm.controls['quantity'].value
      : null;

    this.serviceCostHistoryObj.amount = this.createNewServiceForm.controls[
      'amount'
    ].value
      ? this.createNewServiceForm.controls['amount'].value
      : null;

    this.serviceCostHistory
      .apiServiceCostHistoryPost(this.serviceCostHistoryObj)
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success(res.message);
          this.closeModal(res);
        } else {
          this.closeModal(res);
          this.toastr.error(res.message);
        }
      });
  }

  onUpdate() {
    this.serviceCostHistoryObj.contract_id = this.createNewServiceForm.controls[
      'id_contract_user'
    ].value;

    this.serviceCostHistoryObj.service_cost_id = this.createNewServiceForm.controls[
      'id_service_contract'
    ].value;

    this.serviceCostHistoryObj.quantity = this.createNewServiceForm.controls[
      'quantity'
    ].value
      ? this.createNewServiceForm.controls['quantity'].value
      : null;

    this.serviceCostHistoryObj.amount = this.createNewServiceForm.controls[
      'amount'
    ].value
      ? this.createNewServiceForm.controls['amount'].value
      : null;
    this.serviceCostHistory
      .apiServiceCostHistoryIdPut(this.service_id, this.serviceCostHistoryObj)
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success(res.message);
          this.closeModal(res);
        } else {
          this.closeModal(res);
          this.toastr.error(res.message);
        }
      });
  }

  /// HANDLE SELECT
  getAllUser() {
    this.userService.apiUsersGet('', '', 1, 1000).subscribe((res) => {
      if (res.success == true) {
        this.allUser = res.data.collection;
      }
    });
  }
  getAllContractUser(id) {
    this.contractService
      .apiContractGet(this.inventoryId, `customer_id==${id}`, '', 1, 1000)
      .subscribe((res) => {
        if (res.success == true) {
          this.allContractUser = res.data.collection;
        }
      });
  }
  getAllServiceContract(id) {
    this.contractService
      .apiContractServiceCostGet(`contract_id==${id}`, '', 1, 1000) //`contract_id==${id}`
      .subscribe((res) => {
        if (res.success == true) {
          if (res.data.total == 0) {
            this.createNewServiceForm.patchValue({
              unit: '--',
              amount: '--',
            });
          } else {
            this.allServiceContract = res.data.collection;
          }
        }
      });
  }
  getDetailService(id) {
    this.serviceCost.apiServiceCostIdGet(id).subscribe((res) => {
      if (res.success == true) {
        this.createNewServiceForm.patchValue({
          amount: res.data.amount ? res.data.amount : '--',
          unit: res.data.unit ? res.data.unit : '--',
        });
      }
    });
  }
  onChangeUser(data) {
    if (data) {
      this.allContractUser = null;
      this.allServiceContract = null;
      this.isCheckUser = false;
      //reset
      this.createNewServiceForm.controls['id_contract_user'].reset();
      this.createNewServiceForm.controls['id_service_contract'].reset();

      this.getAllContractUser(data.id);
    } else {
      this.allContractUser = null;
      this.allServiceContract = null;
      this.isCheckUser = true;
      this.isCheckContract = true;
      // //reset
      this.createNewServiceForm.patchValue({
        unit: '--',
        amount: '--',
      });
      this.createNewServiceForm.controls['id_contract_user'].reset();
      this.createNewServiceForm.controls['id_service_contract'].reset();
    }
    this.tooltipUser = data ? data.full_name : null;
    this.tooltipContract = null;
    this.tooltipService = null;

    this.toolTipUser.hide();
  }
  onChangeContract(data) {
    if (data) {
      this.allServiceContract = null;
      this.isCheckContract = false;
      this.createNewServiceForm.controls['id_service_contract'].reset();

      this.createNewServiceForm.patchValue({
        unit: '--',
        amount: '--',
      });
      this.getAllServiceContract(data.id);
    } else {
      this.allServiceContract = null;
      this.isCheckContract = true;
      this.createNewServiceForm.patchValue({
        unit: '--',
        amount: '--',
      });
      this.createNewServiceForm.controls['id_service_contract'].reset();
      this.createNewServiceForm.controls['id_service_contract'].reset();
    }
    this.tooltipContract = data ? data.code : null;
    this.tooltipService = null;
    this.toolTipContract.hide();
  }
  onChangeService(data) {
    if (data) {
      this.getDetailService(data.service_cost_id);
    } else {
      this.createNewServiceForm.patchValue({
        amount: '--',
        unit: '--',
      });
    }
    this.tooltipService = data ? data.extend_service_name : null;
    this.toolTipService.hide();
  }
}
