import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { AppProductCategoriesComponent } from './app-product-categories/app-product-categories.component';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { ProductCategoriesCreateComponent } from './product-categories-create/product-categories-create.component';
import { ProductCategoriesDeleteComponent } from './product-categories-delete/product-categories-delete.component';
import { TreeViewEditComponent } from './tree-view-edit/tree-view-edit.component';

const routes: Routes = [
  {
    path: 'product-categories',
    component: AppProductCategoriesComponent,
  },
];

@NgModule({
  declarations: [
    AppProductCategoriesComponent,
    TreeViewComponent,
    ProductCategoriesCreateComponent,
    ProductCategoriesDeleteComponent,
    TreeViewEditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TreeModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class ProductCategoriesModule {}
