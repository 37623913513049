import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  toastr$ = new Subject<any>();
  constructor() {}
  success(message, timeOut?) {
    this.setToastr('success', message, timeOut)
  }
  error(message, timeOut?) {
    this.setToastr('error',message, timeOut)
  }
  warnning(message, timeOut?) {
    this.setToastr('warnning',message, timeOut)
  }
  info(message, timeOut?) {
    this.setToastr('info',message, timeOut)
  }
  private setToastr(type, message,timeOut){
    timeOut = timeOut ? timeOut : 3000;
    this.toastr$.next({ type: type, message: message });
    setTimeout(() => {
      this.toastr$.next(null)
    }, timeOut);
  }
}
