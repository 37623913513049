<div class="sm:grid sm:grid-cols-12 py-8px px-16px h-240px w-440px">
    <div class=" justify-between flex sm:col-span-full border-light-grey border-b border-opacity-40">
      <div class="font-roboto-medium text-16px" style="color: #505050">
        Xóa phân loại
      </div>
      <div>
        <button
          type="button"
          class="focus:outline-none"
          (click)="closeModal()"
        >
          x
        </button>
      </div>
    </div>

    <div class="sm:col-span-full mt-24px">

        <!-- <div class="w-full flex flex-col  sm:grid sm:grid-cols-12 gap-4"> -->
          <div class="sm:col-span-full text-center pt-10px font-roboto-medium text-16px" style="color: #505050">
            <span>Bạn có chắc chắn muốn xóa phân loại này ? </span>  
          </div>
          <div
            class="col-span-full flex-row-reverse flex-wrap gap-8px flex h-64px mt-60px pt-8px items-center"
          >
            <button class="btn-text-cancel"  (click)="closeModal()" >
              Hủy
            </button>   
            <button
            class="btn-red-delete"
            type="button"
            (click)="deleteProductWithId()">
            {{nameButton}}
          </button>
          </div>
        <!-- </div> -->

    </div>
  </div>
