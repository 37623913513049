<div *ngIf="!isMobile; else isMobileScreen">
  <div
    class="content overflow-y-auto overflow-x-hidden"
    style="max-height: 80vh"
  >
    <form [formGroup]="formImport">
      <div class="grid grid-cols-12 gap-4 mt-20px">
        <div class="md:col-span-3 col-span-full ml-20px div-info">
          <div
            class="flex h-full flex-grow-0 rounded-lg border border-solid border-grayscale-light-grey bg-white"
          >
            <div class="col-span-full flex">
              <div
                class="h-full w-5px bg-grayscale-light-grey rounded-l-md"
                *ngIf="!imExport_code"
              ></div>

              <div
                class="h-full w-5px rounded-l-md bg-grayscale-light-grey cursor-pointer"
                *ngIf="imExport_code && is_draft"
                tooltip="Phiếu nháp"
                placement="top"
              ></div>

              <div
                class="h-full w-5px rounded-l-md bg-green-blue"
                *ngIf="imExport_code && !is_draft && is_checked"
              ></div>

              <div
                class="h-full w-5px rounded-l-md bg-grayscale-light-grey"
                *ngIf="imExport_code && !is_draft && !is_checked"
              ></div>
            </div>

            <div
              class="flex w-full flex-col xl:flex-row xl:justify-between py-16px px-10px items-center"
            >
              <div class="flex flex-col cursor-pointer">
                <span
                  class="text-16px font-roboto-medium text-primary-dodger-blue"
                  >{{ titleImport }}</span
                >
                <div class="grid">
                  <span
                    class="text-20px font-roboto-medium text-dark-grey"
                    tooltip="Mã phiếu nhập"
                    >{{ imExport_code ? imExport_code : '---' }}</span
                  >

                  <span
                    class="text-14px font-roboto-medium text-dark-grey"
                    tooltip="Ngày tạo"
                    >{{
                      (create_at ? create_at : currentDate)
                        | date: 'dd/MM/yyyy HH:mm'
                    }}
                  </span>
                </div>
              </div>
              <div
                class="col-span-4 opacity-100"
                *ngIf="imExport_code; else showQRCode"
              >
                <qrcode
                  [qrdata]="imExport_code"
                  [width]="100"
                  [errorCorrectionLevel]="'M'"
                  [elementType]="'svg'"
                  [margin]="0"
                ></qrcode>
              </div>
              <ng-template #showQRCode>
                <div class="col-span-4 opacity-60">
                  <qrcode
                    [qrdata]="qrImportData"
                    [width]="100"
                    [errorCorrectionLevel]="'M'"
                    [elementType]="'svg'"
                    [margin]="0"
                  ></qrcode>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div
          class="md:col-span-9 col-span-full mr-20px -mt-6px flex flex-col xl:justify-between"
        >
          <div class="grid grid-cols-5 gap-4 div-select-info">
            <div class="md:col-span-1 col-span-full">
              <span class="font-roboto-regular text-12px text-grey-label">
                Khách hàng <span class="text-red-light">*</span></span
              >
              <div
                class="relative w-full"
                clickOutside
                (clickOutside)="closeOutsideCustomer()"
              >
                <div
                  class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                  (click)="openCustomer = !openCustomer"
                  [ngClass]="{
                    'text-grey': !customer?.full_name
                  }"
                >
                  <img
                    *ngIf="customer?.avatar"
                    [src]="customer?.avatar"
                    alt=""
                    class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
                  />
                  <span
                    class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                    [tooltip]="customer?.full_name || 'Chọn khách hàng'"
                  >
                    {{ customer?.full_name || 'Chọn khách hàng' }}
                  </span>

                  <div class="mt-2px relative right-5px">
                    <svg
                      fill="#4d4d50"
                      viewBox="0 0 20 20"
                      [ngClass]="{
                        'rotate-180': openCustomer,
                        'rotate-0': !openCustomer
                      }"
                      class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div
                  class="absolute right-0"
                  [ngClass]="{ hidden: !openCustomer }"
                  style="z-index: 9999"
                >
                  <div
                    class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box custom-scroll max-h-248px"
                    *ngIf="
                      (is_draft == false && lstPermission.includes('ADMIN')) ||
                      is_draft == true ||
                      is_draft == null
                    "
                  >
                    <div
                      class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                    >
                      <img
                        class="w-20px h-20px m-10px"
                        src="../../../../assets/icons/handle-icons/Icons_search.svg"
                      />
                      <input
                        class="w-full px-5px border-none focus:outline-none text-14px"
                        placeholder="Nhập tìm kiếm..."
                        [value]="clearSearchCustomer"
                        (keyup)="keyUpCustomer.next($event.target.value)"
                      />
                    </div>
                    <a
                      *ngFor="let item of allCustomers"
                      class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseCustomer(item)"
                    >
                      <img
                        [src]="item.avatar"
                        alt=""
                        class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
                      />
                      <span
                        class="text-14px ml-10px text-gray ellipsis"
                        [tooltip]="item.full_name"
                      >
                        {{ item.full_name }}
                      </span>
                    </a>

                    <a
                      *ngIf="allCustomers.length == 0"
                      class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                    >
                      <span class="text-14px ml-10px text-grey-med ellipsis">
                        Không tìm thấy kết quả
                      </span>
                    </a>
                    <a
                      *ngIf="allCustomers.length == 0 && textSearchCustomer"
                      class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="navigateSSO()"
                    >
                      <span
                        class="text-14px ml-10px text-primary-dodger-blue ellipsis"
                      >
                        + Thêm mới "{{ textSearchCustomer }}"
                      </span>
                    </a>

                    <div
                      [ngClass]="
                        pageCustomer < totalCustomer ? 'block' : 'hidden'
                      "
                      (click)="loadMoreCustomer()"
                      class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                    >
                      Xem thêm
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="md:col-span-1 col-span-full div-creater">
              <span class="font-roboto-regular text-12px text-grey-label">
                Mã hợp đồng <span class="text-red-light">*</span></span
              >

              <div
                class="relative w-full"
                clickOutside
                (clickOutside)="closeOutsideContract()"
              >
                <div
                  class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                  (click)="openContract = !openContract"
                  [ngClass]="{
                    'text-grey': !contract?.code
                  }"
                >
                  <span
                    class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                    [tooltip]="contract?.code || 'Chọn mã hợp đồng'"
                  >
                    {{ contract?.code || 'Chọn mã hợp đồng' }}
                  </span>

                  <div class="mt-2px relative right-5px">
                    <svg
                      fill="#4d4d50"
                      viewBox="0 0 20 20"
                      [ngClass]="{
                        'rotate-180': openContract,
                        'rotate-0': !openContract
                      }"
                      class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div
                  class="absolute right-0"
                  [ngClass]="{ hidden: !openContract }"
                  style="z-index: 9999"
                >
                  <div
                    class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box custom-scroll max-h-248px"
                    *ngIf="
                      (is_draft == false && lstPermission.includes('ADMIN')) ||
                      is_draft == true ||
                      is_draft == null
                    "
                  >
                    <div
                      class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                    >
                      <img
                        class="w-20px h-20px m-10px"
                        src="../../../../assets/icons/handle-icons/Icons_search.svg"
                      />
                      <input
                        class="w-full px-5px border-none focus:outline-none text-14px"
                        placeholder="Nhập tìm kiếm..."
                        [value]="clearSearchContract"
                        (keyup)="keyUpContract.next($event.target.value)"
                      />
                    </div>
                    <a
                      *ngFor="let item of allContracts"
                      class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseContract(item)"
                    >
                      <span
                        class="text-14px ml-10px text-gray ellipsis"
                        [tooltip]="item.code"
                      >
                        {{ item.code }}
                      </span>
                    </a>

                    <a
                      *ngIf="allContracts.length == 0"
                      class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                    >
                      <span class="text-14px ml-10px text-grey-med ellipsis">
                        Không tìm thấy kết quả
                      </span>
                    </a>
                    <a
                      *ngIf="allContracts.length == 0 && textSearchContract"
                      class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseSearchContract(textSearchContract)"
                    >
                      <span
                        class="text-14px ml-10px text-primary-dodger-blue ellipsis"
                      >
                        + Thêm mới "{{ textSearchContract }}"
                      </span>
                    </a>

                    <div
                      [ngClass]="
                        pageContract < totalContract ? 'block' : 'hidden'
                      "
                      (click)="loadMoreContract()"
                      class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                    >
                      Xem thêm
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="md:col-span-1 col-span-full div-creater">
              <span class="font-roboto-regular text-12px text-grey-label">
                Người lập phiếu</span
              >

              <div class="relative" [tooltip]="created_by">
                <input
                  type="text"
                  class="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap mt-8px w-full h-32px border mb-4px rounded-4px bg-white border-grayscale-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px pr-8px focus:ring-1 focus:ring-primary-blue appearance-none font-roboto-regular text-14px text-dark-grey"
                  disabled
                  [(ngModel)]="created_by"
                  [ngModelOptions]="{ standalone: true }"
                />
                <img
                  class="absolute ml-10px cursor-pointer top-14px rounded-full w-20px h-20px"
                  [src]="created_avatar"
                  alt=""
                />
              </div>
            </div>

            <div class="md:col-span-1 col-span-full">
              <span class="font-roboto-regular text-12px text-grey-label">
                Người kiểm kê</span
              >

              <div
                class="relative w-full"
                clickOutside
                (clickOutside)="closeOutsideImplementer()"
              >
                <div
                  class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                  (click)="openImplementer = !openImplementer"
                  [ngClass]="{
                    'text-grey': !implementer?.full_name
                  }"
                >
                  <img
                    *ngIf="implementer?.avatar"
                    [src]="implementer?.avatar"
                    alt=""
                    class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
                  />
                  <span
                    class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                    [tooltip]="implementer?.full_name || 'Chọn người kiểm kê'"
                  >
                    {{ implementer?.full_name || 'Chọn người kiểm kê' }}
                  </span>

                  <div class="mt-2px relative right-5px">
                    <svg
                      fill="#4d4d50"
                      viewBox="0 0 20 20"
                      [ngClass]="{
                        'rotate-180': openImplementer,
                        'rotate-0': !openImplementer
                      }"
                      class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div
                  class="absolute right-0"
                  [ngClass]="{ hidden: !openImplementer }"
                  style="z-index: 9999"
                >
                  <div
                    class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box custom-scroll max-h-248px"
                    *ngIf="
                      (is_draft == false && lstPermission.includes('ADMIN')) ||
                      is_draft == true ||
                      is_draft == null
                    "
                  >
                    <div
                      class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                    >
                      <img
                        class="w-20px h-20px m-10px"
                        src="../../../../assets/icons/handle-icons/Icons_search.svg"
                      />
                      <input
                        class="w-full px-5px border-none focus:outline-none text-14px"
                        placeholder="Nhập tìm kiếm..."
                        [value]="clearSearchImplementer"
                        (keyup)="keyUpImplementer.next($event.target.value)"
                      />
                    </div>
                    <a
                      *ngFor="let item of allImplemeters"
                      class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseImplementer(item)"
                    >
                      <img
                        [src]="item.avatar"
                        alt=""
                        class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
                      />
                      <span
                        class="text-14px ml-10px text-gray ellipsis"
                        [tooltip]="item.full_name"
                      >
                        {{ item.full_name }}
                      </span>
                    </a>
                    <a
                      *ngIf="allImplemeters.length == 0"
                      class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                    >
                      <span class="text-14px ml-10px text-grey-med ellipsis">
                        Không tìm thấy kết quả
                      </span>
                    </a>
                    <div
                      [ngClass]="
                        pageImplementer < totalImplementer ? 'block' : 'hidden'
                      "
                      (click)="loadMoreImplementer()"
                      class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                    >
                      Xem thêm
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="md:col-span-1 col-span-full div-receiptdate">
              <span
                class="font-roboto-regular text-12px text-grey-label overflow-hidden overflow-ellipsis whitespace-nowrap"
              >
                Số container<span class="text-red-light">*</span></span
              >
              <input
                type="text"
                class="class-valid w-full border rounded-4px py-8px pl-10px pr-8px h-32px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                formControlName="container_code"
                placeholder="Số container"
                [attr.disabled]="is_checked == true ? true : null"
              />
            </div>

            <div class="md:col-span-1 col-span-full div-receiptdate hidden">
              <span class="font-roboto-regular text-12px text-grey-label">
                Ngày nhập kho</span
              >
              <input
                #dayPicker
                type="text"
                #dateToDp="dpDayPicker"
                [dpDayPicker]="datePickerConfig"
                [theme]="'dp-material'"
                formControlName="receipt_date"
                [mode]="'daytime'"
                (onChange)="getDateTime($event)"
                placeholder="DD/MM/YYYY HH:mm"
                class="class-valid overflow-ellipsis overflow-hidden whitespace-nowrap cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-32px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
              />
            </div>
          </div>

          <div class="grid grid-cols-5 gap-4 div-input-info">
            <div class="md:col-span-1 col-span-full">
              <span class="font-roboto-regular text-12px text-grey-label">
                Người nhận <span class="text-red-light">*</span></span
              >

              <div
                class="relative w-full"
                clickOutside
                (clickOutside)="closeOutsideReceiver()"
              >
                <div
                  class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                  (click)="openReceiver = !openReceiver"
                  [ngClass]="{
                    'text-grey': !receiver?.full_name
                  }"
                >
                  <img
                    *ngIf="receiver?.avatar"
                    [src]="receiver?.avatar"
                    alt=""
                    class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
                  />
                  <span
                    class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                    [tooltip]="receiver?.full_name || 'Chọn người nhận'"
                  >
                    {{ receiver?.full_name || 'Chọn người nhận' }}
                  </span>

                  <div class="mt-2px relative right-5px">
                    <svg
                      fill="#4d4d50"
                      viewBox="0 0 20 20"
                      [ngClass]="{
                        'rotate-180': openReceiver,
                        'rotate-0': !openReceiver
                      }"
                      class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div
                  class="absolute right-0"
                  [ngClass]="{ hidden: !openReceiver }"
                  style="z-index: 9999"
                >
                  <div
                    class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box-floor2 custom-scroll max-h-200px"
                    *ngIf="
                      (is_draft == false && lstPermission.includes('ADMIN')) ||
                      is_draft == true ||
                      is_draft == null
                    "
                  >
                    <div
                      class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                    >
                      <img
                        class="w-20px h-20px m-10px"
                        src="../../../../assets/icons/handle-icons/Icons_search.svg"
                      />
                      <input
                        class="w-full px-5px border-none focus:outline-none text-14px"
                        placeholder="Nhập tìm kiếm..."
                        [value]="clearSearchReceiver"
                        (keyup)="keyUpReceiver.next($event.target.value)"
                      />
                    </div>
                    <a
                      *ngFor="let item of allReceivers"
                      class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseReceiver(item)"
                    >
                      <img
                        [src]="item.avatar"
                        alt=""
                        class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
                      />
                      <span
                        class="text-14px ml-10px text-gray ellipsis"
                        [tooltip]="item.full_name"
                      >
                        {{ item.full_name }}
                      </span>
                    </a>
                    <a
                      *ngIf="allReceivers.length == 0"
                      class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                    >
                      <span class="text-14px ml-10px text-grey-med ellipsis">
                        Không tìm thấy kết quả
                      </span>
                    </a>
                    <a
                      *ngIf="allReceivers.length == 0 && filterReceiver"
                      class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="navigateNewWindowsSSO()"
                    >
                      <span
                        class="text-14px ml-10px text-primary-dodger-blue ellipsis"
                      >
                        + Thêm mới người nhận
                      </span>
                    </a>
                    <div
                      [ngClass]="
                        pageReceiver < totalReceiver ? 'block' : 'hidden'
                      "
                      (click)="loadMoreReceiver()"
                      class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                    >
                      Xem thêm
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md:col-span-1 col-span-full">
              <span class="font-roboto-regular text-12px text-grey-label">
                Nhân viên kinh doanh <span class="text-red-light">*</span></span
              >

              <div
                class="relative w-full"
                clickOutside
                (clickOutside)="closeOutsideSeller()"
              >
                <div
                  class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                  (click)="openSeller = !openSeller"
                  [ngClass]="{
                    'text-grey': !seller?.full_name
                  }"
                >
                  <img
                    *ngIf="seller?.avatar"
                    [src]="seller?.avatar"
                    alt=""
                    class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
                  />
                  <span
                    class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                    [tooltip]="seller?.full_name || 'Chọn nhân viên'"
                  >
                    {{ seller?.full_name || 'Chọn nhân viên' }}
                  </span>

                  <div class="mt-2px relative right-5px">
                    <svg
                      fill="#4d4d50"
                      viewBox="0 0 20 20"
                      [ngClass]="{
                        'rotate-180': openSeller,
                        'rotate-0': !openSeller
                      }"
                      class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div
                  class="absolute right-0"
                  [ngClass]="{ hidden: !openSeller }"
                  style="z-index: 9999"
                >
                  <div
                    class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box-floor2 custom-scroll max-h-200px"
                    *ngIf="
                      (is_draft == false && lstPermission.includes('ADMIN')) ||
                      is_draft == true ||
                      is_draft == null
                    "
                  >
                    <div
                      class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                    >
                      <img
                        class="w-20px h-20px m-10px"
                        src="../../../../assets/icons/handle-icons/Icons_search.svg"
                      />
                      <input
                        class="w-full px-5px border-none focus:outline-none text-14px"
                        placeholder="Nhập tìm kiếm..."
                        [value]="clearSearchSeller"
                        (keyup)="keyUpSeller.next($event.target.value)"
                      />
                    </div>
                    <a
                      *ngFor="let item of allSellers"
                      class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseSeller(item)"
                    >
                      <img
                        [src]="item.avatar"
                        alt=""
                        class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
                      />
                      <span
                        class="text-14px ml-10px text-gray ellipsis"
                        [tooltip]="item.full_name"
                      >
                        {{ item.full_name }}
                      </span>
                    </a>
                    <a
                      *ngIf="allSellers.length == 0"
                      class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                    >
                      <span class="text-14px ml-10px text-grey-med ellipsis">
                        Không tìm thấy kết quả
                      </span>
                    </a>
                    <a
                      *ngIf="allSellers.length == 0 && filterSeller"
                      class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="navigateNewWindowsSSO()"
                    >
                      <span
                        class="text-14px ml-10px text-primary-dodger-blue ellipsis"
                      >
                        + Thêm mới nhân viên
                      </span>
                    </a>
                    <div
                      [ngClass]="pageSeller < totalSeller ? 'block' : 'hidden'"
                      (click)="loadMoreSeller()"
                      class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                    >
                      Xem thêm
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md:col-span-1 col-span-full flex flex-col justify-end">
              <div class="grid grid-cols-1 gap-4 div-batch-ctn">
                <div class="md:col-span-1">
                  <span
                    class="font-roboto-regular text-12px text-grey-label overflow-hidden overflow-ellipsis whitespace-nowrap"
                  >
                    Số xe</span
                  >
                  <input
                    type="text"
                    class="class-valid w-full border rounded-4px py-8px pl-10px pr-8px h-32px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
                    formControlName="receive_car"
                    placeholder="Số xe"
                  />
                </div>
              </div>
            </div>

            <div class="md:col-span-2 col-span-full flex flex-col justify-end">
              <span class="font-roboto-regular text-12px text-grey-label"
                >Ghi chú
              </span>
              <textarea
                formControlName="note"
                spellcheck="false"
                placeholder="Ghi chú"
                class="w-full border rounded-4px bg-white border-grayscale-light-grey hover:border-primary-blue focus:outline-none py-4px pl-10px pr-8px focus:ring-1 focus:ring-primary-blue mt-8px h-32px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="mx-20px mb-80px mt-10px">
      <div class="min-h-full grid grid-cols-12">
        <div class="flex col-span-full lg:inline">
          <div
            class="lg:grid lg:grid-cols-12 w-full justify-between block md:flex"
          >
            <div class="col-span-6">
              <div
                class="pt-4px font-roboto-medium font-medium text-dark-grey text-16px leading-normal not-italic tracking-normal"
              >
                Danh sách hàng hóa
              </div>
            </div>
            <div class="col-span-6 md:ml-0">
              <div
                class="flex flex-wrap lg:float-right items-center div-search-btn"
              >
                <div
                  class="relative"
                  clickOutside
                  (clickOutside)="openSearchProduct = false"
                >
                  <input
                    type="text"
                    (keyup)="updateSearch()"
                    [(ngModel)]="search"
                    placeholder="Nhập tìm kiếm..."
                    class="m-auto h-32px border mb-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
                  />
                  <img
                    class="absolute ml-12px cursor-pointer top-8px"
                    src="../../../../assets/icons/product-categories-icons/Icons_16_search.svg"
                    alt=""
                  />
                </div>
                <div
                  class="relative w-full"
                  clickOutside
                  (clickOutside)="openSearchProduct = false"
                >
                  <div
                    class="absolute right-0"
                    [ngClass]="{ hidden: !openSearchProduct }"
                    style="z-index: 9999"
                  >
                    <div
                      class="bg-white rounded-md box-shadow-custom float-right w-200px search-box-search-product custom-scroll max-h-150px"
                      *ngIf="is_checked == false || is_checked == null"
                    >
                      <a
                        *ngFor="let item of allSearchProduct"
                        class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-200px"
                        href="javascript:void(0)"
                        (click)="addRowProduct(item)"
                        permission="STAFF"
                      >
                        <span class="text-14px ml-10px text-gray ellipsis">
                          [{{ item.code }}] {{ item.name }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-full w-ful mt-10px">
          <table class="w-full border-collapse">
            <!-- Header -->
            <tr
              class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
            >
              <th class="md:text-center col-no">#</th>

              <th class="text-left col-code items-center cursor-pointer">
                <div class="items-center">Mã hàng hóa</div>
              </th>

              <th class="text-left col-name items-center cursor-pointer">
                <div class="items-center">Tên hàng hóa</div>
              </th>

              <th class="text-left col-sku">
                <div class="items-center">SKU</div>
              </th>

              <th class="text-left col-supplier cursor-pointer">
                <div class="items-center">Nhà cung cấp</div>
              </th>

              <th class="text-right col-quantity cursor-pointer">
                <div class="items-center">Số lượng</div>
              </th>

              <th class="text-left col-unit cursor-pointer">
                <div class="items-center">ĐVT</div>
              </th>

              <th class="text-right col-netWeigth cursor-pointer">
                <div class="items-center">Quy cách</div>
              </th>

              <!-- <th class="text-left col-batch-code cursor-pointer">
                <div class="items-center">Số lô</div>
              </th> -->

              <th class="text-left col-expiry-date cursor-pointer">
                <div class="items-center">Ngày hết hạn</div>
              </th>

              <th class="text-left col-number">
                <div class="items-center">Vị trí ô</div>
              </th>

              <th
                class="text-center col-operation"
                *ngIf="
                  (is_draft == false && lstPermission.includes('ADMIN')) ||
                  is_draft == true ||
                  is_draft == null
                "
              >
                Thao tác
              </th>
            </tr>

            <!-- Body -->
            <tr
              *ngFor="let element of arrProducts; let i = index"
              class="cursor-pointer"
            >
              <td class="td-index text-14px text-center">{{ i + 1 }}</td>

              <td class="td-product-code">
                <div class="grid grid-cols-12 items-center">
                  <div
                    *ngIf="!element.isEdit"
                    class="col-span-12 overflow-hidden overflow-ellipsis text-primary-dodger-blue text-14px"
                    [tooltip]="
                      element.product_code ? element.product_code : '---'
                    "
                  >
                    {{ element.product_code ? element.product_code : '---' }}
                  </div>

                  <div *ngIf="element.isEdit" class="col-span-12">
                    <!--Using items input-->
                    <ng-select
                      [items]="allProducts"
                      bindLabel="code"
                      autofocus
                      bindValue="id"
                      [(ngModel)]="element.selectProduct"
                      class="select-code mt-4px"
                      (change)="
                        getUnitNameAndAllSkusByProductId($event, element, i)
                      "
                      notFoundText="Không có mã nào"
                      #myTooltipProductCode="tooltip"
                      [tooltip]="
                        element.product_code ? element.product_code : '[Trống]'
                      "
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div
                          [tooltip]="item.code"
                          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                        >
                          {{ item.code }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </td>

              <td class="td-product-name">
                <div
                  *ngIf="!element.isEdit"
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
                  [tooltip]="
                    element.product_name ? element.product_name : '---'
                  "
                >
                  {{ element.product_name ? element.product_name : '---' }}
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <div class="col-span-12">
                    <!--Using items input-->
                    <ng-select
                      [items]="allProducts"
                      bindLabel="name"
                      bindValue="id"
                      [(ngModel)]="element.selectProduct"
                      class="select-name mt-4px"
                      (change)="
                        getUnitNameAndAllSkusByProductId($event, element, i)
                      "
                      [addTag]="addProduct"
                      addTagText="+ Thêm hàng hóa mới"
                      [closeOnSelect]="true"
                      notFoundText="Không có tên nào"
                      #myTooltipProductName="tooltip"
                      [tooltip]="
                        element.product_name ? element.product_name : '[Trống]'
                      "
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div
                          [tooltip]="item.name"
                          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                        >
                          {{ item.name }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </td>

              <td class="td-sku-code">
                <div
                  *ngIf="!element.isEdit"
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
                  [tooltip]="element.sku_code ? element.sku_code : '---'"
                >
                  {{ element.sku_code ? element.sku_code : '---' }}
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <div class="col-span-12">
                    <ng-select
                      [items]="element.allSkus"
                      bindLabel="sku_code"
                      bindValue="id"
                      [clearable]="false"
                      [(ngModel)]="element.selectSku"
                      class="select-sku-code mt-4px"
                      (click)="onClickSKU(element)"
                      (change)="getSku($event, element)"
                      notFoundText="Không có mã SKU"
                      [addTag]="addSku"
                      addTagText="+ Thêm mới"
                      [closeOnSelect]="true"
                      #myTooltipSkuCode="tooltip"
                      [tooltip]="
                        element.sku_code ? element.sku_code : '[Trống]'
                      "
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div
                          [tooltip]="item.sku_code"
                          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                        >
                          {{ item.sku_code }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </td>
              <td class="td-supplier">
                <div
                  *ngIf="!element.isEdit"
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
                  [tooltip]="
                    element.supplier_name ? element.supplier_name : '---'
                  "
                >
                  {{ element.supplier_name ? element.supplier_name : '---' }}
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <div class="col-span-12">
                    <!--Using items input-->
                    <ng-select
                      [items]="element.allSkus"
                      bindLabel="extend_supplier_name"
                      bindValue="supplier_id"
                      [clearable]="false"
                      [(ngModel)]="element.extend_supplier_name"
                      class="select-sku-code mt-4px"
                      (click)="onClickSKU(element)"
                      (change)="getSku($event, element)"
                      notFoundText="Không có nhà cung cấp"
                      [addTag]="addSku"
                      addTagText="+ Thêm mới"
                      [closeOnSelect]="true"
                      #myTooltipSkuCode="tooltip"
                      [tooltip]="
                        element.supplier_name
                          ? element.supplier_name
                          : '[Trống]'
                      "
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div
                          [tooltip]="item.extend_supplier_name"
                          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                        >
                          {{ item.extend_supplier_name }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </td>
              <!-- nhà cung cấp -->
              <!-- <td class="td-supplier" style="max-width: 70px">
                <div
                  class="text-14px overflow-ellipsis overflow-hidden whitespace-nowrap div-ellip"
                  *ngIf="!element.isEdit"
                  [tooltip]="
                    element.supplier_name ? element.supplier_name : '---'
                  "
                >
                  {{ element.supplier_name ? element.supplier_name : '---' }}
                </div>

                <div
                  class="items-center overflow-ellipsis overflow-hidden whitespace-nowrap div-ellip"
                  *ngIf="element.isEdit"
                  [tooltip]="element.supplier_name"
                >
                  <label>{{ element.supplier_name }}</label>
                </div>
              </td> -->
              <!-- số lượng -->
              <td class="td-quantity" style="max-width: 60px">
                <div
                  class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
                  *ngIf="!element.isEdit"
                  [tooltip]="element.quantity ? (element.quantity | meucurrency) : '---'"
                >
                  {{ element.quantity ? (element.quantity | meucurrency): '---' }}
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <input
                    type="number"
                    min="1"
                    onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                    class="col-span-12 text-right px-2 h-42px border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
                    [(ngModel)]="element.quantity"
                    (change)="quantityChange(element)"
                  />
                </div>
              </td>
              <!-- đơn vị -->
              <td class="td-unit-name" style="max-width: 70px">
                <div
                  class="text-14px overflow-ellipsis overflow-hidden whitespace-nowrap div-ellip"
                  *ngIf="!element.isEdit"
                  [tooltip]="element.unit_name ? element.unit_name : '---'"
                >
                  {{ element.unit_name ? element.unit_name : '---' }}
                </div>

                <div
                  class="items-center overflow-ellipsis overflow-hidden whitespace-nowrap div-ellip"
                  *ngIf="element.isEdit"
                  [tooltip]="element.unit_name"
                >
                  <label>{{ element.unit_name }}</label>
                </div>
              </td>
              <!-- số Quy cách -->
              <td class="td-netWeigth" style="max-width: 70px">
                <div
                  class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
                  *ngIf="!element.isEdit"
                  [tooltip]="element.net_weigth ? element.net_weigth : '---'"
                >
                  {{ element.net_weigth ? element.net_weigth : '---' }}
                </div>

                <!-- <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <input
                    type="number"
                    min="1"
                    readonly
                    onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                    class="col-span-12 text-right px-2 h-42px border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
                    [(ngModel)]="element.net_weigth"
                  />
                </div> -->
                <div
                  class="items-center overflow-ellipsis overflow-hidden whitespace-nowrap div-ellip text-right"
                  *ngIf="element.isEdit"
                  [tooltip]="element.net_weigth"
                >
                  <label>{{ element.net_weigth }}</label>
                </div>
              </td>
              <!-- số lô -->
              <!-- <td class="td-batch-code" style="max-width: 60px">
                <div
                  class="text-14px overflow-hidden overflow-ellipsis whitespace-nowrap div-ell"
                  *ngIf="!element.isEdit"
                  [tooltip]="element.batch_code ? element.batch_code : '---'"
                >
                  {{ element.batch_code ? element.batch_code : '---' }}
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <input
                    type="text"
                    class="col-span-12 px-2 h-42px border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
                    [(ngModel)]="element.batch_code"
                  />
                </div>
              </td> -->

              <td class="td-expiry-date">
                <div
                  class="text-14px overflow-hidden overflow-ellipsis whitespace-nowrap div-ell-date"
                  *ngIf="!element.isEdit"
                  [tooltip]="element.expiry_date ? element.expiry_date : '---'"
                >
                  {{ element.expiry_date ? element.expiry_date : '---' }}
                </div>

                <div
                  class="grid grid-cols-12 items-center dtpExpiryDate"
                  *ngIf="element.isEdit"
                >
                  <input
                    #dayPicker
                    type="text"
                    #dateExpiryDp="dpDayPicker"
                    [dpDayPicker]="dateExpiryPickerConfig"
                    [theme]="'dp-material'"
                    [mode]="'day'"
                    [(ngModel)]="element.expiry_date"
                    [(ngModel)]="expiry_date"
                    placeholder="Ngày"
                    class="cursor-pointer overflow-ellipsis overflow-hidden whitespace-nowrap col-span-12 px-2 h-42px border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-color-placeholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
                  />
                </div>
              </td>

              <td class="td-hashtag">
                <div *ngIf="!element.isEdit && !element.is_sale" class="flex flex-wrap -ml-5px">
                  <div
                    *ngFor="let tag of element.selectHashtag; index as i"
                    class="w-auto h-auto flex-grow-0 gap-4px rounded-4px border border-solid border-grey-light m-1 py-2px px-4px text-12px"
                  >
                    <label *ngIf="tag.quantity > 0; else show"
                      >{{ tag.code }} ({{ tag.quantity }})</label
                    >
                    <ng-template #show>
                      <label>{{ tag.code }}</label>
                    </ng-template>
                  </div>
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <!-- (onSelect)="onSelect($event, element.selectHashtag)" -->
                  <div class="col-span-12">
                    <tag-input
                      [(ngModel)]="element.selectHashtag"
                      [onlyFromAutocomplete]="true"
                      (onTextChange)="getSlot($event)"
                      (onRemove)="onItemRemoved($event, element)"
                      [placeholder]="'Chọn số ô mới'"
                      [secondaryPlaceholder]="'Chọn số ô'"
                      [identifyBy]="'id'"
                      [displayBy]="'code'"
                      (keyup.enter)="addFirstItem(element)"
                      class="tag-input-slot col-span-12 pl-2 border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey py-12px"
                    >
                      <tag-input-dropdown
                        [identifyBy]="'id'"
                        [displayBy]="'code'"
                        [showDropdownIfEmpty]="true"
                        [autocompleteItems]="arrHashtag"
                        [zIndex]="10000"
                      ></tag-input-dropdown>
                    </tag-input>
                  </div>
                </div>
              </td>

              <td
                permission="STAFF"
                class="td-button"
                *ngIf="
                  (is_draft == false && lstPermission.includes('ADMIN')) ||
                  is_draft ||
                  is_draft == null
                "
              >
                <button
                  class="focus:outline-none xl:mr-5px"
                  *ngIf="element.isEdit"
                  (click)="openSelectCell(i, element)"
                  tooltip="Chọn ô"
                >
                  <img
                    src="../../../../assets/icons/product-categories-icons/u_edit-alt.svg"
                    alt=""
                  />
                </button>
                <button
                  class="focus:outline-none xl:mr-5px"
                  *ngIf="element.isEdit"
                  (click)="saveItem(element, i)"
                >
                  <img
                    src="../../../../assets/icons/handle-icons/u_save_grey.svg"
                    alt=""
                  />
                </button>
                <button
                  class="focus:outline-none"
                  *ngIf="element.isEdit"
                  (click)="cancelEdit(i, element)"
                >
                  <img
                    src="../../../../assets/icons/handle-icons/u_trash-alt.svg"
                    alt=""
                  />
                </button>
                <button
                  class="focus:outline-none xl:mr-5px"
                  *ngIf="!element.isEdit && !element.is_sale"
                  (click)="editItem(element)"
                >
                  <img
                    src="../../../../assets/icons/product-categories-icons/u_edit-alt.svg"
                    alt=""
                  />
                </button>
                <button
                  class="focus:outline-none"
                  *ngIf="!element.isEdit && !element.is_sale"
                  (click)="removeItem(i)"
                >
                  <img
                    src="../../../../assets/icons/product-categories-icons/u_trash-alt.svg"
                    alt=""
                  />
                </button>
              </td>
            </tr>
          </table>
        </div>

        <!-- Add row  *ngIf="is_checked == false || is_checked == null" -->
        <button
          class="bg-white hover:bg-grey-lightest flex relative items-center w-90px h-30px cursor-pointer focus:outline-none"
          (click)="addRowProduct('')"
          *ngIf="
            (is_draft == null || is_draft == true) &&
            (is_checked == null || is_checked == false)
          "
          permission="STAFF"
        >
          <img src="assets/icons/handle-icons/u_plus.svg" alt="" />
          <label
            class="ml-4px font-roboto-medium text-13px font-medium not-italic cursor-pointer tracking-normal text-primary-dark-blue"
            >Thêm hàng</label
          >
        </button>
      </div>
    </div>

    <!-- Button -->
    <div
      class="footer col-span-full gap-8px flex py-15px px-20px justify-between"
      style="
        border-top: 1px solid #f6f6f6;
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 1000;
        background: white;
      "
      *ngIf="!is_sale"
    >
      <div>
        <button
          permission="STAFF"
          class="btn-trash flex items-center gap-2px"
          type="button"
          (click)="removeImport()"
          *ngIf="!is_checked && (imExport_code || is_draft)"
        >
          <img
            src="../../../../assets/icons/handle-icons/u_trash_white.svg"
            alt=""
          />
          Xóa phiếu này
        </button>

      </div>
      <div class="flex-row-reverse flex gap-8px">
        <button
          class="btn-cancel flex items-center gap-2px"
          type="button"
          (click)="closeModal(false)"
        >
          <img src="../../../../assets/icons/handle-icons/u_times.svg" alt="" />
          Đóng
        </button>

        <!-- <button
          permission="STAFF"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          *ngIf="is_draft == false && is_checked == false"
          (click)="saveUncheck()"
        >
          <img src="../../../../assets/icons/handle-icons/u_save.svg" alt="" />
          Lưu
        </button> -->

        <button
          permission="STAFF"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          *ngIf="
            (is_draft == null || is_draft == true) &&
            (is_checked == null || is_checked == false)
          "
          (click)="saveInDraft()"
        >
          <img src="../../../../assets/icons/handle-icons/u_save.svg" alt="" />
          {{ is_draft ? 'Lưu' : 'Lưu nháp' }}
        </button>
        <button
          permission="ADMIN"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          *ngIf="is_draft == false"
          (click)="saveInDraft()"
        >
          <img src="../../../../assets/icons/handle-icons/u_save.svg" alt="" />
          {{ 'Lưu' }}
        </button>

        <button
          permission="STAFF"
          class="btn-primary-save flex items-center gap-2px"
          type="button"
          *ngIf="
            (is_draft == null || is_draft == true) &&
            (is_checked == null || is_checked == false)
          "
          (click)="saveImport()"
          [disabled]="checkImport"
        >
          <img
            src="../../../../assets/icons/handle-icons/fi_download.svg"
            alt=""
          />
          Nhập hàng
        </button>

        <button
          permission="STAFF"
          class="btn-primary-save flex items-center gap-2px"
          type="button"
          *ngIf="is_draft == false && is_checked == false"
          (click)="saveCheck()"
          [disabled]="disableCheck"
        >
          <img
            src="../../../../assets/icons/handle-icons/checkmark.svg"
            alt=""
          />
          Đã kiểm kho
        </button>

        <!-- <button
          class="btn-primary-barcode flex items-center gap-2px"
          type="button"
          [disabled]="!imExport_code"
          (click)="openPrintQR()"
        >
          <img
            src="../../../../assets/icons/handle-icons/u_qrcode-scan.svg"
            alt=""
          />
          In QR code
        </button>
        <button
          permission="STAFF"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          [disabled]="!imExport_code"
          (click)="openModalPrint()"
        >
          In Tờ Phân Bổ
        </button> -->
        <ng-select
          [disabled]="!imExport_code"
          [(ngModel)]="titlePrint"
          [items]="allPrint"
          bindLabel="title"
          [searchable]="false"
          bindValue="title"
          placeholder="In Chứng Từ"
          (change)="optionPrint($event)"
          class="select-arrayPrint focus:outline-none"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </div>
    <!-- Button for Sale -->
    <div
      class="footer col-span-full gap-8px flex py-15px px-20px justify-between"
      style="
        border-top: 1px solid #f6f6f6;
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 1000;
        background: white;
      "
      *ngIf="is_sale"
    >
      <div class="flex-row-reverse flex gap-8px">
        <button
          class="btn-cancel flex items-center gap-2px"
          type="button"
          (click)="closeModal(false)"
        >
          <img src="../../../../assets/icons/handle-icons/u_times.svg" alt="" />
          Đóng
        </button>
      </div>
    </div>
  </div>
</div>
<!-- mobile -->
<ng-template #isMobileScreen>
  <div class="hidden">
    <div class="md:col-span-1 col-span-full">
      <span class="font-roboto-regular text-12px text-grey-label">
        Khách hàng <span class="text-red-light">*</span></span
      >
      <div
        class="relative w-full"
        clickOutside
        (clickOutside)="closeOutsideCustomer()"
      >
        <div
          class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
          (click)="openCustomer = !openCustomer"
          [ngClass]="{
            'text-grey': !customer?.full_name
          }"
        >
          <img
            *ngIf="customer?.avatar"
            [src]="customer?.avatar"
            alt=""
            class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
          />
          <span
            class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
            [tooltip]="customer?.full_name || 'Chọn khách hàng'"
          >
            {{ customer?.full_name || 'Chọn khách hàng' }}
          </span>

          <div class="mt-2px relative right-5px">
            <svg
              fill="#4d4d50"
              viewBox="0 0 20 20"
              [ngClass]="{
                'rotate-180': openCustomer,
                'rotate-0': !openCustomer
              }"
              class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <div
          class="absolute right-0"
          [ngClass]="{ hidden: !openCustomer }"
          style="z-index: 9999"
        >
          <div
            class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box custom-scroll max-h-248px"
            *ngIf="
              (is_draft == false && lstPermission.includes('ADMIN')) ||
              is_draft == true ||
              is_draft == null
            "
          >
            <div
              class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
            >
              <img
                class="w-20px h-20px m-10px"
                src="../../../../assets/icons/handle-icons/Icons_search.svg"
              />
              <input
                class="w-full px-5px border-none focus:outline-none text-14px"
                placeholder="Nhập tìm kiếm..."
                [value]="clearSearchCustomer"
                (keyup)="keyUpCustomer.next($event.target.value)"
              />
            </div>
            <a
              *ngFor="let item of allCustomers"
              class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
              href="javascript:void(0)"
              (click)="chooseCustomer(item)"
            >
              <img
                [src]="item.avatar"
                alt=""
                class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
              />
              <span
                class="text-14px ml-10px text-gray ellipsis"
                [tooltip]="item.full_name"
              >
                {{ item.full_name }}
              </span>
            </a>

            <a
              *ngIf="allCustomers.length == 0"
              class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
              href="javascript:void(0)"
            >
              <span class="text-14px ml-10px text-grey-med ellipsis">
                Không tìm thấy kết quả
              </span>
            </a>
            <a
              *ngIf="allCustomers.length == 0 && textSearchCustomer"
              class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
              href="javascript:void(0)"
              (click)="navigateSSO()"
            >
              <span
                class="text-14px ml-10px text-primary-dodger-blue ellipsis"
              >
                + Thêm mới "{{ textSearchCustomer }}"
              </span>
            </a>

            <div
              [ngClass]="
                pageCustomer < totalCustomer ? 'block' : 'hidden'
              "
              (click)="loadMoreCustomer()"
              class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
            >
              Xem thêm
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="md:col-span-1 col-span-full div-creater">
      <span class="font-roboto-regular text-12px text-grey-label">
        Mã hợp đồng <span class="text-red-light">*</span></span
      >

      <div
        class="relative w-full"
        clickOutside
        (clickOutside)="closeOutsideContract()"
      >
        <div
          class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-32px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
          (click)="openContract = !openContract"
          [ngClass]="{
            'text-grey': !contract?.code
          }"
        >
          <span
            class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
            [tooltip]="contract?.code || 'Chọn mã hợp đồng'"
          >
            {{ contract?.code || 'Chọn mã hợp đồng' }}
          </span>

          <div class="mt-2px relative right-5px">
            <svg
              fill="#4d4d50"
              viewBox="0 0 20 20"
              [ngClass]="{
                'rotate-180': openContract,
                'rotate-0': !openContract
              }"
              class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <div
          class="absolute right-0"
          [ngClass]="{ hidden: !openContract }"
          style="z-index: 9999"
        >
          <div
            class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box custom-scroll max-h-248px"
            *ngIf="
              (is_draft == false && lstPermission.includes('ADMIN')) ||
              is_draft == true ||
              is_draft == null
            "
          >
            <div
              class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
            >
              <img
                class="w-20px h-20px m-10px"
                src="../../../../assets/icons/handle-icons/Icons_search.svg"
              />
              <input
                class="w-full px-5px border-none focus:outline-none text-14px"
                placeholder="Nhập tìm kiếm..."
                [value]="clearSearchContract"
                (keyup)="keyUpContract.next($event.target.value)"
              />
            </div>
            <a
              *ngFor="let item of allContracts"
              class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
              href="javascript:void(0)"
              (click)="chooseContract(item)"
            >
              <span
                class="text-14px ml-10px text-gray ellipsis"
                [tooltip]="item.code"
              >
                {{ item.code }}
              </span>
            </a>

            <a
              *ngIf="allContracts.length == 0"
              class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
              href="javascript:void(0)"
            >
              <span class="text-14px ml-10px text-grey-med ellipsis">
                Không tìm thấy kết quả
              </span>
            </a>
            <a
              *ngIf="allContracts.length == 0 && textSearchContract"
              class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
              href="javascript:void(0)"
              (click)="chooseSearchContract(textSearchContract)"
            >
              <span
                class="text-14px ml-10px text-primary-dodger-blue ellipsis"
              >
                + Thêm mới "{{ textSearchContract }}"
              </span>
            </a>

            <div
              [ngClass]="
                pageContract < totalContract ? 'block' : 'hidden'
              "
              (click)="loadMoreContract()"
              class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
            >
              Xem thêm
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container config-p">
    <form [formGroup]="formImport">
      <div class="hidden">
        <input
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfig"
          [theme]="'dp-material'"
          formControlName="receipt_date"
          [mode]="'daytime'"
          (onChange)="getDateTime($event)"
          placeholder="DD/MM/YYYY HH:mm"
          class="class-valid overflow-ellipsis overflow-hidden whitespace-nowrap cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-32px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
          />
      </div>
      <div>
        <div class="form-qrcode">
            <div class="info-code">
              <div class="col-span-full flex">
                <div
                  class="h-full w-5px bg-grayscale-light-grey rounded-l-md"
                  *ngIf="!imExport_code"
                ></div>
  
                <div
                  class="h-full w-5px rounded-l-md bg-grayscale-light-grey cursor-pointer"
                  *ngIf="imExport_code && is_draft"
                  tooltip="Phiếu nháp"
                  placement="top"
                ></div>
  
                <div
                  class="h-full w-5px rounded-l-md bg-green-blue"
                  *ngIf="imExport_code && !is_draft && is_checked"
                ></div>
  
                <div
                  class="h-full w-5px rounded-l-md bg-grayscale-light-grey"
                  *ngIf="imExport_code && !is_draft && !is_checked"
                ></div>
              </div>
              <div class="flex flex-col cursor-pointer">
                <span
                  class="text-16px font-roboto-medium text-primary-dodger-blue"
                  >{{ titleImport }}</span
                >
                <div class="grid">
                  <span
                    class="text-20px font-roboto-medium text-dark-grey"
                    tooltip="Mã phiếu nhập"
                    >{{ imExport_code ? imExport_code : '---' }}</span
                  >

                  <span
                    class="text-14px font-roboto-medium text-dark-grey"
                    tooltip="Ngày tạo"
                    >{{
                      (create_at ? create_at : currentDate)
                        | date: 'dd/MM/yyyy HH:mm'
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="info-qr">
              <div
                class="col-span-4 opacity-100"
                *ngIf="imExport_code; else showQRCode"
              >
                <qrcode
                  [qrdata]="imExport_code"
                  [width]="100"
                  [errorCorrectionLevel]="'M'"
                  [elementType]="'svg'"
                  [margin]="0"
                ></qrcode>
              </div>
              <ng-template #showQRCode>
                <div class="col-span-4 opacity-60">
                  <qrcode
                    [qrdata]="qrImportData"
                    [width]="100"
                    [errorCorrectionLevel]="'M'"
                    [elementType]="'svg'"
                    [margin]="0"
                  ></qrcode>
                </div>
              </ng-template>
            </div>
        </div>
        <div class="w-full mt-4">
          <!-- Số container -->
          <div class="flex flex-col">
            <span class="font-roboto-regular text-12px text-grey-light">Số containers<span class="text-red-light">*</span></span>
            <input 
              type="text"
              class="input-custom font-roboto-medium"
              placeholder="Nhập số container"
              formControlName="container_code"
              >
          </div>
          <div class="grid grid-cols-2 gap-2">
            <!-- Người nhận -->
            <div class="col-span-1 flex flex-col mt-4">
              <span class="font-roboto-regular text-12px text-grey-light">Người nhận<span class="text-red-light">*</span></span>
              <div
                class="relative w-full"
                clickOutside
                (clickOutside)="closeOutsideReceiver()"
              >
                <div
                  class="h-40px cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                  (click)="openReceiver = !openReceiver"
                  [ngClass]="{
                    'text-grey': !receiver?.full_name
                  }"
                >
                  <img
                    *ngIf="receiver?.avatar"
                    [src]="receiver?.avatar"
                    alt=""
                    class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
                  />
                  <span
                    class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                  >
                    {{ receiver?.full_name || 'Chọn người nhận' }}
                  </span>
  
                  <div class="mt-2px relative right-5px">
                    <svg
                      fill="#4d4d50"
                      viewBox="0 0 20 20"
                      [ngClass]="{
                        'rotate-180': openReceiver,
                        'rotate-0': !openReceiver
                      }"
                      class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div
                  class="absolute left-0"
                  [ngClass]="{ hidden: !openReceiver }"
                  style="z-index: 9999"
                >
                  <div
                    class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box-floor2 custom-scroll max-h-200px"
                    *ngIf="
                      (is_draft == false && lstPermission.includes('ADMIN')) ||
                      is_draft == true ||
                      is_draft == null
                    "
                  >
                    <div
                      class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                    >
                      <img
                        class="w-20px h-20px m-10px"
                        src="../../../../assets/icons/handle-icons/Icons_search.svg"
                      />
                      <input
                        class="w-full px-5px border-none focus:outline-none text-14px"
                        placeholder="Nhập tìm kiếm..."
                        [value]="clearSearchReceiver"
                        (keyup)="keyUpReceiver.next($event.target.value)"
                      />
                    </div>
                    <a
                      *ngFor="let item of allReceivers"
                      class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="chooseReceiver(item)"
                    >
                      <img
                        [src]="item.avatar"
                        alt=""
                        class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
                      />
                      <span
                        class="text-14px ml-10px text-gray ellipsis"
                      >
                        {{ item.full_name }}
                      </span>
                    </a>
                    <a
                      *ngIf="allReceivers.length == 0"
                      class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                    >
                      <span class="text-14px ml-10px text-grey-med ellipsis">
                        Không tìm thấy kết quả
                      </span>
                    </a>
                    <a
                      *ngIf="allReceivers.length == 0 && filterReceiver"
                      class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                      href="javascript:void(0)"
                      (click)="navigateNewWindowsSSO()"
                    >
                      <span
                        class="text-14px ml-10px text-primary-dodger-blue ellipsis"
                      >
                        + Thêm mới người nhận
                      </span>
                    </a>
                    <div
                      [ngClass]="
                        pageReceiver < totalReceiver ? 'block' : 'hidden'
                      "
                      (click)="loadMoreReceiver()"
                      class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                    >
                      Xem thêm
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Nhân viên kinh doanh -->
            <div class="col-span-1 flex flex-col mt-4">
              <span class="font-roboto-regular text-12px text-grey-light">Nhân viên kinh doanh<span class="text-red-light">*</span></span>
              <div
                  class="relative w-full"
                  clickOutside
                  (clickOutside)="closeOutsideSeller()"
                >
                  <div
                    class="cursor-pointer justify-between relative inline-flex items-center focus:outline-none font-roboto-regular text-14px text-dark-grey font-medium not-italic w-full border-solid rounded-4px h-40px mt-8px py-8px pl-10px pr-8px border bg-white border-grayscale-light-grey hover:border-primary-light focus:ring-1 focus:ring-primary-blue"
                    (click)="openSeller = !openSeller"
                    [ngClass]="{
                      'text-grey': !seller?.full_name
                    }"
                  >
                    <img
                      *ngIf="seller?.avatar"
                      [src]="seller?.avatar"
                      alt=""
                      class="mr-10px rounded-full ml-0 avatar-user w-20px h-20px"
                    />
                    <span
                      class="overflow-ellipsis overflow-hidden whitespace-nowrap mr-10px"
                    >
                      {{ seller?.full_name || 'Chọn nhân viên' }}
                    </span>
  
                    <div class="mt-2px relative right-5px">
                      <svg
                        fill="#4d4d50"
                        viewBox="0 0 20 20"
                        [ngClass]="{
                          'rotate-180': openSeller,
                          'rotate-0': !openSeller
                        }"
                        class="inline w-4 h-4 mt-0 mb-1 md:mb-0 ml-1 transition-transform duration-200 transform md:-mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div
                    class="absolute right-0"
                    [ngClass]="{ hidden: !openSeller }"
                    style="z-index: 9999"
                  >
                    <div
                      class="pb-8px bg-white rounded-md box-shadow-custom float-right w-220px search-box-floor2 custom-scroll max-h-200px"
                      *ngIf="
                        (is_draft == false && lstPermission.includes('ADMIN')) ||
                        is_draft == true ||
                        is_draft == null
                      "
                    >
                      <div
                        class="flex bg-white w-220px h-40px mr-8px sticky top-0 border-b border-grayscale-light-grey"
                      >
                        <img
                          class="w-20px h-20px m-10px"
                          src="../../../../assets/icons/handle-icons/Icons_search.svg"
                        />
                        <input
                          class="w-full px-5px border-none focus:outline-none text-14px"
                          placeholder="Nhập tìm kiếm..."
                          [value]="clearSearchSeller"
                          (keyup)="keyUpSeller.next($event.target.value)"
                        />
                      </div>
                      <a
                        *ngFor="let item of allSellers"
                        class="bg-white hover:bg-primary-lightest py-10px px-16px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                        href="javascript:void(0)"
                        (click)="chooseSeller(item)"
                      >
                        <img
                          [src]="item.avatar"
                          alt=""
                          class="mr-3px rounded-full m-auto ml-0 avatar-user w-20px h-20px"
                        />
                        <span
                          class="text-14px ml-10px text-gray ellipsis"
                        >
                          {{ item.full_name }}
                        </span>
                      </a>
                      <a
                        *ngIf="allSellers.length == 0"
                        class="bg-lightest-grey hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                        href="javascript:void(0)"
                      >
                        <span class="text-14px ml-10px text-grey-med ellipsis">
                          Không tìm thấy kết quả
                        </span>
                      </a>
                      <a
                        *ngIf="allSellers.length == 0 && filterSeller"
                        class="bg-white hover:bg-primary-lightest py-10px px-5px rounded inline-flex items-center focus:outline-none h-40px mr-8px w-220px"
                        href="javascript:void(0)"
                        (click)="navigateNewWindowsSSO()"
                      >
                        <span
                          class="text-14px ml-10px text-primary-dodger-blue ellipsis"
                        >
                          + Thêm mới nhân viên
                        </span>
                      </a>
                      <div
                        [ngClass]="pageSeller < totalSeller ? 'block' : 'hidden'"
                        (click)="loadMoreSeller()"
                        class="text-14px w-220px h-30px text-center justify-center border-t border-primary-lightest flex flex-col pt-4px cursor-pointer"
                      >
                        Xem thêm
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- ghi chú -->
          <div class="flex flex-col mt-4">
            <span class="font-roboto-regular text-12px text-grey-light">Ghi chú</span>
            <textarea
              formControlName="note"
              spellcheck="false"
              placeholder="Ghi chú"
              class="w-full border rounded-4px bg-white border-grayscale-light-grey hover:border-primary-blue focus:outline-none py-4px pl-10px pr-8px focus:ring-1 focus:ring-primary-blue mt-8px h-40px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
    <div class="flex flex-col mt-4">
      <div class="grid grid-cols-2">
        <div class="col-span-1 text-dark-grey text-16px font-roboto-medium">
          Danh sách hàng hóa
        </div>
        <div class="col-span-1 flex justify-end">
          <button *ngIf="!is_checked" class="outline-button" (click)="addRowProduct('')">+</button>
        </div>
      </div>
      <table class="w-full border-collapse">
        <thead>
          <tr class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med">
            <th class="col-name-mobile">Tên hàng</th>
            <th class="col-sku-mobile">SKU</th>
          </tr>
        </thead>
        <tbody class="tb-body" *ngFor="let element of arrProducts; let i = index">
          <tr>
            <td>
              <div
                  *ngIf="!element.isEdit"
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px px-3 pt-2"
                >
                  <span>{{ element.product_name ? element.product_name : '---' }}</span>
                </div>

                <div
                  class="grid grid-cols-12 items-center"
                  *ngIf="element.isEdit"
                >
                  <div class="col-span-12">
                    <!--Using items input-->
                    <ng-select
                      [items]="allProducts"
                      bindLabel="name"
                      bindValue="id"
                      placeholder="Chọn tên hàng"
                      [(ngModel)]="element.selectProduct"
                      class="select-name mt-4px"
                      [ngClass]="element.product_name?'':'select-name-mobile'"
                      (change)="
                        getUnitNameAndAllSkusByProductId($event, element, i)
                      "
                      [addTag]="addProduct"
                      [clearable]="false"
                      addTagText="+ Thêm hàng hóa mới"
                      [closeOnSelect]="true"
                      notFoundText="Không có tên nào"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div
                          class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                        >
                          {{ item.name? item.name : '--' }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
            </td>
            <td>
              <div class="w-full" *ngIf="element.isEdit">
                <ng-select
                  [items]="element.allSkus"
                  bindLabel="sku_code"
                  bindValue="id"
                  placeholder="Chọn mã SKU"
                  [clearable]="false"
                  [(ngModel)]="element.selectSku"
                  class="select-sku-code mt-4px"
                  (click)="onClickSKU(element)"
                  (change)="getSku($event, element)"
                  notFoundText="Không có mã SKU"
                  [addTag]="addSku"
                  addTagText="+ Thêm mới"
                  [closeOnSelect]="true"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div
                      class="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >
                      {{ item.sku_code }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div
                  *ngIf="!element.isEdit"
                  class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px px-3 pt-2"
                >
                  {{ element.sku_code ? element.sku_code : '---' }}
              </div>
              <!-- {{ element.sku_code ? element.sku_code : '---' }} -->
            </td>
          </tr>
          <tr class="h-40px">
            <td class="td-mobile">Mã hàng hóa</td>
            <td class="td-mobile overflow-hidden overflow-ellipsis">
              {{ element.product_code ? element.product_code : '---' }}
            </td>
          </tr>
          <tr class="h-40px">
            <td class="td-mobile">Nhà cung cấp</td>
            <td class="td-mobile overflow-hidden overflow-ellipsis">
              {{ element.supplier_name ? element.supplier_name : '---' }}
            </td>
          </tr>
          <tr class="h-40px">
            <td class="td-mobile">Số lượng</td>
            <td>
              <div class="wrapper-quantity pr-2" *ngIf="element.isEdit">
                <input 
                  type="number"
                  min="1"
                  onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                  [(ngModel)]="element.quantity"
                  (change)="quantityChange(element)"
                  class="h-36px rounded outline-none text-right w-99percent text-14px font-roboto-medium" 
                  placeholder="Nhập số lượng">
              </div>
              <div *ngIf="!element.isEdit" class="w-full text-right font-roboto-medium text-12px pr-2">
                <span>{{element.quantity?element.quantity:0}}</span>
              </div>
            </td>
          </tr>
          <tr class="h-40px">
            <td class="td-mobile">Vị trí ô</td>
            <td>
              <div class="wrapper-position" [ngClass]="element.isEdit ? '':'none-border'" (click)="openSelectCell(i,element)">
                <span class="text-grey-light text-14px font-roboto-medium" *ngIf="element.selectHashtag?.length===0;else elseHashtag">Chọn ô</span>
                <ng-template #elseHashtag>
                  <div class="hashtag-custom">
                    <div class="item-hashtag" *ngFor="let item of element.selectHashtag">{{item.code}}</div>
                  </div>
                </ng-template>
              </div>
            </td>
          </tr>
          <tr class="h-40px pb-1">
            <td>
              <button *ngIf="element.isEdit" class="btn-close ml-10px" (click)="cancelEdit(i, element)">Hủy</button>
              <div class="hidden">
                <input
                    #dayPicker
                    type="text"
                    #dateExpiryDp="dpDayPicker"
                    [dpDayPicker]="dateExpiryPickerConfig"
                    [theme]="'dp-material'"
                    [mode]="'day'"
                    [(ngModel)]="element.expiry_date"
                    [(ngModel)]="expiry_date"
                    placeholder="Ngày"
                    class="cursor-pointer overflow-ellipsis overflow-hidden whitespace-nowrap col-span-12 px-2 h-42px border my-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-color-placeholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
                  />
              </div>
            </td>
            <td>
              <div class="flex items-end justify-end">
                <button *ngIf="element.isEdit" class="btn-save mr-2" (click)="saveItem(element, i)">Lưu</button>
                <button *ngIf="!element.isEdit && !is_sale" class="btn-edit mr-2" (click)="editItem(element)">Chỉnh sửa</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex items-center mt-2 justify-between">
      <button
          class="btn-cancel flex items-center gap-2px"
          type="button"
          (click)="closeModal(false)"
        >
          <img src="../../../../assets/icons/handle-icons/u_times.svg" alt="" />
          Đóng
      </button>

        <!-- <button
          permission="STAFF"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          *ngIf="is_draft == false && is_checked == false"
          (click)="saveUncheck()"
        >
          <img src="../../../../assets/icons/handle-icons/u_save.svg" alt="" />
          Lưu
        </button> -->

      <button
          permission="STAFF"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          *ngIf="
            (is_draft == null || is_draft == true) &&
            (is_checked == null || is_checked == false)
          "
          (click)="saveInDraft()"
        >
          <img src="../../../../assets/icons/handle-icons/u_save.svg" alt="" />
          {{ is_draft ? 'Lưu' : 'Lưu nháp' }}
      </button>
      <button
          permission="ADMIN"
          class="btn-primary-draft flex items-center gap-2px"
          type="button"
          *ngIf="is_draft == false"
          (click)="saveInDraft()"
        >
          <img src="../../../../assets/icons/handle-icons/u_save.svg" alt="" />
          {{ 'Lưu' }}
      </button>

      <button
          permission="STAFF"
          class="btn-primary-save flex items-center gap-2px"
          type="button"
          *ngIf="
            (is_draft == null || is_draft == true) &&
            (is_checked == null || is_checked == false)
          "
          (click)="saveImport()"
          [disabled]="checkImport"
        >
          <img
            src="../../../../assets/icons/handle-icons/fi_download.svg"
            alt=""
          />
          Nhập hàng
      </button>

      <button
          permission="STAFF"
          class="btn-primary-save flex items-center gap-2px"
          type="button"
          *ngIf="is_draft == false && is_checked == false"
          (click)="saveCheck()"
          [disabled]="disableCheck"
        >
          <img
            src="../../../../assets/icons/handle-icons/checkmark.svg"
            alt=""
          />
          Đã kiểm kho
      </button>
    </div>
  </div>
</ng-template>

<meu-modal-component
  [isOpen]="isOpenSelectCell"
  [options]="optionsSelectCell"
  [title]="titleModalCell"
  *ngIf="isOpenSelectCell"
>
  <meu-cell-location-modal
    [lstCell]="lstSlot"
    [inventoryID]="inventoryId"
    (closeModal)="closeSelectCell($event)"
    [isExport]="false"
    [selectedProduct]="productSelected"
    [selectedSku]="skuSelected"
  ></meu-cell-location-modal>
</meu-modal-component>

<meu-modal-component
  [isOpen]="isOpenPrintIE"
  [options]="optionsPrintIE"
  [title]="title_print"
  *ngIf="isOpenPrintIE"
>
  <meu-print
    [isTransferCar]="isTransferCar"
    [isBillOrCommand]="isBillOrCommand"
    [arrayImport]="arrProducts"
    [isPrintDivideQuantity]="isPrintDivideQuantity"
    [iexportCode]="imExport_code"
  ></meu-print>
</meu-modal-component>

<!-- Modal quantity -->
<meu-modal-component
  [isOpen]="isOpenModalQuantity"
  [options]="optionsQuantity"
  [title]="titleModalQuantity"
  *ngIf="isOpenModalQuantity"
>
  <meu-modal-slot-quantity
    [(slot)]="slot"
    (closeModalQuantity)="closeModalQuantity($event)"
    (valueQuantity)="valueQuantity($event)"
  ></meu-modal-slot-quantity>
</meu-modal-component>

<!-- Modal Product -->
<meu-modal-component
  [isOpen]="isOpenModalProduct"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModalProduct"
>
  <meu-modal-create-product
    [productName]="product_name"
    (closeModalUnit)="closeModalProduct($event)"
  ></meu-modal-create-product>
</meu-modal-component>

<!-- Modal SKU -->
<meu-modal-component
  [isOpen]="isOpenModalSKU"
  [options]="optionsSKU"
  [title]="titleModalSKU"
  *ngIf="isOpenModalSKU"
>
  <meu-modal-create-sku
    [id_product]="product_id"
    [code_sku]="sku_code"
    (closeModalSku)="closeModalSku($event)"
  ></meu-modal-create-sku>
</meu-modal-component>

<!-- Modal Print QR -->
<meu-modal-component
  [isOpen]="isOpenModalQR"
  [options]="optionsQR"
  [title]="titleModalQR"
  *ngIf="isOpenModalQR"
>
  <meu-print-qrcode
    [imExport_code]="imExport_code"
    (closeModalQR)="closeModalQR($event)"
  ></meu-print-qrcode>
</meu-modal-component>

<!-- Modal Contract -->
<meu-modal-component
  [isOpen]="isOpenModalContract"
  [options]="optionsContract"
  [title]="titleModalContract"
  *ngIf="isOpenModalContract"
>
  <meu-contract-management-create
    [contractCode]="contract_code"
    [customerID]="customer.id"
    (closeModalCreate)="closeModalContract($event)"
  ></meu-contract-management-create>
</meu-modal-component>

<!-- Modal Percent Used Slot -->
<meu-modal-component
  [isOpen]="isOpenModalPercentSlot"
  [options]="optionsPercentSlot"
  [title]="titleModalPercentSlot"
  *ngIf="isOpenModalPercentSlot"
>
  <meu-percent-used-slot
    [isCheckIE]="true"
    [listSlots]="listImportSlots"
    (closeModalPercentUsedSlot)="closeModalPercenUsedSlot($event)"
  ></meu-percent-used-slot>
</meu-modal-component>

<!-- for mobile -->
<meu-modal-popup-bottom
  [isOpen]="isOpenSelectCellBottom"
  [options]="optionsSelectCellBottom"
  [title]="titleModalCell"
  *ngIf="isOpenSelectCellBottom"
>
  <meu-cell-location-modal
    [lstCell]="lstSlot"
    [inventoryID]="inventoryId"
    (closeModal)="closeSelectCell($event)"
    [isExport]="false"
    [selectedProduct]="productSelected"
    [selectedSku]="skuSelected"
  ></meu-cell-location-modal>
</meu-modal-popup-bottom>

<meu-modal-popup-bottom
  [isOpen]="isOpenModalPercentSlotBottom"
  [options]="optionsPercentSlotBottom"
  [title]="titleModalPercentSlot"
  *ngIf="isOpenModalPercentSlotBottom"
>
  <meu-percent-used-slot
    [isCheckIE]="true"
    [listSlots]="listImportSlots"
    (closeModalPercentUsedSlot)="closeModalPercenUsedSlot($event)"
  ></meu-percent-used-slot>
</meu-modal-popup-bottom>