import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { ContainerChargeHistory, ContainerChargeHistoryService } from 'src/app';
import { ToastrService } from 'src/app/core/services';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { timeStamp } from 'console';
@Component({
  selector: 'meu-container-charge-service',
  templateUrl: './container-charge-service.component.html',
  styleUrls: ['./container-charge-service.component.scss'],
})
export class ContainerChargeServiceComponent implements OnInit {
  listContainerCharge: ContainerChargeHistory[] = [];

  totalArray: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  searchContainerCharge: any = '';
  id_conatainer_charge: string = '';
  titleModal: string;

  public keyUp = new Subject<KeyboardEvent>();

  selectedStatus = null;
  allStatus: any[] = [
    { id: 1, value: '', name: 'Tất cả trạng thái' },
    { id: 2, value: 'true', name: 'Lưu nháp' },
    { id: 3, value: 'false', name: 'Đã thanh toán' },
  ];

  isOpenModal: boolean = false;
  options: MxModalOption = {
    callBackAfterClose: () => {
      this.isOpenModal = false;
      this.id_conatainer_charge = '';
    },
  };
  isOpenBottomsheet: boolean = false;
  optionsBottom: MxBottomPopupOption = {
    callBackAfterClose: () => {
      this.isOpenModal = false;
    },
  };

  constructor(
    private containerChargeService: ContainerChargeHistoryService,
    private toastService: ToastrService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.selectedStatus = this.allStatus[0];
    this.onSearch();
    this.getAllContainerCharge();
  }

  //API
  getAllContainerCharge() {
    this.containerChargeService
      .apiContainerChargeHistoryGet(
        `container_number|cost|extend_creator_name@=*${this.searchContainerCharge},is_draft==${this.selectedStatus.value}`,
        '',
        this.pageIndex,
        this.pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          this.listContainerCharge = res.data.collection;
          this.totalArray = res.data.total;
        }
      });
  }
  deleteconfirm(id) {
    let title = 'Xóa đơn vị tính';
    let content = 'Bạn có chắc chắn muốn xóa đơn vị tính này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.containerChargeService
            .apiContainerChargeHistoryIdDelete(id)
            .subscribe((res) => {
              if (res.success == true) {
                this.getAllContainerCharge();
                this.toastService.success(res.message);
              } else {
                this.toastService.error(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  //Paging
  onPaging(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllContainerCharge();
  }
  get changePageFunc() {
    return this.onKeyDown.bind(this);
  }
  onKeyDown(): void {}

  //Select status
  onChangeStatus(status) {
    this.selectedStatus = status;
    this.getAllContainerCharge();
  }

  //search
  onSearch() {
    this.keyUp.pipe(debounceTime(500)).subscribe((res) => {
      if (res) {
        this.searchContainerCharge = res;
        this.getAllContainerCharge();
      }
    });
  }

  //Modal
  openModal(id = null) {
    this.isOpenModal = true;
    this.id_conatainer_charge = id ? id : '';
    this.titleModal = id
      ? 'Chi tiết phí sạc container'
      : 'Thêm mới phí sạc container';
    if (window.screen.width < 767) {
      this.isOpenModal = false;
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      }
    } else {
      this.isOpenBottomsheet = false;
      if (!this.isOpenModal) {
        this.isOpenModal = true;
      }
    }
  }
  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
      this.id_conatainer_charge = '';
    }
    if (this.isOpenBottomsheet) {
      this.isOpenBottomsheet = false;
      this.id_conatainer_charge = '';
    }
    if (event) {
      this.getAllContainerCharge();
    }
  }
}
