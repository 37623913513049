<div class="grid grid-cols-12 mt-8px pb-14px">
  <div
    class="col-span-12 flex flex-col md:flex-row md:justify-between md:items-center"
  >
    <div>
      <div
        class="pt-10px pb-14px font-roboto-medium font-medium text-dark-grey text-20px leading-7 not-italic"
      >
        Quản lý phiếu nhập / xuất
      </div>
    </div>
    <div>
      <div class="flex flex-wrap items-center gap-8px">
        <div class="relative">
          <input
            type="text"
            placeholder="Nhập từ khóa tìm kiếm"
            [(ngModel)]="searchIExport"
            (keyup)="onKeyUp()"
            (keydown)="onKeyDown()"
            class="m-auto h-32px w-320px border mb-4px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
          />
          <img
            class="absolute top-0 mt-9px ml-12px"
            src="assets/icons/handle-icons/Icons_search.svg"
          />
        </div>
        <div class="flex gap-8px">
          <button
            class="btn-primary-icon mt-10px mb-14px flex items-center gap-4px"
            (click)="addImport()"
          >
            <img src="assets/icons/handle-icons/fi_download.svg" />
            <span>Nhập hàng</span>
          </button>
          <button
            (click)="addExport()"
            class="btn-primary-export mt-10px mb-14px flex items-center gap-4px"
          >
            <img src="assets/icons/handle-icons/u_file-export.svg" />
            <span>Xuất hàng</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-12 overflow-x-auto w-full">
    <table
      cellpadding="0"
      cellspacing="0"
      class="w-full border-separate table-fixed"
      *ngIf="totalArray > 0; else noInfo"
    >
      <thead>
        <tr
          class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
        >
          <th class="md:text-center w-48px">#</th>
          <!-- mã phiếu nhập -->
          <th
            class="text-left col-code cursor-pointer"
            (click)="Sort('imExportCode')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Mã phiếu nhập / xuất'"
                >Mã phiếu nhập / xuất</span
              >

              <img
                *ngIf="sort.includes('imExportCode'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- ten Khách hàng -->
          <th
            class="text-left col-name cursor-pointer"
            (click)="Sort('extend_customer_name')"
          >
            <div class="flex justify-between items-center">
              <span
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [tooltip]="'Tên khách hàng'"
                >Tên khách hàng</span
              >

              <img
                *ngIf="sort.includes('extend_customer_name'); else normalSearch"
                [src]="sortName"
              />
              <ng-template #normalSearch>
                <img src="assets/icons/sort/sort_normal.svg" />
              </ng-template>
            </div>
          </th>
          <!-- ngay nhap -->
          <th
            class="text-left col-date-issue overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Ngày nhập / xuất'"
          >
            Ngày nhập / xuất
          </th>
          <!-- nguoi  nhap -->
          <th
            class="text-left col-name1 overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Người nhập'"
          >
            Người nhập
          </th>

          <!-- nguoi kiem ke -->
          <th
            [tooltip]="'Người kiểm kê'"
            class="text-left col-name2 cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            Người kiểm kê
          </th>
          <!-- kiem kho -->
          <th
            class="text-center col-check overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Đã kiểm kho'"
          >
            Đã kiểm kho
          </th>
          <!-- statuc -->
          <th
            class="text-center col-status overflow-hidden whitespace-nowrap overflow-ellipsis"
            [tooltip]="'Trạng thái'"
          >
            Trạng thái
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="font-roboto-regular h-40px"
          *ngFor="let ie of IExports; let i = index"
        >
          <td
            class="text-center td-stt text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer"
            data-label="#"
            [tooltip]="indexArray + i + 1"
          >
            {{ indexArray + i + 1 }}
          </td>
          <!-- mã phiếu nhập -->
          <td
            (click)="open(ie.imExportCode)"
            class="td-code text-left cursor-pointer text-primary-dodger-blue overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Mã phiếu nhập / xuất"
          >
            <span
              [tooltip]="ie.imExportCode ? ie.imExportCode : '--'"
              class="cursor-pointer"
            >
              {{ ie.imExportCode ? ie.imExportCode : '--' }}</span
            >
          </td>
          <!-- ten hang hoa -->
          <td
            class="td-name text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Tên hàng hóa"
          >
            <span
              [tooltip]="
                ie['extend_customer_name'] ? ie['extend_customer_name'] : '--'
              "
              class="cursor-pointer"
            >
              {{
                ie['extend_customer_name'] ? ie['extend_customer_name'] : '--'
              }}</span
            >
          </td>
          <!-- ngay nhap -->
          <td
            *ngIf="ie.receipt_date; else issuedate"
            class="td-isdate text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Ngày nhập / xuất"
          >
            <span
              [tooltip]="
                ie.receipt_date
                  ? (ie.receipt_date | date: 'dd/MM/yyyy - h:mm')
                  : '--'
              "
              class="cursor-pointer"
            >
              {{
                ie.receipt_date
                  ? (ie.receipt_date | date: 'dd/MM/yyyy - h:mm')
                  : '--'
              }}</span
            >
          </td>
          <ng-template #issuedate>
            <td
              class="td-isdate text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
              data-label="Ngày nhập / xuất"
            >
              <span
                [tooltip]="
                  ie.issue_date
                    ? (ie.issue_date | date: 'dd/MM/yyyy - h:mm')
                    : '--'
                "
                class="cursor-pointer"
              >
                {{
                  ie.issue_date
                    ? (ie.issue_date | date: 'dd/MM/yyyy - h:mm')
                    : '--'
                }}</span
              >
            </td>
          </ng-template>
          <!-- nguoi  nhap -->
          <td
            class="td-name1 text-left text-dark-grey cols cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Nhập"
          >
            <span
              [tooltip]="
                ie['extend_creator_name'] ? ie['extend_creator_name'] : '--'
              "
              class="cursor-pointer"
            >
              {{
                ie['extend_creator_name'] ? ie['extend_creator_name'] : '--'
              }}</span
            >
          </td>

          <!-- nguoi kiem ke -->
          <td
            class="td-name2 text-left text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Đơn vị tính"
          >
            <span
              [tooltip]="
                ie['extend_implementer_name']
                  ? ie['extend_implementer_name']
                  : '--'
              "
              class="cursor-pointer"
            >
              {{
                ie['extend_implementer_name']
                  ? ie['extend_implementer_name']
                  : '--'
              }}</span
            >
          </td>
          <!-- kiem kho -->
          <td
            class="td-check r text-center text-dark-grey overflow-hidden whitespace-nowrap overflow-ellipsis"
            data-label="Đã kiểm kho"
          >
            <div class="flex md:justify-center justify-end">
              <input
                type="checkbox"
                [checked]="ie.is_checked == true"
                onclick="return false"
                class="w-16px h-16px"
              />
            </div>
          </td>
          <!-- status -->
          <td
            class="td-status text-center text-dark-grey"
            data-label="Trạng thái"
          >
            <div class="text-center lg:px-20px">
              <div
                class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                [ngClass]="
                  ie.isImExported == true
                    ? 'bg-duck-egg-blue rounded-4px py-4px px-8px text-greenblue'
                    : 'bg-light-peach rounded-4px py-4px px-8px text-orange-red'
                "
                [tooltip]="ie.isImExported == true ? 'Đã nhập' : 'Đã xuất'"
              >
                {{ ie.isImExported == true ? 'Đã nhập' : 'Đã xuất' }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #noInfo>
      <table
        cellpadding="0"
        cellspacing="0"
        class="w-full border-separate table-fixed"
      >
        <thead>
          <tr
            class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
          >
            <th class="md:text-center w-48px">#</th>
            <!-- mã phiếu nhập -->
            <th
              class="text-left col-code cursor-pointer"
              (click)="Sort('imExportCode')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Mã phiếu nhập / xuất'"
                  >Mã phiếu nhập / xuất</span
                >

                <img
                  *ngIf="sort.includes('imExportCode'); else normalSearch"
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- ten Khách hàng -->
            <th
              class="text-left col-name cursor-pointer"
              (click)="Sort('extend_customer_name')"
            >
              <div class="flex justify-between items-center">
                <span
                  class="overflow-hidden whitespace-nowrap overflow-ellipsis"
                  [tooltip]="'Tên khách hàng'"
                  >Tên khách hàng</span
                >

                <img
                  *ngIf="
                    sort.includes('extend_customer_name');
                    else normalSearch
                  "
                  [src]="sortName"
                />
                <ng-template #normalSearch>
                  <img src="assets/icons/sort/sort_normal.svg" />
                </ng-template>
              </div>
            </th>
            <!-- ngay nhap -->
            <th
              class="text-left col-date-issue overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Ngày nhập / xuất'"
            >
              Ngày nhập / xuất
            </th>
            <!-- nguoi  nhap -->
            <th
              class="text-left col-name1 overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Người nhập'"
            >
              Người nhập
            </th>

            <!-- nguoi kiem ke -->
            <th
              [tooltip]="'Người kiểm kê'"
              class="text-left col-name2 cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              Người kiểm kê
            </th>
            <!-- kiem kho -->
            <th
              class="text-center col-check overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Đã kiểm kho'"
            >
              Đã kiểm kho
            </th>
            <!-- statuc -->
            <th
              class="text-center col-status overflow-hidden whitespace-nowrap overflow-ellipsis"
              [tooltip]="'Trạng thái'"
            >
              Trạng thái
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="font-roboto-regular text-14px h-40px">
            <td colspan="8" class="td-noinfo">Không có dữ liệu</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
  <div class="col-span-12">
    <meu-pagination-component
      *ngIf="totalArray != 0"
      [total]="totalArray"
      [array]="array"
      [changePage]="onPagingFunc"
    ></meu-pagination-component>
  </div>
</div>

<meu-modal-component
  [isOpen]="isOpenModal"
  [options]="options"
  [title]="titleModal"
  *ngIf="isOpenModal"
>
  <meu-import-history
    [imExport_code]="imExport_code"
    [idContract]="idContract"
    (closeModalImport)="closeModal($event)"
    (changeTitleModal)="changeTitle($event)"
  ></meu-import-history>
</meu-modal-component>

<meu-modal-component
  [isOpen]="isOpenModalExport"
  [options]="optionsExport"
  [title]="titleModalExport"
  *ngIf="isOpenModalExport"
>
  <meu-export-history
    [imExport_code]="im_Export_code"
    [idContract]="idContract"
    (closeModalExport)="closeModalExport($event)"
    (changeTitleModal)="changeTitleExport($event)"
  ></meu-export-history>
</meu-modal-component>
