import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from './../../../core/services/toastr.service';
import { ConfirmService } from './../../../core/services/confirm.service';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { Component, OnInit } from '@angular/core';
import { ContractService, ContractStatus } from 'src/app';
import { Contract } from 'src/app';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';

@Component({
  selector: 'meu-app-contract-management',
  templateUrl: './app-contract-management.component.html',
  styleUrls: ['./app-contract-management.component.scss'],
})
export class AppContractManagementComponent implements OnInit {
  indexArray: number = 0;

  isOpenModal: boolean = false;
  titleModal: string;
  options: MxModalOption = {};

  contractDatas: Contract[] = [];
  array: Contract[] = [];
  statusContract: ContractStatus;
  totalArray: number;

  searchContract: string = '';
  subject: Subject<any> = new Subject();

  sort: string = '';
  sortName: string = '';

  inventoryId: string;
  contract_id: string;
  status_id: string = '';
  filter: string;

  constructor(
    private contractService: ContractService,
    private confirmService: ConfirmService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStoreService: LocalStoreService
  ) {}

  ngOnInit(): void {
    this.setInventoryId();
    //this.getAllContract();
    this.getAllStatus();
    this.optionCallBack();
    this.search();
  }

  setInventoryId() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
        this.getAllContract();
      }
    });
  }

  //Filter status
  getAllStatus() {
    this.contractService.apiContractStatusGet().subscribe((data) => {
      if (data.success) {
        this.statusContract = data.data.collection;
      }
    });
  }
  onChangeStatus(data) {
    this.getAllContract();
  }

  //Paging
  getAllContract() {
    if (this.status_id) {
      this.filter = `status==${this.status_id},code|extend_contract_type|extend_contract_customer_name@=${this.searchContract}`;
    } else {
      this.filter = `code|extend_contract_type|extend_contract_customer_name@=${this.searchContract}`;
    }
    this.contractService
      .apiContractGet(this.inventoryId, this.filter)
      .subscribe((data) => {
        if (data.success == true) {
          this.array = data.data.collection;
          this.totalArray = data.data.total;
        }
      });
  }
  get onPagingFunc() {
    return this.onPaging.bind(this);
  }
  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    if (this.status_id) {
      this.filter = `status==${this.status_id},code|extend_contract_type|extend_contract_customer_name@=${this.searchContract}`;
    } else {
      this.filter = `code|extend_contract_type|extend_contract_customer_name@=${this.searchContract}`;
    }
    this.contractService
      .apiContractGet(
        this.inventoryId,
        this.filter,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.contractDatas = data.data.collection;
          this.totalArray = data.data.total;
        }
      });
  }

  //Modal
  optionCallBack() {
    this.options.callBackAfterClose = (e) => {
      this.isOpenModal = false;
      this.contract_id = '';
    };
  }
  closeModal(res) {
    if (!res) {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    } else {
      if (res.success == true) {
        if (this.isOpenModal) {
          this.isOpenModal = false;
          this.contract_id = '';

          this.getAllContract();
        }
      } else {
      }
    }
  }
  addNewContract(id) {
    if (!this.isOpenModal) {
      this.isOpenModal = true;
      this.titleModal = 'Thêm mới hợp đồng';
    }
    if (id) {
      this.contract_id = id;
    }
  }

  //Delete
  onDelete(id) {
    let title = 'Xóa hợp đồng';
    let content = 'Bạn có chắc chắn muốn xóa hợp đồng này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.contractService.apiContractIdDelete(id).subscribe((res) => {
            if (res.success == true) {
              this.getAllContract();
              this.toastService.success(res.message);
            } else {
              //this.getProductById(this.current_folder_id);
              this.toastService.error(res.message);
            }
          });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  //Sort
  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getAllContract();
  }

  //Filter

  search() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.getAllContract();
    });
  }
  onKeyUp(): void {
    this.subject.next();
  }
  onKeyDown(): void {}
}
