<div class="container min-h-full my-30px">
  <div class="grid-contain">
    <div class="col-span-full flex">
      <div class="h-full w-10px bg-yellowish-orange"></div>
      <div class="text-80px text-secondary-lighter font-roboto-bold ml-20px">
        Buttons
      </div>
    </div>
    <div class="col-span-full text-black-52 font-roboto-bold text-28px my-40px">
      Primary Button
    </div>

    <div
      class="col-span-full panel border-2 border-periwinkle rounded-5px border-dashed grid-contain"
    >
      <div class="col-span-3 m-auto">
        <button class="btn-primary-sm m-15px">Button (40px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-primary-sm m-15px" disabled>Button (40px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-primary-md m-15px">Button (48px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-primary-md m-15px" disabled>Button (48px)</button>
      </div>
    </div>

    <div class="col-span-full text-black-52 font-roboto-bold text-28px my-40px">
      Secondary Button
    </div>

    <div
      class="col-span-full panel border-2 border-periwinkle rounded-5px border-dashed grid-contain"
    >
      <div class="col-span-3 m-auto">
        <button class="btn-secondary-sm m-15px">Button (40px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-secondary-sm m-15px" disabled>Button (40px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-secondary-md m-15px">Button (48px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-secondary-md m-15px" disabled>Button (48px)</button>
      </div>
    </div>

    <div class="col-span-full text-black-52 font-roboto-bold text-28px my-40px">
      Text Button
    </div>

    <div
      class="col-span-full panel border-2 border-periwinkle rounded-5px border-dashed grid-contain"
    >
      <div class="col-span-3 m-auto">
        <button class="btn-text-sm m-15px">Button (40px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-text-sm m-15px" disabled>Button (40px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-text-md m-15px">Button (48px)</button>
      </div>
      <div class="col-span-3 m-auto">
        <button class="btn-text-md m-15px" disabled>Button (48px)</button>
      </div>
    </div>
  </div>
</div>
