/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DeleteHistoryNote { 
    id?: string;
    name?: string;
    user_id?: string;
    im_export_note?: string;
    delete_at?: Date;
    receipt_date?: Date;
    issue_date?: Date;
    im_ex_created_at?: Date;
    im_ex_created_by?: string;
    imExportCode?: string;
}