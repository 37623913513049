/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ImportExportHistory { 
    id?: string;
    sku_id?: string;
    isImExported?: boolean;
    quantity?: number;
    batch_code?: string;
    expiry_date?: Date;
    issue_date?: Date;
    description?: string;
    note?: string;
    barcode?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    slot_id?: string;
    implementer_id?: string;
    container_code?: string;
    sku_code?: string;
    product_id?: string;
    product_name?: string;
    product_code?: string;
    is_draft?: boolean;
    imExportCode?: string;
    receipt_date?: Date;
    group_number?: number;
    unit_name?: string;
    customer_id?: string;
    is_checked?: boolean;
    contract_id?: string;
    contract_code?: string;
    supplier_id?: string;
    supplier_name?: string;
    inventory_id?: string;
    unit_code?: string;
    net_weigth?: number;
    import_code?: string;
    percent?: number;
    receive_car?: string;
    receiver?: string;
    is_transfer_car?: boolean;
    real_net_weight?: number;
    seller_id?: string;
    receiver_id?: string;
    is_sale?: boolean;
    is_done?: boolean;
    is_unload?: boolean;
}