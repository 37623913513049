import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractService } from 'src/app/api/api';
import { ContractServiceCost } from 'src/app/model/models';
import { MxModalOption } from 'src/app/shared/components/modal/modal-component/modal-component.component';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { take, debounceTime } from 'rxjs/operators';
import { ToastrService, ConfirmService } from 'src/app/core/services';
import { MxBottomPopupOption } from 'src/app/shared/components/modal/modal-popup-bottom/modal-popup-bottom.component';

@Component({
  selector: 'meu-service-cost-contract',
  templateUrl: './service-cost-contract.component.html',
  styleUrls: ['./service-cost-contract.component.scss'],
})
export class ServiceCostContractComponent implements OnInit {
  idContract: string = '';

  indexArray: number = 0;
  totalArray: number;
  array: ContractServiceCost[] = [];
  allServicesContract: ContractServiceCost[] = [];

  subject: Subject<any> = new Subject();
  sortName: string = '';
  sort: string = '';

  listService = [];

  titleModal: string;
  isOpenModal: boolean = false;
  options: MxModalOption = {};

  isOpenBottomsheet: boolean = false;
  optionsBottom: MxBottomPopupOption = {};

  constructor(
    private contractService: ContractService,
    private toastService: ToastrService,
    private router: Router,
    private toastr: ToastrService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.idContract = this.router.url.split('/')[5];
    this.getServiceCostContract();
    this.optionCallbackModal();
    this.optionCallbackModalBottom();
  }

  getServiceCostContract() {
    this.contractService
      .apiContractServiceCostGet('contract_id==' + this.idContract)
      .pipe(debounceTime(1000))
      .subscribe((res) => {
        if (res.success == true) {
          this.array = res.data.collection;
          this.totalArray = res.data.total;
          this.listService = this.array.map((x) => x.service_cost_id);
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  get onPagingFunc() {
    return this.onPaging.bind(this);
  }

  onPaging(array, currentPage, pageSize) {
    const a = array;
    this.indexArray = (currentPage - 1) * pageSize;
    this.contractService
      .apiContractServiceCostGet(
        'contract_id== ' + this.idContract,
        this.sort,
        currentPage,
        pageSize
      )
      .subscribe((data) => {
        if (data.success == true) {
          this.allServicesContract = data.data.collection;
          this.totalArray = data.data.total;
          this.listService = this.allServicesContract.map(
            (x) => x.service_cost_id
          );
        } else {
          this.toastService.error('Lỗi không lấy được dữ liệu');
        }
      });
  }

  deleteService(id) {
    let title = 'Xóa dịch vụ';
    let content = 'Bạn có chắc chắn muốn xóa dịch vụ này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          this.contractService
            .apiContractServiceCostIdDelete(id)
            .subscribe((res) => {
              if (res.success == true) {
                this.getServiceCostContract();
                this.toastr.success(res.message);
              } else {
                this.getServiceCostContract();
                this.toastr.error(res.message);
              }
            });
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }

  Sort(name) {
    var n = this.sort.search(name);
    if (n > -1) {
      if (this.sortName == 'assets/icons/sort/sort_down.svg') {
        this.sortName = 'assets/icons/sort/sort_up.svg';
        this.sort = this.sort.replace(name, '-' + name);
      } else {
        this.sortName = 'assets/icons/sort/sort_normal.svg';
        this.sort = '';
      }
    } else {
      this.sort = name;
      this.sortName = 'assets/icons/sort/sort_down.svg';
    }
    this.getServiceCostContract();
  }

  optionCallbackModal() {
    this.options.callBackAfterClose = (e) => {
      this.isOpenModal = false;
    };
  }

  optionCallbackModalBottom() {
    this.optionsBottom.callBackAfterClose = (e) => {
      this.isOpenBottomsheet = false;
    };
  }

  open() {
    this.titleModal = 'Thêm mới dịch vụ';

    if (window.screen.width < 767) {
      this.isOpenModal = false;
      if (!this.isOpenBottomsheet) {
        this.isOpenBottomsheet = true;
      }
    } else {
      this.isOpenBottomsheet = false;
      if (!this.isOpenModal) {
        this.isOpenModal = true;
      }
    }
  }

  closeModal(event) {
    if (this.isOpenModal) {
      this.isOpenModal = false;
    }
    if (event.success) {
      this.getServiceCostContract();
    }
  }

  closeModalBottom(data) {
    if (this.isOpenBottomsheet) {
      this.isOpenBottomsheet = false;
    }
    if (data.success) {
      this.getServiceCostContract();
    }
  }
}
