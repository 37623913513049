import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'meu-modal-slot-quantity',
  templateUrl: './modal-slot-quantity.component.html',
  styleUrls: ['./modal-slot-quantity.component.scss']
})
export class ModalSlotQuantityComponent implements OnInit {
  @Input() slot: any;
  @Input() total: number;

  @Output() closeModalQuantity = new EventEmitter();
  @Output() valueQuantity = new EventEmitter();

  quantity: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  closeModal(data = null) {
    if (data) {
      this.closeModalQuantity.emit(data);
      return;
    }
    this.closeModalQuantity.emit();
  }

  saveQuantity() {
    this.valueQuantity.emit(this.slot.quantity);
    this.closeModal();
  }
}
