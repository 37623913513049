import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { ConfirmService } from 'src/app/core/services/confirm.service';

@Component({
  selector: 'meu-modal-example',
  templateUrl: './modal-example.component.html',
  styleUrls: ['./modal-example.component.scss'],
})
export class ModalExampleComponent implements OnInit {
  isOpenModal: boolean;
  isOpenModalConfirm: boolean;
  constructor(private confirmService: ConfirmService) {}

  ngOnInit(): void {
    this.isOpenModal = false;
    this.isOpenModalConfirm = false;
  }
  openModal() {
    if (!this.isOpenModal) {
      this.isOpenModal = true;
    }
  }
  close() {
    this.isOpenModal = false;
  }
  closeModal() {
    if (this.isOpenModal) {
      this.isOpenModal = false;
    }
  }

  deleteconfirm() {
    let title = 'Xóa nhà cung cấp';
    let content = 'Bạn có chắc chắn muốn xóa nhà cung cấp này ?';
    this.confirmService.deleteConfirm(title, content, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type == 'delete') {
          //Do somthing in here when click delete
          this.confirmService.close();
        } else {
          this.confirmService.close();
        }
      });
  }
}
