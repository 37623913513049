import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  // private modalConfirmComponent: ModalConfirmComponent = new ModalConfirmComponent;
  open$: Subject<any>;
  constructor() {
    this.open$ = new Subject();
  }
  deleteConfirm(title, content, confirmOption?, option?) {
    this.open$.next({
      title,
      content,
      confirmOption,
      option,
    });
  }
  close() {
    this.open$.next(null);
  }
}
