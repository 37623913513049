<div class="sm:grid sm:grid-cols-12 px-16px">
  <div class="sm:col-span-full mt-16px">
    <form [formGroup]="formcreate">
      <div class="w-full flex flex-col sm:grid sm:grid-cols-12 gap-4">
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Tên nhà cung cấp <span class="text-red-light">*</span></span
            >

            <input
              [ngClass]="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập tên nhà cung cấp"
              formControlName="name"
              required
              class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-grayscale-light-grey font-medium font-roboto-regular text-14px text-dark-grey not-italic"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('name').touched &&
                  formcreate.controls.name.errors?.required) ||
                formcreate.controls.name.errors?.maxlength
              "
            >
              {{
                formcreate.controls.name.errors?.required
                  ? 'Vui lòng nhập tên nhà cung cấp'
                  : 'Tên nhà cung cấp tối đa 100 kí tự'
              }}
            </span>
          </div>
        </div>
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Mã NCC <span class="text-red-light">*</span></span
            >
            <input
              [ngClass]="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              (change)="codeChange = true"
              type="text"
              placeholder="Nhập mã nhà cung cấp"
              formControlName="code"
              required
              class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-grayscale-light-grey font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                (formcreate.get('code').touched &&
                  formcreate.controls.code.errors?.required) ||
                formcreate.controls.code.errors?.maxlength
              "
            >
              {{
                formcreate.controls.code.errors?.required
                  ? 'Vui lòng nhập mã nhà cung cấp'
                  : 'Mã nhà cung cấp tối đa 20 ký tự'
              }}
            </span>
          </div>
        </div>
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.phone.errors?.pattern ||
                formcreate.controls.phone.errors?.maxlength ||
                formcreate.controls.phone.errors?.minlength
                  ? 'error-class'
                  : ''
              "
            >
              Điện thoại
            </span>
            <input
              [ngClass]="
                formcreate.controls.phone.errors?.pattern ||
                formcreate.controls.phone.errors?.maxlength ||
                formcreate.controls.phone.errors?.minlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              type="text"
              placeholder="VD 098765417"
              formControlName="phone"
              class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-grayscale-light-grey font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                formcreate.controls.phone.errors?.pattern ||
                formcreate.controls.phone.errors?.maxlength ||
                formcreate.controls.phone.errors?.minlength
              "
            >
              {{
                formcreate.controls.phone.errors?.pattern
                  ? 'Vui lòng nhập đúng ký tự số '
                  : formcreate.controls.phone.errors?.maxlength
                  ? 'Số điện thoại tối đa 11 số'
                  : 'Số điện thoại tối thiểu 10 số'
              }}
            </span>
          </div>
        </div>
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.email.errors?.pattern ||
                formcreate.controls.email.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Email
            </span>
            <input
              [ngClass]="
                formcreate.controls.email.errors?.pattern ||
                formcreate.controls.email.errors?.maxlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              type="text"
              placeholder="VD asd@gmail.com"
              formControlName="email"
              class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-grayscale-light-grey font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="
                formcreate.controls.email.errors?.pattern ||
                formcreate.controls.email.errors?.maxlength
              "
            >
              {{
                formcreate.controls.email.errors?.pattern
                  ? 'Vui lòng nhập đúng ký tự email'
                  : 'Email tối đa 150 ký tự'
              }}
            </span>
          </div>
        </div>
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.tax_code.errors?.pattern ||
                formcreate.controls.tax_code.errors?.maxlength ||
                formcreate.controls.tax_code.errors?.minlength
                  ? 'error-class'
                  : ''
              "
            >
              Mã số thuế
            </span>
            <input
              [ngClass]="
                formcreate.controls.tax_code.errors?.pattern ||
                formcreate.controls.tax_code.errors?.maxlength ||
                formcreate.controls.tax_code.errors?.minlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              type="text"
              placeholder="VD 123456789"
              formControlName="tax_code"
              class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-grayscale-light-grey font-roboto-regular text-14px text-dark-grey"
            />
          </div>
          <span
            class="font-roboto-regular text-12px text-red-light"
            *ngIf="
              formcreate.controls.tax_code.errors?.pattern ||
              formcreate.controls.tax_code.errors?.maxlength ||
              formcreate.controls.tax_code.errors?.minlength
            "
          >
            {{
              formcreate.controls.tax_code.errors?.pattern
                ? 'Vui lòng nhập đúng ký tự số'
                : formcreate.controls.tax_code.errors?.maxlength
                ? 'Mã số thuế tối đa 10 số'
                : 'Mã số thuế tối thiểu 10 số'
            }}
          </span>
        </div>
        <div class="sm:col-span-6">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.address.errors?.maxlength
                  ? 'error-class'
                  : ''
              "
            >
              Địa chỉ
            </span>
            <input
              [ngClass]="
                formcreate.controls.address.errors?.maxlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              type="text"
              placeholder="Nhập địa chỉ"
              formControlName="address"
              class="w-full rounded-4px py-8px px-12px h-40px mt-8px appearance-none placeholder-grayscale-light-grey font-roboto-regular text-14px text-dark-grey"
            />
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="formcreate.controls.address.errors?.maxlength"
            >
              {{
                formcreate.controls.address.errors?.maxlength
                  ? 'Địa chỉ tối đa 200 ký tự'
                  : ''
              }}
            </span>
          </div>
        </div>
        <div class="sm:col-span-full">
          <div class="flex flex-col">
            <span
              class="font-roboto-regular text-12px text-grey-label"
              [ngClass]="
                formcreate.controls.notes.errors?.maxlength ? 'error-class' : ''
              "
            >
              Mô tả
            </span>
            <textarea
              [ngClass]="
                formcreate.controls.notes.errors?.maxlength
                  ? 'class-invalid-name'
                  : 'class-valid'
              "
              formControlName="notes"
              placeholder="Nhập mô tả"
              class="w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
            ></textarea>
            <span
              class="font-roboto-regular text-12px text-red-light"
              *ngIf="formcreate.controls.notes.errors?.maxlength"
            >
              {{
                formcreate.controls.notes.errors?.maxlength
                  ? 'Mô tả tối đa 300 ký tự'
                  : ''
              }}
            </span>
            <!-- type="text" -->
          </div>
        </div>
        <div
          class="col-span-full flex-row-reverse gap-8px flex h-64px mt-10px pt-8px items-center"
        >
          <button
            class="btn-primary-add"
            type="button"
            (click)="setButtonAddOrEdit()"
            [disabled]="!formcreate.valid"
          >
            {{ nameButton }}
          </button>
          <button class="btn-text-cancel" type="button" (click)="closeModal()">
            Hủy
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
