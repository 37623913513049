import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';
import { ServiceListManagementComponent } from './service-list-management/service-list-management.component';
import { ServiceListModalComponent } from './service-list-modal/service-list-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  {
    path: 'service-list',
    component: ServiceListManagementComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
];

@NgModule({
  declarations: [ServiceListManagementComponent, ServiceListModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class ServiceListModule {}
