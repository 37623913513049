import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
const crypto = require('asymmetric-crypto');
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  /*
   * @template copyTextToClipboard() - receive text value and copy it to clipboard
   * @Param val: string
   * @return - none
   */
  copyTextToClipboard(val: string) {
    const reg = /_ngcontent.*?""/g;
    val = val.replace(reg, '');
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  openSSO() {
    localStorage.removeItem(environment.authTokenKey);
    // console.log(this.getSSOAuthUrl());

    window.open(this.getSSOAuthUrl(), '_self');
  }

  getSSOAuthUrl(): string {
    return `${
      environment.ssoFeUrl
    }/auth/login?returnUrl=${this.provideRedirectUrl()}`;
  }

  provideRedirectUrl(): string {
    const keyPair = crypto.keyPair();
    localStorage.setItem(environment.privateKeyToken, JSON.stringify(keyPair));

    let returnUrl = `${window.location.protocol}//${window.location.host}`;
    const currentUrl = window.location.href;
    if (!currentUrl.includes(returnUrl + '/auth')) {
      returnUrl = returnUrl;
    }
    const publicKeyBase64 = btoa(keyPair.publicKey);

    return (
      returnUrl +
      '&channel=' +
      environment.channel +
      '&publicKey=' +
      publicKeyBase64
    );
  }

  logout() {
    this.router.navigate(['auth']);
  }
}
