// Angular
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../services/shared.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private router: Router, private sharedService: SharedService) {}
  now = Date.now();
  // intercept request and add token
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let httpHeaders = new HttpHeaders().append('Accept', 'application/json');
    // if (req.headers.get('content-type') == null) {
    //   httpHeaders = httpHeaders.append('content-type', 'application/json');
    // }
    const token = `Bearer ${localStorage.getItem(environment.authTokenKey)}`;
    if (token) {
      httpHeaders = httpHeaders.append('Authorization', token);
    } else {
      this.sharedService.openSSO();
    }
    const request = req.clone({ headers: httpHeaders });

    if (request.method != 'GET') {
      // RootSpinnerService.show();
    }

    return next.handle(request).pipe(
      tap(
        event => { },
        error => { }
      ),
      finalize(() => {
        if (request.method != 'GET') {
          // RootSpinnerService.hide();
        }
      })
    );
  }
}
