import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, timeout } from 'rxjs/operators';
import { InventoryMapService, SlotService } from 'src/app';
import { ToastrService } from 'src/app/core/services';

@Component({
  selector: 'meu-app-change-position-modal',
  templateUrl: './app-change-position-modal.component.html',
  styleUrls: ['./app-change-position-modal.component.scss'],
})
export class AppChangePositionModalComponent implements OnInit {
  @Input() array_slot: Array<any>;
  @Output() closeModal = new EventEmitter();
  @Input() zone_id: string;
  //check from modal slot
  isCheckOpenFormModalSlot: boolean;
  isOpenTable: boolean = false;

  slotSelect: any[] = [];

  //table
  displayColumn = [
    '#',
    'Hàng hóa',
    'Số lượng tồn',
    'Số lượng cần chuyển',
    // 'Thao tác',
  ];
  //form array
  slotForm = this.fb.group({
    slot: this.fb.array([]),
  });

  filterSlot: any = [];
  isCheckAll: boolean = true;
  constructor(
    private fb: FormBuilder,
    private slotService: SlotService,
    private inventoryMap: InventoryMapService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.array_slot) {
      this.getSlotFormMap(this.array_slot.reverse());
    }
  }

  ngOnChanges() {}

  ngOnDestroy() {
    this.array_slot = [];
  }

  //push
  // async pushSlot() {
  //   let lstSlot = await this.getAllSlot(this.zone_id);
  //   let lstSlotFrom = await this.getAllSlot(this.zone_id);

  //   let lst = lstSlotFrom;

  //   this.slotForm.value.slot.forEach((element) => {
  //     lst = lst.filter((x) => x.id != element.fromSlot_id);
  //   });

  //   this.slot.push(
  //     this.fb.group({
  //       fromSlot_id: [],
  //       fromCode: [],
  //       fromPercent: [],
  //       fromExtend_stock_available: [],
  //       fromProduct: [],
  //       fromStock: [],
  //       fromListSlot: [lst],
  //       toListSlot: [lstSlot],
  //       toSlot_id: [],
  //       toCode: [],
  //       toPercent: [],
  //     })
  //   );
  // }

  //remove
  async removeSlot(index, item) {
    let lstSlot = await this.getAllSlot(this.zone_id);
    let slot = {};
    if (item.value.fromPercent != 100) {
      lstSlot.forEach((el) => {
        if (el.id == item.value.fromSlot_id) {
          slot = el;
        }
      });
      if (slot) {
        this.slotForm.value.slot.forEach((element, i) => {
          if (i != index) {
            let array: any[] = [];
            array = element.toListSlot.concat(slot);
            (this.slot.at(i) as FormGroup).patchValue({
              toListSlot: array,
            });
          }
        });
        this.slot.removeAt(index);
      }
    } else {
      this.slot.removeAt(index);
    }
  }
  //save
  saveChangePosition(event) {
    if (event) {
      let isCheck = false;
      let isSelect = false;
      this.slotForm.value.slot.forEach((element) => {
        let totalQuantity = 0;
        element.fromExtend_stock_available.forEach((item) => {
          if ((item.isError && item.isEdit) || (!item.isError && item.isEdit)) {
            isCheck = true;
          }
          if (item.isSelect) {
            isSelect = true;
          }
          totalQuantity += item.quantity;
        });
        Object.assign(element, {
          totalQuantity: totalQuantity,
        });
      });

      if (isCheck) {
        this.toastrService.warnning('Vui lòng lưu danh sách hàng hóa');
        return;
      }

      if (!isSelect) {
        this.toastrService.warnning('Vui lòng chọn hàng hóa để chuyển');
        return;
      }

      let array = [];

      //push to new array
      this.slotForm.value.slot.forEach((element) => {
        element.fromExtend_stock_available.forEach((item) => {
          if (item.isSelect) {
            array.push({
              fromSlotId: element.fromSlot_id,
              toSlotId: element.toSlot_id,
              productId: item.product_id,
              quantity: item.quantityChange,
              percent:
                Math.round(
                  (item.quantityChange * element.fromPercent) /
                    element.totalQuantity
                ) != 0
                  ? Math.round(
                      (item.quantityChange * element.fromPercent) /
                        element.totalQuantity
                    )
                  : 1,
            });
          }
        });
      });

      Promise.all(
        array.map((el, index) => {
          setTimeout(() => {
            this.slotService
              .apiSlotsChangeSlotPut(
                el.fromSlotId,
                el.toSlotId,
                el.productId,
                el.quantity,
                el.percent
              )
              .toPromise()
              .then((res) => {});
          }, (index + 1) * 200);
        })
      ).then((res) => {
        setTimeout(() => {
          this.closeModal.emit(true);
          this.toastrService.success('Thao tác thành công');
        }, 800);
      });
    } else {
      this.slot.clear();
      this.closeModal.emit(false);
    }
  }

  // Get slot
  getSlotFormMap(array_slot) {
    this.FilterSlot();
    this.isCheckOpenFormModalSlot = array_slot['isCheckOpenFormModalSlot'];
    array_slot.forEach((item) => {
      this.getSlotId(item.id_slot, this.zone_id);
    });
  }

  getSlotId(id_slot, zone_id) {
    this.slotService
      .apiSlotIdGet(id_slot, zone_id)
      .toPromise()
      .then(async (res) => {
        if (res.success) {
          if (this.isCheckOpenFormModalSlot) {
            let lstSlot = await this.getAllSlot(zone_id);
            res.data['extend_stock_available'].forEach((element) => {
              Object.assign(element, {
                quantityChange: element.quantity,
                isSelect: true,
                isEdit: false,
                isError: null,
              });
            });

            this.slot.push(
              this.fb.group({
                fromSlot_id: [res.data.id],
                fromCode: [res.data.code],
                fromPercent: [res.data.used_percent],
                fromExtend_stock_available: [
                  res.data['extend_stock_available'],
                  Validators.required,
                ],

                toListSlot: [
                  lstSlot.filter(
                    (x) => x.id != res.data.id && x.used_percent != 100
                  ),
                ],
                toSlot_id: [
                  {
                    value: null,
                    disabled:
                      res.data['extend_stock_available'].length > 0
                        ? false
                        : true,
                  },
                  Validators.required,
                ],
                toCode: [],
                toPercent: [],
              })
            );
          } else {
            let lstSlotTo = await this.FilterSlot();

            res.data['extend_stock_available'].forEach((element) => {
              Object.assign(element, {
                quantityChange: element.quantity,
                isSelect: true,
                isEdit: false,
                isError: null,
              });
            });
            this.slot.push(
              this.fb.group({
                fromSlot_id: [res.data.id],
                fromCode: [res.data.code],
                fromPercent: [res.data.used_percent],
                fromExtend_stock_available: [
                  res.data['extend_stock_available'],
                  Validators.required,
                ],
                fromListSlot: [],
                toListSlot: [lstSlotTo.filter((x) => x.used_percent != 100)],
                toSlot_id: [
                  {
                    value: null,
                    disabled:
                      res.data['extend_stock_available'].length > 0
                        ? false
                        : true,
                  },
                  Validators.required,
                ],
                toCode: [],
                toPercent: [],
                isOpenTable: [false],
                slotHasSelectd: [],
              })
            );
          }
        }
      });
  }

  async FilterSlot() {
    let lstSlotTo = await this.getAllSlot(this.zone_id);

    this.array_slot.forEach((el) => {
      lstSlotTo = lstSlotTo.filter((x) => x.id != el.id_slot);
    });
    return lstSlotTo;
  }
  async getAllSlot(zone_id) {
    let res = [];

    let data = await this.inventoryMap
      .apiInventoryMapZoneIdGet(zone_id)
      .toPromise();
    res = data.data.slots;

    return res;
  }

  getDataToSlot(data, index) {
    if (data) {
      //**** psuh slot to another index  depend on array of slot selected
      let object = [];
      if (
        this.slotForm.value.slot[index].slotHasSelectd &&
        this.slotForm.value.slot[index].slotHasSelectd != data.id
      ) {
        this.slotSelect.forEach((item, i) => {
          if (item.id == this.slotForm.value.slot[index].slotHasSelectd) {
            object.push(item);
          }
        });
      }
      this.slotSelect.push(data);
      this.slotForm.value.slot.forEach((element, i) => {
        if (i != index) {
          let array: any[] = [];
          array = element.toListSlot.concat(object);
          (this.slot.at(i) as FormGroup).patchValue({
            toListSlot: array.filter((x) => x.id != data.id),
          });
        }
      });
      (this.slot.at(index) as FormGroup).patchValue({
        toCode: data.code,
        slotHasSelectd: data.id,
      });
      //****

      //***when push success , remove it
      var indexOfSlotSelect = this.slotSelect.indexOf(object[0]);
      if (indexOfSlotSelect !== -1) {
        this.slotSelect.splice(indexOfSlotSelect, 1);
      }
      //***
    } else {
      //**** psuh slot to another index  depend on array of slot selec
      (this.slot.at(index) as FormGroup).patchValue({
        toCode: null,
      });
      let object = [];
      this.slotSelect.forEach((item, i) => {
        if (item.id == this.slotForm.value.slot[index].slotHasSelectd) {
          object.push(item);
          this.slotSelect.filter((x) => x.id != item.id);
        }
      });

      this.slotForm.value.slot.forEach((element, i) => {
        if (i != index) {
          let array: any[] = [];
          array = element.toListSlot.concat(object);
          (this.slot.at(i) as FormGroup).patchValue({
            toListSlot: array,
          });
        }
      });
      //****

      //***when push success , remove it
      var indexOfSlotSelect = this.slotSelect.indexOf(object[0]);
      if (indexOfSlotSelect !== -1) {
        this.slotSelect.splice(indexOfSlotSelect, 1);
      }
      //****
    }
  }
  getDataFromSlot(data, index) {
    if (data) {
      (this.slot.at(index) as FormGroup).patchValue({
        fromCode: data.code,
        fromPercent: data.used_percent,
      });
    } else {
      (this.slot.at(index) as FormGroup).patchValue({
        fromCode: null,
        fromPercent: null,
      });
    }
  }

  //choose Product to change postion
  changeProduct(data, index) {
    //  Property 'controls' does not exist on type 'AbstractControl'
    // PatchValue or setValue with formGroup more formArray and formGroup
    // https://stackoverflow.com/questions/53575046/patchvalue-or-setvalue-with-formgroup-more-formarray-and-formgroup

    if (data) {
      (this.slot.at(index) as FormGroup)
        .get('fromStock')
        .patchValue(data.quantity);
    }
  }

  //edit-save-quantity
  editQuantity(item) {
    item.isEdit = true;
  }
  saveQuantity(item) {
    if (!item.isError) {
      item.isEdit = false;
    }
  }
  selectAll(data) {
    this.isCheckAll = !this.isCheckAll;
    data.forEach((element) => {
      element.isSelect = this.isCheckAll;
    });
  }
  onChangeQuantity(data, item) {
    if (data > item.quantity) {
      item.isError = 'Số lượng cần chuyển lớn hơn số lượng tồn';
    }
    if (data == item.quantity || data < item.quantity) {
      item.isError = null;
    }
    if (!data) {
      item.isError = 'Vui lòng nhập số lượng';
    }
    if (data == 0) {
      item.isError = 'Số lượng cần chuyển phải lớn hơn 0';
    }
  }

  //
  openTable(i, isOpen) {
    (this.slot.at(i) as FormGroup).get('isOpenTable').patchValue(!isOpen);
  }
  //
  get slot() {
    return this.slotForm.get('slot') as FormArray;
  }
}
