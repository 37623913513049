import { ProductTemplateService } from 'src/app/api/productTemplate.service';
import { ProductTemplate } from 'src/app';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'meu-product-categories-delete',
  templateUrl: './product-categories-delete.component.html',
  styleUrls: ['./product-categories-delete.component.scss'],
})
export class ProductCategoriesDeleteComponent implements OnInit {
  @Input() id_product: string;
  @Output() closeModalProduct = new EventEmitter();
  @Output() closeModalDelProduct = new EventEmitter();
  productTemplate: ProductTemplate = {};

  constructor(private productCategoriesService: ProductTemplateService) {}
  nameButton: string;
  ngOnInit(): void {
    this.getProductId();
  }
  closeModal() {
    this.closeModalProduct.emit();
  }
  closeModalDel() {
    this.closeModalDelProduct.emit();
  }
  getProductId() {
    console.log('id xoa cua bang del ', this.id_product);
    this.productCategoriesService
      .apiProductTemplateIdGet(this.id_product)
      .subscribe((data) => {
        if (data.success == true) {
          this.productTemplate = data.data;
          this.nameButton = 'Xóa';
        } else {
          alert('Fail to get data');
        }
        console.log('du lieu xoa', data);
      });
  }
  deleteProductWithId() {
    console.log('xoa id: ', this.id_product);
    this.productCategoriesService
      .apiProductTemplateIdDelete(this.id_product)
      .subscribe(() => {
        this.closeModalDel();
      });
  }
}
