import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDoughnutChartComponent } from './components/chartjs/app-doughnut-chart/app-doughnut-chart.component';
import { ModalComponentComponent } from './components/modal/modal-component/modal-component.component';
import { PaginationComponentComponent } from './components/paginator/pagination-component/pagination-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalConfirmComponent } from './components/modal/modal-confirm/modal-confirm.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ToastrComponent } from './components/modal/toastr/toastr.component';
import { ModalPopupBottomComponent } from './components/modal/modal-popup-bottom/modal-popup-bottom.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MyDefaultTooltipOptions } from './default-options-tooltip/default-options';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { LocalStoreService } from './services/local-store.service';
import { PermissionDirective } from './directives/permission-attr.directive';
import { MeucurrencyPipe } from './pipes/meucurrency.pipe';

import { NgSelectModule } from '@ng-select/ng-select';
import { ControlSelectSearchLoadMoreComponent } from './components/control-select-search-load-more/control-select-search-load-more.component';
import { ControlSelectSearchComponent } from './components/control-select-search/control-select-search.component';

@NgModule({
  declarations: [
    AppDoughnutChartComponent,
    ModalComponentComponent,
    PaginationComponentComponent,
    ModalConfirmComponent,
    ModalPopupBottomComponent,
    SelectDropdownComponent,
    ClickOutsideDirective,
    ToastrComponent,
    PermissionDirective,
    MeucurrencyPipe,
    ControlSelectSearchLoadMoreComponent,
    ControlSelectSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions),
    NgSelectModule,
    ReactiveFormsModule,
  ],
  providers: [LocalStoreService, MeucurrencyPipe],
  exports: [
    AppDoughnutChartComponent,
    ModalComponentComponent,
    PaginationComponentComponent,
    ModalConfirmComponent,
    ClickOutsideDirective,
    ToastrComponent,
    ModalPopupBottomComponent,
    TooltipModule,
    SelectDropdownComponent,
    PermissionDirective,
    MeucurrencyPipe,
    ControlSelectSearchLoadMoreComponent,
    ControlSelectSearchComponent,
  ],
})
export class SharedModule {}
