<div
  *ngIf="arrayImExport.length != 0"
  class="grid grid-cols-12 gap-4 p-16px scroll"
>
  <div class="col-span-full md:col-span-3">
    <div
      class="flex h-full flex-grow-0 rounded-lg border border-solid border-grayscale-light-grey bg-white"
    >
      <div *ngIf="ImExport" class="col-span-full flex">
        <div
          class="h-full w-5px bg-grayscale-light-grey rounded-l-md"
          *ngIf="!imExport_code"
        ></div>

        <div
          class="h-full w-5px rounded-l-md bg-grayscale-light-grey cursor-pointer"
          *ngIf="imExport_code && is_draft"
          tooltip="Phiếu nháp"
          placement="top"
        ></div>

        <div
          class="h-full w-5px rounded-l-md bg-green-blue"
          *ngIf="imExport_code && !is_draft && is_checked"
        ></div>

        <div
          class="h-full w-5px rounded-l-md bg-grayscale-light-grey"
          *ngIf="imExport_code && !is_draft && !is_checked"
        ></div>
      </div>
      <div *ngIf="!ImExport" class="col-span-full flex">
        <div
          class="h-full w-5px bg-grayscale-light-grey rounded-l-md"
          *ngIf="!code"
        ></div>

        <div
          class="h-full w-5px rounded-l-md bg-grayscale-light-grey cursor-pointer"
          *ngIf="code && arrayImExport[0]?.is_draft"
          tooltip="Phiếu nháp"
          placement="top"
        ></div>

        <div
          class="h-full w-5px rounded-l-md bg-green-blue"
          *ngIf="
            code && !arrayImExport[0]?.is_draft && arrayImExport[0]?.is_checked
          "
        ></div>

        <div
          class="h-full w-5px rounded-l-md bg-grayscale-light-grey"
          *ngIf="
            code && !arrayImExport[0]?.is_draft && !arrayImExport[0]?.is_checked
          "
        ></div>
      </div>

      <div
        class="flex w-full flex-col xl:flex-row xl:justify-between py-16px px-10px items-center"
      >
        <div class="flex flex-col">
          <span
            [ngClass]="
              ImExport ? 'text-primary-dodger-blue' : 'text-blood-orange'
            "
            class="text-16px font-roboto-medium"
            >{{ title }}</span
          >
          <div class="grid">
            <span
              class="text-20px font-roboto-medium text-dark-grey"
              tooltip="Mã phiếu xuất"
              >{{ code ? code : '---' }}</span
            >

            <span
              class="text-14px font-roboto-medium text-dark-grey"
              tooltip="Ngày tạo"
              >{{ arrayImExport[0]?.created_at | date: 'dd/MM/yyyy HH:mm' }}
            </span>
          </div>
        </div>
        <div class="col-span-4 opacity-100" *ngIf="code; else showQRCode">
          <qrcode
            [qrdata]="code"
            [width]="100"
            [errorCorrectionLevel]="'M'"
            [elementType]="'svg'"
            [margin]="0"
          ></qrcode>
        </div>
        <ng-template #showQRCode>
          <div class="col-span-4 opacity-60">
            <qrcode
              [qrdata]="qrExportData"
              [width]="100"
              [errorCorrectionLevel]="'M'"
              [elementType]="'svg'"
              [margin]="0"
            ></qrcode>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-span-full md:col-span-9">
    <div class="grid grid-cols-5 gap-20px">
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Khách hàng
        </span>
        <meu-control-select-search-load-more
          [defaultID]="arrayImExport[0]?.customer_id"
          [nameAPI]="'/api/users'"
          [columnName]="'full_name'"
          [is_readonly]="true"
          ngDefaultControl
        ></meu-control-select-search-load-more>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Mã hợp đồng
        </span>
        <div class="input">{{ arrayImExport[0]?.contract_code }}</div>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Người lập phiếu
        </span>

        <meu-control-select-search-load-more
          [defaultID]="arrayImExport[0]?.created_by"
          [nameAPI]="'/api/users'"
          [columnName]="'full_name'"
          [is_readonly]="true"
          ngDefaultControl
        ></meu-control-select-search-load-more>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Người kiểm kê
        </span>

        <meu-control-select-search-load-more
          [defaultID]="arrayImExport[0]?.implementer_id"
          [nameAPI]="'/api/users'"
          [columnName]="'full_name'"
          [is_readonly]="true"
          ngDefaultControl
        ></meu-control-select-search-load-more>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          {{ ImExport ? 'Số container' : 'Mã phiếu nhập/xuất' }}
        </span>
        <div
          [tooltip]="
            ImExport
              ? arrayImExport[0]?.container_code || '--'
              : lstImportCode || '--'
          "
          class="input"
        >
          {{
            ImExport
              ? arrayImExport[0]?.container_code || '--'
              : lstImportCode || '--'
          }}
        </div>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Người nhận
        </span>
        <div class="input">{{ arrayImExport[0]?.receiver || '--' }}</div>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Nhân viên kinh doanh
        </span>
        <meu-control-select-search-load-more
          [defaultID]="arrayImExport[0]?.seller_id"
          [nameAPI]="'/api/users'"
          [columnName]="'full_name'"
          [is_readonly]="true"
          ngDefaultControl
        ></meu-control-select-search-load-more>
      </div>
      <div class="col-span-full md:col-span-1">
        <span class="font-roboto-regular text-12px text-grey-label">
          Số xe
        </span>
        <div class="input">{{ arrayImExport[0]?.receive_car || '--' }}</div>
      </div>
      <div class="col-span-full md:col-span-2">
        <span class="font-roboto-regular text-12px text-grey-label">
          Ghi chú
        </span>
        <div class="input">{{ arrayImExport[0]?.note || '--' }}</div>
      </div>
    </div>
  </div>
  <div class="col-span-full mt-10px">
    <span class="font-roboto-medium font-medium text-dark-grey text-16px"
      >Danh sách hàng hóa</span
    >
  </div>
  <div *ngIf="ImExport" class="col-span-full table-import mt-10px">
    <table class="w-full border-collapse">
      <!-- Header -->
      <tr
        class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
      >
        <th class="md:text-center col-no">#</th>

        <th class="text-left col-code items-center cursor-pointer">
          <div class="items-center">Mã hàng hóa</div>
        </th>

        <th class="text-left col-name items-center cursor-pointer">
          <div class="items-center">Tên hàng hóa</div>
        </th>

        <th class="text-left col-sku">
          <div class="items-center">SKU</div>
        </th>

        <th class="text-left col-supplier cursor-pointer">
          <div class="items-center">Nhà cung cấp</div>
        </th>

        <th class="text-right col-quantity cursor-pointer">
          <div class="items-center">Số lượng</div>
        </th>

        <th class="text-left col-unit cursor-pointer">
          <div class="items-center">ĐVT</div>
        </th>

        <th class="text-right col-netWeigth cursor-pointer">
          <div class="items-center">Quy cách</div>
        </th>

        <th class="text-left col-expiry-date cursor-pointer">
          <div class="items-center">Ngày hết hạn</div>
        </th>

        <th class="text-left col-number">
          <div class="items-center">Vị trí ô</div>
        </th>
      </tr>

      <!-- Body -->
      <tr
        *ngFor="let element of arrayImExport; let i = index"
        class="cursor-pointer"
      >
        <td class="td-index text-14px text-center">{{ i + 1 }}</td>

        <td class="td-product-code">
          <div class="grid grid-cols-12 items-center">
            <div
              class="col-span-12 overflow-hidden overflow-ellipsis text-primary-dodger-blue text-14px"
              [tooltip]="element.product_code ? element.product_code : '---'"
            >
              {{ element.product_code ? element.product_code : '---' }}
            </div>
          </div>
        </td>

        <td class="td-product-name">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
            [tooltip]="element.product_name ? element.product_name : '---'"
          >
            {{ element.product_name ? element.product_name : '---' }}
          </div>
        </td>

        <td class="td-sku-code">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
            [tooltip]="element.sku_code ? element.sku_code : '---'"
          >
            {{ element.sku_code ? element.sku_code : '---' }}
          </div>
        </td>
        <td class="td-supplier">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
            [tooltip]="element.supplier_name ? element.supplier_name : '---'"
          >
            {{ element.supplier_name ? element.supplier_name : '---' }}
          </div>
        </td>

        <!-- số lượng -->
        <td class="td-quantity" style="max-width: 60px">
          <div
            class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="
              element.quantity ? (element.quantity | meucurrency) : '---'
            "
          >
            {{ element.quantity ? (element.quantity | meucurrency) : '---' }}
          </div>
        </td>
        <!-- đơn vị -->
        <td class="td-unit-name" style="max-width: 70px">
          <div
            class="text-14px overflow-ellipsis overflow-hidden whitespace-nowrap div-ellip"
            [tooltip]="element.unit_name ? element.unit_name : '---'"
          >
            {{ element.unit_name ? element.unit_name : '---' }}
          </div>
        </td>
        <!-- số Quy cách -->
        <td class="td-netWeigth" style="max-width: 70px">
          <div
            class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="element.net_weigth ? element.net_weigth : '---'"
          >
            {{ element.net_weigth ? element.net_weigth : '---' }}
          </div>
        </td>

        <td class="td-expiry-date">
          <div
            class="text-14px overflow-hidden overflow-ellipsis whitespace-nowrap div-ell-date"
            [tooltip]="element.expiry_date ? element.expiry_date : '---'"
          >
            {{ element.expiry_date ? element.expiry_date : '---' }}
          </div>
        </td>

        <td class="td-hashtag">
          <div class="flex flex-wrap -ml-5px">
            <div
              *ngFor="let tag of element.selectHashtag; index as i"
              class="w-auto h-auto flex-grow-0 gap-4px rounded-4px border border-solid border-grey-light m-1 py-2px px-4px text-12px"
            >
              <label *ngIf="tag.quantity > 0; else show"
                >{{ tag.code }} ({{ tag.quantity }})</label
              >
              <ng-template #show>
                <label>{{ tag.code }}</label>
              </ng-template>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="!ImExport" class="col-span-full table-export mt-10px">
    <table class="w-full border-collapse">
      <!-- Header -->
      <tr
        class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden text-grey-med"
      >
        <th class="md:text-center col-no">#</th>

        <th class="text-left col-code items-center cursor-pointer">
          <div class="items-center">Mã hàng hóa</div>
        </th>

        <th class="text-left col-name items-center cursor-pointer">
          <div class="items-center">Tên hàng hóa</div>
        </th>

        <th class="text-left col-sku">
          <div class="items-center">SKU</div>
        </th>

        <th class="text-left col-supplier">
          <div class="items-center">Nhà cung cấp</div>
        </th>

        <!-- <th class="text-right col-stock cursor-pointer">
          <div class="items-center">Tồn kho</div>
        </th> -->

        <th class="text-right col-quantity cursor-pointer">
          <div class="items-center">SL xuất</div>
        </th>

        <th class="text-left col-unit cursor-pointer">
          <div class="items-center">ĐVT</div>
        </th>

        <th class="text-right col-netWeigth cursor-pointer">
          <div class="items-center">Quy Cách</div>
        </th>

        <th class="text-right col-realweight cursor-pointer">
          <div class="items-center">Khối lượng thực</div>
        </th>

        <th class="text-left col-batch-code cursor-pointer hidden">
          <div class="items-center">Số lô</div>
        </th>

        <th class="text-left col-number">
          <div class="items-center">Số ô</div>
        </th>
        <th class="hidden">Mã phiếu nhập</th>
      </tr>

      <!-- Body -->
      <tr
        *ngFor="let element of arrayImExport; let i = index"
        class="cursor-pointer"
      >
        <td class="td-index text-center">{{ i + 1 }}</td>

        <td class="td-product-code">
          <div class="grid grid-cols-12 items-center">
            <div
              class="col-span-12 overflow-hidden overflow-ellipsis text-primary-dodger-blue text-14px"
              [tooltip]="element.product_code ? element.product_code : '---'"
            >
              {{ element.product_code ? element.product_code : '---' }}
            </div>
          </div>
        </td>

        <td class="td-product-name">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
            [tooltip]="element.product_name ? element.product_name : '---'"
          >
            {{ element.product_name ? element.product_name : '---' }}
          </div>
        </td>

        <td class="td-sku-code">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
            [tooltip]="element.sku_code ? element.sku_code : '---'"
          >
            {{ element.sku_code ? element.sku_code : '---' }}
          </div>
        </td>

        <td class="td-supplier" style="max-width: 120px">
          <div
            class="overflow-hidden overflow-ellipsis whitespace-nowrap text-14px div-ell"
            [tooltip]="element.supplier_name ? element.supplier_name : '---'"
          >
            {{ element.supplier_name ? element.supplier_name : '---' }}
          </div>
        </td>

        <!-- <td class="td-stock" style="max-width: 70px">
          <div
            class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="element.stock ? (element.stock | meucurrency) : 0"
          >
            {{ element.stock ? (element.stock | meucurrency) : 0 }}
          </div>
        </td> -->

        <td class="td-quantity" style="max-width: 70px">
          <div
            class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="
              element.totalQuantity ? (element.totalQuantity | meucurrency) : 0
            "
          >
            {{
              element.totalQuantity ? (element.totalQuantity | meucurrency) : 0
            }}
          </div>
        </td>

        <td class="td-unit-name" style="max-width: 70px">
          <div
            class="text-14px overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="element.unit_name ? element.unit_name : '---'"
          >
            {{ element.unit_name ? element.unit_name : '---' }}
          </div>
        </td>

        <!-- số Kg/ĐVT -->
        <td class="td-netWeigth text-right" style="max-width: 60px">
          <div
            class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="element.net_weight ? element.net_weight : '---'"
          >
            <label>{{ element.net_weight ? element.net_weight : '---' }}</label>
          </div>
        </td>

        <!-- Khối lượng thực -->
        <td class="td-realweight" style="max-width: 60px">
          <div
            class="text-14px text-right overflow-ellipsis overflow-hidden whitespace-nowrap"
            [tooltip]="element.real_net_weight ? element.real_net_weight : 0"
          >
            {{ element.real_net_weight ? element.real_net_weight : 0 }}
          </div>
        </td>

        <td class="td-batch-code hidden" style="max-width: 60px">
          <div
            class="text-14px overflow-hidden overflow-ellipsis whitespace-nowrap div-ell"
            [tooltip]="element.batch_code ? element.batch_code : '---'"
          >
            {{ element.batch_code ? element.batch_code : '---' }}
          </div>
        </td>

        <td class="td-hashtag">
          <div class="flex flex-wrap -ml-5px">
            <div
              *ngFor="let tag of element.selectHashtag; index as i"
              class="w-auto h-auto flex-grow-0 gap-4px rounded-4px border border-solid border-grey-light m-1 py-2px px-4px text-12px"
            >
              <label *ngIf="tag.quantity > 0; else show"
                >{{ tag.code }} ({{ tag.quantity }})</label
              >
              <ng-template #show>
                <label>{{ tag.code }}</label>
              </ng-template>
            </div>
          </div>
        </td>

        <td class="hidden">
          {{ element.import_code }}
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="footer w-full gap-8px flex py-15px px-20px justify-end">
  <button
    class="btn-cancel flex items-center gap-2px"
    type="button"
    (click)="closeModalImExport()"
  >
    <img src="../../../../assets/icons/handle-icons/u_times.svg" alt="" />
    Đóng
  </button>
</div>
