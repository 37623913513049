import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';

@Component({
  selector: 'meu-app-menu-mobile',
  templateUrl: './app-menu-mobile.component.html',
  styleUrls: ['./app-menu-mobile.component.scss'],
})
export class AppMenuMobileComponent implements OnInit {
  inventoryId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localStoreService: LocalStoreService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //set inventory
    this.setInventoryId();
  }

  setInventoryId() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.inventoryId && params.inventoryId != 'null') {
        this.inventoryId = params.inventoryId;
      }
    });

    if (
      this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      )
    ) {
      this.inventoryId = this.localStoreService.getFromToLocalStorage(
        this.localStoreService.localStorageItem.inventoryId
      );
    }
  }
  nav(flag) {
    this.router.navigate(
      [`/inventory/${this.inventoryId}/import-export-mobile`],
      { fragment: flag }
    );
  }
}
