import { SummaryContainerService } from 'src/app/view/sales-management/container-management/modal-summary-container/summary-container.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'meu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isPrint = false;

  constructor(private summaryContainerService: SummaryContainerService) {}

  ngOnInit(): void {
    this.summaryContainerService.is_print.subscribe((res) => {
      this.isPrint = res;
    });
  }
}
