// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authTokenKey: 'auth',
  privateKeyToken: 'tokenKey',
  userInfo: 'userInfo',
  backendhost: 'https://backend.inventory.erp.meu-solutions.com',
  notificationHubUrl: 'https://gateway.dev.meu-solutions.com/inventorynotificationhub',
  ssoFeUrl: 'https://sso.inventory.erp.meu-solutions.com',
  firebaseConfig: {
    apiKey: 'AIzaSyAkrjxWXjxwjbujH2n5q2bJv-fwWp8Hr0Q',
    authDomain: 'meu-hub.firebaseapp.com',
    databaseURL: 'https://meu-hub-default-rtdb.firebaseio.com',
    projectId: 'meu-hub',
    storageBucket: 'meu-hub.appspot.com',
    messagingSenderId: '736613345497',
    appId: '1:736613345497:web:369153d69f83c2f1d7b6c8',
    measurementId: 'G-YNYH1QFCFY',
  },
  channel: 'inventory',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
