<div style="max-height: 500px; overflow: hidden; overflow-y: auto">
  <form
    [formGroup]="formcreate"
    class="grid grid-cols-2 gap-x-4 gap-y-2 px-16px pt-8px"
  >
    <div class="col-span-full">
      <span
          class="font-roboto-regular text-12px text-warm-grey"
          >Loại thuê bao
      </span>
      <ng-select
          class="mt-8px focus:outline-none select-name"
          placeholder="Chọn loại thuê bao"
          bindLabel="name"
          bindValue="id"
          [clearable]="false"
          [items]="subscriberType"
          formControlName="subscriber_type"
          (change)="getValueSubscriberType($event)"
          notFoundText="Không tìm thấy loại thuê bao"
        >
      </ng-select>
    </div>

    <div class="col-span-1">
      <div class="col-span-5">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            formcreate.get('type').touched &&
            formcreate.controls.type.errors?.required
              ? 'error-class'
              : ''
          "
          >Loại hợp đồng <span class="text-red-light">*</span>
        </span>

        <ng-select
          required
          [(ngModel)]="defaultType"
          class="mt-8px focus:outline-none"
          placeholder="Chọn loại hợp đồng"
          bindLabel="name"
          bindValue="id"
          [items]="contractType"
          formControlName="type"
          (change)="getValueTypeContract($event)"
          notFoundText="Không tìm thấy loại hợp đồng"
          [ngClass]="
            formcreate.get('type').touched &&
            formcreate.controls.type.errors?.required
              ? 'select-invalid'
              : 'select-name'
          "
        >
        </ng-select>

        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            formcreate.get('type').touched &&
            formcreate.controls.type.errors?.required
          "
        >
          {{
            formcreate.controls.type.errors?.required
              ? 'Vui lòng chọn loại hợp đồng'
              : ''
          }}
        </span>
      </div>
    </div>
    <div class="col-span-1">
      <div class="sm:col-span-5">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            formcreate.get('contractTemplate').touched &&
            formcreate.controls.contractTemplate.errors?.required
              ? 'error-class'
              : ''
          "
          >Mẫu hợp đồng <span class="text-red-light">*</span>
        </span>
        <ng-select
          required
          [(ngModel)]="defaultTemplete"
          class="mt-8px focus:outline-none"
          placeholder="Chọn mẫu hợp đồng"
          bindLabel="name"
          bindValue="id"
          [items]="contractTemplate"
          formControlName="contractTemplate"
          (change)="getValueTemplateContract($event)"
          notFoundText="Không tìm thấy mẫu hợp đồng"
          [ngClass]="
            formcreate.get('contractTemplate').touched &&
            formcreate.controls.contractTemplate.errors?.required
              ? 'select-invalid'
              : 'select-name'
          "
        >
        </ng-select>
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            formcreate.get('contractTemplate').touched &&
            formcreate.controls.contractTemplate.errors?.required
          "
        >
          {{
            formcreate.controls.contractTemplate.errors?.required
              ? 'Vui lòng chọn mẫu hợp đồng'
              : ''
          }}
        </span>
      </div>
    </div>
    <div class="col-span-1">
      <div class="sm:col-span-5">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            formcreate.get('name').touched &&
            formcreate.controls.name.errors?.required
              ? 'error-class'
              : ''
          "
          >Tên khách hàng <span class="text-red-light">*</span>
        </span>
        <ng-select
          [ngClass]="
            formcreate.get('name').touched &&
            formcreate.controls.name.errors?.required
              ? 'select-invalid'
              : 'select-name'
          "
          required
          class="mt-8px focus:outline-none"
          placeholder="Chọn khách hàng"
          bindLabel="full_name"
          bindValue="id"
          [items]="users"
          formControlName="name"
          notFoundText="Không tìm thấy khách hàng"
          (change)="getValueUser($event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="flex items-center space-x-2">
              <img
                height="20"
                width="20"
                [src]="item.avatar"
                class="rounded-full"
              />
              <span class="text-14px font-roboto-regular text-dark-grey">{{
                item.full_name
              }}</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="flex items-center space-x-2">
              <img height="20" width="20" [src]="item.avatar" />
              <span>{{ item.full_name }}</span>
            </div>
          </ng-template>
        </ng-select>
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            formcreate.get('name').touched &&
            formcreate.controls.name.errors?.required
          "
        >
          {{
            formcreate.controls.name.errors?.required
              ? 'Vui lòng chọn khách hàng'
              : ''
          }}
        </span>
      </div>
    </div>
    <div class="col-span-1">
      <span
        class="font-roboto-regular text-12px text-warm-grey"
        [ngClass]="formcreate.get('code').touched && formcreate.controls.code.errors?.maxlength ? 'error-class' : ''"
      >
        Mã hợp đồng
      </span>
      <input
        [ngClass]="formcreate.get('code').touched && formcreate.controls.code.errors?.maxlength ? 'class-invalid ': 'class-valid'"
        type="text"
        placeholder="Nhập mã hợp đồng"
        formControlName="code"
        class="w-full border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px px-12px h-40px focus:ring-1 focus:ring-primary-blue mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
      />
      <span 
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="formcreate.get('code').touched && 
          formcreate.controls.code.errors?.maxlength"
      >
          Mã hợp đồng không dài quá 50 ký tự
      </span>
    </div>
    <div class="col-span-1" *ngIf="isSubscriberTypePostpaid">
      <span
        class="font-roboto-regular text-12px text-warm-grey"
        [ngClass]="
          (formcreate.get('amount').touched &&
            formcreate.controls.amount.errors?.pattern) ||
            formcreate.controls.amount.errors?.pattern ||
          formcreate.controls.amount.errors?.required
            ? 'error-class'
            : ''
        "
      >
        Giá trị hợp đồng (VND) <span class="text-red-light">*</span>
      </span>
      <input
        [ngClass]="
          (formcreate.get('amount').touched &&
            formcreate.controls.amount.errors?.pattern) ||
          formcreate.controls.amount.errors?.pattern || 
          formcreate.controls.amount.errors?.required
            ? 'class-invalid'
            : 'class-valid'
        "
        type="text"
        required
        placeholder="Nhập giá trị hợp đồng"
        formControlName="amount"
        (keyup)="onChangeNumber($event.target.value,'amount')"
        [value]="formcreate.controls.amount.value | meucurrency"
        class="w-full text-right border rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-8px px-12px h-40px focus:ring-1 focus:ring-primary-blue mt-8px appearance-none placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
      />
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          (formcreate.get('amount').touched &&
            formcreate.controls.amount.errors?.pattern) ||
            formcreate.controls.amount.errors?.pattern||
          formcreate.controls.amount.errors?.required
        "
      >
        {{
          formcreate.controls.amount.errors?.pattern
            ? 'Vui lòng nhập đúng ký tự số'
            : 'Vui lòng nhập giá trị hợp đồng'
        }}
      </span>
    </div>
    <div class="col-span-1">
      <span class="font-roboto-regular text-12px text-warm-grey">
        Ngày ký
      </span>
      <input
        #dayPicker
        type="text"
        #dateToDp="dpDayPicker"
        [dpDayPicker]="datePickerConfig"
        [theme]="'dp-material'"
        formControlName="signature"
        [mode]="'day'"
        (onChange)="getDateTime($event)"
        placeholder="Nhập ngày ký"
        class="class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
      />
    </div>
    <div class="col-span-1">
      <div class="sm:col-span-5 relative">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            formcreate.get('status').touched &&
            formcreate.controls.status.errors?.required
              ? 'error-class'
              : ''
          "
          >Trạng thái
        </span>
        <ng-select
          class="mt-8px focus:outline-none select-name"
          placeholder="Chọn trạng thái hợp đồng"
          bindLabel="name"
          bindValue="id"
          [items]="status"
          formControlName="status"
          (change)="getValueStatusContract($event)"
          notFoundText="Không tìm thấy trạng thái hợp đồng"
          [(ngModel)]="defaultStatus"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-span-1 grid grid-cols-2 gap-x-4">
      <div class="" [ngClass]="isSubscriberTypePostpaid ? 'col-span-1':'col-span-2'">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
          [ngClass]="
            formcreate.get('start').touched &&
            formcreate.controls.start.errors?.required
              ? 'error-class'
              : ''
          "
        >
          Ngày bắt đầu <span class="text-red-light">*</span>
        </span>
        <input
          [ngClass]="
            formcreate.get('start').touched &&
            formcreate.controls.start.errors?.required
              ? 'class-invalid'
              : 'class-valid'
          "
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfig"
          [theme]="'dp-material'"
          formControlName="start"
          [mode]="'day'"
          (onChange)="getDateTime($event)"
          placeholder="Nhập ngày bắt đầu"
          class="class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
        />
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="
            formcreate.get('start').touched &&
            formcreate.controls.start.errors?.required
          "
        >
          {{
            formcreate.controls.start.errors?.required
              ? 'Vui lòng chọn ngày bắt đầu'
              : ''
          }}
        </span>
      </div>
      <div class="col-span-1" *ngIf="isSubscriberTypePostpaid">
        <span
          class="font-roboto-regular text-12px text-warm-grey"
        >
          Ngày kết thúc
        </span>
        <input
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfigEnd"
          [theme]="'dp-material'"
          formControlName="end"
          [mode]="'day'"
          (onChange)="getDateTime($event)"
          placeholder="Nhập ngày kết thúc"
          class="class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
        />
      </div>
    </div>
    
    <div class="col-span-2">
      <span
        class="font-roboto-regular text-12px text-warm-grey"
        [ngClass]="
          formcreate.controls.note.errors?.maxlength ? 'error-class' : ''
        "
      >
        Ghi chú
      </span>
      <textarea
        [ngClass]="
          formcreate.controls.note.errors?.maxlength
            ? 'class-invalid'
            : 'class-valid'
        "
        formControlName="note"
        placeholder="Nhập ghi chú"
        class="w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
      ></textarea>
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="formcreate.controls.note.errors?.maxlength"
      >
        {{
          formUpdate.controls.note.errors?.maxlength
            ? 'Mô tả tối đa 300 ký tự'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-2">
      <span class="text-12px font-roboto-regular text-warm-grey mb-2">
        Tài liệu đính kèm
      </span>
      <ngx-dropzone
        #commonDropzone
        (change)="onSelect($event)"
        id="common-dropzone"
        [multiple]="true"
        class="customBG mt-8px"
      >
        <ngx-dropzone-label
          class="opacity-60 flex items-center font-medium text-12px"
          *ngIf="filesLogo.length == 0"
        >
          <div class="flex flex-col">
            <img
              src="../../../../../assets/icons/contract-icons/u_image-v.svg"
              alt="Pic"
              class="h-20px w-20px m-auto"
            />
            <div class="text-12px font-roboto-regular text-purplish-grey flex">
              <span
                >Kéo thả tệp mới vào đây hoặc
                <span class="underline text-primary-blue">Duyệt</span></span
              >
            </div>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let itemFile of filesLogo; let i = index"
          [file]="itemFile"
          [removable]="true"
          (removed)="onRemove(itemFile)"
          [tooltip]="itemFile.name"
          class="customdrop"
        >
          <ngx-dropzone-label
            class="text-12px overflow-hidden overflow-ellipsis whitespace-nowrap"
            >Tài liệu {{ i + 1 }}</ngx-dropzone-label
          >
        </ngx-dropzone-preview>
      </ngx-dropzone>
      <span class="text-12px text-warm-grey font-roboto-regular ml-12px"
        >Kích thước tệp nhỏ hơn 2MB</span
      >
    </div>
  </form>
</div>
<div class="grid grid-cols-12 px-16px pt-16px z-1">
  <div class="col-span-12 flex-row-reverse gap-8px flex h-60px">
    <button
      class="btn-primary-add"
      type="button"
      (click)="submit()"
      [disabled]="!formcreate.valid"
    >
      Thêm
    </button>
    <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
  </div>
</div>
