<div class="container grid-contain">
    <div class="col-span-12 bg-none xl grid grid-cols-1 gap-0.5 mt-15px shadow-md rounded">
        <div class="col-span-12 bg-white h-56px text-20px text-left p-15px font-roboto-medium txt-header rounded-t-4px">Quản lý phân loại hàng</div>
        <!-- mobile not suport -->
        <div class="col-span-12 w-full h-full bg-white rounded-b hidden m-fixed text-center panel items-center justify-center">
            <span class="text-14px text-black">Không hỗ trợ hiển thị trên điện thoại</span>
        </div>
        
        <div class="col-span-12 grid gap-0.5 rounded-b-4px m-display">
            <div class="thanhngang grid grid-cols-5 gap-0.5 rounded-b-4px">
                <div 
                    class="bg-white col-span-1 rounded-bl-4px tree-folder p-2">
                        <meu-tree-view
                            [isRender]="isRender"
                            (current_folder_id) = "getProductById($event)"
                            (nodeCurrent)="nodeFolder($event)"
                            (newFolder)="openEditFolder($event)"
                            (reName)="openEditFolder($event)"
                        ></meu-tree-view>
                </div>
                <div class="bg-white col-span-4 rounded-br-4px">
                    <div class="flex flex-wrap pt-8px px-16px">
                        <div class="font-roboto-medium flex relative text-16px mt-5px flex-1" style="color: #505050">Danh sách phân loại hàng</div>
                            <div  >
                                <div class="relative">
                                    <input
                                      [disabled]="addProductMode"
                                      type="text"
                                      placeholder="Nhập từ khóa tìm kiếm"
                                      [(ngModel)]="searchProduct"
                                      (keyup)="keyUp.next($event.target.value)"
                                      (keydown)="onKeyDown()"
                                      class="m-auto h-32px w-320px border mt-2px mr-5px rounded-4px bg-white border-light-grey hover:border-primary-blue focus:outline-none py-10px pl-40px focus:ring-1 focus:ring-primary-blue appearance-none placeholder-light-grey placeholder-opacity-75 font-roboto-regular text-14px text-dark-grey"
                                    />
                                    <img
                                      class="absolute top-0 mt-9px ml-12px"
                                      src="assets/icons/handle-icons/Icons_search.svg"
                                    />
                                </div>
                            </div>
                            <button 
                                class="btn-primary-icon mt-2px mb-14px focus:outline-none w-108px h-32px rounded-4px items-center flex relative"
                                style="color: #fff;"
                                (click)="open(null)"
                                type="button"
                                [disabled]="addProductMode"
                                >
                                    <img
                                        class="w-16px h-16px pt-2px"
                                        src="assets/icons/handle-icons/icon_add.svg"
                                    />
                                    <label 
                                        class="ml-4px font-roboto-medium text-12px font-medium not-italic pt-3px"
                                        style="line-height: 1.43; letter-spacing: normal;">
                                        Thêm mới
                                    </label>
                            </button>
                        </div>
                    <div class="px-16px flex items-start table-product">
                        <table width="100%" >
                            <thead>
                                <tr 
                                    class="bg-lightest-grey font-roboto-medium text-12px leading-4 overflow-hidden"
                                    style="color: #505050;"
                                    >
                                    <th class="md:text-center hidden-col" style="width: 48px;">#</th>
                                    <th
                                        class="text-left col-code-unit cursor-pointer w-198px h-48px"
                                        (click)="Sort('code')"
                                    >
                                        <div class="grid grid-cols-10 items-center">
                                            <div class="col-span-7 ml-16px">Mã phân loại hàng</div>
                                            <div class="col-span-3 mr-16px">
                                                <img
                                                *ngIf="sort.includes('code'); else normalSearch"
                                                class="float-right"
                                                [src]="sortName"
                                                />
                                                <ng-template #normalSearch>
                                                <img
                                                    class="float-right"
                                                    src="assets/icons/sort/sort_normal.svg"
                                                />
                                                </ng-template>
                                            </div>
                                        </div>
                                    </th>
                                    <th
                                        class="text-left col-code-unit cursor-pointer w-300px h-48px"
                                        (click)="Sort('name')"
                                    >
                                        <div class="grid grid-cols-10 items-center">
                                            <div class="col-span-7 ml-16px">Tên phân loại hàng</div>
                                            <div class="col-span-3 mr-16px">
                                                <img
                                                *ngIf="sort.includes('name'); else normalSearch"
                                                class="float-right"
                                                [src]="sortName"
                                                />
                                                <ng-template #normalSearch>
                                                <img
                                                    class="float-right"
                                                    src="assets/icons/sort/sort_normal.svg"
                                                />
                                                </ng-template>
                                            </div>
                                        </div>
                                    </th>
                                    <th class="w-300px h-48px text-left pl-16px ">Mô tả</th>
                                    <th class="w-211px h-48px ">Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of products; let i = index">
                                    <td class="text-center w-48px h-40px font-roboto-regular text-14px">{{indexArray+i+1}}</td>
                                    <td class="text-left text-14px pl-16px h-40px font-roboto-regular">{{product.code}}</td>
                                    <td class="text-left text-14px pl-16px h-40px font-roboto-regular">{{product.name}}</td>
                                    <td class="text-left text-14px pl-16px h-40px font-roboto-regular">{{product.description}}</td>
                                    <td>
                                        <div class="flex justify-center">
                                            <button class="hover:bg-primary-lightest focus:outline-none w-40px h-40px p-10px rounded-full"
                                                (click)="open(product.id)"
                                            >
                                                <img src="../../../../assets/icons/product-categories-icons/u_edit-alt.svg" >
                                            </button>
                                            <button class="hover:bg-primary-lightest rounded-full focus:outline-none w-40px h-40px p-10px"
                                                (click)="deleteconfirm(product.id)"
                                            >
                                                <img src="../../../../assets/icons/product-categories-icons/u_trash-alt.svg" >
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p *ngIf="!isTable" class="font-roboto text-center mt-10px">Không có dữ liệu</p>
                    <div *ngIf="isTable" class="mx-16px">
                        <meu-pagination-component
                            [total]="totalArray"
                            [array]="array"
                            [changePage]="onPagingFunc"       
                        ></meu-pagination-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <meu-modal-component
        [isOpen]="isOpenModal"
        [options]="options"
        *ngIf="isOpenModal"
        [title]="titleModal"
    >
        <meu-product-categories-create
            [id_product] = "id_product"
            [id_parent_product]="id_parent_product"
            [node_current]="currentNode"
            (closeModalProduct)="closeModal($event)"
        >
        </meu-product-categories-create>
    </meu-modal-component>


    <!-- <meu-modal-component
    [isOpen]="isOpenModalDel"
    [options]="{ closeFromOutside: false, hideCloseButton: true }"
    *ngIf="isOpenModalDel"
    >
        <meu-product-categories-delete
            [id_product] = "id_product"
            (closeModalProduct) = "closeDelModal()"
            (closeModalDelProduct)="closeModalDelSucess()"
        >
        </meu-product-categories-delete>
    </meu-modal-component> -->
    <meu-modal-component
        [isOpen]="isEditFoler"
        [options]="options"
        *ngIf="isEditFoler"
        [title]="titleModal"
    >
        <meu-tree-view-edit
            [id_product]="id_product"
            [node_current]="currentNode"
            (closeModalProduct)="closeModalFolder($event)"
            (reRenderTree)="reRenderTree()"
        >
        </meu-tree-view-edit>
    </meu-modal-component>
</div>