<form [formGroup]="formUpdate" class="grid grid-cols-12 gap-4 pt-8px">
  <div class="col-span-6 grid grid-cols-12 gap-4 col-mobile">
    <div class="col-span-12">
      <span class="font-roboto-medium text-14px text-left leading-5 text-dark-grey">Thông tin cơ bản</span>
    </div>
    <div class="col-span-6 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('type').touched &&
          formUpdate.controls.type.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Loại hợp đồng <span class="text-red-light">*</span></span
      >

      <ng-select
        required
        [clearable]="false"
        class="mt-8px focus:outline-none"
        placeholder="Chọn loại hợp đồng"
        bindLabel="name"
        bindValue="id"
        [items]="typeContract"
        formControlName="type"
        (change)="getValueTypeContract($event)"
        notFoundText="Không tìm thấy loại hợp đồng"
        [ngClass]="
          formUpdate.get('type').touched &&
          formUpdate.controls.type.errors?.required
            ? 'select-invalid'
            : 'select-name'
        "
      >
      </ng-select>

      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          formUpdate.get('type').touched &&
          formUpdate.controls.type.errors?.required
        "
      >
        {{
          formUpdate.controls.type.errors?.required
            ? 'Vui lòng chọn loại hợp đồng'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-3 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('contractTemplate').touched &&
          formUpdate.controls.contractTemplate.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Mẫu hợp đồng <span class="text-red-light">*</span></span
      >

      <ng-select
          required
          [clearable]="false"
          class="mt-8px focus:outline-none"
          placeholder="Chọn mẫu hợp đồng"
          bindLabel="name"
          bindValue="id"
          [tooltip]="tlpContract"
          [items]="contractTemplate"
          formControlName="contractTemplate"
          (change)="getValueTemplateContract($event)"
          notFoundText="Không tìm thấy mẫu hợp đồng"
          [ngClass]="
            formUpdate.get('contractTemplate').touched &&
            formUpdate.controls.contractTemplate.errors?.required
              ? 'select-invalid'
              : 'select-name'
          "
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{item.name}}">{{item.name}}</div>
          </ng-template>
        </ng-select>

      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          formUpdate.get('contractTemplate').touched &&
          formUpdate.controls.contractTemplate.errors?.required
        "
      >
        {{
          formUpdate.controls.contractTemplate.errors?.required
            ? 'Vui lòng chọn loại hợp đồng'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-3 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('code').touched &&
          formUpdate.controls.code.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
        >Mã hợp đồng <span class="text-red-light">*</span></span
      >
      <input
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        [ngClass]="
          formUpdate.get('code').touched &&
          formUpdate.controls.code.errors?.required
            ? 'class-invalid'
            : 'class-valid'
        "
        type="text"
        placeholder="Nhập mã hợp đồng"
        formControlName="code"
        required
        class="w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          formUpdate.get('code').touched &&
          formUpdate.controls.code.errors?.required
        "
      >
        {{
          formUpdate.controls.code.errors?.required ? 'Vui lòng nhập mã ' : ''
        }}
      </span>
    </div>

    <div class="col-span-6 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('customer').touched &&
          formUpdate.controls.customer.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Tên khách hàng <span class="text-red-light">*</span></span
      >
      <ng-select
        [ngClass]="
          formUpdate.get('customer').touched &&
          formUpdate.controls.customer.errors?.required
            ? 'select-invalid'
            : 'select-name'
        "
        required
        [clearable]="false"
        class="mt-8px focus:outline-none"
        placeholder="Chọn khách hàng"
        bindLabel="full_name"
        bindValue="id"
        [items]="users"
        formControlName="customer"
        notFoundText="Không tìm thấy khách hàng"
        (change)="getValueUser($event)"
      >
        <ng-template ng-label-tmp let-item="item">
          <div class="flex items-center space-x-2">
            <img
              height="20"
              width="20"
              [src]="item.avatar"
              class="rounded-full"
            />
            <span
              class="text-14px font-roboto-regular text-dark-grey overflow-hidden overflow-ellipsis"
              >{{ item.full_name }}</span
            >
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="flex items-center space-x-2">
            <img height="20" width="20" [src]="item.avatar" />
            <span>{{ item.full_name }}</span>
          </div>
        </ng-template>
      </ng-select>
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          formUpdate.get('customer').touched &&
          formUpdate.controls.customer.errors?.required
        "
      >
        {{
          formUpdate.controls.customer.errors?.required
            ? 'Vui lòng chọn khách hàng'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-3 col-mobile">
      <span
        [ngClass]="
        formUpdate.controls.tax.errors?.pattern ? 'error-class' : ''
        "
        class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
        >MST</span
      >
      <input
        [ngClass]="
            formUpdate.controls.tax.errors?.pattern
              ? 'class-invalid'
              : 'class-valid'"
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        type="text"
        placeholder="Mã số thuế"
        formControlName="tax"
        class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />
      <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="formUpdate.controls.tax.errors?.pattern"
        >
          {{
            formUpdate.controls.tax.errors?.pattern
              ? 'Vui lòng nhập ký tự số'
              : ''
          }}
      </span>
    </div>
    <div class="col-span-3 col-mobile">
      <span
        [ngClass]="
        formUpdate.controls.fax.errors?.pattern ? 'error-class' : ''
        "
        class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
        >Fax</span
      >
      <input
        [ngClass]="
            formUpdate.controls.fax.errors?.pattern
              ? 'class-invalid'
              : 'class-valid'"
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        type="text"
        placeholder="Số Fax"
        formControlName="fax"
        class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />
      <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="formUpdate.controls.fax.errors?.pattern"
        >
          {{
            formUpdate.controls.fax.errors?.pattern
              ? 'Vui lòng nhập ký tự số'
              : ''
          }}
      </span>
    </div>
    <div class="col-span-12 col-mobile">
      <span
        class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
        >Địa chỉ</span
      >
      <input
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        type="text"
        placeholder="Địa chỉ"
        formControlName="address"
        class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />
    </div>
    <div class="col-span-6 col-mobile">
      <span class="text-12px font-roboto-regular text-warm-grey">Ngày ký</span>
      <input
        (onSelect)="detectChangeSigned($event)"
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        #dayPicker
        type="text"
        #dateToDp="dpDayPicker"
        [dpDayPicker]="datePickerConfig"
        [theme]="'dp-material'"
        formControlName="dateSigned"
        [mode]="'day'"
        placeholder="DD/MM/YYYY"
        class="class-valid cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
      />
    </div>
    <div class="col-span-6 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('status').touched &&
          formUpdate.controls.status.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Trạng thái <span class="text-red-light">*</span></span
      >

      <ng-select
        [ngClass]="
          formUpdate.get('status').touched &&
          formUpdate.controls.status.errors?.required
            ? 'select-invalid'
            : 'select-name'
        "
        required
        [clearable]="false"
        class="mt-8px focus:outline-none"
        placeholder="Chọn trạng thái hợp đồng"
        bindLabel="name"
        bindValue="id"
        [items]="statusContract"
        formControlName="status"
        (change)="getValueStatusContract($event)"
        notFoundText="Không tìm thấy trạng thái hợp đồng"
      >
      </ng-select>

      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="
          formUpdate.get('status').touched &&
          formUpdate.controls.status.errors?.required
        "
      >
        {{
          formUpdate.controls.status.errors?.required
            ? 'Vui lòng chọn trạng thái'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-4 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('dateStart').touched &&
          formUpdate.controls.dateStart.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Ngày bắt đầu <span class="text-red-light">*</span></span
      >
      <input
        required
        (onSelect)="detectChange($event)"
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        [ngClass]="
          formUpdate.get('dateStart').touched &&
          formUpdate.controls.dateStart.errors?.required
            ? 'class-invalid'
            : 'class-valid'
        "
        #dayPicker
        type="text"
        #dateToDp="dpDayPicker"
        [dpDayPicker]="datePickerConfig"
        [theme]="'dp-material'"
        formControlName="dateStart"
        [mode]="'day'"
        placeholder="DD/MM/YYYY"
        class="class-valid cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
      />
      <!-- [value]="formUpdate.get('start').value | date:'dd/MM/yyyy'" -->
      <span
        class="font-roboto-regular text-12px text-red-light ml-15px"
        *ngIf="
          formUpdate.get('dateStart').touched &&
          formUpdate.controls.dateStart.errors?.required
        "
      >
        {{
          formUpdate.controls.dateStart.errors?.required
            ? 'Vui lòng chọn ngày bắt đầu'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-4 col-mobile">
      <span
        [ngClass]="
          formUpdate.get('dateEnd').touched &&
          formUpdate.controls.dateEnd.errors?.required
            ? 'error-class'
            : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Ngày kết thúc <span class="text-red-light">*</span></span
      >
      <input
        required
        (onSelect)="detectChangeEnd($event)"
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        [ngClass]="
          formUpdate.get('dateEnd').touched &&
          formUpdate.controls.dateEnd.errors?.required
            ? 'class-invalid'
            : 'class-valid'
        "
        #dayPicker
        type="text"
        #dateToDp="dpDayPicker"
        [dpDayPicker]="datePickerConfig"
        [theme]="'dp-material'"
        formControlName="dateEnd"
        [mode]="'day'"
        placeholder="DD/MM/YYYY"
        class="cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
      />
      <!-- [value]="formUpdate.get('end').value | date:'dd/MM/yyyy'" -->
      <span
        class="font-roboto-regular text-12px text-red-light ml-15px"
        *ngIf="
          formUpdate.get('dateEnd').touched &&
          formUpdate.controls.dateEnd.errors?.required
        "
      >
        {{
          formUpdate.controls.end.errors?.required
            ? 'Vui lòng chọn ngày kết thúc'
            : ''
        }}
      </span>
    </div>
    <div class="col-span-4 col-mobile">
      <span
        class="text-12px font-roboto-regular text-warm-grey"
        >Ngày thanh lý</span
      >
      <input
        ng-change="formChanged=true"
        (onSelect)="detectChangeliquidationDate($event)"
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        #dayPicker
        type="text"
        #dateToDp="dpDayPicker"
        [dpDayPicker]="datePickerConfig"
        [theme]="'dp-material'"
        formControlName="liquidationDate"
        [mode]="'day'"
        placeholder="DD/MM/YYYY"
        class="class-valid cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
      />
    </div>
    <div class="col-span-12 col-mobile">
      <span
        class="text-12px font-roboto-regular text-warm-grey"
        [ngClass]="
          formUpdate.controls.note.errors?.maxlength ? 'error-class' : ''
        "
        >Ghi chú</span
      >
      <textarea
        [ngClass]="
          formUpdate.controls.note.errors?.maxlength
            ? 'class-invalid'
            : 'class-valid'
        "
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        formControlName="note"
        placeholder="Nhập ghi chú"
        class="w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
      ></textarea>
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="formUpdate.controls.note.errors?.maxlength"
      >
        {{
          formUpdate.controls.note.errors?.maxlength
            ? 'Mô tả tối đa 300 ký tự'
            : ''
        }}
      </span>
    </div>
  </div>
  <div class="col-span-3 col-mobile">
    <div class="mb-4">
      <span class="font-roboto-medium text-14px text-left leading-5 text-dark-grey">Thông tin người đại diện</span>
    </div>
    <div class="grid grid-cols-2 gap-4 col-mobile">
      <div class="col-span-2">
        <span
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >Tên người đại diện</span
        >
        <input
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          type="text"
          placeholder="Tên người đại diện"
          formControlName="name"
          class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
        />
      </div>
      <div class="col-span-2">
        <span
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >Tên công ty</span
        >
        <input
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          type="text"
          placeholder="Tên công ty"
          formControlName="company"
          class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
        />
      </div>
      <div class="col-span-2">
        <span
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >Chức vụ</span
        >
        <input
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          type="text"
          placeholder="Chức vụ"
          formControlName="position"
          class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
        />
      </div>
      <div class="col-span-2">
        <span
          [ngClass]="
            formUpdate.controls.identity_card.errors?.pattern ? 'error-class' : ''
          "
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >CMDD / CCCD</span
        >
        <input
          [ngClass]="
            formUpdate.controls.identity_card.errors?.pattern
              ? 'class-invalid'
              : 'class-valid'
          "
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          type="text"
          placeholder="Số CMND / CCCD"
          formControlName="identity_card"
          class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
        />
        <span
          class="font-roboto-regular text-12px text-red-light"
          *ngIf="formUpdate.controls.identity_card.errors?.pattern"
        >
          {{
            formUpdate.controls.identity_card.errors?.pattern
              ? 'Vui lòng nhập ký tự số'
              : ''
          }}
        </span>
      </div>
      <div class="col-span-1 col-mobile-2">
        <span
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >Nơi cấp</span
        >
        <input
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          type="text"
          placeholder="Nơi cấp"
          formControlName="issued_by"
          class="class-valid w-full border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
        />
      </div>
      <div class="col-span-1 col-mobile-2">
        <span
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >Ngày cấp</span
        >
        <input
          (onSelect)="detectChangeEnd($event)"
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          #dayPicker
          type="text"
          #dateToDp="dpDayPicker"
          [dpDayPicker]="datePickerConfig"
          [theme]="'dp-material'"
          formControlName="issued_date"
          [mode]="'day'"
          placeholder="DD/MM/YYYY"
          class="class-valid cursor-pointer w-full border rounded-4px py-8px pl-10px pr-8px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"
        />
      </div>
      <div class="col-span-2">
        <span
          class="text-12px font-roboto-regular text-warm-grey whitespace-nowrap overflow-hidden overflow-ellipsis"
          >Thông tin chuyển khoản</span
        >
        <textarea
          (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
          formControlName="bank"
          placeholder="Số tài khoản - Tên chủ TK - Tên Ngân hàng - Chi nhánh"
          class="class-valid w-full border rounded-4px mt-8px py-8px px-12px h-100px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="col-span-3 col-mobile">
    <div class="mb-4">
      <span class="font-roboto-medium text-14px text-left leading-5 text-dark-grey">Giá trị hợp đồng</span>
    </div>
    <div class="mb-4">
      <span
        [ngClass]="
          formUpdate.controls.amount.errors?.pattern ? 'error-class' : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Giá trị hợp đồng (VND)
      </span>
      <input
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        [value]="formUpdate.controls.amount.value | meucurrency"
        (input)="updateAmount($event.target.value)"
        [ngClass]="
          formUpdate.controls.amount.errors?.pattern
            ? 'class-invalid'
            : 'class-valid'
        "
        type="text"
        placeholder="Nhập giá trị hợp đồng"
        formControlName="amount"
        class="w-full text-right border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />

      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="formUpdate.controls.amount.errors?.pattern"
      >
        {{
          formUpdate.controls.amount.errors?.pattern
            ? 'Vui lòng nhập đúng ký tự số'
            : 'Vui lòng nhập giá trị hợp đồng'
        }}
      </span>
    </div>
    <div class="mb-4">
      <span
        [ngClass]="
          formUpdate.controls.discount.errors?.pattern ? 'error-class' : ''
        "
        class="text-12px font-roboto-regular text-warm-grey"
        >Chiết khấu (VND)
      </span>
      <input
        (input)="changeDiscount($event.target.value)"
        [value]="formUpdate.controls.discount.value | meucurrency"
        [ngClass]="
          formUpdate.controls.discount.errors?.pattern
            ? 'class-invalid'
            : 'class-valid'
        "
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        type="text"
        placeholder="Nhập giá trị chiết khấu"
        formControlName="discount"
        class="w-full text-right border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="formUpdate.controls.discount.errors?.pattern"
      >
        {{
          formUpdate.controls.discount.errors?.pattern
            ? 'Vui lòng nhập đúng ký tự số'
            : ''
        }}
      </span>
    </div>
    <div>
      <span
        class="text-12px font-roboto-regular text-warm-grey"
        [ngClass]="
          formUpdate.controls.actual_amount.errors?.pattern
            ? 'error-class'
            : ''
        "
        >Giá trị thực tế (VND)</span
      >
      <input
        disabled
        [value]="formUpdate.controls.actual_amount.value | meucurrency"
        [ngClass]="
          formUpdate.controls.actual_amount.errors?.pattern
            ? 'class-invalid'
            : 'class-valid'
        "
        (keyup)="formUpdate.invalid ? (change = true) : (change = false)"
        type="text"
        placeholder="Nhập giá trị thực tế"
        formControlName="actual_amount"
        class="w-full text-right border rounded-4px py-8px px-12px h-40px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic"
      />
      <span
        class="font-roboto-regular text-12px text-red-light"
        *ngIf="formUpdate.controls.actual_amount.errors?.pattern"
      >
        {{
          formUpdate.controls.actual_amount.errors?.pattern
            ? 'Vui lòng nhập đúng ký tự số'
            : ''
        }}
      </span>
    </div>
  </div>
  <div class="col-span-6 col-mobile">
      <span class="text-12px font-roboto-regular text-warm-grey mb-2">
        Tài liệu đính kèm
      </span>
      <ngx-dropzone
        #commonDropzone
        (change)="onSelect($event)"
        id="common-dropzone"
        [multiple]="true"
        class="customBG mt-8px set-h-180"
      >
        <ngx-dropzone-label
          class="opacity-60 flex items-center font-medium text-12px"
          *ngIf="filesContractUpdate.length == 0"
        >
          <div class="flex flex-col">
            <img
              src="../../../../../assets/icons/contract-icons/u_image-v.svg"
              alt="Pic"
              class="h-20px w-20px m-auto"
            />
            <div class="text-12px font-roboto-regular text-purplish-grey flex">
              <span
                >Kéo thả tệp mới vào đây hoặc
                <span class="underline text-primary-blue">Duyệt</span></span
              >
            </div>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let itemFile of filesContract; let i = index"
          [file]="itemFile"
          [removable]="true"
          (removed)="onRemove(itemFile)"
          [tooltip]="itemFile.file_name ? itemFile.file_name : itemFile.name"
          class="customdrop"
        >
          <ngx-dropzone-label
            class="text-12px overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            <span class="text-left"
              >{{itemFile.file_name || itemFile.name}} <br />
              ({{ itemFile.file_name ? 'Đã Lưu' : 'Chưa Lưu' }})
            </span>
          </ngx-dropzone-label>
        </ngx-dropzone-preview>
        <ngx-dropzone-preview
          ngProjectAs="ngx-dropzone-preview"
          [removable]="false"  
          class="customBG"
          *ngIf="isDownload"
        >
          <ngx-dropzone-label
            class="text-20px text-center mb-15px"
          >
            <span
              >+<br />
            </span>
          </ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
      <span class="text-12px text-warm-grey font-roboto-regular pl-12px"
        >Kích thước tệp nhỏ hơn 2MB</span
      >
      <div class="pl-12px" *ngIf="isDownload">
        <span class="text-12px text-warm-grey font-roboto-regular"
          >Tải xuống tệp đính kèm:</span
        >
        <div class="flex flex-wrap gap-4px">
          <div *ngFor="let itemFile of filesContract; let i = index">
            <button
              *ngIf="itemFile.file_name"
              (click)="onDownloadImage(itemFile.id, itemFile.file_name)"
              class="bg-grey-light text-12px rounded-4px px-10px py-4px text-center items-center focus:outline-none"
            >
              {{itemFile.file_name}}
            </button>
          </div>
        </div>
      </div>
  </div>
  <div class="col-span-full grid grid-cols-12">
    <div class="col-span-6">
      <button
        class="btn-primary-add"
        (click)="exportContract()"
      >
        Xuất hợp đồng
      </button>
    </div>
    <div class="col-span-6 flex-row-reverse gap-8px flex h-60px">
      <button
        (click)="onUpdate()"
        class="btn-primary-add"
        type="button"
        [disabled]="!formUpdate.dirty && change && filesContractUpdate.length == 0"
        [disabled]="!formUpdate.valid || change"
      >
      <!-- [disabled]="change" -->
        Lưu
      </button>
      <button
        class="btn-text-cancel"
        [routerLink]="'/contract/contract-list'"
      >
        Hủy
      </button>
    </div>
  </div>
</form>

