export default class BackgroundScroll {
  disable = (isDisable) => {
    if (isDisable) {
      document.body.addEventListener('touchmove', this.freezeVp, false);
      document.body.style.overflow = 'hidden';
      let vpH = window.innerHeight;
      document.documentElement.style.height = vpH.toString() + 'px';
      document.body.style.height = vpH.toString() + 'px';
    } else {
      document.body.removeEventListener('touchmove', this.freezeVp, false);
      document.body.style.overflow = '';
    }
  };

  freezeVp = (e) => {
    e.preventDefault();
  };
}
