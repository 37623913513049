import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  HostListener,
} from '@angular/core';

import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import moment from 'moment';
import { Router } from '@angular/router';
import { NotifyService } from './notify.service';
import { ToastrService } from '../../services/toastr.service';
import { FcmService } from './fcm.service';

@Component({
  selector: 'meu-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [FcmService, NotifyService],
})
export class NotificationComponent implements OnInit {
  @ViewChild('notiGroup', { static: true }) notiGroup: ElementRef;
  isOpenMenu;
  isOpenMenu$ = new Subject();
  groupNotification: any[] = [];
  groupNotification$ = new Subject();
  pageNumber: number = 1;
  selectedGroupIndex: any = -1;
  selectedType: any = '';
  pageSize: number = 10;
  notify: any = [];
  notifyTotal: any;
  notificationCount$ = new Subject();
  notify$: any;
  backendhost = environment.backendhost;
  serviceId: string;
  loading: boolean = false;
  loadList;
  notifyUnread;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private fcmService: FcmService,
    private notifyService: NotifyService,
    private elementRef: ElementRef
  ) {}
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    event.stopPropagation();
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      event.target.scrollTop = event.target.scrollTop - 1;
      if (this.notifyTotal > this.notify.length) {
        this.getNotification(
          this.serviceId,
          this.selectedType,
          this.selectedGroupIndex,
          true
        );
      }
    }
  }
  ngOnInit(): void {
    this.getNotificationGroupInit(true);
    this.notifyService.currentMessage.subscribe((notify: any) => {
      let newNotify = JSON.parse(notify.notification.body);
      newNotify.message = JSON.parse(this.b64DecodeUnicode(newNotify.message));
      this.convertTimeInNotify(newNotify);
      this.notify.unshift(newNotify);
      this.notifyTotal += 1;
      this.countUnreadChange(1);
      this.groupNotification.find(
        (x) => x.type == newNotify.main_category_type
      ).unread += 1;
    });
    this.isOpenMenu$.subscribe((x) => {
      if (x) {
        this.getNotification();
      }
    });
  }
  countUnreadChange(number) {
    this.notifyUnread = this.notifyUnread + number;
    this.notificationCount$.next(this.notifyUnread);
  }
  openDropdownMenu() {
    this.isOpenMenu = !this.isOpenMenu;
    this.isOpenMenu$.next(this.isOpenMenu);
  }
  ngAfterViewInit() {
    //css
    if (screen.width < 480) {
      let scrollBodyHeight = window.innerHeight - 122 - 46 - 42;
      this.elementRef.nativeElement.style.setProperty(
        '--scroll-body-h',
        scrollBodyHeight + 'px'
      );
      this.elementRef.nativeElement.style.setProperty(
        '--dropdown-width',
        screen.width + 'px'
      );
    } else {
      this.elementRef.nativeElement.style.setProperty(
        '--scroll-body-h',
        448 + 'px'
      );
      this.elementRef.nativeElement.style.setProperty(
        '--dropdown-width',
        '100%'
      );
    }
  }
  getNotification(serviceId = '', type = '', index?, isloadMore?) {
    this.loading = true;
    if (isloadMore) {
      this.pageNumber += 1;
    } else {
      this.notifyTotal = this.notifyTotal > 10 ? this.notifyTotal : 0;
      this.notify = [];
      this.pageNumber = 1;
      this.serviceId = serviceId;
      this.selectedGroupIndex = index;
    }
    this.fcmService
      .apiFcmGetMyNotifications(
        `service_id==${this.serviceId},main_category_type==${type}`,
        '',
        this.pageNumber,
        this.pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          let notifyAddlist = res.data.collection;
          this.notifyTotal = res.data.total;
          notifyAddlist.forEach((item, index) => {
            item.message = JSON.parse(this.b64DecodeUnicode(item.message));
            this.convertTimeInNotify(item);
          });
          this.notify = [...this.notify.concat(notifyAddlist)];
          this.loading = false;
          this.loadList = notifyAddlist.flatMap((i, index) => [index]);
        } else {
          this.toastr.error('Lấy thông báo thất bại');
        }
      });
  }
  b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  getNotificationGroupInit(isInit: boolean) {
    this.fcmService.apiFcmGetMyNotificationsSummary().subscribe((res) => {
      if (res.success) {
        this.groupNotification = res.data;
        this.getNotification();
        this.groupNotification$.next(this.groupNotification);
        this.notifyUnread = res.total_unread;
        this.notificationCount$.next(res.total_unread);
      }
    });
  }

  markAsDeleteNotify(notifyId, index, event) {
    event.stopPropagation();
    this.fcmService
      .apiFcmNotificationIdMarkAsDeletePut(notifyId)
      .subscribe((res) => {
        if (res.success) {
          this.notify[index].expired_at = 5;
        } else {
          this.toastr.error('Xóa thất bại', 3000);
        }
      });
  }
  deleteNotify(notifyId, index, event) {
    event.stopPropagation();
    this.fcmService.apiFcmNotificationIdDeletePut(notifyId).subscribe((res) => {
      if (res.success) {
        //this.toastr.success('Đã xóa thành công', '');
        this.notify.splice(index, 1);
        this.notifyTotal -= 1;
        if (!this.notify[index].is_read) {
          this.countUnreadChange(-1);
        }
      } else {
        this.toastr.error('Xóa thất bại', 3000);
      }
    });
  }

  readNotify(notifyId, index, event, isCheckMarkRead?, item = null) {
    this.fcmService
      .apiFcmNotificationIdMarkAsReadPut(notifyId)
      .subscribe((res) => {
        if (res.success) {
          if (!isCheckMarkRead) {
            this.selectedGroupIndex > -1
              ? (this.groupNotification[this.selectedGroupIndex].unread -= 1)
              : null;
            this.countUnreadChange(-1);
            this.markAsDeleteNotify(notifyId, index, event);
          }
          console.log(item);

          let page;
          if (item.main_category_type == 700) {
            page = this.notify[index].message.url;
            localStorage.setItem(
              'inventoryId',
              this.notify[index].message.url.split('/')[4]
            );
          } else {
            this.notify[index].is_read = true;
            page = this.notify[index].message.url.replace(
              '{inventoryId}',
              localStorage.getItem('inventoryId')
            );
          }
          document.location.href = page;
        }
      });
  }
  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  cancelDeleteNotify(notifyId, index, event) {
    event.stopPropagation();
    this.fcmService
      .apiFcmNotificationIdCancelDeletePut(notifyId)
      .subscribe((res) => {
        if (res.success) {
          this.notify[index].expired_at = null;
        }
      });
  }
  convertTimeInNotify(item) {
    if (item.expired_at != null) {
      item.expired_at = moment().diff(item.expired_at, 'minutes');
      item.expired_at = Math.abs(item.expired_at) + 1;
    }
    let timeFrom = moment().diff(item.created_at, 'minutes');
    switch (true) {
      case timeFrom < 60:
        item.created_at = timeFrom + ' phút trước';
        break;
      case timeFrom > 60 && timeFrom < 1440:
        item.created_at =
          moment().diff(item.created_at, 'hours') + ' giờ trước';
        break;
      case timeFrom > 1440 && timeFrom < 4320:
        item.created_at =
          moment().diff(item.created_at, 'days') + ' ngày trước';
        break;
      case timeFrom > 4320:
        item.created_at = moment.utc(item.created_at).format('DD-MM-YYYY');
        break;
      default:
        break;
    }
  }
}
