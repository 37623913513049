<div class="bg-gradient">
  <div class="bg-image h-full">
    <div class="login-image"></div>
    <div class="container grid-contain content h-full text-white">
      <div
        class="col-span-4 flex flex-col items-center justify-center h-full font-roboto-regular"
      >
        <h1 class="font-saira text-40px font-medium mb-8">Kho lạnh Sài Gòn</h1>
        <div class="border rounded-md w-full p-8">
          <div class="text-24px font-medium text-center mb-4">Đăng nhập</div>
          <div class="flex flex-col mb-2">
            <span class="text-12px text-lightest-grey"> Email </span>
            <input
              type="text"
              placeholder="Nhập email"
              class="w-full py-10px px-12px mt-8px text-14px input-style"
            />
            <span class="text-12px text-red-light"> </span>
          </div>
          <div class="flex flex-col mb-8">
            <span class="text-12px text-lightest-grey"> Mật khẩu </span>
            <input
              type="text"
              placeholder="Nhập mật khẩu"
              class="w-full py-10px px-12px mt-8px text-14px input-style"
            />
            <span class="text-12px text-red-light"> </span>
          </div>
          <button
            id="login-btn"
            class="btn-primary-sm mb-4"
            type="button"
          >
            Đăng nhập
          </button>
          <div class="text-center text-14px">
            <a class="">Quên mật khẩu?</a>
          </div>
        </div>
      </div>
      <!-- <div class="col-span-8">
                <img src="/assets/images/login-image.svg" width="500" height="500">
            </div> -->
    </div>
  </div>
</div>
