<form [formGroup]="formRangeDate" class="col-span-full grid grid-cols-12 gap-4 px-10px pb-10px">
    <div class="md:col-span-6 col-span-12">
        <span class="font-roboto-regular text-12px text-warm-grey">Từ ngày</span>
        <input 
          type="text"
          ngxDaterangepickerMd
          [closeOnAutoApply]="true"
          [autoApply]="true"
          [singleDatePicker]="true"
          formControlName="start"
          [maxDate]="maxDate"
          (change)="getDateTime($event)"
          [locale]="{format: 'DD/MM/YYYY'}"
          placeholder="Nhập khoản thời gian"
          class="form-control class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-38px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"/>
    </div>
    <div class="md:col-span-6 col-span-12">
        <span class="font-roboto-regular text-12px text-warm-grey">Đến ngày</span>
        <input 
        type="text"
        ngxDaterangepickerMd
        [closeOnAutoApply]="true"
        [autoApply]="true"
        [singleDatePicker]="true"
        [locale]="{format: 'DD/MM/YYYY'}"
        formControlName="end"
        [maxDate]="maxDate"
        [minDate]="minDate"
        placeholder="Nhập khoảng thời gian"
        class="form-control class-valid cursor-pointer w-full border rounded-4px py-8px px-12px pr-8px h-38px mt-8px placeholder-color-placholder placeholder-opacity-25 font-roboto-regular text-14px text-dark-grey font-medium not-italic hover:border-primary-blue focus:outline-none focus:ring-primary-blue"/>
    </div>
    <div class="col-span-12 flex flex-row-reverse gap-8px">
      <button
        class="btn-primary-add"
        type="button"
        (click)="submit()"
        [disabled]="!formRangeDate.controls.start.value || !formRangeDate.controls.end.value"
      >
        Xác nhận
      </button>
      <button class="btn-text-cancel" (click)="closeModal()">Hủy</button>
    </div>
</form>
