import { PopUpModule } from './../dashboard-client/pop-up/pop-up.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from 'src/app/core/interceptors/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardManageComponent } from './dashboard-manage/dashboard-manage.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImportExportHistoryModule } from '../import-export-history/import-export-history.module';
import { ExportReportManageModule } from './export-report-manage/export-report-manage.module';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { CanActivateRouteGuard } from 'src/app/core/guards/permissions-route.guard';
import { ExpiredProductModalComponent } from './expired-product-modal/expired-product-modal.component';
import { DateRangeModule } from './date-range-modal/date-range-modal.module';

const routes: Routes = [
  {
    path: '',
    component: DashboardManageComponent,
  },
  {
    path: 'dashboard-manage',
    component: DashboardManageComponent,
    canActivate: [CanActivateRouteGuard],
    data: { permissions: 'STAFF' },
  },
];

@NgModule({
  declarations: [DashboardManageComponent, ExpiredProductModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ImportExportHistoryModule,
    NgSelectModule,
    DpDatePickerModule,
    ExportReportManageModule,
    PopUpModule,
    DateRangeModule,
  ],
  providers: [
    InterceptService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
})
export class DashboardsModule {}
